import { StorageService } from 'src/app/services/general/storage.service';
import { ConfigService } from 'src/app/services/general/config.service';
import { ProviderService } from 'src/app/services/user/provider.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { AnalyticsService } from 'src/app/services/general/analytics.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { AuthService } from 'src/app/services/user/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { UserManageService } from 'src/app/services/user/user-manage.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { LinkService } from 'src/app/services/general/link.service';
import { PhotoService } from 'src/app/services/general/photo.service';

import { UserSetupStep } from 'src/app/types/user';
import { User } from 'src/app/interfaces/user';
import { MediaType } from 'src/app/interfaces/general';
import { GeoLocationService } from 'src/app/services/location/geo-location.service';
import { IpLocationService } from 'src/app/services/location/ip-location.service';

import { KeyboardService } from 'src/app/services/general/keyboard.service';

@Component({
  selector: 'app-user-setup',
  templateUrl: './user-setup.component.html',
  styleUrls: ['./user-setup.component.scss'],
})
export class UserSetupComponent implements OnInit, OnDestroy {

  /**
   * Name input viewchild
   */
  @ViewChild('nameInput') private nameInput: any;

  user: User;
  /**
   * User setup form type
   */
  userSetupStep: UserSetupStep;
  /**
   * Avatar
   */
  avatar: MediaType = {
    url: '',
    type: ''
  };
  /**
   * Profile Form
   */
  userSetupForm: FormGroup;
  /**
   * Validation Message
   */
  validationMsg: any;

  private skipAppleIdName: boolean;
  /**
   * Login Ready Subscription
   */
  private loginReadySubscription: Subscription;
  /**
   * User subscription
   */
  private userSubscription: Subscription;

  /**
   * Constructor
   * @param actionSheetController Action Sheet Controller
   * @param modalController Modal Controller
   * @param translate Translate Service
   * @param afStorage Angularfire Storage
   * @param popupService Popup Service
   * @param userService User Service
   * @param authService Authentication Service
   * @param languageService language service
   * @param route Route
   * @param router Router
   * @param formBuilder Form Builder
   * @param localityService Locality service
   * @param errorService Error Service
   * @param functionService Function service
   */
  constructor(
    private platform: Platform,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private translate: TranslateService,
    private popupService: PopupService,
    private photoService: PhotoService,
    private userService: UserService,
    private providerService: ProviderService,
    private userManageService: UserManageService,
    private ipLocationService: IpLocationService,
    private geoLocationService: GeoLocationService,
    private authService: AuthService,
    private functionService: FunctionService,
    private configService: ConfigService,
    private storageService: StorageService,
    private linkService: LinkService,
    private analyticsService: AnalyticsService,
    private keyboardService: KeyboardService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
      this.unwatchLoginReady();
      this.unwatchUser();
  }

  /**
   * Before view enter
   */
  async ionViewWillEnter() {
    // await this.popupService.presentLoading();
    await this.setupForm();
    await this.watchLoginReady();
    await this.watchUser();
    // await this.checkProvider();
    this.userSetupStep = 'name';
    // await this.popupService.dismissLoading();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatchLoginReady();
    this.unwatchUser();
  }

  // async checkProvider() {
  //   if (this.platform.is('hybrid') && this.platform.is('ios') && this.providerService.getProviderData('apple.com') && this.configService.skipAppleIdName) {
  //     this.userSetupStep = 'avatar';
  //     this.skipAppleIdName = true;
  //   } else {
  //     this.userSetupStep = 'name';
  //   }
  // }

  async getLocation() {
    if (!this.user?.locality) {
      await this.ipLocationService.ipGeocode();
    }
    if (!this.user?.location) {
      await this.geoLocationService.checkPermissions();
    }
    this.popupService.dismissLoading();
  }

  /**
   * Setup profile form
   */
  async setupForm() {
    this.userSetupForm = this.formBuilder.group({
      name: new FormControl('', [ Validators.required ])
    });
    this.validationMsg = {
      name: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('LBL.name') }) }]
    };
  }

  /**
   * Watch login ready
   */
  async watchLoginReady() {
    if (!this.loginReadySubscription) {
      this.loginReadySubscription = this.authService.observableLoginReady.subscribe((loginReady: boolean) => {
        if (loginReady) {
          this.setupData();
          this.unwatchLoginReady();
        }
      });
    }
    
  }

  /**
   * Unwatch login ready
   */
  async unwatchLoginReady() {
    if (this.loginReadySubscription) {
      this.loginReadySubscription.unsubscribe();
      this.loginReadySubscription = null;
    }
  }

  /**
   * Watch user
   */
  async watchUser() {
    if (!this.userSubscription) {
      this.userSubscription = this.userService.observableUser.subscribe((user: User) => {
        this.user = user;
        if (this.user?.name) {
          this.dismissModal();
        } else {
          this.setupData();
        }
        this.getLocation();
      });
    }
    
  }

  /**
   * Unwatch user
   */
  async unwatchUser() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
      this.userSubscription = null;
    }
  }

  /**
   * Initial name & avatar from provider data
   */
  async setupData() {
    if (this.user?.name) {
      this.userSetupForm.controls.name.setValue(this.user.name);
    } else if (this.authService?.currentUser?.displayName) {
      this.userSetupForm.controls.name.setValue(this.authService.currentUser.displayName);
    } else if (this.authService?.currentUser?.providerData?.[0]?.displayName) {
      this.userSetupForm.controls.name.setValue(this.authService.currentUser.providerData[0].displayName);
    } else if (this.providerService.getProviderData('apple.com')) {
      const name = await this.storageService.get('display_name');
      if (name) {
        this.userSetupForm.controls.name.setValue(name);
      }
    }

    if (this.user?.avatar) {
      this.avatar = this.user.avatar;
    } else if (this.authService?.currentUser?.photoURL) {
      this.avatar = {
        url: this.authService.currentUser.photoURL,
        type: 'cloud'
      };
    } else if (this.authService?.currentUser?.providerData?.[0]?.photoURL) {
      this.avatar = {
        url: this.authService.currentUser.providerData[0].photoURL,
        type: 'cloud'
      };
    }
  }

  /**
   * Select photo
   */
  async selectPhoto() {
    this.photoService.selectPhoto('user_avatar', this.user?.uid ? true : false);
  }

  getUserAvatar() {
    return this.photoService.userAvatar;
  }

  /**
   * Dismiss Modal
   */
  async dismissModal() {
    if (this.modalController) {
      if (this.platform.is('hybrid')) {
        await this.keyboardService.hide();
        if (this.platform.is('ios')) {
          await this.functionService.delay(100);
        }
      }
      const modal = await this.modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    }
  }

  /**
   * Back step
   */
  back() {
    if (this.userSetupStep === 'avatar') {
      this.userSetupStep = 'name';
    }
  }

  /**
   * Next step
   */
  next() {
    if (this.userSetupStep === 'name') {
      this.userSetupForm.markAsUntouched();
      if (this.userSetupForm.valid) {
        this.userSetupStep = 'avatar';
      }
    } else if (this.userSetupStep) {
      this.saveProfile();
    }
  }

  /**
   * Save new user profile
   */
  async saveProfile() {
    await this.popupService.presentLoading();
    const name: string = this.userSetupForm.value?.name ? this.userSetupForm.value?.name : '';
    let avatar: MediaType = {
      url: '',
      type: 'local'
    };
    if (this.photoService.userAvatar?.url) {
      if (this.photoService.userAvatar?.type !== 'base64') {
        avatar = this.photoService.userAvatar;
      }
    } else if (this.avatar?.url) {
      avatar = this.avatar;
    }
    if (this.authService.currentUser && !this.userService.user) {
      await this.userManageService.createNewUserData(name, avatar);
      this.analyticsService.userCreated(await this.authService.getAuthType(), this.userService?.uid);
      if (this.photoService.userAvatar?.type === 'base64' && this.photoService.userAvatar?.url) {
        await this.photoService.uploadUserAvatar('user_avatar', this.photoService.userAvatar.url, true);
      }
    } else {
      await this.userManageService.updateUser({ name, avatar });
    }
    setTimeout(() => { this.goAccountsList(); }, 500);
  }

  /**
   * Go accounts List
   */
  async goAccountsList() {
    await this.popupService.dismissLoading();
    this.dismissModal();
    this.linkService.goAccountsListPage(true);
  }

}
