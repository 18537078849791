<app-loading class="full-width-height" [type]="'receipt'" *ngIf="!showPdf || !src"></app-loading>

<ng-container *ngIf="showPdf && src">
    <ion-header>
        <ion-toolbar color="light">
            <ion-buttons slot="start">
              <ion-button (click)="dismissModal()">
                <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
              </ion-button>
            </ion-buttons>
            <ion-title>
              <app-offline-indicator></app-offline-indicator>
              {{ 'RECEIPT.title' | translate }}
            </ion-title>
          </ion-toolbar>
    </ion-header>
    <ion-content #content>
        <ngx-extended-pdf-viewer [base64Src]="src" [height]="contentHeight + 'px'" [showOpenFileButton]="true" [showPrintButton]="true" [showDownloadButton]="true"></ngx-extended-pdf-viewer>
    </ion-content>
</ng-container>
