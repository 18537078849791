<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'BLAST.review.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <div class="ion-padding" *ngIf="ready">
    <ion-text class="font-12">{{ 'BLAST.review.intro' | translate: { type: blastType } }}</ion-text>
    <ion-card class="ion-no-margin ion-margin-vertical ion-padding" *ngIf="blastUsageDetailList?.length">
      <app-blast-usage-summary [blastType]="blastType" [blastUsageDetailList]="blastUsageDetailList" [showTotalBlastCount]="true" [ready]="ready"></app-blast-usage-summary>
    </ion-card>

    <ion-card class="ion-no-margin ion-margin-vertical ion-padding" (click)="presentGuestListModal()">
      <ion-item class="ion-item-no-padding" lines="none" [detail]="selectedGuestIdList?.length || selectedGroupIdList?.length">
        <ion-text>
          <div class="font-14 font-bold">
            {{ 'BLAST.usage.send_to' | translate }}
          </div>
          <div class="font-12">
            <div *ngIf="selectedGuestIdList?.length">
              {{ 'BLAST.lbl.count_guest_selected' | translate: { count: selectedGuestIdList?.length ? selectedGuestIdList.length : 0 } }}
            </div>
            <div *ngIf="selectedGroupIdList?.length">
              {{ 'BLAST.lbl.count_group_selected' | translate: { count: selectedGroupIdList?.length ? selectedGroupIdList.length : 0 } }}
            </div> 
          </div>
        </ion-text>
      </ion-item>
    </ion-card>

    <ion-card class="ion-no-margin ion-margin-vertical ion-padding">
      <ion-item class="ion-item-no-padding" lines="none">
        <ion-text>
          <div class="font-14 font-bold">
            {{ 'BLAST.usage.estimated_time' | translate }}
          </div>
          <div class="font-12" *ngIf="estDeliveryTime">
            {{ 'BLAST.usage.time_field' | translate: { min: estDeliveryTime.min, max: estDeliveryTime.max } }}
          </div>
        </ion-text>
      </ion-item>
    </ion-card>

    <ion-card class="ion-no-margin ion-margin-vertical ion-padding">
      <app-blast-credit-summary [blastUsageDetailList]="blastUsageDetailList" [showHeader]="true" [showTotal]="true" [showAvailable]="true" [showBalance]="true" [ready]="ready" (setTotal)="setTotal($event)"></app-blast-credit-summary>
    </ion-card>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar class="ion-no-padding">
    <div class="ion-padding-horizontal ion-padding-bottom ion-margin-horizontal ion-text-center">
      <ion-button expand="block" (click)="next()" [disabled]="!blastTemplate || !blastMsgList?.length || !blastUsageDetailList?.length || (!selectedGuestIdList?.length && !selectedGroupIdList?.length) || trialMode || total < 0">{{ 'BTN.send' | translate }}</ion-button>
      <div (click)="presentBlastPackageModal(total)" *ngIf="!trialMode && total < 0">
        <ion-text class="font-10 font-underline" color="danger" [innerHTML]="'BLAST.error.insufficient_credit' | translate"></ion-text>
      </div>
      <div class="line-100" (click)="goUpgrade()" *ngIf="trialMode">
        <ion-text class="font-10" color="danger" [innerHTML]="'BLAST.error.trial' | translate">
        </ion-text>
      </div>
    </div>
  </ion-toolbar>
</ion-footer>
