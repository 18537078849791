import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Platform, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { InvitedByService } from 'src/app/services/setting/invited-by.service';
import { AccountUserService } from 'src/app/services/account/account-user.service';
import { GiftService } from 'src/app/services/gift/gift.service';

import { AccountUserComponent } from 'src/app/components/account/account-user/account-user.component';
import { SettingFieldComponent } from 'src/app/components/setting/setting-field/setting-field.component';

import { SettingField } from 'src/app/interfaces/database';
import { SettingFieldType, ModuleType } from 'src/app/types/general';
import { SeatingSortType } from 'src/app/types/seating';
import { Range } from 'src/app/interfaces/general';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';


/**
 * Seating list fitler component
 */
@Component({
  selector: 'app-seating-list-filter',
  templateUrl: './seating-list-filter.component.html',
  styleUrls: ['./seating-list-filter.component.scss'],
})
export class SeatingListFilterComponent implements OnInit, OnDestroy {

  /**
   * Sorting type
   */
  sorting: SeatingSortType;
  /**
   * Show field
   */
  showField: {
    giftType?: boolean,
    currency?: boolean,
    amount?: boolean,
    invitedBy?: boolean,
    category?: boolean,
    checkinStatus?: boolean,
    status?: boolean,
    group?: boolean,
    reserved?: boolean,
    seating?: boolean,
    dietaryReq?: boolean,
    specialReq?: boolean,
    session?: boolean,
    mobile?: boolean,
    email?: boolean,
    createdBy?: boolean,
    updateBy?: boolean,
    checkinBy?: boolean,
    deleteBy?: boolean,
    giftBy?: boolean,
  };
  /**
   * Currency list
   */
  currencyList: string[];
  /**
   * Min / max amount
   */
  amount: Range;
  /**
   * AccountModule
   */
  module: ModuleType;
  /**
   * Descending flag
   */
  desc: boolean;
  /**
   * Selected filter conditions
   */
  filter: any;
  /**
   * Filter form
   */
  filterForm: FormGroup;
  /**
   * Invited by list
   */
  invitedByList: SettingField[];

  seatingTypeName: string;

  category: string;
  dietaryReq: string;
  specialReq: string;
  session: string;

  /**
   * Invited by subscription
   */
  private invitedBySubscription: Subscription;
  /**
   * Currency list subscription
   */
  private currencyListSubscription: Subscription;
  /**
   * Min / max amount subscription
   */
  private amountSubscription: Subscription;
  /**
   * Constructor
   * @param platform Platform service
   * @param formBuilder Form builder
   * @param modalController Modal controller
   * @param translate Translate service
   * @param accountUserService Account user service
   * @param invitedByService Invited by service
   */
  constructor(
    private platform: Platform,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private moduleService: ModuleService,
    private accountUserService: AccountUserService,
    private settingFieldService: SettingFieldService,
    private seatingSettingService: SeatingSettingService,
    private invitedByService: InvitedByService,
    private giftService: GiftService,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {
    this.unwatch();
  }

  /**
   * Start before view enter
   */
  ionViewWillEnter() {
    this.initialize();
  }

  /**
   * Unwatch invited by before leave
   */
  ionViewWillLeave() {
    this.unwatch();
  }

  /**
   * Initial component.
   * Setup form & setup form value if applicable.
   * Start watch invited By.
   */
  async initialize() {
    this.module = this.moduleService.currentModule;
    await this.setupForm();
    if (this.filter) {
      this.setupFormValue();
    }

    this.watch();

    this.seatingTypeName = this.getSeatingTypeName();

    this.showField = {};

    this.showField.category = true;
    this.showField.createdBy = true;
    this.showField.dietaryReq = true;
    this.showField.group = true;
    this.showField.reserved = true;
    this.showField.invitedBy = true;
    this.showField.seating = true;
    this.showField.specialReq = true;
    this.showField.session = true;
    this.showField.mobile = true;
    this.showField.email = true;

    this.showField.status = true;
    this.showField.updateBy = true;

    if (this.module === 'gift') {
      this.showField.giftType = true;
      this.showField.amount = true;
      this.showField.currency = true;
      this.showField.giftBy = true;
    }
  }

  watch() {
    this.watchInvitedBy();
    if (this.module === 'gift') {
      this.watchCurrencyList();
      this.watchAmount();
    }
  }

  unwatch() {
    this.unwatchInvitedBy();
    this.unwatchAmount();
    this.unwatchCurrencyList();
  }

  setupSettingField() {
    this.category = this.getSettingField('category', this.filterForm?.value?.category);
    this.dietaryReq = this.getSettingField('dietary_req', this.filterForm?.value?.dietaryReq);
    this.specialReq = this.getSettingField('special_req', this.filterForm?.value?.specialReq);
    this.session = this.getSettingField('session', this.filterForm?.value?.session);
  }

  /**
   * Watch invited by list
   */
  async watchInvitedBy() {
    if (!this.invitedBySubscription) {
      this.invitedBySubscription = this.invitedByService.observableInvitedbyList.subscribe((invitedByList: SettingField[]) => {
        this.invitedByList = this.invitedByService.getInvitedByList();
      });
    }
    
  }

  /**
   * Unwatch invited by list
   */
  async unwatchInvitedBy() {
    if (this.invitedBySubscription) {
      this.invitedBySubscription.unsubscribe();
      this.invitedBySubscription = null;
    }
  }

  /**
   * Watch currency list
   */
  async watchCurrencyList() {
    if (!this.currencyListSubscription) {
      this.currencyListSubscription = this.giftService.observableCurrencyList.subscribe((currencyList: string[]) => {
        this.currencyList = currencyList;
      });
    }
    
  }

  /**
   * Unwatch currency list
   */
  async unwatchCurrencyList() {
    if (this.currencyListSubscription) {
      this.currencyListSubscription.unsubscribe();
      this.currencyListSubscription = null;
    }
  }

  /**
   * Watch amount
   */
  async watchAmount() {
    if (!this.amountSubscription) {
      this.amountSubscription = this.giftService.observableAmount.subscribe((amount: any) => {
        this.amount = amount;
      });
    }
    
  }

  /**
   * Unwatch amount
   */
  async unwatchAmount() {
    if (this.amountSubscription) {
      this.amountSubscription.unsubscribe();
      this.amountSubscription = null;
    }
  }

  /**
   * Setup filter form
   */
  async setupForm() {
    this.filterForm = this.formBuilder.group({
      giftType: new FormControl('', []),
      currency: new FormControl('', []),
      amount: new FormControl('', []),
      invitedBy: new FormControl('', []),
      category: new FormControl('', []),
      group: new FormControl('', []),
      reserved: new FormControl('', []),
      seating: new FormControl('', []),
      dietaryReq: new FormControl('', []),
      specialReq: new FormControl('', []),
      session: new FormControl('', []),
      mobile: new FormControl('', []),
      email: new FormControl('', []),
      attending: new FormControl('', []),
      createBy: new FormControl('', []),
      updateBy: new FormControl('', []),
      giftBy: new FormControl('', []),
    });
  }

  /**
   * Setup filter form value for edit filter conditions
   */
  async setupFormValue() {
    await this.platform.ready();
    if (this.filter && this.filterForm) {
      if (this.filter.invitedBy) {
        this.filterForm.controls.invitedBy.setValue(this.filter.invitedBy);
      }
      if (this.filter.category) {
        this.filterForm.controls.category.setValue(this.filter.category);
      }
      if (this.filter.group) {
        this.filterForm.controls.group.setValue(this.filter.group);
      }
      if (this.filter.reserved) {
        this.filterForm.controls.reserved.setValue(this.filter.reserved);
      }
      if (this.filter.seating) {
        this.filterForm.controls.seating.setValue(this.filter.seating);
      }
      if (this.filter.dietaryReq) {
        this.filterForm.controls.dietaryReq.setValue(this.filter.dietaryReq);
      }
      if (this.filter.specialReq) {
        this.filterForm.controls.specialReq.setValue(this.filter.specialReq);
      }
      if (this.filter.session) {
        this.filterForm.controls.session.setValue(this.filter.session);
      }
      if (this.filter.mobile) {
        this.filterForm.controls.mobile.setValue(this.filter.mobile);
      }
      if (this.filter.email) {
        this.filterForm.controls.email.setValue(this.filter.email);
      }
      if (this.filter?.status?.attending) {
        this.filterForm.controls.attending.setValue(this.filter.status.attending);
      }
      if (this.filter?.createBy) {
        this.filterForm.controls.createBy.setValue(this.filter.createBy);
      }
      if (this.filter.updateBy) {
        this.filterForm.controls.updateBy.setValue(this.filter.updateBy);
      }
    }
    this.setupSettingField();
  }

  /**
   * Check is form is empty
   * @returns true if form is empty
   */
  isEmptyForm(): boolean {
    let empty = true;
    if (this.filterForm?.value) {
      Object.keys(this.filterForm.value)?.forEach(key => {
        if (key && this.filterForm.value[key] && empty) { empty = false; }
      });
    }
    return empty;
  }

  /**
   * Reset filter and sorting
   */
  reset() {
    this.filterForm.reset();
    this.sorting = '';
    this.desc = true;
  }

  getSeatingTypeName(): string {
    return this.seatingSettingService.getSeatingTypeName();
  }

  /**
   * Present setting field cmodal
   * @param settingFieldType Setting field type
   * @param selected Selected setting field
   */
  async presentSettingFieldModal(settingFieldType: SettingFieldType, selected: SettingField[]) {
    const modal = await this.modalController.create({
      component: SettingFieldComponent,
      componentProps: {
        settingFieldType,
        selected
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.selected) {
        if (settingFieldType === 'category') {
          if (this.filterForm.value.category !== result.data.selected) {
            this.filterForm.controls.category.setValue(result.data.selected);
            this.setupSettingField();
          }
        } else if (settingFieldType === 'dietary_req') {
          if (this.filterForm.value.dietaryReq !== result.data.selected) {
            this.filterForm.controls.dietaryReq.setValue(result.data.selected);
            this.setupSettingField();
          }
        } else if (settingFieldType === 'special_req') {
          if (this.filterForm.value.specialReq !== result.data.selected) {
            this.filterForm.controls.specialReq.setValue(result.data.selected);
            this.setupSettingField();
          }
        } else if (settingFieldType === 'session') {
          if (this.filterForm.value.session !== result.data.selected) {
            this.filterForm.controls.session.setValue(result.data.selected);
            this.setupSettingField();
          }
        }
      }
    });
  }

  /**
   * Dismiss current modal
   * @param filter Apply filter here
   */
  async dismissModal(filter?: any) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) {
        this.modalController.dismiss({
          filter,
          sorting: this.sorting,
          desc: this.desc
        });
      }
    }
  }

  /**
   * Present account user modal
   * @param type filter type - created by uid / updated by uid
   */
  async presentAccountUserModal(type: string) {
    if (type) {
      const modal = await this.modalController.create({
        component: AccountUserComponent,
        componentProps: { selectedUid: this.filterForm.value[type] }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result?.data?.uid) {
          this.filterForm.controls[type].setValue(result.data.uid);
        }
      });
    }
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[]): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList);
  }

  /**
   * Get user name by uid
   * @param uid User ID
   * @returns User name
   */
  getUserName(uid: string): string {
    if (uid) {
      return this.accountUserService.getUserByUid(uid)?.name;
    } else {
      return null;
    }
  }

  /**
   * Filter form submit.
   * Mark form as touched and check if is valid.
   * Set filter enable if empty is not empy.
   */
  filterFormSubmit() {
    this.filterForm.markAllAsTouched();
    if (this.filterForm.valid) {
      const filter = this.filterForm.value;

      if (filter.attending) {
        filter.status = {
          attending: filter.attending
        };
      }

      if (!this.isEmptyForm()) {
        filter.enable = true;
      }

      this.dismissModal(filter);
    }
  }

}
