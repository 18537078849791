import { Currency } from '../interfaces/database';

export const StdCurrencyList: Currency[] = [{
  symbol: '$',
  name: 'US Dollar',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'USD',
  namePlural: 'US dollars',
  label: 'USD - US Dollar'
}, {
  symbol: 'S$',
  name: 'Singapore Dollar',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'SGD',
  namePlural: 'Singapore dollars',
  label: 'SGD - Singapore Dollar'
}, {
  symbol: 'RM',
  name: 'Malaysian Ringgit',
  symbolNative: 'RM',
  decimalDigits: 2,
  rounding: 0.05,
  code: 'MYR',
  namePlural: 'Malaysian ringgits',
  label: 'MYR - Malaysian Ringgit'
}, {
  symbol: 'NT$',
  name: 'New Taiwan Dollar',
  symbolNative: 'NT$',
  decimalDigits: 0,
  rounding: 0,
  code: 'TWD',
  namePlural: 'New Taiwan dollars',
  label: 'TWD - New Taiwan Dollar'
}, {
  symbol: 'HK$',
  name: 'Hong Kong Dollar',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'HKD',
  namePlural: 'Hong Kong dollars',
  label: 'HKD - Hong Kong Dollar'
}];

/**
 * Supported account product currency list
 */
export const AccountProductCurrencyList: Currency[] = [{
  symbol: '$',
  name: 'US Dollar',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'USD',
  namePlural: 'US dollars',
  label: 'USD - US Dollar'
}, {
  symbol: 'S$',
  name: 'Singapore Dollar',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'SGD',
  namePlural: 'Singapore dollars',
  label: 'SGD - Singapore Dollar'
}, {
  symbol: 'RM',
  name: 'Malaysian Ringgit',
  symbolNative: 'RM',
  decimalDigits: 2,
  rounding: 0.05,
  code: 'MYR',
  namePlural: 'Malaysian ringgits',
  label: 'MYR - Malaysian Ringgit'
}, {
  symbol: 'NT$',
  name: 'New Taiwan Dollar',
  symbolNative: 'NT$',
  decimalDigits: 0,
  rounding: 0,
  code: 'TWD',
  namePlural: 'New Taiwan dollars',
  label: 'TWD - New Taiwan Dollar'
}, {
  symbol: 'HK$',
  name: 'Hong Kong Dollar',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'HKD',
  namePlural: 'Hong Kong dollars',
  label: 'HKD - Hong Kong Dollar'
}];

/**
 * Preloaded currency list database
 * https://gist.github.com/soubhikchatterjee/7972a069d478acd891c9ab27b87a87e2
 */
export const CurrencyList: Currency[] = [{
  symbol: '$',
  name: 'US Dollar',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'USD',
  namePlural: 'US dollars',
  label: 'USD - US Dollar'
}, {
  symbol: 'AED',
  name: 'United Arab Emirates Dirham',
  symbolNative: 'د.إ.‏',
  decimalDigits: 2,
  rounding: 0,
  code: 'AED',
  namePlural: 'UAE dirhams',
  label: 'AED - United Arab Emirates Dirham'
}, {
  symbol: 'Af',
  name: 'Afghan Afghani',
  symbolNative: '؋',
  decimalDigits: 2,
  rounding: 0,
  code: 'AFN',
  namePlural: 'Afghan Afghanis',
  label: 'AFN - Afghan Afghani'
}, {
  symbol: 'ALL',
  name: 'Albanian Lek',
  symbolNative: 'Lek',
  decimalDigits: 2,
  rounding: 0,
  code: 'ALL',
  namePlural: 'Albanian lekë',
  label: 'ALL - Albanian Lek'
}, {
  symbol: 'AMD',
  name: 'Armenian Dram',
  symbolNative: 'դր.',
  decimalDigits: 2,
  rounding: 0,
  code: 'AMD',
  namePlural: 'Armenian drams',
  label: 'AMD - Armenian Dram'
}, {
  symbol: 'AR$',
  name: 'Argentine Peso',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'ARS',
  namePlural: 'Argentine pesos',
  label: 'ARS - Argentine Peso'
}, {
  symbol: 'AU$',
  name: 'Australian Dollar',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'AUD',
  namePlural: 'Australian dollars',
  label: 'AUD - Australian Dollar'
}, {
  symbol: 'man.',
  name: 'Azerbaijani Manat',
  symbolNative: 'ман.',
  decimalDigits: 2,
  rounding: 0,
  code: 'AZN',
  namePlural: 'Azerbaijani manats',
  label: 'AZN - Azerbaijani Manat'
}, {
  symbol: 'KM',
  name: 'Bosnia-Herzegovina Convertible Mark',
  symbolNative: 'KM',
  decimalDigits: 2,
  rounding: 0,
  code: 'BAM',
  namePlural: 'Bosnia-Herzegovina convertible marks',
  label: 'BAM - Bosnia-Herzegovina Convertible Mark'
}, {
  symbol: 'Tk',
  name: 'Bangladeshi Taka',
  symbolNative: '৳',
  decimalDigits: 2,
  rounding: 0,
  code: 'BDT',
  namePlural: 'Bangladeshi takas',
  label: 'BDT - Bangladeshi Taka'
}, {
  symbol: 'BGN',
  name: 'Bulgarian Lev',
  symbolNative: 'лв.',
  decimalDigits: 2,
  rounding: 0,
  code: 'BGN',
  namePlural: 'Bulgarian leva',
  label: 'BGN - Bulgarian Lev'
}, {
  symbol: 'BD',
  name: 'Bahraini Dinar',
  symbolNative: 'د.ب.‏',
  decimalDigits: 3,
  rounding: 0,
  code: 'BHD',
  namePlural: 'Bahraini dinars',
  label: 'BHD - Bahraini Dinar'
}, {
  symbol: 'FBu',
  name: 'Burundian Franc',
  symbolNative: 'FBu',
  decimalDigits: 0,
  rounding: 0,
  code: 'BIF',
  namePlural: 'Burundian francs',
  label: 'BIF - Burundian Franc'
}, {
  symbol: 'BN$',
  name: 'Brunei Dollar',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'BND',
  namePlural: 'Brunei dollars',
  label: 'BND - Brunei Dollar'
}, {
  symbol: 'Bs',
  name: 'Bolivian Boliviano',
  symbolNative: 'Bs',
  decimalDigits: 2,
  rounding: 0,
  code: 'BOB',
  namePlural: 'Bolivian bolivianos',
  label: 'BOB - Bolivian Boliviano'
}, {
  symbol: 'R$',
  name: 'Brazilian Real',
  symbolNative: 'R$',
  decimalDigits: 2,
  rounding: 0,
  code: 'BRL',
  namePlural: 'Brazilian reals',
  label: 'BRL - Brazilian Real'
}, {
  symbol: 'BWP',
  name: 'Botswanan Pula',
  symbolNative: 'P',
  decimalDigits: 2,
  rounding: 0,
  code: 'BWP',
  namePlural: 'Botswanan pulas',
  label: 'BWP - Botswanan Pula'
}, {
  symbol: 'Br',
  name: 'Belarusian Ruble',
  symbolNative: 'руб.',
  decimalDigits: 2,
  rounding: 0,
  code: 'BYN',
  namePlural: 'Belarusian rubles',
  label: 'BYN - Belarusian Ruble'
}, {
  symbol: 'BZ$',
  name: 'Belize Dollar',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'BZD',
  namePlural: 'Belize dollars',
  label: 'BZD - Belize Dollar'
}, {
  symbol: 'CA$',
  name: 'Canadian Dollar',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'CAD',
  namePlural: 'Canadian dollars',
  label: 'CAD - Canadian Dollar'
}, {
  symbol: 'CDF',
  name: 'Congolese Franc',
  symbolNative: 'FrCD',
  decimalDigits: 2,
  rounding: 0,
  code: 'CDF',
  namePlural: 'Congolese francs',
  label: 'CDF - Congolese Franc'
}, {
  symbol: 'CHF',
  name: 'Swiss Franc',
  symbolNative: 'CHF',
  decimalDigits: 2,
  rounding: 0.05,
  code: 'CHF',
  namePlural: 'Swiss francs',
  label: 'CHF - Swiss Franc'
}, {
  symbol: 'CL$',
  name: 'Chilean Peso',
  symbolNative: '$',
  decimalDigits: 0,
  rounding: 0,
  code: 'CLP',
  namePlural: 'Chilean pesos',
  label: 'CLP - Chilean Peso'
}, {
  symbol: 'CN¥',
  name: 'Chinese Yuan',
  symbolNative: 'CN¥',
  decimalDigits: 2,
  rounding: 0,
  code: 'CNY',
  namePlural: 'Chinese yuan',
  label: 'CNY - Chinese Yuan'
}, {
  symbol: 'CO$',
  name: 'Colombian Peso',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'COP',
  namePlural: 'Colombian pesos',
  label: 'COP - Colombian Peso'
}, {
  symbol: '₡',
  name: 'Costa Rican Colón',
  symbolNative: '₡',
  decimalDigits: 2,
  rounding: 0,
  code: 'CRC',
  namePlural: 'Costa Rican colóns',
  label: 'CRC - Costa Rican Colón'
}, {
  symbol: 'CV$',
  name: 'Cape Verdean Escudo',
  symbolNative: 'CV$',
  decimalDigits: 2,
  rounding: 0,
  code: 'CVE',
  namePlural: 'Cape Verdean escudos',
  label: 'CVE - Cape Verdean Escudo'
}, {
  symbol: 'Kč',
  name: 'Czech Republic Koruna',
  symbolNative: 'Kč',
  decimalDigits: 2,
  rounding: 0,
  code: 'CZK',
  namePlural: 'Czech Republic korunas',
  label: 'CZK - Czech Republic Koruna'
}, {
  symbol: 'Fdj',
  name: 'Djiboutian Franc',
  symbolNative: 'Fdj',
  decimalDigits: 0,
  rounding: 0,
  code: 'DJF',
  namePlural: 'Djiboutian francs',
  label: 'DJF - Djiboutian Franc'
}, {
  symbol: 'Dkr',
  name: 'Danish Krone',
  symbolNative: 'kr',
  decimalDigits: 2,
  rounding: 0,
  code: 'DKK',
  namePlural: 'Danish kroner',
  label: 'DKK - Danish Krone'
}, {
  symbol: 'RD$',
  name: 'Dominican Peso',
  symbolNative: 'RD$',
  decimalDigits: 2,
  rounding: 0,
  code: 'DOP',
  namePlural: 'Dominican pesos',
  label: 'DOP - Dominican Peso'
}, {
  symbol: 'DA',
  name: 'Algerian Dinar',
  symbolNative: 'د.ج.‏',
  decimalDigits: 2,
  rounding: 0,
  code: 'DZD',
  namePlural: 'Algerian dinars',
  label: 'DZD - Algerian Dinar'
}, {
  symbol: 'Ekr',
  name: 'Estonian Kroon',
  symbolNative: 'kr',
  decimalDigits: 2,
  rounding: 0,
  code: 'EEK',
  namePlural: 'Estonian kroons',
  label: 'EEK - Estonian Kroon'
}, {
  symbol: 'EGP',
  name: 'Egyptian Pound',
  symbolNative: 'ج.م.‏',
  decimalDigits: 2,
  rounding: 0,
  code: 'EGP',
  namePlural: 'Egyptian pounds',
  label: 'EGP - Egyptian Pound'
}, {
  symbol: 'Nfk',
  name: 'Eritrean Nakfa',
  symbolNative: 'Nfk',
  decimalDigits: 2,
  rounding: 0,
  code: 'ERN',
  namePlural: 'Eritrean nakfas',
  label: 'ERN - Eritrean Nakfa'
}, {
  symbol: 'Br',
  name: 'Ethiopian Birr',
  symbolNative: 'Br',
  decimalDigits: 2,
  rounding: 0,
  code: 'ETB',
  namePlural: 'Ethiopian birrs',
  label: 'ETB - Ethiopian Birr'
}, {
  symbol: '€',
  name: 'Euro',
  symbolNative: '€',
  decimalDigits: 2,
  rounding: 0,
  code: 'EUR',
  namePlural: 'euros',
  label: 'EUR - Euro'
}, {
  symbol: '£',
  name: 'British Pound Sterling',
  symbolNative: '£',
  decimalDigits: 2,
  rounding: 0,
  code: 'GBP',
  namePlural: 'British pounds sterling',
  label: 'GBP - British Pound Sterling'
}, {
  symbol: 'GEL',
  name: 'Georgian Lari',
  symbolNative: 'GEL',
  decimalDigits: 2,
  rounding: 0,
  code: 'GEL',
  namePlural: 'Georgian laris',
  label: 'GEL - Georgian Lari'
}, {
  symbol: 'GH₵',
  name: 'Ghanaian Cedi',
  symbolNative: 'GH₵',
  decimalDigits: 2,
  rounding: 0,
  code: 'GHS',
  namePlural: 'Ghanaian cedis',
  label: 'GHS - Ghanaian Cedi'
}, {
  symbol: 'FG',
  name: 'Guinean Franc',
  symbolNative: 'FG',
  decimalDigits: 0,
  rounding: 0,
  code: 'GNF',
  namePlural: 'Guinean francs',
  label: 'GNF - Guinean Franc'
}, {
  symbol: 'GTQ',
  name: 'Guatemalan Quetzal',
  symbolNative: 'Q',
  decimalDigits: 2,
  rounding: 0,
  code: 'GTQ',
  namePlural: 'Guatemalan quetzals',
  label: 'GTQ - Guatemalan Quetzal'
}, {
  symbol: 'HK$',
  name: 'Hong Kong Dollar',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'HKD',
  namePlural: 'Hong Kong dollars',
  label: 'HKD - Hong Kong Dollar'
}, {
  symbol: 'HNL',
  name: 'Honduran Lempira',
  symbolNative: 'L',
  decimalDigits: 2,
  rounding: 0,
  code: 'HNL',
  namePlural: 'Honduran lempiras',
  label: 'HNL - Honduran Lempira'
}, {
  symbol: 'kn',
  name: 'Croatian Kuna',
  symbolNative: 'kn',
  decimalDigits: 2,
  rounding: 0,
  code: 'HRK',
  namePlural: 'Croatian kunas',
  label: 'HRK - Croatian Kuna'
}, {
  symbol: 'Ft',
  name: 'Hungarian Forint',
  symbolNative: 'Ft',
  decimalDigits: 2,
  rounding: 0,
  code: 'HUF',
  namePlural: 'Hungarian forints',
  label: 'HUF - Hungarian Forint'
}, {
  symbol: 'Rp',
  name: 'Indonesian Rupiah',
  symbolNative: 'Rp',
  decimalDigits: 0,
  rounding: 0,
  code: 'IDR',
  namePlural: 'Indonesian rupiahs',
  label: 'IDR - Indonesian Rupiah'
}, {
  symbol: '₪',
  name: 'Israeli New Sheqel',
  symbolNative: '₪',
  decimalDigits: 2,
  rounding: 0,
  code: 'ILS',
  namePlural: 'Israeli new sheqels',
  label: 'ILS - Israeli New Sheqel'
}, {
  symbol: 'Rs',
  name: 'Indian Rupee',
  symbolNative: 'টকা',
  decimalDigits: 2,
  rounding: 0,
  code: 'INR',
  namePlural: 'Indian rupees',
  label: 'INR - Indian Rupee'
}, {
  symbol: 'IQD',
  name: 'Iraqi Dinar',
  symbolNative: 'د.ع.‏',
  decimalDigits: 0,
  rounding: 0,
  code: 'IQD',
  namePlural: 'Iraqi dinars',
  label: 'IQD - Iraqi Dinar'
}, {
  symbol: 'IRR',
  name: 'Iranian Rial',
  symbolNative: '﷼',
  decimalDigits: 0,
  rounding: 0,
  code: 'IRR',
  namePlural: 'Iranian rials',
  label: 'IRR - Iranian Rial'
}, {
  symbol: 'Ikr',
  name: 'Icelandic Króna',
  symbolNative: 'kr',
  decimalDigits: 0,
  rounding: 0,
  code: 'ISK',
  namePlural: 'Icelandic krónur',
  label: 'ISK - Icelandic Króna'
}, {
  symbol: 'J$',
  name: 'Jamaican Dollar',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'JMD',
  namePlural: 'Jamaican dollars',
  label: 'JMD - Jamaican Dollar'
}, {
  symbol: 'JD',
  name: 'Jordanian Dinar',
  symbolNative: 'د.أ.‏',
  decimalDigits: 3,
  rounding: 0,
  code: 'JOD',
  namePlural: 'Jordanian dinars',
  label: 'JOD - Jordanian Dinar'
}, {
  symbol: '¥',
  name: 'Japanese Yen',
  symbolNative: '￥',
  decimalDigits: 0,
  rounding: 0,
  code: 'JPY',
  namePlural: 'Japanese yen',
  label: 'JPY - Japanese Yen'
}, {
  symbol: 'Ksh',
  name: 'Kenyan Shilling',
  symbolNative: 'Ksh',
  decimalDigits: 2,
  rounding: 0,
  code: 'KES',
  namePlural: 'Kenyan shillings',
  label: 'KES - Kenyan Shilling'
}, {
  symbol: 'KHR',
  name: 'Cambodian Riel',
  symbolNative: '៛',
  decimalDigits: 2,
  rounding: 0,
  code: 'KHR',
  namePlural: 'Cambodian riels',
  label: 'KHR - Cambodian Riel'
}, {
  symbol: 'CF',
  name: 'Comorian Franc',
  symbolNative: 'FC',
  decimalDigits: 0,
  rounding: 0,
  code: 'KMF',
  namePlural: 'Comorian francs',
  label: 'KMF - Comorian Franc'
}, {
  symbol: '₩',
  name: 'South Korean Won',
  symbolNative: '₩',
  decimalDigits: 0,
  rounding: 0,
  code: 'KRW',
  namePlural: 'South Korean won',
  label: 'KRW - South Korean Won'
}, {
  symbol: 'KD',
  name: 'Kuwaiti Dinar',
  symbolNative: 'د.ك.‏',
  decimalDigits: 3,
  rounding: 0,
  code: 'KWD',
  namePlural: 'Kuwaiti dinars',
  label: 'KWD - Kuwaiti Dinar'
}, {
  symbol: 'KZT',
  name: 'Kazakhstani Tenge',
  symbolNative: 'тңг.',
  decimalDigits: 2,
  rounding: 0,
  code: 'KZT',
  namePlural: 'Kazakhstani tenges',
  label: 'KZT - Kazakhstani Tenge'
}, {
  symbol: 'LB£',
  name: 'Lebanese Pound',
  symbolNative: 'ل.ل.‏',
  decimalDigits: 0,
  rounding: 0,
  code: 'LBP',
  namePlural: 'Lebanese pounds',
  label: 'LBP - Lebanese Pound'
}, {
  symbol: 'SLRs',
  name: 'Sri Lankan Rupee',
  symbolNative: 'SL Re',
  decimalDigits: 2,
  rounding: 0,
  code: 'LKR',
  namePlural: 'Sri Lankan rupees',
  label: 'LKR - Sri Lankan Rupee'
}, {
  symbol: 'Lt',
  name: 'Lithuanian Litas',
  symbolNative: 'Lt',
  decimalDigits: 2,
  rounding: 0,
  code: 'LTL',
  namePlural: 'Lithuanian litai',
  label: 'LTL - Lithuanian Litas'
}, {
  symbol: 'Ls',
  name: 'Latvian Lats',
  symbolNative: 'Ls',
  decimalDigits: 2,
  rounding: 0,
  code: 'LVL',
  namePlural: 'Latvian lati',
  label: 'LVL - Latvian Lats'
}, {
  symbol: 'LD',
  name: 'Libyan Dinar',
  symbolNative: 'د.ل.‏',
  decimalDigits: 3,
  rounding: 0,
  code: 'LYD',
  namePlural: 'Libyan dinars',
  label: 'LYD - Libyan Dinar'
}, {
  symbol: 'MAD',
  name: 'Moroccan Dirham',
  symbolNative: 'د.م.‏',
  decimalDigits: 2,
  rounding: 0,
  code: 'MAD',
  namePlural: 'Moroccan dirhams',
  label: 'MAD - Moroccan Dirham'
}, {
  symbol: 'MDL',
  name: 'Moldovan Leu',
  symbolNative: 'MDL',
  decimalDigits: 2,
  rounding: 0,
  code: 'MDL',
  namePlural: 'Moldovan lei',
  label: 'MDL - Moldovan Leu'
}, {
  symbol: 'MGA',
  name: 'Malagasy Ariary',
  symbolNative: 'MGA',
  decimalDigits: 0,
  rounding: 0,
  code: 'MGA',
  namePlural: 'Malagasy Ariaries',
  label: 'MGA - Malagasy Ariary'
}, {
  symbol: 'MKD',
  name: 'Macedonian Denar',
  symbolNative: 'MKD',
  decimalDigits: 2,
  rounding: 0,
  code: 'MKD',
  namePlural: 'Macedonian denari',
  label: 'MKD - Macedonian Denar'
}, {
  symbol: 'MMK',
  name: 'Myanma Kyat',
  symbolNative: 'K',
  decimalDigits: 0,
  rounding: 0,
  code: 'MMK',
  namePlural: 'Myanma kyats',
  label: 'MMK - Myanma Kyat'
}, {
  symbol: 'MOP$',
  name: 'Macanese Pataca',
  symbolNative: 'MOP$',
  decimalDigits: 2,
  rounding: 0,
  code: 'MOP',
  namePlural: 'Macanese patacas',
  label: 'MOP - Macanese Pataca'
}, {
  symbol: 'MURs',
  name: 'Mauritian Rupee',
  symbolNative: 'MURs',
  decimalDigits: 0,
  rounding: 0,
  code: 'MUR',
  namePlural: 'Mauritian rupees',
  label: 'MUR - Mauritian Rupee'
}, {
  symbol: 'MX$',
  name: 'Mexican Peso',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'MXN',
  namePlural: 'Mexican pesos',
  label: 'MXN - Mexican Peso'
}, {
  symbol: 'RM',
  name: 'Malaysian Ringgit',
  symbolNative: 'RM',
  decimalDigits: 2,
  rounding: 0.05,
  code: 'MYR',
  namePlural: 'Malaysian ringgits',
  label: 'MYR - Malaysian Ringgit'
}, {
  symbol: 'MTn',
  name: 'Mozambican Metical',
  symbolNative: 'MTn',
  decimalDigits: 2,
  rounding: 0,
  code: 'MZN',
  namePlural: 'Mozambican meticals',
  label: 'MZN - Mozambican Metical'
}, {
  symbol: 'N$',
  name: 'Namibian Dollar',
  symbolNative: 'N$',
  decimalDigits: 2,
  rounding: 0,
  code: 'NAD',
  namePlural: 'Namibian dollars',
  label: 'NAD - Namibian Dollar'
}, {
  symbol: '₦',
  name: 'Nigerian Naira',
  symbolNative: '₦',
  decimalDigits: 2,
  rounding: 0,
  code: 'NGN',
  namePlural: 'Nigerian nairas',
  label: 'NGN - Nigerian Naira'
}, {
  symbol: 'C$',
  name: 'Nicaraguan Córdoba',
  symbolNative: 'C$',
  decimalDigits: 2,
  rounding: 0,
  code: 'NIO',
  namePlural: 'Nicaraguan córdobas',
  label: 'NIO - Nicaraguan Córdoba'
}, {
  symbol: 'Nkr',
  name: 'Norwegian Krone',
  symbolNative: 'kr',
  decimalDigits: 2,
  rounding: 0,
  code: 'NOK',
  namePlural: 'Norwegian kroner',
  label: 'NOK - Norwegian Krone'
}, {
  symbol: 'NPRs',
  name: 'Nepalese Rupee',
  symbolNative: 'नेरू',
  decimalDigits: 2,
  rounding: 0,
  code: 'NPR',
  namePlural: 'Nepalese rupees',
  label: 'NPR - Nepalese Rupee'
}, {
  symbol: 'NZ$',
  name: 'New Zealand Dollar',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'NZD',
  namePlural: 'New Zealand dollars',
  label: 'NZD - New Zealand Dollar'
}, {
  symbol: 'OMR',
  name: 'Omani Rial',
  symbolNative: 'ر.ع.‏',
  decimalDigits: 3,
  rounding: 0,
  code: 'OMR',
  namePlural: 'Omani rials',
  label: 'OMR - Omani Rial'
}, {
  symbol: 'B/.',
  name: 'Panamanian Balboa',
  symbolNative: 'B/.',
  decimalDigits: 2,
  rounding: 0,
  code: 'PAB',
  namePlural: 'Panamanian balboas',
  label: 'PAB - Panamanian Balboa'
}, {
  symbol: 'S/.',
  name: 'Peruvian Nuevo Sol',
  symbolNative: 'S/.',
  decimalDigits: 2,
  rounding: 0,
  code: 'PEN',
  namePlural: 'Peruvian nuevos soles',
  label: 'PEN - Peruvian Nuevo Sol'
}, {
  symbol: '₱',
  name: 'Philippine Peso',
  symbolNative: '₱',
  decimalDigits: 2,
  rounding: 0,
  code: 'PHP',
  namePlural: 'Philippine pesos',
  label: 'PHP - Philippine Peso'
}, {
  symbol: 'PKRs',
  name: 'Pakistani Rupee',
  symbolNative: '₨',
  decimalDigits: 0,
  rounding: 0,
  code: 'PKR',
  namePlural: 'Pakistani rupees',
  label: 'PKR - Pakistani Rupee'
}, {
  symbol: 'zł',
  name: 'Polish Zloty',
  symbolNative: 'zł',
  decimalDigits: 2,
  rounding: 0,
  code: 'PLN',
  namePlural: 'Polish zlotys',
  label: 'PLN - Polish Zloty'
}, {
  symbol: '₲',
  name: 'Paraguayan Guarani',
  symbolNative: '₲',
  decimalDigits: 0,
  rounding: 0,
  code: 'PYG',
  namePlural: 'Paraguayan guaranis',
  label: 'PYG - Paraguayan Guarani'
}, {
  symbol: 'QR',
  name: 'Qatari Rial',
  symbolNative: 'ر.ق.‏',
  decimalDigits: 2,
  rounding: 0,
  code: 'QAR',
  namePlural: 'Qatari rials',
  label: 'QAR - Qatari Rial'
}, {
  symbol: 'RON',
  name: 'Romanian Leu',
  symbolNative: 'RON',
  decimalDigits: 2,
  rounding: 0,
  code: 'RON',
  namePlural: 'Romanian lei',
  label: 'RON - Romanian Leu'
}, {
  symbol: 'din.',
  name: 'Serbian Dinar',
  symbolNative: 'дин.',
  decimalDigits: 0,
  rounding: 0,
  code: 'RSD',
  namePlural: 'Serbian dinars',
  label: 'RSD - Serbian Dinar'
}, {
  symbol: 'RUB',
  name: 'Russian Ruble',
  symbolNative: '₽.',
  decimalDigits: 2,
  rounding: 0,
  code: 'RUB',
  namePlural: 'Russian rubles',
  label: 'RUB - Russian Ruble'
}, {
  symbol: 'RWF',
  name: 'Rwandan Franc',
  symbolNative: 'FR',
  decimalDigits: 0,
  rounding: 0,
  code: 'RWF',
  namePlural: 'Rwandan francs',
  label: 'RWF - Rwandan Franc'
}, {
  symbol: 'SR',
  name: 'Saudi Riyal',
  symbolNative: 'ر.س.‏',
  decimalDigits: 2,
  rounding: 0,
  code: 'SAR',
  namePlural: 'Saudi riyals',
  label: 'SAR - Saudi Riyal'
}, {
  symbol: 'SDG',
  name: 'Sudanese Pound',
  symbolNative: 'SDG',
  decimalDigits: 2,
  rounding: 0,
  code: 'SDG',
  namePlural: 'Sudanese pounds',
  label: 'SDG - Sudanese Pound'
}, {
  symbol: 'Skr',
  name: 'Swedish Krona',
  symbolNative: 'kr',
  decimalDigits: 2,
  rounding: 0,
  code: 'SEK',
  namePlural: 'Swedish kronor',
  label: 'SEK - Swedish Krona'
}, {
  symbol: 'S$',
  name: 'Singapore Dollar',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'SGD',
  namePlural: 'Singapore dollars',
  label: 'SGD - Singapore Dollar'
}, {
  symbol: 'Ssh',
  name: 'Somali Shilling',
  symbolNative: 'Ssh',
  decimalDigits: 0,
  rounding: 0,
  code: 'SOS',
  namePlural: 'Somali shillings',
  label: 'SOS - Somali Shilling'
}, {
  symbol: 'SY£',
  name: 'Syrian Pound',
  symbolNative: 'ل.س.‏',
  decimalDigits: 0,
  rounding: 0,
  code: 'SYP',
  namePlural: 'Syrian pounds',
  label: 'SYP - Syrian Pound'
}, {
  symbol: '฿',
  name: 'Thai Baht',
  symbolNative: '฿',
  decimalDigits: 2,
  rounding: 0,
  code: 'THB',
  namePlural: 'Thai baht',
  label: 'THB - Thai Baht'
}, {
  symbol: 'DT',
  name: 'Tunisian Dinar',
  symbolNative: 'د.ت.‏',
  decimalDigits: 3,
  rounding: 0,
  code: 'TND',
  namePlural: 'Tunisian dinars',
  label: 'TND - Tunisian Dinar'
}, {
  symbol: 'T$',
  name: 'Tongan Paʻanga',
  symbolNative: 'T$',
  decimalDigits: 2,
  rounding: 0,
  code: 'TOP',
  namePlural: 'Tongan paʻanga',
  label: 'TOP - Tongan Paʻanga'
}, {
  symbol: 'TL',
  name: 'Turkish Lira',
  symbolNative: 'TL',
  decimalDigits: 2,
  rounding: 0,
  code: 'TRY',
  namePlural: 'Turkish Lira',
  label: 'TRY - Turkish Lira'
}, {
  symbol: 'TT$',
  name: 'Trinidad and Tobago Dollar',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'TTD',
  namePlural: 'Trinidad and Tobago dollars',
  label: 'TTD - Trinidad and Tobago Dollar'
}, {
  symbol: 'NT$',
  name: 'New Taiwan Dollar',
  symbolNative: 'NT$',
  decimalDigits: 0,
  rounding: 0,
  code: 'TWD',
  namePlural: 'New Taiwan dollars',
  label: 'TWD - New Taiwan Dollar'
}, {
  symbol: 'TSh',
  name: 'Tanzanian Shilling',
  symbolNative: 'TSh',
  decimalDigits: 0,
  rounding: 0,
  code: 'TZS',
  namePlural: 'Tanzanian shillings',
  label: 'TZS - Tanzanian Shilling'
}, {
  symbol: '₴',
  name: 'Ukrainian Hryvnia',
  symbolNative: '₴',
  decimalDigits: 2,
  rounding: 0,
  code: 'UAH',
  namePlural: 'Ukrainian hryvnias',
  label: 'UAH - Ukrainian Hryvnia'
}, {
  symbol: 'USh',
  name: 'Ugandan Shilling',
  symbolNative: 'USh',
  decimalDigits: 0,
  rounding: 0,
  code: 'UGX',
  namePlural: 'Ugandan shillings',
  label: 'UGX - Ugandan Shilling'
}, {
  symbol: '$U',
  name: 'Uruguayan Peso',
  symbolNative: '$',
  decimalDigits: 2,
  rounding: 0,
  code: 'UYU',
  namePlural: 'Uruguayan pesos',
  label: 'UYU - Uruguayan Peso'
}, {
  symbol: 'UZS',
  name: 'Uzbekistan Som',
  symbolNative: 'UZS',
  decimalDigits: 0,
  rounding: 0,
  code: 'UZS',
  namePlural: 'Uzbekistan som',
  label: 'UZS - Uzbekistan Som'
}, {
  symbol: 'Bs.F.',
  name: 'Venezuelan Bolívar',
  symbolNative: 'Bs.F.',
  decimalDigits: 2,
  rounding: 0,
  code: 'VEF',
  namePlural: 'Venezuelan bolívars',
  label: 'VEF - Venezuelan Bolívar'
}, {
  symbol: '₫',
  name: 'Vietnamese Dong',
  symbolNative: '₫',
  decimalDigits: 0,
  rounding: 0,
  code: 'VND',
  namePlural: 'Vietnamese dong',
  label: 'VND - Vietnamese Dong'
}, {
  symbol: 'FCFA',
  name: 'CFA Franc BEAC',
  symbolNative: 'FCFA',
  decimalDigits: 0,
  rounding: 0,
  code: 'XAF',
  namePlural: 'CFA francs BEAC',
  label: 'XAF - CFA Franc BEAC'
}, {
  symbol: 'CFA',
  name: 'CFA Franc BCEAO',
  symbolNative: 'CFA',
  decimalDigits: 0,
  rounding: 0,
  code: 'XOF',
  namePlural: 'CFA francs BCEAO',
  label: 'XOF - CFA Franc BCEAO'
}, {
  symbol: 'YR',
  name: 'Yemeni Rial',
  symbolNative: 'ر.ي.‏',
  decimalDigits: 0,
  rounding: 0,
  code: 'YER',
  namePlural: 'Yemeni rials',
  label: 'YER - Yemeni Rial'
}, {
  symbol: 'R',
  name: 'South African Rand',
  symbolNative: 'R',
  decimalDigits: 2,
  rounding: 0,
  code: 'ZAR',
  namePlural: 'South African rand',
  label: 'ZAR - South African Rand'
}, {
  symbol: 'ZK',
  name: 'Zambian Kwacha',
  symbolNative: 'ZK',
  decimalDigits: 0,
  rounding: 0,
  code: 'ZMK',
  namePlural: 'Zambian kwachas',
  label: 'ZMK - Zambian Kwacha'
}, {
  symbol: 'ZWL$',
  name: 'Zimbabwean Dollar',
  symbolNative: 'ZWL$',
  decimalDigits: 0,
  rounding: 0,
  code: 'ZWL',
  namePlural: 'Zimbabwean Dollar',
  label: 'ZWL - Zimbabwean Dollar'
}];
