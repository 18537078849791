<ion-content>
  <div class="full-height vertical-center">
    <ion-card class="center ion-no-padding">
      <!-- <ion-card-header *ngIf="closeBtn">
        <ion-button (click)="dismissModal(false, true)">
          <ion-icon src="./assets/wedding/icon/general/close.svg"></ion-icon>
        </ion-button>
      </ion-card-header> -->
      <ion-card-content class="ion-no-padding">
        <div class="ion-margin-vertical" *ngIf="!hideIcon">
          <ion-icon class="icon-warning center" color="dark" *ngIf="type === 'logout'" src="./assets/wedding/icon/general/logout.svg"></ion-icon>
          <ion-icon class="icon-warning center" color="dark" *ngIf="type !== 'logout'" src="./assets/wedding/icon/general/warning.svg"></ion-icon>
        </div>
        <div>
          <div class="ion-margin-vertical font-18 font-semi" *ngIf="title" [innerHTML]="title"></div>
          <div class="ion-margin-vertical" *ngIf="subtitle" [innerHTML]="subtitle"></div>
          <div class="ion-margin-vertical ion-text-center font-14" *ngIf="msg" [innerHTML]="msg" (click)="previewList()"></div>
        </div>
        <ion-grid fixed>
          <ion-row class="ion-margin-vertical ion-hide-sm-down"></ion-row>
          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col size-xs="0" size-sm="1"></ion-col>
            <ion-col class="ion-align-self-center ion-text-center">
              <ion-button class="ion-no-margin" expand="block" color="grey" fill="solid" type="button" (click)="cancel()">
                {{ noBtnText ? noBtnText : 'BTN.no' | translate }}
              </ion-button>
            </ion-col>
            <ion-col size-xs="1" size-sm="2"></ion-col>
            <ion-col class="ion-align-self-center center">
              <ion-button class="ion-no-margin" expand="block" [color]="yesBtnColor ? yesBtnColor : 'primary'" fill="solid" type="button" (click)="confirm()">
                {{ yesBtnText ? yesBtnText : 'BTN.yes' | translate }}
              </ion-button>
            </ion-col>
            <ion-col size-xs="0" size-sm="1"></ion-col>
          </ion-row>
          <ion-row *ngIf="cancelBtn">
            <ion-col class="ion-text-center ion-padding-top">
              <ion-text class="font-underline font-12" (click)="dismissModal(false, true)">{{ cancelBtnText ? cancelBtnText : 'BTN.cancel' | translate }}</ion-text>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>