import { Platform } from '@ionic/angular';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Router } from '@angular/router';
import { ErrorService } from './error.service';
import { Subscription } from 'rxjs';

import firebase from 'firebase/compat/app';

// const getDeliveredNotifications = async () => {
//   const notificationList = await PushNotifications.getDeliveredNotifications();
//   console.log('delivered notifications', notificationList);
// }

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService implements OnInit, OnDestroy {

  private afsSubscription: Subscription;
  private msgSubscription: firebase.Unsubscribe;
  
  constructor(
    private router: Router,
    private platform: Platform,
    private afMessaging: AngularFireMessaging,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unwatchPushNotification();
  }

  async watchPushNotification() {
    await this.unwatchPushNotification();
    if (this.platform.is('hybrid')) {
      PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
        console.log('pushNotificationReceived => ', notification);
        // this.checkAction(notification?.data);
      });
      PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
        console.log('pushNotificationActionPerformed => ', action);
        this.checkAction(action?.notification?.data);
      });
    } else {
      if (!this.afsSubscription) {
        this.afsSubscription = this.afMessaging.messages.subscribe((message) => {
          console.log('afMessaging.messages.subscribe => ', message);
          this.checkAction(message?.data);
        });
      }
      
      if (!this.msgSubscription) {
        this.msgSubscription = await this.afMessaging.onMessage((message: any) => {
          console.log('Received message:', message);
          console.log('Data payload:', message.data);
          this.checkAction(message?.data);
        });
      }
    }
  }

  async unwatchPushNotification() {
    if (this.platform.is('hybrid')) {
      PushNotifications.removeAllListeners();
    } else {
      if (this.afsSubscription) {
        this.afsSubscription.unsubscribe();
        this.afsSubscription = null;
      }
      if (this.msgSubscription) {
        this.msgSubscription();
        this.msgSubscription = null;
      }
    }
  }

  async checkNotification() {
    // await this.platform.ready();
    // if (this.platform.is('hybrid')) {
    //   const delivered: DeliveredNotifications = await PushNotifications.getDeliveredNotifications();
    //   console.log('delivered => ', delivered);
    //   if (delivered?.notifications?.length) {
    //     const notificationsList = delivered.notifications;
    //     console.log('notificationsList => ', notificationsList);
    //     notificationsList.forEach((x: PushNotificationSchema) => {
    //       if (x?.id === 'tap') {
  
    //       }
    //     });
    //   }
    // }
  }


  checkAction(data: any) {
    if (data?.clickAction) {
      this.goPage(data.clickAction);
    } else if (data?.click_action) {
      this.goPage(data.click_action);
    } else if (data?.url) {
      this.goPage(data.url);
    } else {
      this.goPage('/inbox/home');
    }
  }

  goPage(url: string) {
    if (url) {
      try {
        this.router.navigate([ url ], { replaceUrl: true });
      } catch (err: any) {
        this.errorService.logError(err);
      }
    }
  }
}
