import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { GroupService } from 'src/app/services/group/group.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { Group } from 'src/app/interfaces/group';

/**
 * Group list service
 */
@Injectable({
  providedIn: 'root'
})
export class GroupListService implements OnInit, OnDestroy {

  /**
   * Constructor
   * @param groupService group service
   * @param functionService function service
   */
  constructor(
    private groupService: GroupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Generate group list based on selected guest list
   * @param selectedGuestList Selected guest list
   */
  generateGroupList(selectedGuestList: string[]): Group[] {
    let groupList: Group[] = this.functionService.cloneDeep(this.groupService.groupList);
    groupList = groupList?.filter((group: Group) => {
      return group?.memberList?.filter((guestId: string) => {
        return selectedGuestList?.indexOf(guestId) === -1 ? false : true;
      }).length ? true : false;
    });
    return groupList;
  }

  /**
   * Generate individual guest list
   * @param selectedGuestList Selected guest list
   */
  generateIndividualGuestList(selectedGuestList: string[]): string[] {
    const guestIdList: string[] = selectedGuestList.filter((guestId: string) => {
      if (!this.groupService.getGroupByGuestId(guestId)) {
        return true;
      } else {
        return false;
      }
    });
    return guestIdList;
  }

  /**
   * Exclude selected group member
   * @param selectedGuestList selected guest list
   * @param selectedGroupIdList selected group list
   * @returns excluded member id list
   */
  excludeSelectedGroupMember(selectedGuestList: string[], selectedGroupIdList: string[]): string[] {
    const guestIdList: string[] = selectedGuestList?.length && selectedGroupIdList?.length ? selectedGuestList.filter((guestId: string) => {
      const group: Group = this.groupService.getGroupByGuestId(guestId);
      if (selectedGroupIdList?.indexOf(group?.groupId) === -1) {
        return true;
      } else {
        return false;
      }
    }) : selectedGuestList;
    return guestIdList;
  }

  getGroupReceivedGift(excludedGroupIdList?: string[]): number {
    let groupList: Group[] = this.groupService.groupList;
    groupList = groupList.filter((group: Group) => {
      if (group?.status?.gift && group?.memberList?.length && group?.groupId
      && (!excludedGroupIdList?.length || excludedGroupIdList.indexOf(group.groupId) === -1)) {
        return true;
      }
      return false;
    });
    return groupList?.length ? groupList.length : 0;
  }
}
