import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { py2zy } from 'src/app/commons/chinese/zhuyin';
const toneMarks = ['', 'ˊ', 'ˇ', '`', '˙'];

@Injectable({
  providedIn: 'root'
})
export class ZhuyinService implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  toPinyin(zhuyin: string, opts?: any, firstLetter?: boolean) {
    let list = firstLetter ? this.splitZhuyinFirstLetter(zhuyin, opts?.everything) : this.splitZhuyin(zhuyin, opts?.everything);
    if (!opts?.everything) {
      list = list.filter(item => typeof item === 'string');
    }
    list = list.map(item => {
      // if (opts.everything && typeof item === 'string') {
      //   return item;
      // } else
      if (typeof item !== 'string') {
        item = item[0];
      }
      const pinyin = this.toPinyinSyllable(item);
      if (opts.numbered) {
        return (opts.everything ? [pinyin] : pinyin);
      } else if (opts?.everything) {
        return [(pinyin)];
      } else {
        return (pinyin);
      }
    });
    return list;
  }

  splitZhuyin = (zhuyin: string, everything = false) => {
    const list = [];
    let index = 0;
    while (index < zhuyin.length) {
      let count = zhuyin.length - index;
      let wordFound = false;
      while (count > 1) {
        let word = zhuyin.substr(index, count);
        if (Object.values(py2zy).includes(word)) { // word found
          wordFound = true;
          if (toneMarks.includes(zhuyin[index + count])) { // tone found after word
            word += zhuyin[index + count];
            count++;
          }
          list.push(everything ? [word] : word);
          index += count - 1;
          break;
        }
        count--;
      }
      if (!wordFound && everything) {
        if (index === 0 || typeof list[list.length - 1] === 'object') {
          list.push(zhuyin[index]);
        } else if (typeof list[list.length - 1] === 'string') {
          list[list.length - 1] += zhuyin[index];
        }
      }
      index++;
    }
    return list;
  }

  splitZhuyinFirstLetter = (zhuyin: string, everything = false) => {
    const list = [];
    let index = 0;
    while (index < zhuyin.length) {
      let count = zhuyin.length - index;
      let wordFound = false;
      while (count > 0) {
        const word = zhuyin.substr(index, count);
        if (Object.values(py2zy).includes(word)) { // word found
          wordFound = true;
          list.push(everything ? [word] : word);
          index += count - 1;
          break;
        }
        count--;
      }
      if (!wordFound && everything) {
        if (index === 0 || typeof list[list.length - 1] === 'object') {
          list.push(zhuyin[index]);
        } else if (typeof list[list.length - 1] === 'string') {
          list[list.length - 1] += zhuyin[index];
        }
      }
      index++;
    }
    return list;
  }

  toPinyinSyllable(zhuyin: string) {
    let tone = toneMarks.indexOf(zhuyin[zhuyin.length - 1]) + 1;
    if (tone > 0) {
      zhuyin = zhuyin.substr(0, zhuyin.length - 1);
    }
    else {
        tone = 1;
    }
    const pinyinIndex = Object.values(py2zy).indexOf(zhuyin);
    if (pinyinIndex > -1) {
      return Object.keys(py2zy)[pinyinIndex];
    }
    else {
      return zhuyin;
    }
  }
}
