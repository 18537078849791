import { ErrorService } from 'src/app/services/general/error.service';
import { AppService } from 'src/app/services/general/app.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AngularFireRemoteConfig, Parameter } from '@angular/fire/compat/remote-config';

import { FunctionService } from 'src/app/services/general/function.service';
import { Subscription } from 'rxjs';
// import { FirebaseRemoteConfig } from '@joinflux/firebase-remote-config';
// import { environment } from 'src/environments/environment';

/**
 * Firebase remote config service.
 * Get the system config setting.
 */
@Injectable({
  providedIn: 'root'
})
export class ConfigService implements OnInit, OnDestroy {

  appVersion = '1.0.0';
  /**
   * Trial setting
   */
  trial = {
    trial_new: 1,
    trial_join: 1,
    trial_guest: 30
  };

  iosPromoBanner: boolean;

  private configSubscription: Subscription;

  skipAppleIdName: boolean;

  /**
   * Constructor
   * @param platform Platform
   * @param remoteConfig Remote Config
   */
  constructor(
    private platform: Platform,
    private remoteConfig: AngularFireRemoteConfig,
    private appService: AppService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unwatchConfig();
  }

  /**
   * Initialize
   */
  async initialize() {
    await this.platform.ready();
    this.watchConfig();

    // await FirebaseRemoteConfig.initializeFirebase(environment.firebase);
    // await FirebaseRemoteConfig.initialize({
    //   minimumFetchIntervalInSeconds: 3600,
    // });

    // await FirebaseRemoteConfig.fetchAndActivate();

    // const result = await FirebaseRemoteConfig.getString({
    //   key: 'trial_new',
    // });
  }

  /**
   * Watch Config
   */
  async watchConfig() {
    this.iosPromoBanner = false;
    const setting = await this.remoteConfig.settings;
    setting.minimumFetchIntervalMillis = 60000;
    setting.fetchTimeoutMillis = 60000;
    await this.remoteConfig.fetchAndActivate();
    if (!this.configSubscription) {
      this.configSubscription = this.remoteConfig.parameters.subscribe({
        next: (param: Parameter[]) => {
          if (param?.length) {
            this.setupTrial(param);
            this.setupVersion(param);
            this.setupSkipAppleIdName(param);
            // this.setupIosBanner(param);
          }
        }, error: (err: any) => {
          this.errorService.logError(err);
        }
      });
    }
    
  }

  async unwatchConfig() {
    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
      this.configSubscription = null;
    }
  }

  /**
   * Setup trial setting
   * @param param Param
   */
  setupTrial(param: Parameter[]) {
    param?.forEach((config: Parameter) => {
      if (config.key === 'trial_guest' && config._value &&
      this.functionService.isNumber(this.functionService.toNumber(config._value))) {
        this.trial.trial_guest = this.functionService.toNumber(config._value);
      } else if (config.key === 'trial_new' && config._value &&
      this.functionService.isNumber(this.functionService.toNumber(config._value))) {
        this.trial.trial_new = this.functionService.toNumber(config._value);
      } else if (config.key === 'trial_join' && config._value &&
      this.functionService.isNumber(this.functionService.toNumber(config._value))) {
        this.trial.trial_join = this.functionService.toNumber(config._value);
      }
    });
  }

  setupVersion(param: Parameter[]) {
    param?.forEach(async (config: Parameter) => {
      if (!this.platform.is('hybrid') || this.platform.is('pwa')) {
        if (config.key === 'min_version_web' && config._value ) {
          this.appService.checkMinVersion(config._value);
        } else if (config.key === 'new_version_web' && config._value) {
          this.appService.checkNewVersion(config._value);
        }
      } else if (this.platform.is('ios')) {
        if (config.key === 'min_version_ios' && config._value ) {
          this.appService.checkMinVersion(config._value);
        } else if (config.key === 'new_version_ios' && config._value) {
          this.appService.checkNewVersion(config._value);
        }
      } else if (this.platform.is('android')) {
        if (await this.appService.checkPlayStore()) {
          if (config.key === 'min_version_playstore' && config._value ) {
            this.appService.checkMinVersion(config._value);
          } else if (config.key === 'new_version_playstore' && config._value) {
            this.appService.checkNewVersion(config._value);
          }
        } else if (await this.appService.checkAppGallery()) {
          if (config.key === 'min_version_appgallery' && config._value ) {
            this.appService.checkMinVersion(config._value);
          } else if (config.key === 'new_version_appgallery' && config._value) {
            this.appService.checkNewVersion(config._value);
          }
        } else {
          if (config.key === 'min_version_playstore' && config._value ) {
            this.appService.checkMinVersion(config._value);
          } else if (config.key === 'new_version_playstore' && config._value) {
            this.appService.checkNewVersion(config._value);
          }
        }
      }
    });
  }

  setupSkipAppleIdName(param: Parameter[]) {
    param?.forEach((config: Parameter) => {
      if (config.key === 'skip_apple_id_name' && config._value) {
        if (config._value === 'true') {
          this.skipAppleIdName = true;
        }
      }
    });
  }

  // setupIosBanner(param: Parameter[]) {
  //   param?.forEach(async (config: Parameter) => {
  //     if (config.key === 'ios_promo_banner' && config._value) {
  //       this.iosPromoBanner = config._value === 'true' ? true : false;
  //     }
  //   });
  // }

}
