<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      <ng-container *ngIf="type === 'invite'">
        {{ 'QRCODE.lbl.share_log_title' | translate }} ({{ 'LIST.group.group' | translate }})
      </ng-container>
      <ng-container *ngIf="type !== 'invite'">
        {{ 'GROUP.lbl.log' | translate }}
      </ng-container>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <div class="full-width-height viewport-div" *ngIf="logList?.length">
    <cdk-virtual-scroll-viewport [itemSize]="120" [minBufferPx]="200" [maxBufferPx]="200" autosize>
      <ion-list class="full-width-height background-transparent ion-padding-bottom" lines="none" #list>
        <span *cdkVirtualFor="let log of logList; trackBy: trackByFn;">
          <span *ngIf="log?.after && log?.type?.length">
            <span *ngFor="let logType of log.type">
              <ion-card class="card-margin ion-padding" *ngIf="logType && ((type === 'checkin' && logType === type) || (type === 'invite' && logType === type) || (type !== 'checkin' && type !== 'invite'))">
                <ng-container *ngIf="log.by">
                  <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.by?.time?.seconds">
                    <ion-icon class="icon-small" src="./assets/wedding/icon/general/time-outline.svg"></ion-icon>
                    {{ format(log.by.time.seconds * 1000, 'do MMM yyyy, EEE h:mm a') }}
                  </ion-item>
                  <ng-container *ngIf="!log?.by?.name">
                    <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.by?.uid && !log?.by?.name && getUserName(log.by.uid) as username">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/person-outline.svg"></ion-icon>
                      <ng-container>
                        {{ username }}
                      </ng-container>
                    </ion-item>
                  </ng-container>
                  <ng-container *ngIf="log?.by?.name">
                    <ion-item class="ion-item-no-padding" lines="none">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/person-outline.svg"></ion-icon>
                      <ng-container *ngIf="log.by.name !== 'import'">
                        {{ log.by.name }}
                      </ng-container>
                      <ng-container *ngIf="log.by.name === 'import'">
                        {{ 'IMPORT.title' | translate }}
                      </ng-container>
                    </ion-item>
                  </ng-container>
                </ng-container>
  
                <ng-container *ngIf="type !== 'checkin' && type !== 'invite'">
                  <ion-item class="ion-item-no-padding" lines="none"  *ngIf="logType === 'new'">
                    <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                    <ion-text [innerHTML]="'LOG.msg.new' | translate: { field: 'GROUP.lbl.guest_group' | translate }"></ion-text>
                  </ion-item>
                  <ion-item class="ion-item-no-padding" lines="none"  *ngIf="logType === 'update'">
                    <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                    <ion-text [innerHTML]="'LOG.msg.update' | translate: { field: 'GROUP.lbl.guest_group' | translate }"></ion-text>
                  </ion-item>
                </ng-container>
                
                <ng-container *ngIf="type !== 'checkin'">
                  <ion-item class="ion-item-no-padding" lines="none" *ngIf="logType === 'invite'">
                    <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                    <ion-text *ngIf="log?.after?.status?.qrcode" [innerHTML]="'LOG.msg.invite' | translate: { field: 'GROUP.lbl.guest_group' | translate }">
                    </ion-text>
                    <ion-text *ngIf="!log?.after?.status?.qrcode" [innerHTML]="'LOG.msg.delete_invite' | translate: { field: 'GROUP.lbl.guest_group' | translate }">
                    </ion-text>
                  </ion-item>
                </ng-container>
  
                <ng-container *ngIf="type !== 'checkin' && type !== 'invite'">
                  <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.after?.giftList?.length || log?.before?.giftList?.length">
                    <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                    <ng-container *ngIf="log?.after?.giftList?.length">
                      <ng-container *ngFor="let giftId of log.after.giftList">
                        <ng-container *ngIf="!log?.before?.giftList?.length || log.before.giftList?.indexOf(giftId) === -1">
                          <div *ngIf="getGiftById(giftId) as gift">
                            <ion-text *ngIf="gift?.giftType === 'cash'" [innerHTML]="'LOG.field.new_gift' | translate: { type: ('LIST.gift.' + gift.giftType | translate), value: gift.amount | currency: gift.currency: 'symbol' }">
                            </ion-text>
                            <ion-text *ngIf="gift?.giftType === 'other'" [innerHTML]="'LOG.field.new_gift' | translate: { type: ('LIST.gift.' + gift.giftType | translate), value: gift.name + ' * ' + gift.qty }">
                            </ion-text>
                          </div>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="log?.before?.giftList?.length">
                      <ng-container *ngFor="let giftId of log.before.giftList">
                        <ng-container *ngIf="!log?.after?.giftList?.length || log.after.giftList?.indexOf(giftId) === -1">
                          <div *ngIf="getGiftById(giftId) as gift">
                            <ion-text *ngIf="gift?.giftType === 'cash'" [innerHTML]="'LOG.field.delete_gift' | translate: { type: ('LIST.gift.' + gift.giftType | translate), value: gift.amount | currency: gift.currency: 'symbol' }">
                            </ion-text>
                            <ion-text *ngIf="gift?.giftType === 'other'" [innerHTML]="'LOG.field.delete_gift' | translate: { type: ('LIST.gift.' + gift.giftType | translate), value: gift.name + ' * ' + gift.qty }">
                            </ion-text>
                          </div>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ion-item>
  
                  <ion-item class="ion-item-no-padding" lines="none"  *ngIf="logType === 'group member'">
                    <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                    <ng-container *ngIf="log?.before.groupId && log.before.groupId !== log?.after?.groupId else memberBlock">
                      <ng-container *ngIf="log?.after?.groupId">
                        <ion-text *ngIf="log?.after?.groupId === groupId" [innerHTML]="'LOG.field.update_group_member_from' | translate: { guest: log?.after?.memberDetail?.name, group: getGroupName(log.before.groupId) }"></ion-text>
                        <ion-text *ngIf="log?.after?.groupId !== groupId" [innerHTML]="'LOG.field.update_group_member_to' | translate: { guest: log?.after?.memberDetail?.name, group: getGroupName(log.after.groupId) }"></ion-text>
                      </ng-container>
                      <ion-text *ngIf="!log?.after?.groupId" [innerHTML]="'LOG.field.unassign_group_member' | translate: { guest: log?.before?.memberDetail?.name }"></ion-text>
                    </ng-container>
                    <ng-template #memberBlock>
                      <ion-text *ngIf="!log?.after?.memberDetail?.deleted && !log?.before?.memberDetail?.deleted" [innerHTML]="'LOG.field.new_group_member' | translate: { guest: log?.after?.memberDetail?.name }"></ion-text>
                      <ion-text *ngIf="log?.after?.memberDetail?.deleted && !log?.before?.memberDetail?.deleted" [innerHTML]="'LOG.field.remove_group_member' | translate: { guest: log?.after?.memberDetail?.name }"></ion-text>
                      <ion-text *ngIf="!log?.after?.memberDetail?.deleted && log?.before?.memberDetail?.deleted" [innerHTML]="'LOG.field.restore_group_member' | translate: { guest: log?.after?.memberDetail?.name }"></ion-text>
                    </ng-template>
                  </ion-item>
                </ng-container>
  
                <ng-container *ngIf="type !== 'checkin' && type !== 'invite'">
                  <ng-container *ngIf="logType === 'gift'">
                    <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.after?.status?.gift && !log?.before?.status?.gift">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                      <ion-text [innerHTML]="'LOG.msg.receive_gift_checkin' | translate">
                      </ion-text>
                    </ion-item>
                    <ion-item class="ion-item-no-padding" lines="none" *ngIf="!log?.after?.status?.gift && log?.before?.status?.gift">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                      <ion-text  [innerHTML]="'LOG.msg.delete_received_gift_checkin' | translate">
                      </ion-text>
                    </ion-item>
                  </ng-container>
                </ng-container>
                
                <ng-container *ngIf="type !== 'invite'">
                  <ion-item class="ion-item-no-padding" lines="none"  *ngIf="logType === 'checkin'">
                    <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                    <ion-text *ngIf="log?.after?.status?.checkin" [innerHTML]="'LOG.msg.checkin' | translate: { field: 'GROUP.lbl.guest_group' | translate }">
                    </ion-text>
                  </ion-item>
                  <ion-item class="ion-item-no-padding" lines="none"  *ngIf="logType === 'uncheck'">
                    <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                    <ion-text *ngIf="!log?.after?.status?.checkin" [innerHTML]="'LOG.msg.uncheck' | translate: { field: 'GROUP.lbl.guest_group' | translate }">
                    </ion-text>
                  </ion-item>
                </ng-container>
                
                <ng-container *ngIf="type !== 'checkin' && type !== 'invite'">
                  <ion-item class="ion-item-no-padding" lines="none"  *ngIf="logType === 'delete'">
                    <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                    <ion-text *ngIf="log?.after?.status?.deleted" [innerHTML]="'LOG.msg.remove' | translate: { field: 'GROUP.lbl.guest_group' | translate }">
                    </ion-text>
                    <ion-text *ngIf="!log?.after?.status?.deleted" [innerHTML]="'LOG.msg.restore' | translate: { field: 'GROUP.lbl.guest_group' | translate }">
                    </ion-text>
                  </ion-item>
  
                  <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.after?.memberList?.length || log?.before?.memberList?.length">
                    <ion-label class="ion-no-margin">
                      <ng-container *ngIf="log?.after?.memberList?.length">
                        <ng-container *ngFor="let guestId of log.after.memberList">
                          <ng-container *ngIf="!log?.before?.memberList?.length || log.before.memberList?.indexOf(guestId) === -1">
                            <div>
                              <ng-container *ngIf="getGuestName(guestId) as guest else newGroupMember">
                                <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                                <ion-text [innerHTML]="'LOG.field.new_group_member' | translate: { guest: guest }">
                                </ion-text>
                              </ng-container>
                              <ng-template #newGroupMember>
                                <ng-container>
                                  <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                                  <ion-text [innerHTML]="'LOG.field.new_group_member' | translate: { guest: '' }">
                                  </ion-text>
                                </ng-container>
                                
                              </ng-template>
                            </div>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="log?.before?.memberList?.length">
                        <ng-container *ngFor="let guestId of log.before.memberList">
                          <ng-container *ngIf="!log?.after?.memberList?.length || log.after.memberList?.indexOf(guestId) === -1">
                            <div>
                              <ng-container *ngIf="getGuestName(guestId) as guest else deleteGroupMember">
                                <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                                <ion-text [innerHTML]="'LOG.field.delete_group_member' | translate: { guest: guest }">
                                </ion-text>
                              </ng-container>
                              <ng-template #deleteGroupMember>
                                <ng-container>
                                  <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                                  <ion-text [innerHTML]="'LOG.field.delete_group_member' | translate: { guest: '' }">
                                  </ion-text>
                                </ng-container>
                                
                              </ng-template>
                            </div>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ion-label>
                  </ion-item>
                </ng-container>
  
                <ng-container *ngIf="logType === 'new' || logType === 'update'">
                  <ion-item class="ion-item-no-padding" lines="none"  *ngIf="log.after.groupName || log?.before?.groupName">
                    <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
                    <ion-text *ngIf="logType === 'new' && log?.after?.groupName">
                      {{ 'GROUP.lbl.name' | translate }}: <strong>{{ log.after.groupName }}</strong>
                    </ion-text>
                    <ion-text *ngIf="logType === 'update'" [innerHTML]="'LOG.field.update' | translate: { field: ('GROUP.lbl.name' | translate), value1: log?.before?.groupName ? log?.before?.groupName : '', value2: log.after.groupName }">
                    </ion-text>
                  </ion-item>
                </ng-container>
              </ion-card>
            </span>
          </span>
        </span>
      </ion-list>
    </cdk-virtual-scroll-viewport>
  </div>
  <div class="full-width-height vertical-center ion-padding center" *ngIf="!logList?.length">
    <div class="center">
      <ion-label class="ion-text-center ion-margin ion-padding" color="medium">
        <ion-icon class="center icon-cactus" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
        <div class="ion-margin-top font-14">{{ 'LBL.no_found' | translate }}</div>
      </ion-label>
    </div>
  </div>
</ion-content>