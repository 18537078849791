import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { UpdateByService } from 'src/app/services/user/update-by.service';
import { SeatingService } from 'src/app/services/seating/seating.service';
import { SeatingListService } from 'src/app/services/seating/seating-list.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { Seating } from 'src/app/interfaces/seating';
import { SettingField } from 'src/app/interfaces/database';
import { SeatingSettingService } from './seating-setting.service';

/**
 * Seating manage service
 */
@Injectable({
  providedIn: 'root'
})
export class SeatingManageService implements OnInit, OnDestroy {

  /**
   * Account ID
   */
  private accountId: string;

  /**
   * Constructor
   * @param afs angular firestore
   * @param seatingService seating service
   * @param seatingListService seating list service
   * @param functionService function service
   */
  constructor(
    private afs: AngularFirestore,
    private seatingService: SeatingService,
    private seatingListService: SeatingListService,
    private seatingSettingService: SeatingSettingService,
    private updateByService: UpdateByService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Setup Account ID
   * @param accountId Account ID
   */
  async setupAccountId(accountId?: string) {
    this.accountId = accountId;
  }

  /**
   * Save new seating into seating list
   * @param newSeatingList New seating list
   */
   async saveNewSeating(newSeatingList: Seating[]) {
    let list = this.seatingService.seatingList;
    newSeatingList?.forEach((seating: Seating) => {
      const index = list?.findIndex((x: Seating) => {
        if (seating?.name === x?.name) {
          return true;
        }
        return false;
      });
      if (index === -1) {
        list.push(seating);
      }
    });
    list = list.filter((x: Seating) => {
      if (x?.seatingId && x?.name) {
        return true;
      }
      return false;
    });
    list = this.seatingListService.sortList(list);
    if (this.accountId && list) {
      const data: any = {
        list,
        updateBy: this.updateByService.updateBy
      };
      const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/seating/`);
      accountsRef.set(data, { merge: true });
    }
  }

  /**
   * Update seating info
   * @param seating Seating info
   */
  async updateSeating(seating: Seating) {
    const list = [ ...this.seatingService.seatingList ];
    const index = list?.findIndex((x: Seating) => x?.seatingId === seating?.seatingId);
    if (index !== -1) {
      list[index] = seating;
      if (this.accountId && !this.functionService.isEqual(list, this.seatingService.seatingList)) {
        const data: any = {
          list,
          updateBy: this.updateByService.updateBy
        };
        const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/seating/`);
        accountsRef.set(data, { merge: true });
      }
    }
  }

  /**
   * Update seating list
   * @param seatingList Seating list
   */
  async updateSeatingList(list: Seating[]) {
    list = this.seatingListService.sortList(list);
    if (this.accountId && !this.functionService.isEqual(list, this.seatingService.seatingList)) {
      const data: any = {
        list,
        updateBy: this.updateByService.updateBy
      };
      const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/seating/`);
      accountsRef.set(data, { merge: true });
    }
  }

  /**
   * Update guest per seating
   * @param guestPerSeating guest per seating
   */
  async updateGuestPerSeating(guestPerSeating: number) {
    if (this.accountId && guestPerSeating !== this.seatingSettingService.getGuestPerSeating()) {
      const data: any = {
        guestPerSeating,
        updateBy: this.updateByService.updateBy
      };
      const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/seating/`);
      accountsRef.set(data, { merge: true });

      if (this.seatingService.seatingList?.length) {
        let seatingList = this.functionService.cloneDeep(this.seatingService.seatingList);
        let flag = false;

        seatingList = seatingList.map((seating: Seating) => {
          if (seating.count > 1) {
            seating.maxGuest = guestPerSeating * seating.count;
          } else {
            seating.maxGuest = guestPerSeating;
          }
          return seating;
        });
        this.updateSeatingList(seatingList);
      }
    }
  }

  async updateSeatingType(seatingType: SettingField) {
    const oldVal = this.seatingSettingService.getSeatingType();
    if (this.accountId && !this.functionService.isEqual(seatingType, oldVal)) {
      const data: any = {
        seatingType,
        updateBy: this.updateByService.updateBy
      };
      const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/seating/`);
      accountsRef.set(data, { merge: true });
    }
  }

  async updateSeatingSetting(guestPerSeating?: number, seatingType?: SettingField, seatingTypeOther?: string) {
    if (this.accountId) {
      const data: any = {};
      if (guestPerSeating) {
        if (guestPerSeating !== this.seatingSettingService.guestPerSeating) {
          data.guestPerSeating = guestPerSeating;
          if (this.seatingService.seatingList?.length) {
            const seatingList = this.functionService.cloneDeep(this.seatingService.seatingList).map((seating: Seating) => {
              if (seating.count > 1) {
                seating.maxGuest = guestPerSeating * seating.count;
              } else {
                seating.maxGuest = guestPerSeating;
              }
              return seating;
            });
            this.updateSeatingList(seatingList);
          }
        }
      }
  
      if (seatingType?.value) {
        if (!this.functionService.isEqual(seatingType, this.seatingSettingService.getSeatingType())) {
          data.seatingType = seatingType;
        }

        if (seatingType?.value === 'others' && !seatingType?.custom) {
          if (this.seatingSettingService.seatingTypeOther !== seatingTypeOther) {
            data.seatingTypeOther = seatingTypeOther;
          }
        } else {
          if (this.seatingSettingService.seatingTypeOther) {
            data.seatingTypeOther = '';
          }
        }
      }

      if (!this.functionService.isEmpty(data)) {
        data.updateBy = this.updateByService.updateBy;
        const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/seating/`);
        accountsRef.set(data, { merge: true });
      }
    }
  }

  async updateSeatingNameSetting(alphabet: boolean, skip4: boolean, replace4: boolean) {
    if (this.seatingSettingService.alphabet !== alphabet || this.seatingSettingService.skip4 !== skip4 || this.seatingSettingService.replace4 !== replace4) {
      const data = {
        alphabet,
        skip4,
        replace4,
        updateBy: this.updateByService.updateBy
      };
      const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/seating/`);
      accountsRef.set(data, { merge: true });
    }
  }

}
