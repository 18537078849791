import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { GroupService } from 'src/app/services/group/group.service';
import { GroupManageService } from 'src/app/services/group/group-manage.service';
import { GroupLogService } from 'src/app/services/group/group-log.service';
import { GroupDeleteService } from 'src/app/services/group/group-delete.service';

/**
 * Group setup service
 */
@Injectable({
  providedIn: 'root'
})
export class GroupSetupService implements OnInit, OnDestroy {

  /**
   * Constructor
   * @param groupService group service
   * @param groupManageService group manage service
   */
  constructor(
    private groupService: GroupService,
    private groupManageService: GroupManageService,
    private groupDeleteService: GroupDeleteService,
    private groupLogService: GroupLogService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Setup Account ID
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    await this.groupService.setupAccountId(accountId);
    await this.groupManageService.setupAccountId(accountId);
    await this.groupDeleteService.setupAccountId(accountId);
    await this.groupLogService.setupAccountId(accountId);
  }
}
