import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { BlastCredit } from 'src/app/interfaces/blast';
import { BlastUsage, BlastUsageDetail } from 'src/app/interfaces/blast';
import { AccountUserService } from 'src/app/services/account/account-user.service';
import { BlastCreditService } from 'src/app/services/blast/blast-credit.service';
import { BlastUsageService } from 'src/app/services/blast/blast-usage.service';
import { BlastCreditType, BlastType } from 'src/app/types/blast';

import { BlastUsageDetailComponent } from '../../usage/blast-usage-detail/blast-usage-detail.component';
import { BlastCreditDetailComponent } from '../blast-credit-detail/blast-credit-detail.component';
import { BlastPackageComponent } from '../blast-package/blast-package.component';
import { DateTimeService } from 'src/app/services/general/date-time.service';
import { BlastStatusService } from 'src/app/services/blast/blast-status.service';
import { BlastTemplateManageComponent } from '../../template/blast-template-manage/blast-template-manage.component';

@Component({
  selector: 'app-blast-credit-list',
  templateUrl: './blast-credit-list.component.html',
  styleUrls: ['./blast-credit-list.component.scss'],
})
export class BlastCreditListComponent  implements OnInit, OnDestroy {

  @ViewChild('blastUsageListViewport') blastUsageListViewport: CdkVirtualScrollViewport;
  @ViewChild('blastCreditListViewport') blastCreditListViewport: CdkVirtualScrollViewport;
  /**
     * Header. Calculate content height for page mode.
     */
  @ViewChild('header', { read: ElementRef }) header: ElementRef;
  /**
   * Footer. Calculate content height for page mode.
   */
  @ViewChild('footer', { read: ElementRef }) footer: ElementRef;

  @Input() pageMode: boolean;
  @Input() blastCreditType: BlastCreditType;
  @Input() limit: number;
  @Input() set ready(ready: boolean) {
    if (ready) {
      this.initial();
    } else {
      this.unwatch();
    }
  }

  blastCreditList: BlastCredit[];
  blastUsageList: BlastUsage[];

  blastUsageItem: {
    [usageId: string]: {
      count: number,
      status: string,
    },
  };

  private blastCreditSubscription: Subscription;
  private blastUsageSubscription: Subscription;

  constructor(
    private router: Router,
    private modalController: ModalController,
    private accountUserService: AccountUserService,
    private blastCreditService: BlastCreditService,
    private blastUsageService: BlastUsageService,
    private blastStatusService: BlastStatusService,
    private dateTimeService: DateTimeService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.unwatch();
  }

  ionViewWillEnter() {
    this.initial();
  }

  ionViewWillLeave() {
    this.unwatch();
  }

  initial() {
    this.watch();
    if (!this.blastCreditType) {
      this.blastCreditType = 'out';
    }
  }

  watch() {
    this.watchBlastCredit();
    this.watchBlastUsage();
  }

  unwatch() {
    this.unwatchBlastCredit();
    this.unwatchBlastUsage();
  }

  async watchBlastCredit() {
    if (!this.blastCreditSubscription) {
      this.blastCreditSubscription = this.blastCreditService.observableBlastCredit.subscribe((blastCreditList: BlastCredit[]) => {
        if (this.limit && blastCreditList?.length > this.limit) {
          this.blastCreditList = [ ...blastCreditList ].splice(0, this.limit);
        } else {
          this.blastCreditList = blastCreditList;
        }
        this.setupBlastCreditListViewport();
      });
    }
    
  }

  async unwatchBlastCredit() {
    if (this.blastCreditSubscription) {
      this.blastCreditSubscription.unsubscribe();
      this.blastCreditSubscription = null;
    }
  }

  async watchBlastUsage() {
    if (!this.blastUsageSubscription) {
      this.blastUsageSubscription = this.blastUsageService.observableBlastUsage.subscribe((blastUsageList: BlastUsage[]) => {
        if (this.limit && blastUsageList?.length > this.limit) {
          this.blastUsageList = [ ...blastUsageList ].splice(0, this.limit);
        } else {
          this.blastUsageList = blastUsageList;
        }
        this.setupBlastUsageItem();
        this.setupBlastUsageListViewport();
      });
    }
  }

  async unwatchBlastUsage() {
    if (this.blastUsageSubscription) {
      this.blastUsageSubscription.unsubscribe();
      this.blastUsageSubscription = null;
    }
  }

  setupBlastUsageItem() {
    if (!this.blastUsageItem) {
      this.blastUsageItem = {};
    }
    if (this.blastUsageList?.length) {
      this.blastUsageList.forEach((blastUsage: BlastUsage) => {
        if (blastUsage?.usageId) {
          this.blastUsageItem[blastUsage.usageId] = {
            count: this.getBlastUsageQty(blastUsage),
            status: this.getBlastStatus(blastUsage.usageId),
          }
        }
      });
    }
  }

  setupBlastCreditListViewport() {
    if (this.blastCreditListViewport) {
      this.blastCreditListViewport?.checkViewportSize();
    } else {
      setTimeout(() => {
        this.setupBlastCreditListViewport();
      }, 200);
    }
  }

  setupBlastUsageListViewport() {
    if (this.blastUsageListViewport) {
      this.blastUsageListViewport?.checkViewportSize();
    } else {
      setTimeout(() => {
        this.setupBlastUsageListViewport();
      }, 200);
    }
  }

  getScreenHeight() {
    return '100vh';
  }

  /**
    * Get header height
    */
  getHeaderHeight() {
    let header = 0;
    if (this.pageMode) {
      if (this.header?.nativeElement?.offsetHeight) {
        header = this.header.nativeElement.offsetHeight;
      }
    }
    return header;
  }

  /**
    * Get footer height
    */
  getFooterHeight() {
    let footer = 0;
    if (this.pageMode) {
      if (this.footer?.nativeElement?.offsetHeight) {
        footer += this.footer.nativeElement.offsetHeight;
      }
    }
    return footer;
  }

  getUserName(uid: string) {
    if (uid) {
      return this.accountUserService.getUserByUid(uid)?.name;
    }
    return '';
  }

  getBlastUsageQty(blastUsage: BlastUsage) {
    return blastUsage?.usageDetail?.reduce((a: number, b: BlastUsageDetail) => {
      return a + b.qty;
    }, 0);
  }

  getTotal() {
    if (this.blastCreditType === 'in') {
      return this.blastCreditList?.reduce((a: number, b: BlastCredit) => {
        return a + b.blastCredit;
      }, 0);
    } else if (this.blastCreditType === 'out') {
      return this.blastUsageList?.reduce((a: number, b: BlastUsage) => {
        return a + b.blastCredit;
      }, 0);
    }
    return 0;
  }

  getBlastStatus(usageId: string) {
    return this.blastStatusService.getBlastMsgStatusTextByUsageId(usageId);
  }

  format(timestamp: number, dateTimeFormat: string, timezone?: string) {
    return this.dateTimeService.format(timestamp, dateTimeFormat, '', timezone);
  }

  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { await modal.dismiss(); }
    }
  }

  async presentBlastCreditDetailModal(blastCredit: BlastCredit) {
    const modal = await this.modalController.create({
      component: BlastCreditDetailComponent,
      cssClass: '',
      componentProps: {
        blastCredit
      }
    });
    modal.present();
  }

  async presentBlastUsageModal(blastUsage: BlastUsage) {
    const modal = await this.modalController.create({
      component: BlastUsageDetailComponent,
      componentProps: {
        blastUsage
      }
    });
    modal.present();
    modal.onDidDismiss().then((result: any) => {
      if (result?.data?.dismiss) {
        this.dismissModal();
      }
    });
  }

  blastCreditItemHeightFn() {
    return 86;
  }

  /**
   * Calculate item height for virtual scroll
   * @param item Item
   */
  blastUsageItemHeightFn() {
    return 86;
  }

  /**
   * Track item by account login
   * @param index Index
   * @param item item
   */
  blastCreditTrackByFn(index: number, item: BlastCredit) {
    if (item?.blastCreditId) {
      return item?.blastCreditId;
    }
    return index;
  }

  blastUsageTrackByFn(index: number, item: BlastUsage) {
    if (item?.usageId) {
      return item?.usageId;
    }
    return index;
  }

  async presentTemplateManageModal(blastType?: BlastType) {
    const modal = await this.modalController.create({
      component: BlastTemplateManageComponent,
      componentProps: {
        blastType,
        hideBlastType: false,
      }
    });
    modal.present();
  }

  async presentBlastPackageModal() {
    const modal = await this.modalController.create({
      component: BlastPackageComponent,
      componentProps: {
      }
    });
    modal.present();
  }

  async goBlastHomePage() {
    await this.router.navigate(['/blast/home']);
    await this.dismissModal();
  }

  async back() {
    if (this.pageMode) {
      await this.goBlastHomePage();
    } else {
      this.dismissModal();
    }
  }

}
