import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SettingField } from 'src/app/interfaces/database';
import { FunctionService } from 'src/app/services/general/function.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { SeatingManageService } from 'src/app/services/seating/seating-manage.service';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';
import { SeatingService } from 'src/app/services/seating/seating.service';

@Component({
  selector: 'app-setting-seating',
  templateUrl: './setting-seating.component.html',
  styleUrls: ['./setting-seating.component.scss'],
})
export class SettingSeatingComponent  implements OnInit, OnDestroy {

  @Input() newMode: boolean;
  @Input() countryCode: string;
  @Input() seatingType: SettingField;

  @Input() seatingTypeOther: string;

  @Input() guestPerSeating: number;

  @Input() set setInitial(flag: boolean) {
    if (flag) {
      this.initial();
    } else {
      this.unwatch();
    }
  }
  // @Input() set getOutput(flag: boolean) {
  //   if (flag) {
  //     this.setGuestPerSeating.next(this.guestPerSeating);
  //     this.setSeatingType.next(this.seatingType);
  //     this.setSeatingTypeOther.next(this.seatingTypeOther);
  //   }
  // }
  @Output() setGuestPerSeating = new EventEmitter<number>;
  @Output() setSeatingType = new EventEmitter<SettingField>;
  @Output() setSeatingTypeOther = new EventEmitter<string>;

  seatingTypeList: SettingField[];

  seatingTypeName: string;

  // seatingType: SettingField;

  // seatingTypeOther: string;

  // guestPerSeating: number;

  private seatingTypeSubscription: Subscription;
  private seatingTypeOtherSubscription: Subscription;
  private guestPerSeatingSubscription: Subscription;
  
  constructor(
    private translate: TranslateService,
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private seatingSettingService: SeatingSettingService,
    private seatingManageService: SeatingManageService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {
    this.unwatch();
  }

  ionViewWillEnter() {
    this.initial();
  }

  ionViewWillLeave() {
    this.unwatch();
  }

  initial() {
    this.seatingTypeList = this.seatingSettingService.getSeatingTypeList();
    this.seatingTypeName = this.getSeatingTypeName();
    
    if (this.newMode) {
      if (!this.guestPerSeating) {
        this.guestPerSeating = this.seatingSettingService.getDefaultGuestPerSeating(this.countryCode);
      }
      if (!this.seatingType?.value) {
        this.seatingType = this.seatingSettingService.getDefaultSeatingType();
      }
      if (this.seatingTypeOther && this.seatingType?.value !== 'others') {
        this.seatingTypeOther = '';
      } else if (this.seatingType?.value === 'others' && this.seatingSettingService.seatingTypeOther) {
        this.seatingTypeOther = this.seatingSettingService.seatingTypeOther;
      }
    } else {
      this.watch();
    }

    if (this.seatingTypeList?.length && this.seatingType?.value) {
      const index = this.seatingTypeList.findIndex((x: SettingField) => {
        return x.custom === this.seatingType.custom && x.value === this.seatingType.value;
      });
      if (index === -1) {
        this.seatingTypeList.push(this.seatingType);
      }
    }
  }

  watch() {
    this.watchSeatingType();
    this.watchSeatingTypeOther();
    this.watchGuestPerSeating();
  }

  unwatch() {
    this.unwatchSeatingType();
    this.unwatchSeatingTypeOther();
    this.unwatchGuestPerSeating();
  }

  async watchSeatingType() {
    if (!this.seatingTypeSubscription) {
      this.seatingTypeSubscription = this.seatingSettingService.observableSeatingType.subscribe(()  => {
        if (!this.newMode || !this.seatingType?.value) {
          this.seatingType = this.seatingSettingService.getSeatingType();
        }
      });
    }
    
  }

  async watchSeatingTypeOther() {
    if (!this.seatingTypeOtherSubscription) {
      this.seatingTypeOtherSubscription = this.seatingSettingService.observableSeatingTypeOther.subscribe(()  => {
        if (!this.newMode || !this.seatingTypeOther) {
          this.seatingTypeOther = this.seatingSettingService.seatingTypeOther;
        }
      });
    }
    
  }

  async watchGuestPerSeating() {
    if (!this.guestPerSeatingSubscription) {
      this.guestPerSeatingSubscription = this.seatingSettingService.observableGuestPerSeating.subscribe(()  => {
        if (!this.newMode || !this.guestPerSeating) {
          this.guestPerSeating = this.seatingSettingService.getGuestPerSeating(this.countryCode);
        }
      });
    }
    
  }

  async unwatchSeatingType() {
    if (this.seatingTypeSubscription) {
      this.seatingTypeSubscription.unsubscribe();
      this.seatingTypeSubscription = null;
    }
  }

  async unwatchSeatingTypeOther() {
    if (this.seatingTypeOtherSubscription) {
      this.seatingTypeOtherSubscription.unsubscribe();
      this.seatingTypeOtherSubscription = null;
    }
  }

  async unwatchGuestPerSeating() {
    if (this.guestPerSeatingSubscription) {
      this.guestPerSeatingSubscription.unsubscribe();
      this.guestPerSeatingSubscription = null;
    }
  }



  compareWith(a: SettingField, b: SettingField) {
    return a?.value === b?.value && a?.custom === b?.custom;
  }

  getSeatingTypeName(): string {
    return this.seatingSettingService.getSeatingTypeName('', this.seatingType, this.seatingTypeOther);
  }

  guestPerSeatingChanged() {
    if (this.newMode) {
      this.setGuestPerSeating.next(this.guestPerSeating);
    }
  }

  seatingTypeChanged() {
    if (this.newMode) {
      this.setSeatingType.next(this.seatingType);
    }
    this.seatingTypeName = this.getSeatingTypeName();

    if (this.seatingType?.value === 'seat' && !this.seatingType?.custom) {
      this.guestPerSeating = 1;
    } else {
      if (this.guestPerSeating = 1) {
        this.guestPerSeating = this.seatingSettingService.getGuestPerSeating(this.countryCode);
      }
    }
  }

  seatingTypeOtherChanged() {
    if (this.newMode) {
      this.setSeatingTypeOther.next(this.seatingTypeOther);
    }
  }

  async discard() {
    if (!this.functionService.isEqual(this.seatingType, this.seatingSettingService.getSeatingType()) || this.guestPerSeating !== this.seatingSettingService.getGuestPerSeating() || (this.seatingType?.value === 'others' && this.seatingTypeOther !== this.seatingSettingService.seatingTypeOther)) {
      this.confirmDiscard();
    } else {
      this.dismissModal();
    }
  }

  async confirmDiscard() {
    const actionSheet = await this.actionSheetController.create({
      header: this.translate.instant('MSG.discard_msg'),
      buttons: [{
        text: this.translate.instant('BTN.confirm'),
        role: 'destructive',
        icon: 'trash',
        handler: () => {
          this.dismissModal();
        }
      }, {
        text: this.translate.instant('BTN.cancel'),
        icon: 'close',
        role: 'cancel',
        handler: () => {
        }
      }]
    });
    actionSheet.present();
  }

  /**
   * Dismiss checkin setting modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { modal.dismiss(); }
    }
  }

  async presentGuestPerSeatingPrompt() {
    const modal = await this.popupService.presentInput(
      this.translate.instant('SEATING.lbl.max_guest', { seating: this.getSeatingTypeName() }), '', '', '', this.guestPerSeating, false, 'number');
    modal.onDidDismiss().then((result: any) => {
      if (result?.data?.input) {
        this.guestPerSeating = result.data.input;
        if (this.newMode) {
          this.guestPerSeatingChanged();
        }
      }
    });
  }

  /**
   * Submit
   */
  async submit() {
    await this.popupService.presentLoading();
    await this.seatingManageService.updateSeatingSetting(this.guestPerSeating, this.seatingType, this.seatingTypeOther);
    await this.dismissModal();
    this.popupService.dismissLoading();
    this.popupService.saveSuccessToast();
  }

}
