import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { StripeCurrencyList } from 'src/app/commons/stripe';
import { StdCurrencyList } from 'src/app/commons/currency';
import { Currency } from 'src/app/interfaces/database';

@Injectable({
  providedIn: 'root'
})
export class StripeCurrencyService implements OnInit, OnDestroy {

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  validateCurrency(currencyCode: string) {
    if (!this.validateStdCurrency(currencyCode) && !this.validateStripeCurrency(currencyCode)) {
      return false;
    }
    return true;
}

  validateStdCurrency(currencyCode: string) {
    const index = StdCurrencyList.findIndex((currency: Currency) => {
      return currency?.code && currency?.code === currencyCode;
    });
    if (index === -1) {
      return false;
    }
    return true;
  }

  validateStripeCurrency(currencyCode: string) {
    return StripeCurrencyList.indexOf(currencyCode) !== -1 ? true : false;
  }
}
