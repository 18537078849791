import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestSummaryService } from 'src/app/services/guest/guest-summary.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { GiftSummaryService } from 'src/app/services/gift/gift-summary.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { GuestListComponent } from 'src/app/components/guest/guest-list/guest-list.component';
import { GiftListComponent } from 'src/app/components/gift/gift-list/gift-list.component';
import { GiftStatisticComponent } from 'src/app/components/gift/gift-statistic/gift-statistic.component';

import { SettingField } from 'src/app/interfaces/database';
import { Status } from 'src/app/interfaces/status';
import { GiftType } from 'src/app/types/gift';
import { CardType, GiftStatisticType, GuestStatisticType } from 'src/app/types/statistic';
import { Subscription } from 'rxjs';
import { ModuleType } from 'src/app/types/general';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { GiftReceivedType, GuestFilterType } from 'src/app/types/guest';
import { GuestStatisticComponent } from 'src/app/components/guest/guest-statistic/guest-statistic.component';

/**
 * Gift statistic item component
 */
@Component({
  selector: 'app-gift-statistic-item',
  templateUrl: './gift-statistic-item.component.html',
  styleUrls: ['./gift-statistic-item.component.scss'],
})
export class GiftStatisticItemComponent implements OnInit, OnDestroy {

  @Input() set reset(reset: boolean) {
    if (reset) {
      this.getData();
    } else {
      this.unwatch();
    }
  }
  @Input() color: string;
  /**
   * Gift Statistic type
   */
  @Input() statisticType: GiftStatisticType;
   /**
    * Statistic card type
    */
  @Input() cardType: CardType;
   /**
    * Set gift type
    */
  @Input() set setGiftType(giftType: GiftType ) {
    this.giftType = giftType;
    this.getData();
  }
   /**
    * Set invited by for statistic card and get card data
    */
  @Input() set setInvitedBy(invitedBy: SettingField) {
      this.invitedBy = invitedBy;
      this.getData();
  }
  /**
   * Category
   */
  @Input() category: SettingField;
  /**
   * Dietary request
   */
  @Input() dietaryReq: SettingField;
  /**
   * Special request
   */
  @Input() specialReq: SettingField;

  @Input() session: SettingField;
  /**
   * Seating status
   */
  @Input() seating: any;
  /**
   * Guest status
   */
  @Input() status: Status;
  /**
   * All statistic
   */
  @Input() all: boolean;
  /**
   * Show statistic in number
   */
  @Input() showNumber: boolean;
  /**
   * Show statistic in percentage
   */
  @Input() showPercent: boolean;
  /**
   * Show total
   */
  @Input() showTotal: boolean;
  /**
   * White background without progress bar
   */
  @Input() whiteBackground: boolean;
  /**
   * Display name for statistic card
   */
  @Input() name: string;
  /**
   * Invited by
   */
  @Input() invitedBy: SettingField;
  /**
   * Currency
   */
  @Input() currency: string;

  @Input() module: ModuleType;

  giftReceiveType: string;
  /**
   * Gift type
   */
  giftType: GiftType;
  /**
   * Gift amount
   */
  amount: number;
  /**
   * Gift count
   */
  count: number;
  /**
   * Total of gift
   */
  total: number;
  /**
   * Percentage of gift
   */
  percent: number;
  /**
   * Margin for card
   */
  margin: string;
  /**
   * Guest list subscription
   */
  private guestListSubscription: Subscription;
  /**
   * Gift list subscription
   */
  private giftListSubscription: Subscription;

  /**
   * Constructor
   * @param modalController modal controller
   * @param guestService guest service
   * @param guestSummaryService guest summary service
   * @param giftService gift service
   * @param giftSummaryService gift summary service
   * @param popupService popup service
   */
  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private moduleService: ModuleService,
    private guestService: GuestService,
    private guestSummaryService: GuestSummaryService,
    private giftService: GiftService,
    private giftSummaryService: GiftSummaryService,
    private popupService: PopupService,
  ) { }

  /**
   * Initialize card type and margin
   */
  ngOnInit() {
    this.watchGiftList();
    if (this.statisticType === 'received') {
      this.watchGuestList();
    }
    this.setupCardType();
    this.setupMargin();
  }

  ngOnDestroy() {
    this.unwatch();
  }

  unwatch() {
    this.unwatchGuestList();
    this.unwatchGiftList();
  }

  /**
   * Watch gift list changes
   */
  async watchGuestList() {
    if (!this.guestListSubscription) {
      this.guestListSubscription = this.guestService.observableGuestList.subscribe(() => {
        this.getData();
      });
    }
    
  }

  /**
   * Unwatch gift list changes
   */
  async unwatchGuestList() {
    if (this.guestListSubscription) {
      this.guestListSubscription.unsubscribe();
      this.guestListSubscription = null;
    }
  }

  /**
   * Watch gift list changes
   */
  async watchGiftList() {
    if (!this.giftListSubscription) {
      this.giftListSubscription = this.giftService.observableGiftList.subscribe(() => {
        this.getData();
      });
    }
    
  }

  /**
   * Unwatch gift list changes
   */
  async unwatchGiftList() {
    if (this.giftListSubscription) {
      this.giftListSubscription.unsubscribe();
      this.giftListSubscription = null;
    }
  }

  /**
   * Setup card type
   */
   setupCardType() {
    if (!this.cardType) {
      this.cardType = 'block';
    }
  }

  /**
   * Setup margin
   */
   setupMargin() {
    if (this.cardType === 'square') {
      this.margin = '0';
    } else if (this.cardType === 'rect') {
      this.margin = '0';
    } else if (this.cardType === 'dashboard') {
      this.margin = '10px auto';
    } else {
      this.margin = '10px 0';
    }
  }

  /**
   * Get statistic data
   */
  getData() {
    this.total = this.getTotalCount(this.all);

    if (this.giftType === 'cash' && this.currency) {
      this.amount = this.giftSummaryService.getGiftListSum(
        null,
        this.currency,
        this.invitedBy,
        this.category,
        this.dietaryReq,
        this.specialReq,
        null,
        null,
        null,
        this.module
      );
    }

    this.count = this.getGiftListCount(
      this.giftType,
      this.currency,
      this.invitedBy,
      this.category,
      this.dietaryReq,
      this.specialReq,
      this.session
    );

    this.percent = this.getPercentCount(this.count, this.total);
  }

  /**
   * Get gift list count
   * @param giftType gift type
   * @param currency currency
   * @param invitedBy invited by
   * @param category category
   * @param dietaryReq dietary request
   * @param specialReq secial request
   * @param seating seating
   * @param group group
   * @param status status
   * @param all all
   * @returns Gift list count
   */
  getGiftListCount(
    giftType?: GiftType,
    currency?: string,
    invitedBy?: SettingField,
    category?: SettingField,
    dietaryReq?: SettingField,
    specialReq?: SettingField,
    session?: SettingField,
    seating?: any,
    group?: string,
    status?: Status,
    all?: boolean
  ): number {
    if (!status) {
      status = {};
    }

    if (this.statisticType === 'received') {
      status.gift = true;
      // return this.guestSummaryService.getGuestListCount(invitedBy, category, dietaryReq, specialReq, session, seating, group, status, this.module)
      // + this.groupListService.getGroupReceivedGift();
      // const result = this.guestListService.searchGuestList('', { giftReceived: 'received'} );
      // return result.count;
      return this.guestSummaryService.getGuestListCount(invitedBy, category, dietaryReq, specialReq, session, seating, group, status, this.module, true);
    } else {
      return this.giftSummaryService.getGiftListCount(
        giftType, currency, invitedBy, category, dietaryReq, specialReq, seating, group, status, [], this.module
      );
    }
  }

  /**
   * Get total count of current card type
   * @param all all card of current card type
   */
  getTotalCount(all?: boolean, status?: Status): number {
    return this.getGiftListCount(
      this.giftType,
      this.currency,
      all ? null : this.invitedBy,
      null,
      null,
      null,
      null,
      null,
      null,
      status,
      !this.invitedBy && all ? true : false
    );
  }

  /**
   * Calculate percentage
   * @param value count
   * @param total total
   */
  getPercentCount(value: number, total: number): number {
    let percent = 0;
    if (value && total) {
      percent = Math.round((value / total) * 100);
    }
    return percent;
  }

  /**
   * Present modal
   */
  presentModal() {
    if (this.whiteBackground) {
      if (this.statisticType === 'received') {
        this.presentGuestStatisticModal('giftReceived', 'received');
        // this.presentGuestListModal(this.invitedBy,  this.category, this.dietaryReq, this.specialReq);
      } else {
        this.presentGiftStatisticModal(this.statisticType, this.giftType, this.currency);
      }
    } else {
      if (this.statisticType === 'received') {
        this.presentGuestStatisticModal('giftReceived', 'received');
        // this.presentGuestListModal(this.invitedBy,  this.category, this.dietaryReq, this.specialReq);
      } else {
        this.presentGiftListModal(this.invitedBy, this.category, this.dietaryReq, this.specialReq);
      }
    }
  }

  /**
   * Present gift statistic modal
   * @param statisticType statistic type
   * @param giftType gift type
   * @param currency currency
   */
  async presentGiftStatisticModal(statisticType: GiftStatisticType, giftType?: GiftType, currency?: string) {
    if (this.cardType === 'dashboard') {
      this.moduleService.setCurrentModule(this.module);
    }
    const modal = await this.modalController.create({
      component: GiftStatisticComponent,
      cssClass: '',
      componentProps: {
        statisticType,
        giftType,
        currency,
        invitedBy: this.invitedBy,
        module: this.module,
      }
    });
    await modal.present();
    if (this.cardType === 'dashboard') {
      this.moduleService.setCurrentModule(this.module);
      modal.onWillDismiss().then(() => {
        this.moduleService.setCurrentModule('account');
      });
    }
  }

  async presentGuestStatisticModal(statisticType: GuestStatisticType, giftReceivedType: GiftReceivedType) {
    const modal = await this.modalController.create({
      component: GuestStatisticComponent,
      cssClass: '',
      componentProps: {
        statisticType,
        giftReceivedType,
        invitedBy: this.invitedBy,
        module: this.module,
      }
    });
    await modal.present();
  }

  /**
   * Present gift list modal
   * @param invitedBy invited by
   * @param category category
   * @param dietaryReq dietary request
   * @param specialReq special request
   */
  async presentGiftListModal(
    invitedBy?: SettingField, category?: SettingField,
    dietaryReq?: SettingField, specialReq?: SettingField
  ) {
    await this.popupService.presentLoading();

    const defaultFilter: any = {
      enable: false,
      show: false,
      invitedBy: invitedBy ? invitedBy : null,
      category: category ? [ category ] : null,
      dietaryReq: dietaryReq ? [ dietaryReq ] : null,
      specialReq: specialReq ? [ specialReq ] : null,
    };

    if (invitedBy || category || dietaryReq || specialReq) {
      defaultFilter.enable = true;
      defaultFilter.show = false;
    }

    let title = '';
    if (this.invitedBy?.value) {
      title = !this.invitedBy?.custom ? this.translate.instant('LIST.invited_by.' + this.invitedBy.value) : this.invitedBy.value;
    }
    if (this.category?.value) {
      const value = !this.category?.custom ? this.translate.instant('LIST.category.' + this.category.value) : this.category.value;
      title = title ? title + ' - ' + value : value;
    }
    if (this.dietaryReq?.value) {
      const value = !this.dietaryReq?.custom ? this.translate.instant('LIST.dietary_req.' + this.dietaryReq.value) : this.dietaryReq.value;
      title = title ? title + ' - ' + value : value;
    }
    if (this.specialReq?.value) {
      const value = !this.specialReq?.custom ? this.translate.instant('LIST.special_req.' + this.specialReq.value) : this.specialReq.value;
      title = title ? title + ' - ' + value : value;
    }

    const modal = await this.modalController.create({
      component: GiftListComponent,
      cssClass: '',
      componentProps: {
        defaultFilter,
        title,
        statisticType: this.statisticType,
        giftType: this.giftType,
        currency: this.currency,
        mode: 'list'
      }
    });
    modal.present();
    this.popupService.dismissLoading();
  }

  /**
   * Present guest list modal
   * @param invitedBy invited by
   * @param category category
   * @param dietaryReq dietary req
   * @param specialReq special req
   */
  async presentGuestListModal(
    invitedBy?: SettingField, category?: SettingField, dietaryReq?: SettingField, specialReq?: SettingField
  ) {
    const defaultFilter: any = {
      enable: false,
      show: false,
      invitedBy: invitedBy ? invitedBy : null,
      category: category ? [ category ] : null,
      dietaryReq: dietaryReq ? [ dietaryReq ] : null,
      specialReq: specialReq ? [ specialReq ] : null,
    };

    if (invitedBy || category || dietaryReq || specialReq) {
      defaultFilter.enable = true;
    }

    let title = this.translate.instant('GIFT.lbl.received_gift_checkin');
    if (this.invitedBy?.value) {
      title = !this.invitedBy?.custom ? this.translate.instant('LIST.invited_by.' + this.invitedBy.value) : this.invitedBy.value;
    }
    if (this.category?.value) {
      const value = !this.category?.custom ? this.translate.instant('LIST.category.' + this.category.value) : this.category.value;
      title = title ? title + ' - ' + value : value;
    }
    if (this.dietaryReq?.value) {
      const value = !this.dietaryReq?.custom ? this.translate.instant('LIST.dietary_req.' + this.dietaryReq.value) : this.dietaryReq.value;
      title = title ? title + ' - ' + value : value;
    }
    if (this.specialReq?.value) {
      const value = !this.specialReq?.custom ? this.translate.instant('LIST.special_req.' + this.specialReq.value) : this.specialReq.value;
      title = title ? title + ' - ' + value : value;
    }

    const type: GuestFilterType = {
      giftReceived: 'received'
    };
    const modal = await this.modalController.create({
      component: GuestListComponent,
      cssClass: '',
      componentProps: {
        defaultFilter,
        mode: 'list',
        title,
        type,
      }
    });
    modal.present();
    this.popupService.dismissLoading();
  }

}
