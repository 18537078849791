import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GiftSummary } from 'src/app//interfaces/gift';

/**
 * Gift summary component
 */
@Component({
  selector: 'app-gift-summary',
  templateUrl: './gift-summary.component.html',
  styleUrls: ['./gift-summary.component.scss'],
})
export class GiftSummaryComponent implements OnInit, OnDestroy {

  /**
   * Gift summary
   */
  @Input() giftSummary: GiftSummary;

  /**
   * Constructor
   */
  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
