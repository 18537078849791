import { PopupService } from './../general/popup.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationExtras, Router } from '@angular/router';
import { SupportTicketType } from 'src/app/types/support';

@Injectable({
  providedIn: 'root'
})
export class AccountAupService implements OnInit, OnDestroy {

  exceed: boolean;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private popupService: PopupService,
  ) { }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  setupAup(exceed: boolean) {
    this.exceed = exceed;
  }

  isExceed() {
    if (this.exceed) {
      this.promptAupModal();
      return true;
    }
    return false;
  }

  async promptAupModal() {
    const confirm = await this.popupService.presentConfirm(
      this.translate.instant('AUP.exceed'),
      '', '', '',
      this.translate.instant('AUP.btn.more'),
      this.translate.instant('BTN.close')
    );
    confirm.onWillDismiss().then((result) => {

      if (!result?.data?.cancel) {
        if (result?.data?.confirm) {
          this.goAupPage();
        } else {
          this.goSupportPage('aup_appeal');
        }
      }
    });
    this.popupService.dismissToast();
  }

  goAupPage() {
    const navigationExtras: NavigationExtras = {
      state: {
        exceed: true,
      }
    };
    this.router.navigate(['/about/aup'], navigationExtras);
  }

  goSupportPage(ticketType: SupportTicketType) {
    const navigationExtras: NavigationExtras = {
      state: {
        ticketType,
      }
    };
    this.router.navigate(['/about/support'], navigationExtras);
  }
}
