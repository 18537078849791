import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestListService } from 'src/app/services/guest/guest-list.service';

import { SettingField } from 'src/app/interfaces/database';
import { Status } from 'src/app/interfaces/status';
import { Guest } from 'src/app/interfaces/guest';
import { ModuleType } from 'src/app/types/general';

/**
 * Guest summary service
 */
@Injectable({
  providedIn: 'root'
})
export class GuestSummaryService implements OnInit, OnDestroy {

  /**
   * Constructor
   * @param moduleService module service
   * @param guestService guest service
   * @param guestListService guest list service
   * @param groupService group service
   * @param giftService gift service
   * @param functionService function service
   */
  constructor(
    private guestService: GuestService,
    private guestListService: GuestListService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Get guest list count
   * @param invitedBy Invited by
   * @param category Category
   * @param dietaryReq Dietary request
   * @param specialReq Seating request
   * @param seating Seating
   * @param status Status
   */
  getGuestListCount(
    invitedBy?: SettingField,
    category?: SettingField,
    dietaryReq?: SettingField,
    specialReq?: SettingField,
    session?: SettingField,
    seating?: any,
    group?: string,
    status?: Status,
    module?: ModuleType,
    generateGroupList?: boolean,
  ): number {
    let guestList: Guest[] = this.guestService.getGuestList(module);
    if (invitedBy || category || dietaryReq || specialReq || session || seating || group || status) {
      const filter: any = {
        enable: true,
        invitedBy,
        category: category ? [ category ] : null,
        dietaryReq: dietaryReq ? [ dietaryReq ] : null,
        specialReq: specialReq ? [ specialReq ] : null,
        session: session ? [ session ] : null,
        seating,
        group,
        status,
      };
      guestList = guestList?.filter((guest: Guest) => {
        return this.guestListService.filterGuestByCriteria(guest, filter);
      });
    }
    if (generateGroupList) {
      return this.guestListService.generateGroupList(guestList).length;
    } else {
      return guestList.length;
    }
  }
}
