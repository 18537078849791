import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subscription, BehaviorSubject, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VisitorLogoService implements OnInit, OnDestroy {

  logo: string;
  logoSize: number;
  observableLogo: any;

  private accountId: string;
  private partnerSubscription: Subscription;

  constructor(
    private afs: AngularFirestore,
  ) {
    this.observableLogo = new BehaviorSubject<string>(this.logo);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy() {
    this.unwatchPartner();
  }

  setupAccountId(accountId?: string) {
    this.accountId = accountId;
    if(this.accountId) {
      // this.watchPartner();
      this.readPartner();
    } else {
      this.unwatchPartner();
    }
  }

  async readPartner() {
    if (this.accountId) {
      const docSnapshot = await firstValueFrom(this.afs.doc(`accounts/${this.accountId}/accountSetting/partner`).get());
      const result = docSnapshot.data();
      if (result) {
        const data: any = result;
        if (data?.logo) {
          this.logo = data.logo;
          this.observableLogo.next(this.logo);
        } else {
          this.logo = '';
          this.observableLogo.next(this.logo);
        }
        if (data?.logoSize) {
          this.logoSize = data.logoSize;
        } else {
          this.logoSize = 0;
        }
      } else {
        this.logo = '';
        this.observableLogo.next(this.logo);

        this.logoSize = 0;
      }
    }
  }

  async watchPartner() {
    if (this.accountId) {
      if (!this.partnerSubscription) {
        this.partnerSubscription = this.afs.doc(`accounts/${ this.accountId }/accountSetting/partner`)
        .snapshotChanges().subscribe({
          next: (result) => {
            if (result?.payload?.data()) {
              const data: any = result.payload.data();
              if (data?.logo) {
                this.logo = data.logo;
                this.observableLogo.next(this.logo);
              } else {
                this.logo = '';
                this.observableLogo.next(this.logo);
              }
              if (data?.logoSize) {
                this.logoSize = data.logoSize;
              } else {
                this.logoSize = 0;
              }
            } else {
              this.logo = '';
              this.observableLogo.next(this.logo);
  
              this.logoSize = 0;
            }
          }
        });
      }
    }
  }

  async unwatchPartner() {
    if (this.partnerSubscription) {
      this.partnerSubscription.unsubscribe();
      this.partnerSubscription = null;
    }
  }
}
