import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { SupportRtdbUrl } from 'src/app/commons/db';

@Injectable({
  providedIn: 'root'
})
export class SupportDbService implements OnInit, OnDestroy {

  constructor(
    private db: AngularFireDatabase,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  getDb() {
    return this.db.database.app.database(SupportRtdbUrl);
  }

  getRef(uid?: string) {
    const db = this.getDb();
    if (uid && uid !== '3SAfCuWtlWXAvSnk4at4zezSct72') {
      return db.ref(`tickets/${ uid }/`);
    } else {
      return db.ref(`tickets/`);
    }
  }

  generateTicketId(uid: string): string {
    const ref = this.getRef(uid);
    return ref.push().key;
  }

  generateMessagesId(uid: string, ticketId: string): string {
    if (ticketId) {
      const ref = this.getRef(uid).child(ticketId).child(`messages`);
      return ref.push().key;
    }
    return '';
  }
}
