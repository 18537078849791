import { SettingField } from "../interfaces/database";

export const SeatingTypeList: SettingField[] = [
    {
        value: 'table',
        custom: false,
    },
    {
        value: 'seat',
        custom: false,
    },
    {
        value: 'zone',
        custom: false,
    },
    {
        value: 'others',
        custom: false,
    }
];

export const DefaultSeatingType: SettingField = {
    value: 'table',
    custom: false,
};