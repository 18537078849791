import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root'
})
export class GooglePlaceService {

  constructor(
    private fns: AngularFireFunctions,
  ) { }

  async getAddress(placeId: string, language: string) {
    return await this.fns.httpsCallable('getPlaceAddressCall')({ placeId, language })
    .toPromise().then(async (result: any) => {
      return result;
    }).catch((err: any) => {
      return '';
    });
  }
}
