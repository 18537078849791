import { GeneralComponentsModule } from 'src/app/components/general/general.components.module';
import { GuestExportComponent } from 'src/app/components/guest/guest-export/guest-export.component';
import { GuestImportComponent } from 'src/app/components/guest/guest-import/guest-import.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';

import { SearchComponentsModule } from 'src/app/components/general/search/search.components.module';
import { SelectAllComponentsModule } from 'src/app/components/general/select-all/select-all.components.module';
import { BulkActionComponentsModule } from 'src/app/components/general/bulk-action/bulk-action.components.module';
import { OfflineComponentsModule } from 'src/app/components/general/offline/offline.components.module';

import { GuestStatisticItemComponent } from 'src/app/components/guest/guest-statistic-item/guest-statistic-item.component';
import { GuestCardComponent } from 'src/app/components/guest/guest-card/guest-card.component';
import { GuestItemComponent } from 'src/app/components/guest/guest-item/guest-item.component';
import { SeatingViewComponent } from 'src/app/components/seating/seating-view/seating-view.component';
import { GuestListComponent } from 'src/app/components/guest/guest-list/guest-list.component';
import { GuestNewComponent } from 'src/app/components/guest/guest-new/guest-new.component';
import { SeatingListComponent } from 'src/app/components/seating/seating-list/seating-list.component';
import { SeatingSwapPreviewComponent } from 'src/app/components/seating/seating-swap-preview/seating-swap-preview.component';
import { NgxFitTextModule } from '@pikselin/ngx-fittext';
import { InboxComponentsModule } from '../inbox/inbox.components.module';
import { TextFitDirective } from 'src/app/directives/text-fit';
import { Ng2FittextModule } from 'ng2-fittext';

@NgModule({
    declarations: [
        GuestImportComponent,
        GuestExportComponent,
        GuestListComponent,
        GuestNewComponent,
        GuestCardComponent,
        GuestItemComponent,
        GuestStatisticItemComponent,
        SeatingListComponent,
        SeatingViewComponent,
        SeatingSwapPreviewComponent,
        TextFitDirective,
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        Ng2FittextModule,
        NgxFitTextModule,
        ReactiveFormsModule,
        OfflineComponentsModule,
        SearchComponentsModule,
        SelectAllComponentsModule,
        BulkActionComponentsModule,
        GeneralComponentsModule,
        TranslateModule,
        InboxComponentsModule,
        ScrollingModule,
        ExperimentalScrollingModule,
    ],
    exports: [
        GuestImportComponent,
        GuestExportComponent,
        GuestListComponent,
        GuestNewComponent,
        GuestCardComponent,
        GuestItemComponent,
        GuestStatisticItemComponent,
        SeatingListComponent,
        SeatingViewComponent,
        SeatingSwapPreviewComponent,
        TextFitDirective,
    ]
})
export class GuestComponentsModule { }
