import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { UserService } from 'src/app/services/user/user.service';
import { UpdateByService } from 'src/app/services/user/update-by.service';
import { OnlineService } from 'src/app/services/general/online.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GroupListService } from 'src/app/services/group/group-list.service';
import { GroupManageService } from 'src/app/services/group/group-manage.service';
import { GroupChangeComponent } from 'src/app/components/group/group-change/group-change.component';
import { GroupCheckService } from 'src/app/services/group/group-check.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { Guest } from 'src/app/interfaces/guest';
import { Group } from 'src/app/interfaces/group';
import { UpdateBy } from 'src/app/interfaces/user';

/**
 * Guest manage service to save guest data.
 */
@Injectable({
  providedIn: 'root'
})
export class GuestManageService implements OnInit, OnDestroy {

  /**
   * Account ID
   */
  private accountId: string;

  /**
   * Constructor
   * @param modalController modal controller
   * @param afs angular firestore
   * @param fns firebase cloud function
   * @param translate translate service
   * @param guestService guest service
   * @param groupListService group list service
   * @param groupManageService group manage service
   * @param groupCheckService group check service
   * @param userService user service
   * @param errorService error service
   * @param onlineService online service
   * @param popupService popup service
   * @param functionService function service
   */
  constructor(
    private modalController: ModalController,
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private translate: TranslateService,
    private guestService: GuestService,
    private groupListService: GroupListService,
    private groupManageService: GroupManageService,
    private groupCheckService: GroupCheckService,
    private userService: UserService,
    private updateByService: UpdateByService,
    private errorService: ErrorService,
    private onlineService: OnlineService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Setup Account ID
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
  }

  /**
   * Get new guest ID from firestore
   * @returns New guest id
   */
  get guestId(): string {
    return this.accountId ? this.afs.collection(`accounts/${ this.accountId }/guests/`).ref.doc().id : '';
  }

  /**
   * Get new group ID from firestore
   * @returns New group id
   */
  get groupId(): string {
    return this.accountId ? this.afs.collection(`accounts/${ this.accountId }/groups/`).ref.doc().id : '';
  }

  /**
   * Update guest
   * @param data new data
   * @param guestList guest list
   * @param type update type
   * @param skipGroupChange skip group change flag
   */
  async updateGuest(data: any, guestList: string[], type?: string, skipGroupChange?: boolean, dismiss?: boolean){
    if (guestList?.length && !this.functionService.isEmpty(data)) {
      const groupList: Group[] = this.groupListService.generateGroupList(guestList);
      if (!skipGroupChange && guestList?.length && groupList?.length
      && this.checkGroupChange(guestList, this.functionService.cloneDeep(groupList))) {
        this.presentGroupChangeConfirm(guestList, groupList, data, type, dismiss);
      } else {
        this.saveDb(data, guestList, type, dismiss);
      }
    }
  }

  /**
   * Check group change
   * @param guestList selected guest list
   * @param groupList group list
   * @param type save type
   * @returns true if need present for group change
   */
  checkGroupChange(guestList: string[], groupList: Group[]): boolean {
    return this.groupCheckService.checkGroupSelected(guestList, groupList);
  }

  /**
   * Present group change confirm
   * @param guestList selected guest list
   * @param groupList group list
   * @param data data
   * @param mode guest list mode
   * @param type save type
   */
  async presentGroupChangeConfirm(guestList: string[], groupList: Group[], data: any, type: string, dimiss: boolean) {
    if (guestList?.length && !this.functionService.isEmpty(data)) {
      await this.popupService.dismissLoading();
      const modal = await this.popupService.presentConfirm(this.translate.instant('GROUP.change_prompt.guest'), '', '', '', '', '', true);
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          this.presentGroupChangeModal(guestList, groupList, data);
        } else if (!result?.data?.cancel) {
          this.saveDb(data, guestList, type, dimiss);
        }
      });
    }
  }

  /**
   * Present group change modal for apply change to group member
   * @param selectedGuestList selected guest list
   * @param groupList group list
   * @param newData new data
   * @param mode guest list mode
   * @param type save type
   */
  async presentGroupChangeModal(selectedGuestList: string[], groupList: Group[], newData: any) {
    const modal = await this.modalController.create({
      component: GroupChangeComponent,
      componentProps: {
        groupList,
        selectedGuestList,
        newData,
      }
    });
    modal.present();
    modal.onDidDismiss().then((result: any) => {
      this.saveDone(result?.data?.dismiss);
      if (result?.data?.dismiss) {

      }
    });
  }

  /**
   * Save guest db
   * @param data new data
   * @param guestList guest list
   * @param type save type
   */
  async saveDb(data: any, guestIdList: string[], type?: string, dismissModal?: boolean) {
    await this.saveGuest(data, guestIdList, type, [], false, [], dismissModal);
  }

  async saveDone(dismiss?: boolean) {
    await this.popupService.dismissLoading();
    if (dismiss) {
      await this.popupService.dismissModal(dismiss);
    }
  }

  /**
   * Setup data with actionby for guest and group, save into firestore
   * @param guestIdList guest id list
   * @param data save data for bulk update
   * @param type save type
   * @param guestList For individual update - with individual guest data updated
   * @param skipGroup skip group member check
   */
  async saveGuest(data: any, guestIdList: string[], type?: string, guestList?: Guest[],
                  skipGroup?: boolean, groupList?: Group[], dismissModal?: boolean) {
    if (guestIdList?.length && (data || guestList?.length)) {
      const updateBy: UpdateBy = this.updateByService.updateBy;
      const groupData: any = {};

      if (!skipGroup) {
        groupList = this.groupListService.generateGroupList(guestIdList);
      }

      if (!data) { data = {}; }

      if (type === 'new') {
        data.createBy = updateBy;
        data.updateBy = updateBy;
        if (groupList?.length) {
          groupData.createBy = updateBy;
          groupData.updateBy = updateBy;
        }
      } else {
        if (!this.functionService.isUndefined(data?.status?.gift)) {
          data.giftBy = updateBy;
        }

        if (!this.functionService.isUndefined(data?.status?.qrcode)) {
          data.shareBy = updateBy;
        }

        if (!this.functionService.isUndefined(data?.status?.checkin)) {
          if (data.status.checkin) {
            data.checkinBy = updateBy;
          } else {
            data.uncheckBy = updateBy;
          }
        }

        if (groupList?.length) {
          groupList?.forEach((group: Group) => {
            let flag = true;
            if (group?.memberList?.length) {
              group.memberList?.forEach((guestId: string) => {
                if (guestIdList?.indexOf(guestId) === -1) {
                  flag = false;
                }
              });
            }

            if (!group?.status) {
              group. status = {};
            }

            if (flag && data.checkinBy && data?.status?.checkin) {
              group.checkinBy = updateBy;
              groupData.checkinBy = updateBy;
              group.status.checkin = true;
            }

            if (flag && data.uncheckBy && !data?.status?.checkin) {
              group.uncheckBy = updateBy;
              groupData.uncheckBy = updateBy;
              group.status.checkin = false;
            }

            if (flag && data.shareBy) {
              if (data?.status?.qrcode) {
                group.shareBy = updateBy;
                groupData.shareBy = updateBy;
                group.status.qrcode = true;
              } else {
                group.shareBy = updateBy;
                groupData.shareBy = updateBy;
                group.status.qrcode = false;
              }
            }

            if (flag && data.giftBy) {
              if (data?.status?.gift) {
                group.giftBy = updateBy;
                groupData.giftBy = updateBy;
                group.status.gift = true;
              } else {
                group.giftBy = updateBy;
                groupData.giftBy = updateBy;
                group.status.gift = false;
              }
            }
          });
        }

        if (data?.status?.deleted || type === 'restore') {
          data.deleteBy = updateBy;
        }

        if ((!this.functionService.isEmpty(data) || !this.functionService.isEmpty(groupData)) && !data.giftBy && !data.checkinBy && !data.uncheckBy && !data.deleteBy && !data.createBy && !data.updateBy) {
          if (!this.functionService.isEmpty(data)) {
            data.updateBy = updateBy;
          }
          if (!this.functionService.isEmpty(groupData) && groupList?.length) {
            groupData.updateBy = updateBy;
          }
        }
      }

      if (guestList?.length) {
        guestList.map((guest: Guest) => {
          Object.keys(data)?.forEach((key: string) => {
            guest[key] = data[key];
          });
          return guest;
        });
      }

      if (!skipGroup && groupList?.length && !this.functionService.isEmpty(groupData)) {
        const groupIdList = groupList.map((group: Group) => {
          return group.groupId;
        });
        await this.groupManageService.saveGroupList(groupIdList, groupData, groupList);
      }
      await this.saveGuestListDb(data, guestIdList, guestList, dismissModal);
    }
  }

  /**
   * Save bulk guest list into firestore.
   * Cloud function, if online & > 100 records then save by firebase cloud function.
   * Batch commit, if online & > 1 records then save by batch commit.
   * Loop to save individually.
   * @param guestIdList Guest id list
   * @param data For generate update - new update data
   * @param guestList For individual update - with individual guest data updated.
   */
  async saveGuestListDb(data: any, guestIdList: string[], guestList?: Guest[], dismissModal?: boolean) {
    // let skipBatch = data?.seating && !data?.name ? true : false;
    // if (!skipBatch && guestList?.length) {
    //   const swapSeatingList = [ ...guestList ].filter((guest: Guest) => {
    //     return guest?.seating && !guest?.name;
    //   });
    //   if (swapSeatingList?.length === guestList?.length) {
    //     skipBatch = true;
    //   }
    // }

    // const skipBatch = false;
    if (this.onlineService.online && (guestIdList?.length > 100 || this.guestService.getGuestList()?.length > 500)) {
      // if (skipBatch) {
      //   this.saveIndividually(data, guestIdList, guestList);
      // }
      await this.fns.httpsCallable('batchSaveGuestCallV2')({
        guestIdList,
        data,
        guestList,
        accountId: this.accountId,
        updateBy: this.updateByService.updateBy,
        uid: this.userService.uid
      }).toPromise().then((response) => {
      }).catch(async (err) => {
        if (data) {
          console.log(data);
        }
        if (guestList?.length) {
          console.log(guestList);
        }
        this.errorService.logError(err);
        await this.saveIndividually(data, guestIdList, guestList);
      });
    } else if (this.onlineService.online && guestIdList?.length > 1) {
      const batch = this.afs.firestore.batch();
      guestIdList?.forEach((guestId: string) => {
        if (guestList) {
          const guestIndex = guestList?.findIndex((guest: Guest) => guest?.guestId === guestId);
          if (guestIndex !== -1) {
            data = guestList[guestIndex];
          }
        }
        const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/guests/${ guestId }/`);
        batch.set(ref, data, { merge: true });
      });
      try {
        await batch.commit().then(() => {

        }).catch((err: any) => {
          if (data) {
            console.log(data);
          }
          if (guestList?.length) {
            console.log(guestList);
          }
          this.errorService.logError(err);
        });
      } catch (err: any) {
        if (data) {
          console.log(data);
        }
        if (guestList?.length) {
          console.log(guestList);
        }
        this.errorService.logError(err);
        await this.saveIndividually(data, guestIdList, guestList);
      }
    } else {
      await this.saveIndividually(data, guestIdList, guestList);
    }
    await this.saveDone(dismissModal);
  }

  async saveIndividually(data: any, guestIdList: string[], guestList?: Guest[]) {
    for (const guestId of guestIdList) {
      if (guestList) {
        const guestIndex = guestList?.findIndex((x: Guest) => x?.guestId === guestId );
        if (guestIndex !== -1) {
          data = guestList[guestIndex];
        }
      }
      await this.saveGuestDb(data, guestId);
    }
  }

  /**
   * Save guest into firestore
   * @param guestId Guest ID
   * @param data Guest Data
   */
  async saveGuestDb(data: any, guestId: string) {
    if (this.accountId) {
      const guestRef = this.afs.firestore.doc(`accounts/${ this.accountId }/guests/${ guestId }`);
      guestRef.set(data, { merge: true }).catch((err: any) => {
        this.errorService.logError(err);
      });
    }
  }
}
