import { AttendingType, CheckinType, GiftReceivedType, GroupType, GuestFilterType, QrcodeType, SeatingType } from 'src/app/types/guest';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { GuestSummaryService } from 'src/app/services/guest/guest-summary.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { GuestStatisticComponent } from 'src/app/components/guest/guest-statistic/guest-statistic.component';
import { GuestListComponent } from 'src/app/components/guest/guest-list/guest-list.component';

import { Status } from 'src/app/interfaces/status';
import { SettingField } from 'src/app/interfaces/database';

import { ModuleType } from 'src/app/types/general';
import { GuestStatisticType, CardType } from 'src/app/types/statistic';
import { GuestListService } from 'src/app/services/guest/guest-list.service';
import { GiftService } from 'src/app/services/gift/gift.service';

/**
 * Guest statistic item card
 */
@Component({
  selector: 'app-guest-statistic-item',
  templateUrl: './guest-statistic-item.component.html',
  styleUrls: ['./guest-statistic-item.component.scss'],
})
export class GuestStatisticItemComponent implements OnInit, OnDestroy {

  @Input() set reset(reset: boolean) {
    if (reset) {
      this.initial();
    } else {
      this.unwatch();
    }
  }
  /**
   * Statistic type
   */
  @Input() statisticType: GuestStatisticType;
  /**
   * Statistic card type
   */
  @Input() cardType: CardType;
  /**
   * Attending type
   */
  @Input() set setAttendingType(attendingType: AttendingType ) {
    this.attendingType = attendingType;
    if (this.attendingType) {
      this.getData();
    }
  }
  /**
   * Seating type
   */
  @Input() set setSeatingType(seatingType: SeatingType ) {
    this.seatingType = seatingType;
    if (this.seatingType) {
      this.getData();
    }
  }
  /**
   * QR code type
   */
  @Input() set setQrcodeType(qrcodeType: QrcodeType ) {
    this.qrcodeType = qrcodeType;
    if (this.qrcodeType) {
      this.getData();
    }
  }
  /**
   * Group type
   */
  @Input() set setGroupType(groupType: GroupType ) {
    this.groupType = groupType;
    if (this.groupType) {
      this.getData();
    }
  }
  /**
   * Checkin type
   */
  @Input() set setCheckinType(checkinType: CheckinType ) {
    this.checkinType = checkinType;
    if (this.checkinType) {
      this.getData();
    }
  }

  @Input() set setGiftReceivedType(giftReceivedType: GiftReceivedType) {
    this.giftReceivedType = giftReceivedType;
    if (this.giftReceivedType) {
      this.getData();
    }
  }
  @Input() module: ModuleType;
  /**
   * Category
   */
  @Input() category: SettingField;
  /**
   * Dietary request
   */
  @Input() dietaryReq: SettingField;
  /**
   * Special request
   */
  @Input() specialReq: SettingField;

  @Input() session: SettingField;
  /**
   * Seating status
   */
  @Input() seating: any;
  /**
   * Guest status
   */
  @Input() status: Status;
  /**
   * All statistic
   */
  @Input() all: boolean;
  /**
   * Show statistic in number
   */
  @Input() showNumber: boolean;
  /**
   * Show statistic in percentage
   */
  @Input() showPercent: boolean;

  @Input() color: string;
  /**
   * Show total count
   */
  @Input() showTotal: boolean;
  /**
   * White background without progress bar
   */
  @Input() whiteBackground: boolean;
  /**
   * Display name for statistic card
   */
  @Input() name: string;
  /**
   * Set invited by for statistic card and get card data
   */
  @Input() set setInvitedBy(invitedBy: SettingField) {
    this.invitedBy = invitedBy;
    this.getData();
  }

  /**
   * Attending type
   */
  attendingType: AttendingType;
  /**
   * Seating type
   */
  seatingType: SeatingType;
  /**
   * QR code type
   */
  qrcodeType: QrcodeType;
  /**
   * Group type
   */
  groupType: GroupType;
  /**
   * Check-in type
   */
  checkinType: CheckinType;
  /**
   * Gift received type
   */
  giftReceivedType: GiftReceivedType
  /**
   * Count of current card
   */
  count: number;
  /**
   * Total of current type
   */
  total: number;
  /**
   * Percentage of current card
   */
  percent: number;
  /**
   * Margin for card
   */
  margin: string;
  /**
   * Invited by
   */
  invitedBy: SettingField;
  /**
   * Guest list subscription
   */
  private guestListSubscription: Subscription;

  private checkinListSubscription: Subscription;

  private giftListSubscription: Subscription;

  /**
   * Constructor
   * @param modalController modal controller
   * @param guestListService guest list service
   * @param popupService popup service
   * @param functionService general function service
   */
  constructor(
    private translate: TranslateService,
    private modalController: ModalController,
    private guestService: GuestService,
    private giftService: GiftService,
    private guestSummaryService: GuestSummaryService,
    private moduleService: ModuleService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  /**
   * Initialize component
   */
  ngOnInit() {
    this.setupCardType();
    this.setupMargin();
    this.initial();
  }

  ngOnDestroy(): void {
    this.unwatch();
  }

  unwatch() {
    this.unwatchGuestList();
    this.unwatchCheckinList();
    this.unwatchGiftList();
  }

  initial() {
    if (!this.module) {
      this.module = this.moduleService.currentModule;
    }
    if (this.statisticType === 'giftReceived') {
      this.watchGiftList();
    } else {
      this.watchGuestList();
      this.watchCheckinList();
    }
    this.getData();
  }

  /**
   * Watch guest list changes
   */
  async watchGuestList() {
    if (!this.guestListSubscription) {
      this.guestListSubscription = this.guestService.observableGuestList.subscribe(() => {
        this.getData();
      });
    }
    
  }

  /**
   * Unwatch guest list changes
   */
  async unwatchGuestList() {
    if (this.guestListSubscription) {
      this.guestListSubscription.unsubscribe();
      this.guestListSubscription = null;
    }
  }

  async watchCheckinList() {
    if (!this.checkinListSubscription) {
      this.checkinListSubscription = this.guestService.observableCheckinGuestList.subscribe(() => {
        this.getData();
      });
    }
    
  }

  async unwatchCheckinList() {
    if (this.checkinListSubscription) {
      this.checkinListSubscription.unsubscribe();
      this.checkinListSubscription = null;
    }
  }

  /**
   * Watch guest list changes
   */
  async watchGiftList() {
    if (!this.giftListSubscription) {
      this.giftListSubscription = this.giftService.observableGiftList.subscribe(() => {
        this.getData();
      });
    }
    
  }

  /**
   * Unwatch guest list changes
   */
  async unwatchGiftList() {
    if (this.giftListSubscription) {
      this.giftListSubscription.unsubscribe();
      this.giftListSubscription = null;
    }
  }

  /**
   * Setup card type
   */
  setupCardType() {
    if (!this.cardType) {
      this.cardType = 'block';
    }
  }

  /**
   * Setup margin
   */
  setupMargin() {
    if (this.cardType === 'square') {
      this.margin = '0';
    } else if (this.cardType === 'rect') {
      this.margin = '0';
    } else if (this.cardType === 'dashboard') {
      this.margin = '5px auto';
    } else {
      this.margin = '10px 0';
    }
  }

  showCard() {
    // if ((this.dietaryReq || this.specialReq)) {
    //   if (!this.count) {
    //     return false;
    //   }
    // }
    return true;
  }
  /**
   * Get card data
   */
  getData() {
    this.total = this.getTotalCount(this.all);
    // if (this.module === 'checkin') {
    //   this.total = this.getTotalCount(this.all, { attending: 'attending' });
    // } else {
    //   this.total = this.getTotalCount(this.all);
    // }
    // if (this.statisticType === 'giftReceived') {

    // } else {
    //   this.count = this.getGuestListCount(
    //     this.invitedBy, this.category, this.dietaryReq, this.specialReq, this.session, this.seating, this.groupType, this.status, this.all
    //   );
    // }

    this.count = this.getGuestListCount(
      this.invitedBy, this.category, this.dietaryReq, this.specialReq, this.session, this.seating, this.groupType, this.status, this.all
    );
    

    this.percent = this.getPercentCount(this.count, this.total);
  }

  /**
   * Get guest list count by criteria
   * @param invitedBy invited by
   * @param category category
   * @param dietaryReq dietary request
   * @param specialReq special request
   * @param seating seating type
   * @param group group status
   * @param status guest status
   * @param all all card of current card type
   */
  getGuestListCount(
    invitedBy?: SettingField,
    category?: SettingField,
    dietaryReq?: SettingField,
    specialReq?: SettingField,
    session?: SettingField,
    seating?: any,
    group?: string,
    status?: Status,
    all?: boolean
  ): number {
    if (!status) {
      status = {};
    }

    if (!all && !this.all) {
      if (this.attendingType && this.functionService.isUndefined(status.attending) && !all && !this.all) {
        status.attending = this.attendingType;
      }

      if (this.seatingType && !seating && !all && !this.all) {
        seating = this.seatingType;
      }

      if (this.qrcodeType && this.functionService.isUndefined(status.qrcode) && !all && !this.all) {
        if (this.qrcodeType === 'sent') {
          status.qrcode = true;
        } else if (this.qrcodeType === 'not_send') {
          status.qrcode = false;
        }
      }

      if (this.checkinType && this.functionService.isUndefined(status.checkin) && !all && !this.all) {
        if (this.checkinType === 'attended') {
          status.checkin = true;
        } else if (this.checkinType === 'not_attend') {
          status.checkin = false;
        }
      }

      if (this.giftReceivedType && this.functionService.isUndefined(status.gift) && !all && !this.all) {
        if (this.giftReceivedType === 'received') {
          status.gift = true;
        } else if (this.giftReceivedType === 'not_receive') {
          status.gift = false;
        }
      }

      if (this.groupType && this.functionService.isUndefined(group) && !all && !this.all) {
        group = this.groupType;
        if (this.module === 'checkin') {
          status.checkin = true;
        }
      }
    }

    // if (this.module === 'checkin' && this.checkinType === 'not_attend') {
    //   status.attending = 'attending';
    // }

    return this.guestSummaryService.getGuestListCount(invitedBy, category, dietaryReq, specialReq, session, seating, group, status, this.module, this.giftReceivedType ? true : false);
  }

  /**
   * Get total count of current card type
   * @param all all card of current card type
   */
  getTotalCount(all?: boolean, status?: Status): number {
    if (this.module === 'checkin') {
      if (this.groupType) {
        return this.getGuestListCount(this.invitedBy, this.category, this.dietaryReq, this.specialReq, this.session, null, this.groupType, status, true);
      }
      return this.getGuestListCount(this.invitedBy, this.category, this.dietaryReq, this.specialReq, this.session, null, null, status, true);
    }
    // if (this.statisticType === 'giftReceived') {
    //   const result = this.guestListService.searchGuestList();
    //   return result?.list?.length ? result.list.length : 0;
    // }
    return this.getGuestListCount(!this.invitedBy && all ? null : this.invitedBy, null, null, null, null, null, null,
      status, !this.invitedBy && all ? true : false);
  }

  /**
   * Calculate percentage
   * @param value count
   * @param total total
   */
  getPercentCount(value: number, total: number): number {
    let percent = 0;
    if (value && total) {
      percent = (value / total) * 100;
      if (percent > 100) {
        percent = 100;
      }
    }
    if (percent && percent > 0) {
      if (percent < 1) {
        if (percent < 0.1) {
          percent = 0.1;
        }
        percent = Math.round(percent * 10) / 10;
      } else {
        if (percent > 99 && percent < 100) {
          percent = 99;
        }
        percent = Math.round(percent);
      }
    }
    return percent;
  }

  /**
   * Present modal
   */
  async presentModal() {
    if (this.whiteBackground) {
      this.presentGuestStatisticModal(this.statisticType);
    } else {
      this.presentGuestListModal(this.invitedBy, this.category, this.dietaryReq, this.specialReq, this.session, this.groupType, this.checkinType);
    }
  }

  /**
   * Present guest statistic modal
   * @param statisticType Statistic type
   */
  async presentGuestStatisticModal(statisticType: GuestStatisticType) {
    const modal = await this.modalController.create({
      component: GuestStatisticComponent,
      cssClass: '',
      componentProps: {
        statisticType,
        invitedBy: this.invitedBy,
        attendingType: this.attendingType,
        seatingType: this.seatingType,
        qrcodeType: this.qrcodeType,
        groupType: this.groupType,
        checkinType: this.checkinType,
        giftReceivedType: this.giftReceivedType,
        module: this.module,
      }
    });
    await modal.present();
    // if (this.cardType === 'dashboard') {
    //   this.moduleService.setCurrentModule(this.module);
    //   modal.onWillDismiss().then(() => {
    //     this.moduleService.setCurrentModule('account');
    //   });
    // }
  }

  /**
   * Present guest list modal
   * @param invitedBy invited by
   * @param category category
   * @param dietaryReq dietary request
   * @param specialReq special request
   * @param groupType group type
   */
  async presentGuestListModal(
    invitedBy?: SettingField, category?: SettingField,
    dietaryReq?: SettingField, specialReq?: SettingField, session?: SettingField,
    groupType?: string, checkinType?: CheckinType, giftReceivedType?: GiftReceivedType
  ) {
    await this.popupService.presentLoading();
    const type: GuestFilterType = {
      qrcode: this.qrcodeType,
      seating: this.seatingType,
      attending: this.attendingType,
      group: this.groupType,
      checkin: this.checkinType,
      giftReceived: this.giftReceivedType,
    };
    const status: any = {};

    if (this.attendingType && this.functionService.isUndefined(status.attending)) {
      status.attending = this.attendingType;
    }

    if (this.qrcodeType && this.functionService.isUndefined(status.qrcode)) {
      status.qrcode = this.qrcodeType;
    }

    if (this.groupType && this.functionService.isUndefined(groupType)) {
      groupType = this.groupType;
    }

    if (checkinType) {
      if (checkinType === 'attended') {
        status.checkin = true;
      } else if (checkinType === 'not_attend') {
        status.checkin = false;
      }
    } else if (this.checkinType) {
      if (this.checkinType === 'attended') {
        status.checkin = true;
      } else if (this.checkinType === 'not_attend') {
        status.checkin = false;
      }
    }

    if (giftReceivedType) {
      if (giftReceivedType === 'received') {
        status.gift = true;
      } else if (giftReceivedType === 'not_receive') {
        status.gift = false;
      }
    } else if (this.giftReceivedType) {
      if (this.giftReceivedType === 'received') {
        status.gift = true;
      } else if (this.giftReceivedType === 'not_receive') {
        status.gift = false;
      }
    }

    const defaultFilter: any = {
      enable: false,
      show: false,
      invitedBy: invitedBy ? invitedBy : null,
      category: category ? [ category ] : null,
      dietaryReq: dietaryReq ? [ dietaryReq ] : null,
      specialReq: specialReq ? [ specialReq ] : null,
      session: session ? [ session ] : null,
    };

    if (invitedBy || category || dietaryReq || specialReq || session) {
      defaultFilter.enable = true;
      defaultFilter.show = false;
    }

    let title = '';
    if (this.invitedBy?.value) {
      title = !this.invitedBy?.custom ? this.translate.instant('LIST.invited_by.' + this.invitedBy.value) : this.invitedBy.value;
    }
    if (this.category?.value) {
      const value = !this.category?.custom ? this.translate.instant('LIST.category.' + this.category.value) : this.category.value;
      title = title ? title + ' - ' + value : value;
    }
    if (this.dietaryReq?.value) {
      const value = !this.dietaryReq?.custom ? this.translate.instant('LIST.dietary_req.' + this.dietaryReq.value) : this.dietaryReq.value;
      title = title ? title + ' - ' + value : value;
    }
    if (this.specialReq?.value) {
      const value = !this.specialReq?.custom ? this.translate.instant('LIST.special_req.' + this.specialReq.value) : this.specialReq.value;
      title = title ? title + ' - ' + value : value;
    }
    if (this.session?.value) {
      const value = !this.session?.custom ? this.translate.instant('LIST.session.' + this.session.value) : this.session.value;
      title = title ? title + ' - ' + value : value;
    }

    const modal = await this.modalController.create({
      component: GuestListComponent,
      cssClass: '',
      componentProps: {
        defaultFilter,
        type,
        title,
        mode: 'list'
      }
    });
    modal.present();
    this.popupService.dismissLoading();
  }

}
