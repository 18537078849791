import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Language } from 'src/app/interfaces/database';
import { FunctionService } from '../general/function.service';
import { LanguageService } from '../general/language.service';
import { VisitorManageService } from './visitor-manage.service';

@Injectable({
  providedIn: 'root'
})
export class VisitorLanguageService implements OnInit, OnDestroy {

  language: string;
  /**
   * Page language list
   */
  pageLangList: Language[];
  /**
   * Observable page language list
   */
  observablePageLangList: any;

  constructor(
    private languageService: LanguageService,
    private visitorManageService: VisitorManageService,
    private functionService: FunctionService,
  ) {
    this.observablePageLangList = new BehaviorSubject<Language[]>(this.pageLangList);
  }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  setupLanguage(language: string) {
    this.language = language;
    this.visitorManageService.language = language;
  }

  setupPageLangList(pageLangList?: Language[]) {
    if (pageLangList && !this.functionService.isEqual(pageLangList, this.pageLangList)) {
      this.pageLangList = pageLangList;
      this.visitorManageService.pageLangList = pageLangList;
      this.observablePageLangList.next(this.pageLangList);
    }
  }

  getPangeLangList(): Language[] {
    return this.pageLangList?.length ? this.pageLangList : this.getDefaultPageLangList();
  }

  getDefaultPageLang(): Language {
    return this.languageService.getAccountLanguage();
  }

  getDefaultPageLangList(): Language[] {
    const language = this.languageService.getAccountLanguage();
    return [{
      code: language?.code,
      name: language?.name,
    }];
  }
}
