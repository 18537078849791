
<div class="font-10 ion-text-wrap">
  <ng-container *ngIf="accountStatus?.trial && !accountStatus?.premium">
    <ion-icon class="icon-small status-icon" src="./assets/wedding/icon/account/trial.svg"></ion-icon>{{ 'ACCOUNT.lbl.trial' | translate }}
    <ng-container *ngIf="trialEnd">
      <ng-container *ngIf="trialEnd !== 'tomorrow' && trialEnd !== 'today' && trialEnd !== 'expired'"> ( {{ 'EXPIRY.nday' | translate: { n: trialEnd } }} )</ng-container>
      <ng-container *ngIf="trialEnd === 'tomorrow'"> ( {{ 'EXPIRY.1day' | translate }} )</ng-container>
      <ng-container *ngIf="trialEnd === 'today'"> ( {{ 'EXPIRY.today' | translate }} )</ng-container>
      <ng-container *ngIf="trialEnd === 'expired'"> ( {{ 'EXPIRY.expired' | translate }} )</ng-container>
    </ng-container>
    <div *ngIf="showSubscribe" (click)="goUpgrade()">
      &nbsp;<ion-text class="font-underline">{{ 'EXPIRY.upgrade' | translate }}</ion-text>
    </div>
  </ng-container>
  <ng-container *ngIf="accountStatus?.premium">
    <ion-icon class="icon-small status-icon" src="./assets/wedding/icon/account/premium.svg"></ion-icon>{{ 'ACCOUNT.lbl.premium' | translate }}
  </ng-container>
</div>
