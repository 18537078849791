import { BlastPackage } from "../interfaces/blast";

export const BlastPackageList: BlastPackage[] = [
	{
		blastPackageId: 'blast_50',
		enable: true,
		blastCredit: 50,
		price: [
			{
				currency: 'USD',
				amount: 0.02,
			},
			{
				currency: 'MYR',
				amount: 0.093,
			},
			{
				currency: 'SGD',
				amount: 0.027,
			},
			{
				currency: 'HKD',
				amount: 0.158,
			},
			{
				currency: 'TWD',
				amount: 0.6,
			}
		]
	},
	{
		blastPackageId: 'blast_250',
		enable: true,
		blastCredit: 250,
		price: [
			{
				currency: 'USD',
				amount: 0.0175,
			},
			{
				currency: 'MYR',
				amount: 0.081,
			},
			{
				currency: 'SGD',
				amount: 0.0236,
			},
			{
				currency: 'HKD',
				amount: 0.138,
			},
			{
				currency: 'TWD',
				amount: 0.525,
			}
		]
	},
	{
		blastPackageId: 'blast_500',
		enable: true,
		blastCredit: 500,
		price: [
			{
				currency: 'USD',
				amount: 0.015,
			},
			{
				currency: 'MYR',
				amount: 0.069,
			},
			{
				currency: 'SGD',
				amount: 0.0202,
			},
			{
				currency: 'HKD',
				amount: 0.0118,
			},
			{
				currency: 'TWD',
				amount: 0.45,
			}
		]
	},
	{
		blastPackageId: 'blast_750',
		enable: true,
		blastCredit: 750,
		price: [
			{
				currency: 'USD',
				amount: 0.0125,
			},
			{
				currency: 'MYR',
				amount: 0.058,
			},
			{
				currency: 'SGD',
				amount: 0.01687,
			},
			{
				currency: 'HKD',
				amount: 0.09875,
			},
			{
				currency: 'TWD',
				amount: 0.375,
			}
		]
	},
	{
		blastPackageId: 'blast_1000',
		enable: true,
		blastCredit: 1000,
		price: [
			{
				currency: 'USD',
				amount: 0.01,
			},
			{
				currency: 'MYR',
				amount: 0.0465,
			},
			{
				currency: 'SGD',
				amount: 0.0135,
			},
			{
				currency: 'HKD',
				amount: 0.079,
			},
			{
				currency: 'TWD',
				amount: 0.30,
			}
		]
	}
];