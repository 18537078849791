import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { GiftService } from 'src/app/services/gift/gift.service';
import { GiftLogService } from 'src/app/services/gift/gift-log.service';
import { GiftDeleteService } from 'src/app/services/gift/gift-delete.service';
import { GiftManageService } from 'src/app/services/gift/gift-manage.service';

/**
 * Gift setup service
 */
@Injectable({
  providedIn: 'root'
})
export class GiftSetupService implements OnInit, OnDestroy {

  /**
   * Constructor
   * @param giftService Gift service
   * @param giftManageService Gift manage service
   * @param giftDeleteService Gift Delete service
   */
  constructor(
    private giftService: GiftService,
    private giftManageService: GiftManageService,
    private giftDeleteService: GiftDeleteService,
    private giftLogService: GiftLogService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Setup Account ID
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    await this.giftService.setupAccountId(accountId);
    await this.giftManageService.setupAccountId(accountId);
    await this.giftDeleteService.setupAccountId(accountId);
    await this.giftLogService.setupAccountId(accountId);
  }
}
