import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { GuestDeleteService } from 'src/app/services/guest/guest-delete.service';
import { GuestLogService } from 'src/app/services/guest/guest-log.service';
import { GroupSetupService } from 'src/app/services/group/group-setup.service';
import { CheckinSettingService } from 'src/app/services/checkin/checkin-setting.service';
import { QrcodeTemplateService } from 'src/app/services/qrcode/qrcode-template.service';
import { GiftSetupService } from 'src/app/services/gift/gift-setup.service';
import { FunctionService } from '../general/function.service';

/**
 * Guest setup service
 */
@Injectable({
  providedIn: 'root'
})
export class GuestSetupService implements OnInit, OnDestroy {

  /**
   * Constructor
   * @param guestService guest service
   * @param guestManageService guest manage service
   * @param guestDeleteService guest delete service
   * @param guestLogService guest log service
   * @param groupSetupService group setup service
   * @param qrcodeTemplateService qrcode service
   * @param checkinSettingService checkin setting service
   * @param giftSetupService gift setup service
   */
  constructor(
    private guestService: GuestService,
    private guestManageService: GuestManageService,
    private guestDeleteService: GuestDeleteService,
    private guestLogService: GuestLogService,
    private groupSetupService: GroupSetupService,
    private qrcodeTemplateService: QrcodeTemplateService,
    private checkinSettingService: CheckinSettingService,
    private giftSetupService: GiftSetupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Setup Account ID and start guest related services
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    await this.groupSetupService.setupAccountId(accountId);
    // await this.functionService.delay(500);
    await this.guestService.setupAccountId(accountId);
    // await this.functionService.delay(500);
    await this.qrcodeTemplateService.setupAccountId(accountId);
    // await this.functionService.delay(200);
    await this.checkinSettingService.setupAccountId(accountId);

    await this.guestManageService.setupAccountId(accountId);
    await this.guestDeleteService.setupAccountId(accountId);
    await this.guestLogService.setupAccountId(accountId);
    
    // await this.functionService.delay(200);
    await this.giftSetupService.setupAccountId(accountId);
    // await this.functionService.delay(200);
  }
}
