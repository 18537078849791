<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'EXPORT.title' | translate }}
    </ion-title>
  </ion-toolbar>
  <ion-toolbar>
    <ion-segment scrollable [(ngModel)]="exportType">
      <ion-segment-button value="guestList">
        <ion-label>{{ 'MENU.guest_list' | translate }}</ion-label>
      </ion-segment-button>
      <ion-segment-button value="qrcode">
        <ion-label>{{ 'MODULE.list.qrcode' | translate }}</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div *ngIf="exportType === 'guestList'">
    <ion-label color="dark">
      <div class="ion-margin ion-padding-vertical">
        <div class="font-bold ion-text-center ion-padding-vertical">
          {{ 'EXPORT.guestList.msg.intro' | translate }}
        </div>
        <div>
          <ul class="font-12">
            <li class="ion-margin-vertical">{{ 'EXPORT.guestList.msg.point1' | translate }}</li>
            <li class="ion-margin-vertical">{{ 'EXPORT.guestList.msg.point2' | translate }}</li>
          </ul>
        </div>
      </div>
    </ion-label>
  </div>
  <div *ngIf="exportType === 'qrcode'">
    <ion-label color="dark">
      <div class="ion-margin ion-padding-vertical">
        <div class="font-bold ion-text-center ion-padding-vertical">
          {{ 'EXPORT.qrcode.msg.intro' | translate }}
        </div>
        <div>
          <ul class="font-12">
            <li class="ion-margin-vertical">{{ 'EXPORT.qrcode.msg.point1' | translate }}</li>
            <li class="ion-margin-vertical">{{ 'EXPORT.qrcode.msg.point2' | translate }}</li>
            <li class="ion-margin-vertical">{{ 'EXPORT.qrcode.msg.point3' | translate }}</li>
          </ul>
        </div>
      </div>
    </ion-label>
  </div>

</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button class="ion-margin" expand="block" (click)="download()">
      {{ 'BTN.download' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>