import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

/**
 * Select all component to select all list if edit mode trigger, else display total count in the list.
 */
@Component({
  selector: 'app-select-all',
  templateUrl: './select-all.component.html',
  styleUrls: ['./select-all.component.scss'],
})
export class SelectAllComponent implements OnInit, OnDestroy {

  /**
   * Edit mode, show select all btn
   */
  @Input() editMode: boolean;
  /**
   * Expand mode, show expand all btn.
   */
  @Input() expandMode: boolean;
  /**
   * Expand all status
   */
  @Input() expandAll: boolean;
  /**
   * Select all status
   */
  @Input() selectAll: boolean;
  /**
   * Selected count
   */
  @Input() selected: number;
  /**
   * Total count
   */
  @Input() total: number;
  /**
   * Hide total count
   */
  @Input() hideTotal: boolean;

  /**
   * Toggle select all
   */
  @Output() toggleSelect = new EventEmitter();
  /**
   * Toggle expand all
   */
  @Output() toggleExpand = new EventEmitter();

  /**
   * Constructor
   */
  constructor() { }

  ngOnInit() {}

  ngOnDestroy() {}

  /**
   * Toggle select all
   */
  toggleSelectAll() {
    this.toggleSelect.next('');
  }

  /**
   * Toggle expand all
   */
  toggleExpandAll() {
    this.toggleExpand.next('');
  }

}
