<ion-card class="ion-padding ion-margin">
  <ion-item class="ion-item-no-padding" lines="none"> 
    <ion-avatar slot="start">
      <ion-icon class="icon-large" src="./assets/wedding/icon/general/switch.svg"></ion-icon>
    </ion-avatar>
    <ion-label class="font-10" color="dark">
      <div>
        <p class="font-bold font-underline">{{ 'LBL.changes' | translate }}:</p>
      </div>
      <div *ngIf="newData?.status?.attending">
        <ion-text class="filter-label font-bold">{{ 'LBL.status' | translate }}: </ion-text>
        <ion-text>{{ 'LIST.attending_status.' + newData.status.attending | translate }}</ion-text>
      </div>
      <div *ngIf="newData.invitedBy">
        <ion-text class="filter-label font-bold">{{ 'GUEST.lbl.invited_by' | translate }}: </ion-text>
        <ion-text>
          {{ getSettingField('invited_by', newData.invitedBy) }}
        </ion-text>
      </div>
      <div *ngIf="newData.category">
        <ion-text class="filter-label font-bold">{{ 'LBL.category' | translate }}: </ion-text>
        <ion-text>
          {{ getSettingField('category', newData.category) }}
        </ion-text>
      </div>
      <div *ngIf="newData.seating">
        <ion-text class="filter-label font-bold">{{ seatingTypeName }}: </ion-text>
        <ion-text class="font-10">{{ newData.seating }}</ion-text>
      </div>
      <div *ngIf="newData.dietaryReq">
        <ion-text class="filter-label font-bold">{{ 'GUEST.lbl.dietary_req' | translate }}: </ion-text>
        <ion-text>
          {{ getSettingField('dietary_req', newData.dietaryReq) }}
        </ion-text>
      </div>
      <div *ngIf="newData.specialReq">
        <ion-text class="filter-label font-bold">{{ 'GUEST.lbl.special_req' | translate }}: </ion-text>
        <ion-text>
          {{ getSettingField('special_req', newData.specialReq) }}
        </ion-text>
      </div>
      <div *ngIf="newData.email">
        <ion-text class="filter-label font-bold">{{ 'LBL.email' | translate }}: </ion-text>
        <ion-text class="font-10">{{ newData.email }}</ion-text>
      </div>
      <div *ngIf="newData?.mobile?.no">
        <ion-text class="filter-label font-bold">{{ 'MOBILE.lbl.no' | translate }}: </ion-text>
        <ion-text class="font-10">{{ newData.mobile.no }}</ion-text>
      </div>
      <div *ngIf="newData.remark">
        <ion-text class="filter-label font-bold">{{ 'GUEST.lbl.remark' | translate }}: </ion-text>
        <ion-text class="font-10">{{ newData.remark }}</ion-text>
      </div>
    </ion-label>
  </ion-item>
</ion-card>