import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActionSheetController, IonList, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestListService } from 'src/app/services/guest/guest-list.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { GuestDeleteService } from 'src/app/services/guest/guest-delete.service';
import { CheckinService } from 'src/app/services/checkin/checkin.service';
import { SeatingService } from 'src/app/services/seating/seating.service';
import { SeatingManageService } from 'src/app/services/seating/seating-manage.service';
import { SeatingSummaryService } from 'src/app/services/seating/seating-summary.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { GuestListComponent } from 'src/app/components/guest/guest-list/guest-list.component';
import { GuestEditComponent } from 'src/app/components/guest/guest-edit/guest-edit.component';
import { SeatingListComponent } from 'src/app/components/seating/seating-list/seating-list.component';
import { SeatingEditComponent } from 'src/app/components/seating/seating-edit/seating-edit.component';
import { SeatingSwapPreviewComponent } from 'src/app/components/seating/seating-swap-preview/seating-swap-preview.component';

import { Guest } from 'src/app/interfaces/guest';
import { SeatingSummary } from 'src/app/interfaces/seating';
import { Seating } from 'src/app/interfaces/seating';
import { ModuleType, SettingFieldType } from 'src/app/types/general';
import { GuestFilterType, GuestListMode } from 'src/app/types/guest';
import { SettingField } from 'src/app/interfaces/database';
import { Group } from 'src/app/interfaces/group';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CurrentPrivilege } from 'src/app/interfaces/privilege';

/**
 * Seating detail component
 */
@Component({
  selector: 'app-seating-detail',
  templateUrl: './seating-detail.component.html',
  styleUrls: ['./seating-detail.component.scss'],
})
export class SeatingDetailComponent implements OnInit, OnDestroy {
  @ViewChild('content', { read: ElementRef }) contentElement: ElementRef;
  
  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  /**
   * Ion list, close opened ion item.
   */
  @ViewChild('list') list: IonList;

  limit: number;
  /**
   * AccountModule
   */
  module: ModuleType;
  /**
   * Guest ist mode
   */
  mode: GuestListMode;
  /**
   * Edit mode, bulk edit guest in current seating
   */
  editMode: boolean;
  /**
   * Swap mode, swap guest with another seating
   */
  swapMode: boolean;
  /**
   * Assign mode, assign guest into current seating.
   */
  assignMode: boolean;
  /**
   * Seating
   */
  seating: Seating;
  /**
   * Seating summary
   */
  seatingSummary: SeatingSummary;
  /**
   * Select all
   */
  selectAll: boolean;
  /**
   * Selected guest id list
   */
  selectedGuestList: string[];
  /**
   * Selected group id list
   */
  selectedGroupList: string[];
  /**
   * Guest list
   */
  guestList: Guest[];
  /**
   * New guest list, to be updated into current seating.
   */
  newGuestList: Guest[];
  newGroupList: Group[];
  /**
   * Attended count
   */
  attendedCount: number;
  /**
   * guest count
   */
  count: number;

  color: string;

  invitedBy: string;
  category: string;
  dietaryReq: string;
  specialReq: string;

  currentPrivilege: CurrentPrivilege;

  seatingTypeName: string;

  /**
   * Guest list subscription
   */
  private guestListSubscription: Subscription;
  /**
   * Seating subscription
   */
  private seatingSubscription: Subscription;

  private privilegeSubscription: Subscription;

  /**
   * constructor
   * @param modalController modal controller
   * @param actionSheetController action sheet controller
   * @param translate translate service
   * @param seatingService seating service
   * @param seatingManageService seating manage service
   * @param guestService guest service
   * @param guestListService guest list service
   * @param guestSummaryService guest summary service
   * @param guestManageService guest manage service
   * @param guestDeleteService guest delete service
   * @param checkinService checkin service
   * @param moduleService module service
   * @param popupService popup service
   * @param functionService funtion service
   */
  constructor(
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private translate: TranslateService,
    private settingFieldService: SettingFieldService,
    private guestService: GuestService,
    private guestListService: GuestListService,
    private guestManageService: GuestManageService,
    private guestDeleteService: GuestDeleteService,
    private seatingService: SeatingService,
    private seatingSettingService: SeatingSettingService,
    private seatingManageService: SeatingManageService,
    private seatingSummaryService: SeatingSummaryService,
    private checkinService: CheckinService,
    private moduleService: ModuleService,
    private privilegeService: PrivilegeService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {
    this.unwatch();
  }

  /**
   * Initialize
   */
  ionViewWillEnter() {
    this.initialize();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatch();
    this.limit = this.getLimit();
  }

  /**
   * Initiali Empty selected list.
   * Start watch guest and seating.
   * Setup new guest list for swap / assign mode
   */
  initialize() {
    this.module = this.moduleService.currentModule;
    this.selectedGuestList = [];
    this.selectedGroupList = [];

    this.seatingTypeName = this.getSeatingTypeName();
    
    this.watch();
    this.getSeatingGuest();
    setTimeout(() => {
      this.limit = this.getLimit();
    }, 200);
  }


  watch() {
    this.watchGuest();
    this.watchSeating();
    this.watchPrivilege();
  }

  unwatch() {
    this.unwatchGuest();
    this.unwatchSeating();
    this.unwatchPrivilege();
  }

  /**
   * Watch seating changes
   */
  async watchSeating() {
    if (!this.seatingSubscription) {
      this.seatingSubscription = this.seatingService.observableSeatingList.subscribe(() => {
        this.getSeatingInfo();
      });
    }
    
  }

  /**
   * Watch guest changes
   */
  async watchGuest() {
    if (!this.guestListSubscription) {
      this.guestListSubscription = this.guestService.observableGuestList.subscribe(() => {
        this.getSeatingGuest();
      });
    }
    
  }

  /**
   * Unwatch guest changes
   */
  async unwatchGuest() {
    if (this.guestListSubscription) {
      this.guestListSubscription.unsubscribe();
      this.guestListSubscription = null;
    }
  }

  /**
   * Unwatch seating changes
   */
  async unwatchSeating() {
    if (this.seatingSubscription) {
      this.seatingSubscription.unsubscribe();
      this.seatingSubscription = null;
    }
  }

  async watchPrivilege() {
    if (!this.privilegeSubscription) {
      this.privilegeSubscription = this.privilegeService.observableCurrentPrivilege.subscribe(() => {
        this.setupPrivilege();
      })
    }
  }

  async unwatchPrivilege() {
    if (this.privilegeSubscription) {
      this.privilegeSubscription.unsubscribe();
      this.privilegeSubscription = null;
    }
  }

  setupPrivilege() {
    if (!this.currentPrivilege) {
      this.currentPrivilege = {};
    }
    if (this.module) {
      this.currentPrivilege = {
        [this.module]: {
          'seating': this.checkPrivilege(this.module, 'seating'),
        }
      };
    }
  }

  setupColor() {
    this.color = this.getColor();
  }

  setupInvitedBy() {
    if (this.seatingSummary?.invitedBy?.length) {
      this.invitedBy = this.getSettingField('invited_by', this.seatingSummary.invitedBy);
    } else if (this.seating?.invitedBy) {
      this.invitedBy = this.getSettingField('invited_by', this.seating.invitedBy);
    } else {
      this.invitedBy = '';
    }
  }

  setupCategory() {
    if (this.seatingSummary?.category?.length) {
      this.category = this.getSettingField('category', this.seatingSummary.category);
    } else if (this.seating?.category) {
      this.category = this.getSettingField('category', this.seating.category);
    } else {
      this.category = '';
    }
  }

  setupDietaryReq() {
    this.dietaryReq = this.getSettingField('dietary_req', this.seatingSummary?.dietaryReq, true, true);
  }

  setupSpecialReq() {
    this.specialReq = this.getSettingField('special_req', this.seatingSummary?.specialReq, true, true);
  }

  /**
   * Get seating info by seating id.
   */
  getSeatingInfo() {
    if (this.seating && this.seating.seatingId) {
      this.seating = { ...this.seatingService.getSeatingById(this.seating.seatingId) };
      this.setupColor();
      this.setupInvitedBy();
      this.setupCategory();
    }
  }

  /**
   * Get seating summary & guest
   */
  getSeatingGuest() {
    if (this.seating) {
      this.seatingSummary = this.seatingSummaryService.getSeatingSummary(this.seating);

      if (this.assignMode && this.newGuestList?.length) {
        this.newGuestList = this.newGuestList.filter((guest: Guest) => {
          const index = this.seatingSummary?.guestList?.findIndex((x: Guest) => x?.guestId === guest?.guestId );
          return index === -1 ? true : false;
        });
        this.selectedGuestList = this.newGuestList.map((guest: Guest) => {
          return guest?.guestId;
        });
        if (this.newGroupList?.length) {
          this.selectedGroupList = this.newGroupList.map((group: Group) => {
            return group?.groupId;
          });
        }
        if (this.newGuestList?.length) {
          this.newGuestList.forEach((guest: Guest) => {
            if (guest?.groupId && this.selectedGroupList.indexOf(guest.groupId) === -1) {
              this.selectedGroupList.push(guest.groupId);
            }
          });
        }
        this.guestList = this.guestListService.generateGroupList([...this.seatingSummary.guestList, ...this.newGuestList]);
        this.count = this.seatingSummary.guestList.length + this.newGuestList.length;
      } else if (this.seatingSummary?.guestList?.length) {
        this.guestList = this.guestListService.generateGroupList(this.seatingSummary.guestList);
        this.count = this.seatingSummary.guestList.length;
      } else {
        this.guestList = [];
        this.count = 0;
      }
      this.setupColor();
      this.setupInvitedBy();
      this.setupCategory();
      this.setupDietaryReq();
      this.setupSpecialReq();
      this.setupViewport();
      this.setupAttendedCount();
    }
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport) {
      this.cdkVirtualScrollViewport?.checkViewportSize();
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @param showCount Show setting field count
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[], showCount?: boolean, newLine?: boolean): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList, true, showCount, newLine);
  }

  getSeatingTypeName(): string {
    return this.seatingSettingService.getSeatingTypeName();
  }

  /**
   * Setup color
   */
  getColor(): string {
    if (!this.seating?.seatingId && !this.seating.name) {
      return 'medium';
    } else if (this.module === 'checkin') {
      return this.attendedCount && this.attendedCount >= this.seatingSummary?.guestList?.length ? 'success'
      : this.attendedCount ? 'warning' : 'white';
    } else {
      return this.seatingSummary?.guestList?.length >= this.seating?.maxGuest ? 'success' : this.seatingSummary?.guestList?.length ? 'warning' : 'white';
    }
  }

  /**
   * Seutp attended guest count
   */
  setupAttendedCount() {
    if (this.module === 'checkin' && this.seatingSummary?.guestList?.length) {
      this.attendedCount = this.seatingSummary.guestList.filter((guest: Guest) => {
        if (guest?.status?.checkin) {
          return true;
        } else {
          return false;
        }
      }).length;
    } else {
      this.attendedCount = 0;
    }
  }

  /**
   * Toggle edit mode.
   * Reset selected list.
   * Close opened sliding item.
   */
   toggleEditMode(editMode?: boolean) {
    if (this.functionService.isUndefined(editMode)) {
      this.editMode = !this.editMode;
    } else {
      this.editMode = editMode;
    }
    this.resetSelectAll();
    this.closeSliding();
  }

  /**
   * Reset select all and list.
   */
  resetSelectAll() {
    this.selectAll = false;
    this.selectedGuestList = [];
    this.selectedGroupList = [];
  }

  /**
   * Toggle select all.
   * Check for selected group.
   */
  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    this.selectedGuestList = [];
    this.selectedGroupList = [];
    if (this.selectAll) {
      this.guestList?.forEach((guest: Guest) => {
        if (guest?.group?.groupId) {
          this.setSelectedGroup(guest.group.groupId, true);
        } else if (guest?.guestId) {
          this.selectedGuestList.push(guest.guestId);
        }
      });
    }
  }

  /**
   * Check select all status
   */
  checkSelectAll() {
    if (this.selectedGuestList.length === this.seatingSummary.guestList.length) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  }

  /**
   * Set guest selected by guest id.
   * @param guestId Guest ID
   */
  setSelectedGuest(guestId: string) {
    const index = this.selectedGuestList?.indexOf(guestId);
    if (index === -1) {
      this.selectedGuestList.push(guestId);
    } else {
      this.selectedGuestList?.splice(index, 1);
    }
    this.checkSelectAll();
  }

  /**
   * Set group selected by Group ID.
   * @param groupId Group ID.
   * @param selected Select status.
   * @param skipCheck Skip check selected group to prevent infinite loop.
   */
  setSelectedGroup(groupId: string, selected?: boolean, skipCheck?: boolean) {
    const index = this.selectedGroupList?.indexOf(groupId);
    if (index === -1) {
      if (this.functionService.isUndefined(selected) || selected === true) {
        this.selectedGroupList.push(groupId);
        if (!skipCheck) {
          this.checkSelectedGroup(groupId, true, this.functionService.isUndefined(selected) ? false : true);
        }
      }
    } else {
      if (this.functionService.isUndefined(selected) || selected === false) {
        if (this.selectedGroupList?.length) {
          this.selectedGroupList?.splice(index, 1);
        }
        if (!skipCheck) {
          this.checkSelectedGroup(groupId, false, this.functionService.isUndefined(selected) ? false : true);
        }
      }
    }
  }

  /**
   * Check selected group by Group ID.
   * @param groupId Groupd Id.
   * @param selected Select status.
   * @param skipCheck Skip select all for preset status
   */
  checkSelectedGroup(groupId: string, selected?: boolean, skipCheck?: boolean) {
    const index = this.guestList?.findIndex((x) => x?.group?.groupId === groupId);
    let memberList: string[] = [];
    if (index !== -1 && this.guestList?.[index]?.group?.memberList?.length) {
      memberList = this.guestList[index].group.memberList;
    }

    if (memberList) {
      let flag = true;
      memberList?.forEach((guestId: string) => {
        const index2 = this.selectedGuestList?.indexOf(guestId);
        if (index2 === -1) {
          flag = false;
          if (!this.functionService.isUndefined(selected) && selected === true) {
            this.selectedGuestList.push(guestId);
          }
        } else {
          if (!this.functionService.isUndefined(selected) && selected === false) {
            this.selectedGuestList?.splice(index2, 1);
          }
        }
      });
      if (this.functionService.isUndefined(selected)) {
        this.setSelectedGroup(groupId, flag, true);
      }
    }

    if (!skipCheck) {
      this.checkSelectAll();
    }
  }

  /**
   * Dismiss seating detail modal
   * @param dismiss Dismiss flag for previous modal
   */
  async dismissModal(dismiss?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ dismiss }); }
    }
  }

  async promptSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[], showCount: boolean) {
    if (settingFieldType && settingFieldList?.length) {
      this.popupService.presentAlert(this.getSettingField(settingFieldType, settingFieldList, showCount, true), null, null, null, null, false, true);
    }
  }

  /**
   * Present modal to edit seating
   */
  async presentSeatingEditModal() {
    if (this.checkPrivilege(this.module, 'seating')) {
      const modal = await this.modalController.create({
        component: SeatingEditComponent,
        cssClass: '',
        componentProps: {
          seating: this.seating
        }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result?.data?.seating) {
          this.seating = result.data.seating;
          this.setupInvitedBy();
          this.setupCategory();
        }
      });
      modal.onDidDismiss().then((result: any) => {
        if (result?.data?.dismiss) {
          this.dismissModal(true);
        }
      });
    } else {
      this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
    }
  }

  /**
   * Present modal for guest bulk edit
   */
  async presentGuestBulkEditModal() {
    if (this.checkPrivilege(this.module, 'edit')) {
      let guest: Guest;
      let bulkEditMode = true;

      if (this.selectedGuestList?.length) {
        if (this.selectedGuestList.length === 1) {
          bulkEditMode = false;
          guest = this.guestService.getGuest(this.selectedGuestList[0]);
        }
      }

      const modal = await this.modalController.create({
        component: GuestEditComponent,
        cssClass: '',
        componentProps: {
          bulkEditMode,
          guest,
          selectedGuestList: this.selectedGuestList
        }
      });
      modal.present();
    } else {
      this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
    }
  }

  /**
   * Present modal for assign existing guest to current seating.
   * Excluded guest from current seating
   */
  async presentGuestListModal() {
    const excludeGuestIdList: string[] = this.seatingSummary?.guestList?.length ? this.seatingSummary.guestList.map((guest: Guest) => {
      return guest?.guestId;
    }) : [];
    const type: GuestFilterType = {
      seating: 'not_assign'
    };
    const modal = await this.modalController.create({
      component: GuestListComponent,
      cssClass: '',
      componentProps: {
        selectMode: true,
        excludeGuestIdList,
        mode: 'seating',
        type,
      }
    });
    modal.present();
    modal.onDidDismiss().then(async (result: any) => {
      const newGuestList = result?.data?.selectedGuestList;
      if (newGuestList?.length) {
        if ((newGuestList.length + this.seatingSummary?.guestList?.length > this.seating?.maxGuest) &&
        (this.seatingSummary?.guestList.length + 1 < this.seating?.maxGuest)) {
          const confirm = await this.popupService.presentConfirm(
            this.translate.instant('SEATING.msg.confirm_assign_seating_full', { seating: this.getSeatingTypeName() })
          );
          confirm.onDidDismiss().then(async (result2: any) => {
            if (result2?.data?.confirm) {
              this.saveGuestSeating(newGuestList);
            }
          });
        } else {
          this.saveGuestSeating(newGuestList);
        }
      }
    });
  }

  /**
   * Present modal - seating list for assign / swap seating
   * @param assignMode Assign seating flag.
   * @param swapMode Swap seating flag.
   */
  async presentSeatingListModal(assignMode: boolean, swapMode: boolean) {
    const newGuestList: Guest[] = this.selectedGuestList.map((guestId: string) => {
      return this.guestService.getGuest(guestId);
    });

    const modal = await this.modalController.create({
      component: SeatingListComponent,
      cssClass: '',
      componentProps: {
        assignMode,
        swapMode,
        newGuestList,
        excludeSeatingList: [ this.seating ]
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      this.toggleEditMode(false);
      if (result?.data?.done) {
        this.getSeatingGuest();
      }
    });
  }

  /**
   * Present modal for preview swap result between 2 seating guest.
   * @param selectedSeating Origin seating
   * @param selectedGuestList Selected guest list
   * @param targetSeating Target seating
   * @param targetGuestList Target guest list
   */
  async presentSeatingSwapPreviewModal(
    selectedSeating: Seating, selectedGuestList: Guest[], selectedGuestIdList: string[],
    targetSeating: Seating, targetGuestList: Guest[], targetGuestIdList: string[]
  ) {
    const modal = await this.modalController.create({
      component: SeatingSwapPreviewComponent,
      cssClass: '',
      componentProps: {
        selectedSeating,
        selectedGuestList,
        selectedGuestIdList,
        targetSeating,
        targetGuestList,
        targetGuestIdList,
      }
    });
    this.popupService.dismissLoading();
    modal.present();
    modal.onWillDismiss().then(() => {
      this.toggleEditMode(false);
    });
    modal.onDidDismiss().then(async (result: any) => {
      if (result?.data?.dismiss) {
        this.dismissModal(true);
      }
    });
  }

  /**
   * Confirm to delete current guest
   */
  async promptRemove() {
    if (this.editMode) {
      const guestList = this.guestService.getGuestListById(this.selectedGuestList);
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_field_count', {
          field: this.translate.instant('GUEST.lbl.guest'), count: this.selectedGuestList?.length }),
          null, null, null, null, null, null, null, guestList
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.removeGuest();
        }
        this.toggleEditMode(false);
      });
    }
  }

  /**
   * Prompt confirm restore modal
   */
  async promptCheckin() {
    await this.checkinService.checkin('', this.selectedGuestList);
    this.toggleEditMode(false);
  }

  /**
   * Prompt confirm restore modal
   */
  async promptUncheck() {
    await this.checkinService.uncheck(this.selectedGuestList);
    this.toggleEditMode(false);
  }

  /**
   * Present action sheet for guest seating action - Move / Swap seating.
   */
  async presentSeatingTypeSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: this.translate.instant('SEATING.btn.assign', { seating: this.getSeatingTypeName() }),
      buttons: [{
        text: this.translate.instant('BTN.move'),
        handler: () => {
          this.presentSeatingListModal(true, false);
        }
      }, {
        text: this.translate.instant('BTN.swap'),
        handler: () => {
          this.presentSeatingListModal(false, true);
        }
      }, {
        text: this.translate.instant('BTN.cancel'),
        icon: 'close',
        role: 'cancel',
        handler: () => {
        }
      }]
    });
    actionSheet.present();
  }

  /**
   * Bulk Action
   * @param type action type
   */
  async bulkAction(type: string) {
    if (type) {
      if (type === 'add') {
        this.promptSeatingOverload(this.seating, this.seatingSummary, 1);
        this.toggleEditMode(false);
      } else {
        this.checkSingleRecord();
        if (type === 'delete') {
          this.promptRemove();
        } else if (type === 'info') {
          await this.presentGuestBulkEditModal();
          this.toggleEditMode(false);
        } else if (type === 'assign') {
          this.presentSeatingTypeSheet();
        } else if (type === 'checkin') {
          this.promptCheckin();
        } else if (type === 'uncheck') {
          this.promptUncheck();
        }
      }
    }
  }

  /**
   * Prompt seating overload
   * @param seating Seating
   * @param seatingSummary seating summary
   * @param newGuestCount new guest count
   */
  async promptSeatingOverload(seating: Seating, seatingSummary: SeatingSummary, newGuestCount: number) {
    if (seatingSummary?.guestList?.length + newGuestCount > seating?.maxGuest) {
      const confirm = await this.popupService.presentConfirm(
        this.translate.instant('SEATING.msg.confirm_assign_seating_full', { seating: this.getSeatingTypeName() })
      );
      confirm.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          this.presentGuestListModal();
        }
      });
    }  else {
      this.presentGuestListModal();
    }
  }

  /**
   * Check if single record then trigger select all
   */
  checkSingleRecord() {
    if ( !this.selectAll && this.count === 1) {
      this.toggleSelectAll();
    }
  }

  /**
   * Check user privilege
   * @param action Action
   */
  checkPrivilege(module: ModuleType, action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege(module, action);
  }

  /**
   * Preview swap seating result.
   */
  async previewSwapSeating() {
    if (this.swapMode) {
      await this.popupService.presentLoading();
      let selectedSeating: Seating;
      const selectedGuestList: Guest[] = this.selectedGuestList.map((guestId: string) => {
        return this.guestService.getGuest(guestId);
      });

      if (this.newGuestList?.length && this.newGuestList[0] && this.newGuestList[0].seating) {
        selectedSeating = this.seatingService.getSeatingByName(this.newGuestList[0].seating);
      }
      const newGuestIdList = this.newGuestList.map((guest: Guest) => {
        return guest?.guestId;
      });
      this.presentSeatingSwapPreviewModal(selectedSeating, this.newGuestList, newGuestIdList,
        this.seating, selectedGuestList, this.selectedGuestList);
    }
  }

  /**
   * Assigned guest to current seating
   */
  async assignSeating() {
    if (this.assignMode && this.newGuestList?.length && this.seating?.name) {
      await this.popupService.presentLoading();
      const data = { seating: this.seating.name };
      const guestIdList: string[] = this.newGuestList.map((guest: Guest) => {
        return guest?.guestId;
      });
      this.newGuestList = [];
      // await this.guestManageService.updateGuest(data, guestIdList, '', false, true);
      await this.guestManageService.saveDb(data, guestIdList, '', true);
      this.getSeatingGuest();
      await this.popupService.dismissLoading();
      
      this.popupService.saveSuccessToast();
    }
  }


  /**
   * Update favorite status for seating
   */
  async updateFavorite() {
    if (this.checkPrivilege(this.module, 'seating')) {
      await this.popupService.presentLoading();
      this.seating.favorite = !this.seating.favorite;
      await this.seatingManageService.updateSeating(this.seating);
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
  }

  /**
   * Save selected guest into current seating
   */
  async saveGuestSeating(guestList: string[]) {
    if (guestList?.length && this.seating?.name) {
      await this.popupService.presentLoading();
      await this.guestManageService.saveGuest({ seating: this.seating.name }, guestList);

      await this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
  }

  /**
   * Save remove guest
   */
  async removeGuest() {
    if (this.editMode && this.selectedGuestList) {
      await this.popupService.presentLoading();
      await this.guestDeleteService.removeGuestList(this.selectedGuestList);
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
  }

  /**
   * Close all opened ion-sliding item
   */
  closeSliding() {
    if (this.list) {
      this.list?.closeSlidingItems();
    }
  }

  estimateItemSize(index: number, guest: Guest): number {
    return this.itemHeightFn(guest) + 10;
    // Get the item element and measure its height
    // const itemElement = this.elementRef.nativeElement.querySelector(`.item:nth-child(${index + 1})`);
    // const itemHeight = itemElement.offsetHeight;

    // // Return the estimated item size
    // return itemHeight;
  }

  /**
   * Calculate item height for virtual scroll based on group member count
   */
  itemHeightFn(item: any) {
    const margin = 10;
    const guestItem = 100;
    const groupItem = 80;

    if (item?.group?.memberList?.length) {
      return (item.group.memberList.length * guestItem) + groupItem + margin;
    }
    return guestItem + margin;
  }

  /**
   * Track guest card item by guest id, group id or index.
   */
  trackByFn(index: number, item: Guest) {
    if (item?.guestId) {
      return item.guestId;
    } else if (item?.group?.groupId) {
      return item.group.groupId;
    }
    return index;
  }

  getContentHeight(): number {
    return this.contentElement?.nativeElement?.offsetHeight ? this.contentElement.nativeElement.offsetHeight : 0;
  }

  getLimit() {
    const limit = Math.ceil(this.getContentHeight() / 110);
    return limit ? limit : 20;
  }

  async loadLimit(event: any) {
    await this.functionService.delay(50);
    if (this.limit >= this.guestList.length) {
      // event.target.disabled = true;
    } else {
      this.limit += this.getLimit();
    }
    event.target.complete();
    this.setupViewport();
  }

}
