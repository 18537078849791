import { CurrencyService } from 'src/app/services/general/currency.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ErrorService } from '../../general/error.service';
import { BehaviorSubject, map, Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { StripeCurrencyService } from './stripe-currency.service';

@Injectable({
  providedIn: 'root'
})
export class StripeExchangeRateService implements OnInit, OnDestroy {

  exchangeRateData: any;
  conversionRates: any;

  observableExchangeRateData: any;
  private exchangeRateSubscription: Subscription;

  constructor(
    private afs: AngularFirestore,
    private currencyService: CurrencyService,
    private stripeCurrencyService: StripeCurrencyService,
    private errorService: ErrorService,
  ) {
    this.observableExchangeRateData = new BehaviorSubject<any>(this.exchangeRateData);
    this.watchExchangeRate();
  }

  ngOnInit() {
    
  }

  ngOnDestroy() {
    this.unwatchExchangeRate();
  }

  async watchExchangeRate() {
    if (!this.exchangeRateSubscription) {
      this.exchangeRateSubscription = this.afs.doc(`currency/exchangeRates/`)
      .snapshotChanges().pipe(map(changes => {
        const data: any = changes.payload.data();
        return data;
      })).subscribe({
        next: (exchangeRateData: any) => {
          this.exchangeRateData = exchangeRateData;
          this.observableExchangeRateData.next(this.exchangeRateData);
        }, error: (err: any) => {
          this.errorService.logError(err);
        }
      });
    }
  }

  async unwatchExchangeRate() {
    if (this.exchangeRateSubscription) {
      this.exchangeRateSubscription.unsubscribe();
      this.exchangeRateSubscription = null;
    }
  }

  getExchangeRateByCurrency(currency: string): number {
    if (!currency) {
      currency = this.currencyService.getAccountCurrency()?.code;
    }

    if (currency) {
      if (this.stripeCurrencyService.validateCurrency(currency) && this.stripeCurrencyService.validateStripeCurrency(currency)) {
        const conversionRates = this.getConversionRates();
        if (conversionRates && Object.keys(conversionRates).indexOf(currency) !== -1) {
          return conversionRates[currency];
        }
      }
    }

    return -1;
  }

  getConversionRates() {
    if (this.exchangeRateData?.latest) {
      return this.exchangeRateData.latest;
    } else if (this.exchangeRateData?.previous) {
      return this.exchangeRateData.previous;
    }
    return null;
  }

}
