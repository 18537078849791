import { DeviceManageService } from 'src/app/services/device/device-manage.service';
import { UserAccounts } from 'src/app/interfaces/account';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { DeviceTokenService } from 'src/app/services/device/device-token.service';
import { UserService } from 'src/app/services/user/user.service';
import { UpdateByService } from 'src/app/services/user/update-by.service';
import { AccountsListService } from 'src/app/services/accounts/accounts-list.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Provider, User } from 'src/app/interfaces/user';
import { Timezone } from 'src/app/interfaces/database';
import { MediaType, Locality, LocationDetail } from 'src/app/interfaces/general';
import { LanguageService } from 'src/app/services/general/language.service';
import { TimezoneService } from 'src/app/services/general/timezone.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Platform } from '@ionic/angular';
import { GeoLocationService } from '../location/geo-location.service';
import { IpLocationService } from '../location/ip-location.service';
import { MobileService } from '../general/mobile.service';
import { UserRtdbService } from './user-rtdb.service';
import { UserRtdb } from 'src/app/interfaces/rtdb';

/**
 * User manage service
 */
@Injectable({
  providedIn: 'root'
})
export class UserManageService implements OnInit, OnDestroy {

  /**
   * Constructor
   * @param afs angular firestore
   * @param deviceTokenService device token service
   * @param userService user service
   * @param updateByService update by service
   * @param accountsListService account list service
   * @param functionService function service
   */
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private deviceManageService: DeviceManageService,
    private deviceTokenService: DeviceTokenService,
    private geoLocationService: GeoLocationService,
    private ipLocationService: IpLocationService,
    private userService: UserService,
    private userRtdbService: UserRtdbService,
    private mobileService: MobileService,
    private updateByService: UpdateByService,
    private accountsListService: AccountsListService,
    private timezoneService: TimezoneService,
    private languageService: LanguageService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
  }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Create new user data
   */
  async createNewUserData(name?: string, avatar?: MediaType) {
    try {
      const currentUser = await this.afAuth.currentUser;
      if (currentUser?.uid && !this.userService?.user?.uid) {
        const location: LocationDetail = this.geoLocationService.locationDetail;
        const locality: Locality = this.ipLocationService.locality;
  
        const timezone: Timezone = this.timezoneService.getDeviceTimezone(locality?.country ? locality.country : location?.locality?.country);
        const user: any = {
          uid: currentUser.uid,
          enable: true,
          isAnonymous: false,
          timezone,
          language: this.languageService.getLanguageCode(await this.languageService.readDeviceLanguage()),
          prompt: {
            aup: true,
            welcome: false,
          },
          createBy: this.updateByService.updateBy,
          updateBy: this.updateByService.updateBy,
        };
        if (name) {
          user.name = name;
        }
        if (avatar) {
          user.avatar = avatar;
        }
        if (location) {
          user.location = location;
        }
        if (locality?.country) {
          user.locality = locality;
        } else if (location?.locality) {
          user.locality = location.locality;
        }
        // if (currentUser?.isAnonymous) {
        //   user.isAnonymous = true;
        // } else 
        if (currentUser?.providerData) {
          user.provider = [];
          currentUser.providerData?.forEach((value: Provider) => {
            const provider: Provider = {
              providerId: value.providerId ? value.providerId : '',
              uid: value.uid ? value.uid : '',
              displayName: value.displayName ? value.displayName : '',
              email: value.email ? value.email : '',
              photoURL: value.photoURL ? value.photoURL : '',
              phoneNumber: value.phoneNumber ? value.phoneNumber : '',
            };
            user.provider.push(provider);
  
            if (!user.email && value.email) {
              user.email = value.email;
            }
            if (value.phoneNumber && (value.providerId === 'mobile' || !user?.mobile?.no)) {
              const phoneUtil = PhoneNumberUtil.getInstance();
              user.mobile = {
                no: value.phoneNumber,
                code: phoneUtil.parseAndKeepRawInput(value.phoneNumber).getCountryCode()
              };
              user.mobile.country = this.mobileService.getCountryByPhoneCode(user.mobile.code);
              if (!user?.locality?.country) {
                user.locality = {
                  country: user.mobile.country,
                }
              }
              if (!user?.location?.locality?.country) {
                user.location = {
                  locality: {
                    country: user.mobile.country,
                  }
                }
              }
            }
          });
        }

        await this.createUser(user);
        const userRtdb: UserRtdb = {
          uid: currentUser.uid,
          timezoneOffset: user.timezone.offset,
          language: user.language,
        }
        if (user?.locality) {
          userRtdb.locality = user.locality;
        }
        await this.updateUserRtdb(userRtdb, currentUser.uid);
      }
    } catch (err: any) {
      console.error(err);
    }
  }

  /**
   * Create new user data
   * @param data User Data
   */
   async createUser(data: any) {
    if (this.userService.uid && !this.functionService.isEmpty(data) && !this.userService?.user) {
      const userRef = this.afs.firestore.doc(`users/${ this.userService.uid }/`);
      await userRef.set(data, { merge: true });
      await this.deviceTokenService.tokenPermission();
      await this.userService.watchUser();
    }
  }

  /**
   * Updated user data.
   * Update account user data if changes applied to name / avatar.
   * @param data user data
   */
  async updateUser(data: any) {
    if (this.userService.user && !this.functionService.isEmpty(data)) {
      data.updateBy = this.updateByService.updateBy;

      const user: User = this.userService.user;
      const userRtdb: any = {};

      if (!user?.locality?.country && !data?.locality?.country) {
        const locality: Locality = this.ipLocationService.locality;
        if (locality?.country) {
          data.locality = locality;
          userRtdb.locality = locality;
        }
      }

      if (!user?.location?.locality?.country && !data?.location?.locality?.country) {
        const location: LocationDetail = this.geoLocationService.locationDetail;
        if (location?.locality?.country) {
          data.location = location;
        }
      }

      if (data.language && data.language !== user?.language) {
        await this.deviceManageService.updateDeviceLanguage(data.langauge);
        userRtdb.language = data.language;
      }

      if (data?.locality?.country) {
        userRtdb.locality = data.locality;
      } else if (data?.location?.locality?.country) {
        userRtdb.locality = data.location.locality;
      }

      if (data?.timezone?.offset) {
        userRtdb.timezoneOffset = data.timezone.offset;
      }

      const userRef = this.afs.firestore.doc(`users/${ this.userService.uid }/`);
      userRef.set(data, { merge: true }).then(result => {
      }).catch((err: any) => {
        this.errorService.logError(err);
      });

      if (!this.functionService.isEmpty(userRtdb)) {
        await this.updateUserRtdb(userRtdb);
      }
      if ((data.name || data?.avatar?.url) && this.accountsListService.userAccountsList?.length) {
        this.accountsListService.userAccountsList?.forEach(async (userAccounts: UserAccounts) => {
          if (userAccounts?.accountId && userAccounts?.enable) {
            const accountUserData: any = {};
            if (data.name) {
              accountUserData.name = data.name;
            }
            if (data?.avatar?.url) {
              accountUserData.avatar = data.avatar;
            }
            if (!this.functionService.isEmpty(accountUserData)) {
              accountUserData.updateBy = this.updateByService.updateBy;
              const accountUserRef = this.afs.firestore.doc(`accounts/${ userAccounts.accountId }/accountUser/${ this.userService.uid }/`);
              accountUserRef.set(accountUserData, { merge: true }).then(result => {
              }).catch((err: any) => {
                this.errorService.logError(err);
              });
            }
          }
        });
      }
    }
  }

  async updateUserRtdb(data: any, uid?: string) {
    if (data) {
      await this.userRtdbService.updateUserRtdb(uid ? uid : this.userService.uid, data);
    }
  }

}
