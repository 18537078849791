import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { UserService } from 'src/app/services/user/user.service';
import { OnlineService } from 'src/app/services/general/online.service';
import { UpdateByService } from 'src/app/services/user/update-by.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { GroupService } from 'src/app/services/group/group.service';
import { GroupListService } from 'src/app/services/group/group-list.service';
import { GroupManageService } from 'src/app/services/group/group-manage.service';
import { ErrorService } from 'src/app/services/general/error.service';

import { Group } from 'src/app/interfaces/group';

/**
 * Delete guest service, delete guest to trash bin, restore guest from trash bin, remove guest from trash bin.
 */
@Injectable({
  providedIn: 'root'
})
export class GuestDeleteService implements OnInit, OnDestroy {

  /**
   * Account ID
   */
  private accountId: string;

  /**
   * Constructor
   * @param afs Angular firestore
   * @param fns Angular cloud function
   * @param guestService guest service
   * @param guestManageService guest manage service
   * @param groupService group service
   * @param groupListService group list service
   * @param groupManageService group manage service
   * @param userService user service
   * @param onlineService online service
   */
  constructor(
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private guestService: GuestService,
    private guestManageService: GuestManageService,
    private groupService: GroupService,
    private groupListService: GroupListService,
    private groupManageService: GroupManageService,
    private userService: UserService,
    private updateByService: UpdateByService,
    private onlineService: OnlineService,
    private errorService: ErrorService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Setup Account ID
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
  }

  /**
   * Move guest to trash bin.
   * Update guest status to deleted.
   * Remove guest from group member list if applicable.
   * @param guestId guest id to move to trash bin
   */
  private async removeGuest(guestId: string) {
    if (guestId) {
      const guest = this.guestService.getGuest(guestId);
      if (guest?.groupId) {
        const group = this.groupService.getGroup(guest.groupId);

        if (!group || !group?.memberList) { group.memberList = []; }
        group.memberList = group.memberList.filter((memberId: string) => {
          if (memberId === guestId) {
            return false;
          } else {
            return true;
          }
        });

        if (group.memberList.length <= 1) {
          group.memberList = [];
          group.deleteBy = this.updateByService.updateBy;
        } else {
          group.updateBy = this.updateByService.updateBy;
        }
        await this.groupManageService.saveGroupList([ group.groupId ], null, [ group ]);
      }

      await this.guestManageService.saveGuest({ status: { deleted: true } }, [ guestId ], 'delete', [], true);
    }
  }

  /**
   * Move guest list to trash bin.
   * Update guest list status to deleted.
   * Remove guest list from group member list and update group list.
   * @param guestIdList guest id list to move to trash bin
   */
  async removeGuestList(guestIdList: string[]) {
    if (guestIdList?.length) {
      if (guestIdList?.length === 1) {
        this.removeGuest(guestIdList[0]);
      } else {
        const groupList: Group[] = this.groupListService.generateGroupList(guestIdList);
        if (groupList?.length) {
          groupList?.forEach((group: Group) => {
            group.memberList = group?.memberList?.filter((guestId: string) => {
              if (guestIdList?.indexOf(guestId) === -1) {
                return true;
              } else {
                return false;
              }
            });
            if (group.memberList.length <= 1) {
              group.memberList = [];
              group.deleteBy = this.updateByService.updateBy;
            } else {
              group.updateBy = this.updateByService.updateBy;
            }
          });
          const groupIdList = groupList.map((x: Group) => {
            return x.groupId;
          });
          if (groupIdList?.length) {
            await this.groupManageService.saveGroupList(groupIdList, null, groupList);
          }
        }
        if (guestIdList?.length) {
          await this.guestManageService.saveGuest({ status: { deleted: true } }, guestIdList, 'delete', [], true);
        }
      }
    }
  }

  /**
   * Restore guest from trash bin.
   * Add guest into group member list if applicable.
   * @param guestId guest id to restore from trash bin.
   */
  private async restoreGuest(guestId: string) {
    if (guestId) {
      const guest = this.guestService.getGuest(guestId);
      if (guest?.groupId) {
        const group = this.groupService.getGroup(guest.groupId);
        group.updateBy = this.updateByService.updateBy;
        if (!group || !group?.memberList) { group.memberList = []; }
        if (group.memberList?.indexOf(guestId) === -1) {
          group.memberList.push(guestId);
        }
        await this.groupManageService.saveGroupList([ group.groupId ], null, [ group ]);
      }

      await this.guestManageService.saveGuest({ status: { deleted: false } }, [ guestId ], 'restore', [], true);
    }
  }

  /**
   * Restore guest list from trash bin.
   * Add guest list into member list if applicable.
   * @param guestIdList guest id list to restore from trash bin.
   */
  async restoreGuestList(guestIdList: string[]) {
    if (guestIdList?.length) {
      if (guestIdList.length === 1) {
         this.restoreGuest(guestIdList[0]);
      } else {
        const groupList: Group[] = [];
        guestIdList?.forEach((guestId: string) => {
          const guest = this.guestService.getGuest(guestId);
          if (guest?.groupId) {
            const groupIndex = groupList?.findIndex((x: Group) => x?.groupId === guest?.groupId);
            if (groupIndex === -1) {
              const group = this.groupService.getGroup(guest.groupId);
              if (group?.memberList?.indexOf(guestId) === -1) {
                group.memberList.push(guestId);
              }
              group.deleteBy = this.updateByService.updateBy;
              groupList.push(group);
            } else {
              groupList?.[groupIndex]?.memberList.push(guestId);
            }
          }
        });
        if (groupList?.length) {
          const groupIdList = groupList.map((x: Group) => {
            return x.groupId;
          });
          await this.groupManageService.saveGroupList(groupIdList, null, groupList);
        }
        await this.guestManageService.saveGuest({ status: { deleted: false } }, guestIdList, 'restore', [], true);
      }
    }
  }

  /**
   * Remove guest from db permanently.
   * @param guestId guest id to delete from db permanently.
   */
  private async deleteGuest(guestId: string) {
    if (this.accountId && guestId) {
      const guestRef = this.afs.firestore.doc(`accounts/${ this.accountId }/guests/${ guestId }`);
      guestRef.delete();
    }
  }

  /**
   * Remove guest list from db permanently.
   * @param guestId guest id list to delete from db permanently.
   */
  async deleteGuestList(guestIdList: string[]) {
    if (this.onlineService.online && guestIdList?.length > 100) {
      await this.fns.httpsCallable('batchClearGuestCall')({
        guestIdList,
        accountId: this.accountId,
        updateBy: this.updateByService.updateBy,
        uid: this.userService.uid
      }).toPromise().then((response) => {
      }).catch((err) => {
        this.errorService.logError(err);
      });
    } else if (this.onlineService.online && guestIdList?.length > 1) {
      const batch = this.afs.firestore.batch();
      guestIdList?.forEach((guestId: string) => {
        const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/guests/${ guestId }`);
        batch.delete(ref);
      });
      await batch.commit();
    } else {
      for (const guestId of guestIdList) {
        await this.deleteGuest(guestId);
      }
    }
  }

}
