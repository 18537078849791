import { ErrorService } from 'src/app/services/general/error.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { UserService } from 'src/app/services/user/user.service';
import { UpdateByService } from 'src/app/services/user/update-by.service';
import { GiftManageService } from 'src/app/services/gift/gift-manage.service';
import { OnlineService } from 'src/app/services/general/online.service';

import { Gift } from 'src/app/interfaces/gift';

/**
 * Gift delete servcice.
 * Delete gift item / guest's gift record.
 */
@Injectable({
  providedIn: 'root'
})
export class GiftDeleteService implements OnInit, OnDestroy {

  /**
   * Account ID
   */
  private accountId: string;

  /**
   * Constructor
   * @param afs Angular firestore
   * @param fns Angular firestore functions
   * @param giftService Gift service
   * @param giftManageService Gift manage service
   * @param guestService Guest service
   * @param guestManageService Guest manage service
   * @param groupService Group service
   * @param groupManageService Group manage service
   * @param onlineService Online service
   * @param userService User service
   */
  constructor(
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private giftManageService: GiftManageService,
    private onlineService: OnlineService,
    private userService: UserService,
    private updateByService: UpdateByService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Setup Account ID
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
  }

  /**
   * Save delete gift
   * @param giftIdList Gift ID list
   * @param giftList Gift list
   */
  async removeGiftList(giftIdList: string[], giftList?: Gift[]) {
    if (giftList?.length || giftIdList?.length) {
      await this.giftManageService.saveGiftList(giftIdList, { delete: true }, [], 'delete');
    }
  }

  /**
   * Remove guest list from db permanently.
   * @param guestId guest id list to delete from db permanently.
   */
   async deleteGiftList(giftIdList?: string[]) {
    if (this.onlineService.online && giftIdList?.length > 100) {
      await this.fns.httpsCallable('batchClearGiftCall')({
        giftIdList,
        accountId: this.accountId,
        updateBy: this.updateByService.updateBy,
        uid: this.userService.uid
      }).toPromise().then((response) => {
      }).catch((err) => {
        this.errorService.logError(err);
      });
    } else if (this.onlineService.online && giftIdList?.length > 1) {
      const batch = this.afs.firestore.batch();
      giftIdList?.forEach((guestId: string) => {
        const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/gifts/${ guestId }`);
        batch.delete(ref);
      });
      await batch.commit();
    } else {
      for (const giftId of giftIdList) {
        await this.deleteGift(giftId);
      }
    }
  }

  /**
   * Remove guest from db permanently.
   * @param giftId gift id to delete from db permanently.
   */
  private async deleteGift(giftId: string) {
    if (this.accountId && giftId) {
      const guestRef = this.afs.firestore.doc(`accounts/${ this.accountId }/gifts/${ giftId }`);
      guestRef.delete();
    }
  }

  /**
   * Restore guest list from trash bin.
   * Add guest list into member list if applicable.
   * @param guestIdList guest id list to restore from trash bin.
   */
  async restoreGiftList(giftIdList: string[]) {
    if (giftIdList?.length) {
      const deleteBy = this.updateByService.updateBy;
      await this.giftManageService.saveGiftList(giftIdList, { delete: false, deleteBy });
    }
  }

}
