import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { StripeCurrencyList } from 'src/app/commons/stripe';
import { BlastPackage } from 'src/app/interfaces/blast';
import { Price } from 'src/app/interfaces/product';
import { Item } from 'src/app/interfaces/transaction';
import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { BlastPackageService } from 'src/app/services/blast/blast-package.service';
import { BlastPricingService } from 'src/app/services/blast/blast-pricing.service';
import { BlastRedeemService } from 'src/app/services/blast/blast-redeem.service';
import { CurrencyService } from 'src/app/services/general/currency.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { LocalityService } from 'src/app/services/location/locality.service';
import { StripeExchangeRateService } from 'src/app/services/payment/stripe/stripe-exchange-rate.service';
import { BlastType } from 'src/app/types/blast';
import { BlastCreditCalculatorComponent } from '../blast-credit-calculator/blast-credit-calculator.component';
import { BlastCreditService } from 'src/app/services/blast/blast-credit.service';
import { AccountPackageService } from 'src/app/services/account/account-package.service';
import { FunctionService } from 'src/app/services/general/function.service';

@Component({
  selector: 'app-blast-package-component',
  templateUrl: './blast-package.component.html',
  styleUrls: ['./blast-package.component.scss'],
})
export class BlastPackageComponent  implements OnInit, OnDestroy {
  
  @Input() pageMode: boolean;

  @Input() total: number;

  @Input() currency: string;

  @Input() itemList: Item[];

  @Input() set ready(ready: boolean) {
    this.initial(ready);
  }

  trialMode: boolean;
  
  countryCode: string;

  countryName: string;

  blastPackageProductList: BlastPackage[];

  exchangeRate: number;

  requiredCredit: number;
  customCredit: number;

  minusAvailableCredit: boolean;

  minCredit: number = this.blastPackageService.minCredit;

  bulkQty: any;

  smsCount: number;
  whatsAppCount: number;

  availableCredit: number;
  totalBlastCredit: number;
  totalAmount: number;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private modalController: ModalController,
    private accountInfoService: AccountInfoService,
    private accountPackageService: AccountPackageService,
    private currencyService: CurrencyService,
    private localityService: LocalityService,
    private blastPackageService: BlastPackageService,
    private blastPricingService: BlastPricingService,
    private blastRedeemService: BlastRedeemService,
    private blastCreditService: BlastCreditService,
    private stripeExchangeRateService: StripeExchangeRateService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
      this.initial(false);
  }

  ionViewWillEnter() {
    this.initial(true);
  }

  ionViewWillLeave() {
    this.initial(false);
  }

  initial(ready: boolean) {
    this.total = 0;
    this.availableCredit = this.getAvailableCredit();
    this.totalBlastCredit = this.getTotalBlastCredit();

    if (!this.customCredit) {
      this.customCredit = 0;
    }

    this.countryName = this.getCountryName();
    // else if (this.customCredit && this.customCredit < this.minCredit) {
    //   this.customCredit = this.minCredit;
    // }
    if (ready) {
      if (!this.itemList?.length) {
        this.itemList = [];
      }
      if (!this.currency) {
        this.currency = this.currencyService.getAccountCurrency().code;
      }
      
      this.blastPackageProductList = this.blastPackageService.blastPackageProductList;
      this.setupBulkQty();
      if (this.requiredCredit) {
        this.setupCustomCredit(this.requiredCredit);
      }
    } else {
      this.itemList = [];
      this.currency = this.currencyService.getAccountCurrency().code;
    }


    this.smsCount = this.getBlastCount('sms', this.customCredit, this.countryCode);
    this.whatsAppCount = this.getBlastCount('whatsapp', this.customCredit, this.countryCode);
    
    this.availableCredit = this.getAvailableCredit();
    this.totalBlastCredit = this.getTotalBlastCredit();

    this.setupTrialMode();
  }

  setupTrialMode() {
    if (!this.accountPackageService.accountPackage?.accountStatus?.premium && this.accountPackageService.accountPackage?.accountStatus?.trial) {
      this.trialMode = true;
    } else {
      this.trialMode = false;
    }
  }

  setupCustomCredit(requiredCredit: number) {
    if (requiredCredit) {
      // const customCredit = requiredCredit - this.getAvailableCredit();
      const customCredit = requiredCredit;
      if (customCredit > 0) {
        this.customCredit = customCredit;
      } else {
        this.customCredit = 0;
      }
      this.setupItemList();
    }
  }

  async setupBulkQty() {
    this.bulkQty = {};
    this.blastPackageProductList?.forEach((blastPackageProduct: BlastPackage) => {
      if (blastPackageProduct?.blastPackageId) {
        const index = this.itemList?.findIndex((item: Item) => item?.productName === blastPackageProduct?.blastPackageId);
        if (index !== -1) {
          this.bulkQty[blastPackageProduct?.blastPackageId] = this.itemList[index].qty;
        } else {
          this.bulkQty[blastPackageProduct?.blastPackageId] = 0;
        }
      }
    });
  }

  async presentBlastCreditCalculatorModal() {
    const modal = await this.modalController.create({
      component: BlastCreditCalculatorComponent,
      cssClass: '',
      componentProps: {
        packageMode: true,
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.requiredCredit) {
        this.setupCustomCredit(result.data.requiredCredit);
      }
    })
  }

  getPrice(priceList: Price[]): Price {
    if (priceList?.length) {
      const currencyIndex = priceList?.findIndex((x: Price) => x?.currency === this.currency);
      if (currencyIndex !== -1) {
        return priceList[currencyIndex];
      } else if (StripeCurrencyList?.indexOf(this.currency) !== -1) {
        const usdIndex = priceList?.findIndex((x: Price) => x?.currency === 'USD');
        if (usdIndex !== -1) {
          const price = { ...priceList[usdIndex] };
          this.exchangeRate = this.stripeExchangeRateService.getExchangeRateByCurrency(this.currency);
          if (this.exchangeRate > 0) {
            // price.amount = this.currencyService.rounding(this.currency, price.amount * this.exchangeRate);
            price.amount = price.amount * this.exchangeRate;
            price.currency = this.currency;
          } else {
            price.currency = 'USD';
          }
          return price;
        }
      }
    }
    return null;
  }

  getCountryCode(): string {
    let country: string = '';
    if (!country && this.accountInfoService.accountInfo?.location?.locality?.country) {
      country = this.accountInfoService.accountInfo.location.locality.country;
    }
    return country;
  }

  getCountryName(): string {
    if (!this.countryCode) {
      this.countryCode = this.getCountryCode();
    }
    const country = this.localityService.getCountryByCode(this.countryCode)?.name;
    if (country) {
      return ' (' + country + ')';
    }
    return '';
  }

  getBlastCount(blastType: BlastType, totalBlastCredit: number, country?: string) {
    return this.blastPricingService.getBlastCount(blastType, totalBlastCredit, country);
  }

  getTotalBlastCredit() {
    // if (this.customCredit) {
    //   return this.customCredit;
    // }
    return this.itemList?.reduce((a: number, b: Item) => {
      const blastPackage = this.blastPackageService.getBlastPackageById(b?.productId);
      return a + blastPackage?.blastCredit ? (blastPackage.blastCredit * b.qty) : 0;
    }, 0);
  }

  getAvailableCredit() {
    return this.blastCreditService.getAvailableBlastCredit();
  }

  validateRedeemBlastCredit() {
    return this.blastRedeemService.validateRedeemBlastCredit();
  }

  checkSelected(blastPackageProduct: BlastPackage) {
    if (this.bulkQty[blastPackageProduct?.blastPackageId]) {
      return true;
    }
    return false;
  }

  selectItem(blastPackageProduct: BlastPackage) {
    const name = blastPackageProduct?.blastPackageId;
    if (!this.bulkQty[name] || this.bulkQty[name] === 0) {
      this.bulkQty[name] = 1;
      this.setupItemList();
    }
  }

  getItemIndex(productName: string) {
    if (productName) {
      const index = this.itemList.findIndex((item: Item) => {
        if (item.productType === 'blast' && item.productName === productName) {
          return true;
        }
        return false;
      });
      return index;
    }
    return -1;
  }

  addQty(blastPackageProduct?: BlastPackage) {
    if (blastPackageProduct?.blastPackageId) {
      this.customCredit = 0;
      if (!this.bulkQty?.[blastPackageProduct?.blastPackageId]) { this.bulkQty[blastPackageProduct?.blastPackageId] = 0; }
      this.bulkQty[blastPackageProduct?.blastPackageId]++;
      this.setupItemList();
    } else {
      if (!this.customCredit) {
        this.customCredit = 0;
      }
      if (this.customCredit < this.minCredit) {
        this.customCredit = this.minCredit;
      } else {
        this.customCredit++;
      }
      
      this.setupItemList();
    }
  }

  /**
    * Minus Qty
    * @param productId product ID
    */
  minusQty(blastPackageProduct?: BlastPackage) {
    if (blastPackageProduct?.blastPackageId) {
      this.customCredit = 0;
      if (!this.bulkQty?.[blastPackageProduct?.blastPackageId]) { this.bulkQty[blastPackageProduct?.blastPackageId] = 0; }
      if (this.bulkQty[blastPackageProduct?.blastPackageId]) {
        this.bulkQty[blastPackageProduct?.blastPackageId]--;
        this.setupItemList();
      }        
    } else {
      if (!this.customCredit) {
        this.customCredit = 0;
      }  else if (this.customCredit <= this.minCredit) {
        this.customCredit = 0;
        this.setupItemList();
      } else if (this.customCredit > this.minCredit) {
        this.customCredit--;
        this.setupItemList();
      }
    }
  }

  setupItemList() {
    this.itemList = [];
    let total = 0;
    if (this.customCredit >= this.minCredit) {
      Object.keys(this.bulkQty)?.forEach((key: string) => {
        this.bulkQty[key] = 0;
      });
      let customCredit = this.customCredit;
      const availableCredit = this.getAvailableCredit();
      if (this.minusAvailableCredit && availableCredit) {
        if ((customCredit - availableCredit) > 0) {
          if ((customCredit - availableCredit) < 50) {
            customCredit = 50;
          } else {
            customCredit = customCredit -  availableCredit;
          }
        } else {
          customCredit = 0;
        }
      }
      const price = this.blastPackageService.calculateBlastPackagePrice(customCredit, this.currency);
      const productId = 'blast_' + customCredit + '_custom';
      const item: Item = {
        productId,
        productName: this.translate.instant('BLAST.package.name', { count: customCredit }),
        productType: 'blast',
        qty: 1,
        oriPrice: price,
        price,
        payment: price,
      };
      this.itemList.push(item);
      total += (price.amount);
    } else {
      // this.blastPackageProductList.forEach((blastPackageProduct: BlastPackage) => {
      //   if (blastPackageProduct?.blastPackageId && this.bulkQty[blastPackageProduct.blastPackageId]) {
      //     const price = this.getPrice(blastPackageProduct.price);
      //     const productId = blastPackageProduct?.blastPackageId;
      //     const item: Item = {
      //       productId,
      //       productName: this.translate.instant('BLAST.package.name', { count: blastPackageProduct.blastCredit }),
      //       productType: 'blast',
      //       qty: this.bulkQty[productId],
      //       oriPrice: price,
      //       price,
      //       payment: price,
      //     };
      //     this.itemList.push(item);
      //     total += (price.amount * this.bulkQty[productId]);
      //   }
      // });
    }
    // const availableCredit = this.getAvailableCredit();
    // if (this.minusAvailableCredit && availableCredit) {

    // }
    // if (total < 0) {
    //   total = 0;
    // }
    this.smsCount = this.getBlastCount('sms', this.customCredit, this.countryCode);
    this.whatsAppCount = this.getBlastCount('whatsapp', this.customCredit, this.countryCode);

    this.availableCredit = this.getAvailableCredit();
    this.totalBlastCredit = this.getTotalBlastCredit();
    this.total = total;
  }

  async dismissModal(checkout?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { await modal.dismiss({ checkout }); }
    }
  }

  next() {
    this.goPaymentPage();
  }

  generateParam(): NavigationExtras {
    const navigationExtras: NavigationExtras = {
      state: {
        transactionType: 'blast',
        total: this.total,
        accountId: this.accountInfoService.accountId,
        currency: this.currency,
        itemList: this.itemList,
      }
    };
    return navigationExtras;
  }

  async goPaymentPage() {
    if (this.currency && this.itemList?.length) {
      this.router.navigate(['/payment/stripe-checkout'], this.generateParam());
      await this.dismissModal(true);
      this.popupService.dismissAllModal();
    } else {
      // prompt error
      this.popupService.presentActionError();
    }
  }

  async goBlastHomePage() {
    await this.router.navigate(['/blast/home']);
    await this.dismissModal();
  }

  async goUpgrade() {
    this.accountInfoService.goUpgradeSubscriptionPage();
  }

  async back() {
    if (this.pageMode) {
      await this.goBlastHomePage();
    } else {
      this.dismissModal();
    }
  }

}
