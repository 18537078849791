import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { InboxListService } from './inbox-list.service';
import { InboxStatusService } from './inbox-status.service';
import { UserService } from '../user/user.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/interfaces/user';
import { Platform } from '@ionic/angular';
import { AccountsListService } from '../accounts/accounts-list.service';
import { UserAccounts } from 'src/app/interfaces/account';
import { FunctionService } from '../general/function.service';

import { InboxService } from './inbox.service';

@Injectable({
  providedIn: 'root'
})
export class InboxSetupService implements OnInit, OnDestroy {

  uid: string;

  userAccountsList: UserAccounts[];

  private userSubscription: Subscription;
  private accountListSubpscrption: Subscription;

  constructor(
    private platform: Platform,
    private userService: UserService,
    private accountsListService: AccountsListService,
    private inboxService: InboxService,
  
    private inboxStatusService: InboxStatusService,
    private functionService: FunctionService,
  ) {
    this.watch();
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    this.unwatch();
  }

  async watch() {
    await this.platform.ready();
    this.watchUser();
    this.watchAccountsListService();
  }

  unwatch() {
    this.unwatchUser();
    this.unwatchAccountList();
  }

  async watchUser() {
    if (!this.userSubscription) {
      this.userSubscription = this.userService.observableUser.subscribe((user: User) => {
        if (this.uid !== user?.uid) {
          this.uid = user?.uid;
          this.setup(this.uid);
        } 
      });
    }
    
  }

  async watchAccountsListService() {
    if (!this.accountListSubpscrption) {
      this.accountListSubpscrption = this.accountsListService.observableUserAccountsList.subscribe((userAccountsList: UserAccounts[]) => {
        if (!this.functionService.isEqual(userAccountsList, this.userAccountsList)) {
          this.userAccountsList = userAccountsList;
          this.setup(this.uid);
        }
      });
    }
  }

  async unwatchUser() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
      this.userSubscription = null;
    }
  }

  async unwatchAccountList() {
    if (this.accountListSubpscrption) {
      this.accountListSubpscrption.unsubscribe();
      this.accountListSubpscrption = null;
    }
  }

  setup(uid: string) {
    this.inboxService.setup(uid);
    this.inboxStatusService.setup(uid);
  }

}
