export const RefundTitle = {
    table: {
        widths: [ '*', 200],
        body: [
            [
                { colSpan: 2, text: '{{REFUND_TITLE}}', fontSize: 10, style: ['bold'] },
            ],
            [
                {
                    colSpan: 2,
                    canvas: [
                        {
                            type: 'line',
                            x1: 0,
                            y1: 0,
                            x2: 515.28,
                            y2: 0,
                            lineWidth: 0.5,
                            lineColor: 'grey',
                            dash: { length: 2, space: 1 }
                        }
                    ],
                    margin: [0, 0, 0, 10]
                }
            ]
        ]
    },
    margin: [0, 40, 0, 0],
    layout: 'noBorders',
};