import { Component, OnDestroy, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { OfflinePopoverComponent } from 'src/app/components/general/offline/offline-popover/offline-popover.component';
import { OnlineService } from 'src/app/services/general/online.service';

/**
 * Offline indicator component
 */
@Component({
  selector: 'app-offline-indicator',
  templateUrl: './offline-indicator.component.html',
  styleUrls: ['./offline-indicator.component.scss'],
})
export class OfflineIndicatorComponent implements OnInit, OnDestroy {

  /**
   * Online status
   */
  online: boolean;
  /**
   * Offline popover
   */
  private offlinePopover: HTMLIonPopoverElement;
  /**
   * Online subscription
   */
  private onlineSubscription: Subscription;

  /**
   * Constructor
   * @param platform Platform
   * @param popoverController Popover Controller
   * @param onlineService Online Service
   */
  constructor(
    private popoverController: PopoverController,
    private onlineService: OnlineService
  ) {
    this.initialize();
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.unwatchOnline();
  }

  /**
   * Initialize
   */
  async initialize() {
    this.online = true;
    this.watchOnline();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
   this.unwatchOnline();
  }

  /**
   * Watch online status
   */
  async watchOnline() {
    if (!this.onlineSubscription) {
      this.onlineSubscription = this.onlineService.observableOnline.subscribe((online: boolean) => {
        this.online = online;
        if (online) { this.dismissPopover(); }
      });
    }
    
  }

  /**
   * Unwatch online status
   */
  async unwatchOnline() {
    if (this.onlineSubscription) {
      this.onlineSubscription.unsubscribe();
      this.onlineSubscription = null;
    }
  }

  /**
   * Present offline popover
   */
  async presentPopover() {
    await this.dismissPopover();
    this.offlinePopover = await this.popoverController.create({
      component: OfflinePopoverComponent
    });
    this.offlinePopover.present();
    this.offlinePopover.onWillDismiss().then(() => {
      this.offlinePopover = null;
    });
  }

  /**
   * Dismiss offline popover
   */
  async dismissPopover() {
    if (this.offlinePopover) {
      await this.offlinePopover.dismiss();
    }
  }

}
