import { OnlineService } from 'src/app/services/general/online.service';
import { TransactionService } from 'src/app/services/payment/transaction.service';
import { ModalController } from '@ionic/angular';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { BehaviorSubject, Subscription, firstValueFrom, map } from 'rxjs';

import { PopupService } from 'src/app/services/general/popup.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { UpdateByService } from 'src/app/services/user/update-by.service';
import { ErrorService } from 'src/app/services/general/error.service';

import { Credit } from 'src/app/interfaces/credit';
import { ShareRecord } from 'src/app/interfaces/share';
import { ShareChannel, ShareMethod } from 'src/app/types/share';
import { UpdateBy } from 'src/app/interfaces/user';
import { AccountShareComponent } from 'src/app/components/account/account-share/account-share.component';
import { GiftCardTemplateComponent } from 'src/app/components/subscription/gift-card-template/gift-card-template.component';


@Injectable({
  providedIn: 'root'
})
export class CreditService implements OnInit, OnDestroy {

  /**
   * Credit list
   */
  creditList: Credit[];
  /**
   * Observable credit list
   */
  observableCreditList: any;

  /**
   * User id
   */
  private uid: string;
  /**
   * User credit subscription
   */
  private userCreditSubscription: Subscription;

  /**
   * Constructor
   * @param afs angularfire store
   * @param fns angularfire functions
   * @param popupService popup service
   * @param functionService function service
   */
  constructor(
    private modalController: ModalController,
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private transactionService: TransactionService,
    private updateByService: UpdateByService,
    private popupService: PopupService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
    this.creditList = [];
    this.observableCreditList = new BehaviorSubject<Credit[]>(this.creditList);
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy() {
    this.unwatchUserCredit();
    this.creditList = [];
  }

  /**
   * Set UID
   * @param uid UID
   */
  async setUid(uid?: string) {
    this.uid = uid;
    
    if (this.uid) {
      await this.watchUserCredit();
    } else {
      await this.unwatchUserCredit();
      this.creditList = [];
      this.observableCreditList.next(this.creditList);
    }
  }

  /**
   * Watch user credit
   */
  async watchUserCredit() {
    if (this.uid) {
      if (!this.userCreditSubscription) {
        this.userCreditSubscription = this.afs.collection(`credits`,
        ref => ref.where('createBy.uid', '==', this.uid).where('enable', '==', true))
        .snapshotChanges().pipe(map(changes => changes.map( a => {
          const data: Credit = a.payload.doc.data() as Credit;
          if (!data.creditId) { data.creditId = a.payload.doc.id; }
          if (data?.debug) {
  
          }
          return data;
        }).sort((a: Credit, b: Credit) => {
          return this.functionService.compare(a.createBy.time.seconds, b.createBy.time.seconds);
        }))).subscribe({
          next: (creditList: Credit[]) => {
            this.creditList = creditList;
            this.observableCreditList.next(this.creditList);
          }, error: (err: any) => {
            this.errorService.logError(err);
          }
        });
      }
    }
  }

  /**
   * Unwatch user credit
   */
  async unwatchUserCredit() {
    if (this.userCreditSubscription) {
      this.userCreditSubscription.unsubscribe();
      this.userCreditSubscription = null;
    }
  }

  async readCredit() {
    if (this.uid) {
      try {
        const querySnapshot = await firstValueFrom(this.afs.collection('credits', ref => ref.where('createBy.uid', '==', this.uid).where('enable', '==', true)).get());
        const creditList: Credit[] = querySnapshot.docs.map(doc => {
          const data = doc.data() as Credit;
          if (!data.creditId) {
            data.creditId = doc.id;
          }
          return data;
        }).sort((a: Credit, b: Credit) => {
          return this.functionService.compare(
            a?.createBy?.time?.seconds,
            b?.createBy?.time?.seconds,
            true
          );
        });
  
        this.creditList = creditList;
        this.observableCreditList.next(this.creditList);
      } catch (error) {
        this.errorService.logError(error);
      }
    }
  }

  /**
   * Search credit
   * @param type credit redeem status
   * @returns credit list
   */
  searchCredit(type: string): Credit[] {
    let creditList = this.creditList;
    if (type) {
      creditList = creditList.filter((credit: Credit) => {
        if (type === 'active' && credit.redeemed) {
          return false;
        } else if (type === 'redeemed' && !credit.redeemed) {
          return false;
        }
        return true;
      });
    }
    return creditList;
  }

  /**
   * Validate credit
   * @param creditId credit id
   * @returns promise return, true if is a valid credit
   */
  async validateCredit(creditId: string): Promise<boolean> {
    const creditRef = this.afs.doc(`credits/${ creditId }/`);
    const creditResult = await creditRef.ref.get();
    if (creditResult && creditResult.exists && creditResult.data()) {
        const credit: Credit = creditResult.data() as Credit;
        if (credit.enable && !credit.redeemed) {
            return true;
        }
    }
    return false;
  }

  /**
   * Get credit by id
   * @param creditId Credit id
   * @returns Credit
   */
  getCreditById(creditId: string): Credit {
    const index = this.creditList?.findIndex((credit: Credit) => credit?.creditId === creditId);
    if (index !== -1) {
      return this.creditList[index];
    }
    return null;
  }

  getCreditByAccountId(accountId: string): Credit {
    const index = this.creditList?.findIndex((credit: Credit) => accountId && credit?.accountId === accountId);
    if (index !== -1) {
      return this.creditList[index];
    }
    return null;
  }

  /**
   * Get user total credit list
   * @returns total credit list
   */
  getUserTotalCreditList(): Credit[] {
    const creditList = this.creditList.filter((credit: Credit) => credit.enable);
    return creditList;
  }

  /**
   * Get user used credit list
   * @returns used credit list
   */
  getUserUsedCreditList(): Credit[] {
    const creditList = this.creditList.filter((credit: Credit) => credit.enable && credit.redeemed);
    return creditList;
  }

  /**
   * Get user available credit list
   * @returns available credit list
   */
  getUserAvailableCreditList(gift?: boolean): Credit[] {
    const creditList = this.creditList.filter((credit: Credit) => {
      if (gift) {
        if (credit.enable && !credit.redeemed && !credit.gift) {
          return true;
        }
      } else {
        if (credit.enable && !credit.redeemed) {
          return true;
        }
      }
      return false;
    });
    return creditList;
  }

  /**
   * Get user total credit count
   * @returns number of total credit
   */
  getUserTotalCreditCount(): number {
    return this.getUserTotalCreditList().length;
  }

  /**
   * Get user used credit count
   * @returns number of used credit
   */
  getUserUsedCreditCount(): number {
    return this.getUserUsedCreditList().length;
  }

  /**
   * Get user available credit count
   * @returns number of available credit
   */
  getUserAvailableCreditCount(): number {
    return this.getUserAvailableCreditList().length;
  }

  /**
   * Get user available credit
   * @returns Credit
   */
  getUserAvailableCredit(): Credit {
    const creditList = this.creditList.filter((credit: Credit) => credit.enable && !credit.redeemed);
    return creditList.length ? creditList[0] : null;
  }

  getUserMigrateCredit(): Credit {
    const creditList = this.creditList.filter((credit: Credit) => {
      if (credit?.enable && !credit?.redeemed && credit?.transactionId) {
        const transaction = this.transactionService.getTransactionById(credit.transactionId);
        if (transaction?.paymentRef?.method === 'gift' && !transaction?.payment?.amount) {
          return true;
        }
      }
      return false;
    });
    return creditList.length ? creditList[0] : null;
  }

  /**
   * Update credit gift card ID fns call
   * @param creditId Credit ID
   * @param giftCardId Gift card ID
   * @returns true if update success
   */
  async updateCreditGiftCardIdCall(creditId: string, giftCardId: string) {
    // if (this.onlineService.isOnline()) {
      await this.popupService.presentLoading();
      return await this.fns.httpsCallable('updateCreditGiftCardIdCall')({ creditId, giftCardId })
      .toPromise().then(async (result) => {
        this.popupService.dismissLoading();
        return result;
      }).catch((err: any) => {
        this.errorService.logError(err);
        this.popupService.dismissLoading();
        return false;
      });
    // } else {
    //   return false;
    // }
  }

  /**
   * Update credit share log call
   * @param credit Credit
   * @param share Share record
   * @returns true if update success
   */
  async updateCreditShareLogCall(credit: Credit, share: ShareRecord, updateBy: UpdateBy) {
    // if (this.onlineService.isOnline()) {
      await this.popupService.presentLoading();
      return await this.fns.httpsCallable('updateCreditShareLogCall')({ credit, share, updateBy })
      .toPromise().then(async (result) => {
        this.popupService.dismissLoading();
        return result;
      }).catch((err: any) => {
        this.errorService.logError(err);
        this.popupService.dismissLoading();
        return false;
      });
    // } else {
    //   return false;
    // }
  }

  /**
   * Save share log
   * @param credit Credit
   * @param channel Channel
   * @param type Type
   */
  async saveShareLog(credit: Credit, channel: ShareChannel, method: ShareMethod) {
    if (credit?.creditId && method) {
      const by: UpdateBy = this.updateByService.updateBy;
      const share: ShareRecord = {
        channel,
        method,
        type: 'gift_card',
        by
      };
      await this.updateCreditShareLogCall(credit, share, by);
    }
  }

  /**
   * Send as gift
   */
  async sendAsGift(creditId: string) {
    // if (creditId && this.onlineService.isOnline()) {
      await this.popupService.presentLoading();
      const credit: Credit = this.getCreditById(creditId);
      if (credit?.enable && !credit.redeemed) {
        if (credit?.link?.short && credit.link.short.indexOf('wedding.thebigday.my') === -1) {
          this.sendGift(credit);
        } else {
          await this.generateCreditGiftCall(credit?.creditId);
        }
      }
      this.popupService.dismissLoading();
    // }
  }

  /**
   * Send gift
   * @param credit credit
   */
  async sendGift(credit: Credit) {
    if (credit) {
      if (credit.giftCardId) {
        await this.presentAccountShareModal(credit);
      } else {
        await this.presentGiftCardTemplateModal(credit);
      }
    }
  }

  /**
   * Present account share modal
   * @param credit credit
   */
  async presentAccountShareModal(credit: Credit) {
    if (credit) {
      const modal = await this.modalController.create({
        component: AccountShareComponent,
        cssClass: 'modal-full-screen-bk',
        componentProps: {
          credit,
          giftCard: true
        }
      });
      modal.present();
    }
  }

  /**
   * Present gift card template modal
   * @param credit credit
   */
  async presentGiftCardTemplateModal(credit: Credit) {
    if (credit) {
      const modal = await this.modalController.create({
        component: GiftCardTemplateComponent,
        componentProps: {
          giftCardId: credit?.giftCardId,
          credit
        }
      });
      modal.present();
    }
  }

  /**
   * Generate credit gift card fns call
   */
  async generateCreditGiftCall(creditId: string) {
    // if (creditId && this.onlineService.isOnline()) {
      await this.popupService.presentLoading();
      await this.fns.httpsCallable('generateCreditGiftCard')({
        creditId
      }).toPromise().then(async (credit: Credit) => {
        if (credit?.creditId && credit?.link) {
          await this.sendGift(credit);
        }
      }).catch((err) => {
        this.errorService.logError(err);
      });
      this.popupService.dismissLoading();
    // }
  }

}
