import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WebsiteAttachmentList, WebsiteAttachment } from 'src/app/interfaces/website';
import { AttachmentType } from 'src/app/types/general';

@Injectable({
  providedIn: 'root'
})
export class WebsiteAttachmentService implements OnInit, OnDestroy {
  
  attachment: WebsiteAttachmentList;

  defaultAttachment: WebsiteAttachment = {
    enable: false,
    title: '',
    msg: '',
    attachmentUrl: {
      url: '',
      type: '',
    },
    attachmentType: '',
    mimeType: '',
    fileName: '',
  };
  
  constructor(
    private translate: TranslateService,
  ) { }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
  
  getDefaultAttachment() {
    return { ...this.defaultAttachment };
  }

  setupAttachment(type: AttachmentType): WebsiteAttachment {
    if (type) {
      const attachment = this.getDefaultAttachment();
      attachment.attachmentType = type;
      attachment.title = this.translate.instant('ATTACHMENT.list.' + type);
      return attachment;
    }
    return null; 
  }
}
