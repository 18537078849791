import { UpdateByService } from 'src/app/services/user/update-by.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Device, DeviceInfo } from '@capacitor/device';
import { BrowserType } from 'src/app/types/device';

/**
 * Device service
 */
@Injectable({
  providedIn: 'root'
})
export class DeviceService implements OnInit, OnDestroy {

  /**
   * Device UUID
   */
  uuid: string;
  /**
   * Device Info from capacitor plugin
   */
  deviceInfo: DeviceInfo;

  /**
   * Constructor
   * @param platform Platform
   */
  constructor(
    private platform: Platform,
    private updateByService: UpdateByService,
  ) {
    this.getDeviceInfo();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Get device info
   */
  async getDeviceInfo() {
    await this.platform.ready();
    this.deviceInfo = await Device.getInfo();
    if (!this.deviceInfo?.webViewVersion) {
      this.deviceInfo.webViewVersion = '';
    }
    this.uuid = (await Device.getId())?.identifier;
    this.setupUuid();
    return this.deviceInfo;
  }

  getDeviceBrowser(): BrowserType {
    const userAgent = navigator.userAgent;
    let browserName: BrowserType = '';
    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = 'firefox';
    } else if (userAgent.match(/safari/i)) {
      browserName = 'safari';
    } else if (userAgent.match(/opr\//i)) {
      browserName = 'opera';
    } else if (userAgent.match(/edg/i)) {
      browserName = 'edge';
    } else if (userAgent.match(/FBAN|FBAV|FB_IAB/i)) {
      browserName = 'facebook';
    }
    return browserName;
  }

  setupUuid() {
    this.updateByService.setUuid(this.uuid);
  }

}
