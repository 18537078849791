import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { OnlineService } from 'src/app/services/general/online.service';
import { UpdateByService } from 'src/app/services/user/update-by.service';
import { UserService } from 'src/app/services/user/user.service';
import { GuestDeleteService } from 'src/app/services/guest/guest-delete.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { GroupService } from 'src/app/services/group/group.service';
import { GroupManageService } from 'src/app/services/group/group-manage.service';
import { ErrorService } from 'src/app/services/general/error.service';

import { Group } from 'src/app/interfaces/group';
import { UpdateBy } from 'src/app/interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class GroupDeleteService implements OnInit, OnDestroy {

  /**
   * Account ID
   */
  private accountId: string;

  /**
   * Constructor
   * @param afs angular firestore
   * @param fns cloud functions
   * @param guestManageService guest manage service
   * @param groupService group service
   * @param groupManageService group manage service
   * @param guestDeleteService guest delete service
   * @param userService user service
   * @param updateByService update by service
   * @param onlineService online service
   */
  constructor(
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private guestManageService: GuestManageService,
    private groupService: GroupService,
    private groupManageService: GroupManageService,
    private guestDeleteService: GuestDeleteService,
    private userService: UserService,
    private updateByService: UpdateByService,
    private onlineService: OnlineService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Setup Account ID
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
  }

  /**
   * Remove group
   * @param groupId group id
   * @param group group
   * @param updateGuest update guest
   */
  private async removeGroup(groupId: string, group?: Group, updateGuest?: boolean) {
    if (groupId) {
      if (!group?.groupId) {
        group = this.groupService.getGroup(groupId);
      }
      if (group?.groupId && !group?.status?.deleted) {
        if (group?.memberList?.length) {
          if (updateGuest) {
            await this.guestManageService.saveGuest({ groupId: '' }, group.memberList, '', [], true);
          } else {
            await this.guestDeleteService.removeGuestList(group.memberList);
          }
        }

        const groupData = {
          status: {
            deleted: true,
          },
          deleteBy: this.updateByService.updateBy
        };
        await this.groupManageService.saveGroupList([ group.groupId ], groupData);
      }
    }
  }

  /**
   * Remove group list
   * @param groupIdList group id list
   * @param groupList group list
   * @param updateGuest update guest
   */
  async removeGroupList(groupIdList: string[], groupList?: Group[], updateGuest?: boolean) {
    if (groupIdList?.length) {
      if (groupIdList.length === 1) {
        await this.removeGroup(groupIdList[0], groupList?.length ? groupList[0] : null, updateGuest);
      } else {
        let guestIdList: string[] = [];

        groupIdList?.forEach((groupId: string) => {
          let group: Group = null;
          const index = groupList?.findIndex((x: Group) => x?.groupId === groupId);
          if (index !== -1) {
            group = groupList[index];
          } else {
            group = this.groupService.getGroup(groupId);
          }
          if (group?.groupId && !group?.status?.deleted && group?.memberList?.length) {
            guestIdList = guestIdList.concat(group.memberList);
          }
        });

        if (guestIdList?.length) {
          if (updateGuest) {
            await this.guestManageService.saveGuest({ groupId: '' }, guestIdList, '', [], true);
          } else {
            await this.guestDeleteService.removeGuestList(guestIdList);
          }
        }

        const groupData = {
          status: {
            deleted: true,
          },
          deleteBy: this.updateByService.updateBy
        };
        await this.groupManageService.saveGroupList(groupIdList, groupData);
      }
    }
  }

  /**
   * Remove member from group
   * @param groupId group id
   * @param group group
   * @param memberList member list
   * @param removeGuest remove guest
   */
  async removeMemberFromGroup(groupId: string, group?: Group, memberList?: string[], removeGuest?: boolean) {
    if (groupId) {
      if (!group?.groupId) {
        group = this.groupService.getGroup(groupId);
      }
      if (group?.groupId && !group?.status?.deleted && group?.memberList?.length) {
        const guestIdList: string[] = [];

        group.memberList = group.memberList.filter((guestId: string) => {
          const index = memberList?.indexOf(guestId);
          if (!memberList?.length || index !== -1) {
            guestIdList.push(guestId);
          }
          if (!memberList?.length || index === -1) {
            return true;
          } else {
            return false;
          }
        });

        if (guestIdList?.length && removeGuest) {
          await this.guestDeleteService.removeGuestList(guestIdList);
        } else {
          await this.guestManageService.saveGuest({ groupId: '' }, guestIdList, '', [], true);
        }
      }
    }
  }

  /**
   * Restore group
   * @param groupId group id
   */
  private async restoreGroup(groupId: string) {
    if (groupId) {
      const group = this.groupService.getGroup(groupId);
      if (group?.groupId && group?.status?.deleted) {
        const groupData = {
          status: {
            deleted: false,
          },
          updateBy: this.updateByService.updateBy
        };
        await this.groupManageService.saveGroupList([ group.groupId ], groupData);
      }
    }
  }

  /**
   * Restore group list
   * @param groupIdList group id list
   */
  async restoreGroupList(groupIdList: string[]) {
    if (groupIdList?.length) {
      if (groupIdList.length === 1) {
        await this.restoreGroup(groupIdList[0]);
      } else {
        const updateBy: UpdateBy = this.updateByService.updateBy;
        const groupData = {
          status: {
            deleted: false,
          },
          updateBy
        };
        await this.groupManageService.saveGroupList(groupIdList, groupData);
      }
    }
  }

  /**
   * Delete group
   * @param groupId group id
   */
  private async deleteGroup(groupId: string) {
    if (this.accountId && groupId) {
      const groupRef = this.afs.firestore.doc(`accounts/${ this.accountId }/groups/${ groupId }`);
      groupRef.delete();
    }
  }

  /**
   * Delete group list
   * @param groupIdList group id list
   */
  async deleteGroupList(groupIdList: string[]) {
    if (this.onlineService.online && groupIdList?.length > 100) {
      await this.fns.httpsCallable('batchDeleteGroup')({
        groupIdList,
        accountId: this.accountId,
        updateBy: this.updateByService.updateBy,
        uid: this.userService.uid
      }).toPromise().then((response) => {
      }).catch((err) => {
        this.errorService.logError(err);
      });
    } else if (this.onlineService.online && groupIdList?.length > 1) {
      const batch = this.afs.firestore.batch();
      groupIdList?.forEach((groupId: string) => {
        const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/groups/${ groupId }`);
        batch.delete(ref);
      });
      await batch.commit();
    } else {
      for (const groupId of groupIdList) {
        await this.deleteGroup(groupId);
      }
    }
  }

  /**
   * Delete group member
   * @param groupId group id
   * @param memberList member list
   * @param group group
   */
  async deleteGroupMember(groupId: string, memberList?: string[], group?: Group) {
    if (groupId) {
      if (!group?.groupId) {
        group = this.groupService.getGroup(groupId);
      }
      if (group?.groupId && group?.status?.deleted && group?.memberList?.length) {
        if (memberList.length) {
          group.memberList = group.memberList.filter((guestId: string) => {
            if (memberList?.indexOf(guestId) === -1) {
              return true;
            } else {
              return false;
            }
          });
        }
        await this.guestDeleteService.deleteGuestList(group.memberList);
      }
    }
  }

  /**
   * Delete group list member
   * @param groupIdList group id list
   * @param groupList group list
   */
  async deleteGroupListMember(groupIdList: string[], groupList?: Group[]) {
    if (groupIdList?.length) {
      let memberList: string[] = [];
      groupIdList?.forEach((groupId: string) => {
        let group: Group = null;
        const index = groupList?.findIndex((x: Group) => x?.groupId === groupId);
        if (index !== -1) {
          group = groupList[index];
        } else {
          group = this.groupService.getGroup(groupId);
        }
        if (group?.memberList) {
          memberList = memberList.concat(group.memberList);
        }
      });
      if (memberList?.length) {
        await this.guestDeleteService.deleteGuestList(memberList);
      }
    }
  }

}
