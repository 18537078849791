import { BlastTemplate } from "../interfaces/blast";

export const SmsAscii = {
    single: 160,
    multi: 153
};
export const SmsUnicode = {
    single: 70,
    multi: 67
};

export const SmsMaxCount = 10;

export const SmsEscapeCharacter = [
    '^',
    '{',
    '}',
    '\\',
    '[',
    ']',
    '~',
    '|',
    '€',
    ']'
];

export const SmsTitleMaxLength = 100;
export const SmsTitleMaxLengthUnicode = 30;
export const SmsUrlMsgMaxLength = 60;
export const SmsUrlMsgMaxLengthUnicode = 20;

export const StdSmsTemplateList: BlastTemplate[] = [
    {
        templateId: 'wedding-sms-qrcode-en-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: false,
        url: true,
        eventMode: false,
        msg: 'Simply present the QR code below to check-in on our big day.'
    },
    {
        templateId: 'wedding-sms-qrcode-en-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: false,
        msg: 'Join us on [WEDDING_DATE] at [WEDDING_START_TIME] at [WEDDING_VENUE_NAME]! Present the attached QR code for check-in on our big day.'
    },
    {
        templateId: 'wedding-sms-qrcode-en-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: false,
        msg: 'Save the date! Join us at [WEDDING_VENUE_NAME] on [WEDDING_DATE] for our special day. Simply present the attached QR code for a hassle-free check-in.'
    },
    {
        templateId: 'wedding-sms-qrcode-en-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: false,
        msg: 'Dear [GUEST_NAME], join us at our wedding on [WEDDING_DATE] at [WEDDING_START_TIME] in [WEDDING_VENUE_NAME]. Present the attached QR code for check-in.'
    },
    {
        templateId: 'wedding-sms-qrcode-en-5',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '5',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: false,
        msg: 'Name: [GUEST_NAME]\n[SEATING_TYPE]: [GUEST_SEATING]\nVenue: [WEDDING_VENUE_NAME]\nDate: [WEDDING_DATE]\nTime: [WEDDING_START_TIME]'
    },
    {
        templateId: 'wedding-sms-qrcode-ms-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: false,
        url: true,
        eventMode: false,
        msg: 'Hanya tunjukkan kod QR untuk mendaftar masuk pada hari besar kami.'
    },
    {
        templateId: 'wedding-sms-qrcode-ms-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: false,
        msg: 'Sertai kami pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME]! Kemukakan kod QR yang dilampirkan untuk daftar masuk pada hari besar kami.'
    },
    {
        templateId: 'wedding-sms-qrcode-ms-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: false,
        msg: 'Simpan tarikh! Sertai kami di [WEDDING_VENUE_NAME] pada [WEDDING_DATE] untuk hari istimewa kami. Hanya tunjukkan kod QR yang dilampirkan untuk daftar masuk tanpa kerumitan.'
    },
    {
        templateId: 'wedding-sms-qrcode-ms-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: false,
        msg: '[GUEST_NAME] yang dihormati, sertai kami di majlis perkahwinan kami pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME]. Kemukakan kod QR yang dilampirkan untuk daftar masuk.'
    },
    {
        templateId: 'wedding-sms-qrcode-ms-5',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '5',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: false,
        msg: 'Nama: [GUEST_NAME]\n[SEATING_TYPE]: [GUEST_SEATING]\nTempat: [WEDDING_VENUE_NAME]\nTarikh: [WEDDING_DATE]\nMasa: [WEDDING_START_TIME]'
    },
    {
        templateId: 'wedding-sms-qrcode-zh-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: false,
        url: true,
        eventMode: false,
        msg: '请使用以下链接在前台出示您的QR Code。'
    },
    {
        templateId: 'wedding-sms-qrcode-zh-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: false,
        msg: '诚挚邀请您出席我们于[WEDDING_DATE][WEDDING_START_TIME]，在[WEDDING_VENUE_NAME]举办的婚礼。'
    },
    {
        templateId: 'wedding-sms-qrcode-zh-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: false,
        url: true,
        eventMode: false,
        msg: '荣幸地邀请您参加我们婚礼，一同见证我们的爱情盛宴。请使用以下链接在前台出示您的QR Code。'
    },
    {
        templateId: 'wedding-sms-qrcode-zh-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: false,
        msg: '亲爱的[GUEST_NAME]，诚挚邀请您出席我们于[WEDDING_DATE][WEDDING_START_TIME]，在[WEDDING_VENUE_NAME]举办的婚礼。请使用以下链接在前台出示您的QR Code。'
    },
    {
        templateId: 'wedding-sms-qrcode-zh-5',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '5',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: false,
        msg: '名字: [GUEST_NAME]\n[SEATING_TYPE]: [GUEST_SEATING]\n场地: [WEDDING_VENUE_NAME]\n日期: [WEDDING_DATE]\n时间: [WEDDING_START_TIME]'
    },
    {
        templateId: 'wedding-sms-qrcode-zh-tw-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: false,
        url: true,
        eventMode: false,
        msg: '請使用以下鏈接在前台出示您的QR Code。'
    },
    {
        templateId: 'wedding-sms-qrcode-zh-tw-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: false,
        msg: '誠摯邀請您出席我們於[WEDDING_DATE][WEDDING_START_TIME]，在[WEDDING_VENUE_NAME]舉辦的婚禮。'
    },
    {
        templateId: 'wedding-sms-qrcode-zh-tw-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: false,
        url: true,
        eventMode: false,
        msg: '榮幸地邀請您參加我們婚禮，一同見證我們的愛情盛宴。請使用以下鏈接在前台出示您的QR Code。'
    },
    {
        templateId: 'wedding-sms-qrcode-zh-tw-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: false,
        msg: '親愛的[GUEST_NAME]，誠摯邀請您出席我們於[WEDDING_DATE][WEDDING_START_TIME]，在[WEDDING_VENUE_NAME]舉辦的婚禮。請使用以下鏈接在前台出示您的QR Code。'
    },
    {
        templateId: 'wedding-sms-qrcode-zh-tw-5',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '5',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: false,
        msg: '名字: [GUEST_NAME]\n[SEATING_TYPE]: [GUEST_SEATING]\n場地: [WEDDING_VENUE_NAME]\n日期: [WEDDING_DATE]\n時間: [WEDDING_START_TIME]'
    },
    {
        templateId: 'wedding-sms-reminder-en-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: false,
        msg: 'Looking forward to celebrating with you at our wedding on [WEDDING_DATE] at [WEDDING_START_TIME] at [WEDDING_VENUE_NAME].'
    },
    {
        templateId: 'wedding-sms-reminder-en-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: false,
        msg: 'Get ready to celebrate! Our wedding day is on [WEDDING_DATE] at [WEDDING_VENUE_NAME], where love and happiness will fill the air from [WEDDING_START_TIME] onwards.'
    },
    {
        templateId: 'wedding-sms-reminder-en-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: false,
        msg: 'We\'re counting down the days until our wedding on [WEDDING_DATE]! We hope you\'ll be there to share in the love and joy at [WEDDING_VENUE_NAME], starting at [WEDDING_START_TIME].'
    },
    {
        templateId: 'wedding-sms-reminder-en-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: false,
        msg: 'Dear [GUEST_NAME], we\'re excited to have you join us at our wedding on [WEDDING_DATE]! Get ready for a day filled with love and joy at [WEDDING_VENUE_NAME] starting at [WEDDING_START_TIME]. Your assigned [SEATING_TYPE] is [GUEST_SEATING], and we\'ve reserved [NUMBER_OF_GUEST] seats for you and your party.'
    },
    {
        templateId: 'wedding-sms-reminder-en-5',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '5',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: 'Name: [GUEST_NAME]\n[SEATING_TYPE]: [GUEST_SEATING]\nVenue: [WEDDING_VENUE_NAME]\nDate: [WEDDING_DATE]\nTime: [WEDDING_START_TIME]'
    },
    {
        templateId: 'wedding-sms-reminder-ms-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: 'Tidak sabar-sabar untuk meraikan anda di perkahwinan kami pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME].'
    },
    {
        templateId: 'wedding-sms-reminder-ms-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: 'Bersedia untuk meraikan! Hari perkahwinan kami pada [WEDDING_DATE] di [WEDDING_VENUE_NAME], di mana cinta dan kebahagiaan akan memenuhi udara mulai [WEDDING_START_TIME] dan seterusnya.'
    },
    {
        templateId: 'wedding-sms-reminder-ms-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: 'Kami sedang menghitung hari sehingga perkahwinan kami pada [WEDDING_DATE]! Kami berharap anda akan berada di sana untuk berkongsi cinta dan kegembiraan di [WEDDING_VENUE_NAME], bermula pada [WEDDING_START_TIME].'
    },
    {
        templateId: 'wedding-sms-reminder-ms-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: '[GUEST_NAME] yang dihormati, kami teruja untuk anda menyertai kami di majlis perkahwinan kami pada [WEDDING_DATE]! Bersedialah untuk hari yang penuh dengan cinta dan kegembiraan di [WEDDING_VENUE_NAME] bermula pada [WEDDING_START_TIME]. [SEATING_TYPE] yang anda berikan ialah [GUEST_SEATING] dan kami telah menempah [NUMBER_OF_GUEST] tempat duduk untuk anda dan parti anda.'
    },
    {
        templateId: 'wedding-sms-reminder-ms-5',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '5',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: 'Nama: [GUEST_NAME]\n[SEATING_TYPE]: [GUEST_SEATING]\nTempat: [WEDDING_VENUE_NAME]\nTarikh: [WEDDING_DATE]\nMasa: [WEDDING_START_TIME]'
    },
    {
        templateId: 'wedding-sms-reminder-zh-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: '期待在[WEDDING_DATE][WEDDING_START_TIME]，在[WEDDING_VENUE_NAME]于我们的婚礼上见到您！'
    },
    {
        templateId: 'wedding-sms-reminder-zh-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: '非常期待您的出席，让婚礼更加难忘。婚礼于[WEDDING_DATE][WEDDING_START_TIME]，在[WEDDING_VENUE_NAME]举行。'
    },
    {
        templateId: 'wedding-sms-reminder-zh-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: '期待您出席我们于[WEDDING_DATE][WEDDING_START_TIME]在[WEDDING_VENUE_NAME]举行的婚礼，您的到来将为我们增添喜悦。'
    },
    {
        templateId: 'wedding-sms-reminder-zh-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: '亲爱的 [GUEST_NAME]。期待您出席我们于[WEDDING_DATE][WEDDING_START_TIME]在[WEDDING_VENUE_NAME]举行的婚礼，您的到来将为我们增添喜悦。'
    },
    {
        templateId: 'wedding-sms-reminder-zh-5',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '5',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: '名字: [GUEST_NAME]\n[SEATING_TYPE]: [GUEST_SEATING]\n场地: [WEDDING_VENUE_NAME]\n日期: [WEDDING_DATE]\n时间: [WEDDING_START_TIME]'
    },
    {
        templateId: 'wedding-sms-reminder-zh-tw-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: '期待在[WEDDING_DATE][WEDDING_START_TIME]，在[WEDDING_VENUE_NAME]於我們的婚禮上見到您！'
    },
    {
        templateId: 'wedding-sms-reminder-zh-tw-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: '非常期待您的出席，讓婚禮更加難忘。婚禮於[WEDDING_DATE][WEDDING_START_TIME]，在[WEDDING_VENUE_NAME]舉行。'
    },
    {
        templateId: 'wedding-sms-reminder-zh-tw-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: '期待您出席我們於[WEDDING_DATE][WEDDING_START_TIME]在[WEDDING_VENUE_NAME]舉行的婚禮，您的到來將為我們增添喜悅。'
    },
    {
        templateId: 'wedding-sms-reminder-zh-tw-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: '親愛的 [GUEST_NAME]。期待您出席我們於[WEDDING_DATE][WEDDING_START_TIME]在[WEDDING_VENUE_NAME]舉行的婚禮，您的到來將為我們增添喜悅。'
    },
    {
        templateId: 'wedding-sms-reminder-zh-tw-5',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '5',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: '名字: [GUEST_NAME]\n[SEATING_TYPE]: [GUEST_SEATING]\n場地: [WEDDING_VENUE_NAME]\n日期: [WEDDING_DATE]\n時間: [WEDDING_START_TIME]'
    },
    {
        templateId: 'wedding-sms-thank_you-en-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: false,
        msg: 'Your presence at our wedding made it an extraordinary day. Thank you for being a part of our special celebration!'
    },
    {
        templateId: 'wedding-sms-thank_you-en-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: false,
        msg: 'We want to express our deepest gratitude for your presence at our wedding, making our day truly unforgettable. Thank you from the bottom of our hearts for being there and sharing in the creation of cherished memories.'
    },
    {
        templateId: 'wedding-sms-thank_you-en-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: false,
        msg: 'Our hearts are overflowing with gratitude for your presence at our wedding. Your love and support made our day absolutely extraordinary. Thank you for joining us and helping to create everlasting memories.'
    },
    {
        templateId: 'wedding-sms-thank_you-en-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: 'Dear [GUEST_NAME], thank you for being an integral part of our wedding day. Your presence brought warmth and happiness to our celebration, and we are incredibly thankful for the memories we shared together.'
    },
    {
        templateId: 'wedding-sms-thank_you-ms-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: false,
        msg: 'Kehadiran anda di majlis perkahwinan kami menjadikannya hari yang luar biasa. Terima kasih kerana menjadi sebahagian daripada perayaan istimewa kami!'
    },
    {
        templateId: 'wedding-sms-thank_you-ms-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: false,
        msg: 'Kami ingin merakamkan ucapan terima kasih yang tidak terhingga atas kehadiran anda di majlis perkahwinan kami, menjadikan hari kami benar-benar tidak dapat dilupakan. Terima kasih dari lubuk hati kami kerana berada di sana dan berkongsi dalam penciptaan kenangan yang dihargai.'
    },
    {
        templateId: 'wedding-sms-thank_you-ms-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: false,
        msg: 'Hati kami dilimpahi rasa syukur atas kehadiran anda di majlis perkahwinan kami. Kasih sayang dan sokongan anda menjadikan hari kami benar-benar luar biasa. Terima kasih kerana menyertai kami dan membantu mencipta kenangan abadi.'
    },
    {
        templateId: 'wedding-sms-thank_you-ms-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: '[GUEST_NAME] yang dihormati, terima kasih kerana menjadi sebahagian daripada hari perkahwinan kami. Kehadiran anda membawa kemesraan dan kegembiraan pada perayaan kami, dan kami amat berterima kasih atas kenangan yang kami kongsi bersama.'
    },
    {
        templateId: 'wedding-sms-thank_you-zh-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: false,
        msg: '感谢您在百忙中参加我们的婚礼见证我们的幸福。也祝福您平安健康，一切顺利。'
    },
    {
        templateId: 'wedding-sms-thank_you-zh-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: false,
        msg: '感谢您与我们分享婚礼的美好时刻，您的存在让我们感到无比的喜悦和温暖。'
    },
    {
        templateId: 'wedding-sms-thank_you-zh-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: false,
        msg: '感谢您出席我们的婚礼，您的到来让我们的特别日子更加完美和有意义！您的支持和祝福对我们意义非凡。'
    },
    {
        templateId: 'wedding-sms-thank_you-zh-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: '亲爱的[GUEST_NAME]，谢谢您的出席，与我们共同分享这个特别的时刻，也衷心感谢您的祝福。'
    },
    {
        templateId: 'wedding-sms-thank_you-zh-tw-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: false,
        msg: '感謝您在百忙中參加我們的婚禮見證我們的幸福。也祝福您平安健康，一切順利。'
    },
    {
        templateId: 'wedding-sms-thank_you-zh-tw-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: false,
        msg: '感謝您與我們分享婚禮的美好時刻，您的存在讓我們感到無比的喜悅和溫暖。'
    },
    {
        templateId: 'wedding-sms-thank_you-zh-tw-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: false,
        msg: '感謝您出席我們的婚禮，您的到來讓我們的特別日子更加完美和有意義！您的支持和祝福對我們意義非凡。'
    },
    {
        templateId: 'wedding-sms-thank_you-zh-tw-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: false,
        msg: '親愛的[GUEST_NAME]，謝謝您的出席，與我們共同分享這個特別的時刻，也衷心感謝您的祝福。'
    }
];

export const StdEventSmsTemplateList: BlastTemplate[] = [
    {
        templateId: 'event-sms-qrcode-en-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: false,
        url: true,
        eventMode: true,
        msg: 'Kindly present the enclosed QR code for check-in during the event.'
    },
    {
        templateId: 'event-sms-qrcode-en-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: true,
        msg: 'We kindly request your presence at our event on [WEDDING_DATE] at [WEDDING_START_TIME] at [WEDDING_VENUE_NAME]. Please present the attached QR code for check-in upon arrival.'
    },
    {
        templateId: 'event-sms-qrcode-en-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: true,
        msg: 'Join us for an unforgettable event on [WEDDING_DATE] at [WEDDING_START_TIME] at the enchanting [WEDDING_VENUE_NAME]. Simply scan the attached QR code upon arrival to check-in and embark on a remarkable experience.'
    },
    {
        templateId: 'event-sms-qrcode-en-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: true,
        msg: 'Dear [GUEST_NAME], we humbly request the pleasure of your company at our event on [WEDDING_DATE] at [WEDDING_START_TIME] at the esteemed [WEDDING_VENUE_NAME]. Kindly present the attached QR code for check-in upon your arrival.'
    },
    {
        templateId: 'event-sms-qrcode-en-5',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '5',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: true,
        msg: 'Name: [GUEST_NAME]\n[SEATING_TYPE]: [GUEST_SEATING]\nVenue: [WEDDING_VENUE_NAME]\nDate: [WEDDING_DATE]\nTime: [WEDDING_START_TIME]'
    },
    {
        templateId: 'event-sms-qrcode-ms-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: false,
        url: true,
        eventMode: true,
        msg: 'Sila kemukakan kod QR yang disertakan untuk daftar masuk semasa acara berlangsung.'
    },
    {
        templateId: 'event-sms-qrcode-ms-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: false,
        url: true,
        eventMode: true,
        msg: 'Kami memohon kehadiran anda di acara kami pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME]. Sila tunjukkan kod QR yang dilampirkan untuk daftar masuk semasa ketibaan.'
    },
    {
        templateId: 'event-sms-qrcode-ms-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: true,
        msg: 'Sertai kami untuk acara yang tidak dapat dilupakan pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME] yang mempesonakan. Hanya imbas kod QR yang dilampirkan semasa ketibaan untuk mendaftar masuk dan mulakan pengalaman yang luar biasa.'
    },
    {
        templateId: 'event-sms-qrcode-ms-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: true,
        msg: '[GUEST_NAME] yang dihormati, kami dengan rendah hati meminta keridhaan syarikat anda pada acara kami pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME] yang dihormati. Sila tunjukkan kod QR yang dilampirkan untuk daftar masuk semasa ketibaan anda.'
    },
    {
        templateId: 'event-sms-qrcode-ms-5',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '5',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: true,
        msg: 'Nama: [GUEST_NAME]\n[SEATING_TYPE]: [GUEST_SEATING]\nTempat: [WEDDING_VENUE_NAME]\nTarikh: [WEDDING_DATE]\nMasa: [WEDDING_START_TIME]'
    },
    {
        templateId: 'event-sms-qrcode-zh-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: false,
        url: true,
        eventMode: true,
        msg: '请使用以下链接在前台出示您的QR Code。'
    },
    {
        templateId: 'event-sms-qrcode-zh-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: true,
        msg: '我们恳请您参加我们于[WEDDING_DATE][WEDDING_START_TIME]，在[WEDDING_VENUE_NAME]举办的活动。'
    },
    {
        templateId: 'event-sms-qrcode-zh-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: false,
        url: true,
        eventMode: true,
        msg: '荣幸地邀请您参加我们活动。请在抵达时出示随附的QR Code。'
    },
    {
        templateId: 'event-sms-qrcode-zh-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: true,
        msg: '亲爱的 [GUEST_NAME]，我们诚挚地邀请您参加于 [WEDDING_DATE] [WEDDING_START_TIME] 在 [WEDDING_VENUE_NAME] 举办的活动。请在抵达时出示随附的QR Code以办理签到手续。'
    },
    {
        templateId: 'event-sms-qrcode-zh-5',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '5',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: true,
        msg: '名字: [GUEST_NAME]\n[SEATING_TYPE]: [GUEST_SEATING]\n场地: [WEDDING_VENUE_NAME]\n日期: [WEDDING_DATE]\n时间: [WEDDING_START_TIME]'
    },
    {
        templateId: 'event-sms-qrcode-zh-tw-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: false,
        url: true,
        eventMode: true,
        msg: '請使用以下鏈接在前台出示您的QR Code。'
    },
    {
        templateId: 'event-sms-qrcode-zh-tw-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: true,
        msg: '我們懇請您參加我們於[WEDDING_DATE][WEDDING_START_TIME]，在[WEDDING_VENUE_NAME]舉辦的活動。'
    },
    {
        templateId: 'event-sms-qrcode-zh-tw-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: false,
        url: true,
        eventMode: true,
        msg: '榮幸地邀請您參加我們活動。請在抵達時出示隨附的QR Code。'
    },
    {
        templateId: 'event-sms-qrcode-zh-tw-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: true,
        msg: '親愛的 [GUEST_NAME]，我們誠摯地邀請您參加於 [WEDDING_DATE] [WEDDING_START_TIME] 在 [WEDDING_VENUE_NAME] 舉辦的活動。請在抵達時出示隨附的QR Code以辦理簽到手續。'
    },
    {
        templateId: 'event-sms-qrcode-zh-tw-5',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '5',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: true,
        msg: '名字: [GUEST_NAME]\n[SEATING_TYPE]: [GUEST_SEATING]\n場地: [WEDDING_VENUE_NAME]\n日期: [WEDDING_DATE]\n時間: [WEDDING_START_TIME]'
    },
    {
        templateId: 'event-sms-reminder-en-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: 'We eagerly await your presence at our event on [WEDDING_DATE] at [WEDDING_START_TIME] at [WEDDING_VENUE_NAME].'
    },
    {
        templateId: 'event-sms-reminder-en-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: 'We can\'t wait to see you at our event on [WEDDING_DATE] at [WEDDING_START_TIME] at [WEDDING_VENUE_NAME].'
    },
    {
        templateId: 'event-sms-reminder-en-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: true,
        msg: 'We are excitedly anticipating your attendance at our event on [WEDDING_DATE] at [WEDDING_START_TIME] at [WEDDING_VENUE_NAME].'
    },
    {
        templateId: 'event-sms-reminder-en-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: 'Dear [GUEST_NAME], we anxiously anticipate your attendance at our event on [WEDDING_DATE] at [WEDDING_START_TIME] at [WEDDING_VENUE_NAME]. Your designated [SEATING_TYPE] is [GUEST_SEATING], and we have secured [NUMBER_OF_GUEST] seats for you and your esteemed guests.'
    },
    {
        templateId: 'event-sms-reminder-en-5',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '5',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: 'Name: [GUEST_NAME]\n[SEATING_TYPE]: [GUEST_SEATING]\nVenue: [WEDDING_VENUE_NAME]\nDate: [WEDDING_DATE]\nTime: [WEDDING_START_TIME]'
    },
    {
        templateId: 'event-sms-reminder-ms-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: 'Kami sangat menanti kehadiran anda di acara kami pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME].'
    },
    {
        templateId: 'event-sms-reminder-ms-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: 'Kami tidak sabar untuk melihat anda di acara kami pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME].'
    },
    {
        templateId: 'event-sms-reminder-ms-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: true,
        msg: 'Kami sangat teruja menjangkakan kehadiran anda pada acara kami pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME].'
    },
    {
        templateId: 'event-sms-reminder-ms-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: '[GUEST_NAME] yang dihormati, kami amat menjangkakan kehadiran anda pada acara kami pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME]. Nombor [SEATING_TYPE] anda yang ditetapkan ialah [GUEST_SEATING], dan kami telah menjamin tempat duduk [NUMBER_OF_GUEST] untuk anda dan tetamu yang dihormati.'
    },
    {
        templateId: 'event-sms-reminder-ms-5',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '5',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: 'Nama: [GUEST_NAME]\n[SEATING_TYPE]: [GUEST_SEATING]\nTempat: [WEDDING_VENUE_NAME]\nTarikh: [WEDDING_DATE]\nMasa: [WEDDING_START_TIME]'
    },
    {
        templateId: 'event-sms-reminder-zh-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: '我们非常期待您出席我们于 [WEDDING_DATE] [WEDDING_START_TIME] 在 [WEDDING_VENUE_NAME] 举行的活动。'
    },
    {
        templateId: 'event-sms-reminder-zh-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: '我们迫不及待地想在 [WEDDING_DATE] [WEDDING_START_TIME] [WEDDING_VENUE_NAME] 的活动中见到您。'
    },
    {
        templateId: 'event-sms-reminder-zh-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: true,
        msg: '热切期待您参加我们于 [WEDDING_DATE] [WEDDING_START_TIME] 在 [WEDDING_VENUE_NAME] 举办的活动。'
    },
    {
        templateId: 'event-sms-reminder-zh-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: '亲爱的 [GUEST_NAME]，我们热切期待您参加我们于 [WEDDING_DATE] [WEDDING_START_TIME] 在 [WEDDING_VENUE_NAME] 举行的活动。您的指定[SEATING_TYPE]是[GUEST_SEATING]，我们已经为您和您的贵宾预留了[NUMBER_OF_GUEST]个座位。'
    },
    {
        templateId: 'event-sms-reminder-zh-5',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '5',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: '名字: [GUEST_NAME]\n[SEATING_TYPE]: [GUEST_SEATING]\n场地: [WEDDING_VENUE_NAME]\n日期: [WEDDING_DATE]\n时间: [WEDDING_START_TIME]'
    },
    {
        templateId: 'event-sms-reminder-zh-tw-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: '我們非常期待您出席我們於 [WEDDING_DATE] [WEDDING_START_TIME] 在 [WEDDING_VENUE_NAME] 舉行的活動。'
    },
    {
        templateId: 'event-sms-reminder-zh-tw-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: '我們迫不及待地想在 [WEDDING_DATE] [WEDDING_START_TIME] [WEDDING_VENUE_NAME] 的活動中見到您。'
    },
    {
        templateId: 'event-sms-reminder-zh-tw-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: true,
        url: true,
        eventMode: true,
        msg: '熱切期待您參加我們於 [WEDDING_DATE] [WEDDING_START_TIME] 在 [WEDDING_VENUE_NAME] 舉辦的活動。'
    },
    {
        templateId: 'event-sms-reminder-zh-tw-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: '親愛的 [GUEST_NAME]，我們熱切期待您參加我們於 [WEDDING_DATE] [WEDDING_START_TIME] 在 [WEDDING_VENUE_NAME] 舉行的活動。您的指定[SEATING_TYPE]是[GUEST_SEATING]，我們已經為您和您的貴賓預留了[NUMBER_OF_GUEST]個座位。'
    },
    {
        templateId: 'event-sms-reminder-zh-tw-5',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '5',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: '名字: [GUEST_NAME]\n[SEATING_TYPE]: [GUEST_SEATING]\n場地: [WEDDING_VENUE_NAME]\n日期: [WEDDING_DATE]\n時間: [WEDDING_START_TIME]'
    },
    {
        templateId: 'event-sms-thank_you-en-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: true,
        msg: 'We\'re grateful for your presence at our event. Your support is invaluable, and we eagerly anticipate future celebrations together.'
    },
    {
        templateId: 'event-sms-thank_you-en-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: true,
        msg: 'Thank you for being a part of our event. Your presence has touched our hearts, and we\'re excited to create more lasting memories together.'
    },
    {
        templateId: 'event-sms-thank_you-en-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: true,
        msg: 'We extend our heartfelt gratitude for your presence at our event. Your support is invaluable, and we can\'t wait to celebrate together again.'
    },
    {
        templateId: 'event-sms-thank_you-en-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'en',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: 'Dear [GUEST_NAME], thank you for attending our event. Your presence is greatly appreciated, and we value your support. We look forward to future celebrations together.'
    },
    {
        templateId: 'event-sms-thank_you-ms-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: true,
        msg: 'Kami berterima kasih atas kehadiran anda di majlis kami. Sokongan anda tidak ternilai, dan kami tidak sabar-sabar menjangkakan perayaan masa depan bersama-sama.'
    },
    {
        templateId: 'event-sms-thank_you-ms-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: true,
        msg: 'Terima kasih kerana menjadi sebahagian daripada majlis kami. Kehadiran anda telah menyentuh hati kami dan kami teruja untuk mencipta lebih banyak kenangan kekal bersama.'
    },
    {
        templateId: 'event-sms-thank_you-ms-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: true,
        msg: 'Kami mengucapkan ribuan terima kasih atas kehadiran anda di majlis kami. Sokongan anda tidak ternilai, dan kami tidak sabar untuk meraikan bersama lagi.'
    },
    {
        templateId: 'event-sms-thank_you-ms-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'ms',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: '[GUEST_NAME] yang dihormati, terima kasih kerana menghadiri acara kami. Kehadiran anda amat kami hargai dan sokongan anda amat kami hargai. Kami menantikan perayaan akan datang bersama-sama.'
    },
    {
        templateId: 'event-sms-thank_you-zh-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: true,
        msg: '感谢您出席我们的活动，您的参与使这个特别的时刻更加难忘！我们深感荣幸能与您分享这段珍贵的时光。'
    },
    {
        templateId: 'event-sms-thank_you-zh-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: true,
        msg: '感谢您莅临我们的活动！我们想向每一位与会者表达衷心的感谢和诚挚的祝福。期待与您在下次活动中再次相见！'
    },
    {
        templateId: 'event-sms-thank_you-zh-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: true,
        msg: '我们对您出席我们的活动表示衷心的感谢。您的支持是无价的，我们迫不及待地想再次一起庆祝。'
    },
    {
        templateId: 'event-sms-thank_you-zh-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'zh',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: '亲爱的 [GUEST_NAME]，衷心感谢您的参与和支持。我们真心希望这次活动能够为您带来满满的收获和回忆。希望未来能有更多的机会与您再次相聚。'
    },
    {
        templateId: 'event-sms-thank_you-zh-tw-1',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '1',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: true,
        msg: '感謝您出席我們的活動，您的參與使這個特別的時刻更加難忘！我們深感榮幸能與您分享這段珍貴的時光。'
    },
    {
        templateId: 'event-sms-thank_you-zh-tw-2',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '2',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: true,
        msg: '感謝您蒞臨我們的活動！我們想向每一位與會者表達衷心的感謝和誠摯的祝福。期待與您在下次活動中再次相見！'
    },
    {
        templateId: 'event-sms-thank_you-zh-tw-3',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '3',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: false,
        url: false,
        eventMode: true,
        msg: '我們對您出席我們的活動表示衷心的感謝。您的支持是無價的，我們迫不及待地想再次一起慶祝。'
    },
    {
        templateId: 'event-sms-thank_you-zh-tw-4',
        enable: true,
        blastType: 'sms',
        templateTitle: '[WEDDING_TITLE]',
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '4',
        custom: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        dynamicVariable: true,
        url: false,
        eventMode: true,
        msg: '親愛的 [GUEST_NAME]，衷心感謝您的參與和支持。我們真心希望這次活動能夠為您帶來滿滿的收穫和回憶。希望未來能有更多的機會與您再次相聚。'
    }
];
