import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { GroupService } from 'src/app/services/group/group.service';
import { GuestService } from 'src/app/services/guest/guest.service';

import { Seating } from 'src/app/interfaces/seating';
import { GiftSummary, Gift } from 'src/app/interfaces/gift';
import { Guest } from 'src/app/interfaces/guest';
import { SeatingSummary } from 'src/app/interfaces/seating';

/**
 * Seating summary service
 */
@Injectable({
  providedIn: 'root'
})
export class SeatingSummaryService implements OnInit, OnDestroy {

  /**
   * Constructor
   */
  constructor(
    private moduleService: ModuleService,
    private guestService: GuestService,
    private groupService: GroupService,
    private giftService: GiftService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Get seating summary
   * @param seating Seating
   * @returns seating summary
   */
  getSeatingSummary(seating: Seating, showGiftSummary?: boolean): SeatingSummary {
    const giftSummary: GiftSummary = {
      other: 0,
      cash: 0,
      amount: []
    };

    const guestList = this.guestService.getSeatingGuest(seating?.name);
    if (guestList?.length) {
      const invitedBy = (guestList.map(guest => guest?.invitedBy?.[0]))?.reduce((accumalator, current) => {
        if (!accumalator.some((item: any) => this.functionService.isEqual(item, current))) {
          accumalator.push(current);
        }
        return accumalator;
      }, []);

      const category = (guestList.map(guest => guest?.category?.[0]))?.reduce((accumalator, current) => {
        if (!accumalator.some((item: any) => this.functionService.isEqual(item, current))) {
          accumalator.push(current);
        }
        return accumalator;
      }, []);

      const dietaryReq = (guestList.map(guest => guest?.dietaryReq?.[0]))?.reduce((accumalator: any, current: any) => {
        if (current?.value !== 'none') {
          const index = accumalator?.findIndex(x => this.functionService.isEqual(x?.value, current?.value));
          if (index === -1) {
            current.count = 1;
            accumalator.push(current);
          } else {
            accumalator[index].count += 1;
          }
        }
        return accumalator;
      }, []);

      const specialReq = (guestList.map(guest => guest?.specialReq?.[0]))?.reduce((accumalator: any, current: any) => {
        if (current?.value !== 'none') {
          const index = accumalator?.findIndex(x => this.functionService.isEqual(x?.value, current?.value));
          if (index === -1) {
            current.count = 1;
            accumalator.push(current);
          } else {
            accumalator[index].count += 1;
          }
        }
        return accumalator;
      }, []);

      if (this.moduleService.currentModule === 'gift') {
        let giftIdList: string[] = [];
        let giftList: Gift[] = [];
        guestList?.forEach((guest: Guest) => {
          if (guest?.giftList?.length) {
            giftIdList = [...new Set([... giftIdList, ...guest.giftList])];
          }
          if (guest.groupId) {
            const group = this.groupService.getGroup(guest.groupId);
            if (group?.giftList?.length) {
              giftIdList = [...new Set([...giftIdList, ...group.giftList])];
            }
          }
        });

        giftList = this.giftService.getGiftListById(giftIdList);

        giftList?.forEach((gift: Gift) => {
          if (gift.giftType === 'other') {
            giftSummary.other++;
          } else if (gift.giftType === 'cash') {
            giftSummary.cash++;
            if (gift.currency) {
              const index = giftSummary.amount?.findIndex((x) => x?.currency === gift?.currency);
              if (index === -1) {
                giftSummary.amount.push({
                  currency: gift.currency,
                  amount: gift.amount,
                  count: 1
                });
              } else {
                giftSummary.amount[index].amount += gift.amount;
                giftSummary.amount[index].count++;
              }
            }
          }
        });
      }

      return { guestList, invitedBy, category, dietaryReq, specialReq, giftSummary };
    }
    return { guestList, invitedBy: [], category: [], dietaryReq: [], specialReq: [], giftSummary };
  }
}
