import { FilterValueComponent } from './filter-value/filter-value.component';
import { SearchComponent } from './search/search.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
      SearchComponent,
      FilterValueComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        TranslateModule
    ],
    exports: [
        SearchComponent
    ],
    // entryComponents: [
    //     FilterValueComponent
    // ]
})
export class SearchComponentsModule { }
