<ion-content>
  <div class="full-height vertical-center">
    <ion-card class="center">
      <ion-card-header class="ion-no-padding">
        <ion-item class="close-item" lines="none">
          <ion-icon class="font-24 vertical-middle" slot="end" color="dark" src="./assets/wedding/icon/general/close.svg" (click)="dismissModal()"></ion-icon>
        </ion-item>
      </ion-card-header>
      <ion-card-content>
        <div class="ion-text-center">
          <ion-text class="font-16">{{ 'BLAST.template.type' | translate }}</ion-text>
        </div>
        <ion-item class="prompt-input" [color]="checkSelected(templateType) ? 'tertiary' : 'light'" *ngFor="let templateType of templateTypeList" (click)="select(templateType)" lines="none">
          <ion-text>
            <ng-container *ngIf="!templateType?.custom">{{ 'LIST.blast_type.' + templateType.value | translate }}</ng-container>
            <ng-container *ngIf="templateType?.custom">{{ templateType.value }}</ng-container>
          </ion-text>
          <ion-note slot="end" *ngIf="checkSelected(templateType)">
            <ion-icon class="font-24 vertical-middle" color="success" src="./assets/wedding/icon/general/checkmark-circle.svg"></ion-icon>
          </ion-note>
        </ion-item>

        <!-- <div *ngIf="blastType === 'sms'">
          <ion-text class="font-12 font-underline" (click)="dismissModal(null, true)">
            {{ 'CRUD.new_field' | translate: { field: 'BLAST.template.type' | translate } }}
          </ion-text>
        </div> -->
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>