import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { SettingFieldService } from 'src/app/services/setting/setting-field.service';

import { SettingField } from 'src/app/interfaces/database';
import { SettingFieldType } from 'src/app/types/general';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';

/**
 * Group change component
 */
@Component({
  selector: 'app-group-change-value',
  templateUrl: './group-change-value.component.html',
  styleUrls: ['./group-change-value.component.scss'],
})
export class GroupChangeValueComponent implements OnInit, OnDestroy {

  /**
   * New data
   */
  @Input() newData: any;

  seatingTypeName: string;

  /**
   * Constructor
   * @param settingFieldService setting field service
   */
  constructor(
    private settingFieldService: SettingFieldService,
    private seatingSettingService: SeatingSettingService,
  ) { }

  ngOnInit(): void {
    this.seatingTypeName = this.getSeatingTypeName();
  }

  ngOnDestroy(): void {
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[]): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList);
  }

  getSeatingTypeName(): string {
    return this.seatingSettingService.getSeatingTypeName();
  }

}
