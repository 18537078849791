import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProviderService } from 'src/app/services/user/provider.service';
import { Provider } from 'src/app/interfaces/user';

/**
 * Avatar list component
 */
@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit, OnDestroy {

  /**
   * Avatar List
   */
  avatarList = [
    './assets/wedding/img/avatar/groom.svg',
    './assets/wedding/img/avatar/bestman.svg',
    './assets/wedding/img/avatar/leader.svg',
    './assets/wedding/img/avatar/entrepreneur.svg',
    './assets/wedding/img/avatar/broker.svg',
    './assets/wedding/img/avatar/worker.svg',
    './assets/wedding/img/avatar/man-2.svg',
    './assets/wedding/img/avatar/musician.svg',
    './assets/wedding/img/avatar/professor.svg',
    './assets/wedding/img/avatar/bride.svg',
    './assets/wedding/img/avatar/bride-2.svg',
    './assets/wedding/img/avatar/bridesmaids.svg',
    './assets/wedding/img/avatar/woman.svg',
    './assets/wedding/img/avatar/gynecologist.svg',
    './assets/wedding/img/avatar/professor.svg',
    './assets/wedding/img/avatar/saleswoman.svg',
    './assets/wedding/img/avatar/bearded-woman.svg',
    './assets/wedding/img/avatar/woman-3.svg',
    './assets/wedding/img/avatar/queen.svg'
  ];

  /**
   * Constructor
   * modal controller
   */
  constructor(
    private modalController: ModalController,
    private providerService: ProviderService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Before view enter, setup avatar list
   */
  async ionViewWillEnter() {
    this.getProviderPhoto();
  }

  /**
   * Get provider photo into avatar list
   */
  getProviderPhoto() {
    const providerList: Provider[] = this.providerService?.providerList;
    if (providerList?.length) {
      providerList?.forEach((provider: Provider) => {
        if (provider?.photoURL?.indexOf('http') === 0) {
          const index = this.avatarList?.indexOf(provider.photoURL);
          if (index === -1) {
            this.avatarList.push(provider.photoURL);
          }
        }
      });
    }
    this.setupAvatarList();
  }

  /**
   * Setup avatar list
   */
  setupAvatarList() {
    const blankUrl = './assets/wedding/img/avatar/blank.svg';
    const index = this.avatarList?.indexOf(blankUrl);
    if (index === -1) {
      this.avatarList.push(blankUrl);
    }

    const spaceCount = 12 - (this.avatarList.length % 12);
    if (this.avatarList?.length && spaceCount) {
      for (let i = 0; i < spaceCount; i++) {
        this.avatarList.push('');
      }
    }
  }

  /**
   * Dismiss Avatar Modal
   * @param img Avatar image url
   */
  async dismissModal(avatar?: string) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) {
        if (avatar) {
          if (avatar?.indexOf('./assets/wedding/img/avatar/') === 0) {
            modal.dismiss({ img: avatar });
          } else {
            modal.dismiss({ provider: avatar });
          }
        } else {
          modal.dismiss();
        }
      }
    }
  }

}
