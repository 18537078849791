import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Language } from 'src/app/interfaces/database';

import { PageSetting } from 'src/app/interfaces/visitor';
import { AccountAupService } from '../account/account-aup.service';
import { ErrorService } from '../general/error.service';
import { FunctionService } from '../general/function.service';
import { UpdateByService } from '../user/update-by.service';

@Injectable({
  providedIn: 'root'
})
export class VisitorManageService implements OnInit, OnDestroy {

  pageInfo: any;
  pageSetting: PageSetting;
  pageLangList: Language[];
  language: string;
  themeId: string;
  
  private accountId: string;

  constructor(
    private afs: AngularFirestore,
    private accountAupService: AccountAupService,
    private updateByService: UpdateByService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) { }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  setupAccountId(accountId: string) {
    this.accountId = accountId;
  }

  getDiff(data: any) {
    const result: any = {};
    if (data) {
      if (data?.info && !this.functionService.isEqual(data.info, this.pageInfo)) {
        result.info = data.info;
      } else if (data?.setting && !this.functionService.isEqual(data.setting, this.pageSetting)) {
        result.setting = data.setting;
      } else if (data?.languageList && !this.functionService.isEqual(data.languageList, this.pageLangList)) {
        result.languageList = data.languageList;
      } else if (data?.themeId && data.themeId !== this.themeId) {
        result.themeId = data.themeId;
      }
    }
    return result;
  }

  async savePageSetting(data: any) {
    if (this.accountId) {
      if (data && !this.functionService.isEmpty(data) && !this.accountAupService.isExceed()) {
        data.updateBy = this.updateByService.updateBy;
        const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/page/`);
        accountsRef.set(data, { merge: true }).then(result => {
        }).catch((err: any) => {
          this.errorService.logError(err);
        });
      }
    }
  }
}
