import { GuestExportComponent } from 'src/app/components/guest/guest-export/guest-export.component';
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController, ActionSheetController, IonContent, IonList } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestListService } from 'src/app/services/guest/guest-list.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { GuestDeleteService } from 'src/app/services/guest/guest-delete.service';
import { CheckinService } from 'src/app/services/checkin/checkin.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { LocalityService } from 'src/app/services/location/locality.service';
import { LanguageService } from 'src/app/services/general/language.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { TabService } from 'src/app/services/general/tab.service';

import { GuestNewComponent } from 'src/app/components/guest/guest-new/guest-new.component';
import { GuestEditComponent } from 'src/app/components/guest/guest-edit/guest-edit.component';
import { SeatingListComponent } from 'src/app/components/seating/seating-list/seating-list.component';

import { Guest } from 'src/app/interfaces/guest';
import { Group } from 'src/app/interfaces/group';
import { Language, Country } from 'src/app/interfaces/database';

import { GuestFilterType, GuestListMode, GuestSortType } from 'src/app/types/guest';
import { ModuleType, SmartSearchType } from 'src/app/types/general';
import { DeviceSafeAreaService } from 'src/app/services/device/device-safe-area.service';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { BlastStatusService } from 'src/app/services/blast/blast-status.service';
import { CurrentPrivilege } from 'src/app/interfaces/privilege';
import { BlastTemplate } from 'src/app/interfaces/blast';

/**
 * Guest list component
 */
@Component({
  selector: 'app-guest-list-component',
  templateUrl: './guest-list.component.html',
  styleUrls: ['./guest-list.component.scss'],
})
export class GuestListComponent implements OnInit, OnDestroy {
  /**
   * Watch screen resize change
   */
  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.resizeLimit();
    this.setupContentHeight();
  }
  /**
   * Watch screen orientation change
   */
  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event: any) {
    this.resizeLimit();
    this.setupContentHeight();
  }

  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  /**
   * Header for getting header height
   */
  @ViewChild('header', { read: ElementRef, static: false }) header: ElementRef;
  /**
   * Footer for getting footer height
   */
  @ViewChild('footer', { read: ElementRef, static: false }) footer: ElementRef;
  /**
   * Content for scroll to top / bottom
   */

  @ViewChild('content', { read: ElementRef }) contentElement: ElementRef;
  /**
   * Ion list for closing opened item
   */
  @ViewChild('list') list: IonList;

  @Input() blastUsageId: string;
  /**
   * Guest list title
   */
  @Input() title: string;
  /**
   * Page mode flag
   */
  @Input() pageMode: boolean;
  /**
   * Select mode flag
   */
  @Input() selectMode: boolean;
  /**
   * Guest list mode
   */
  @Input() mode: GuestListMode;
  /**
   * Default filter
   */
  @Input() defaultFilter: any;
  /**
   * Exclude guest id list
   */
  @Input() excludeGuestIdList: string[];
  /**
   * Exclude group id list
   */
  @Input() excludeGroupIdList: string[];
  /**
   * Reset component to initial state.
   * Call per page loaded.
   */
  @Input() set reset(flag: boolean) {
    this.resetSearch = flag;
    this.searchTerm = '';
    this.filter = null;

    if (flag) {  
      this.initialize();
    } else {
      this.setupLimit();
      this.unwatch();
    }
  }
  /**
   * Set type of guest list
   */
  @Input() set setType(value: GuestFilterType) {
    if (value) {
      this.type = value;
      this.setupGuestListMode();
    }
  }

  @Input() blastTemplate: BlastTemplate;

  limit: number;

  language: string;

  /**
   * Guest group
   * Param from guest group modal.
   */
  group: Group;
  /**
   * AccountModule
   */
  module: ModuleType;
  currentModule: ModuleType;
  /**
   * Edit mode
   */
  editMode: boolean;
  /**
   * Guest list type
   */
  type: GuestFilterType;
  /**
   * Count of guest list
   */
  count: number;
  /**
   * Guest list
   */
  guestList: Guest[];

  private previewGuestList: Guest[];
  /**
   * Selected guest list
   */
  selectedGuestList: string[];
  /**
   * Selected group list
   */
  selectedGroupList: string[];
  /**
   * Select all
   */
  selectAll: boolean;
  /**
   * Reset search
   */
  resetSearch: boolean;
  /**
   * Smart search type
   */
  smartSearchType: SmartSearchType = 'english';
  /**
   * Search term
   */
  searchTerm: string;
  /**
   * Filter
   */
  filter: any;
  /**
   * Sorting field
   */
  sorting: GuestSortType;
  /**
   * Preview guest seating
   */
  showGuestSeating: boolean;

  hideFilterField: any;

  selectAllEditMode: boolean;
  contentHeight: string;

  demoGuest: {
    [key: string]: Guest;
  };

  currentPrivilege: CurrentPrivilege;
  /**
   * Sort descending flag
   */
  private desc: boolean;
  /**
   * Guest list subscription
   */
  private guestListSubscription: Subscription;

  private moduleSubscription: Subscription;

  private privilegeSubscription: Subscription;

  /**
   * Constructor
   * @param platform platform
   * @param modalController modal controller
   * @param translate translate service
   * @param checkinService checkin service
   * @param guestListService guest list service
   * @param guestDeleteService guest delete service
   * @param tabService tab service
   * @param popupService popup service
   * @param functionService function service
   */
  constructor(
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private translate: TranslateService,
    private deviceSafeAreaService: DeviceSafeAreaService,
    private checkinService: CheckinService,
    private guestService: GuestService,
    private guestListService: GuestListService,
    private guestManageService: GuestManageService,
    private guestDeleteService: GuestDeleteService,
    private seatingSettingService: SeatingSettingService,
    private blastStatusService: BlastStatusService,
    private moduleService: ModuleService,
    private privilegeService: PrivilegeService,
    private localityService: LocalityService,
    private languageService: LanguageService,
    private tabService: TabService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) {
    this.guestList = [];
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unwatch();
    this.setupLimit();
  }

  /**
   * Before view enter
   */
  ionViewWillEnter() {
    this.setupGuestListMode();
    this.initialize();
  }

  ionViewDidEnter() {}

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatch();
    this.setupLimit();
  }

  ionViewDidLeave() {
  }

  setupDemoGuest() {
    this.demoGuest = {
      'english': this.getDemoGuest('english'),
      'malay': this.getDemoGuest('malay'),
      'pinyin': this.getDemoGuest('pinyin'),
    };
  }

  setupLimit() {
    this.limit = this.getLimit();
  }

  /**
   * Watch guest list
   */
  async watchGuestList() {
    if (!this.guestListSubscription) {
      if (this.module === 'trash') {
        this.guestListSubscription = this.guestService.observableTrashGuestList.subscribe(() => {
          this.setupGuestList();
        });
      } else {
        this.guestListSubscription = this.guestService.observableGuestList.subscribe((guestList: Guest[]) => {
          this.setupGuestList();
        });
      }
    }
  }

  /**
   * Unwatch guest list
   */
  async unwatchGuestList() {
    if (this.guestListSubscription) {
      this.guestListSubscription.unsubscribe();
      this.guestListSubscription = null;
    }
  }

  async watchModule() {
    if (!this.moduleSubscription) {
      this.moduleSubscription = this.moduleService.observableCurrentModule.subscribe((module: ModuleType) => {
        if (this.module !== module) {
          this.module = module;
          this.setupPrivilege();
          this.setupSelectAllEditMode();
        }
      });
    }
    
  }

  /**
   * Unwatch module
   */
  async unwatchModule() {
    if (this.moduleSubscription) {
      this.moduleSubscription.unsubscribe();
      this.moduleSubscription = null;
    }
  }

  async watchPrivilege() {
    if (!this.privilegeSubscription) {
      this.privilegeSubscription = this.privilegeService.observableCurrentPrivilege.subscribe(() => {
        this.setupPrivilege();
      })
    }
  }

  async unwatchPrivilege() {
    if (this.privilegeSubscription) {
      this.privilegeSubscription.unsubscribe();
      this.privilegeSubscription = null;
    }
  }

  setupPrivilege() {
    if (!this.currentPrivilege) {
      this.currentPrivilege = {};
    }
    if (this.module) {
      this.currentPrivilege = {
        [this.module]: {
          'new': this.checkPrivilege(this.module, 'new'),
        }
      };
    }
  }

  unwatch() {
    this.unwatchGuestList();
    this.unwatchModule();
    this.unwatchPrivilege();
    this.guestList = [];
  }

  /**
   * Initialize guest list variable.
   * Start watch guest list and check header / footer height.
   */
  initialize() {
    
    this.language = this.languageService.getUserLanguage()?.code;
    this.watchModule();
    this.watchPrivilege();
    // if (!this.module) {
    //   this.module = this.moduleService.currentModule;
    // }
    // this.currentModule = this.moduleService.currentModule;
    // this.editMode = false;
    if (!this.selectMode) {
      this.selectedGuestList = [];
      this.selectedGroupList = [];
    }
    if (!this.selectedGuestList) { this.selectedGuestList = []; }
    if (!this.selectedGroupList) { this.selectedGroupList = []; }

    // this.selectAll = false;

    const language: Language = this.languageService.getAccountLanguage();
    const country: Country = this.localityService.getAccountCountry();
    // if (country?.code === 'hk') {
    //   this.smartSearchType = 'cangjie';
    // } else
    if (country?.code === 'tw') {
      this.smartSearchType = 'zhuyin';
    } else if (language?.code === 'en') {
      this.smartSearchType = 'english';
    } else if (language?.code === 'zh') {
      this.smartSearchType = 'pinyin';
    } else if (language?.code === 'zh-TW') {
      this.smartSearchType = 'pinyin';
    }
    
    if (!this.guestList?.length) {
      this.watchGuestList();
    } else {
      this.setupViewport();
    }
    
    this.setupDemoGuest();
    this.setupContentHeight();

    setTimeout(() => {
      this.setupLimit();
      this.scrollToTop();
    }, 200);
    
  }

  getScreenHeight() {
    return '100vh';
    // if (window?.innerHeight) {
    //   return window.innerHeight.toString() + 'px';
    // } else {
    //   return '100vh';
    // }
  }

  /**
   * Get header height
   */
  getHeaderHeight() {
    let header = 0;
    if (this.header?.nativeElement?.offsetHeight) {
      header = this.header.nativeElement.offsetHeight;
      if (this.pageMode) { 
        if (this.tabService.showTab) {
          header += this.deviceSafeAreaService.getSafeArea();
        }
      }
    }
    
    return header;
  }

  /**
   * Get footer height
   */
  getFooterHeight() {
    let footer = 0;
    if (this.pageMode) {
      if (this.editMode) {
        footer += this.footer?.nativeElement?.offsetHeight ? this.footer.nativeElement.offsetHeight : 106;
      }

      if (this.tabService.showTab) {
        footer += 50;
      }
    }
    return footer;
  }

  setupFooter() {
    if (this.pageMode) {
      this.tabService.updateShowTab(!this.editMode);
      this.setupContentHeight();
    }
  }

  /**
   * Setup guest list mode based on type
   */
  setupGuestListMode() {
    if (this.type?.qrcode) {
      this.mode = 'qrcode';
    } else if (this.type?.attending) {
      this.mode = 'attending';
    } else if (this.type?.seating) {
      this.mode = 'seating';
    } else if (this.type?.group) {
      this.mode = 'group';
    } else if (this.type?.checkin) {
      this.mode = 'checkin';
    } else if (this.type?.mobile) {
      if (!this.mode) {
        this.mode = 'sms';
      }
    }
  }

  /**
   * Search guest
   * @param data data for search, filter, sorting
   */
  search(data: any) {
    this.searchTerm = data?.keyword;
    this.filter = data?.filter?.enable ? data.filter : null;
    this.sorting = data?.sorting;
    this.desc = data?.desc ? true : false;
    this.setupGuestList();
  }

  /**
   * Check if default filter applied
   */
  checkDefaultFilter() {
    if (this.defaultFilter?.enable) {
      if (!this.filter) { this.filter = {}; }
      Object.keys(this.defaultFilter)?.forEach(key => {
        if (!this.defaultFilter[key]) {
          this.defaultFilter[key] = null;
        } else {
          this.filter[key] = this.defaultFilter[key];
        }
      });
    }
    this.hideFilterField = this.getHideFilterField();
  }

  checkEditMode() {
    if (this.module === 'blast') {
      if (this.type?.mobile === 'with') {
        return true;
      }
    } else {
      if (this.editMode || this.selectMode) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get hide filter field
   * @returns hide filter field
   */
  getHideFilterField() {
    const hideFilterField: any = {};
    if (this.defaultFilter?.enable) {
      Object.keys(this.defaultFilter)?.forEach(key => {
        if (this.defaultFilter[key]) {
          hideFilterField[key] = true;
        }
      });
    }
    return hideFilterField;
  }

  getDemoGuest(smartSearchType: SmartSearchType): Guest {
    if (smartSearchType === 'english' || smartSearchType === 'malay') {
      return {
        guestId: 'test',
        name: 'Natasha Romanoff',
        nickname: 'Black Widow',
        invitedBy: [{
          custom: false,
          value: 'groom'
        }],
        category: [{
          custom: true,
          value: 'Avengers'
        }],
        groupId: '',
        seating: '',
        email: '',
        mobile: {
          no: '',
          country: '',
          code: 0
        },
        dietaryReq: [],
        specialReq: [],
        giftList: [],
        remark: '',
        status: {
          attending: 'attending'
        }
      };
    } else if (smartSearchType === 'pinyin') {
      return {
        guestId: 'test',
        name: this.translate.instant('DEMO.pinyin.name'),
        nickname: this.translate.instant('DEMO.pinyin.nickname'),
        invitedBy: [{
          custom: false,
          value: 'groom'
        }],
        category: [{
          custom: true,
          value: this.translate.instant('DEMO.vip')
        }],
        groupId: '',
        seating: '',
        email: '',
        mobile: {
          no: '',
          country: '',
          code: 0,
        },
        dietaryReq: [],
        specialReq: [],
        giftList: [],
        remark: '',
        status: {
          attending: 'attending'
        }
      };
    }
  }

  getSeatingTypeName(): string {
    return this.seatingSettingService.getSeatingTypeName();
  }

  /**
   * Setup guest list and group guest into same object.
   * Apply default filter for specified guest list.
   */
  setupGuestList() {
    if (this.mode === 'sms-result' || this.mode === 'whatsapp-result') {
      if (this.type?.usageId && this.type?.blastDeliveredType && this.type.blastDeliveredType !== 'all') {
        this.guestList = this.functionService.cloneDeep(this.previewGuestList).filter((guest: Guest) => {
          if (guest?.group?.groupId) {
            const result = this.blastStatusService.checkGuestDeliveredStatusByUsageId(this.type.usageId, '', guest.group.groupId, this.type.blastDeliveredType);
            if (guest?.group?.memberList?.length) {
              guest.group.memberList = guest.group.memberList.filter((memberId: string) => {
                return this.blastStatusService.checkGuestDeliveredStatusByUsageId(this.type.usageId, memberId, guest.group.groupId, this.type.blastDeliveredType);
              });
            }
            if (guest?.group?.memberList?.length || result) {
              return true;
            }
            return false;
          }  else {
            return this.blastStatusService.checkGuestDeliveredStatusByUsageId(this.type.usageId, guest?.guestId, '', this.type.blastDeliveredType);
          }
          
        });
      } else {
        this.guestList = [ ...this.previewGuestList ];
      }

      this.count = this.guestList.reduce((a: number, b: Guest) => {
        if (b?.group?.groupId) {
          return a + b.group.memberList.length;
        } else {
          return a + 1;
        }
      }, 0);
    } else if (this.mode !== 'preview') {
      this.checkDefaultFilter();
      if (this.module === 'guest' || this.module === 'trash' || this.module === 'blast' || this.module === 'rsvp'
      || this.searchTerm || this.filter || this.type || (this.mode && this.mode !== 'list' && this.mode !== 'checkin')) {
        const result = this.guestListService.searchGuestList(
          this.searchTerm,
          this.type,
          this.filter,
          this.excludeGuestIdList,
          this.excludeGroupIdList,
          this.sorting,
          this.desc,
          this.module,
          this.mode,
        );

        const guestList = result.list;
        const count = result.count;
        this.guestList = guestList;

        this.count = count;
      } else {
        this.guestList = [];
        this.count = 0;
      }
    }
    this.setupViewport();
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport) {
      this.cdkVirtualScrollViewport?.checkViewportSize();
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  async setupContentHeight() {
    if (this.pageMode) {
      if (this.getHeaderHeight()) {
        await this.functionService.delay(200);
        this.contentHeight = 'calc(100vh - ' + (this.getHeaderHeight() + this.getFooterHeight()) + 'px)';
      } else {
        await this.functionService.delay(100);
        this.setupContentHeight();
      }
    } else {
      this.contentHeight = '100%';
    }
  }

  // getDistinctDatesFromTimestamps() {
  //   const distinctDates = new Set();
  
  //   this.guestList.forEach(obj => {
  //     // Assuming the timestamp field is named `createdAt`
  //     const timestamp = obj.createBy?.time?.seconds * 1000;
  
  //     if (timestamp) {
  //       console.log(timestamp);
  //       const date = new Date(timestamp); // Convert numeric timestamp to JS Date
  //       console.log(date);
  //       const formattedDate = date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
  //       console.log(formattedDate);
  //       distinctDates.add(formattedDate); // Add formatted date to the set
  //     }
  //   });
  
  //   return Array.from(distinctDates); // Convert set to array
  // }

  setupSelectAllEditMode() {
    this.selectAllEditMode = this.checkEditMode();
  }

  /**
   * Toggle edit mode
   */
  toggleEditMode(editMode?: boolean) {
    if (this.mode !== 'preview') {
      if (this.functionService.isUndefined(editMode)) {
        this.editMode = !this.editMode;
      } else {
        this.editMode = editMode;
      }
      this.resetSelectAll();
      this.setupFooter();
      this.setupSelectAllEditMode();
      this.closeSliding();
    }
  }

  /**
   * Reset select all
   */
  resetSelectAll() {
    this.selectAll = false;
    this.selectedGuestList = [];
    this.selectedGroupList = [];
  }

  /**
   * Toggle select all
   */
  toggleSelectAll() {
    this.selectAll = !this.selectAll;

    this.selectedGuestList = [];
    this.selectedGroupList = [];

    if (this.selectAll) {
      this.guestList?.forEach((guest: Guest) => {
        if (guest?.group?.groupId) {
          this.setSelectedGroup(guest.group.groupId, true);
        } else {
          this.selectedGuestList.push(guest.guestId);
        }
      });
    }
  }

  /**
   * Check select all
   */
  checkSelectAll() {
    if (this.count && this.selectedGuestList?.length === this.count) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  }

  /**
   * Set selected group
   * @param groupId group id
   * @param selected selected flag
   * @param skipCheck skip check
   */
  setSelectedGroup(groupId: string, selected?: boolean, skipCheck?: boolean) {
    const index = this.selectedGroupList?.indexOf(groupId);
    if (index === -1) {
      if (this.functionService.isUndefined(selected) || selected === true) {
        this.selectedGroupList.push(groupId);
        if (!skipCheck) {
          this.checkSelectedGroup(groupId, true, this.functionService.isUndefined(selected) ? false : true);
        }
      }
    } else {
      if (this.functionService.isUndefined(selected) || selected === false) {
        if (this.selectedGroupList?.length) {
          this.selectedGroupList?.splice(index, 1);
        }
        if (!skipCheck) {
          this.checkSelectedGroup(groupId, false, this.functionService.isUndefined(selected) ? false : true);
        }
      }
    }
  }

  /**
   * Check selected group
   * @param groupId group id
   * @param selected selected flag
   * @param skipCheck skip check flag
   */
  checkSelectedGroup(groupId: string, selected?: boolean, skipCheck?: boolean) {
    const index = this.guestList?.findIndex((x) => x?.group?.groupId === groupId);
    let memberList: string[] = [];
    if (index !== -1 && this.guestList?.[index]?.group?.memberList?.length) {
      memberList = this.guestList[index].group.memberList;
    }

    if (memberList?.length) {
      let flag = true;
      memberList?.forEach((guestId: string) => {
        const selectedIndex = this.selectedGuestList?.indexOf(guestId);
        if (selectedIndex === -1) {
          flag = false;
          if (!this.functionService.isUndefined(selected) && selected === true) {
            this.selectedGuestList.push(guestId);
          }
        } else {
          if (!this.functionService.isUndefined(selected) && selected === false) {
            this.selectedGuestList?.splice(selectedIndex, 1);
          }
        }
      });
      if (this.functionService.isUndefined(selected)) {
        this.setSelectedGroup(groupId, flag, true);
      }
    }

    if (!skipCheck) {
      this.checkSelectAll();
    }
  }

  /**
   * Set selected guest
   * @param guestId guest id
   */
  setSelectedGuest(guestId: string) {
    const index = this.selectedGuestList?.indexOf(guestId);
    if (index === -1) {
      this.selectedGuestList.push(guestId);
    } else {
      this.selectedGuestList?.splice(index, 1);
    }
    this.checkSelectAll();
  }

  /**
   * Bulk action
   * @param type bulk action type
   */
  async bulkAction(type: string) {
    if (type) {
      if (type === 'add') {
        await this.presentGuestNewModal();
        this.toggleEditMode(false);
      } else {
        this.checkSingleRecord();
        if (type === 'delete') {
          if (this.module === 'trash') {
            this.promptDelete();
          } else {
            this.promptRemove();
          }
        } else if (type === 'info') {
          await this.presentGuestEditModal();
          this.toggleEditMode(false);
        } else if (type === 'assign') {
          // await this.presentSeatingListModal();
          await this.presentSeatingActionSheet();
        } else if (type === 'restore') {
          this.promptRestore();
        } else if (type === 'checkin') {
          this.promptCheckin();
        } else if (type === 'uncheck') {
          this.promptUncheck();
        }
      }
    }
  }

  /**
   * Check if single record then trigger select all
   */
  checkSingleRecord() {
    if (!this.selectAll && this.count === 1) {
      this.toggleSelectAll();
    }
  }

  /**
   * Select guest / group list
   */
  select() {
    if (this.selectedGroupList?.length || this.selectedGuestList?.length) {
      this.dismissModal(this.selectedGuestList, this.selectedGroupList);
    } else {
      this.popupService.presentAlert(
        this.translate.instant('VALIDATION.select_field',
        { field: this.translate.instant('GUEST.lbl.guest') })
      );
    }
  }

  async presentSeatingActionSheet() {
    await this.popupService.presentLoading();
    const header = this.getSeatingTypeName();
    let unassign = false;
    const buttons = [];
    buttons.push({
      text: this.translate.instant('SEATING.btn.assign', { seating: this.getSeatingTypeName() }),
      handler: async () => {
        this.presentSeatingListModal();
      }
    });
    if (this.selectedGuestList?.length) {
      const selectedGuestList = this.guestService.getGuestListById(this.selectedGuestList);
      selectedGuestList?.forEach((guest: Guest) => {
        if (!unassign && guest?.seating) {
          unassign = true;
        }
      });
      if (unassign) {
        buttons.push({
          text: this.translate.instant('SEATING.btn.unassign', { seating: this.getSeatingTypeName() }),
          handler: () => {
            this.promptUnassign();
          }
        });
      }
      this.popupService.dismissLoading();
    }

    if (header && buttons?.length) {
      buttons.push({
        text: this.translate.instant('BTN.cancel'),
        icon: 'close',
        role: 'cancel',
        handler: () => {
          this.toggleEditMode(false);
        }
      });
      const actionSheet = await this.actionSheetController.create({
        header,
        buttons
      });
      actionSheet.present();
    } else {
      this.popupService.dismissLoading();
      this.toggleEditMode(false);
    }
  }

  /**
   * Presesnt guest edit modal.
   * Trigger bulk edit mode if selected guest list > 1
   */
  async presentGuestEditModal() {
    if (this.checkPrivilege(this.module, 'edit')) {
      if (this.selectedGuestList?.length) {
        let guest: Guest;
        let bulkEditMode = true;
        if (this.selectedGuestList?.length === 1) {
          bulkEditMode = false;
          guest = this.guestService.getGuest(this.selectedGuestList[0]);
        }
        const modal = await this.modalController.create({
          component: GuestEditComponent,
          cssClass: '',
          componentProps: {
            bulkEditMode,
            guest,
            selectedGuestList: bulkEditMode ? this.selectedGuestList : []
          }
        });
        modal.present();
      }
    } else {
      this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
    }
  }

  /**
   * Present guest new modal
   */
  async presentGuestNewModal() {
    const defaultValue: any = {};
    if (this.group?.memberList?.length) {
      const groupAdmin = this.guestService.getGuest(this.group.memberList[0]);
      if (groupAdmin) {
        if (groupAdmin?.invitedBy?.length) {
          defaultValue.invitedBy = [ groupAdmin.invitedBy[0] ];
        }
        if (groupAdmin?.category?.length) {
          defaultValue.category = [ groupAdmin.category[0] ];
        }
        if (groupAdmin?.dietaryReq?.length) {
          defaultValue.dietaryReq = [ groupAdmin?.dietaryReq?.[0] ];
        }
      }
    }

    const modal = await this.modalController.create({
      component: GuestNewComponent,
      cssClass: '',
      componentProps: {
        skipResult: this.mode === 'rsvp-invite' ? false : true,
        setupGroup: this.group,
        defaultValue,
        mode: this.mode,
      }
    });
    modal.present();
    modal.onDidDismiss().then((result: any) => {
      if (this.selectMode && (result?.data?.guestList?.length || result?.data?.groupList?.length)) {
        if (this.module !== 'blast' && this.mode !== 'sms' && this.mode !== 'whatsapp') {
          this.dismissModal(result?.data?.guestList, result?.data?.groupList, true);
        }
      }
    });
  }

  /**
   * Present seating list modal
   */
  async presentSeatingListModal() {
    const newGuestList: Guest[] = this.selectedGuestList.map((guestId: string) => {
      return this.guestService.getGuest(guestId);
    });
    const modal = await this.modalController.create({
      component: SeatingListComponent,
      cssClass: '',
      componentProps: {
        assignMode: true,
        newGuestList
      }
    });
    modal.present();
    this.toggleEditMode(false);
  }

  async presentGuestExportModal() {
    const modal = await this.modalController.create({
      component: GuestExportComponent
    });
    modal.present();
  }

  /**
   * Dismiss guest list modal
   * @param selectedGuestList selected guest list, applied for select mode.
   */
  async dismissModal(selectedGuestList?: string[], selectedGroupList?: string[], newGuest?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) {
        this.modalController.dismiss({ selectedGuestList, selectedGroupList, newGuest });
      }
    }
  }

  /**
   * Prompt unassign
   */
  async promptUnassign() {
    const guestList: Guest[] = this.guestService.getGuestListById(this.selectedGuestList);
    const modal = await this.popupService.presentConfirm(
      this.translate.instant('SEATING.msg.confirm_unassign_seating_count', {
        seating: this.getSeatingTypeName(),
        field: this.selectedGuestList?.length > 1 ? this.translate.instant('GUEST.lbl.guests') : this.translate.instant('GUEST.lbl.guest'),
        count: this.selectedGuestList?.length }), '', '', '', '', '', false, '', guestList
      );
    modal.onDidDismiss().then(async (result: any) => {
      if (result?.data?.confirm) {
        await this.saveUnassignSeating();
      }
      this.toggleEditMode(false);
    });
  }

  /**
   * Prompt confirm delete modal
   */
  async promptRemove() {
    if (this.editMode) {
      const guestList: Guest[] = this.guestService.getGuestListById(this.selectedGuestList);
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_field_count', {
          field: this.selectedGuestList?.length > 1
          ? this.translate.instant('GUEST.lbl.guests')
          : this.translate.instant('GUEST.lbl.guest'),
          count: this.selectedGuestList?.length }), '', '',  '', '', '', false, '', guestList
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.removeGuest();
        }
        this.toggleEditMode(false);
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Prompt confirm restore modal
   */
  async promptRestore() {
    if (this.editMode) {
      const guestList: Guest[] = this.guestService.getGuestListById(this.selectedGuestList);
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_restore_field_count', {
          field: this.selectedGuestList?.length > 1
          ? this.translate.instant('GUEST.lbl.guests')
          : this.translate.instant('GUEST.lbl.guest'),
          count: this.selectedGuestList?.length }), '', '',  '', '', '', false, '', guestList
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.saveRestoreGuest();
        }
        this.toggleEditMode(false);
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Prompt confirm restore modal
   */
  async promptDelete() {
    if (this.editMode) {
      const guestList: Guest[] = this.guestService.getGuestListById(this.selectedGuestList);
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_db_field_count', {
          field: this.selectedGuestList?.length > 1
          ? this.translate.instant('GUEST.lbl.guests')
          : this.translate.instant('GUEST.lbl.guest'),
          count: this.selectedGuestList?.length }), '', '',  '', '', '', false, '', guestList
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.deleteGuest();
        }
        this.toggleEditMode(false);
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Prompt confirm restore modal
   */
  async promptCheckin() {
    await this.checkinService.checkin('', this.selectedGuestList, null, this.selectedGroupList, null);
    this.toggleEditMode(false);
  }

  /**
   * Prompt confirm restore modal
   */
  async promptUncheck() {
    await this.checkinService.uncheck(this.selectedGuestList, [], this.selectedGroupList);
    this.toggleEditMode(false);
  }

  /**
   * Check user privilege
   * @param action Action
   */
  checkPrivilege(module: ModuleType, action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege(module, action);
  }

  /**
   * Save unassign seating
   */
  async saveUnassignSeating() {
    await this.popupService.presentLoading();
    const data = {
      seating: ''
    };
    await this.guestManageService.updateGuest(data, this.selectedGuestList);
    await this.popupService.dismissLoading();
  }

  /**
   * Save restore guest
   */
  async saveRestoreGuest() {
    if (this.editMode) {
      await this.popupService.presentLoading();
      await this.guestDeleteService.restoreGuestList(this.selectedGuestList);
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Remove guest from db
   */
  async removeGuest() {
    if (this.editMode) {
      await this.popupService.presentLoading();
      await this.guestDeleteService.removeGuestList(this.selectedGuestList);
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Save delete guest
   */
  async deleteGuest() {
    if (this.editMode) {
      await this.popupService.presentLoading();
      await this.guestDeleteService.deleteGuestList(this.selectedGuestList);
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Close opened sliding item.
   */
  closeSliding() {
    if (this.list) {
      this.list?.closeSlidingItems();
    }
  }

  /**
   * Scroll to top
   * @param time scroll animation duration
   */
  scrollToTop(time?: number) {
    // if (this.content) {
    //   this.content.scrollToTop(time ? time : 0);
    // }
    if (this.cdkVirtualScrollViewport) {
      this.cdkVirtualScrollViewport?.checkViewportSize();
      this.cdkVirtualScrollViewport?.scrollToIndex(0);
    }
  }

  /**
   * Calculate item height for virtual scroll
   * @param item Item
   */
  itemHeightFn(guest: Guest) {
    const margin = 20;
    const guestItem = 100;
    const groupItem = 80;

    if (guest?.group?.memberList?.length) {
      return (guest.group.memberList.length * guestItem) + groupItem + margin;
    }
    return guestItem + margin;
  }

  /**
   * Track item by guest id / group id or index for virtual scroll
   * @param index Index
   * @param item item
   */
  trackByFn(index: number, item: Guest) {
    if (item?.guestId) {
      return item?.guestId;
    } else if (item?.group?.groupId) {
      return item.group.groupId;
    }
    return index;
  }

  getContentHeight(): number {
    return this.contentElement?.nativeElement?.offsetHeight ? this.contentElement.nativeElement.offsetHeight : 0;
  }

  headerFn(guest: Guest, index: number) {
    const timestamp = (guest?.group?.rsvp?.updateBy?.time?.seconds ? guest?.group?.rsvp?.updateBy?.time?.seconds : guest?.rsvp?.updateBy?.time?.seconds ? guest?.rsvp?.updateBy?.time?.seconds : 0) * 1000;
    if (timestamp) {
      const date = new Date(timestamp); // Convert numeric timestamp to JS Date
      if (date) {
        const formattedDate = date?.toISOString().split('T')[0]; // Format date as YYYY-MM-DD

        if (formattedDate) {
          if (index === 0) {
            return formattedDate; // Return the group name as the header
          } else if (this.guestList?.[index - 1]?.group?.rsvp?.updateBy?.time?.seconds) {
            const timestamp2 = this.guestList[index - 1].group.rsvp.updateBy.time.seconds * 1000;
            if (timestamp2) {
              const date2 = new Date(timestamp2); // Convert numeric timestamp to JS Date
              if (date2) {
                const formattedDate2 = date2.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
                if (formattedDate2 && formattedDate !== formattedDate2) {
                  return formattedDate;
                }
              }
            }
          } else if (this.guestList?.[index - 1]?.rsvp?.updateBy?.time?.seconds) {
            const timestamp2 = this.guestList[index - 1].rsvp.updateBy.time.seconds * 1000;
            if (timestamp2) {
              const date2 = new Date(timestamp2); // Convert numeric timestamp to JS Date
              if (date2) {
                const formattedDate2 = date2.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
                if (formattedDate2 && formattedDate !== formattedDate2) {
                  return formattedDate;
                }
              }
            }
          }
        }
      }
    }
    
    
        
    
    return null; // Return null when no header should be displayed
  }

  estimateItemSize(index: number, guest: Guest): number {
    return this.itemHeightFn(guest) + 10;
    // Get the item element and measure its height
    // const itemElement = this.elementRef.nativeElement.querySelector(`.item:nth-child(${index + 1})`);
    // const itemHeight = itemElement.offsetHeight;

    // // Return the estimated item size
    // return itemHeight;
  }

  resizeLimit() {
    const limit = this.getLimit();
    if (limit > this.limit) {
      this.limit = limit;
      this.scrollToTop();
    }
  }

  getLimit() {
    const limit = Math.ceil(this.getContentHeight() / 110);
    return limit ? limit : 20;
  }

  async loadLimit(event: any) {
    await this.functionService.delay(50);
    if (this.limit >= this.guestList.length) {
      // event.target.disabled = true;
    } else {
      this.limit += this.getLimit();
    }
    event.target.complete();
    this.setupViewport();
  }


}
