import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { GuestService } from 'src/app/services/guest/guest.service';
import { Group } from 'src/app/interfaces/group';

/**
 * Check group member for action
 */
@Injectable({
  providedIn: 'root'
})
export class GroupCheckService implements OnInit, OnDestroy {

  /**
   * Constructor
   * @param guestListService guest list service
   */
  constructor(
    private guestService: GuestService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Check any group member checkin status is not equal to param checkin
   * @param selectedGuestList selected guest list
   * @param groupList group list to check
   * @param checkin true - attended, false - not_attend
   */
  checkGroupCheckin(selectedGuestList: string[], groupList: Group[], checkin: boolean): boolean {
    const result: string[] = [];
    if (selectedGuestList?.length && groupList?.length) {
      groupList = [...groupList].filter((group: Group) => {
        if (group?.memberList?.length) {
          group.memberList = [...group.memberList].filter((guestId: string) => {
            return checkin === this.guestService.getGuestCheckinStatus(guestId) ? false : true;
          });

          const memberList = [...group.memberList].filter((guestId: string) => {
            return selectedGuestList?.indexOf(guestId) !== -1 ? false : true;
          });
          if (memberList?.length) { result.push(group.groupId); }
        }
        return group?.memberList?.length ? true : false;
      });
    }
    return result?.length ? true : false;
  }

  /**
   * Check any group member is not selected in selected guest list
   * @param selectedGuestList selected guest list
   * @param groupList group list to check
   * @return true if not all group member selected
   */
  checkGroupSelected(selectedGuestList: string[], groupList: Group[]): boolean {
    return [...groupList].filter((group: Group) => {
      return [...group.memberList].filter((guestId: string) => {
        return selectedGuestList?.indexOf(guestId) !== -1 ? false : true;
      }).length ? true : false;
    }).length ? true : false;
  }

}
