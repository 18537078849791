import { CheckinSetting } from '../interfaces/setting';
import { PageSetting } from '../interfaces/visitor';

/**
 * Standard checkin setting
 */
export const StdCheckinSetting: CheckinSetting = {
    giftRecord: false,
    giftDetail: false,
    promptGift: false,
    promptCheckin: false,
    partialCheckin: true,
    scanSound: true,
    pendingGuest: true,
    notAttendingGuest: true
};

/**
 * Standard visitor page setting
 */
export const StdVisitorPageSetting: PageSetting = {
    googleMap: true,
    waze: true,
    uber: true,
    grab: true,
    apple: true,
    pax: true,
    btn: true,
    qrcode: true,
    seatingBeforeCheckin: false,
    session: false,
    groupMember: true,
    calendar: true,
    backgroundUrl: '',
    floorplan: null,
    menu: null,
    attachment: [],
    webCheckin: false,
    webCheckinStartHour: 0,
    webCheckinStartMinute: 0,
    webCheckinEndHour: 0,
    webCheckinEndMinute: 0,
};
