<ion-header #header>
  <ion-toolbar color="light">  
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'GIFT.btn.receive' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-horizontal" color="light" #content>
  <div class="full-width-height viewport-div ion-padding-bottom" lines="none" #list *ngIf="previewGuestList?.length">
    <cdk-virtual-scroll-viewport [itemSize]="110" [minBufferPx]="200" [maxBufferPx]="200">
      <ion-list class="full-width background-transparent ion-padding-bottom" lines="none" #list>
        <app-guest-card *cdkVirtualFor="let guest of previewGuestList; trackBy: trackByFn; let index = index;" [style.height.px]="itemHeightFn(guest)" [mode]="'gift'" [guestList]="previewGuestList" [setGuest]="guest" [index]="index" [showGuestSeating]="true"></app-guest-card>
      </ion-list>
    </cdk-virtual-scroll-viewport>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-grid fixed>
      <ion-row>
        <ion-col>
          <ion-button class="ion-margin" expand="block" color="light" (click)="dismissModal(true, true)">
            {{ 'BTN.skip' | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>