import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FunctionService } from 'src/app/services/general/function.service';
import { KeyboardService } from 'src/app/services/general/keyboard.service';

/**
 * Input component
 */
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, OnDestroy {

  /**
   * Type
   */
  type: string;
  /**
   * Img
   */
  img: string;
  /**
   * Title
   */
  title: string;
  /**
   * Subtitle
   */
  subtitle: string;
  /**
   * place holder
   */
  placeholder: string;

  /**
   * Message
   */
  msg: string;
  /**
   * Name
   */
  name: string;
  /**
   * Value
   */
  value: any;
  /**
   * Optional
   */
  optional: boolean;
  /**
   * Autocomplete
   */
  autocomplete = 'off';
  /**
   * Autocapitalize
   */
  autocapitalize = 'off';

  confirmBtnTxt: string;
  cancelBtnTxt: string;

  /**
   * Input form
   */
  inputForm: FormGroup;
  /**
   * Validation msg
   */
  validationMsg: any;

  /**
   * Constructor
   * @param platform Platform
   * @param formBuilder Form builder
   * @param modalController Modal controller
   * @param translate Translate service
   */
  constructor(
    private platform: Platform,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private translate: TranslateService,
    private keyboardService: KeyboardService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {}

  /**
   * Before view enter, initialize input
   */
  ionViewWillEnter() {
    if (!this.type) {
      this.type = 'text';
    }
    this.setupAutoInput();
    this.setupForm();
  }

  /**
   * Setup auto form input based on input name
   */
  setupAutoInput() {
    if (this.name === 'email') {
      this.autocomplete = 'email';
      this.autocapitalize = 'off';
    } else if (this.name === 'name') {
      this.autocomplete = 'on';
      this.autocapitalize = 'words';
    } else {
      this.autocomplete = 'off';
      this.autocapitalize = 'off';
    }
  }

  /**
   * Setup input form
   */
  setupForm() {
    this.setupValidationMsg();
    this.inputForm = this.formBuilder.group({
      input: new FormControl(this.value, [
        this.optional ? Validators.nullValidator : Validators.required,
        this.type === 'email' ? Validators.email : Validators.nullValidator
      ]),
    });
  }

  /**
   * Setup validation msg
   */
  setupValidationMsg() {
    this.validationMsg = {
      input : [
        { type: 'required', msg: this.translate.instant('MSG.input_required') },
        { type: 'email', msg: this.translate.instant('VALIDATION.invalid_format', { field: this.translate.instant('LBL.email') }) }
      ]
    };
  }

  /**
   * Input form submit check if form is valid.
   * Trim input value.
   */
  inputFormSubmit() {
    const value = this.inputForm?.value?.input?.toString()?.trim();
    if (this.inputForm.value.input) {
      this.inputForm.controls.input.setValue(value);
    }

    if (this.inputForm.valid) {
      this.dismissModal(value);
    }
  }

  /**
   * Cancel
   */
  cancel() {
    this.dismissModal('', true);
  }

  /**
   * Dimiss Input modal
   * @param confirm confirm
   */
  async dismissModal(input?: string, cancel?: boolean) {
    if (this.modalController) {
      if (this.platform.is('hybrid')) {
        await this.keyboardService.hide();
        if (this.platform.is('ios')) {
          await this.functionService.delay(100);
        }
      }
      const modal = await this.modalController.getTop();
      if (modal) {
        modal.dismiss({ input, cancel });
      }
    }
  }

}
