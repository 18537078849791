import { BlastPrice } from "../interfaces/blast";

export const WhatsAppOtherCredit: number = 10;
export const WhatsAppPriceList: BlastPrice[] = [
	{ country: 'SG', phone: 65, blastCredit: 12 },
	{ country: 'MY', phone: 60, blastCredit: 13 },
	{ country: 'TW', phone: 886, blastCredit: 12 },
	{ country: 'HK', phone: 852, blastCredit: 12 },
	{ country: 'US', phone: 1, blastCredit: 7 },
	{ country: 'AF', phone: 93, blastCredit: 8 },
	{ country: 'AL', phone: 355, blastCredit: 13 },
	{ country: 'DZ', phone: 213, blastCredit: 7 },
	{ country: 'AS', phone: 1684, blastCredit: 10 },
	{ country: 'AD', phone: 376, blastCredit: 10 },
	{ country: 'AO', phone: 244, blastCredit: 7 },
	{ country: 'AI', phone: 1264, blastCredit: 10 },
	{ country: 'AG', phone: 1268, blastCredit: 10 },
	{ country: 'AR', phone: 54, blastCredit: 11 },
	{ country: 'AM', phone: 374, blastCredit: 13 },
	{ country: 'AW', phone: 297, blastCredit: 10 },
	{ country: 'AU', phone: 61, blastCredit: 12 },
	{ country: 'AT', phone: 43, blastCredit: 11 },
	{ country: 'AZ', phone: 994, blastCredit: 13 },
	{ country: 'BS', phone: 1242, blastCredit: 10 },
	{ country: 'BH', phone: 973, blastCredit: 8 },
	{ country: 'BD', phone: 880, blastCredit: 12 },
	{ country: 'BB', phone: 1246, blastCredit: 10 },
	{ country: 'BY', phone: 375, blastCredit: 13 },
	{ country: 'BE', phone: 32, blastCredit: 11 },
	{ country: 'BZ', phone: 501, blastCredit: 10 },
	{ country: 'BJ', phone: 229, blastCredit: 7 },
	{ country: 'BM', phone: 1441, blastCredit: 10 },
	{ country: 'BT', phone: 975, blastCredit: 10 },
	{ country: 'BO', phone: 591, blastCredit: 12 },
	{ country: 'BA', phone: 387, blastCredit: 10 },
	{ country: 'BW', phone: 267, blastCredit: 7 },
	{ country: 'BV', phone: 47, blastCredit: 10 },
	{ country: 'BR', phone: 55, blastCredit: 11 },
	{ country: 'IO', phone: 246, blastCredit: 10 },
	{ country: 'VG', phone: 1284, blastCredit: 10 },
	{ country: 'BN', phone: 673, blastCredit: 10 },
	{ country: 'BG', phone: 359, blastCredit: 13 },
	{ country: 'BF', phone: 226, blastCredit: 7 },
	{ country: 'BI', phone: 257, blastCredit: 7 },
	{ country: 'KH', phone: 855, blastCredit: 12 },
	{ country: 'CM', phone: 237, blastCredit: 7 },
	{ country: 'CA', phone: 1, blastCredit: 7 },
	{ country: 'CV', phone: 238, blastCredit: 10 },
	{ country: 'KY', phone: 1345, blastCredit: 10 },
	{ country: 'CF', phone: 236, blastCredit: 10 },
	{ country: 'TD', phone: 235, blastCredit: 7 },
	{ country: 'CL', phone: 56, blastCredit: 14 },
	{ country: 'CN', phone: 86, blastCredit: 12 },
	{ country: 'CX', phone: 61, blastCredit: 10 },
	{ country: 'CC', phone: 61, blastCredit: 10 },
	{ country: 'CO', phone: 57, blastCredit: 6 },
	{ country: 'KM', phone: 269, blastCredit: 10 },
	{ country: 'CK', phone: 682, blastCredit: 10 },
	{ country: 'CR', phone: 506, blastCredit: 12 },
	{ country: 'HR', phone: 385, blastCredit: 13 },
	{ country: 'CU', phone: 53, blastCredit: 10 },
	{ country: 'CY', phone: 357, blastCredit: 10 },
	{ country: 'CZ', phone: 420, blastCredit: 13 },
	{ country: 'DK', phone: 45, blastCredit: 11 },
	{ country: 'DJ', phone: 253, blastCredit: 10 },
	{ country: 'DM', phone: 1767, blastCredit: 10 },
	{ country: 'DO', blastCredit: 12 },
	{ country: 'CD', phone: 243, blastCredit: 7 },
	{ country: 'EC', phone: 593, blastCredit: 12 },
	{ country: 'EG', phone: 20, blastCredit: 16 },
	{ country: 'SV', phone: 503, blastCredit: 12 },
	{ country: 'GQ', phone: 240, blastCredit: 10 },
	{ country: 'ER', phone: 291, blastCredit: 7 },
	{ country: 'EE', phone: 372, blastCredit: 10 },
	{ country: 'ET', phone: 251, blastCredit: 7 },
	{ country: 'FK', phone: 500, blastCredit: 10 },
	{ country: 'FO', phone: 298, blastCredit: 10 },
	{ country: 'FJ', phone: 679, blastCredit: 10 },
	{ country: 'FI', phone: 358, blastCredit: 11 },
	{ country: 'FR', phone: 33, blastCredit: 20 },
	{ country: 'GF', phone: 594, blastCredit: 10 },
	{ country: 'PF', phone: 689, blastCredit: 10 },
	{ country: 'TF', phone: 262, blastCredit: 10 },
	{ country: 'GA', phone: 241, blastCredit: 7 },
	{ country: 'GM', phone: 220, blastCredit: 7 },
	{ country: 'GE', phone: 995, blastCredit: 13 },
	{ country: 'DE', phone: 49, blastCredit: 19 },
	{ country: 'GH', phone: 233, blastCredit: 7 },
	{ country: 'GI', phone: 350, blastCredit: 10 },
	{ country: 'GR', phone: 30, blastCredit: 13 },
	{ country: 'GL', phone: 299, blastCredit: 10 },
	{ country: 'GD', phone: 1473, blastCredit: 10 },
	{ country: 'GP', phone: 590, blastCredit: 10 },
	{ country: 'GU', phone: 1671, blastCredit: 10 },
	{ country: 'GT', phone: 502, blastCredit: 12 },
	{ country: 'GG', phone: 44, blastCredit: 10 },
	{ country: 'GN', phone: 224, blastCredit: 10 },
	{ country: 'GW', phone: 245, blastCredit: 7 },
	{ country: 'GY', phone: 592, blastCredit: 10 },
	{ country: 'HT', phone: 509, blastCredit: 12 },
	{ country: 'HN', phone: 504, blastCredit: 12 },
	{ country: 'HU', phone: 36, blastCredit: 13 },
	{ country: 'IS', phone: 354, blastCredit: 10 },
	{ country: 'IN', phone: 91, blastCredit: 6 },
	{ country: 'ID', phone: 62, blastCredit: 9 },
	{ country: 'IR', phone: 98, blastCredit: 10 },
	{ country: 'IQ', phone: 964, blastCredit: 8 },
	{ country: 'IE', phone: 353, blastCredit: 11 },
	{ country: 'IM', phone: 44, blastCredit: 10 },
	{ country: 'IL', phone: 972, blastCredit: 8 },
	{ country: 'IT', phone: 39, blastCredit: 12 },
	{ country: 'CI', phone: 225, blastCredit: 7 },
	{ country: 'JM', phone: 1876, blastCredit: 12 },
	{ country: 'JP', phone: 81, blastCredit: 12 },
	{ country: 'JE', phone: 44, blastCredit: 10 },
	{ country: 'JO', phone: 962, blastCredit: 8 },
	{ country: 'KE', phone: 254, blastCredit: 7 },
	{ country: 'KI', phone: 686, blastCredit: 10 },
	{ country: 'KW', phone: 965, blastCredit: 8 },
	{ country: 'KG', phone: 996, blastCredit: 10 },
	{ country: 'LA', phone: 856, blastCredit: 12 },
	{ country: 'LV', phone: 371, blastCredit: 13 },
	{ country: 'LB', phone: 961, blastCredit: 8 },
	{ country: 'LS', phone: 266, blastCredit: 7 },
	{ country: 'LR', phone: 231, blastCredit: 7 },
	{ country: 'LY', phone: 218, blastCredit: 7 },
	{ country: 'LI', phone: 423, blastCredit: 10 },
	{ country: 'LT', phone: 370, blastCredit: 13 },
	{ country: 'LU', phone: 352, blastCredit: 10 },
	{ country: 'MO', phone: 853, blastCredit: 10 },
	{ country: 'MK', phone: 389, blastCredit: 13 },
	{ country: 'MG', phone: 261, blastCredit: 7 },
	{ country: 'MW', phone: 265, blastCredit: 7 },
	{ country: 'MV', phone: 960, blastCredit: 10 },
	{ country: 'ML', phone: 223, blastCredit: 7 },
	{ country: 'MT', phone: 356, blastCredit: 10 },
	{ country: 'MH', phone: 692, blastCredit: 10 },
	{ country: 'MQ', phone: 596, blastCredit: 10 },
	{ country: 'MR', phone: 222, blastCredit: 7 },
	{ country: 'MU', phone: 230, blastCredit: 10 },
	{ country: 'YT', phone: 262, blastCredit: 10 },
	{ country: 'MX', phone: 52, blastCredit: 9 },
	{ country: 'FM', phone: 691, blastCredit: 10 },
	{ country: 'MD', phone: 373, blastCredit: 13 },
	{ country: 'MC', phone: 377, blastCredit: 10 },
	{ country: 'MN', phone: 976, blastCredit: 12 },
	{ country: 'MS', phone: 1664, blastCredit: 10 },
	{ country: 'MA', phone: 212, blastCredit: 7 },
	{ country: 'MZ', phone: 258, blastCredit: 7 },
	{ country: 'MM', phone: 95, blastCredit: 10 },
	{ country: 'NA', phone: 264, blastCredit: 7 },
	{ country: 'NR', phone: 674, blastCredit: 10 },
	{ country: 'NP', phone: 977, blastCredit: 12 },
	{ country: 'NL', phone: 31, blastCredit: 22 },
	{ country: 'NC', phone: 687, blastCredit: 10 },
	{ country: 'NZ', phone: 64, blastCredit: 12 },
	{ country: 'NI', phone: 505, blastCredit: 12 },
	{ country: 'NE', phone: 227, blastCredit: 7 },
	{ country: 'NG', phone: 234, blastCredit: 10 },
	{ country: 'NU', phone: 683, blastCredit: 10 },
	{ country: 'NF', phone: 672, blastCredit: 10 },
	{ country: 'KP', phone: 850, blastCredit: 10 },
	{ country: 'MP', phone: 1670, blastCredit: 10 },
	{ country: 'NO', phone: 47, blastCredit: 11 },
	{ country: 'OM', phone: 968, blastCredit: 8 },
	{ country: 'PK', phone: 92, blastCredit: 10 },
	{ country: 'PW', phone: 680, blastCredit: 10 },
	{ country: 'PS', phone: 970, blastCredit: 10 },
	{ country: 'PA', phone: 507, blastCredit: 12 },
	{ country: 'PG', phone: 675, blastCredit: 12 },
	{ country: 'PY', phone: 595, blastCredit: 12 },
	{ country: 'PE', phone: 51, blastCredit: 12 },
	{ country: 'PH', phone: 63, blastCredit: 12 },
	{ country: 'PN', phone: 64, blastCredit: 10 },
	{ country: 'PL', phone: 48, blastCredit: 13 },
	{ country: 'PT', phone: 351, blastCredit: 11 },
	{ country: 'QA', phone: 974, blastCredit: 8 },
	{ country: 'CG', phone: 242, blastCredit: 10 },
	{ country: 'RE', phone: 262, blastCredit: 10 },
	{ country: 'RO', phone: 40, blastCredit: 13 },
	{ country: 'RU', phone: 7, blastCredit: 13 },
	{ country: 'RW', phone: 250, blastCredit: 7 },
	{ country: 'KN', phone: 1869, blastCredit: 10 },
	{ country: 'LC', phone: 1758, blastCredit: 10 },
	{ country: 'PM', phone: 508, blastCredit: 10 },
	{ country: 'VC', phone: 1784, blastCredit: 10 },
	{ country: 'WS', phone: 685, blastCredit: 10 },
	{ country: 'SM', phone: 378, blastCredit: 10 },
	{ country: 'ST', phone: 239, blastCredit: 10 },
	{ country: 'SA', phone: 966, blastCredit: 9 },
	{ country: 'SN', phone: 221, blastCredit: 7 },
	{ country: 'RS', phone: 381, blastCredit: 13 },
	{ country: 'SC', phone: 248, blastCredit: 10 },
	{ country: 'SL', phone: 232, blastCredit: 7 },
	{ country: 'SK', phone: 421, blastCredit: 13 },
	{ country: 'SI', phone: 386, blastCredit: 13 },
	{ country: 'SB', phone: 677, blastCredit: 10 },
	{ country: 'SO', phone: 252, blastCredit: 7 },
	{ country: 'ZA', phone: 27, blastCredit: 8 },
	{ country: 'GS', phone: 500, blastCredit: 10 },
	{ country: 'KR', phone: 82, blastCredit: 10 },
	{ country: 'SS', phone: 211, blastCredit: 7 },
	{ country: 'ES', phone: 34, blastCredit: 11 },
	{ country: 'LK', phone: 94, blastCredit: 12 },
	{ country: 'SD', phone: 249, blastCredit: 7 },
	{ country: 'SR', phone: 597, blastCredit: 10 },
	{ country: 'SJ', phone: 4779, blastCredit: 10 },
	{ country: 'SZ', phone: 268, blastCredit: 7 },
	{ country: 'SE', phone: 46, blastCredit: 11 },
	{ country: 'CH', phone: 41, blastCredit: 11 },
	{ country: 'SY', phone: 963, blastCredit: 10 },
	{ country: 'TJ', phone: 992, blastCredit: 8 },
	{ country: 'TZ', phone: 255, blastCredit: 7 },
	{ country: 'TH', phone: 66, blastCredit: 12 },
	{ country: 'TL', phone: 670, blastCredit: 10 },
	{ country: 'TG', phone: 228, blastCredit: 7 },
	{ country: 'TK', phone: 690, blastCredit: 10 },
	{ country: 'TO', phone: 676, blastCredit: 10 },
	{ country: 'TT', phone: 1868, blastCredit: 10 },
	{ country: 'TN', phone: 216, blastCredit: 7 },
	{ country: 'TR', phone: 90, blastCredit: 6 },
	{ country: 'TM', phone: 993, blastCredit: 8 },
	{ country: 'TC', phone: 1649, blastCredit: 10 },
	{ country: 'TV', phone: 688, blastCredit: 10 },
	{ country: 'UG', phone: 256, blastCredit: 7 },
	{ country: 'UA', phone: 380, blastCredit: 13 },
	{ country: 'AE', phone: 971, blastCredit: 8 },
	{ country: 'GB', phone: 44, blastCredit: 12 },
	{ country: 'UM', phone: 1, blastCredit: 10 },
	{ country: 'VI', phone: 1340, blastCredit: 10 },
	{ country: 'UY', phone: 598, blastCredit: 12 },
	{ country: 'UZ', phone: 998, blastCredit: 8 },
	{ country: 'VU', phone: 678, blastCredit: 10 },
	{ country: 'VA', phone: 379, blastCredit: 10 },
	{ country: 'VE', phone: 58, blastCredit: 12 },
	{ country: 'VN', phone: 84, blastCredit: 12 },
	{ country: 'WF', phone: 681, blastCredit: 10 },
	{ country: 'EH', phone: 212, blastCredit: 10 },
	{ country: 'YE', phone: 967, blastCredit: 8 },
	{ country: 'ZM', phone: 260, blastCredit: 7 },
	{ country: 'ZW', phone: 263, blastCredit: 10 }
];