import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BlastPackageList } from 'src/app/commons/blastPackage';
import { BlastPackage } from 'src/app/interfaces/blast';
import { Price } from 'src/app/interfaces/product';
import { CurrencyService } from '../general/currency.service';
import { StripeExchangeRateService } from '../payment/stripe/stripe-exchange-rate.service';
import { Router } from '@angular/router';
import { LanguageService } from '../general/language.service';

@Injectable({
  providedIn: 'root'
})
export class BlastPackageService implements OnInit, OnDestroy {

  minCredit = 50;
  blastPackageProductList: BlastPackage[] = BlastPackageList;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private languageService: LanguageService,
    private stripeExchangeRateService: StripeExchangeRateService,
    private currencyService: CurrencyService,
  ) { }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  getBlastPackageNameById(blastPackageId: string, langCode?: string): string {
    if (blastPackageId) {
      if (langCode) {
        const res = this.languageService.getTranslation(langCode);
        if (res) {
          const translation = res;
          const blastPackage = this.getBlastPackageById(blastPackageId);
          if (blastPackage?.blastCredit) {
            return translation?.BLAST.package.name.replace('{{ count }}', Number(blastPackage.blastCredit) );
          } else if (blastPackageId.indexOf('FREE_SMS_CREDIT_') === 0) {
            const blastCredit: number = Number(blastPackageId?.replace('FREE_SMS_CREDIT_', ''));
            return translation?.BLAST.package.name.replace('{{ count }}', Number(blastCredit) );
          } else if (blastPackageId.indexOf('FREE_BLAST_CREDIT_') === 0) {
            const blastCredit: number = Number(blastPackageId?.replace('FREE_BLAST_CREDIT_', ''));
            return translation?.BLAST.package.name.replace('{{ count }}', Number(blastCredit) );
          } else if (blastPackageId.indexOf('blast_') === 0 || blastPackageId.indexOf('sms_') === 0) {
            const data = blastPackageId.split('_');
            if (data?.length >= 3) {
              const blastCredit: number = Number(data[1]);
              return translation?.BLAST.package.name.replace('{{ count }}', Number(blastCredit) );
            }
          }
        }
      } else {
        const blastPackage = this.getBlastPackageById(blastPackageId);
        if (blastPackage?.blastCredit) {
          return this.translate.instant('BLAST.package.name', { count: Number(blastPackage.blastCredit) });
        } else if (blastPackageId.indexOf('FREE_SMS_CREDIT_') === 0) {
          const blastCredit: number = Number(blastPackageId?.replace('FREE_SMS_CREDIT_', ''));
          return this.translate.instant('BLAST.package.name', { count: Number(blastCredit) });
        } else if (blastPackageId.indexOf('FREE_BLAST_CREDIT_') === 0) {
          const blastCredit: number = Number(blastPackageId?.replace('FREE_BLAST_CREDIT_', ''));
          return this.translate.instant('BLAST.package.name', { count: Number(blastCredit) });
        } else if (blastPackageId.indexOf('blast_') === 0 || blastPackageId.indexOf('sms_') === 0) {
          const data = blastPackageId.split('_');
          if (data?.length >= 3) {
            const blastCredit: number = Number(data[1]);
            return this.translate.instant('BLAST.package.name', { count: Number(blastCredit) });
          }
        }
      }
      
    }

    return '';
  }

  getBlastPackageById(blastPackageId: string): BlastPackage {
    blastPackageId = blastPackageId.replace('sms_', 'blast_');
    const index = this.blastPackageProductList.findIndex((x: BlastPackage) => {
      return x.blastPackageId === blastPackageId;
    });
    if (index !== -1) {
     return this.blastPackageProductList[index]; 
    } else if (blastPackageId.indexOf('blast_') !== -1 && blastPackageId.indexOf('_custom') !== -1 ) {
      const blastCredit: number = Number(blastPackageId.replace('blast_', '').replace('_custom', ''));
      const blastPackage: BlastPackage = {
        blastPackageId,
        blastCredit,
        enable: true,
        price: [],
      }
      return blastPackage;
    } else {
      const data = blastPackageId.split('_');
      if (data?.length >= 3) {
        const blastCredit: number = Number(data[1]);
        const blastPackage: BlastPackage = {
          blastPackageId,
          blastCredit,
          enable: true,
          price: [],
        }
        return blastPackage;
      }
    }
    return null;
  }

  calculateBlastPackagePrice(blastCredit: number, currency?: string): Price {
    const price: Price = {
      currency: '',
      amount: 0,
    };
    if (!currency) {
      currency = this.currencyService.getAccountCurrency()?.code;
    }
    if (blastCredit && currency) {
      let max = 0;
      let packageIndex = -1;
      BlastPackageList?.forEach((x: BlastPackage, index: number) => {
        if (x.enable && x.blastCredit && blastCredit) {
          if (blastCredit >= x.blastCredit && x.blastCredit >= max) {
            max = x.blastCredit;
            packageIndex = index;
          }
        }
      });
 
      if (packageIndex === -1) {
        if (blastCredit <= 50) {
          packageIndex = 0
        } else if (blastCredit > 1000) {
          packageIndex = BlastPackageList.length -1;
        }
      }

      if (packageIndex !== -1) {
        const blastPackage = BlastPackageList[packageIndex];
        const packagePriceIndex = BlastPackageList[packageIndex].price.findIndex((x: Price) => x.currency === currency);
        if (packagePriceIndex !== -1) {
          const packagePrice = BlastPackageList[packageIndex].price[packagePriceIndex];
          const pricePerCredit = packagePrice.amount;
          price.amount = this.currencyService.rounding(currency, blastCredit * pricePerCredit);
        } else {
          const exchangeRate = this.stripeExchangeRateService.getExchangeRateByCurrency(currency);
          if (exchangeRate) {
            const usdIndex = blastPackage?.price.findIndex((price: Price) => price?.currency === 'USD');
            if (usdIndex !== -1) {
              // const packagePrice = this.currencyService.rounding(currency, blastPackage.price[usdIndex].amount * exchangeRate);
              const pricePerCredit =  blastPackage.price[usdIndex].amount * exchangeRate;
              price.amount = this.currencyService.rounding(currency, blastCredit * pricePerCredit);
            }
          }
        }
      }
    }
    price.currency = currency;
    return price;
  }

  goBlastPackagePage() {
    this.router.navigate(['/blast/package']);
  }
}
