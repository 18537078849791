import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { UserService } from 'src/app/services/user/user.service';

import { Country, Timezone } from 'src/app/interfaces/database';
import { TimezoneList } from 'src/app/commons/timezone';
import { LocalityService } from '../location/locality.service';

/**
 * Timezone service to manage timezone.
 */
@Injectable({
  providedIn: 'root'
})
export class TimezoneService implements OnInit, OnDestroy {

  /**
   * Timezone list
   */
  timezoneList: Timezone[] = TimezoneList;
  // deviceTimezone: Timezone;
  /**
   * Default timezone
   */
  private default: Timezone = {
    utc: 'UTC+08:00',
    name: 'Asia/Kuala_Lumpur',
    label: 'Asia/Kuala Lumpur (UTC+08)',
    offset: 480
  };

  /**
   * Constructor
   * @param userService user service
   * @param accountService account service
   */
  constructor(
    private userService: UserService,
    private localityServie: LocalityService,
    private accountInfoService: AccountInfoService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Get list of std timezone obj by list of timezone name
   * @param timezones list of timezone name
   * @returns list of std timezone obj
   */
  getTimezoneList(timezones: string[]): Timezone[] {
    const list: Timezone[] = [];
    if (timezones?.length) {
      timezones?.forEach((timezone: string) => {
        if (timezone) {
          const index = this.timezoneList?.findIndex(x => x?.name === timezone);
          if (index !== -1) {
            list.push(this.timezoneList[index]);
          }
        }
      });
    }
    return list;
  }

  getTimezonListByCountry(countryCode: string): Timezone[] {
    const list: Timezone[] = [];
    if (countryCode) {
      const country = this.localityServie.getCountryByCode(countryCode);
      if (country?.timezones?.length) {
        return this.getTimezoneList(country?.timezones);
      }
    }
    return list;
  }

  /**
   * Get std timezone obj by name
   * @param name timezone name
   * @returns std timezone obj
   */
  getTimezoneByName(name: string): Timezone {
    if (name) {
      const index = this.timezoneList?.findIndex(x => x?.name === name);
      if (index !== -1) {
        return this.timezoneList[index];
      }
    }
    return null;
  }

  /**
   * Get device timezone
   * @return std timezone obj
   */
  getDeviceTimezone(countryCode?: string): Timezone {
    let timezone: Timezone;
    let country: any;
    if (countryCode) {
      country = this.localityServie.getCountryByCode(countryCode);
    }
    if (!country?.code || !country?.timezones?.length) {
      country = this.localityServie.getUserCountry();
    }
    if (country?.code && country?.timezones?.length) {
      country?.timezones?.forEach((name: string) => {
        if (name) {
          if (!timezone?.name) {
            timezone = this.getTimezoneByName(name);
          }
        }
      });
      if (timezone?.name) {
        return timezone;
      }
    }
    
    const name = new Intl.DateTimeFormat('en-US').resolvedOptions().timeZone;
    if (name) {
      if (!timezone?.name) {
        timezone = this.getTimezoneByName(name);
        if (timezone?.name) {
          return timezone;
        }
      }
    }

    return this.default;
  }

  /**
   * Get user account timezone
   * @returns std timezone obj
   */
  getUserTimezone(): Timezone {
    if (this.userService?.user?.timezone) {
      return this.userService.user.timezone;
    }
    return this.getDeviceTimezone();
  }

  /**
   * Get wedding account timezone
   * @returns std timezone obj
   */
  getAccountTimezone(): Timezone {
    if (this.accountInfoService?.accountInfo?.timezone) {
      return this.accountInfoService.accountInfo.timezone;
    }
    return this.getUserTimezone();
  }

  // validateDeviceTimezone(country: Country) {
  //   if (country?.code && country?.timezones.length) {
  //     const deviceTimezone = this.getDeviceTimezone();
  //     const index = country.timezones.findIndex((timezone: string) => timezone === deviceTimezone.name);
  //     if (index !== -1) {
  //       this.deviceTimezone = deviceTimezone;
  //     } else {
  //       this.deviceTimezone = this.getTimezoneByName(country.timezones[0]);
  //     }
  //     return this.deviceTimezone;
  //   }
  // }

}
