<ion-content>
  <div class="full-screen gradient-primary table-center">
    <ion-grid class="full-width ion-no-padding" fixed>
      <ion-row class="ion-align-items-center">
        <ion-col>
          <div class="thebigday-logo-div">
            <ion-img class="thebigday-logo pulse center"></ion-img>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="text-row" *ngIf="type">
        <ion-col class="ion-text-center ion-padding ion-margin-horizontal">
          <ion-text class="font-14 font-med spacing-2 line-120" color="dark">{{ 'LOADING.page.' + type | translate }}</ion-text>
        </ion-col>
      </ion-row>
      <ion-row *ngIf=showBtn>
        <ion-col class="ion-text-center ion-padding ion-margin-horizontal">
          <ion-button color="danger" (click)="reload()">{{ 'LOADING.retry_now' | translate }}</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>