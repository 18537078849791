/**
 * Receipt PDF document defination
 */
export const ReceiptPdf = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [ 40, 280, 40, 100 ],
    header: [],
    footer: [],
    content: [],
    defaultStyle: {},
    styles: {},
    images: {}
};
