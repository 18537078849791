/**
 * Receipt style
 */
export const ReceiptStyle = {
    defaultStyle: {
        lineHeight: 1.5,
        characterSpacing: 0.6,
    },
    styles: {
        center: {
            alignment: 'center',
        },
        right: {
            alignment: 'right'
        },
        bold: {
            bold: true
        },
        red: {
            color: 'red'
        },
        margin: {
            margin: [ 40, 0 ]
        }
    }
};
