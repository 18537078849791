import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, map } from 'rxjs';

import { UpdateByService } from 'src/app/services/user/update-by.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { LocalityService } from 'src/app/services/location/locality.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { ErrorService } from 'src/app/services/general/error.service';

import { CheckinSetting } from 'src/app/interfaces/setting';
import { StdCheckinSetting } from 'src/app/commons/setting';
import { GuestListService } from 'src/app/services/guest/guest-list.service';

/**
 * Check-in setting service
 */
@Injectable({
  providedIn: 'root'
})
export class CheckinSettingService implements OnInit, OnDestroy {

  checkinSettingFlag: boolean;
  /**
   * Checkin setting
   */
  checkinSetting: CheckinSetting;
  /**
   * Observable checkin setting
   */
  observableCheckinSetting: any;
  /**
   * Category list subscription
   */
  private checkinSettingSubscription: Subscription;
  /**
   * Account ID
   */
  private accountId: string;

  /**
   * Constructor
   * @param afs Angular firestore
   * @param guestService guest service
   * @param userService user service
   * @param functionService function service
   */
  constructor(
    private afs: AngularFirestore,
    private guestService: GuestService,
    private guestListService: GuestListService,
    private localityService: LocalityService,
    private updateByService: UpdateByService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
    this.observableCheckinSetting = new BehaviorSubject<CheckinSetting>(this.checkinSetting);
    this.setupGuestCheckinSetting();
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy() {
    this.unwatchCheckinSetting();
  }

  /**
   * Setup Account ID and watch / unwatch category
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
    if (this.accountId) {
      await this.watchCheckinSetting();
    } else {
      await this.unwatchCheckinSetting();
    }
  }

  /**
   * Watch category from firestore
   */
  async watchCheckinSetting() {
    this.checkinSetting = StdCheckinSetting;
    if (this.accountId) {
      if (!this.checkinSettingSubscription) {
        this.checkinSettingSubscription = this.afs.doc(`accounts/${ this.accountId }/accountSetting/checkin/`)
        .snapshotChanges().pipe(map(changes => {
          const data: any = changes.payload.data();
          return data;
        })).subscribe({
          next: (checkinSetting: CheckinSetting) => {
            if (checkinSetting) {
              this.checkinSettingFlag = true;
            } else {
              this.checkinSettingFlag = false;
            }
            this.checkinSetting = checkinSetting;
            this.observableCheckinSetting.next(this.checkinSetting);
            this.setupGuestCheckinSetting();
          }
        });
      }
    }
  }

  /**
   * Unwatch category
   */
  async unwatchCheckinSetting() {
    if (this.checkinSettingSubscription) {
      this.checkinSettingSubscription.unsubscribe();
      this.checkinSettingSubscription = null;
    }
  }

  /**
   * Setup guest checkin setting
   */
  setupGuestCheckinSetting() {
    if (!this.checkinSetting) {
      this.checkinSetting = StdCheckinSetting;
    }
    if (this.checkinSetting) {
      this.guestService.setCheckinSetting(this.checkinSetting?.pendingGuest, this.checkinSetting?.notAttendingGuest);
      this.guestListService.setCheckinSetting(this.checkinSetting?.pendingGuest, this.checkinSetting?.notAttendingGuest);
    }
  }


  /**
   * Save checkin setting
   * @param checkinSetting checkin setting
   */
   async saveCheckInSetting(checkinSetting: CheckinSetting) {
    if (this.accountId && !this.functionService.isEqual(checkinSetting, this.checkinSetting)) {
      const data = checkinSetting;
      data.updateBy = this.updateByService.updateBy;
      const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/checkin/`);
      accountsRef.set(data, { merge: true }).then(result => {
      }).catch((err: any) => {
        this.errorService.logError(err);
      });
    }
  }

  /**
   * Get default checkin setting.
   * Set Gift record setting based on country.
   * TW - Record gift with detail.
   * HK - Record gift received status.
   * @returns Checkin Setting
   */
  getDefaultCheckinSetting(): CheckinSetting {
    const country: string = this.localityService.getAccountCountry()?.code;
    const checkinSetting = StdCheckinSetting;
    if (country === 'TW') {
      // checkinSetting.giftRecord = true;
      // checkinSetting.giftDetail = true;
      // checkinSetting.promptGift =  true;
    } else if (country === 'HK') {
      // checkinSetting.giftRecord = true;
      // checkinSetting.promptGift =  true;
    }
    return checkinSetting;
  }
}
