<ion-header class="header-light" #header>
  <ion-toolbar [color]="module === 'trash' ? 'danger' : 'light'">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()" *ngIf="!pageMode">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
      <ion-menu-button *ngIf="pageMode">
        <ion-icon class="icon-menu" src="./assets/wedding/icon/general/menu.svg"></ion-icon>
        <app-inbox-count></app-inbox-count>
      </ion-menu-button>
    </ion-buttons> 
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      <ng-container *ngIf="title">
        {{ title }}
      </ng-container>
      <ng-container *ngIf="!title">
        <ng-container *ngIf="module === 'trash'">
          {{ 'GIFT.title.deleted' | translate }}
        </ng-container>
        <ng-container *ngIf="module !== 'trash'">
          <ng-container *ngIf="mode === 'preview' else giftListTitle">
            {{ 'BTN.preview' | translate }}
          </ng-container>
          <ng-template #giftListTitle>
            {{ 'GIFT.title.list' | translate }}
          </ng-template>
        </ng-container>
      </ng-container>
    </ion-title>
  </ion-toolbar>
  <ion-toolbar class="ion-no-padding background-transparent" *ngIf="giftType">
    <div class="content-div seg-div full-width">
      <ion-segment [(ngModel)]="giftType" (ionChange)="setupGiftList(); resetSelectAll();" scrollable>
        <ion-segment-button value="cash">
          <ion-label>{{ 'LIST.gift.cash' | translate }} <ion-text class="font-10 font-normal" *ngIf="currency">({{ currency }})</ion-text></ion-label>
        </ion-segment-button>
        <ion-segment-button value="other">
          <ion-label>{{ 'LIST.gift.other' | translate }}</ion-label>
        </ion-segment-button>
      </ion-segment>
    </div>
  </ion-toolbar>
  <ion-toolbar class="toolbar-search ion-no-padding ion-no-border" [ngClass]="module === 'trash' ? 'trash-toolbar' : ''" color="light" *ngIf="mode !== 'preview'">
    <div class="content-div">
      <app-search [type]="'giftList'" [reset]="resetSearch" [guestListMode]="mode" (action)="search($event)" (setupHeight)="setupContentHeight()"></app-search>
    </div>
  </ion-toolbar>
  <ion-toolbar class="toolbar-select-all" color="light">
    <div class="content-div">
      <app-select-all [total]="giftList.length" *ngIf="selectedGiftList && giftList?.length" [selected]="selectedGiftList.length" [selectAll]="selectAll" [editMode]="editMode ? true : false" (toggleSelect)="toggleSelectAll()"></app-select-all>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content class="full-width ion-padding-horizontal" [style.height]="contentHeight" [scrollY]="false" color="light">
  <div class="content-div">
    <div class="full-width viewport-div" [style.height]="contentHeight" *ngIf="giftList?.length">
      <cdk-virtual-scroll-viewport class="ion-content-scroll-host" [itemSize]="77">
        <ion-list class="full-width-height background-transparent ion-padding-horizontal ion-padding-bottom" lines="none" #list>
          <app-gift-item *cdkVirtualFor="let gift of giftList; trackBy: trackByFn; let index = index;" [setGift]="gift" [index]="index" [editMode]="editMode" [selectMode]="selectMode" [selectedGiftList]="selectedGiftList" (setSelectedGift)="setSelectedGift($event)"></app-gift-item>
        </ion-list>
      </cdk-virtual-scroll-viewport>
    </div>
    <div class="full-width-height vertical-center ion-padding" *ngIf="!giftList?.length">
      <div class="center">
        <ion-label class="ion-text-center ion-margin ion-padding" color="medium">
          <ion-icon class="center icon-cactus" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
          <div class="ion-margin-top font-14">{{ 'LBL.no_found' | translate }}</div>
        </ion-label>
      </div>
    </div>
  </div>

  <ion-fab horizontal="end" vertical="bottom" slot="fixed" *ngIf="module !== 'account' && mode !== 'preview' && giftList?.length">
    <ion-fab-button color="primary" (click)="toggleEditMode()">
      <ion-icon src="./assets/wedding/icon/general/chevron-up.svg" *ngIf="!editMode"></ion-icon>
      <ion-icon src="./assets/wedding/icon/general/close.svg" *ngIf="editMode"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
<ion-footer *ngIf="editMode && editMode" #footer>
  <app-bulk-action [mode]="mode" [selectedList]="selectedGiftList" [count]="giftList.length" (action)="bulkAction($event)"></app-bulk-action>
</ion-footer>