import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { UrlService } from 'src/app/services/general/url.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { UserSetupComponent } from 'src/app/components/user/user-setup/user-setup.component';
import { LoginComponent } from 'src/app/components/login/login/login.component';
import { User } from 'src/app/interfaces/user';

import { UniversalUrl, PublicUrl, HideAnonymousLoginUrl } from 'src/app/commons/url';
import { NavigationExtras, Router } from '@angular/router';
import { FunctionService } from './function.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService implements OnInit, OnDestroy {

  /**
   * Login modal
   */
  loginModal: HTMLIonModalElement;
  /**
   * User setup modal
   */
  userSetupModal: HTMLIonModalElement;

  private userSetupFlag: boolean;

  private user: User;
  /**
   * upgrade anonymous flag
   */
  private upgradeAnonymous: boolean;
  /**
   * Url
   */
  private url: string;
  /**
   * Login ready
   */
  private loginReady: boolean;
  /**
   * Url subscription
   */
  private urlSubscription: Subscription;
  /**
   * Login ready subscription
   */
  private loginReadySubscription: Subscription;
  /**
   * Current user subscription
   */
  private currentUserSubscription: Subscription;
  /**
   * User subscription
   */
  private userSubscription: Subscription;

  /**
   * Constructor
   * @param platform platform
   * @param modalController modal controller
   * @param urlService url service
   * @param authService auth service
   * @param userService user service
   */
  constructor(
    private router: Router,
    private platform: Platform,
    private modalController: ModalController,
    private translate: TranslateService,
    private urlService: UrlService,
    private authService: AuthService,
    private userService: UserService,
    private functionService: FunctionService,
    private popupService: PopupService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unwatchCurrentUser();
    this.unwatchLoginReady();
    this.unwatchUrl();
    this.unwatchUser();
  }

  async initialize() {
    await this.platform.ready();
    this.watchUrl();
    this.watchLoginReady();
  }

  /**
   * Watch URL
   */
  async watchUrl() {
    if (!this.urlSubscription) {
      this.urlSubscription = this.urlService.observableUrl.subscribe((url: string) => {
        this.url = url;
        this.checkUserLogin();
      });
    }
    
  }

  /**
   * Unwatch URL
   */
  async unwatchUrl() {
    if (this.urlSubscription) {
      this.urlSubscription.unsubscribe();
      this.urlSubscription = null;
    }
  }

  /**
   * Watch login ready
   */
  async watchLoginReady() {
    if (!this.loginReadySubscription) {
      this.loginReadySubscription = this.authService.observableLoginReady.subscribe((loginReady: boolean) => {
        this.loginReady = loginReady;
        if (this.loginReady) {
          this.unwatchLoginReady();
          this.watchCurrentUser();
          this.checkUserLogin();
        }
      });
    }
    
  }

  /**
   * Unwatch login ready
   */
  async unwatchLoginReady() {
    if (this.loginReadySubscription) {
      this.loginReadySubscription.unsubscribe();
      this.loginReadySubscription = null;
    }
  }

  /**
   * Watch current user
   */
  async watchCurrentUser() {
    if (!this.currentUserSubscription) {
      this.currentUserSubscription = this.authService.observableCurrentuser.subscribe((currentUser: firebase.User) => {
        if (currentUser) {
          this.unwatchCurrentUser();
          this.watchUser();
          this.dismissLoginModal();
        }
      });
    }
    
  }

  /**
   * Unwatch current user
   */
  async unwatchCurrentUser() {
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
      this.currentUserSubscription = null;
    }
  }

  /**
   * Watch user
   */
  async watchUser() {
    if (!this.userSubscription) {
      this.userSubscription = this.userService.observableUser.subscribe((user: User) => {
        this.user = user;
        if (this.user?.name) {
          this.dismissUserSetupModal();
          this.unwatchUser();
        } else {
          this.checkUserSetup();
        }
        if (this.upgradeAnonymous && !this.user?.isAnonymous) {
          this.upgradeAnonymous = false;
          this.dismissLoginModal();
        }
      });
    }
    
  }

  /**
   * Unwatch user
   */
  async unwatchUser() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
      this.userSubscription = null;
    }
  }

  /**
   * Check prompt login url
   * @returns prompt login url
   */
  checkPromptLoginUrl(): boolean {
    if (this.loginReady) {
      if (this.url && this.url !== '/') {
        if (!this.urlService.checkUrl(UniversalUrl, this.url) && !this.urlService.checkUrl(PublicUrl, this.url)) {
          return true;
        } else if (this.url.indexOf('/about/support') !== -1) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Check if anonymous login is allow for current URL
   * @returns true if anonymous login is not allow
   */
  checkAnonymousLoginUrl(): boolean {
    if (this.loginReady) {
      if (this.url && this.url !== '/') {
        if (this.urlService.checkUrl(HideAnonymousLoginUrl, this.url)) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Check user login
   */
  async checkUserLogin() {
    await this.platform.ready();
    if (this.loginReady) {
      if (this.checkPromptLoginUrl()) {
        if (!this.authService.currentUser) {
          await this.functionService.delay(this.functionService.randomNumber(10, 200));
          this.presentLoginModal(true, true);
        } else if (this.userService?.user?.isAnonymous) {
          if (this.checkAnonymousLoginUrl()) {
            await this.functionService.delay(this.functionService.randomNumber(10, 200));
            this.presentLoginModal(true, false, true);
          }
        }
      }
    }
  }

  /**
   * Check user setup
   */
  async checkUserSetup() {
    if (this.loginReady) {
      if (this.authService.currentUser) {
        if (this.user?.uid && this.user?.enable && !this.user?.name) {
          await this.functionService.delay(this.functionService.randomNumber(1, 5) * 100);
          if (!this.userSetupFlag) {
            this.userSetupFlag = true;
            await this.functionService.delay(this.functionService.randomNumber(1, 5) * 100);
            this.presentUserSetupModal();
          }
        }
      }
    }
  }

  /**
   * Dismiss Login Modal
   */
  async dismissLoginModal() {
    if (this.loginModal) {
      await this.loginModal.dismiss();
      this.loginModal = null;
    }
  }

  /**
   * Dismiss User setup modal
   */
  async dismissUserSetupModal() {
    if (this.userSetupModal) {
      await this.userSetupModal.dismiss();
      this.userSetupModal = null;
    }
  }

  /**
   * Present login modal
   * @param loginRequired login required
   * @param showAnonymousLogin show anonymous login
   * @param linkProvider link provider
   * @returns Login modal
   */
  async presentLoginModal(loginRequired?: boolean, showAnonymousLogin?: boolean, linkProvider?: boolean): Promise<HTMLIonModalElement> {
    if (!this.loginModal) {
      if (!showAnonymousLogin && linkProvider) {
        this.upgradeAnonymous = true;
      }
      this.loginModal = await this.modalController.create({
        component: LoginComponent,
        backdropDismiss: loginRequired ? false : true,
        cssClass: 'modal-transparent modal-login',
        initialBreakpoint: 0.6,
        breakpoints: [0.6, 0.9],
        componentProps: {
          loginRequired,
          showAnonymousLogin,
          linkProvider
        }
      });
      this.loginModal.present();
      this.loginModal.onDidDismiss().then(() => {
        this.loginModal = null;
      });
    }
    return this.loginModal;
  }

  /**
   * Present user setup modal
   * @returns user setup modal
   */
  async presentUserSetupModal(): Promise<any> {
    if (!this.userSetupModal) {
      await this.popupService.dismissLoading();
      this.userSetupModal = await this.modalController.create({
        component: UserSetupComponent,
        cssClass: 'modal-transparent',
        componentProps: { }
      });
      this.userSetupModal.present();
    }
    return this.userSetupModal;
  }

  /**
   * Present Anonymous error alert while anonymous user access is not allow.
   * Prompt user to link credential and redirect to user profile account tab.
   */
  async presentAnonymousErrorAlert() {
    const modal = await this.popupService.presentAlert(
      this.translate.instant('ACCOUNTS_LOGIN.anonymous') + ' ' + this.translate.instant('ACCOUNT.msg.link_credential_required')
    );
    modal.onWillDismiss().then(() => {
      const navigationExtras: NavigationExtras = {
        state: {
          type: 'account'
        }
      };
      this.router.navigate(['/user/profile'], navigationExtras);
    });
  }

}
