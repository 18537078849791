import { ModalController } from '@ionic/angular';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { LanguageService } from 'src/app/services/general/language.service';
import { CreditService } from 'src/app/services/subscription/credit.service';
import { AccountShareComponent } from 'src/app/components/account/account-share/account-share.component';
import { Language } from 'src/app/interfaces/database';
import { Credit } from 'src/app/interfaces/credit';
import { GiftCardTemplateList } from 'src/app/commons/giftCard';

@Component({
  selector: 'app-gift-card-template',
  templateUrl: './gift-card-template.component.html',
  styleUrls: ['./gift-card-template.component.scss'],
})
export class GiftCardTemplateComponent implements OnInit, OnDestroy {

  /**
   * Language list
   */
  languageList: Language[] = this.languageService.languageList;
  /**
   * Gift card template list
   */
  giftCardTemplateList: any = GiftCardTemplateList;
  /**
   * Language
   */
  language: Language;
  /**
   * gift card id
   */
  giftCardId: string;
  /**
   * credit
   */
  credit: Credit;
  /**
   * edit mode
   */
  editMode: boolean;

  /**
   * Constructor
   * @param modalController modal controller
   * @param languageService language service
   * @param creditService credit service
   */
  constructor(
    private modalController: ModalController,
    private languageService: LanguageService,
    private creditService: CreditService,
  ) { }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  /**
   * Before view enter
   */
  ionViewWillEnter() {
    // if (!this.language) {
    //   const languageIndex = this.languageService.languageList?.findIndex((language: Language) =>
    //     language.code === this.languageService.getAccountLanguage().code);
    //   if (languageIndex !== -1) {
    //     this.language = this.languageService.languageList[languageIndex];
    //   }
    // }
    this.language = this.languageService.getLanguageByCode('en');
  }

  /**
   * Dismiss couple modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { await modal.dismiss(); }
    }
  }

  /**
   * present account share modal
   * @param credit credit
   */
  async presentAccountShareModal(credit: Credit) {
    if (credit) {
      const modal = await this.modalController.create({
        component: AccountShareComponent,
        cssClass: 'modal-full-screen-bk',
        componentProps: {
          credit,
          giftCard: true
        }
      });
      modal.present();
    }
  }

  /**
   * select gift card
   * @param giftCardId gift card id
   */
  selectGiftCard(giftCardId: string) {
    if (giftCardId) {
      this.giftCardId = giftCardId;
    }
  }

  /**
   * save
   */
  async save() {
    if (this.credit?.creditId && this.giftCardId && this.giftCardId !== this.credit?.giftCardId) {
      this.credit.giftCardId =  this.giftCardId;
      await this.creditService.updateCreditGiftCardIdCall(this.credit.creditId,  this.credit.giftCardId);
      this.dismissModal();
      if (!this.editMode) {
        this.presentAccountShareModal(this.credit);
      }
    }
  }
}
