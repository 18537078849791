import { Timezone } from 'src/app/interfaces/database';
import { IonDatetime, ModalController, Platform } from '@ionic/angular';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { addYears, set, subYears } from 'date-fns';
import { TimezoneService } from 'src/app/services/general/timezone.service';
import { LanguageService } from 'src/app/services/general/language.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { DateTimeService } from 'src/app/services/general/date-time.service';
import { PopupService } from 'src/app/services/general/popup.service';

@Component({
  selector: 'app-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.scss'],
})
export class DatetimeComponent implements OnInit, OnDestroy {

  @ViewChild(IonDatetime) datetime: IonDatetime;

  min: string;
  max: string;
  time: string;
  timezone: Timezone;
  presentation: string;
  hourCycle: string;
  result: {
    iso: string,
    display: string,
    firestore: any
  };
  ready: boolean;
  locale: string;
  clearBtn: boolean;

  constructor(
    private modalController: ModalController,
    private dateTimeService: DateTimeService,
    private languageService: LanguageService,
    private timezoneService: TimezoneService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.ready = false;
  }

  getLangCode() {
    // const locale = this.dateTimeService.getLocale();
    // console.log(locale);
    // return locale;
    return this.languageService.getUserLanguage()?.code;
  }

  ionViewWillEnter() {
    this.locale = this.getLangCode();
  }

  async ionViewDidEnter() {
    await this.popupService.presentLoading();
    this.ready = false;
    if (!this.timezone) {
      this.timezone = this.timezoneService.getAccountTimezone();
    }

    if (this.timezone && !this.time) {
      this.time = this.dateTimeService.format(set(this.time ? new Date(this.time) : new Date(), { hours: this.presentation === 'time' ? 19 : 0, minutes: 0, seconds: 0, milliseconds: 0 }),
        'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx', 'en', this.timezone.name);
    }

    if (!this.min) {
      this.min = this.dateTimeService.formatISO(set(subYears(new Date(), 10), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }));
    }
    if (!this.max) {
      this.max = this.dateTimeService.formatISO(set(addYears(new Date(), 20), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 }));
    }
    
    this.ready = true;
    // this.setupDatetime();
    await this.functionService.delay(200);
    this.popupService.dismissLoading();
  }

  setupDatetime() {
    if (this.time) {
      if (this.datetime) {
        this.datetime.value = this.time;
      } else {
        setTimeout(() => {
          this.setupDatetime();
        }, 50);
      }
    }
  }

  /**
   * Dismiss Modal
   * @param select Select flag
   */
  async dismissModal(select?: boolean, clear?: boolean) {
    if (this.datetime) {
      if (select) {
        const nativeElement = (this.datetime as any).el;
        if (nativeElement.classList.contains('show-month-and-year')) {
          nativeElement.shadowRoot.querySelector('.calendar-month-year-toggle').click();
          return;
        }
        await this.datetime.confirm();
      } else {
        await this.datetime.cancel();
      }
    }
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) {
        if (select) {
          const timestamp = this.dateTimeService.generatePickerTime(this.datetime?.value?.toString(), this.timezone);
          modal.dismiss({ timestamp, dateTime: this.datetime?.value });
        } else if (clear) {
          modal.dismiss({ timestamp: '', dateTime: '' });
        } else {
          modal.dismiss();
        }
      }
    }
  }

  setupTime(time?: any) {
    if (time) {
      this.time = time;
    }
  }

}
