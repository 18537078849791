/**
 * Server config for production
 * @environment
 */
export const environment = {
  production: true,
  emulator: false,
  firebase: {
    apiKey: 'AIzaSyDeeOVRHeYboeZrVg9Y0JHQeCmVY4_D7wg',
    authDomain: 'thebigday-wedding.firebaseapp.com',
    databaseURL: 'https://thebigday-wedding-user.asia-southeast1.firebasedatabase.app/',
    projectId: 'thebigday-wedding',
    storageBucket: 'thebigday-wedding.appspot.com',
    messagingSenderId: '409119306881',
    appId: '1:409119306881:web:185722899d5a6939580980',
    measurementId: 'G-197HPX9RZ0'
  },
  recaptcha: {
    invisible: '6LfdwtIZAAAAAAFASzNlKpTa42yrG2Y6MN6ypE3O',
    v3: '6Lf72D0cAAAAADMK9JpCVPGp45RZHd5jSgHdm395'
  },
  qrcode: {
    aesKey: 'JR]$))8wm3'
  }
};
