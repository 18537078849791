import { ErrorService } from 'src/app/services/general/error.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { NativeGeocoderResult, NativeGeocoder, NativeGeocoderOptions } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { Platform } from '@ionic/angular';
import { Coordinate, LocationDetail } from 'src/app/interfaces/general';
import { Geolocation, PermissionStatus, GeolocationPluginPermissions, Position } from '@capacitor/geolocation';
import { User } from 'src/app/interfaces/user';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';
import { StorageService } from 'src/app/services/general/storage.service';
import { LocationDetailService } from './location-detail.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { UrlService } from '../general/url.service';
import { CapacitorHttp, HttpOptions, HttpResponse } from '@capacitor/core';

const STORAGE_KEY = 'location_detail';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService implements OnInit, OnDestroy {

  locationDetail: LocationDetail;
  private user: User;
  private userSubscription: Subscription;

  private locationCheck: boolean;

  observableLocationDetail: any;

  constructor(
    private platform: Platform,
    private nativeGeocoder: NativeGeocoder,
    private afs: AngularFirestore,
    private userService: UserService,
    private locationDetailService: LocationDetailService,
    private urlService: UrlService,
    private storageService: StorageService,
    private errorService: ErrorService,
  ) {
    this.observableLocationDetail = new BehaviorSubject<LocationDetail>(this.locationDetail);
    this.initial();
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy() {
    this.unwatchUser();
  }

  async initial() {
    await this.platform.ready();
    // await this.functionService.delay(1000);
    if (!this.urlService.checkVipHostName(true)) {
      if (!this.locationDetail) {
        await this.read();
      }
      this.checkPermissions();
      await this.watchUser();
    }
  }

  async watchUser() {
    if (!this.userSubscription) {
      this.userSubscription = this.userService.observableUser.subscribe((user: User) => {
        if (user) {
          this.user = user;
          this.checkUser();
        }
      });
    }
  }

  async unwatchUser() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
      this.userSubscription = null;
    }
  }

  async read() {
    await this.platform.ready();
    const locationDetail = await this.storageService.getObject(STORAGE_KEY);
    if (locationDetail?.locality?.country) {
      this.locationDetail = locationDetail;
      this.observableLocationDetail.next(this.locationDetail);
    }
    return this.locationDetail;
  }

  async save(locationDetail: LocationDetail) {
    if (locationDetail?.locality?.country) {
      this.locationDetail = locationDetail;
      this.observableLocationDetail.next(this.locationDetail);
    }
    
    this.checkUser();
    await this.storageService.setObject(STORAGE_KEY, locationDetail);
  }

  async checkPermissions() {
    try {
      const permission: PermissionStatus = await Geolocation.checkPermissions();
      if (permission?.location || permission?.coarseLocation) {
        this.getPosition(permission);
      } else {
        this.requestPermission();
      }
    } catch (err) {
      this.errorService.logError(err);
      this.requestPermission();
    }
  }

  async requestPermission() {
    try {
      const options: GeolocationPluginPermissions = {
        permissions: [ 'location', 'coarseLocation' ]
      };
      const permission: PermissionStatus = await Geolocation.requestPermissions(options);
      if (permission?.location || permission?.coarseLocation) {
        this.getPosition(permission);
      } else {
        this.setupLocationDetail();
      }
    } catch (err) {
      this.errorService.logError(err);
      this.setupLocationDetail();
    }
  }

  async getPosition(permission: PermissionStatus) {
    if (!this.locationDetail?.locality?.country) {
      try {
        const options: PositionOptions = { enableHighAccuracy: permission?.location ? true : false };
        const geoposition: Position = await Geolocation.getCurrentPosition(options);
        if (geoposition) {
          const coord: Coordinate = {
            lat: geoposition.coords.latitude,
            lng: geoposition.coords.longitude,
          };
          await this.getGeocoder(coord);
        } else {
          this.setupLocationDetail();
        }
      } catch (err) {
        this.errorService.logError(err);
        this.setupLocationDetail();
      }
    }
  }

  async getGeocoder(coord: Coordinate) {
    if (coord) {
      if (this.platform.is('hybrid')) {
        if (coord) {
          const options: NativeGeocoderOptions = {
            useLocale: false,
            maxResults: 1
          };
          const result = await this.nativeGeocoder.reverseGeocode(
            coord.lat,
            coord.lng,
            options
          );
          if (result?.length) {
            const geocoderResult: NativeGeocoderResult = result[0];
            this.setupLocationDetail(coord, geocoderResult);
          } else {
            this.setupLocationDetail(coord);
          }
        }
      } else {
        const latlng = coord.lat + ',' + coord.lng;
        const options: HttpOptions = {
          url: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + encodeURI(latlng) + '&language=en&key=AIzaSyDeeOVRHeYboeZrVg9Y0JHQeCmVY4_D7wg',
        };
        try {
          const response: HttpResponse = await CapacitorHttp.get(options);
          if (response?.status === 200 && response?.data?.results?.length) {
            const googleMapResult: google.maps.GeocoderResult = response.data.results[0];
            this.setupLocationDetail(coord, null, googleMapResult);
          } else {
            this.setupLocationDetail(coord);
          }
        } catch (err: any) {
          this.errorService.logError(err);
          this.setupLocationDetail(coord);
        }
      }
    }
  }

  setupLocationDetail(coords?: Coordinate, geocoderResult?: NativeGeocoderResult, googleMapResult?: google.maps.GeocoderResult) {
    const locationDetail = this.locationDetailService.setupLocationDetail(coords, geocoderResult, googleMapResult);
    this.save(locationDetail);
  }

  async checkUser() {
    if (this.user?.uid && this.user?.name) {
      if (!this.user?.location?.locality?.country) {
        if (this.locationDetail?.locality?.country) {
          const location = this.locationDetail;
          const userRef = this.afs.firestore.doc(`users/${ this.userService.uid }/`);
          userRef.set({ location }, { merge: true }).then(result => {
          }).catch((err: any) => {
            this.errorService.logError(err);
          });
        } else if (!this.locationCheck) {
          this.locationCheck = true;
          await this.checkPermissions();
        } else {
          setTimeout(() => {
            this.checkUser();
          }, 3000);
        }
      }
    }
  }
}
