import { LanguageService } from './language.service';
import { Location } from '@angular/common';
import { NavigationExtras, Router } from '@angular/router';
import { Injectable, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Platform, ModalController } from '@ionic/angular';
import { App } from '@capacitor/app';

import { PopupService } from 'src/app/services/general/popup.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { UrlService } from 'src/app/services/general/url.service';

import { AccountsSetupComponent } from 'src/app/components/accounts/accounts-setup/accounts-setup.component';
import { AccountRedirectUrl, AppDownloadUrl, DynamicUrl, UniversalUrl, UserRedirectUrl } from 'src/app/commons/url';
import { Browser } from '@capacitor/browser';
import { Language } from 'src/app/interfaces/database';
import { PluginListenerHandle } from '@capacitor/core';
import { User } from 'src/app/interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class LinkService implements OnInit, OnDestroy {

  /**
   * Login ready
   */
  loginReady: boolean;
  /**
   * Url
   */
  url: string;

  playStore: boolean;
  appGallery: boolean;
  universalLink: boolean;

  private universalLinkHandler: PluginListenerHandle;
  
  /**
   * constructor
   * @param router router
   * @param fns angularfire store
   * @param modalController modal controller
   * @param platform platform
   * @param zone zone
   */
  constructor(
    private router: Router,
    private location: Location,
    private fns: AngularFireFunctions,
    private modalController: ModalController,
    private platform: Platform,
    private zone: NgZone,
    private urlService: UrlService,
    private languageService: LanguageService,
    private popupService: PopupService,
    private errorService: ErrorService,
  ) {
    this.initialize();
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    this.unwatchUniversalLinks();
    this.unwatchApp();
  }

  /**
   * Initialize
   */
  async initialize() {
    await this.platform.ready();
    if (this.platform.is('hybrid')) {
      this.watchUniversalLinks();
    } else {
      this.watchWebLinks();
    }
  }

  async watchAppState() {
    App.addListener('appStateChange', async ({ isActive }) => {
      if (isActive) {
        this.watchUniversalLinks();
      }
    });
  }

  /**
   * Watch universal links
   */
  async watchUniversalLinks() {
    this.universalLinkHandler = await App.addListener('appUrlOpen', (data: any) => {
      this.zone.run(() => {
        if (data?.url) {
          if (data.url.indexOf('https://wedding.thebigday.my/') !== -1) {
            const slug = data.url.split('https://wedding.thebigday.my/#').pop();
            if (slug) {
              this.checkAppLink(slug);
            }
          } else if (data.url.indexOf('https://wedding.thebigdays.com/') !== -1) {
            const slug = data.url.split('https://wedding.thebigdays.com/#').pop();
            if (slug) {
              this.checkAppLink(slug);
            }
          }
        }
      });
    });
  }

  async unwatchUniversalLinks() {
    if (this.universalLinkHandler) {
      await this.universalLinkHandler.remove();
      this.universalLinkHandler = null;
    }
  }

  async unwatchApp() {
    await App.removeAllListeners();
  }

  /**
   * watch web links
   */
  watchWebLinks() {
  }

  /**
   * Check app link
   * @param url Url
   */
  async checkAppLink(url: string) {
    const navigationExtras: NavigationExtras = {
      state: {
        replaceUrl: true
      }
    };
    if (url?.indexOf('/vip/') !== -1) {
      const loginData = decodeURI(url.replace('/vip/', ''));
      this.goPage('/vip/', loginData, navigationExtras);
    } else if (url?.indexOf('/links/gift/') !== -1) {
      const creditId: string = this.encodeUrl(decodeURI(url.replace('/links/gift/', '')));
      this.goPage('/links/gift/', creditId, navigationExtras);
    } else if (url?.indexOf('/links/login/') !== -1) {
      const loginData = this.encodeUrl(decodeURI(url.replace('/links/login/', '')));
      this.goPage('/links/login/', loginData, navigationExtras);
    } else if (url?.indexOf('/links/migrate/') !== -1) {
      const loginData = decodeURI(url.replace('/links/migrate/', ''));
      this.goPage('/links/migrate/', loginData, navigationExtras);
    } else if (url?.indexOf('/subscription/account/') !== -1) {
      const subscriptionData: string = decodeURI(url.replace('/subscription/account/', ''));
      this.goPage('/subscription/account/', subscriptionData, navigationExtras);
    } else if (url?.indexOf('/links/') !== -1) {
      this.goPage(url, '');
    } else if (url?.indexOf('/about/') !== -1) {
      this.goPage(url, '');
    } else if (url?.indexOf('/business/') !== -1) {
      this.goPage(url, '');
    } else if (url?.indexOf('/payment/result/') !== -1) {
      const paymentResultData: string = decodeURI(url.replace('/payment/result/', ''));
      this.goPage('/payment/result/', paymentResultData, navigationExtras);
    } else if (url?.indexOf('/payment/stripe-status/') !== -1) {
      const paymentResultData: string = decodeURI(url.replace('/payment/stripe-status/', ''));
      this.goPage('/payment/stripe-status/', paymentResultData, navigationExtras);
    } else if (url?.indexOf('/subscription/premium') !== -1) {
      this.goPage('/subscription/premium', '', navigationExtras);
    } else {
      this.goPage(url, '', navigationExtras);
    }
  }

  encodeUrl(url: string) {
    return url?.replace(/\//g, '%2F')?.replace(/\(/g, '%28')?.replace(/\)/g, '%29')?.replace(/\[/g, '%5B')?.replace(/\]/g, '%5D');
  }

  /**
   * Check web link
   * @param url url
   */
  async checkWebLink(url: string) {
  }

  /**
   * Go Page
   * @param url Url
   * @param navigationExtras navigations extras
   */
  async goPage(url: string, param: string, navigationExtras?: NavigationExtras) {
    if (url && this.validUniversalLinks(url)) {
      this.universalLink = true;
      await this.popupService.presentLoading();
      if (this.loginReady) {
        setTimeout(() => {
          this.zone.run(() => {
            this.router.navigate([ url + param ], navigationExtras);
            this.popupService.dismissLoading();
            setTimeout(() => {
              this.universalLink = false;
            }, 1500);
          });
        }, 1500);
      } else {
        setTimeout(() => {
          this.goPage(url, param, navigationExtras);
        }, 500);
      }
    }
  }

  /**
   * Check valid universal links
   * @param url url
   * @returns true if valid universal links
   */
  validUniversalLinks(url: string): boolean {
    if (url) {
      if (this.urlService.checkUrl(UniversalUrl, url)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Check valid user redirect links
   * @returns true if valid user redirect links
   */
  validUserRedirectLinks(): boolean {
    const url = this.router.url;
    let valid = false;
    if (!url || url === '/' || this.urlService.checkUrl(UserRedirectUrl, url)) {
      valid = true;
    }
    return valid;
  }

  /**
   * Check valid account redirect link
   * @returns true if valid account redirect links
   */
  validAccountRedirectLinks(): boolean {
    const url = this.router.url;
    let valid = false;
    if (!url || url === '/' || (this.urlService.checkUrl(AccountRedirectUrl, url))) {
    // if (!url || url === '/' || (!this.urlService.checkUrl(['/about/'], url))) {
      valid = true;
    }
    return valid;
  }

  /**
   * Check valid gift credit id
   * @param creditId credit id
   */
  async validateGiftCreditId(creditId: string) {
    if (creditId) {
      this.validateGiftCardCreditCall(creditId);
    } else {
      this.goMainPage();
    }
  }

  /**
   * validate gift card credit call
   * @param creditId credit id
   */
  async validateGiftCardCreditCall(creditId: string) {
    // if (this.onlineService.isOnline()) {
      if (creditId) {
        await this.fns.httpsCallable('validateGiftCardCreditCall')({ creditId })
        .toPromise().then(async (valid) => {
          if (valid) {
            this.presentAccountSetupModal(creditId);
          } else {
            this.goMainPage();
          }
        }).catch((err: any) => {
          this.errorService.logError(err);
          this.goMainPage();
        });
      } else {
        this.goMainPage();
      }
    // }
  }

  /**
   * Present accounts setup modal
   * @param creditId credit id
   */
  async presentAccountSetupModal(creditId: string) {
    if (creditId) {
      const modal = await this.modalController.create({
        component: AccountsSetupComponent,
        backdropDismiss: false,
        componentProps: {
          premium: true,
          creditId
        }
      });
      modal.present();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Go accounts list page
   * @param replaceUrl replace url
   */
  async goAccountsListPage(replaceUrl?: boolean) {
    this.loginReady = true;
    if (this.validUserRedirectLinks() && !this.universalLink) {
      const navigationExtras: NavigationExtras = {
        replaceUrl: replaceUrl ? true : false,
        state: { }
      };

      await this.router.navigate([ '/tabs-accounts/list' ], navigationExtras);
    }
  }

  /**
   * Go account home page
   * @param replaceUrl replace url
   */
  async goAccountHomePage(replaceUrl?: boolean) {
    this.loginReady = true;
    if (this.validAccountRedirectLinks()) {
      const navigationExtras: NavigationExtras = {
        replaceUrl: replaceUrl ? true : false,
        state: {}
      };
      await this.router.navigate([ '/account/home' ], navigationExtras);
    }
  }

  /**
   * Go main page
   */
  async goMainPage(reload?: boolean) {
    if (!this.universalLink) {
      const navigationExtras: NavigationExtras = {
        replaceUrl: true,
        state: {  }
      };
      await this.router.navigate([ '/main' ], navigationExtras);
      if (reload) {
        this.reload();
      }
    }
  }

  async reload() {
    const currentUrl = window.location.href;
    const timestamp = new Date().getTime();

    if (currentUrl.includes('timestamp=')) {
      // If it exists, replace its value with the new timestamp
      const updatedUrl = currentUrl.replace(/(\?|&)timestamp=\d+/, `$1timestamp=${timestamp}`);
      window.location.href = updatedUrl;
    } else {
      // If it doesn't exist, add the 'timestamp' parameter to the URL
      const separator = currentUrl.includes('?') ? '&' : '?';
      const updatedUrl = currentUrl + separator + 'timestamp=' + timestamp;
      window.location.href = updatedUrl;
    }
    window?.location?.reload();
  }

  goAccountLogoutPage() {
    this.router.navigate(['/accounts/logout']);
  }

  // async goBack() {
  //   this.location.back();
  // }

  /**
   * Go website
   */
  goWebsite() {
    this.openUrl(DynamicUrl.long.website);
  }

  goHelpCenter() {
    const language: Language = this.languageService.getUserLanguage();
    if ((language?.code === 'zh' || language?.code === 'zh-TW') && DynamicUrl?.long?.helpCenter?.['zh-TW']) {
      this.openUrl(DynamicUrl.long.helpCenter['zh-TW']);
    } else {
      this.openUrl(DynamicUrl.long.helpCenter.en);
    }
  }

  goAppDownloadPage(showPage?: boolean, backHelpPage?: boolean) {
    const navigationExtras: NavigationExtras = {
      state: { showPage, backHelpPage }
    };
    this.router.navigate([ AppDownloadUrl ], navigationExtras);
  }

  async openUrl(url: string, skipIAB?: boolean) {
    if (url) {
      try {
        if (this.platform.is('hybrid') && !skipIAB) {
          await Browser.open({ url });
        } else {
          window.open(url, 'system');
        }
      } catch (err) {
        this.errorService.logError(url.toString());
      }
    }
  }

  logLoginAction(action: any) {
    if (action) {
      this.errorService.logError(action);
    }
  }

  goBack() {
    this.location.back();
  }

}
