import { UrlService } from 'src/app/services/general/url.service';
import { AppService } from 'src/app/services/general/app.service';
import { DebugModeService } from './debug-mode.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Platform } from '@ionic/angular';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
// import { FirebaseAnalytics } from '@capacitor-firebase/analytics';

import { GAPurchase } from 'src/app/interfaces/analytics';
import { Subscription } from 'rxjs';
import { AttachmentType } from 'src/app/types/general';
import { GpsType } from 'src/app/types/visitor';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService implements OnInit, OnDestroy {

  private iosApp: boolean;
  private urlSubscription: Subscription;

  constructor(
    private platform: Platform,
    private angularFireAnalytics: AngularFireAnalytics,
    private debugModeService: DebugModeService,
    private appService: AppService,
    private urlService: UrlService,
  ) {
    this.initialize();
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy() {
    this.unwatchUrl();
  }

  async initialize() {
    await this.platform.ready();
    if (this.platform.is('hybrid') && this.platform.is('ios')) {
      this.iosApp = true;
    } else {
      this.iosApp = false;
    }

    await this.debugModeService.check();
    this.setDebugMode();
    if (!this.debugModeService.debugMode) {
      await this.setup();
      this.setAppName();
      this.setAppVersion();
      this.watchUrl();
    }
  }

  /**
   * Watch url
   */
   watchUrl() {
    if (this.iosApp) {
      if (!this.urlSubscription) {
        this.urlSubscription = this.urlService.observableUrl.subscribe((url: string) => {
          this.setScreenName(url);
        });
      }
    }
  }

  /**
   * Unwatch url
   */
  unwatchUrl() {
    if (this.iosApp) {
      if (this.urlSubscription) {
        this.urlSubscription.unsubscribe();
        this.urlSubscription = null;
      }
    }
  }

  async setup() {
    if (!this.debugModeService.debugMode && this.iosApp) {
      await FirebaseAnalytics.enable();
    }
  }

  async setAppName() {
    await this.platform.ready();
    if (!this.debugModeService.debugMode) {
      let appName = '';
      if (!this.platform.is('hybrid')) {
        appName = 'Web';
      } else if (this.platform.is('ios')) {
        appName = 'iOS';
      } else if (this.platform.is('android')) {
        appName = 'Android';
      }
      if (this.iosApp) {
        await FirebaseAnalytics.setUserProperty({ name: 'app_name', value: appName });
      } else {
        await this.angularFireAnalytics.updateConfig({ app_name: appName });
      }
    }
  }

  async setAppVersion() {
    await this.platform.ready();
    if (!this.debugModeService.debugMode) {
      const appVersion = await this.appService.getAppVersion();
      if (this.iosApp) {
        await FirebaseAnalytics.setUserProperty({ name: 'app_version', value: appVersion });
      } else {
        await this.angularFireAnalytics.updateConfig({ app_version: appVersion });
      }
    }
  }

  async setDebugMode() {
    if (this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.disable();
      } else {
        await this.angularFireAnalytics.updateConfig({ debug_mode: true });
        await this.angularFireAnalytics.setAnalyticsCollectionEnabled(false);
      }
    }
  }

  async userId(userId: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.setUserId({ userId });
      } else {
        this.angularFireAnalytics.setUserId(userId);
      }
    }
  }

  async userCreated(method?: string, uid?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'user_created', params: { method, uid }});
      } else {
        this.angularFireAnalytics.logEvent('user_created', { method, uid });
      }
    }
  }

  async userLogin(method?: string, uid?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'user_login', params: { method, uid }});
      } else {
        this.angularFireAnalytics.logEvent('user_login', { method, uid });
      }
    }
  }

  async userLogout(uid?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'user_logout', params: { uid }});
      } else {
        this.angularFireAnalytics.logEvent('user_logout', { uid });
      }
    }
  }

  async userDelete(uid?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'user_delete', params: { uid }});
      } else {
        this.angularFireAnalytics.logEvent('user_delete', { uid });
      }
    }
  }

  async trialAccountSetup(accountId?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'accounts_trial', params: { accountId }});
      } else {
        this.angularFireAnalytics.logEvent('accounts_trial', { accountId });
      }
    }
  }

  async giftAccountSetup(accountId?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'accounts_gift', params: { accountId }});
      } else {
        this.angularFireAnalytics.logEvent('accounts_gift', { accountId });
      }
    }
  }

  async migrateAccountSetup(accountId?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'accounts_migrate', params: { accountId }});
      } else {
        this.angularFireAnalytics.logEvent('accounts_migrate', { accountId });
      }
    }
  }

  async premiumAccountSetup(accountId?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'accounts_premium', params: { accountId }});
      } else {
        this.angularFireAnalytics.logEvent('accounts_premium', { accountId });
      }
    }
  }

  async setAccountType(eventMode: boolean) {
    const accountType = eventMode ? 'event' : 'wedding';
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'set_accounts_type', params: { accountType }});
      } else {
        this.angularFireAnalytics.logEvent('set_accounts_type', { accountType });
      }
    }
  }

  async updateAccountType(eventMode: boolean) {
    const accountType = eventMode ? 'event' : 'wedding';
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'update_accounts_type', params: { accountType }});
      } else {
        this.angularFireAnalytics.logEvent('update_accounts_type', { accountType });
      }
    }
  }

  async accountLogin(accountId?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'account_login', params: { accountId }});
      } else {
        this.angularFireAnalytics.logEvent('account_login', { accountId });
      }
    }
  }

  async accountLoginAuto(accountId?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'account_login_auto', params: { accountId }});
      } else {
        this.angularFireAnalytics.logEvent('account_login_auto', { accountId });
      }
    }
  }

  async accountLogout(accountId?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'account_logout', params: { accountId }});
      } else {
        this.angularFireAnalytics.logEvent('account_logout', { accountId });
      }
    }
  }

  async accountRole(role: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'account_role', params: { role }});
      } else {
        this.angularFireAnalytics.logEvent('account_role', { role });
      }
    }
  }

  async upgradeTrial(accountId?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'trial_upgrade', params: { accountId }});
      } else {
        this.angularFireAnalytics.logEvent('trial_upgrade', { accountId });
      }
    }
  }

  async iap(data: GAPurchase) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'iap', params: data});
      } else {
        this.angularFireAnalytics.logEvent('iap', data);
      }
    }
  }

  async purchase(data: GAPurchase) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'purchase', params: data});
      } else {
        this.angularFireAnalytics.logEvent('purchase', data);
      }
    }
  }

  async setScreenName(screenName: string) {
    if (!this.debugModeService.debugMode && this.platform.is('hybrid')) {
      if (screenName) {
        await FirebaseAnalytics.setScreenName({ screenName, nameOverride: screenName });
      }
    }
  }

  async setVisitor(accountId?: string, guestId?: string, groupId?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'visitor_page', params: { accountId, guestId, groupId }});
      } else {
        this.angularFireAnalytics.logEvent('visitor_page', { accountId, guestId, groupId });
      }
    }
  }

  async updateDate(accountId?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'date_update', params: { accountId }});
      } else {
        this.angularFireAnalytics.logEvent('date_update', { accountId });
      }
    }
  }

  async openInbox(templateId?: string, uid?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'open_inbox', params: { uid, templateId }});
      } else {
        this.angularFireAnalytics.logEvent('open_inbox', { uid, templateId });
      }
    }
  }

  async addCalendar(accountId?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'add_calendar', params: { accountId }});
      } else {
        this.angularFireAnalytics.logEvent('add_calendar', { accountId });
      }
    }
  }

  async openAttachment(accountId?: string, type?: AttachmentType) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'open_attachment', params: { accountId, type }});
      } else {
        this.angularFireAnalytics.logEvent('open_attachment', { accountId, type });
      }
    }
  }

  async openGps(accountId?: string, type?: GpsType, venue?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'open_gps', params: { accountId, type, venue }});
      } else {
        this.angularFireAnalytics.logEvent('open_gps', { accountId, type, venue });
      }
    }
  }

  async openGuestPageHowBtn(accountId?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'guest_page_how_btn', params: { accountId }});
      } else {
        this.angularFireAnalytics.logEvent('guest_page_how_btn', { accountId });
      }
    }
  }

  async webCheckin(accountId?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'web_checkin', params: { accountId }});
      } else {
        this.angularFireAnalytics.logEvent('web_checkin', { accountId });
      }
    }
  }

  async guestPageOpenWebsite(accountId?: string) {
    if (!this.debugModeService.debugMode) {
      if (this.iosApp) {
        await FirebaseAnalytics.logEvent({name: 'guest_page_open_website', params: { accountId }});
      } else {
        this.angularFireAnalytics.logEvent('guest_page_open_website', { accountId });
      }
    }
  }


}
