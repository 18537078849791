import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';

import { Inbox, InboxStatus } from 'src/app/interfaces/inbox';
import { ErrorService } from 'src/app/services/general/error.service';
import { InboxDbService } from './inbox-db.service';
import { InboxService } from './inbox.service';

@Injectable({
  providedIn: 'root'
})
export class InboxStatusService implements OnInit, OnDestroy {

  private uid: string;

  constructor(
    private inboxDbService: InboxDbService,
    // private onlineService: OnlineService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  setup(uid: string) {
    this.uid = uid;
  }

  // getInboxStatusList(inboxType?: InboxType): InboxStatus[] {
  //   const inboxStatusList = this.functionService.cloneDeep(this.inboxStatusList);
  //   if (inboxType) {
  //     // return inboxStatusList?.filter((inboxStatus: InboxStatus) => {
  //     //   return inboxStatus?.inboxType === inboxType;
  //     // });
  //   }
  //   return inboxStatusList;
  // }

  // getInboxStatusById(inboxId: string, inboxType: InboxType): InboxStatus {
  //   // if (inboxId) {
  //   //   const inboxStatusList = this.getInboxStatusList(inboxType);
  //   //   const index = inboxStatusList?.findIndex((x: InboxStatus) => {
  //   //     return x.inboxId === inboxId;
  //   //   });
  //   //   if (index !== -1 && inboxStatusList?.[index]) {
  //   //     return inboxStatusList[index];
  //   //   }
  //   // }
    
  //   // return { inboxId, inboxType, read: false, deleted: false };
  //   return null;
  // }

  // generateInboxStatusList(inboxList: Inbox[]): InboxStatus[] {
  //   const inboxStatusList: InboxStatus[] = this.getInboxStatusList();
  //   // if (inboxList?.length) {
  //   //   inboxList.forEach((inbox: Inbox) => {
  //   //     if (inbox?.inboxId) {
  //   //       const index = inboxStatusList.findIndex((x: InboxStatus) => {
  //   //         return x?.inboxId === inbox.inboxId;
  //   //       });
  //   //       if (index === -1) {
  //   //         const inboxStatus: InboxStatus = {
  //   //           inboxId: inbox.inboxId,
  //   //           inboxType: inbox?.inboxType,
  //   //           read: false,
  //   //           deleted: false,
  //   //         };
  //   //         inboxStatusList.push(inboxStatus);
  //   //       }
  //   //     }
  //   //   });
  //   // }
  //   return inboxStatusList;
  // }

  updateInboxStatus(inbox: Inbox, inboxStatus?: InboxStatus) {
    if (this.uid && inbox?.inboxId) {
      if (inbox?.inboxType === 'releaseNote') {
        const db = this.inboxDbService.getReleaseNoteStatusDbRef(this.uid, inbox.inboxId);
        db.update(inboxStatus).then(() => {
        }).catch((err: any) => {
          this.errorService.logError(err);
        });
      } else {
        const db = this.inboxDbService.getInboxDbRef(this.uid, inbox.inboxId);
        db.update(inbox).then(() => {
        }).catch((err: any) => {
          this.errorService.logError(err);
        });
      }
    } else {
      setTimeout(() => {
        this.updateInboxStatus(inbox);
      }, 500);
    }    
  }

}
