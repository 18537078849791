import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ModalController, ActionSheetController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { InvitedByService } from 'src/app/services/setting/invited-by.service';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { GuestDeleteService } from 'src/app/services/guest/guest-delete.service';
import { GroupService } from 'src/app/services/group/group.service';
import { SeatingService } from 'src/app/services/seating/seating.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { CheckinSettingService } from 'src/app/services/checkin/checkin-setting.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { GuestGroupComponent } from 'src/app/components/group/guest-group/guest-group.component';
import { SeatingListComponent } from 'src/app/components/seating/seating-list/seating-list.component';
import { SeatingDetailComponent } from 'src/app/components/seating/seating-detail/seating-detail.component';

import { CheckinSetting } from 'src/app/interfaces/setting';
import { Guest } from 'src/app/interfaces/guest';
import { Group } from 'src/app/interfaces/group';
import { SettingField } from 'src/app/interfaces/database';
import { Mobile } from 'src/app/interfaces/general';
import { Gift } from 'src/app/interfaces/gift';

import { SettingFieldType, ModuleType } from 'src/app/types/general';
import { SettingFieldComponent } from 'src/app/components/setting/setting-field/setting-field.component';

import { ReadContactsComponent } from 'src/app/components/general/read-contacts/read-contacts.component';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';
import { SessionService } from 'src/app/services/setting/session.service';
import { CurrentPrivilege } from 'src/app/interfaces/privilege';

/**
 * Guest edit component
 */
@Component({
  selector: 'app-guest-edit',
  templateUrl: './guest-edit.component.html',
  styleUrls: ['./guest-edit.component.scss']
})
export class GuestEditComponent implements OnInit, OnDestroy {

  /**
   * AccountModule type
   */
  module: ModuleType;
  /**
   * Bulk edit mode
   */
  bulkEditMode: boolean;
  /**
   * Check-in Setting
   */
  checkinSetting: CheckinSetting;
  /**
   * New guest
   */
  newGuest: boolean;
  /**
   * Selected guest list
   */
  selectedGuestList: string[];
  /**
   * Guest
   */
  guest: Guest;
  /**
   * Guest group
   */
  group: Group;
  /**
   * Guest edit form
   */
  form: FormGroup;
  /**
   * Validation msg
   */
  validationMsg: any;
  /**
   * Invited by list
   */
  invitedByList: SettingField[];

  mobile: Mobile;
  mobileInvalid: boolean;

  isHybrid: boolean;

  showSession: boolean;

  currentPrivilege: CurrentPrivilege;

  seatingTypeName: string;

  category: string;
  dietaryReq: string;
  specialReq: string;
  session: string;

  /**
   * Open from guest group modal
   */
  private groupModal: boolean;
  /**
   * Open from seating detail modal
   */
  private seatingDetail: boolean;
  /**
   * Guest list subscription
   */
  private guestListSubscription: Subscription;
  /**
   * Group list subscription
   */
  private groupListSubscription: Subscription;
  /**
   * Invited by subscription
   */
  private invitedBySubscription: Subscription;
  /**
   * Check-in setting subscription
   */
  private checkinSettingSubscription: Subscription;

  private privilegeSubscription: Subscription;

  /**
   * Constructor
   * @param formBuilder form builder
   * @param platform platform
   * @param actionSheetController action sheet controller
   * @param modalController modal controller
   * @param translate translate service
   * @param localityService locality service
   * @param invitedByService invited by service
   * @param guestListService guest list service
   * @param guestManageService guest manage service
   * @param guestDeleteService guest delete service
   * @param groupService guest group service
   * @param seatingService seating service
   * @param popupService popup service
   * @param functionService function service
   */
  constructor(
    private platform: Platform,
    private formBuilder: FormBuilder,
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private translate: TranslateService,
    private settingFieldService: SettingFieldService,
    private invitedByService: InvitedByService,
    private moduleService: ModuleService,
    private privilegeService: PrivilegeService,
    private guestService: GuestService,
    private guestManageService: GuestManageService,
    private guestDeleteService: GuestDeleteService,
    private seatingSettingService: SeatingSettingService,
    private sessionService: SessionService,
    private groupService: GroupService,
    private giftService: GiftService,
    private seatingService: SeatingService,
    private checkinSettingService: CheckinSettingService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.unwatch();
  }

  /**
   * Before view enter
   */
  ionViewWillEnter() {
    if (!this.module) {
      this.module = this.moduleService.currentModule;
    }
    if (this.sessionService?.list?.length > 1) {
      this.showSession = true;
    } else {
      this.showSession = false;
    }

    this.seatingTypeName = this.getSeatingTypeName();
    
    this.watch();
    this.setupForm();
    this.isHybrid = this.platform.is('hybrid');
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatch();
  }

  watch() {
    if (!this.bulkEditMode) {
      this.watchGuest();
      this.watchGroupList();
    }
    this.watchInvitedBy();
    if (this.module === 'checkin') {
      this.watchCheckinSetting();
    }
    this.watchPrivilege();
  }

  unwatch() {
    this.unwatchGuest();
    this.unwatchGroupList();
    this.unwatchInvitedBy();
    this.unwatchCheckinSetting();
    this.unwatchPrivilege();
  }

  /**
   * Watch invited by list
   */
  async watchInvitedBy() {
    if (!this.invitedBySubscription) {
      this.invitedBySubscription = this.invitedByService.observableInvitedbyList.subscribe((invitedByList: SettingField[]) => {
        this.invitedByList = this.invitedByService.getInvitedByList();
        this.setupInvitedByValidation();
      });
    }
    
  }

  /**
   * Unwatch invited by list
   */
  async unwatchInvitedBy() {
    if (this.invitedBySubscription) {
      this.invitedBySubscription.unsubscribe();
      this.invitedBySubscription = null;
    }
  }

  /**
   * Watch guest list changes
   */
  async watchGuest() {
    if (!this.guestListSubscription) {
      this.guestListSubscription = this.guestService.observableGuestList.subscribe(() => {
        this.setupGuest();
      });
    }
   
  }

  /**
   * Unwatch guest list
   */
  async unwatchGuest() {
    if (this.guestListSubscription) {
      this.guestListSubscription.unsubscribe();
      this.guestListSubscription = null;
    }
  }

  /**
   * Watch group list changes
   */
  async watchGroupList() {
    if (!this.groupListSubscription) {
      this.groupListSubscription = this.groupService.observableGroupList.subscribe(() => {
        this.setupGroup();
      });
    }
    
  }

  /**
   * Unwatch group list
   */
  async unwatchGroupList() {
    if (this.groupListSubscription) {
      this.groupListSubscription.unsubscribe();
      this.groupListSubscription = null;
    }
  }

  /**
   * Watch check-in setting
   */
  async watchCheckinSetting() {
    if (!this.checkinSettingSubscription) {
      this.checkinSettingSubscription = this.checkinSettingService.observableCheckinSetting.subscribe((checkinSetting: CheckinSetting) => {
        this.checkinSetting = checkinSetting;
      });
    }
    
  }

  /**
   * Unwatch check-in setting
   */
  async unwatchCheckinSetting() {
    if (this.checkinSettingSubscription) {
      this.checkinSettingSubscription.unsubscribe();
      this.checkinSettingSubscription = null;
    }
  }

  async watchPrivilege() {
    if (!this.privilegeSubscription) {
      this.privilegeSubscription = this.privilegeService.observableCurrentPrivilege.subscribe(() => {
        this.setupPrivilege();
      })
    }
  }

  async unwatchPrivilege() {
    if (this.privilegeSubscription) {
      this.privilegeSubscription.unsubscribe();
      this.privilegeSubscription = null;
    }
  }

  setupPrivilege() {
    if (!this.currentPrivilege) {
      this.currentPrivilege = {};
    }
    if (this.module) {
      this.currentPrivilege = {
        [this.module]: {
          'delete': this.checkPrivilege(this.module, 'delete'),
        }
      };
    }
  }

  setupSettingField() {
    this.category = this.getSettingField('category', this.form.value.category);
    this.dietaryReq = this.getSettingField('dietary_req', this.form.value.dietaryReq);
    this.specialReq = this.getSettingField('special_req', this.form.value.specialReq);
    this.session = this.getSettingField('session', this.form.value.session);
  }

  /**
   * Setup form
   */
  setupForm() {
    this.form = this.formBuilder.group({
      name: new FormControl('', [ !this.bulkEditMode ? Validators.required : Validators.nullValidator ]),
      nickname:  new FormControl('', []),
      invitedBy: new FormControl('', [ !this.bulkEditMode ? Validators.required : Validators.nullValidator ]),
      category: new FormControl('', [ !this.bulkEditMode ? Validators.required : Validators.nullValidator ]),
      seating: new FormControl(''),
      email: new FormControl('', [ Validators.email ]),
      dietaryReq: new FormControl('', [ !this.bulkEditMode ? Validators.required : Validators.nullValidator ]),
      specialReq: new FormControl('', [ !this.bulkEditMode ? Validators.required : Validators.nullValidator ]),
      session: new FormControl('', []),
      remark: new FormControl(''),
      attendingStatus: new FormControl('', [ !this.bulkEditMode ? Validators.required : Validators.nullValidator ]),
      checkinStatus: new FormControl(!this.bulkEditMode && this.module === 'checkin' ? false : null),
      giftStatus: new FormControl(!this.bulkEditMode && this.module === 'checkin' && this.checkinSetting?.giftRecord && !this.checkinSetting?.giftDetail ? false : null)
    });

    this.validationMsg = {
      pax: [
        {type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('GUEST.lbl.pax') }) },
        {type: 'minLength', msg: this.translate.instant('GUEST.validation.pax.minLength') }
      ],
      name: [{ type: 'required', msg: this.translate.instant('VALIDATION.required',
        { field: this.translate.instant('GUEST.lbl.guest_name') }) }],
      invitedBy: [{ type: 'required', msg: this.translate.instant('VALIDATION.required',
        { field: this.translate.instant('GUEST.lbl.invited_by') }) }],
      category: [{ type: 'required', msg: this.translate.instant('VALIDATION.required',
        { field: this.translate.instant('LBL.category') }) }],
      attendingStatus: [{ type: 'required', msg: this.translate.instant('VALIDATION.required',
        { field: this.translate.instant('LBL.status') }) }],
      dietaryReq: [{ type: 'required', msg: this.translate.instant('VALIDATION.required',
        { field: this.translate.instant('GUEST.lbl.dietary_req') }) }],
      specialReq: [{ type: 'required', msg: this.translate.instant('VALIDATION.required',
        { field: this.translate.instant('GUEST.lbl.special_req') }) }],
      email: [{ type: 'email', msg: this.translate.instant('VALIDATION.invalid_format',
        { field: this.translate.instant('LBL.email') }) }],
    };
    this.setupInvitedByValidation();
    this.setupFormValue();
  }

  /**
   * Setup invited by validation
   */
  setupInvitedByValidation() {
    if (this.form?.controls?.invitedBy) {
      if (this.invitedByList?.length > 1 && !this.bulkEditMode) {
        this.form.controls.invitedBy.setValidators([ Validators.required ]);
      } else {
        this.form.controls.invitedBy.clearValidators();
      }
      this.form.controls.invitedBy.updateValueAndValidity();
    }
  }

  /**
   * Setup form vale
   */
  setupFormValue(guest?: Guest) {
    if (this.form) {
      if (guest) {
        if (guest.name !== this.guest.name && this.guest.name === this.form.value.name) {
          this.form.controls.name.setValue(guest.name);
        }
        if (guest.nickname !== this.guest.nickname && this.guest.nickname === this.form.value.nickname) {
          this.form.controls.nickname.setValue(guest.nickname);
        }
        if (!this.isEqual(guest.invitedBy, this.guest.invitedBy)
        && this.guest.invitedBy && this.guest.invitedBy[0].value !== this.form.value.invitedBy) {
          this.form.controls.invitedBy.setValue(guest.invitedBy[0].value);
        }
        if (!this.isEqual(guest.category, this.guest.category) && this.isEqual(this.guest.category, this.form.value.category)) {
          this.form.controls.category.setValue(guest.category);
        }
        if (guest.seating !== this.guest.seating && this.guest.seating === this.form.value.seating) {
          this.form.controls.seating.setValue(guest.seating);
        }
        if (guest.email !== this.guest.email && this.guest.email === this.form.value.email) {
          this.form.controls.email.setValue(guest.email);
        }
        if (guest?.mobile?.no !== this.guest?.mobile?.no) {
          const mobile = this.mobile?.no ? this.mobile?.no : '';
          if (this.guest?.mobile?.no === mobile) {
            this.mobile = guest.mobile;
          }
        }
        if (!this.isEqual(guest.dietaryReq, this.guest.dietaryReq) && this.isEqual(this.guest.dietaryReq, this.form.value.dietaryReq)) {
          this.form.controls.dietaryReq.setValue(guest.dietaryReq);
        }
        if (!this.isEqual(guest.specialReq, this.guest.specialReq) && this.isEqual(this.guest.specialReq, this.form.value.specialReq)) {
          this.form.controls.specialReq.setValue(guest.specialReq);
        }
        if (!this.isEqual(guest.session, this.guest.session) && this.isEqual(this.guest.session, this.form.value.session)) {
          this.form.controls.session.setValue(guest.session);
        }
        if (guest.remark !== this.guest.remark && this.guest.remark !== this.form.value.remark) {
          this.form.controls.remark.setValue(guest.remark);
        }
        if (guest?.status?.attending && !this.isEqual(guest.status.attending, this.guest.status.attending)
        && this.isEqual(this.guest.status.attending, this.form.value.attendingStatus)) {
          this.form.controls.attendingStatus.setValue(guest.status.attending);
        }
        if (guest?.status?.checkin && !this.isEqual(guest.status.checkin, this.guest.status.checkin)
        && this.isEqual(this.guest.status.checkin, this.form.value.checkinStatus)) {
          this.form.controls.checkinStatus.setValue(guest.status.checkin);
        }
        if (guest?.status?.gift && !this.isEqual(guest.status.gift, this.guest.status.gift)
        && this.isEqual(this.guest.status.gift, this.form.value.giftStatus)) {
          this.form.controls.giftStatus.setValue(guest.status.gift);
        }
        this.guest = guest;
      } else if (this.guest) {
        this.form.controls.name.setValue(this.guest?.name ? this.guest.name : '');
        this.form.controls.nickname.setValue(this.guest.nickname ? this.guest.nickname : '');
        this.form.controls.invitedBy.setValue(this.guest.invitedBy && this.guest.invitedBy[0].value ? this.guest.invitedBy[0].value : '');
        this.form.controls.category.setValue(this.guest.category ? this.guest.category : '');
        this.form.controls.seating.setValue(this.guest.seating ? this.guest.seating : '');
        this.form.controls.email.setValue(this.guest.email ? this.guest.email : '');
        this.form.controls.dietaryReq.setValue(this.guest.dietaryReq ? this.guest.dietaryReq : '');
        this.form.controls.specialReq.setValue(this.guest.specialReq ? this.guest.specialReq : '');
        this.form.controls.session.setValue(this.guest.session ? this.guest.session : '');
        this.form.controls.remark.setValue(this.guest.remark ? this.guest.remark : '');
        this.form.controls.attendingStatus.setValue(this.guest?.status?.attending ? this.guest.status.attending : '');
        this.form.controls.checkinStatus.setValue(this.guest?.status?.checkin ? true : false);
        this.form.controls.giftStatus.setValue(this.guest?.status?.gift ? true : false);
        if (this.guest?.mobile?.no) {
          this.mobile = this.guest.mobile;
        }
      }
      this.form.updateValueAndValidity();
      this.setupSettingField();
    }
  }

  /**
   * Setup guest
   */
  setupGuest() {
    if (this.guest?.guestId) {
      const guest = this.guestService.getGuest(this.guest.guestId);
      if (!this.isEqual(this.guest, guest)) {
        if (guest?.groupId !== this.guest?.groupId) {
          this.setupGroup();
        }
        this.setupFormValue(guest);
      }
    }
  }

  /**
   * Setup guest group
   */
  setupGroup() {
    if (this.guest?.groupId) {
      const group = this.groupService.getGroup(this.guest.groupId);
      this.group = group;
    }
  }

  updateMobile(mobile: Mobile) {
    this.mobile = mobile;
  }

  updateMobileInvalid(invalid: boolean) {
    this.mobileInvalid = invalid;
  }

  /**
   * Check is two variable is equal
   * @param a variable a
   * @param b variable b
   * @returns true if two varible is equal
   */
  isEqual(a: any, b: any): boolean {
    return this.functionService.isEqual(a, b);
  }

  /**
   * Get gift of guest
   * @returns gift in string
   */
  getGift(): string {
    let result = '';
    if (this.guest?.giftList?.length) {
      const giftList: Gift[] = this.giftService.getGiftListById(this.guest.giftList);
      if (giftList?.length) {
        giftList.sort((a: Gift, b: Gift) => {
          return this.functionService.compare(a.giftType, b.giftType);
        })?.forEach((gift: Gift, index: number) => {
          if (gift.giftType) {
            if (index > 0) {
              result += ', ';
            }
            if (gift.giftType === 'other') {
              result = result + gift.name + ' x ' + gift.qty;
            } else if (gift.giftType === 'cash' && gift.currency) {
              result = result + gift.currency + ' ' + gift.amount;
            }
          }
        });
      }
    }
    return result;
  }

  getSeatingTypeName(): string {
    return this.seatingSettingService.getSeatingTypeName();
  }

  /**
   * Dismiss guest edit modal
   * @param data new data
   * @param dismiss dismiss previous modal
   */
  async dismissModal(data?: any, dismiss?: boolean) {
    if (this.modalController) {
      if (data) {
        Object?.keys(data)?.forEach(key => {
          if (!this.isEqual(data[key], this.guest[key])) {
            this.guest[key] = data[key];
          }
        });
      }
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ guest: data ? this.guest : null, dismiss }); }
    }
  }

  /**
   * Present seating detail modal. Dismiss if parent modal is seating detail modal
   * @param seatingName Seating name
   */
  async presentSeatingDetailModal(seatingName: string) {
    if (this.seatingDetail) {
      this.dismissModal();
    } else {
      if (seatingName) {
        const seating = this.seatingService.getSeatingByName(seatingName);
        const modal = await this.modalController.create({
          component: SeatingDetailComponent,
          cssClass: '',
          componentProps: {
            seating,
            guest: this.guest
          }
        });
        modal.present();
      } else {
        this.presentSeatingListModal(true);
      }
    }
  }

  /**
   * Present seating list modal for assign
   * @param assignMode assign mode
   */
  async presentSeatingListModal(assignMode: boolean) {
    // const excludeSeatingList: Seating[] = [];
    // if (this.guest && this.guest.seating) {
    //   const seating: Seating = this.seatingService.getSeatingByName(this.guest.seating);
    //   excludeSeatingList.push(seating);
    // }
    const modal = await this.modalController.create({
      component: SeatingListComponent,
      cssClass: '',
      componentProps: {
        assignMode,
        newGuestList: [ this.guest ],
        // excludeSeatingList
      }
    });
    modal.present();
  }

  /**
   * Present guest group modal. Dismiss current modal if parent modal is guest group modal
   * @param group Group
   */
  async presentGroupModal(group: Group) {
    if (!this.groupModal) {
      const modal = await this.modalController.create({
        component: GuestGroupComponent,
        cssClass: '',
        componentProps: {
          group,
          guest: this.guest
        }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result && result.data && result.data.guest as Guest) {
          this.setupFormValue(result.data.guest);
        }
      });
    } else {
      this.dismissModal(null, true);
    }
  }

  /**
   * Present setting field cmodal
   * @param settingFieldType Setting field type
   * @param selected Selected setting field
   */
   async presentSettingFieldModal(settingFieldType: SettingFieldType, selected: SettingField[]) {
    const modal = await this.modalController.create({
      component: SettingFieldComponent,
      componentProps: {
        settingFieldType,
        selected
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.selected) {
        if (settingFieldType === 'category') {
          if (this.form.value.category !== result.data.selected) {
            this.form.controls.category.setValue(result.data.selected);
            this.setupSettingField();
          }
        } else if (settingFieldType === 'dietary_req') {
          if (this.form.value.dietaryReq !== result.data.selected) {
            this.form.controls.dietaryReq.setValue(result.data.selected);
            this.setupSettingField();
          }
        } else if (settingFieldType === 'special_req') {
          if (this.form.value.specialReq !== result.data.selected) {
            this.form.controls.specialReq.setValue(result.data.selected);
            this.setupSettingField();
          }
        } else if (settingFieldType === 'session') {
          if (this.form.value.session !== result.data.selected) {
            this.form.controls.session.setValue(result.data.selected);
            this.setupSettingField();
          }
        }
      }
    });
  }

  async presentReadContactsModal() {
    const modal = await this.modalController.create({
      component: ReadContactsComponent,
      cssClass: '',
      componentProps: {
        editMode: true,
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.mobile?.no) {
        this.mobile = result.data.mobile;
      }
      if (result?.data?.email && this.functionService.validateEmail(result.data.email)) {
        this.form.controls?.email.setValue(result.data.email);
      }
    });
  }

  /**
   * Confirm delete guest modal
   * @param guest guest
   * @param ionSlide ion slide
   */
  async promptRemove(guest?: Guest, ionSlide?: any) {
    if (guest?.guestId && guest?.name) {
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_field', {
          field: guest.name
        })
      );
      modal.onDidDismiss().then((result: any) => {
        if (result?.data?.confirm) {
          this.removeGuest(guest);
        }
      });
    }
    if (ionSlide) { ionSlide.close(); }
  }

  /**
   * Discard changes, prompt confirm action sheet if changes detect
   */
  async discard() {
    let flag = true;
    if (this.guest) {
      Object.keys(this.form?.value)?.forEach((key: string) => {
        if (key === 'invitedBy') {
          if (this.form.value.invitedBy !== this.guest.invitedBy[0].value) {
            flag = false;
          }
        } else if (key === 'mobile') {
          let mobile = '';
          if (this.mobile?.no) {
            mobile = this.mobile.no;
          }
          if (this.guest.mobile.no !== mobile) {
            flag = false;
          }
        } else if (key === 'attendingStatus') {
          if (this.form.value.attendingStatus !== this.guest.status.attending) {
            flag = false;
          }
        } else if (key === 'checkinStatus') {
          if (this.form.value.checkinStatus !== this.guest.status.checkin) {
            flag = false;
          }
        } else if (key === 'giftStatus') {
          if (this.form.value.giftStatus !== this.guest.status.gift) {
            flag = false;
          }
        } else if (!this.isEqual(this.form.value[key], this.guest[key])) {
          flag = false;
        }
      });
    }

    if (!flag) {
      const actionSheet = await this.actionSheetController.create({
        header: this.translate.instant('MSG.discard_msg'),
        buttons: [{
          text: this.translate.instant('BTN.confirm'),
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            this.dismissModal();
          }
        }, {
          text: this.translate.instant('BTN.cancel'),
          icon: 'close',
          role: 'cancel',
          handler: () => {
          }
        }]
      });
      actionSheet.present();
    }  else {
      this.dismissModal();
    }
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[]): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList);
  }

  /**
   * Check user privilege
   * @param action Action
   */
  checkPrivilege(module: ModuleType, action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege(module, action);
  }

  /**
   * Guest edit form submit
   */
  async guestEditFormSubmit() {
    this.form.markAllAsTouched();
    if (this.form.valid && !this.mobileInvalid) {
      await this.popupService.presentLoading();
      const invitedByIndex = this.invitedByList?.findIndex(x => x.value === this.form.value.invitedBy);

      const data: any = {};
      Object.keys(this.form?.value)?.forEach((key: string) => {
        if (this.bulkEditMode) {
          if (key !== 'name' && key !== 'nickname' && key !== 'seating') {
            if (key === 'invitedBy') {
              if (invitedByIndex !== -1) {
                data.invitedBy = [ this.invitedByList[invitedByIndex] ];
              }
            } else if (key === 'attendingStatus') {
              if (this.form.value.attendingStatus) {
                if (!data.status) { data.status = {}; }
                data.status.attending = this.form.value.attendingStatus;
              }
            } else if (key === 'checkinStatus') {
              if (!this.functionService.isUndefined(this.form.value.checkinStatus)) {
                if (!data.status) { data.status = {}; }
                data.status.checkin = this.form.value.checkinStatus;
              }
            } else if (key === 'giftStatus') {
              if (!this.functionService.isUndefined(this.form.value.giftStatus)) {
                if (!data.status) { data.status = {}; }
                data.status.gift = this.form.value.giftStatus;
              }
            } else if (this.form.value[key]) {
              data[key] = this.form.value[key];
            }
          }
        } else {
          if (key === 'invitedBy') {
            if (invitedByIndex !== -1) {
              const invitedBy: SettingField[] = [ this.invitedByList[invitedByIndex] ];
              if (!this.isEqual(invitedBy, this.guest.invitedBy)) {
                data.invitedBy = invitedBy;
              }
            }
          } else if (key === 'attendingStatus') {
            if (this.form.value.attendingStatus !== this.guest.status.attending) {
              if (!data.status) { data.status = {}; }
              data.status.attending = this.form.value.attendingStatus;
            }
          } else if (key === 'checkinStatus') {
            if (this.form.value.checkinStatus !== this.guest.status.checkin) {
              if (!data.status) { data.status = {}; }
              data.status.attending = this.form.value.attendingStatus;
            }
          } else if (key === 'giftStatus') {
            if (this.form.value.giftStatus !== this.guest.status.gift) {
              if (!data.status) { data.status = {}; }
              data.status.gift = this.form.value.giftStatus;
            }
          } else if (!this.isEqual(this.form.value?.[key], this.guest?.[key])) {
            data[key] = this.form.value[key];
          }
        }
      });
      
      if (this.bulkEditMode) {
        if (this.mobile?.no) {
          data.mobile = this.mobile;
        }
      } else {
        if (this.mobile.no !== this.guest.mobile?.no || this.mobile.code !== this.guest.mobile?.code) {
          data.mobile = this.mobile;
        }
      }
      await this.prepareSave(data);
    }
  }

  /**
   * Prepare save
   * @param data data
   */
  async prepareSave(data: any) {
    if (this.bulkEditMode) {
      if (!this.functionService.isEmpty(data) && this.selectedGuestList?.length) {
        await this.guestManageService.updateGuest(data, this.selectedGuestList, '', false, true);
        this.saveDone();
      }
    } else {
      if (this.guest?.guestId && !this.functionService.isEmpty(data)) {
        if (this.guest?.groupId) {
          let flag = false;
          Object.keys(data)?.forEach((key: string) => {
            if (key !== 'name' && key !== 'nickname') {
              flag = true;
            }
          });
          if (flag) {
            await this.guestManageService.updateGuest(data, [ this.guest.guestId ], '', false, true);
          } else {
            await this.guestManageService.saveGuest(data, [ this.guest.guestId ]);
          }
          this.saveDone(data);
        } else {
          await this.guestManageService.saveGuest(data, [ this.guest.guestId ]);
          this.saveDone(data);
        }
      } else {
        this.saveDone(data);
      }
    }
  }

  /**
   * Save done, dismiss modal and present success toast msg.
   * @param data new data to be update
   */
  async saveDone(data?: any) {
    await this.popupService.dismissLoading();
    if (data) {
      await this.dismissModal(data);
    }
    this.popupService.saveSuccessToast();
  }

  /**
   * Save delete guest
   * @param guest guest
   */
  async removeGuest(guest?: Guest) {
    if (guest?.guestId) {
      await this.popupService.presentLoading();
      await this.guestDeleteService.removeGuestList([ guest.guestId ]);
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
      this.dismissModal(null, true);
    }
  }

}
