import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { InboxRtdbUrl } from 'src/app/commons/db';

@Injectable({
  providedIn: 'root'
})
export class InboxDbService implements OnInit, OnDestroy {

  constructor(
    private db: AngularFireDatabase,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  getDb() {
    return this.db.database.app.database(InboxRtdbUrl);
  }

  getInboxDbRef(uid: string, inboxId?: string) {
    if (uid) {
      const db = this.getDb();
      if (inboxId) {
        return db.ref(`inbox/${ uid }/${ inboxId }`);
      } else {
        return db.ref(`inbox/${ uid }`);
      }
    }
    return null;
  }

  getReleaseNoteDbRef(inboxId?: string) {
    const db = this.getDb();
    if (inboxId) {
      return db.ref(`releaseNote/${ inboxId }`);
    } else {
      return db.ref(`releaseNote`);
    }
  }

  getReleaseNoteStatusDbRef(uid: string, inboxId?: string) {
    if (uid) {
      const db = this.getDb();
      if (inboxId) {
        return db.ref(`releaseNoteStatus/${ uid }/${ inboxId }`);
      } else {
        return db.ref(`releaseNoteStatus/${ uid }`);
      }
    }
    return null;
  }
}
