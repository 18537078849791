import { FunctionService } from 'src/app/services/general/function.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, Platform } from '@ionic/angular';

import { GiftService } from 'src/app/services/gift/gift.service';
import { GiftManageService } from 'src/app/services/gift/gift-manage.service';
import { CurrencyService } from 'src/app/services/general/currency.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { Currency } from 'src/app/interfaces/database';
import { Guest } from 'src/app/interfaces/guest';
import { Group } from 'src/app/interfaces/group';
import { Gift } from 'src/app/interfaces/gift';
import { GiftType } from 'src/app/types/gift';

import { SearchSelectListComponent } from 'src/app/components/general/search-select-list/search-select-list.component';
import { KeyboardService } from 'src/app/services/general/keyboard.service';

/**
 * Gift prompt component
 */
@Component({
  selector: 'app-gift-prompt',
  templateUrl: './gift-prompt.component.html',
  styleUrls: ['./gift-prompt.component.scss'],
})
export class GiftPromptComponent implements OnInit, OnDestroy {

  /**
   * Currency ionic selectable viewchild
   */
  // @ViewChild('currencyIonicSelect', { static: false }) currencyIonicSelect: IonicSelectableComponent;

  /**
   * Setup
   */
  step: number;
  /**
   * Gift Type
   */
  giftType: GiftType;
  /**
   * Gift
   */
  gift: Gift;
  /**
   * Currency list
   */
  // currencyList: Currency[] = this.currencyService.currencyList;
  /**
   * Form
   */
  form: FormGroup;
  /**
   * Validation msg
   */
  validationMsg: any;
  /**
   * Selected currency
   */
  private selectedCurrency: Currency;
  /**
   * Guest
   */
  private guest: Guest;
  /**
   * Group
   */
  private group: Group;

  /**
   * Constructor
   * @param formBuilder Form builder
   * @param modalController modal controller
   * @param translate translate service
   * @param currencyService currency service
   * @param giftService gift service
   * @param giftManageService gift manage service
   * @param popupService popup service
   */
  constructor(
    private platform: Platform,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private translate: TranslateService,
    private currencyService: CurrencyService,
    private giftService: GiftService,
    private giftManageService: GiftManageService,
    private functionService: FunctionService,
    private popupService: PopupService,
    private keyboardService: KeyboardService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Before view enter
   */
  async ionViewWillEnter() {
    if (!this.step) { this.step = 1; }
    if (this.gift?.giftType) { this.giftType = this.gift.giftType; }
    this.selectedCurrency = this.currencyService.getAccountCurrency();
  }

  /**
   * Setup form
   */
  setupForm() {
    this.form = this.formBuilder.group({
      name: new FormControl('', [ this.giftType === 'other' ? Validators.required : Validators.nullValidator ]),
      qty: new FormControl(1, [ Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1) ]),
      amount: new FormControl(0, [ Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(0) ]),
      currency: new FormControl(this.selectedCurrency?.code ? this.selectedCurrency.code : 'SGD', [ Validators.required ])
    });
    this.setupValidationMsg();
    this.setupAmountValidator();
    this.setupFormData();
  }

  /**
   * Setup validation msg
   */
  setupValidationMsg() {
    this.validationMsg = {
      amount: [
        { type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('LBL.amount') }) },
        { type: 'pattern', msg: this.translate.instant('VALIDATION.currency') },
        {type: 'min', msg: this.translate.instant('VALIDATION.min_number', { number: 0 }) },
      ],
      currency: [
        { type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('LBL.currency') }) }
      ],
      name: [
        { type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('GIFT.lbl.name') }) }
      ],
      qty: [
        { type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('LBL.qty') }) },
        { type: 'pattern', msg: this.translate.instant('VALIDATION.number_only') },
        {type: 'min', msg: this.translate.instant('VALIDATION.min_number', { number: 1 }) },
      ],
    };
  }

  /**
   * Setup form data
   */
  setupFormData() {
    if (this.gift?.giftId) {
      if (this.gift?.name) {
        this.form.controls.name.setValue(this.gift.name);
      }
      if (this.gift?.qty) {
        this.form.controls.qty.setValue(this.gift.qty);
      }
      if (this.gift?.currency) {
        this.form.controls.currency.setValue(this.gift.currency);
      }
      if (this.gift?.amount) {
        this.form.controls.amount.setValue(this.gift.amount);
      }
    }
  }

  /**
   * Dismiss modal
   * @param gift gift
   */
  async dismissModal(gift?: Gift) {
    if (this.modalController) {
      if (this.platform.is('hybrid')) {
        await this.keyboardService.hide();
        if (this.platform.is('ios')) {
          await this.functionService.delay(200);
        }
      }
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ gift }); }
    }
  }

  /**
   * Next step
   */
  next() {
    if (this.giftType) {
      this.setupForm();
      this.step = 2;
    }
  }

  /**
   * Set gift type
   * @param type gift type
   */
  setGiftType(giftType: GiftType) {
    this.giftType = giftType;
  }

  /**
   * Get gift data
   * @returns Gift
   */
  getGiftData(): Gift {
    return {
      giftId: this.gift?.giftId ? this.gift.giftId : this.giftService.getNewGiftId(),
      giftType: this.giftType,
      name: this.form?.value?.name ? this.form.value.name : '',
      qty: this.form?.value?.qty ? this.form.value.qty : 1,
      currency: this.form?.value?.currency ? this.form.value.currency : '',
      amount: this.form?.value?.amount ? this.form.value.amount : 0,
      guestIdList: this.gift?.guestIdList ? this.gift.guestIdList : this.guest?.guestId ? [ this.guest.guestId ] : [],
      groupIdList: this.gift?.groupIdList ? this.gift.groupIdList : this.group?.groupId ? [ this.group.groupId ] : [],
      delete: this.gift?.delete ? true : false,
      createBy: this.gift?.createBy ? this.gift.createBy : null,
      updateBy: this.gift?.updateBy ? this.gift.updateBy : null
    };
  }

  /**
   * Add gift qty
   */
  addQty() {
    if (this.form.value?.qty) {
      this.form.controls.qty.setValue(this.form.value.qty + 1);
    } else {
      this.form.controls.qty.setValue(1);
    }
  }

  /**
   * Minus gift qty
   */
  minusQty() {
    if (this.form.value?.qty > 1) {
      this.form.controls.qty.setValue(this.form.value.qty - 1);
    } else {
      this.popupService.presentToast(this.translate.instant('VALIDATION.min_number', { number: 1 }), 'warning');
    }
  }

  /**
   * Check amount
   */
  checkAmount() {
    this.form.controls.amount.setValue(Number(this.form.value.amount));
  }

  /**
   * Open currency
   */
  async openCurrency() {
    const modal = await this.modalController.create({
      component: SearchSelectListComponent,
      componentProps: {
        items: this.currencyService.getCurrencyList(),
        selected: this.selectedCurrency,
        title: this.translate.instant('LBL.currency'),
        itemTextField: 'label',
        placeholder: this.translate.instant('BTN.search'),
        closeButtonText: this.translate.instant('BTN.cancel'),
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.item) {
        this.currencyChange(result.data.item);
      }
    });
  }

  /**
   * Currency change
   * @param event currency data
   */
  currencyChange(currency: Currency) {
    if (currency?.code) {
      this.form.controls.currency.setValue(currency.code);
      if (this.selectedCurrency?.code !== currency.code) {
        this.selectedCurrency = currency;
        this.setupAmountValidator();
      }
    }
  }

  /**
   * Setup amount validator
   */
  setupAmountValidator() {
    if (this.selectedCurrency) {
      let currencyValidator = Validators.pattern('^[0-9]*$');
      if (this.selectedCurrency?.decimalDigits >= 1) {
        currencyValidator = Validators.pattern('^[0-9]+(.[0-9]{0,' + this.selectedCurrency.decimalDigits + '})?$');
      }
      this.form.controls.amount.setValidators([ currencyValidator, Validators.required, Validators.min(0) ]);
      this.form.controls.amount.updateValueAndValidity();
    }
  }

  /**
   * Form submit
   */
  formSubmit() {
    this.form.markAllAsTouched();
    if (this.form.valid && this.giftType) {
      if (this.giftType === 'cash' && !this.form.value.amount) {
        this.form.controls.amount.setErrors({ required: true });
      } else {
        const gift: Gift = this.getGiftData();
        this.giftManageService.saveGiftList([ gift.giftId ], null, [ gift ], 'new');
        this.dismissModal(gift);
      }
    }
  }

}
