import { ModalController } from '@ionic/angular';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AccountInfo, AccountUser } from 'src/app/interfaces/account';
import { AccountsSetupComponent } from 'src/app/components/accounts/accounts-setup/accounts-setup.component';
import { AccountsSetupStep } from 'src/app/types/accounts';
import { InvitedByService } from '../setting/invited-by.service';
import { CategoryService } from '../setting/category.service';
import { DietaryReqService } from '../setting/dietary-req.service';
import { SpecialReqService } from '../setting/special-req.service';

@Injectable({
  providedIn: 'root'
})
export class AccountPromptService implements OnInit, OnDestroy {

  private modal: HTMLIonModalElement;
  private accountInfo: AccountInfo;
  private currentUser: AccountUser;

  constructor(
    private modalController: ModalController,
    private invitedByService: InvitedByService,
    private categoryService: CategoryService,
    private dietaryReqService: DietaryReqService,
    private specialReqService: SpecialReqService,
  ) {
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  setupAccountInfo(accountInfo: AccountInfo) {
    this.accountInfo = accountInfo;
    if (this.checkPrompt()) {
      this.setupPrompt();
    }
  }

  setupCurrentUser(currentUser: AccountUser) {
    this.currentUser = currentUser;
    if (this.checkPrompt()) {
      this.setupPrompt();
    }
  }

  checkPrompt() {
    if (this.accountInfo?.accountId && (this.currentUser?.owner || this.currentUser?.role?.coupleId > 0)) {
      return true;
    }
    return false;
  }

  setupPrompt() {
    if (!this.accountInfo?.title?.value || (!this.accountInfo?.eventMode && (!this.accountInfo?.coupleList?.length || !this.accountInfo?.coupleList?.[0]?.name || !this.accountInfo?.coupleList?.[1]?.name)) || (this.accountInfo?.eventMode && !this.accountInfo?.organizer)) {
      this.presentAccountSetupModal('couple');
    } else if (!this.currentUser?.role?.coupleId && !this.currentUser?.role?.type) {
      this.presentAccountSetupModal('role');
    } else if (!this.accountInfo?.location?.locality?.country || !this.accountInfo?.timezone?.utc || !this.accountInfo?.time?.seconds) {
      this.presentAccountSetupModal('account');
    } else if (!this.invitedByService.getInvitedByList()?.length || !this.categoryService.getCategoryList()?.length) {
      this.presentAccountSetupModal('category');
    } else if (!this.dietaryReqService.getDietaryReqList()?.length || !this.specialReqService.getSpecialReqList()?.length) {
      this.presentAccountSetupModal('dietary');
    }
  }

  async presentAccountSetupModal(type: AccountsSetupStep) {
    if (!this.modal && type && this.checkPrompt()) {
      const migrate = this.accountInfo?.accountStatus?.migrate && !this.accountInfo?.coupleList?.length ? true : false;
      this.modal = await this.modalController.create({
        component: AccountsSetupComponent,
        backdropDismiss: false,
        componentProps: {
          premium: this.accountInfo?.accountStatus?.premium ? true : false,
          type,
          migrate
        }
      });
      this.modal.present();
    }
  }
}
