import { Theme } from '../interfaces/general';

/**
 * Preloaded standard couple list
 */
export const ThemeList: Theme[] = [
    {
        themeId: '1.1',
        backgroundId: '1',
        order: 1
    },
    {
        themeId: '1.2',
        backgroundId: '1',
        order: 2
    },
    {
        themeId: '1.3',
        backgroundId: '1',
        order: 3
    },
    {
        themeId: '2.1',
        backgroundId: '2',
        order: 4
    },
    {
        themeId: '2.2',
        backgroundId: '2',
        order: 5
    },
    {
        themeId: '2.3',
        backgroundId: '2',
        order: 6
    },
    {
        themeId: '3.1',
        backgroundId: '3',
        order: 7
    },
    {
        themeId: '3.2',
        backgroundId: '3',
        order: 8
    },
    {
        themeId: '3.3',
        backgroundId: '3',
        order: 9
    },
    {
        themeId: '4.1',
        backgroundId: '4',
        order: 9
    },
    {
        themeId: '4.2',
        backgroundId: '4',
        order: 10
    },
    {
        themeId: '5.1',
        backgroundId: '5',
        order: 11
    },
    {
        themeId: '5.2',
        backgroundId: '5',
        order: 12
    },
    {
        themeId: '6.1',
        backgroundId: '6',
        order: 13
    },
    {
        themeId: '7.1',
        backgroundId: '7',
        order: 14
    },
    {
        themeId: '8.1',
        backgroundId: '8',
        order: 15
    },
    {
        themeId: '8.2',
        backgroundId: '8',
        order: 16
    },
    {
        themeId: '8.3',
        backgroundId: '8',
        order: 17
    },
    {
        themeId: '9.1',
        backgroundId: '9',
        order: 18
    },
    {
        themeId: '9.2',
        backgroundId: '9',
        order: 19
    },
    {
        themeId: '9.3',
        backgroundId: '9',
        order: 20
    },
    {
        themeId: '10.1',
        backgroundId: '10',
        order: 21
    },
    {
        themeId: '10.2',
        backgroundId: '10',
        order: 22
    },
    {
        themeId: '10.3',
        backgroundId: '10',
        order: 23
    },
    {
        themeId: '11.1',
        backgroundId: '11',
        order: 24
    },
    {
        themeId: '11.2',
        backgroundId: '11',
        order: 25
    },
    {
        themeId: '11.3',
        backgroundId: '11',
        order: 26
    },
    {
        themeId: '12.1',
        backgroundId: '12',
        order: 27
    },
    {
        themeId: '12.2',
        backgroundId: '12',
        order: 28
    },
    {
        themeId: '12.3',
        backgroundId: '12',
        order: 29
    },
    {
        themeId: '13.1',
        backgroundId: '13',
        order: 27
    },
    {
        themeId: '13.2',
        backgroundId: '13',
        order: 28
    },
    {
        themeId: '13.3',
        backgroundId: '13',
        order: 29
    },
    {
        themeId: '14.1',
        backgroundId: '14',
        order: 30
    },
    {
        themeId: '14.2',
        backgroundId: '14',
        order: 31
    },
    {
        themeId: '14.3',
        backgroundId: '14',
        order: 32
    },
    {
        themeId: '15.1',
        backgroundId: '15',
        order: 33
    },
    {
        themeId: '15.2',
        backgroundId: '15',
        order: 34
    },
    {
        themeId: '15.3',
        backgroundId: '14',
        order: 35
    },
    {
        themeId: '16.1',
        backgroundId: '16',
        order: 36
    },
    {
        themeId: '16.2',
        backgroundId: '16',
        order: 37
    },
    {
        themeId: '16.3',
        backgroundId: '16',
        order: 38
    },
    {
        themeId: '17.1',
        backgroundId: '16',
        order: 39
    },
    {
        themeId: '17.2',
        backgroundId: '16',
        order: 40
    },
    {
        themeId: '17.3',
        backgroundId: '16',
        order: 41
    },
    {
        themeId: '18.1.1',
        backgroundId: '18',
        order: 42
    },
    {
        themeId: '18.1.2',
        backgroundId: '18',
        order: 43
    },
    {
        themeId: '18.1.3',
        backgroundId: '18',
        order: 44
    },
    {
        themeId: '18.2.1',
        backgroundId: '18',
        order: 45
    },
    {
        themeId: '18.2.2',
        backgroundId: '18',
        order: 46
    },
    {
        themeId: '18.2.3',
        backgroundId: '18',
        order: 47
    },
    {
        themeId: '19.1.1',
        backgroundId: '19',
        order: 48
    },
    {
        themeId: '19.1.2',
        backgroundId: '19',
        order: 49
    },
    {
        themeId: '19.1.3',
        backgroundId: '19',
        order: 50
    },
    {
        themeId: '19.2.1',
        backgroundId: '19',
        order: 51
    },
    {
        themeId: '19.2.2',
        backgroundId: '19',
        order: 52
    },
    {
        themeId: '19.2.3',
        backgroundId: '19',
        order: 53
    },
    {
        themeId: '20.1.1',
        backgroundId: '20',
        order: 54
    },
    {
        themeId: '20.1.2',
        backgroundId: '20',
        order: 55
    },
    {
        themeId: '20.1.3',
        backgroundId: '20',
        order: 56
    },
    {
        themeId: '20.2.1',
        backgroundId: '20',
        order: 57
    },
    {
        themeId: '20.2.2',
        backgroundId: '20',
        order: 58
    },
    {
        themeId: '20.2.3',
        backgroundId: '20',
        order: 59
    },
    {
        themeId: '21.1.1',
        backgroundId: '21',
        order: 60
    },
    {
        themeId: '21.1.2',
        backgroundId: '21',
        order: 61
    },
    {
        themeId: '21.1.3',
        backgroundId: '21',
        order: 62
    },
    {
        themeId: '21.2.1',
        backgroundId: '21',
        order: 63
    },
    {
        themeId: '21.2.2',
        backgroundId: '21',
        order: 64
    },
    {
        themeId: '21.2.3',
        backgroundId: '21',
        order: 65
    },
    {
        themeId: '22.1.1',
        backgroundId: '22',
        order: 66
    },
    {
        themeId: '22.1.2',
        backgroundId: '22',
        order: 67
    },
    {
        themeId: '22.1.3',
        backgroundId: '22',
        order: 68
    },
    {
        themeId: '22.2.1',
        backgroundId: '22',
        order: 69
    },
    {
        themeId: '22.2.2',
        backgroundId: '22',
        order: 70
    },
    {
        themeId: '22.2.3',
        backgroundId: '22',
        order: 71
    }
];
