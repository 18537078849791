import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VisitorComponentsModule } from 'src/app/components/visitor/visitor.components.module';
import { SettingVisitorComponent } from 'src/app/components/setting/setting-visitor/setting-visitor.component';

import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TranslateModule } from '@ngx-translate/core';
import { OfflineComponentsModule } from 'src/app/components/general/offline/offline.components.module';
// import { SwiperModule } from 'swiper/angular';
import { CachedImageComponentsModule } from 'src/app/components/general/cached-image/cached-image.components.module';
// import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { SettingSeatingComponent } from './setting-seating/setting-seating.component';
import { SettingVisitorWizardComponent } from './setting-visitor-wizard/setting-visitor-wizard.component';
import { SettingVisitorThemeComponent } from './setting-visitor-theme/setting-visitor-theme.component';

@NgModule({
    declarations: [
        SettingVisitorComponent,
        SettingVisitorWizardComponent,
        SettingVisitorThemeComponent,
        SettingSeatingComponent,
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        // SwiperModule,
        // NgxQRCodeModule,
        OfflineComponentsModule,
        VisitorComponentsModule,
        CachedImageComponentsModule,
        ScrollingModule,
    ],
    exports: [
        SettingVisitorComponent,
        SettingVisitorWizardComponent,
        SettingVisitorThemeComponent,
        SettingSeatingComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
})
export class SettingComponentsModule { }
