import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ErrorService } from 'src/app/services/general/error.service';

import { Preferences } from '@capacitor/preferences';


/**
 * Preferences Service.
 * Store / get storage data.
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService implements OnInit, OnDestroy {

  /**
   * Constructor
   * @param errorService Error Service
   */
  constructor(
    // private platform: Platform,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  // async migrate() {
  //   await this.platform.ready();
  //   await Preferences.migrate();
  //   await Preferences.removeOld();
  // }

  /**
   * Set data
   * @param key Key
   * @param value Value
   */
  async set(key: string, value: any) {
    if (key) {
      try {
        await Preferences.set({key, value});
        return true;
      } catch (err) {
        this.errorService.logError(err);
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * Get data
   * @param key Key
   */
  async get(key: string): Promise<any> {
    if (key) {
      const { value } = await Preferences.get({ key });
      return value;
    } else {
      return null;
    }
  }

  /**
   * Set object
   * @param key key
   * @param obj obj
   */
  async setObject(key: string, obj: any) {
    if (key) {
      try {
        if (obj && obj !== null) {
          let value = obj;
          value = JSON.stringify(value);
          // if (typeof obj === 'object') {
          //   value = JSON.stringify(value);
          // }
          await Preferences.set({key, value});
        }
        return true;
      } catch (err) {
        this.errorService.logError(err);
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * Get Object
   * @param key Key
   */
  async getObject(key: string): Promise<any> {
    if (key) {
      try {
        const { value } = await Preferences.get({ key });
        if (value && value !== null) {
          // console.log(typeof value);
          // if (typeof value === 'object') {
          //   return JSON.parse(value);
          // } else {
          //   return value;
          // }
          return JSON.parse(value);
        }
        return null;
      } catch (err) {
        console.error(err);
        return null;
      }
    } else {
      return null;
    }
  }

  /**
   * Logout
   */
  async logout() {
    await this.clearUserData();
    await this.clearAccountData();
  }

  /**
   * Clear user data
   */
  async clearUserData() {
    await this.set('auth_type', '');
    await this.setObject('location_detail', {});
  }

  /**
   * Clear account data
   */
  async clearAccountData() {
    await this.set('account_id', '');
    // await this.setObject('qr_template', null);
  }

  /**
   * Clear data
   */
  async clear() {
    await Preferences.clear();
  }

}
