import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { SupportAdminService } from './support-admin.service';
import { SupportTicket } from 'src/app/interfaces/chat';
import { SupportTicketService } from './support-ticket.service';

@Injectable({
  providedIn: 'root'
})
export class SupportCountService implements OnInit, OnDestroy {

  constructor(
    private supportAdminService: SupportAdminService,
    private supportTicketService: SupportTicketService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  getSupportCount(ticketId?: string) {
    let count = 0;
    if (this.supportAdminService.checkAdmin()) {
      this.supportAdminService.supportTicketList?.forEach((ticket: SupportTicket) => {
        if (ticket?.messages && ticket?.uid && ticket.ticketId && (!ticketId || ticket?.ticketId === ticketId)) {
          Object.keys(ticket.messages)?.forEach((key: string) => {
            if (ticket.messages?.[key]?.createBy?.uid !== 'admin') {
              const msg = ticket.messages?.[key];
              let read = false;
              if (msg.status) {
                Object.keys(msg.status)?.forEach((uid: string) => {
                  if (uid === 'admin') {
                    if (msg.status['admin']?.read || msg.status['admin']?.deleted) {
                      read = true;
                    }
                  }
                });
              }
              if (!read) {
                count += 1;
              }
            }
          })
        }
      });
    } else {
      this.supportTicketService.supportTicketList?.forEach((ticket: SupportTicket) => {
        if (ticket?.messages && ticket?.uid && ticket.ticketId && (!ticketId || ticket?.ticketId === ticketId)) {
          Object.keys(ticket.messages)?.forEach((key: string) => {
            if (ticket.messages?.[key]?.createBy?.uid === 'admin') {
              const msg = ticket.messages?.[key];
              let read = false;
              if (msg.status) {
                Object.keys(msg.status)?.forEach((uid: string) => {
                  if (ticket.uid === uid) {
                    if (msg.status?.[ticket.uid]?.read || msg.status?.[ticket.uid]?.deleted) {
                      read = true;
                    }
                  }
                });
              }
              if (!read) {
                count += 1;
              }
            }
          })
        }
      });
    }
    
    return count;
  }
}
