import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { OfflineIndicatorComponent } from './offline-indicator/offline-indicator.component';
import { OfflinePopoverComponent } from './offline-popover/offline-popover.component';

@NgModule({
    declarations: [
      OfflineIndicatorComponent,
      OfflinePopoverComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
        TranslateModule
    ],
    exports: [
        OfflineIndicatorComponent
    ],
    // entryComponents: [
    //     OfflinePopoverComponent
    // ]
})
export class OfflineComponentsModule { }
