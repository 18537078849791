import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ThemeList } from 'src/app/commons/theme';
import { Theme } from 'src/app/interfaces/general';
import { FunctionService } from 'src/app/services/general/function.service';

@Component({
  selector: 'app-setting-visitor-theme',
  templateUrl: './setting-visitor-theme.component.html',
  styleUrls: ['./setting-visitor-theme.component.scss'],
})
export class SettingVisitorThemeComponent  implements OnInit, OnDestroy {

  @HostListener('window:resize', ['$event'])onResize(event: any) {
    this.setupSlidePerView();
  }
  /**
   * Watch screen orientation change for login slider setup
   */
  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event: any) {
    this.setupSlidePerView();
  }
  
  @ViewChild('swiperGrid', { read: ElementRef, static: false }) swiperGrid: ElementRef;

  @ViewChild('content', { read: ElementRef }) content: ElementRef;

  @Input() pageTheme: string;
  @Output() setPageTheme = new EventEmitter<string>();

  themeList: Theme[] = ThemeList;

  slidePerView: number;

  constructor(
    private functionService: FunctionService,
  ) { }

  ngOnInit() {
    this.setupSlidePerView();
  }

  ngOnDestroy(): void {
      
  }

  setupSlidePerView() {
    if (this.swiperGrid?.nativeElement?.offsetWidth) {
      this.slidePerView = this.getSlidePerView();
    } else {
      setTimeout(() => {
        this.setupSlidePerView();
      }, 500);
    }
  }

  getSlidePerView() {
    const width = this.getContainerWidth();
    const maxWidthPerSlide = 170;
    
    let slidesPerView = 1;
    slidesPerView = Math.floor(width / maxWidthPerSlide);
    if (!slidesPerView || slidesPerView < 1) {
      slidesPerView = 1;
    }
    slidesPerView += 0.5;
    return slidesPerView;
  }

  getContainerWidth() {
    return this.swiperGrid?.nativeElement?.offsetWidth;
  }

  getPreviewImgName(themeId: string) {
    return this.functionService.replaceAll(themeId, '.', '_');
  }

  selectPageTheme(pageTheme: string) {
    this.pageTheme = pageTheme;
    this.setPageTheme.next(this.pageTheme);
  }

}
