import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BlastCreditListComponent } from 'src/app/components/blast/credit/blast-credit-list/blast-credit-list.component';
import { AccountUser } from 'src/app/interfaces/account';
import { BlastCreditType } from 'src/app/types/blast';
import { AccountInfoService } from '../account/account-info.service';
import { AccountPackageService } from '../account/account-package.service';
import { AccountUserService } from '../account/account-user.service';
import { DeviceService } from '../device/device.service';
import { DebugModeService } from '../general/debug-mode.service';
import { ErrorService } from '../general/error.service';
import { LanguageService } from '../general/language.service';
import { PopupService } from '../general/popup.service';
import { TimezoneService } from '../general/timezone.service';
import { LocalityService } from '../location/locality.service';
import { BlastCreditService } from '../blast/blast-credit.service';
import { UpdateByService } from '../user/update-by.service';

@Injectable({
  providedIn: 'root'
})
export class BlastRedeemService implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private fns: AngularFireFunctions,
    private translate: TranslateService,
    private modalController: ModalController,
    private deviceService: DeviceService,
    private languageService: LanguageService,
    private localityService: LocalityService,
    private timezoneService: TimezoneService,
    private debugModeService: DebugModeService,
    private accountInfoService: AccountInfoService,
    private accountPackageService: AccountPackageService,
    private accountUserService: AccountUserService,
    private blastCreditService: BlastCreditService,
    private updateByService: UpdateByService,
    private popupService: PopupService,
    private errorService: ErrorService,
  ) {}

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  async presentBlastCreditListModal(blastCreditType: BlastCreditType) {
    const modal = await this.modalController.create({
      component: BlastCreditListComponent,
      componentProps: {
        blastCreditType
      }
    });
    modal.present();
  }

  validateRedeemBlastCredit() {
    if (this.accountPackageService.accountPackage?.accountStatus?.premium && this.checkAccountUser() && this.checkAccountTime()) {
      const blastCredit = this.blastCreditService.getTotalRedeemBlastCredit();
      if (this.accountPackageService.accountPackage?.partnerId) {
        if (this.checkPartnerAccountCreatedTime()) {
          const blastPartnerCredit = this.blastCreditService.getBlastCreditByType('partner');
          if (!blastPartnerCredit) {
            return true;
          }
        }
      } else if (this.checkAccountCreatedTime()) {
        if (!blastCredit) {
          return true;
        }
      }
    }
    return false;
  }

  checkAccountUser() {
    const accountUser: AccountUser = this.accountUserService.getCurrenctUser();
    if (accountUser?.owner || accountUser?.role?.coupleId > 0) {
      return true;
    }
    return false;
  }

  checkPartnerAccount() {
    if (this.accountPackageService?.accountPackage?.partnerId) {
      return true;
    }
  }

  checkAccountTime() {
    if (this.accountInfoService.accountInfo?.time?.seconds) {
      const now = new Date();
      const time = new Date(this.accountInfoService.accountInfo.time.seconds * 1000);

      if (time > now) {
        return true;
      }
    }
    return false;
  }

  checkAccountCreatedTime() {
    if (this.accountPackageService.accountPackage?.createBy?.time?.seconds) {
      const deadline = new Date('2023-02-12T00:00:00');
      const createdTime = new Date(this.accountPackageService.accountPackage.createBy.time.seconds * 1000);

      if (createdTime < deadline) {
        return true;
      }
    }
    return false;
  }

  checkPartnerAccountCreatedTime() {
    if (this.accountPackageService.accountPackage?.createBy?.time?.seconds) {
      const deadline = new Date('2023-02-12T00:00:00');
      const createdTime = new Date(this.accountPackageService.accountPackage.createBy.time.seconds * 1000);

      if (createdTime > deadline) {
        return true;
      }
    }
    return false;
  }

  generateRedeemBlastCreditParam() {
    const param = {
      accountId: this.accountPackageService.accountPackage?.accountId,
      currency: this.accountPackageService.accountPackage?.accountPrice?.currency,
      createBy: this.updateByService.generateUpdateBy(),
      platform: this.deviceService.deviceInfo.platform,
      language: this.languageService.getUserLanguage().code,
      locality: this.localityService.getUserLocality(),
      timezone: this.timezoneService.getUserTimezone(),
      debugMode: this.debugModeService.debugMode,
    };
    return param;
  }

  async redeemBlastCreditCall() {
    const param = this.generateRedeemBlastCreditParam();
    if (param?.accountId && param?.currency && param?.createBy && param?.platform && param?.language && param?.locality && param?.timezone) {
      if (this.validateRedeemBlastCredit()) {
        await this.popupService.presentLoading();
        await this.fns.httpsCallable('redeemBlastCreditCall')(param)
        .toPromise().then(async (result: any) => {
          if (result.blastCredit) {
            const alert = await this.popupService.presentAlert(this.translate.instant('BLAST.redeem.success', { credit: result.blastCredit }));
            alert.onDidDismiss().then(() => {
              this.goBlastHomePage();
            });
          } else {
            if (result?.error === 'duplicate') {
              this.promptDuplicate();
            } else if ( result?.error === 'trial') {
              this.promptTrial();
            } else {
              this.popupService.presentAlert(this.translate.instant('BLAST.redeem.error.error'));
            }
          }
        }).catch((err: any) => {
          this.errorService.logError(err);
          this.popupService.presentAlert(this.translate.instant('MSG.action_error'));
        });
      } else {
        this.promptError();
      }
      await this.popupService.dismissLoading();
    } else {
      this.popupService.presentAlert(this.translate.instant('BLAST.redeem.error.error'));
    }
  }

  async goBlastHomePage() {
    await this.router.navigate(['/blast/home']);
    await this.popupService.dismissAllModal();
  }

  async promptError() {
    if (!this.accountPackageService.accountPackage?.accountStatus?.premium) {
      this.promptTrial();
    } else if (this.blastCreditService.getTotalRedeemBlastCredit()) {
      this.promptDuplicate();
    } else {
      const alert = await this.popupService.presentAlert(this.translate.instant('BLAST.redeem.error.error'));
      alert.onDidDismiss().then(() => {
        this.goBlastHomePage();
      });
    }
  }

  async promptDuplicate() {
    const alert = await this.popupService.presentAlert(this.translate.instant('BLAST.redeem.error.duplicate'));
    alert.onDidDismiss().then(() => {
      this.presentBlastCreditListModal('in');
    });
  }

  async promptTrial() {
    const alert = await this.popupService.presentAlert(this.translate.instant('BLAST.redeem.error.trial'));
    alert.onDidDismiss().then(() => {
      this.goBlastHomePage();
    });
  }
}
