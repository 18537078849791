import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IonContent, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { nanoid } from 'nanoid';

import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { InvitedByService } from 'src/app/services/setting/invited-by.service';
import { SeatingService } from 'src/app/services/seating/seating.service';
import { SeatingNewService } from 'src/app/services/seating/seating-new.service';
import { SeatingManageService } from 'src/app/services/seating/seating-manage.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { SeatingEditComponent } from 'src/app/components/seating/seating-edit/seating-edit.component';

import { Seating } from 'src/app/interfaces/seating';
import { SettingField } from 'src/app/interfaces/database';
import { SettingFieldType } from 'src/app/types/general';
import { SettingFieldComponent } from 'src/app/components/setting/setting-field/setting-field.component';
import { ErrorService } from 'src/app/services/general/error.service';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';
import { FunctionService } from 'src/app/services/general/function.service';

/**
 * New seating component
 */
@Component({
  selector: 'app-seating-new',
  templateUrl: './seating-new.component.html',
  styleUrls: ['./seating-new.component.scss'],
})
export class SeatingNewComponent implements OnInit, OnDestroy {
  /**
   * Ion content
   */
  @ViewChild('content', { read: ElementRef, static: false }) contentElement: ElementRef;

  @ViewChild('seatingListElement', { read: ElementRef }) seatingListElement: ElementRef;
  /**
   * Header element.
   * Calculate header height.
   */
  @ViewChild('header') header: ElementRef;
  /**
   * Content element.
   * Scroll to top.
   */
  @ViewChild('content') private content: IonContent;

   /**
   * Watch screen resize change
   */
  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.setupContentWidth(event?.target?.innerWidth);
  }
  /**
   * Watch screen orientation change
   */
  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event: any) {
    this.setupContentWidth(event?.target?.innerWidth);
  }

  contentWidth: number;
  gridSize: number;
  gridNameHeight: number;

  newMode: boolean;
  
  /**
   * Add new seating step.
   * 1 - Seat setting
   * 2 - Seat name, invited by, category
   * 3 - Preview
   */
  step: number;
  /**
   * Seating preview infinite load limit
   */
  gridLimit: number;
  /**
   * Seat setting Form
   */
  settingForm: FormGroup;
  /**
   * Seat name form
   */
  nameForm: FormGroup;
  /**
   * Validation message
   */
  validationMsg: any;
  /**
   * Invited by list
   */
  invitedByList: SettingField[];
  /**
   * Seating list to be add
   */
  seatingList: Seating[];

  seatingTypeName: string;

  category: string;

  private seatingType: SettingField;
  private seatingTypeOther: string;

  /**
   * Seating name
   */
  private name: string;
  /**
   * Maximum guest per table
   */
  private guestPerSeating: number;
  /**
   * Invited by list subscription
   */
  private invitedBySubscription: Subscription;

  /**
   * Constructor
   * @param formBuilder Form builder
   * @param modalController Modal controller
   * @param translate Translate service
   * @param seatingService Seating service
   * @param invitedByService Invited by service
   * @param popupService Popup service
   */
  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private translate: TranslateService,
    private settingFieldService: SettingFieldService,
    private invitedByService: InvitedByService,
    private seatingService: SeatingService,
    private seatingSettingService: SeatingSettingService,
    private seatingNewService: SeatingNewService,
    private seatingManageService: SeatingManageService,
    private functionService: FunctionService,
    private popupService: PopupService,
    private errorService: ErrorService,
  ) { }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.unwatchInvitedBy();
  }

  /**
   * Initial status
   */
  ionViewWillEnter() {
    this.step = 1;
    this.seatingList = [];

    if (!this.guestPerSeating) {
      this.guestPerSeating = this.seatingSettingService.getGuestPerSeating();
    }

    this.seatingTypeName = this.getSeatingTypeName();
    
    this.setupContentWidth();
    
    this.watchInvitedBy();
    this.setupForm();
  }

  /**
   * Unwatch invited by list
   */
  ionViewWillLeave() {
    this.unwatchInvitedBy();
  }

  getContentWidth(): number {
    return this.contentElement?.nativeElement?.offsetWidth ? this.contentElement.nativeElement.offsetWidth : 0;
  }

  async setupContentWidth(contentWidth?: number) {
    if (!contentWidth) {
      await this.functionService.delay(200);
      contentWidth = this.getContentWidth();
    }
    
    if (contentWidth) {
      this.contentWidth = (contentWidth);
      this.gridSize = this.seatingService.calculateGridItemSize(this.contentWidth);
      this.gridNameHeight = this.seatingService.calculateGridNameHeight(this.gridSize);

    } else {
      setTimeout(() => {
        this.setupContentWidth(contentWidth);
      }, 500);
    }
  }

  /**
   * Setup setting form & name form
   */
  setupForm() {
    this.settingForm = this.formBuilder.group({
      count: new FormControl(1, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(1)]
      ),
      maxGuest: new FormControl(this.guestPerSeating, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(1)]
      ),
      group: new FormControl(false, []),
      reserved: new FormControl(false, []),
      skip4: new FormControl(this.seatingSettingService.skip4 ? true : false, []),
      replace4: new FormControl(this.seatingSettingService.replace4 ? true : false, []),
      alphabet: new FormControl(this.seatingSettingService.alphabet ? true : false, [])
    });

    this.nameForm = this.formBuilder.group({
      name: new FormControl('', [ Validators.required, Validators.maxLength(this.seatingService.nameMaxLength) ]),
      invitedBy: new FormControl('', []),
      category: new FormControl('', [])
    });

    this.setupValidationMsg();
  }

  /**
   * Setup validation message
   */
  setupValidationMsg() {
    this.validationMsg = {
      count: [
        { type: 'required', msg: this.translate.instant('VALIDATION.required', {
          field: this.translate.instant('SEATING.lbl.count', { seating: this.getSeatingTypeName() })
        }) },
        { type: 'min', msg: this.translate.instant('VALIDATION.min_number', { number: 1 }) },
        { type: 'pattern', msg: this.translate.instant('VALIDATION.number_only') }
      ],
      maxGuest: [
        { type: 'required', msg: this.translate.instant('VALIDATION.required', {
          field: this.translate.instant('SEATING.lbl.max_guest', { seating: this.getSeatingTypeName() })
        }) },
        { type: 'min', msg: this.translate.instant('VALIDATION.min_number', { number: 1 }) },
        { type: 'pattern', msg: this.translate.instant('VALIDATION.number_only') }
      ],
      name: [
        { type: 'required', msg: this.translate.instant('VALIDATION.required', {
          field: this.translate.instant('SEATING.lbl.name', { seating: this.getSeatingTypeName() })
        }) },
        { 
          type: 'maxlength', msg: this.translate.instant('VALIDATION.max_length', {
          number: this.seatingService.nameMaxLength }) 
        },
      ]
    };
  }

  /**
   * Watch invited by list
   */
  async watchInvitedBy() {
    if (!this.invitedBySubscription) {
      this.invitedBySubscription = this.invitedByService.observableInvitedbyList.subscribe((invitedByList: SettingField[]) => {
        this.invitedByList = this.invitedByService.getInvitedByList();
      });
    }
    
  }

  /**
   * Unwatch invited by list
   */
  async unwatchInvitedBy() {
    if (this.invitedBySubscription) {
      this.invitedBySubscription.unsubscribe();
      this.invitedBySubscription = null;
    }
  }

  setupSettingField() {
    this.category = this.getSettingField('category', this.nameForm?.value?.category);
  }

  /**
   * Add seating count
   */
  addSeating() {
    this.settingForm.controls.count.setValue(this.settingForm.value.count + 1);
    if (this.settingForm.value.group) {
      this.settingForm.controls.maxGuest.setValue(this.settingForm.value.count * this.guestPerSeating);
    }
  }

  /**
   * Minus seting count
   */
  minusSeating() {
    if (this.settingForm.value.count > 1) {
      this.settingForm.controls.count.setValue(this.settingForm.value.count - 1);
      if (this.settingForm.value.group) {
        this.settingForm.controls.maxGuest.setValue(this.settingForm.value.count * this.guestPerSeating);
      }
    } else {
      this.popupService.presentToast(this.translate.instant('VALIDATION.min_number', { number: 1 }), 'warning');
    }
  }

  /**
   * Add guest per seating
   */
  addMaxGuest() {
    this.settingForm.controls.maxGuest.setValue(this.settingForm.value.maxGuest + 1);
  }

  /**
   * Minus guest per seating
   */
  minusMaxGuest() {
    if (this.settingForm.value.maxGuest > 1) {
      this.settingForm.controls.maxGuest.setValue(this.settingForm.value.maxGuest - 1);
    } else {
      this.popupService.presentToast(this.translate.instant('VALIDATION.min_number', { number: 1 }), 'warning');
    }
  }

  /**
   * Dismiss current modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ seatingList: this.seatingList, guestPerSeating: this.settingForm?.value?.maxGuest }); }
    }
  }

  /**
   * Present modal for seating info udpate
   */
  async presentSeatingEditModal(seating: Seating, index: number) {
    const modal = await this.modalController.create({
      component: SeatingEditComponent,
      componentProps: {
        seating,
        newSeating: true
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.seating && index !== -1) {
        this.seatingList[index] = result.data.seating;
      }
    });
  }

  /**
   * Present setting field cmodal
   * @param settingFieldType Setting field type
   * @param selected Selected setting field
   */
  async presentSettingFieldModal(settingFieldType: SettingFieldType, selected: SettingField[]) {
    const modal = await this.modalController.create({
      component: SettingFieldComponent,
      componentProps: {
        settingFieldType,
        selected
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.selected) {
        if (settingFieldType === 'category') {
          if (this.nameForm.value.category !== result.data.selected) {
            this.nameForm.controls.category.setValue(result.data.selected);
            this.checkNameForm();
            this.setupSettingField();
          }
        }
      }
    });
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[]): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList);
  }

  getContentWidthHeight(): { width: number, height: number } {
    const width: number = this.seatingListElement?.nativeElement?.offsetWidth ? this.seatingListElement.nativeElement.offsetWidth : window?.innerWidth ? window.innerWidth : 0;
    const height: number = this.seatingListElement?.nativeElement?.offsetHeight ? this.seatingListElement.nativeElement.offsetHeight : window?.innerHeight ? window.innerHeight : 0;
    return { width, height };
  }

  getGridLimit() {
    const { width, height } = this.getContentWidthHeight();
    return this.seatingSettingService.calculateGridLimit(width, height);
  }

  getSeatingTypeName(): string {
    return this.seatingSettingService.getSeatingTypeName('', this.seatingType, this.seatingTypeOther);
  }

  /**
   * Update guest per seating on seating group changed
   */
  groupChanged() {
    if (this.settingForm.value.group) {
      this.settingForm.controls.maxGuest.setValue(this.settingForm.value.count * this.guestPerSeating);
    } else {
      this.settingForm.controls.maxGuest.setValue(this.guestPerSeating);
    }
  }

  /**
   * Back to previous step
   */
  back() {
    if (this.step === 3) {
      this.step = 2;
      this.scrollToTop();
    } else if (this.step === 2) {
      this.step = 1;
      this.scrollToTop();
    } else {
      this.dismissModal();
    }
  }

  /**
   * Generate seating list for preview if seating count is 1 or seating group is true
   */
  checkNameForm() {
    if (this.settingForm.value.count === 1 || this.settingForm.value.group) {
      this.nameFormSubmit();
    }
  }

  /**
   * Seating form submit and go to step 2, setup seating name and check if need generate seating list
   */
  seatingFormSubmit() {
    this.settingForm.markAllAsTouched();
    if (this.settingForm.valid) {
      this.step = 2;
      this.setupGridLimit();
      this.scrollToTop();
      this.setupNameValue();
      this.checkNameForm();
    }
  }

  /**
   * Name form submit and generate seating list for preview
   * @param nextStep Next step boolean, false to disable go to step 3
   */
  nameFormSubmit(nextStep?: boolean) {
    this.nameForm.markAllAsTouched();
    setTimeout(() => {
      if (this.nameForm.valid) {
        if (nextStep) {
          this.step = 3;
          this.setupGridLimit();
          this.scrollToTop();
        }
        this.generateSeatingList();
      }
    }, 200);
  }

  setupGridLimit() {
    if (this.seatingListElement?.nativeElement?.offsetWidth) {
      this.gridLimit = this.getGridLimit();
    } else {
      setTimeout(() => {
        this.setupGridLimit();
      }, 200);
    }
  }

  /**
   * Setup seating name value
   */
  async setupNameValue() {
    const order: number = this.seatingService.getMaxSeatingOrder();
    this.name = this.seatingService.getMaxSeatingName(this.settingForm.value.alphabet);
    // try {
    //   const newSeating = this.seatingNewService.newSeating(
    //     this.name,
    //     order,
    //     this.settingForm.value.skip4,
    //     this.settingForm.value.replace4,
    //     this.settingForm.value.alphabet
    //   );
    //   this.name = newSeating.name;
    // } catch(err: any) {
    //   this.errorService.logError(err);
    // }
    this.nameForm.controls.name.setValue(this.name);
  }

  /**
   * Genreate new seating list to preivew / save
   */
  async generateSeatingList() {
    this.seatingList = [];
    let seating: Seating;
    let name: string = this.nameForm.value.name;
    let order: number = this.seatingService.getMaxSeatingOrder();
    let seatingId: string;

    const invitedByIndex: number = this.nameForm.value.invitedBy ?
      this.invitedByList?.findIndex(x => x.value === this.nameForm.value.invitedBy) : -1;

    for (let i = 1; i <= this.settingForm.value.count; i++) {
      do {
        seatingId = nanoid();
      } while (this.checkDuplicateSeatingById(seatingId) || this.seatingService.checkDuplicateSeatingById(seatingId));

      try {
        do {
          if (this.settingForm.value.replace4 && name.slice(-2)?.toString().toLowerCase() === '3a') {
            name = name.slice(0, -2) + '4';
          }
          const newSeating = this.seatingNewService.newSeating(
            name,
            order,
            this.settingForm.value.skip4,
            this.settingForm.value.replace4,
            this.settingForm.value.alphabet,
            i === 1 ? true : false
          );
          name = newSeating.name;
          order = newSeating.order;
        } while (this.checkDuplicateSeatingByName(name) || this.seatingService.checkDuplicateSeatingByName(name));
      } catch(err: any) {
        this.errorService.logError(err);
      }
      
      const invitedBy = invitedByIndex !== -1 && this.invitedByList[invitedByIndex] ? [this.invitedByList[invitedByIndex]] : [];
      const category = this.nameForm.value?.category ? this.nameForm.value.category : [];
      const maxGuest = this.settingForm.value.maxGuest;
      const reserved = this.settingForm?.value?.reserve ? true : false;

      if (this.settingForm.value.group) {
        if (!seating) {
          seating = {
            seatingId,
            name,
            invitedBy,
            category,
            maxGuest,
            count: this.settingForm.value.count,
            reserved,
            order
          };
        } else {
          seating.name = seating.name + ', ' + name;
          seating.order = order;
        }
      } else {
        seating = {
          seatingId,
          name,
          invitedBy,
          category,
          maxGuest,
          count: 1,
          reserved,
          order
        };
        this.seatingList.push(seating);
      }
    }

    if (this.settingForm.value.group) {
      this.seatingList.push(seating);
    }
  }

  /**
   * Check if duplicate seating name detected in new seating list
   */
  checkDuplicateSeatingByName(seatName: string): boolean {
    const index = this.seatingList?.findIndex((x: Seating) => x.name?.toString().toLowerCase() === seatName?.toString().toLowerCase());
    if (index === -1) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Check if duplicate seating id detected in new seating list
   */
  checkDuplicateSeatingById(seatingId: string): boolean {
    const index = this.seatingList?.findIndex((x: Seating) => x.seatingId === seatingId);
    if (index === -1) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Save new seating list
   */
  async saveSeatingList() {
    try {
      await this.popupService.presentLoading();
      await this.seatingManageService.updateSeatingNameSetting(
        this.settingForm.value?.alphabet,
        this.settingForm.value?.skip4,
        this.settingForm.value?.replace4,
      );
      if (!this.newMode) {
        await this.seatingManageService.saveNewSeating(this.seatingList);
        this.popupService.saveSuccessToast();
      }
      await this.popupService.dismissLoading();
      this.dismissModal();
    } catch (err) {
      this.errorService.logError(err);
      await this.popupService.dismissLoading();
    }
    
  }

  /**
   * Load infinite data for seating grid view
   * @param event event
   */
  loadData(event: any) {
    setTimeout(() => {
      if (this.gridLimit >= this.seatingList.length) {
        // event.target.disabled = true;
      } else {
        this.gridLimit += this.getGridLimit();
      }
      event.target.complete();
    }, 500);
  }

  /**
   * Scroll to top
   * @param duration animation duration
   */
  scrollToTop(duration?: number) {
    if (this.content) {
      this.content.scrollToTop(duration ? duration : 0);
    }
  }

  /**
   * Infinite load for grid view
   * @param event event
   */
  loadGridData(event: any) {
    setTimeout(() => {
      if (this.gridLimit >= this.seatingList.length) {
        // event.target.disabled = true;
      } else {
        this.gridLimit += this.getGridLimit();
      }
      event.target.complete();
    }, 300);
  }

  trackByFn(index: number, item: Seating) {
    if (item?.seatingId) {
      return item?.seatingId;
    }
    return index;
  }

}
