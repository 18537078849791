import { MediaType } from '../interfaces/general';
import { WebsiteMediaList, WebsiteStdContentList, WebsiteThemeBackground, WebsiteThemeColor } from '../interfaces/website';

export const StdWebsiteItineraryList: string[] = [
    'ceremony',
    'cocktail',
    'reception',
    'dinner',
    'lunch',
    'toast_speeches',
    'dancing',
    'bouquet',
    'after_party',
    'mehndi',
    'nikah',
    'tea_ceremony',
    'pithi'
];

export const StdEventWebsiteItineraryList: string[] = [
    'ceremony',
    'cocktail',
    'reception',
    'dinner',
    'lunch',
    'toast_speeches',
    'dancing',
    'bouquet',
    'after_party',
    'mehndi',
    'nikah',
    'tea_ceremony',
    'pithi'
];

export const StdWebsiteContentsList: WebsiteStdContentList = {
    // 'info': {
    //     contentId: 'info',
    //     order: 1,
    //     fixed: true,
    // },
    'host_msg': {
        contentId: 'host_msg',
        order: 1,
        fixed: false,
    },
    'save_the_date': {
        contentId: 'save_the_date',
        order: 2,
        fixed: false,
    },
    'venue': {
        contentId: 'venue',
        order: 3,
        fixed: false,
    },
    'dress_code': {
        contentId: 'dress_code',
        order: 4,
        fixed: false,
    },
    'itinerary': {
        contentId: 'itinerary',
        order: 5,
        fixed: false,
    },
    'gallery': {
        contentId: 'gallery',
        order: 6,
        fixed: false,
    },
    'contact': {
        contentId: 'contact',
        order: 7,
        fixed: false,
    }
};

export const StdWebsiteContactType: string[] = [
    'whatsapp',
    'phone',
    'email',
    'line',
    'messenger',
    'wechat',
    'telegram',
    'viber',
    'kaokao',
    'signal',
    'snapchat',
    'threads',
    'instagram',
    'facebook',
    'skype',
    'discord',
    'slack',
    'website'
];

export const WebsiteGallerySample: WebsiteMediaList = {
    '1': {
        mediaId: '1',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/1.jpg',
            type: 'local',
            mimeType: 'image/jpg'
        },
        sample: true,
        order: 1,
    },
    '2': {
        mediaId: '2',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/2.jpg',
            type: 'local',
            mimeType: 'image/jpg'
        },
        sample: true,
        order: 2,
    },
    '3': {
        mediaId: '3',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/3.jpg',
            type: 'local',
            mimeType: 'image/jpg'
        },
        sample: true,
        order: 3,
    },
    '4': {
        mediaId: '4',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/4.jpg',
            type: 'local',
            mimeType: 'image/jpg'
        },
        sample: true,
        order: 4,
    },
    '5': {
        mediaId: '5',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/5.jpg',
            type: 'local',
            mimeType: 'image/jpg'
        },
        sample: true,
        order: 5,
    },
    '6': {
        mediaId: '6',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/6.jpg',
            type: 'local',
            mimeType: 'image/jpg'
        },
        sample: true,
        order: 6,
    },
    '7': {
        mediaId: '7',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/7.jpg',
            type: 'local',
            mimeType: 'image/jpg'
        },
        sample: true,
        order: 7,
    },
    '8': {
        mediaId: '8',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/8.jpg',
            type: 'local',
            mimeType: 'image/jpg'
        },
        sample: true,
        order: 8,
    },
    '9': {
        mediaId: '9',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/9.jpg',
            type: 'local',
            mimeType: 'image/jpg'
        },
        sample: true,
        order: 9,
    },
    '10': {
        mediaId: '10',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/10.jpg',
            type: 'local',
            mimeType: 'image/jpg'
        },
        sample: true,
        order: 10,
    },
    '11': {
        mediaId: '11',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/11.jpg',
            type: 'local',
            mimeType: 'image/jpg'
        },
        sample: true,
        order: 11,
    },
    '12': {
        mediaId: '12',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/12.jpg',
            type: 'local',
            mimeType: 'image/jpg'
        },
        sample: true,
        order: 12,
    }
    ,'13': {
        mediaId: '13',
        mediaUrl: {
            url: './assets/wedding/img/website/gallery/13.jpg',
            type: 'local',
            mimeType: 'image/jpg'
        },
        sample: true,
        order: 13,
    }
}

export const WebsiteCoverSample: MediaType[] = [
    {
        url: './assets/wedding/img/website/cover/1.svg',
        type: 'local',
    },
    {
        url: './assets/wedding/img/website/cover/2.webp',
        type: 'local',
    }
];