export const InboxRtdbUrl = 'https://thebigday-wedding-inbox.asia-southeast1.firebasedatabase.app/';

export const VisitorRtdbUrl: string = 'https://thebigday-wedding-guest.asia-southeast1.firebasedatabase.app/';

export const UserRtdbUrl = 'https://thebigday-wedding-user.asia-southeast1.firebasedatabase.app/';

export const PushNotificationRtdbUrl = 'https://thebigday-wedding-push-notification.asia-southeast1.firebasedatabase.app/';

export const WhatsAppRtdbUrl = 'https://thebigday-wedding-whatsapp.asia-southeast1.firebasedatabase.app/';

export const OldOnlineRtdbUrl = 'https://wedding-by-thebigday.asia-southeast1.firebasedatabase.app/';
export const OldOnlineRtdbUrl2 = 'https://thebigday-wedding.firebaseio.com/';

export const SupportRtdbUrl = 'https://thebigday-wedding-support.asia-southeast1.firebasedatabase.app/';

export const ChatRtdbUrl = 'https://thebigday-wedding-chat.asia-southeast1.firebasedatabase.app/';

export const StatRtdbUrl = 'https://thebigday-wedding-statistic.asia-southeast1.firebasedatabase.app';

export const UserOnlineRtdbUrl = 'https://thebigday-wedding-user-online.asia-southeast1.firebasedatabase.app/';