import { SessionService } from 'src/app/services/setting/session.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ItemReorderEventDetail, ModalController, ActionSheetController, Platform, IonContent } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';

import { CategoryService } from 'src/app/services/setting/category.service';
import { SpecialReqService } from 'src/app/services/setting/special-req.service';
import { DietaryReqService } from 'src/app/services/setting/dietary-req.service';
import { InvitedByService } from 'src/app/services/setting/invited-by.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestListService } from 'src/app/services/guest/guest-list.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { Guest } from 'src/app/interfaces/guest';
import { SettingField } from 'src/app/interfaces/database';
import { ModuleType, SettingFieldType } from 'src/app/types/general';
import { VisitorSettingType } from 'src/app/types/visitor';
import { SettingVisitorComponent } from '../setting-visitor/setting-visitor.component';
// import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SettingFieldNewComponent } from '../setting-field-new/setting-field-new.component';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { AccountEventModeService } from 'src/app/services/account/account-event-mode.service';
import { CurrentPrivilege } from 'src/app/interfaces/privilege';

@Component({
  selector: 'app-setting-field',
  templateUrl: './setting-field.component.html',
  styleUrls: ['./setting-field.component.scss'],
})
export class SettingFieldComponent implements OnInit, OnDestroy {

  /**
   * Watch screen resize change
   */
  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.resizeLimit();
  }
  /**
   * Watch screen orientation change
   */
  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event: any) {
    this.resizeLimit();
  }

  @ViewChild('content') private content: IonContent;

  @ViewChild('content', { read: ElementRef }) contentElement: ElementRef;
  
  // @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  
  limit: number;
  // title: string;
  settingFieldType: SettingFieldType;
  /**
   * Module
   */
  module: ModuleType;
  /**
   * Search keyword
   */
  searchTerm: string;
  /**
   * Category list
   */
  list: SettingField[];
  eventMode: boolean;
  /**
   * Setting mode, for editing not select.
   */
  settingMode: boolean;
  reorderMode: boolean;

  title: string;
  
  currentPrivilege: CurrentPrivilege;
  /**
   * Selected settingField
   */
  private selected: SettingField[];
  /**
   * Filter criteria
   */
  private filter: any;
  /**
   * Sorting
   */
  private sorting: any;
  /**
   * Descending sort flag
   */
  private desc: boolean;
  /**
   * Setting field list subscription
   */
  private subscription: Subscription;

  private privilegeSubscription: Subscription;

  /**
   * Constructor
   * @param modalController Modal Controller
   * @param translate Translate
   * @param guestListService Guest list service
   * @param guestManageService Guest manage service
   * @param specialReqService Role Service
   * @param popupService Popup service
   * @param userService User service
   * @param functionService Function service
   */
  constructor(
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private translate: TranslateService,
    private moduleService: ModuleService,
    private privilegeService: PrivilegeService,
    private invitedByService: InvitedByService,
    private categoryService: CategoryService,
    private dietaryReqService: DietaryReqService,
    private specialReqService: SpecialReqService,
    private sessionService: SessionService,
    private settingFieldService: SettingFieldService,
    private accountEventModeService: AccountEventModeService,
    private guestService: GuestService,
    private guestManageService: GuestManageService,
    private guestListService: GuestListService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.unwatchSettingFieldList();
    this.unwatchPrivilege();
  }

  /**
   * Before view enter
   */
  async ionViewWillEnter() {
    this.title = this.getSettingFieldTitle();
    this.module = this.moduleService.currentModule;
    this.searchTerm = '';
    // this.setupTitle();
    this.watchSettingFieldList();
    this.watchPrivilege();
    this.setupLimit();
    this.eventMode = this.accountEventModeService.eventMode;
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatchSettingFieldList();
    this.unwatchPrivilege();
  }

  /**
   * Watch setting field
   */
  async watchSettingFieldList() {
    await this.unwatchSettingFieldList();
    if (this.settingFieldType === 'invited_by') {
      this.subscription = this.invitedByService.observableInvitedbyList.subscribe(() => {
        this.loadList();
      });
    } else if (this.settingFieldType === 'category') {
      this.subscription = this.categoryService.observableCategoryList.subscribe(() => {
        this.loadList();
      });
    } else if (this.settingFieldType === 'dietary_req') {
      this.subscription = this.dietaryReqService.observableDietaryReqList.subscribe(() => {
        this.loadList();
      });
    } else if (this.settingFieldType === 'special_req') {
      this.subscription = this.specialReqService.observableSpecialReqList.subscribe(() => {
        this.loadList();
      });
    } else if (this.settingFieldType === 'session') {
      this.subscription = this.sessionService.observable.subscribe(() => {
        this.loadList();
      });
    }
  }

  /**
   * Unwatch setting field
   */
  async unwatchSettingFieldList() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  async watchPrivilege() {
    if (!this.privilegeSubscription) {
      this.privilegeSubscription = this.privilegeService.observableCurrentPrivilege.subscribe(() => {
        this.setupPrivilege();
      })
    }
  }

  async unwatchPrivilege() {
    if (this.privilegeSubscription) {
      this.privilegeSubscription.unsubscribe();
      this.privilegeSubscription = null;
    }
  }

  setupPrivilege() {
    if (!this.currentPrivilege) {
      this.currentPrivilege = {};
    }
    this.currentPrivilege = {
      'guest': {
        'edit': this.checkPrivilege('guest', 'edit'),
        'delete': this.checkPrivilege('guest', 'delete'),
      }
    };
  }

  // setupViewport() {
  //   if (this.cdkVirtualScrollViewport) {
  //     this.cdkVirtualScrollViewport?.checkViewportSize();
  //   } else {
  //     setTimeout(() => {
  //       this.setupViewport();
  //     }, 200);
  //   }
  // }

  // setupTitle() {
  //   if (this.settingFieldType === 'invited_by') {
  //     this.title = this.translate.instant('GUEST.lbl.invited_by');
  //   } else if (this.settingFieldType === 'category') {
  //     this.title = this.translate.instant('LBL.category');
  //   } else if (this.settingFieldType === 'dietary_req') {
  //     this.title = this.translate.instant('GUEST.lbl.dietary_req');
  //   } else if (this.settingFieldType === 'special_req') {
  //     this.title = this.translate.instant('GUEST.lbl.special_req');
  //   } else if (this.settingFieldType === 'session') {
  //     this.title = this.translate.instant('LBL.session');
  //   } else {
  //     this.title = '';
  //   }
  // }

  setupLimit() {
    this.limit = this.getLimit();
  }

  /**
   * Search setting field
   */
  async search(data: any) {
    this.searchTerm = data?.keyword;
    this.filter = data?.filter?.enable ? data?.filter : null;
    this.sorting = data?.sorting;
    this.desc = data?.desc ? true : false;
    this.loadList();
  }

  /**
   * Load setting field list
   */
  async loadList() {
    this.list = [ ...this.searchList() ];
    // this.setupViewport();
  }

  searchList() {
    if (this.settingFieldType === 'invited_by') {
      return this.invitedByService.searchInvitedByList(this.eventMode, this.searchTerm, this.filter, this.sorting, this.desc);
    } else if (this.settingFieldType === 'category') {
      return this.categoryService.searchCategoryList(this.eventMode, this.searchTerm, this.filter, this.sorting, this.desc);
    } else if (this.settingFieldType === 'dietary_req') {
      return this.dietaryReqService.searchDietaryReqList(this.searchTerm, this.filter, this.sorting, this.desc);
    } else if (this.settingFieldType === 'special_req') {
      return this.specialReqService.searchSpecialReqList(this.eventMode, this.searchTerm, this.filter, this.sorting, this.desc);
    } else if (this.settingFieldType === 'session') {
      return this.sessionService.search(this.searchTerm, this.filter, this.sorting, this.desc);
    } else {
      return [];
    }
  }

  /**
   * Present check-in setting
   */
  async presentVisitorSetting(type: VisitorSettingType) {
    if (type) {
      const modal = await this.modalController.create({
        component: SettingVisitorComponent,
        cssClass: 'modal-full-screen-bk',
        componentProps: {
          type,
          enableSession: true,
        }
      });
      modal.present();
    }
  }

  /**
   * Check if setting field is selected
   * @param settingField settingField
   */
  checkSelected(settingField: SettingField): boolean {
    if (this.selected) {
      const index = this.selected?.findIndex((x: SettingField) => x.custom === settingField.custom && x.value === settingField.value);
      if (index !== -1) {
        return true;
      }
    }
    return false;
  }

  /**
   * Check ion item slide swipe, prompt for system reserved value and unable to edit / delete.
   * @param settingField settingField
   */
  checkSwipe(settingField: SettingField) {
    if (settingField.value === this.getReservedField()) {
      this.popupService.presentToast(this.translate.instant('VALIDATION.reserved', { field: this.getSettingFieldTitle() }), 'warning');
    }
  }

  getSettingFieldTitle(): string {
    return this.settingFieldService.getSettingFieldTitle(this.settingFieldType);
  }

  getValue(settingField: SettingField): string {
    if (settingField?.value) {
      if (settingField?.custom) {
        return settingField.value;
      } else if (this.settingFieldType) {
        return this.translate.instant('LIST.' + this.settingFieldType + '.' + settingField.value);
      }
    }
    return '';
  }

  getReservedField() {
    if (this.settingFieldType === 'category') {
      return this.categoryService.reservedField.value;
    } else if (this.settingFieldType === 'dietary_req') {
      return this.dietaryReqService.reservedField.value;
    } else if (this.settingFieldType === 'special_req') {
      return this.specialReqService.reservedField.value;
    } else if (this.settingFieldType === 'session') {
      return this.sessionService.reservedField.value;
    }
    return '';
  }

  getOriginalList() {
    if (this.settingFieldType === 'invited_by') {
      return this.invitedByService.getInvitedByList(this.eventMode);
    } else if (this.settingFieldType === 'category') {
      return this.categoryService.getCategoryList(this.eventMode);
    } else if (this.settingFieldType === 'dietary_req') {
      return this.dietaryReqService.getDietaryReqList();
    } else if (this.settingFieldType === 'special_req') {
      return this.specialReqService.getSpecialReqList(this.eventMode);
    } else if (this.settingFieldType === 'session') {
      return this.sessionService.list;
    }
  }

  getStdList() {
    if (this.settingFieldType === 'invited_by') {
      return this.invitedByService.getStdInvitedByList(this.eventMode);
    } else if (this.settingFieldType === 'category') {
      return this.categoryService.getStdCategoryList(this.eventMode);
    } else if (this.settingFieldType === 'dietary_req') {
      return this.dietaryReqService.getStdDietaryReqList();
    } else if (this.settingFieldType === 'special_req') {
      return this.specialReqService.getStdSpecialReqList(this.eventMode);
    } else if (this.settingFieldType === 'session') {
      return this.sessionService.list;
    }
  }

  getContentHeight(): number {
    return this.contentElement?.nativeElement?.offsetHeight ? this.contentElement.nativeElement.offsetHeight : 0;
  }

  generateFilter(value: SettingField) {
    if (this.settingFieldType) {
      const filter: any = {
        enable: true,
      };
      const settingField = {
        value: value.value,
        custom: value.custom,
      };
      if (this.settingFieldType === 'invited_by') {
        filter.invitedBy = settingField;
      } else if (this.settingFieldType === 'category') {
        filter.category = [ settingField ];
      } else if (this.settingFieldType === 'dietary_req') {
        filter.dietaryReq = [ settingField ];
      } else if (this.settingFieldType === 'special_req') {
        filter.specialReq = [ settingField ];
      } else if (this.settingFieldType === 'session') {
        filter.session = [ settingField ];
      }
      return filter;
    }
    return null;
  }

  generateData(value: SettingField) {
    const data: any = {};
    const settingField = {
      value: value.value,
      custom: value.custom,
    };
    if (this.settingFieldType === 'invited_by') {
      data.invitedBy = [ settingField ];
    } else if (this.settingFieldType === 'category') {
      data.category = [ settingField ];
    } else if (this.settingFieldType === 'dietary_req') {
      data.dietaryReq = [ settingField ];
    } else if (this.settingFieldType === 'special_req') {
      data.specialReq = [ settingField ];
    } else if (this.settingFieldType === 'session') {
      data.session = [ settingField ];
    }
    return data;
  }

  /**
   * Select settingField, prompt edit for setting mode, else dimiss current modal for selection.
   * @param settingField settingField
   */
  select(settingField: SettingField) {
    if (!this.reorderMode) {
      if (!this.settingMode) {
        this.selected = [];
        this.selected.push({ custom: settingField.custom, value: settingField.value });
        this.dismissModal(this.selected);
      } else {
        this.presentInput(settingField);
      }
    }
  }

  async discard() {
    if (this.reorderMode && !this.functionService.isEqual(this.getOriginalList(), this.list)) {
      const actionSheet = await this.actionSheetController.create({
        header: this.translate.instant('MSG.discard_msg'),
        buttons: [{
          text: this.translate.instant('BTN.discard'),
          role: 'destructive',
          handler: () => {
            this.dismissModal();
          }
        }, {
          text: this.translate.instant('BTN.save'),
          role: 'destructive',
          handler: async () => {
            await this.save(this.list);
            this.dismissModal();
          }
        }, {
          text: this.translate.instant('BTN.cancel'),
          role: 'cancel',
          handler: () => {
          }
        }]
      });
      actionSheet.present();
    } else {
      this.dismissModal();
    }
  }

  /**
   * Dismiss modal
   * @param selected Selected settingField
   */
  async dismissModal(selected?: SettingField[]) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { modal.dismiss({ selected }); }
    }
  }

  async presentSettingFieldNewModal() {
    const modal = await this.modalController.create({
      component: SettingFieldNewComponent,
      componentProps: {
        list: this.list,
        eventMode: this.eventMode,
        settingFieldType: this.settingFieldType,
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.list?.length) {
        this.list = result.data.list;
      }

      if (result?.data?.selected) {
        this.selected = result.data.selected;
      }
    });
  }

  /**
   * Present setting field input prompt
   * @param settingField settingField
   * @param slidingItem Sliding item to be closed
   */
  async presentInput(settingField?: SettingField, slidingItem?: any) {
    if ( settingField?.value === this.getReservedField() || (settingField?.value && !settingField?.custom)) {
      this.popupService.presentToast(this.translate.instant('VALIDATION.reserved', { field: this.getSettingFieldTitle() }), 'warning');
    } else {
      let value = '';
      let header = this.translate.instant('CRUD.new');

      if (settingField?.value) {
        value = this.getValue(settingField);
        header = this.translate.instant('CRUD.update');
      }
      header = header + ' ' + this.getSettingFieldTitle();

      const modal = await this.popupService.presentInput(header, '', '', '', value);
      modal.onDidDismiss().then((result: any) => {
        const newValue = result?.data?.input?.toString()?.trim();
        if (newValue && newValue !== value) {
          this.checkSettingField(newValue, { ...settingField });
        }
      });
      if (slidingItem) { slidingItem.close(); }
    }
  }

  /**
   * Check settingField for duplicate before save
   * @param value New settingField
   * @param settingField settingField
   */
  async checkSettingField(value: string, settingField: SettingField) {
    if (value) {
      if (!settingField) {
        settingField = {
          value,
          custom: true,
          // createBy: this.updateByService.getUpdateBy(),
          // updateBy: this.updateByService.getUpdateBy()
        };
      } else {
        settingField.value = value;
        settingField.custom = true;
        // settingField.updateBy = this.updateByService.getUpdateBy();
      }

      const duplicateIndex: number = this.list?.findIndex((x: SettingField) =>
        x.value?.toString()?.toLowerCase() === value.toString().toLowerCase() || this.getValue(x)?.toString()?.toLowerCase() === value?.toString()?.toLowerCase()
      );
      if (duplicateIndex !== -1) {
        this.popupService.presentToast(
          this.translate.instant('VALIDATION.duplicate_field', { field: this.getSettingFieldTitle() }), 'danger');
      } else {
        const list = [ ...this.getOriginalList() ];
        const stdList = this.getStdList();
        const duplicateStdIndex: number = stdList?.findIndex((x: SettingField) =>
          x.value?.toString().toLowerCase() === value.toString().toLowerCase() || (this.getValue(x)?.toString().toLowerCase() === value.toString().toLowerCase())
        );
        if (duplicateStdIndex !== -1 && stdList?.[duplicateStdIndex]) {
          settingField = stdList[duplicateStdIndex];
        }
        
        if (!settingField?.id) {
          settingField.id = this.functionService.randomId() + '_' + value;
          list.push(settingField);
        } else {
          const index = list.findIndex((x: SettingField) => x.id === settingField.id);
          if (index !== -1) {
            list[index] = settingField;
          } else {
            list.push(settingField);
          }
        }
        this.save(list);
      }
    }
  }

  /**
   * Check user privilege
   * @param action Action
   */
  checkPrivilege(module: ModuleType, action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege(module, action);
  }

  /**
   * Delete settingField
   * @param index index of deleted item
   * @param slidingItem Sliding item to be closed
   */
  async deletePrompt(settingField: SettingField, slidingItem?: any) {
    if (this.settingFieldType && settingField) {
      const filter: any = this.generateFilter(settingField);
      const guestList: Guest[] = this.guestService.getGuestList().filter((guest: Guest) => {
        return this.guestListService.filterGuestByCriteria(guest, filter);
      });
      const field = this.getValue(settingField);
      if (guestList?.length) {
        this.popupService.presentAlert(
          this.translate.instant('CRUD.unable_delete_field', {
            field
          })
          + '<br><br>' +
          this.translate.instant('CRUD.delete_setting_field_guest_count_prompt', {
            field: this.getSettingFieldTitle(),
            count: guestList.length
          }), '', '', null, guestList
        );
      } else {
        const modal = await this.popupService.presentConfirm(
          this.translate.instant('CRUD.confirm_delete_field', { field })
        );
        modal.onDidDismiss().then(async (result: any) => {
          if (result?.data?.confirm) {
            const list = [ ...this.getOriginalList() ];
            if (list?.length) {
              const index = list.findIndex((x: SettingField) => x.id === settingField.id);
              if (index !== -1) {
                list?.splice(index, 1);
                this.save(list);
              }
            }
          }
        });
      }
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Save setting field. Update existing guest also if both old and new value provided.
   */
  async save(list: SettingField[]) {
    await this.popupService.presentLoading();
    const originalList = [ ...this.getOriginalList() ];
    await this.updateGuestSettingField(list, originalList);
    await this.saveList(list);
    this.list = list;
    await this.popupService.dismissLoading();
    await this.popupService.saveSuccessToast();
    this.reorderMode = false;
  }

  async saveList(list: SettingField[]) {
    if (this.settingFieldType === 'invited_by') {
      await this.invitedByService.saveInvitedBy(list);
    } else if (this.settingFieldType === 'category') {
      await this.categoryService.saveCategory(list);
    } else if (this.settingFieldType === 'dietary_req') {
      await this.dietaryReqService.saveDietaryReq(list);
    } else if (this.settingFieldType === 'special_req') {
      await this.specialReqService.saveSpecialReq(list);
    } else if (this.settingFieldType === 'session') {
      await this.sessionService.save(list);
    }
  }


  /**
   * Update guest setting field value
   */
  async updateGuestSettingField(list: SettingField[], originalList: SettingField[]) {
    list?.forEach((settingField: SettingField) => {
      const index = originalList.findIndex((x: SettingField) => x.id === settingField.id && x.value !== settingField.value);
      if (index !== -1) {
        const filter = this.generateFilter(originalList[index]);
        if (filter) {
          const guestIdList: string[] = this.guestService.getGuestList().filter((guest: Guest) => {
            return this.guestListService.filterGuestByCriteria(guest, filter);
          }).map((guest: Guest) => {
            return guest?.guestId;
          });
          if (guestIdList?.length) {
            const data = this.generateData(settingField);
            this.guestManageService.saveGuest(data, guestIdList);
          }
        }
      }
    });
  }

  toggleReorder(reorderMode: boolean) {
    // this.list = [ ...this.getOriginalList() ];
    this.reorderMode = reorderMode;
  }

  doReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    this.list = ev.detail.complete(this.list);
  }

  /**
   * Track setting field item
   * @param index Index
   * @param item item
   */
  trackByFn(index: number, item: SettingField) {
    if (item?.id) {
      return item.id;
    } else if (item?.value) {
      return item.value;
    }
    return index;
  }

  scrollToTop(time?: number) {
    if (this.content) {
      this.content.scrollToTop(time ? time : 0);
    }
  }

  resizeLimit() {
    const limit = this.getLimit();
    if (limit > this.limit) {
      this.limit = limit;
      this.scrollToTop();
    }
  }

  getLimit() {
    const limit = Math.ceil(this.getContentHeight() / 48);
    return limit ? limit : 20;
  }

  async loadLimit(event: any) {
    await this.functionService.delay(50);
    if (this.limit >= this.list.length) {
      // event.target.disabled = true;
    } else {
      this.limit += this.getLimit();
    }
    event.target.complete();
  }

}
