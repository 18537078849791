<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'DEVICE_CONTACTS.btn.import_contacts' | translate }}
    </ion-title>
  </ion-toolbar>
  <ion-toolbar class="ion-no-padding" color="light" *ngIf="step === 'contact'">
    <div>
      <app-search [type]="'contacts'" [disableFilter]="true" (action)="search($event)"></app-search>
    </div>
  </ion-toolbar>
  <ion-toolbar *ngIf="step">
    <ion-text class="font-14">
      {{ 'DEVICE_CONTACTS.msg.' + step | translate }}
    </ion-text>
    <div *ngIf="editMode && step === 'contact'">
      <ion-text class="font-10">
        *{{ 'DEVICE_CONTACTS.msg.edit' | translate }}
      </ion-text>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content #content>
  <ng-container *ngIf="step === 'contact'">
    <div class="ion-padding ion-margin" *ngIf="!contactList?.length">
      <ion-button expand="block" (click)="getContacts()">
        {{ 'DEVICE_CONTACTS.btn.read_contacts' | translate }}
      </ion-button>
    </div>
    <div class="full-width-height viewport-div" *ngIf="contactList?.length">
      <cdk-virtual-scroll-viewport [itemSize]="50" [minBufferPx]="200" [maxBufferPx]="200">
        <ion-list class="full-height background-transparent ion-padding-bottom" lines="none" #list>
          <ion-item class="item" *cdkVirtualFor="let contact of contactList; trackBy: trackByFn; let i = index;" [style.height.px]="itemHeightFn(contact)" (click)="selectContact(contact)" detail>
            <ion-label>
              <div class="font-14 text-ellipsis" *ngIf="contact?.name?.display">{{ contact.name.display }}</div>
              <div class="font-12 text-ellipsis" *ngIf="contact?.organization?.company">
                {{ contact.organization.company }}
              </div>
              <div class="font-12 text-ellipsis" *ngIf="contact?.organization?.jobTitle">
                {{ contact.organization.jobTitle }}
              </div>
              <div class="font-10 text-ellipsis" *ngIf="contact?.phones?.length && getContactPhoneNumber(contact.phones) as phoneNumbers">
                {{ phoneNumbers }}
              </div>
              <div class="font-10 text-ellipsis" *ngIf="contact?.emails?.length && getContactEmail(contact.emails) as emails">
                {{ emails }}
              </div>
            </ion-label>
            <ion-avatar *ngIf="contact?.image?.base64String" slot="end">
              <ion-img [src]="contact.image.base64String"></ion-img>
            </ion-avatar>
          </ion-item>
        </ion-list>
      </cdk-virtual-scroll-viewport>
    </div>
    
    <div class="full-width-height center vertical-center" *ngIf="!contactList?.length">
      <ion-label class="ion-text-center ion-margin" color="medium">
        <ion-icon class="center icon-cactus" color="medium" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
        <div class="ion-margin-top font-12"><ion-text color="medium">{{ 'LBL.no_found' | translate }}</ion-text></div>
      </ion-label>
    </div>
  </ng-container>
  

  <ion-list *ngIf="selectedContact?.phones?.length > 1 && step === 'mobile'">
    <span *ngFor="let phoneNumber of selectedContact.phones">
      <ion-item *ngIf="phoneNumber?.number" (click)="selectPhoneNumber(phoneNumber)" detail>
        <ion-label class="text-ellipsis font-14">
          <ion-text *ngIf="phoneNumber?.isPrimary" color="danger">*&nbsp;</ion-text>{{ phoneNumber.number }} 
        </ion-label>
        <ion-text class="text-ellipsis font-12" *ngIf="phoneNumber?.label" slot="end">
          {{ phoneNumber.label }}
        </ion-text>
      </ion-item>
    </span>
  </ion-list>

  <ion-list *ngIf="selectedContact?.emails?.length > 1 && step === 'email'">
    <span *ngFor="let email of selectedContact.emails">
      <ion-item *ngIf="email?.address" (click)="selectEmail(email)" detail>
        <ion-label class="text-ellipsis font-14">
          <ion-text *ngIf="email?.isPrimary" color="danger">*&nbsp;</ion-text>{{ email.address }}
        </ion-label>
        <ion-text class="text-ellipsis font-12" *ngIf="email?.label" slot="end">
          {{ email.label }}
        </ion-text>
      </ion-item>
    </span>
  </ion-list>
</ion-content>
<ion-footer *ngIf="step === 'mobile' || step === 'email'">
  <ion-toolbar>
    <ng-container *ngIf="!editMode || (step === 'mobile' && selectedContact?.emails?.length > 1) || (step === 'email' && result?.mobile?.no) else cancelBtn">
      <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" (click)="skip(step)">
        {{ 'BTN.skip' | translate }}
      </ion-button>
    </ng-container>
    <ng-template #cancelBtn>
      <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" (click)="dismissModal()">
        {{ 'BTN.cancel' | translate }}
      </ion-button>
    </ng-template>
  </ion-toolbar>
</ion-footer>