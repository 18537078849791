import { AccountCoupleItemComponent } from 'src/app/components/account/account-couple-item/account-couple-item.component';
import { AccountStatusComponent } from 'src/app/components/account/account-status/account-status.component';
import { AccountCoupleTypeComponent } from 'src/app/components/account/account-couple-type/account-couple-type.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { OfflineComponentsModule } from 'src/app/components/general/offline/offline.components.module';
import { CachedImageComponentsModule } from 'src/app/components/general/cached-image/cached-image.components.module';
import { NgxQrcodeStylingModule } from 'ngx-qrcode-styling';

@NgModule({
    declarations: [
        AccountCoupleTypeComponent,
        AccountStatusComponent,
        AccountCoupleItemComponent,
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        OfflineComponentsModule,
        CachedImageComponentsModule,
        NgxQrcodeStylingModule,
        TranslateModule,
        ScrollingModule
    ],
    exports: [
        AccountCoupleTypeComponent,
        AccountStatusComponent,
        AccountCoupleItemComponent,
    ]
})
export class AccountComponentsModule { }
