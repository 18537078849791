import { AccountsListComponent } from './accounts-list/accounts-list.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { OfflineComponentsModule } from 'src/app/components/general/offline/offline.components.module';
import { AccountsSetupComponent } from './accounts-setup/accounts-setup.component';
import { AccountComponentsModule } from 'src/app/components/account/account.components.module';
import { AccountsPasswordLoginComponent } from './accounts-password-login/accounts-password-login.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CachedImageComponentsModule } from '../general/cached-image/cached-image.components.module';
import { SettingComponentsModule } from '../setting/setting.components.module';
import { GuestComponentsModule } from '../guest/guest.components.module';
import { AccountsAddComponent } from './accounts-add/accounts-add.component';

@NgModule({
    declarations: [
        AccountsSetupComponent,
        AccountsPasswordLoginComponent,
        AccountsListComponent,
        AccountsAddComponent,
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        OfflineComponentsModule,
        // IonicSelectableModule,
        TranslateModule,
        AccountComponentsModule,
        NgxCaptchaModule,
        CachedImageComponentsModule,
        SettingComponentsModule,
        GuestComponentsModule,
        ScrollingModule,
    ],
    exports: [
        AccountsSetupComponent,
        AccountsPasswordLoginComponent,
        AccountsListComponent,
        AccountsAddComponent,
    ]
})
export class AccountsComponentsModule { }
