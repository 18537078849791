import { SelectAllComponent } from './select-all.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
    declarations: [
        SelectAllComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
        TranslateModule
    ],
    exports: [
        SelectAllComponent
    ]
})
export class SelectAllComponentsModule { }
