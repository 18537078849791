import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';

import { PopupService } from 'src/app/services/general/popup.service';

import { AccountsFilterComponent } from 'src/app/components/accounts/accounts-filter/accounts-filter.component';
import { SettingFieldFilterComponent } from 'src/app/components/setting/setting-field-filter/setting-field-filter.component';
import { SeatingListFilterComponent } from 'src/app/components/seating/seating-list-filter/seating-list-filter.component';
import { GuestListFilterComponent } from 'src/app/components/guest/guest-list-filter/guest-list-filter.component';
import { GiftListFilterComponent } from 'src/app/components/gift/gift-list-filter/gift-list-filter.component';

import { SearchType, ModuleType } from 'src/app/types/general';
import { GuestListMode } from 'src/app/types/guest';
import { KeyboardService } from 'src/app/services/general/keyboard.service';
import { InboxFilterComponent } from 'src/app/components/inbox/inbox-filter/inbox-filter.component';

/**
 * Search component
 */
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {

  module: ModuleType;
  /**
   * Hide filter filed
   */
  @Input() hideFilterField: any;
  /**
   * Search type
   */
  @Input() type: SearchType;
  /**
   * Disable filter
   */
  @Input() disableFilter: boolean;
  /**
   * Search input place holder
   */
  @Input() placeholder: string;
  /**
   * Sorting field
   */
  @Input() sorting: string;
  /**
   * Sorting descending flag
   */
  @Input() desc: boolean;
  /**
   * Disable flag
   */
  @Input() disabled: boolean;
  /**
   * Reset filter & sorting
   */
  @Input() set reset(flag: boolean) {
    if (flag) {
      this.keyword = '';
      this.filter = null;
      this.sorting = '';
      this.desc = false;
    }
  }
  /**
   * Guest list mode
   */
  @Input() private guestListMode: GuestListMode;
  /**
   * Search / filter action output
   */
  @Output() action = new EventEmitter<any>();

  @Output() setupHeight = new EventEmitter<boolean>();

  /**
   * Search keyword
   */
  @Input() keyword: string;
  /**
   * Filter criteria
   */
  @Input() filter: any;

  /**
   * Constructor
   * @param platform Platform
   * @param modalController Modal Controller
   * @param translate Translate service
   */
  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private popupService: PopupService,
    private keyboardService: KeyboardService,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {}

  /**
   * Search action
   * @param getHeader Get header height flag
   */
  search(getHeaderHeight?: boolean) {
    if (!this.disabled) {
      this.action.next({ keyword: this.keyword, filter: this.filter, sorting: this.sorting, desc: this.desc });
      this.checkHeight();
    }
  }

  /**
   * Filter action
   */
  filterAction() {
    if (this.filter?.enable || this.sorting || this.desc) {
      this.resetFilter();
    } else {
      this.presentFilterModal();
    }
  }

  /**
   * Present filter modal based on search type.
   * @param edit Edit filter flag
   */
  async presentFilterModal(edit?: boolean) {
    if (this.type === 'seating') {
      this.presentSeatingListFilterModal(edit);
    } else if (this.type === 'guestList') {
      this.presentGuestListFilterModal(edit);
    } else if (this.type === 'accountList') {
      this.presentAccountsFilterModal(edit);
    } else if (this.type === 'settingField') {
      this.presentSettingFiledFilterModal(edit);
    } else if (this.type === 'giftList') {
      this.presentGiftListFilterModal(edit);
    } else if (this.type === 'inbox') {
      this.presentInboxFilterModal(edit);
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Present seating list filter modal
   * @param edit Edit flag
   */
  async presentSeatingListFilterModal(edit?: boolean) {
    const modal = await this.modalController.create({
      component: SeatingListFilterComponent,
      componentProps: {
        filter: edit ? this.filter : null,
        sorting: this.sorting,
        desc: this.desc
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data ) {
        this.setupFilter(result.data);
      }
    });
  }

  /**
   * Present guest list filter modal
   * @param edit Edit flag
   */
  async presentGuestListFilterModal(edit?: boolean) {
    const modal = await this.modalController.create({
      component: GuestListFilterComponent,
      componentProps: {
        filter: edit ? this.filter : null,
        sorting: this.sorting,
        desc: this.desc,
        guestListMode: this.guestListMode,
        hideField: this.hideFilterField
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data ) {
        this.setupFilter(result.data);
      }
    });
  }

  /**
   * Present gift list filter modal
   * @param edit edit flag
   */
  async presentGiftListFilterModal(edit?: boolean) {
    const modal = await this.modalController.create({
      component: GiftListFilterComponent,
      componentProps: {
        filter: edit ? this.filter : null,
        sorting: this.sorting,
        desc: this.desc,
        guestListMode: this.guestListMode,
        module: this.module
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data ) {
        this.setupFilter(result.data);
      }
    });
  }

  /**
   * Present accounts list filter modal
   * @param edit Edit flag
   */
  async presentAccountsFilterModal(edit?: boolean) {
    const modal = await this.modalController.create({
      component: AccountsFilterComponent,
      componentProps: {
        filter: edit ? this.filter : null,
        sorting: this.sorting,
        desc: this.desc
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data ) {
        this.setupFilter(result.data);
      }
    });
  }

  async presentInboxFilterModal(edit?: boolean) {
    const modal = await this.modalController.create({
      component: InboxFilterComponent,
      componentProps: {
        filter: edit ? this.filter : null,
        sorting: this.sorting,
        desc: this.desc
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data ) {
        this.setupFilter(result.data);
      }
    });
  }

  /**
   * Present setting field list filter modal
   * @param edit Edit flag
   */
  async presentSettingFiledFilterModal(edit?: boolean) {
    const modal = await this.modalController.create({
      component: SettingFieldFilterComponent,
      componentProps: {
        filter: edit ? this.filter : null,
        sorting: this.sorting,
        desc: this.desc
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data ) {
        this.setupFilter(result.data);
      }
    });
  }

  /**
   * Setup filter & sorting data from filter modal and start search if applicable.
   * @param data Filter data
   */
  setupFilter(data: any) {
    if (data) {
      let flag = false;
      if (data.filter) {
        this.filter = data.filter;
        flag = true;
      }

      if (data.sorting !== this.sorting) {
        this.sorting = data.sorting;
        flag = true;
      }

      if (data.desc !== this.desc) {
        this.desc = data.desc;
        flag = true;
      }

      if (flag) {
        this.search(true);
      }
    }
  }

  checkHeight() {
    this.setupHeight.next(true);
  }

  /**
   * Reset filter, sorting and search
   */
  resetFilter() {
    this.filter = null;
    this.sorting = '';
    this.desc = false;
    this.search(true);
  }

  /**
   * Close keyboard after enter
   * @param event Key enter event
   */
  closeKeyboard(event: any) {
    if (this.platform.is('hybrid')) {
      if (event?.key === 'Enter') {
        this.keyboardService.hide();
      }
    }
  }

}
