import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { FunctionService } from '../general/function.service';
import { BlastVariableService } from './blast-variable.service';
import { TemplateVariableEvent, TemplateVariableWedding } from 'src/app/commons/template';
import { TemplateVariableType } from 'src/app/types/template';
import { SmsAscii, SmsEscapeCharacter, SmsTitleMaxLength, SmsTitleMaxLengthUnicode, SmsUnicode } from 'src/app/commons/smsTemplate';
import { BlastType } from 'src/app/types/blast';
import { TranslateService } from '@ngx-translate/core';

import { AccountEventModeService } from '../account/account-event-mode.service';

@Injectable({
  providedIn: 'root'
})
export class BlastCountService implements OnInit, OnDestroy {

  constructor(
    private translate: TranslateService,
    private accountEventModeService: AccountEventModeService,
    private blastVariableService: BlastVariableService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  getBlastCount(blastType: BlastType, title: string, msg: string, url: string, phoneCode: number, dynamicVariable?: boolean, langCode?: string) {
    let blastCount = 1;
    let maxWordCount = 0;
    let splitUrl = false;

    title = this.blastVariableService.getHtml(title, true, blastType, langCode);
    msg = this.blastVariableService.getHtml(msg, false, blastType, langCode);
    
    const { unicode, titleMaxLength } = this.checkUnicode(title, msg);
    const totalWordCount = this.getWordCount(blastType, title, msg, url, phoneCode, unicode);
    let wordCount = this.getWordCount(blastType, title, msg, '', phoneCode, unicode);

    if (blastType === 'sms') {
      if (url) {
        if (unicode) {
          if (totalWordCount > SmsUnicode.single) {
            splitUrl = true;
            blastCount = Math.ceil(totalWordCount / SmsUnicode.multi);
            if (wordCount > SmsUnicode.single) {
              maxWordCount = blastCount * SmsUnicode.multi;
              blastCount++;
            } else {
              maxWordCount = SmsUnicode.single;
            }
          } else {
            blastCount = 1;
            maxWordCount = SmsUnicode.single;
            wordCount = wordCount + url.length;
          }
        } else {
          if (totalWordCount > SmsAscii.single) {
            blastCount = Math.ceil(totalWordCount / SmsAscii.multi);
            splitUrl = true;
            if (wordCount > SmsAscii.single) {
              maxWordCount = blastCount * SmsAscii.multi;
              blastCount++;
            } else {
              maxWordCount = SmsAscii.single;
            }
          } else {
            blastCount = 1;
            maxWordCount = SmsAscii.single;
            wordCount = wordCount + url.length;
          }
        }
      } else {
        if (unicode) {
          if (totalWordCount > SmsUnicode.single) {
            blastCount = Math.ceil(totalWordCount / SmsUnicode.multi);
            maxWordCount = blastCount * SmsUnicode.multi;
          } else {
            blastCount = 1;
            maxWordCount = SmsUnicode.single;
          }
        } else {
          if (totalWordCount > SmsAscii.single) {
            blastCount = Math.ceil(totalWordCount / SmsAscii.multi);
            maxWordCount = blastCount * SmsAscii.multi;
          } else {
            blastCount = 1;
            maxWordCount = SmsAscii.single;
          }
        }
      }
    }
    
    if (!dynamicVariable) {
      dynamicVariable = this.checkDynamicVariable(msg);
    }
    if (dynamicVariable && !splitUrl && blastType === 'sms') {
      splitUrl = true;
      blastCount++;
    }
    if (blastType === 'whatsapp') {
      blastCount = 1;
    }

    return { blastCount, unicode, titleMaxLength, wordCount, maxWordCount, splitUrl, dynamicVariable };
  }

  getTemplateVariableList() {
    return this.accountEventModeService.eventMode ? TemplateVariableEvent : TemplateVariableWedding;
  }

  checkDynamicVariable(msg: string) {
    let dynamicVariable = false
    const variableList = this.getTemplateVariableList();
    variableList?.forEach((templateVariable: TemplateVariableType) => {
      if (msg.indexOf('[' + templateVariable.toUpperCase() + ']') !== -1) {
        dynamicVariable = true;
      } else if (msg.indexOf('<span class="variable">' + templateVariable.toUpperCase() + '</span>') !== -1) {
        dynamicVariable = true;
      } else {
        const value = this.replaceEventType(this.translate.instant('TEMPLATE.variable.' + templateVariable.toLowerCase()));
        if (msg.indexOf('<span class="variable">' + value + '</span>') !== -1 || msg.indexOf('<span class="variable">[' + value + ']</span>') !== -1) {
          dynamicVariable = true;
        }
      }
      
    });
    return dynamicVariable;
  }

  checkUnicode(title: string, msg: string): { unicode: boolean, titleMaxLength: number } {
    let unicode = false;
    if (title && !unicode) {
      if (this.functionService.containsUnicodeCharacters(title)) {
        unicode = true;
      }
    }
    if (msg && !unicode) {
      if (this.functionService.containsUnicodeCharacters(msg)) {
        unicode = true;
      }
    }
    return {
      unicode,
      titleMaxLength: (unicode ? SmsTitleMaxLengthUnicode : SmsTitleMaxLength)
    };
  }

  getWordCount(blastType: BlastType, title: string, msg: string, url: string, phoneCode: number, unicode: boolean): number {
    let count = 0;
    const msgCount = msg?.length;
    const titleCount = title?.length;
    if (blastType === 'sms') {
      if (titleCount) {
        count += (titleCount + 1);
      }
      if (url?.length) {
        count += (url.length + 1);
      }
      if (phoneCode === 60) {
        count += 4;
      }
      if (!unicode) {
        count = count + this.checkEspaceCharacter(title) + this.checkEspaceCharacter(msg);
      }
    } else {
      return msgCount + titleCount;
    }

    return count + msgCount;
  }

  checkEspaceCharacter(str: string): number {
    let count = 0;
    if (str) {
      SmsEscapeCharacter?.forEach((escape: string) => {
        count = count + (str?.split(escape).length - 1);
      });
    }
    return count;
  }

  replaceEventType(text: string) {
    return this.accountEventModeService.replaceEventType(text);
  }

}
