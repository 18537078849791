<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'GIFT_CARD.title' | translate }}
    </ion-title>
  </ion-toolbar>
  <!-- <ion-toolbar class="background-light ion-padding-horizontal" *ngIf="languageList">
    <ion-segment scrollable [(ngModel)]="language">
      <ion-segment-button [value]="lang" *ngFor="let lang of languageList">
        <ion-label>{{ lang.name }}</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar> -->
</ion-header>

<ion-content class="ion-padding" color="light">
  <div class="ion-text-center">
    <ion-text>{{ 'GIFT_CARD.pick' | translate }}</ion-text>
  </div>
  <ng-container *ngIf="language">
    <ng-container *ngFor="let template of giftCardTemplateList[language.code]">
      <ion-card class="ion-padding" (click)="selectGiftCard(template)" *ngIf="template" button>
        <ion-icon class="icon-checked icon-large" color="success" src="./assets/wedding/icon/general/checkmark-circle.svg" *ngIf="giftCardId === template"></ion-icon>
        <ion-img class="gift-card-img center" [src]="'./assets/wedding/img/gift-card/' + template + '.webp'" ></ion-img>
      </ion-card>
    </ng-container>
  </ng-container>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button class="ion-margin" expand="block" (click)="save()" [disabled]="!giftCardId">
      <ng-container *ngIf="editMode">{{ 'BTN.save' | translate }}</ng-container>
      <ng-container *ngIf="!editMode">{{ 'BTN.next' | translate }}</ng-container>
    </ion-button>
  </ion-toolbar>
</ion-footer>