<ion-header #header>
  <ion-toolbar color="light">  
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons> 
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'BTN.apply_changes' | translate }}
    </ion-title>
  </ion-toolbar>
  <ion-toolbar color="light" *ngIf="newData">
    <app-group-change-value [newData]="newData"></app-group-change-value>
  </ion-toolbar>
  <ion-toolbar class="toolbar-select-all" color="light">
    <app-select-all [total]="count" *ngIf="selectedGuestList && guestList?.length" [selected]="selectedGuestList.length" [selectAll]="selectAll" [editMode]="true" (toggleSelect)="toggleSelectAll()"></app-select-all>
  </ion-toolbar>
</ion-header>
<ion-content color="light" #content>
  <div class="full-width-height viewport-div ion-padding-horizontal" *ngIf="guestList?.length">
    <cdk-virtual-scroll-viewport [itemSize]="estimateItemSize" [minBufferPx]="200" [maxBufferPx]="200">
      <ion-list class="full-width background-transparent ion-padding-bottom" lines="none" #list>
        <app-guest-card *cdkVirtualFor="let guest of guestList; trackBy: trackByFn; let index = index;" [style.height.px]="itemHeightFn(guest)" [setGuest]="guest" [selectMode]="true" [selectedGuestList]="selectedGuestList" [selectedGroupList]="selectedGroupList" [index]="index" (setSelectedGuest)="setSelectedGuest($event)" (setSelectedGroup)="setSelectedGroup($event)" (checkSelectedGroup)="checkSelectedGroup($event)"></app-guest-card>
      </ion-list>
    </cdk-virtual-scroll-viewport>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" [disabled]="!selectedGuestList?.length" (click)="next()">
      {{ 'BTN.apply' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>
