import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Platform, ModalController, PickerController, ActionSheetController, PickerColumnOption } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { OnlineService } from 'src/app/services/general/online.service';
import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { LocalityService } from 'src/app/services/location/locality.service';
import { TimezoneService } from 'src/app/services/general/timezone.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { GoogleMapService } from 'src/app/services/location/google-map.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { Country, Timezone } from 'src/app/interfaces/database';
import { AccountInfo } from 'src/app/interfaces/account';
import { Coordinate, LocationDetail } from 'src/app/interfaces/general';
import { LocationType } from 'src/app/types/general';
import { VisitorLocationService } from 'src/app/services/visitor/visitor-location.service';
import { SearchSelectListComponent } from '../search-select-list/search-select-list.component';
import { AccountEventModeService } from 'src/app/services/account/account-event-mode.service';
import { KeyboardService } from 'src/app/services/general/keyboard.service';
import { GooglePlaceService } from 'src/app/services/location/google-place.service';

// import { } from '@google/maps';
/**
 * Google map api
 */
declare const google: any;

/**
 * Component
 */
@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit, OnDestroy {

  /**
   * Search bar input, for set input focus on edit mode trigger
   */
  @ViewChild('searchbar') searchbar: any;
  /**
   * Map element for google map display
   */
  @ViewChild('map') private mapElement: ElementRef;

  eventMode: boolean;

  // keyboardShowing: boolean;

  locationType: LocationType;
  /**
   * Country list
   */
  // countryList: Country[] = this.localityService.getCountryList();
  /**
   * Disable country selection
   */
  disableCountry: boolean;
  /**
   * Disable timezone
   */
  disableTimezone: boolean;
  /**
   * Account location
   */
  location: LocationDetail;
  /**
   * Account country
   */
  country: Country;

  countryLbl: string;
  /**
   * Account timezone
   */
  timezone: Timezone;
  /**
   * Venue search term
   */
  searchTerm = '';
  /**
   * Venue search result
   */
  places: any = [];
  /**
   * Venue edit mode
   */
  editMode: boolean;
  /**
   * Google places loading
   */
  showLoading: boolean;
  /**
   * Google places not found flag
   */
  notFound: boolean;
  /**
   * Online flag
   */
  online: boolean;

  disableWatchAccountInfo: boolean;

  locale: string;

  private timeout: any;

  /**
   * Account info
   */
  private accountInfo: AccountInfo;
  /**
   * Google map marker
   */
  private marker: any;
  /**
   * Timezone list
   */
  private timezoneList: Timezone[];
  /**
   * Account info subscription
   */
  private accountInfoSubscription: Subscription;
  /**
   * Online subscription
   */
  private onlineSubscription: Subscription;
  /**
   * Google map auto complete service
   */
  private autocompleteService: google.maps.places.AutocompleteService;
  /**
   * Google map place service
   */
  private placesService: google.maps.places.PlacesService;

  private googleMap;

  /**
   * Constructor
   * @param ngZone Ng zone
   * @param platform Platform
   * @param modalController Modal controller
   * @param pickerController Picker controller
   * @param translate Translate service
   * @param onlineService Online service
   * @param accountService Account service
   * @param localityService Locality service
   * @param timezoneService Timezone service
   * @param googleMapService Google map service
   * @param popupService Popup service
   */
  constructor(
    private ngZone: NgZone,
    private platform: Platform,
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private pickerController: PickerController,
    private translate: TranslateService,
    private onlineService: OnlineService,
    private accountInfoService: AccountInfoService,
    private accountEventModeService: AccountEventModeService,
    private visitorLocationService: VisitorLocationService,
    private localityService: LocalityService,
    private timezoneService: TimezoneService,
    private googleMapService: GoogleMapService,
    private googlePlaceService: GooglePlaceService,
    private popupService: PopupService,
    private functionService: FunctionService,
    private keyboardService: KeyboardService,
  ) {
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.unwatchAccountInfo();
    this.unwatchOnline();
    this.stopTimeout();
  }

  /**
   * Before view enter
   */
  async ionViewWillEnter() {
    if (!this.locationType) {
      this.locationType = 'venue';
    }
    if (this.locationType === 'venue' && this.accountInfo?.eventMode) {
      this.eventMode;
    }

    this.countryLbl = this.getCountryName();

    if (this.country?.code) {
      this.setupCountry(this.country.code);
    } else if (this.localityService.getAccountCountry()) {
      this.setupCountry(this.localityService.getAccountCountry().code);
    }
    if (!this.disableWatchAccountInfo) {
      this.watchAccountInfo();
    }
    
    this.watchOnline();
    if (!this.location) {
      this.resetLocation();
    }
    if (this.location?.coordinate && this.location?.name) {
      this.searchTerm = this.location.name;
    }
    this.setupGoolgeMap();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatchAccountInfo();
    this.unwatchOnline();
    this.stopTimeout();
  }

  async stopTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  /**
   * Rest location
   */
  async resetLocation() {
    this.location = null;
    this.location = {
      locality: {
        country: '',
        state: '',
        city: '',
        town: ''
      },
      name: '',
      address: '',
      coordinate: null,
      placeId: '',
      plusCode: null
    };
  }

  async openCountryModal() {
    const modal = await this.modalController.create({
      component: SearchSelectListComponent,
      componentProps: {
        items: this.localityService.getCountryList(),
        selected: this.country,
        title: this.getCountryName(),
        itemTextField: 'name',
        placeholder: this.translate.instant('BTN.search'),
        closeButtonText: this.translate.instant('BTN.cancel'),
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.item && !this.functionService.isEqual(this.country, result.data.item)) {
        this.country = result.data.item;
        this.countryChange();
      }
    });
  }

  getCountryName() {
    if (this.locationType === 'business') {
      return this.translate.instant('BUSINESS.lbl.country');
    } else if (this.eventMode) {
      return this.replaceEventType(this.translate.instant('LOCATION.lbl.wedding_country'));
      
    } else {
      return this.translate.instant('LOCATION.lbl.wedding_country');
    }
  }

  getLocationName() {
    if (this.locationType === 'business') {
      return this.translate.instant('BUSINESS.lbl.location');
    } else if (this.eventMode) {
      return this.replaceEventType(this.translate.instant('LOCATION.lbl.wedding_venue'));
    } else {
      return this.translate.instant('LOCATION.lbl.wedding_venue');
    }
  }

  getRequiredField(type: string) {
    const required = 'VALIDATION.required';
    if (type === 'country') {
      if (this.locationType === 'business') {
        const field = this.translate.instant('BUSINESS.lbl.country');
        return this.translate.instant(required, { field });
      } else {
        const field = this.replaceEventType(this.translate.instant('LOCATION.lbl.wedding_country'));
        return this.translate.instant(required, { field });
      }
    } else if (type === 'timezone') {
      if (this.locationType === 'business') {
        const field = this.translate.instant('BUSINESS.lbl.timezone');
        return this.translate.instant(required, { field });
      } else {
        const field = this.replaceEventType(this.translate.instant('LOCATION.lbl.wedding_timezone'));
        return this.translate.instant(required, { field });
      }
    } else if (type === 'location') {
      if (this.locationType === 'business') {
        const field = this.translate.instant('BUSINESS.lbl.location');
        return this.translate.instant(required, { field });
      } else {
        const field = this.replaceEventType(this.translate.instant('LOCATION.lbl.wedding_venue'));
        return this.translate.instant(required, { field });
      }
    }
    return '';
  }

  /**
   * Setup google map
   */
  async setupGoolgeMap() {
    await this.platform.ready();
    if (this.onlineService.online) {
      await this.googleMapService.init(this.mapElement.nativeElement, this.location.coordinate).then(() => {
        this.autocompleteService = new google.maps.places.AutocompleteService();
        this.placesService = new google.maps.places.PlacesService(this.googleMapService.map);
        if (this.location?.coordinate) {
          this.setMarker(this.location.coordinate);
        }
      });
    } else {
      await this.stopTimeout();
      this.timeout = setTimeout(() => {
        this.setupGoolgeMap();
      }, 3000);
    }
  }

  /**
   * Watch account info
   */
  async watchAccountInfo() {
    if (!this.accountInfoSubscription) {
      this.accountInfoSubscription = this.accountInfoService.observableAccountInfo.subscribe((accountInfo: AccountInfo) => {
        this.accountInfo = accountInfo;
        if (accountInfo) {
          if (accountInfo?.location) {
            this.location = accountInfo.location;
            if (accountInfo.location?.locality?.country) {
              this.setupCountry(accountInfo.location.locality.country);
            }
          }
          if (accountInfo.timezone) {
            this.timezone = accountInfo.timezone;
          }
        }
      });
    }
    
  }

  /**
   * Unwatch account info
   */
  async unwatchAccountInfo() {
    if (this.accountInfoSubscription) {
      this.accountInfoSubscription.unsubscribe();
      this.accountInfoSubscription = null;
    }
  }

  /**
   * Watch online status
   */
  async watchOnline() {
    if (!this.onlineSubscription) {
      this.onlineSubscription = this.onlineService.observableOnline.subscribe((online: boolean) => {
        this.online = online;
      });
    }
    
  }

  /**
   * Unwatch online status
   */
  async unwatchOnline() {
    if (this.onlineSubscription) {
      this.onlineSubscription.unsubscribe();
      this.onlineSubscription = null;
    }
  }

  /**
   * On country change, reset location and search again
   */
  countryChange() {
    this.resetLocation();
    this.searchPlace();
    this.setupCountry(this.country.code);
  }

  /**
   * Setup Country
   * @param locality Locality
   */
  setupCountry(countryCode?: string) {
    if (countryCode) {
      const country = this.localityService.getCountryByCode(countryCode);
      if (country) {
        this.country = country;
      }
      this.setupTimezoneList();
    }
  }

  /**
   * Setup timezone list
   * @param timezones Timezone
   */
  setupTimezoneList() {
    if (this.country?.timezones) {
      const timezoneList = this.timezoneService.getTimezoneList(this.country.timezones);
      if (timezoneList && !this.functionService.isEqual(timezoneList, this.timezoneList)) {
        this.timezoneList = timezoneList;
        const index = this.timezoneList?.findIndex((x: Timezone) =>
          this.functionService.isEqual(x, this.timezoneService.getAccountTimezone()));
        if (index !== -1) {
          this.timezone = this.timezoneList[index];
        } else if (this.timezoneList?.[0]?.name) {
          this.timezone = this.timezoneList[0];
        }
      } else {
        this.timezone = null;
      }
    }
  }

  /**
   * Setup timezone
   * @param timezone Timezone
   */
  setupTimezone(timezone: any) {
    const timezoneIndex = this.timezoneList?.findIndex((x: Timezone) => x?.name === timezone);
    if (timezoneIndex !== -1) {
      this.timezone = this.timezoneList[timezoneIndex];
    } else {
      this.timezone = null;
    }
  }

  /**
   * Toggle edit mode for search input
   * @param editMode Edit mode
   */
  async toggleEditMode(editMode: boolean) {
    if (this.onlineService.isOnline()) {
      this.editMode = editMode;
      if (this.editMode) {
        // this.searchTerm = '';
        if (!this.autocompleteService || !this.placesService) {
          await this.setupGoolgeMap();
        }
      }
    }
  }

  /**
   * Search place from google map service
   */
  searchPlace() {
    if (this.searchTerm && this.country?.code) {
      const config: google.maps.places.AutocompletionRequest = {
        types: ['establishment', 'geocode'],
        input: this.searchTerm,
        componentRestrictions: { country: this.country.code },
      };
      this.showLoading = true;
      this.notFound = false;
      this.resetLocation();
      this.ngZone.run(() => {
        this.autocompleteService.getPlacePredictions(config,
          ( predictions: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus ) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
              this.places = [];
              predictions?.forEach((prediction) => {
                this.places.push(prediction);
              });
            }
            if (status === 'ZERO_RESULTS' || !predictions?.length || !this.places.length) {
              this.places = [];
              this.notFound = true;
              this.showLoading = false;
            } else {
              setTimeout(() => { this.showLoading = false; }, 200);
            }
          });
      });
    }
  }

  /**
   * Clear places
   */
  clearPlace() {
    this.searchTerm = '';
    this.places = [];

    this.resetLocation();

    if (this.marker) {
      this.marker.setMap(null);
      this.marker = null;
    }
  }

  /**
   * Select place from google map
   * @param place Place
   */
  async selectPlace(place: google.maps.places.AutocompletePrediction) {
    if (!this.showLoading) {
      await this.popupService.presentLoading();
      this.ngZone.run(() => {
        this.searchTerm = '';
        this.notFound = false;
        this.places = [];

        this.placesService.getDetails({ placeId: place.place_id }, async (details: google.maps.places.PlaceResult) => {
          await this.resetLocation();
          let address = '';
          address = await this.googlePlaceService.getAddress(place.place_id, this.getLocale());
          console.log(address);
          this.ngZone.run(() => {
            if (details) {
              const coordinate: Coordinate = {};
              if (details?.geometry?.location) {
                if (details.geometry.location.lat()) {
                  coordinate.lat = details.geometry.location.lat();
                }
                if (details.geometry.location.lng()) {
                  coordinate.lng = details.geometry.location.lng();
                }
              }

              if (details.name) {
                this.location.name = details.name;
              }

              if (coordinate) {
                this.location.coordinate = coordinate;
              }
              
              if (details.formatted_address) {
                if (address) {
                  this.location.address = address;
                } else {
                  this.location.address = details.formatted_address;
                }
              }

              this.location.locality.country = this.country.code;

              if (details.place_id) {
                this.location.placeId = details.place_id;
              }

              if (details.plus_code) {
                if (!this.location.plusCode) { this.location.plusCode = {}; }
                if (details.plus_code.compound_code) {
                  this.location.plusCode.compoundCode = details.plus_code.compound_code;
                }
                if (details.plus_code.global_code) {
                  this.location.plusCode.globalCode = details.plus_code.global_code;
                }
              }
              this.location.locality = this.googleMapService.generateLocality(details?.address_components);

              if (coordinate && !this.functionService.isEmpty(coordinate)) {
                this.setMarker(coordinate);
              }
            }
            setTimeout(() => { this.popupService.dismissLoading(); }, 300);
          });
        });
      });
    }
  }

  /**
   * Set marker
   * @param coordinate Coordinate
   */
  setMarker(coordinate: Coordinate) {
    if (coordinate && !this.functionService.isEmpty(coordinate)) {
      this.googleMapService.map.setCenter(coordinate);
      this.googleMapService.map.setZoom(17);

      if (this.marker) {
        this.marker.setMap(null);
        this.marker = null;
      }

      this.marker = new google.maps.Marker({
        animation: google.maps.Animation.DROP,
        position: coordinate
      });

      this.marker.setMap(this.googleMapService.map);
    }
  }

  /**
   * Open Timezone
   */
  async openTimezone() {
    if (this.timezoneList?.length) {
      const options: PickerColumnOption[] = [];
      let selectedIndex = 0;
      this.timezoneList?.forEach((value: Timezone, index: number) => {
        if (value === this.timezone) {
          selectedIndex = index;
        }
        options.push({
          text: value.label,
          value
        });
      });
      this.presentTimezonePicker(options, selectedIndex);
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Present timezone picker
   * @param options Options
   */
  async presentTimezonePicker(options: any[], selectedIndex?: number) {
    if (options?.length) {
      const picker = await this.pickerController.create({
        columns: [{
          name: 'timezone',
          selectedIndex,
          options
        }],
        buttons: [{
          text: this.translate.instant('BTN.cancel'),
          role: 'cancel'
        }, {
          text: this.translate.instant('BTN.confirm'),
          handler: (result: any) => {
            if (result?.timezone?.value) {
              if (!this.functionService.isEqual(result.timezone.value, this.timezone)) {
                this.setupTimezone(result.timezone.value.name);
              }
            }
          }
        }]
      });
      picker.present();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Close location modal
   */
  async dismissModal() {
    if (this.accountInfo && this.country && (this.country.code !== this.accountInfo?.location?.locality?.country
    || !this.functionService.isEqual(this.location, this.accountInfo.location)
    || !this.functionService.isEqual(this.timezone, this.accountInfo.timezone))) {
      const actionSheet = await this.actionSheetController.create({
        header: this.translate.instant('MSG.discard_msg'),
        buttons: [{
          text: this.translate.instant('BTN.confirm'),
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            this.dismissLocationModal();
          }
        }, {
          text: this.translate.instant('BTN.cancel'),
          icon: 'close',
          role: 'cancel',
          handler: () => {
          }
        }]
      });
      actionSheet.present();
    } else {
      this.dismissLocationModal();
    }
  }

  /**
   * Dismiss location modal
   */
  async dismissLocationModal(location?: LocationDetail, timezone?: Timezone) {
    if (this.modalController) {
      if (location) {
        if (this.country?.code) {
          location.locality.country = this.country.code;
        }
        if (this.locationType !== 'business' && this.accountInfo && (!this.functionService.isEqual(location, this.accountInfo.location)
        || !this.functionService.isEqual(this.timezone, this.accountInfo.timezone))) {
          this.saveDB({ location, timezone: this.timezone });
        }
      }
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ location, timezone }); }
    }
  }

  /**
   * Select
   */
  select() {
    this.dismissLocationModal(this.location, this.timezone);
  }

  /**
   * Save DB
   * @param data data
   */
  async saveDB(data: any) {
    await this.popupService.presentLoading();
    if (data) {
      if (!this.functionService.isEqual(this.location, this.accountInfo.location)) {
        this.visitorLocationService.updateLocation(this.location);
      }
      await this.accountInfoService.updateAccountInfo(data);
    }
    this.popupService.dismissLoading();
    this.popupService.saveSuccessToast();
  }

  /**
   * Close keyboard while enter key press
   * @param event keyboard event
   */
  closeKeyboard(event: any) {
    if (this.platform.is('hybrid')) {
      if (event?.key === 'Enter') {
        this.keyboardService.hide();
      }
    }
  }

  replaceEventType(text: string) {
    return this.accountEventModeService.replaceEventType(text);
  }

  getLocale() {
    if (this.locale) {
      return this.locale;
    } else if (this.country?.locale?.length) {
      return this.country.locale[0];
    }
    return '';
  }

  checkLocale() {
    
  }

}
