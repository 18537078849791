<ion-grid class="full-width" fixed #swiperGrid>
  <ion-row>
    <ion-col>
     <swiper-container class="full-width" [slidesPerView]="slidePerView">
        <ng-container *ngFor="let theme of themeList">
          <swiper-slide *ngIf="(theme.themeId === '18.1.1' || theme.themeId === '18.1.2' || theme.themeId === '18.1.3' || theme.themeId === '18.2.1' || theme.themeId === '18.2.2' || theme.themeId === '18.2.3' || theme.themeId === '19.1.1' || theme.themeId === '19.1.2' || theme.themeId === '19.1.3' || theme.themeId === '19.2.1' || theme.themeId === '19.2.2' || theme.themeId === '19.2.3') && getPreviewImgName(theme.themeId) as background" swiperSlide>
            <ion-card class="theme-card box-shadow background-transparent ion-text-center" (click)="selectPageTheme(theme.themeId)">
              <div class="full-width-height vertical-center">
                <ion-img class="theme-img" [src]="'./assets/wedding/img/visitor/theme/preview/' + background + '.webp'"></ion-img>
                <ion-button class="selected-btn" size="small" color="primary" *ngIf="theme.themeId === pageTheme">
                  {{ 'LBL.selected' | translate }}
                </ion-button>
              </div>
            </ion-card>
          </swiper-slide>
        </ng-container>
      </swiper-container>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
     <swiper-container class="full-width" [slidesPerView]="slidePerView">
        <ng-container *ngFor="let theme of themeList">
          <swiper-slide *ngIf="(theme.themeId === '20.1.1' || theme.themeId === '20.1.2' || theme.themeId === '20.1.3' || theme.themeId === '20.2.1' || theme.themeId === '20.2.2' || theme.themeId === '20.2.3' || theme.themeId === '21.1.1' || theme.themeId === '21.1.2' || theme.themeId === '21.1.3' || theme.themeId === '21.2.1' || theme.themeId === '21.2.2' || theme.themeId === '21.2.3' || theme.themeId === '22.1.1' || theme.themeId === '22.1.2' || theme.themeId === '22.1.3' || theme.themeId === '22.2.1' || theme.themeId === '22.2.2' || theme.themeId === '22.2.3') && getPreviewImgName(theme.themeId) as background" swiperSlide>
            <ion-card class="theme-card box-shadow background-transparent ion-text-center" (click)="selectPageTheme(theme.themeId)">
              <div class="full-width-height vertical-center">
                <ion-img class="theme-img" [src]="'./assets/wedding/img/visitor/theme/preview/' + background + '.webp'"></ion-img>
                <ion-button class="selected-btn" size="small" color="primary" *ngIf="theme.themeId === pageTheme">
                  {{ 'LBL.selected' | translate }}
                </ion-button>
              </div>
            </ion-card>
          </swiper-slide>
        </ng-container>
      </swiper-container>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
     <swiper-container class="full-width" [slidesPerView]="slidePerView">
        <ng-container *ngFor="let theme of themeList">
          <swiper-slide *ngIf="(theme.themeId === '12.1' || theme.themeId === '12.2' || theme.themeId === '12.3' || theme.themeId === '13.1' || theme.themeId === '13.2' || theme.themeId === '13.3' || theme.themeId === '14.1' || theme.themeId === '14.2' || theme.themeId === '14.3') && getPreviewImgName(theme.themeId) as background" swiperSlide>
            <ion-card class="theme-card box-shadow background-transparent ion-text-center" (click)="selectPageTheme(theme.themeId)">
              <div class="full-width-height vertical-center">
                <ion-img class="theme-img" [src]="'./assets/wedding/img/visitor/theme/preview/' + background + '.webp'"></ion-img>
                <ion-button class="selected-btn" size="small" color="primary" *ngIf="theme.themeId === pageTheme">
                  {{ 'LBL.selected' | translate }}
                </ion-button>
              </div>
            </ion-card>
          </swiper-slide>
        </ng-container>
      </swiper-container>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
     <swiper-container class="full-width" [slidesPerView]="slidePerView">
        <ng-container *ngFor="let theme of themeList">
          <swiper-slide *ngIf="(theme.themeId === '15.1' || theme.themeId === '15.2' || theme.themeId === '15.3' || theme.themeId === '16.1' || theme.themeId === '16.2' || theme.themeId === '16.3') && getPreviewImgName(theme.themeId) as background" swiperSlide>
            <ion-card class="theme-card box-shadow background-transparent ion-text-center" (click)="selectPageTheme(theme.themeId)">
              <div class="full-width-height vertical-center">
                <ion-img class="theme-img" [src]="'./assets/wedding/img/visitor/theme/preview/' + background + '.webp'"></ion-img>
                <ion-button class="selected-btn" size="small" color="primary" *ngIf="theme.themeId === pageTheme">
                  {{ 'LBL.selected' | translate }}
                </ion-button>
              </div>
            </ion-card>
          </swiper-slide>
        </ng-container>
      </swiper-container>
    </ion-col>
  </ion-row>
  
  <ion-row>
    <ion-col>
      <!-- <ion-text class="theme-design-lbl font-12">
        {{ 'VISITOR.theme.design.greenery' | translate }}
      </ion-text> -->
     <swiper-container class="full-width" [slidesPerView]="slidePerView">
        <ng-container *ngFor="let theme of themeList">
          <swiper-slide *ngIf="(theme.themeId === '1.1' || theme.themeId === '1.2' || theme.themeId === '1.3' || theme.themeId === '2.1' || theme.themeId === '2.2' || theme.themeId === '2.3' || theme.themeId === '3.1' || theme.themeId === '3.2' || theme.themeId === '3.3') && getPreviewImgName(theme.themeId) as background" swiperSlide>
            <ion-card class="theme-card box-shadow background-transparent ion-text-center" (click)="selectPageTheme(theme.themeId)">
              <div class="full-width-height vertical-center">
                <ion-img class="theme-img" [src]="'./assets/wedding/img/visitor/theme/preview/' + background + '.webp'"></ion-img>
                <ion-button class="selected-btn" size="small" color="primary" *ngIf="theme.themeId === pageTheme">
                  {{ 'LBL.selected' | translate }}
                </ion-button>
              </div>
            </ion-card>
          </swiper-slide>
        </ng-container>
      </swiper-container>
    </ion-col>
  </ion-row>
    
  <ion-row>
    <ion-col>
      <!-- <ion-text class="theme-design-lbl font-12">
        {{ 'VISITOR.theme.design.ticket' | translate }}
      </ion-text> -->
     <swiper-container class="full-width" [slidesPerView]="slidePerView">
        <ng-container *ngFor="let theme of themeList">
          <swiper-slide *ngIf="(theme.themeId === '4.1' || theme.themeId === '4.2' || theme.themeId === '5.1' || theme.themeId === '5.2' || theme.themeId === '6.1' || theme.themeId === '7.1') && getPreviewImgName(theme.themeId) as background" swiperSlide>
            <ion-card class="theme-card box-shadow background-transparent ion-text-center" (click)="selectPageTheme(theme.themeId)">
              <div class="full-width-height vertical-center">
                <ion-img class="theme-img" [src]="'./assets/wedding/img/visitor/theme/preview/' + background + '.webp'"></ion-img>
                <ion-button class="selected-btn" size="small" color="primary" *ngIf="theme.themeId === pageTheme">
                  {{ 'LBL.selected' | translate }}
                </ion-button>
              </div>
            </ion-card>
          </swiper-slide>
        </ng-container>
      </swiper-container>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
     <swiper-container class="full-width" [slidesPerView]="slidePerView">
        <ng-container *ngFor="let theme of themeList">
          <swiper-slide *ngIf="(theme.themeId === '8.1' || theme.themeId === '8.2' || theme.themeId === '8.3' || theme.themeId === '9.1' || theme.themeId === '9.2' || theme.themeId === '9.3' || theme.themeId === '10.1' || theme.themeId === '10.2' || theme.themeId === '10.3' || theme.themeId === '11.1' || theme.themeId === '11.2' || theme.themeId === '11.3' || theme.themeId === '17.1' || theme.themeId === '17.2' || theme.themeId === '17.3') && getPreviewImgName(theme.themeId) as background" swiperSlide>
            <ion-card class="theme-card box-shadow background-transparent ion-text-center" (click)="selectPageTheme(theme.themeId)">
              <div class="full-width-height vertical-center">
                <ion-img class="theme-img" [src]="'./assets/wedding/img/visitor/theme/preview/' + background + '.webp'"></ion-img>
                <ion-button class="selected-btn" size="small" color="primary" *ngIf="theme.themeId === pageTheme">
                  {{ 'LBL.selected' | translate }}
                </ion-button>
              </div>
            </ion-card>
          </swiper-slide>
        </ng-container>
      </swiper-container>
    </ion-col>
  </ion-row>
  
</ion-grid>