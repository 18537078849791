import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, map } from 'rxjs';

import { UpdateByService } from 'src/app/services/user/update-by.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { ErrorService } from 'src/app/services/general/error.service';

import { SettingField } from 'src/app/interfaces/database';
import { SettingFieldSortingType } from 'src/app/types/general';
import { DefaultCategoryList, DefaultEventCategoryList, StdCategoryList, StdEventCategoryList } from 'src/app/commons/category';
import { AccountEventModeService } from '../account/account-event-mode.service';

/**
 * Category service
 */
@Injectable({
  providedIn: 'root'
})
export class CategoryService implements OnInit, OnDestroy {

  reservedField: SettingField = {
    custom: false,
    value: 'others',
  };
  /**
   * Standard category database
   */
  // stdCategoryList: string[] = [];
  // defaultCategoryList: string[] = [];
  /**
   * Category list
   */
  categoryList: SettingField[];
  /**
   * Observable category list
   */
  observableCategoryList: any;
  /**
   * Category list subscription
   */
  private categorySubscription: Subscription;
  /**
   * Account ID
   */
  private accountId: string;

  /**
   * Constructor
   * @param afs angualr firestore
   * @param translate translate service
   * @param userService user serivce
   * @param functionService function service
   */
  constructor(
    private afs: AngularFirestore,
    private accountEventModeService: AccountEventModeService,
    private updateByService: UpdateByService,
    private functionService: FunctionService,
    private errorService: ErrorService
  ) {
    this.categoryList = [];
    this.observableCategoryList = new BehaviorSubject<SettingField[]>(this.categoryList);
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.unwatchCategory();
    this.categoryList = [];
  }

  /**
   * Setup Account ID and watch / unwatch category
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
    
    if (this.accountId) {
      await this.watchCategory();
    } else {
      await this.unwatchCategory();
      this.categoryList = [];
    }
  }

  /**
   * Watch category from firestore
   */
  async watchCategory() {
    if (this.accountId && !this.categorySubscription) {
      this.categorySubscription = this.afs.doc(`accounts/${ this.accountId }/accountSetting/category`)
      .snapshotChanges().pipe(map(changes => {
        const data: any = changes.payload.data();
        return data?.list;
      }), map((categoryList: SettingField[]) => {
        if (categoryList?.length) {
          return categoryList.map((settingField: SettingField) => {
            if (!settingField?.id) {
              settingField.id = this.functionService.randomId() + '_' + settingField.value;
            }
            // if (settingField?.createBy) {
            //   delete settingField.createBy;
            // }
            // if (settingField?.updateBy) {
            //   delete settingField.updateBy;
            // }
            return settingField;
          });
        } else {
          return [];
          // return this.stdCategoryList.map((value: string) => {
          //   return { id: value, custom: false, value };
          // });
        }
      })).subscribe({
        next: (categoryList: SettingField[]) => {
          if (!this.checkReservedField(categoryList)) {
            categoryList.push(this.reservedField);
          }
          this.categoryList = categoryList;
          this.observableCategoryList.next(this.categoryList);
        }
      });
    }
  }

  /**
   * Unwatch category
   */
  async unwatchCategory() {
    if (this.categorySubscription) {
      this.categorySubscription.unsubscribe();
      this.categorySubscription = null;
    }
  }

  checkReservedField(settingFieldList: SettingField[]) {
    const index = settingFieldList?.findIndex((x: SettingField) => {
      return x.custom === this.reservedField.custom && x.value === this.reservedField.value;
    });
    return index !== -1;
  }

  /**
   * Search category list
   * @param keyword Keyword
   * @param type Type
   * @param filter Filter
   * @returns List of category
   */
  searchCategoryList(eventMode?: boolean, keyword?: string, filter?: any, sorting?: SettingFieldSortingType, desc?: boolean): SettingField[] {
    let categoryList: SettingField[] = this.getCategoryList(eventMode);
    if (categoryList?.length) {
      if (keyword) {
        categoryList = categoryList.filter((category: SettingField) => {
          return this.searchCategoryBykeyword(category, keyword);
        });
      }

      if (filter?.enable) {
        categoryList = categoryList.filter((category: SettingField) => {
          return this.filterCategoryByCriteria(category, filter);
        });
      }
    }
    return categoryList;
  }

  /**
   * Search category by keyword
   * @param category Category
   * @param keyword Keyword
   */
  searchCategoryBykeyword(category: SettingField, keyword: string): boolean {
    if (keyword && category?.value) {
      const value = category.custom ? category.value : this.functionService.getTranslate('LIST.category.' + category.value)?.toString().toLowerCase();
      if (this.functionService.search(value, keyword)) {
        return true;
      } else if (this.functionService.chineseMath(value, keyword)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Filter category by criteria
   * @param category category
   * @param filter fitler criteria
   */
  filterCategoryByCriteria(category: SettingField, filter: any): boolean {
    if (filter?.enable) {
      if (filter.type) {
        if (filter.type === 'system' && category.custom) {
          return false;
        } else if (filter.type === 'user' && !category.custom) {
          return false;
        }
      }

      // if (filter?.createBy) {
      //   if (filter.createBy === 'system') {
      //     if (category.custom) {
      //       return false;
      //     }
      //   } else {
      //     if (!category.createBy || !category.createBy.uid || category.createBy.uid !== filter.createBy) {
      //       return false;
      //     }
      //   }
      // }

      // if (filter.updateBy) {
      //   if (!category.updateBy || !category.updateBy.uid || category.updateBy.uid !== filter.updateBy) {
      //     return false;
      //   }
      // }
    }
    return true;
  }

  /**
   * Sort special request list
   * @param categoryList list to be sorted
   * @param field Sorting type
   * @param desc Descending flag
   */
  sortList(categoryList: SettingField[], field?: SettingFieldSortingType, desc?: boolean): SettingField[] {
    if (categoryList?.length) {
      categoryList.sort((a: SettingField, b: SettingField) => {
        const nameA = a.custom ? a.value.toString() : this.functionService.getTranslate('LIST.category.' + a.value).toString();
        const nameB = b.custom ? b.value.toString() : this.functionService.getTranslate('LIST.category.' + b.value).toString();
        if (field) {
          // if (field === 'createdTime') {
          //   const timeA: number = a?.createBy?.time?.seconds ? a.createBy.time.seconds : 0;
          //   const timeB: number = b?.createBy?.time?.seconds ? b.createBy.time.seconds : 0;
          //   if (timeA !== timeB) {
          //     return this.functionService.compare(timeA, timeB, desc);
          //   }
          // } else if (field === 'updatedTime') {
          //   const timeA: number = a?.updateBy?.time?.seconds ? a.updateBy.time.seconds : 0;
          //   const timeB: number = b?.updateBy?.time?.seconds ? b.updateBy.time.seconds : 0;
          //   if (timeA !== timeB) {
          //     return this.functionService.compare(timeA, timeB, desc);
          //   }
          // }
          return this.functionService.compare(nameA.toLowerCase(), nameB.toLowerCase(), desc);
        } else {
          return this.functionService.compare(nameA.toLowerCase(), nameB.toLowerCase(), desc);
        }
        return this.functionService.compare(nameA.toLowerCase(), nameB.toLowerCase(), false);
      });
    }
    return categoryList;
  }

  /**
   * Save category list to firestore
   * @param categoryList new category list
   */
  async saveCategory(list: SettingField[]) {
    if (this.accountId && list?.length) {
      const data: any = {
        list,
        updateBy: this.updateByService.updateBy
      };

      const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/category/`);
      accountsRef.set(data, { merge: true }).then(result => {
      }).catch((err: any) => {
        this.errorService.logError(err);
      });
    }
  }

  getCategoryList(eventMode?: boolean) {
    if (!eventMode) {
      eventMode = this.accountEventModeService.eventMode;
    }
    return this.categoryList?.length ? this.categoryList : this.getStdCategoryList(eventMode);
  }

  getStdCategoryList(eventMode?: boolean): SettingField[] {
    const stdCategoryList: SettingField[] = [];
    if (eventMode) {
      StdEventCategoryList?.forEach((value: string) => {
        const settingField: SettingField = {
          id: value,
          value,
          custom: false,
        }
        stdCategoryList.push(settingField);
      });
    } else {
      StdCategoryList?.forEach((value: string) => {
        const settingField: SettingField = {
          id: value,
          value,
          custom: false,
        }
        stdCategoryList.push(settingField);
      });
    }
    
    return stdCategoryList;
  }

  getDefaultCategoryList(eventMode?: boolean): SettingField[] {
    const defaultCategoryList: SettingField[] = [];
    if (eventMode) {
      DefaultEventCategoryList?.forEach((value) => {
        const settingField: SettingField = {
          id: value,
          value,
          custom: false,
        }
        defaultCategoryList.push(settingField);
      });
    } else {
      DefaultCategoryList?.forEach((value) => {
        const settingField: SettingField = {
          id: value,
          value,
          custom: false,
        }
        defaultCategoryList.push(settingField);
      });
    }
    
    return defaultCategoryList;
  }

}
