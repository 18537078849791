<ion-header class="header-light">
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal(true)">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      <ng-container *ngIf="giftCard">{{ 'GIFT_CARD.btn.send' | translate }}</ng-container>
      <ng-container *ngIf="!giftCard">{{ 'ACCOUNT_SHARE.lbl.invite_member' | translate }}</ng-container>
    </ion-title>
  </ion-toolbar>

</ion-header>
<ion-content color="light">
  <ng-container *ngIf="giftCard && credit">
    <ion-item class="background-transparent ion-item-no-padding ion-margin-vertical" lines="none" (click)="presentGiftCardTemplateModal()" *ngIf="credit?.giftCardId">
      <ion-label class="ion-no-margin">
        <ion-img class="center img-gift-card-preview" [src]="'./assets/wedding/img/gift-card/' + credit.giftCardId + '.webp'"></ion-img>
        <div class="ion-text-center">
          <ion-text class="font-10 font-underline">{{ 'CRUD.edit' | translate }}</ion-text>
        </div>
      </ion-label>
    </ion-item>
    <ion-card class="ion-padding ion-margin" *ngIf="credit?.productId">
      <ion-item class="ion-item-no-padding ion-no-padding" lines="none">
        <ion-icon class="icon-large" slot="start" src="./assets/wedding/icon/account/role-select.svg"></ion-icon>
        <ion-label>
          <ion-label class="font-bold font-14" color="dark">{{ 'PRODUCT.account.title' | translate }}</ion-label>
          <ion-label class="font-10" color="dark" *ngIf="credit?.productId">{{ 'PRODUCT.account.' + credit.productId + '.desc' | translate: { count: getModuleCount(accountLogin)} }}</ion-label>
        </ion-label>
      </ion-item>
    </ion-card>
  </ng-container>

  <div class="ion-margin-horizontal">
    <ion-segment class="full-width" scrollable [(ngModel)]="shareMethod" (ionChange)="setupMsg()">
      <ion-segment-button value="url" *ngIf="(giftCard && credit?.link.short) || (!giftCard && accountLogin?.link?.short)">
        <ion-label>{{ 'LBL.url' | translate }}</ion-label>
      </ion-segment-button>
      <ion-segment-button value="qrcode" *ngIf="qrcodeData">
        <ion-label>{{ 'MODULE.list.qrcode' | translate }}</ion-label>
      </ion-segment-button>
      <ion-segment-button value="password" *ngIf="!giftCard">
        <ion-label>{{ 'LBL.password' | translate }}</ion-label>
      </ion-segment-button>
    </ion-segment>
  </div>

  <ion-card class="ion-padding ion-margin" *ngIf="!giftCard && accountLogin?.role?.type" (click)="presentAccountPrivilegeModal(accountLogin)" button>
    <ion-item class="ion-item-no-padding ion-no-padding" lines="none">
      <ion-icon class="icon-large" slot="start" src="./assets/wedding/icon/account/role-select.svg"></ion-icon>
      <ion-label>
        <ion-label class="font-bold font-14" color="dark">
          <ng-container *ngIf="accountLogin.role?.custom">{{ accountLogin.role.type }}</ng-container>
          <ng-container *ngIf="!accountLogin.role?.custom">{{ 'LIST.role.' + accountLogin.role.type | translate }}</ng-container>
        </ion-label>
        <ion-label class="font-10" color="dark">{{ 'ACCOUNT_SHARE.msg.module_count' | translate: { count: getModuleCount(accountLogin)} }}</ion-label>
      </ion-label>
      <ion-text class="font-10" color="medium" slot="end">{{ 'CRUD.edit' | translate }}</ion-text>
    </ion-item>
  </ion-card>


  <div class="ion-margin">
    <ion-card class="content-card ion-padding">
      <div class="ion-text-center">
        <ion-text class="font-12 ion-text-center" color="dark">
          {{ 'ACCOUNT_SHARE.lbl.' + shareMethod | translate }}
        </ion-text>
      </div>
      <div *ngIf="shareMethod === 'url'">
        <ion-item class="prompt-input-item" lines="none" [detail]="true" [button]="true" (click)="copyField('url')">
          <ion-label class="full-width text-ellipsis ion-no-margin">
            <ng-container *ngIf="!giftCard && accountLogin?.link?.short">{{ accountLogin.link.short }}</ng-container>
            <ng-container *ngIf="giftCard && credit?.link.short">{{ credit.link.short }}</ng-container>
          </ion-label>
        </ion-item>
      </div>
      <ng-container *ngIf="shareMethod === 'qrcode'">
        <div class="qrcode-div ion-text-center">
          <ng-container *ngIf="qrcodeData">
            <!-- <ion-icon class="qrcode-logo" src="./assets/wedding/img/logo/thebigday-logo-round.svg"></ion-icon>
            <div *ngIf="qrcode" #canvas></div> -->
            <ion-img class="qrcode-logo" src="./assets/wedding/img/logo/thebigday-logo-round-primary.webp"></ion-img>
            <!-- <ngx-qrcode [value]="qrcode" elementType="url" errorCorrectionLevel="H" cssClass="center qrcodeImg"></ngx-qrcode> -->
            <ngx-qrcode-styling
              #qrcode
              [data]="qrcodeData"
              [config]="config" *ngIf="config">
            </ngx-qrcode-styling>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="shareMethod === 'password'">
        <div class="ion-margin">
          <div class="ion-margin-vertical ion-padding-vertical" *ngIf="accountLogin?.login">
            <div>
              <ion-label class="line-150">{{ 'ACCOUNT.lbl.wedding_id' | translate }}</ion-label>
              <ion-item class="prompt-input-item" lines="none" (click)="copyField('weddingId')" detail button><ion-label class="text-ellipsis">{{ accountLogin.login.weddingId }}</ion-label></ion-item>
            </div>
            <div class="ion-margin-top">
              <ion-label class="line-150">{{ 'ACCOUNT.lbl.wedding_pass' | translate }}</ion-label>
              <ion-item class="prompt-input-item" lines="none" (click)="copyField('weddingPass')" detail button><ion-label class="text-ellipsis">{{ accountLogin.login.weddingPass }}</ion-label></ion-item>
            </div>
          </div>
        </div>
      </ng-container>
    </ion-card>
  </div>

  <ion-card>
    <div class="ion-margin" *ngIf="giftCard">
      <ion-text class="font-semi" color="dark">
        <ion-icon class="icon-small" color="dark" src="./assets/wedding/icon/general/tips.svg" ></ion-icon>
         &nbsp;{{ 'LBL.tips' | translate }}
      </ion-text>
      <ion-text class="font-12" color="dark" *ngIf="shareMethod === 'url'">
        <div class="ion-margin-vertical" [innerHTML]="'ACCOUNT_SHARE.gift_tips.url.step1' | translate"></div>
      </ion-text>
      <ion-text class="font-12" color="dark" *ngIf="shareMethod === 'qrcode'">
        <div class="ion-margin-vertical" [innerHTML]="'ACCOUNT_SHARE.gift_tips.qrcode.step1' | translate"></div>
        <div class="ion-margin-vertical" [innerHTML]="'ACCOUNT_SHARE.gift_tips.qrcode.step2' | translate"></div>
      </ion-text>
    </div>
    <div class="ion-margin" *ngIf="!giftCard">
      <ion-text class="font-semi" color="dark"><ion-icon class="icon-small" color="dark" src="./assets/wedding/icon/general/tips.svg" ></ion-icon> &nbsp;{{ 'LBL.tips' | translate }}</ion-text>
      <ion-text class="font-12" color="dark" *ngIf="shareMethod === 'url'">
        <div class="ion-margin-vertical" [innerHTML]="'ACCOUNT_SHARE.login_tips.url.step1' | translate"></div>
      </ion-text>
      <ion-text class="font-12" color="dark" *ngIf="shareMethod === 'qrcode'">
        <div class="ion-margin-vertical" [innerHTML]="'ACCOUNT_SHARE.login_tips.qrcode.step1' | translate"></div>
        <div class="ion-margin-vertical" [innerHTML]="'ACCOUNT_SHARE.login_tips.qrcode.step2' | translate"></div>
      </ion-text>
      <ion-text class="font-12" color="dark" *ngIf="shareMethod === 'password'">
        <div class="ion-margin-vertical" [innerHTML]="'ACCOUNT_SHARE.login_tips.password.step1' | translate"></div>
        <div class="ion-margin-vertical" [innerHTML]="'ACCOUNT_SHARE.login_tips.password.step2' | translate"></div>
        <div class="ion-margin-vertical" [innerHTML]="'ACCOUNT_SHARE.login_tips.password.step3' | translate"></div>
        <div class="ion-margin-vertical" [innerHTML]="'ACCOUNT_SHARE.login_tips.password.step4' | translate"></div>
      </ion-text>
    </div>
  </ion-card>
</ion-content>
<ion-footer *ngIf="shareMethod === 'url' || shareMethod === 'password'">
  <ion-toolbar>
    <ion-grid fixed>
      <ion-row>
        <ion-col *ngIf="!isHybrid">
          <ion-button class="ion-margin" expand="block" (click)="copyMsg()">
            {{ 'BTN.copy' | translate }}
          </ion-button>
        </ion-col>
        <ion-col *ngIf="isHybrid">
          <ion-button class="ion-margin" expand="block" (click)="share()">
            {{'BTN.send' | translate}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
