import { FunctionService } from 'src/app/services/general/function.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ContactPayload, Contacts, EmailPayload, GetContactsResult, PermissionStatus, PhonePayload, Projection } from '@capacitor-community/contacts';
import { Platform } from '@ionic/angular';
import { MobileService } from './mobile.service';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from './popup.service';

@Injectable({
  providedIn: 'root'
})
export class ReadContactsService implements OnInit, OnDestroy {

  contactList: ContactPayload[];

  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private mobileService: MobileService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  async requestPermission() {
    await this.platform.ready();
    if (this.platform.is('hybrid')) {
      const result: PermissionStatus = await Contacts.checkPermissions();
      if (result?.contacts === 'granted') {
        return true;
      } else if (result?.contacts !== 'denied')  {
        const result: PermissionStatus = await Contacts.requestPermissions();
        if (result.contacts === 'granted') {
          return true;
        } else {
          this.popupService.presentAlert(this.translate.instant('DEVICE_CONTACTS.msg.permission'));
        }
      } else {
        this.popupService.presentAlert(this.translate.instant('DEVICE_CONTACTS.msg.permission'));
      }
    }
    return false;
  }

  async getContacts(): Promise<ContactPayload[]> {
    await this.platform.ready();
    if (this.platform.is('hybrid') && await this.requestPermission()) {
      const projection: Projection = {
        name: true,
        organization: true,
        phones: true,
        emails: true,
        image: true,
      };
      const result: GetContactsResult = await Contacts.getContacts({ projection });
      if (result?.contacts?.length) {
        return result.contacts;
      }
    }
    return [];
  }

  async generateContactList() {
    if (!this.contactList?.length) {
      this.contactList = await this.getContacts();
    }
    if (this.contactList?.length) {
      try {
        this.contactList = this.contactList.map((contact: ContactPayload) => {
          if (contact?.phones?.length) {
            contact.phones = contact?.phones?.map((x: PhonePayload) => {
              const mobile = this.mobileService.generate(x?.number);
              if (mobile?.no) {
                x.number = mobile.no;
                return x;
              }
              return null;
            })?.filter((x: PhonePayload) => {
              return x?.number ? true : false;
            })?.filter((x: PhonePayload, index: number, self: PhonePayload[]) => {
              return index === self.findIndex((t) => ( t.number === x.number ));
            });
          }
          if (contact?.emails?.length) {
            contact.emails = contact?.emails?.map((x: EmailPayload) => {
              if (this.validateEmail(x)) {
                x.address = x.address?.toString().toLowerCase();
                return x;
              }
              return null;
            })?.filter((x: EmailPayload) => {
              if (x?.address) {
                return true;
              }
              return false;
            })?.filter((x: EmailPayload, index: number, self: EmailPayload[]) => {
              return index === self.findIndex((t) => (
                t.address === x.address
              ));
            });
          }
          return contact;
        });
      } catch (err: any)  {
        console.log(err);
      }
      this.contactList = this.sortList(this.contactList);
    }
  }

  searchList(keyword?: string): ContactPayload[] {
    let contactList: ContactPayload[] = this.contactList;
    if (contactList?.length) {
      if (keyword) {
        contactList = contactList.filter((contact: ContactPayload) => {
          return this.searchBykeyword(contact, keyword);
        });
      }
      contactList = this.sortList(contactList);
    }
    return contactList;
  }

  searchBykeyword(contact: ContactPayload, keyword: string): boolean {
    if (keyword && contact) {
      if (contact?.name?.display) {
        if (this.functionService.search(contact.name.display, keyword)) {
          return true;
        } else if (this.functionService.chineseMath(contact.name.display, keyword)) {
          return true;
        }
      }

      if (contact?.organization?.company) {
        if (this.functionService.search(contact.organization.company, keyword)) {
          return true;
        } else if (this.functionService.chineseMath(contact.organization.company, keyword)) {
          return true;
        }
      }

      if (contact?.organization?.jobTitle) {
        if (this.functionService.search(contact.organization.jobTitle, keyword)) {
          return true;
        } else if (this.functionService.chineseMath(contact.organization.jobTitle, keyword)) {
          return true;
        }
      }

      if (contact?.phones?.length) {
        const phoneNumbers = contact.phones.map((x: PhonePayload) => {
          return x.number;
        }).join(',');
        if (this.functionService.search(phoneNumbers, keyword)) {
          return true;
        } else if (this.functionService.chineseMath(phoneNumbers, keyword)) {
          return true;
        }
      }

      if (contact?.emails?.length) {
        const emails = contact.emails.map((x: EmailPayload) => {
          return x.address;
        }).join(',');
        if (this.functionService.search(emails, keyword)) {
          return true;
        } else if (this.functionService.chineseMath(emails, keyword)) {
          return true;
        }
      }
    }
    return false;
  }

  sortList(list: ContactPayload[]): ContactPayload[] {
    if (list?.length) {
      list.sort((a: ContactPayload, b: ContactPayload) => {
        return this.functionService.compare(a?.name?.display?.toString()?.toLowerCase(), b?.name?.display?.toString()?.toLowerCase());
      });
    }
    return list;
  }

  validateEmail(email: EmailPayload) {
    if (email?.address) {
      return this.functionService.validateEmail(email.address);
    } else {
      return true;
    }
  }
}
