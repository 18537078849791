import { GuestService } from 'src/app/services/guest/guest.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { UpdateByService } from 'src/app/services/user/update-by.service';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { InvitedByService } from 'src/app/services/setting/invited-by.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { SeatingService } from 'src/app/services/seating/seating.service';
import { SeatingManageService } from 'src/app/services/seating/seating-manage.service';
import { SeatingSummaryService } from 'src/app/services/seating/seating-summary.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { SettingField } from 'src/app/interfaces/database';
import { SeatingSummary } from 'src/app/interfaces/seating';
import { Guest } from 'src/app/interfaces/guest';
import { Seating } from 'src/app/interfaces/seating';
import { SettingFieldType } from 'src/app/types/general';
import { SettingFieldComponent } from 'src/app/components/setting/setting-field/setting-field.component';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';


/**
 * Edit seating component
 */
@Component({
  selector: 'app-seating-edit',
  templateUrl: './seating-edit.component.html',
  styleUrls: ['./seating-edit.component.scss'],
})
export class SeatingEditComponent implements OnInit, OnDestroy {

  /**
   * New seating flag.
   * Edit before seating save.
   */
  newSeating: boolean;
  /**
   * Bulk edit mode.
   */
  bulkEditMode: boolean;
  /**
   * Selected seating list.
   * Applied with bulk edit mode.
   */
  selectedSeatingList: string[];
  /**
   * Seating info.
   */
  seating: Seating;
  /**
   * Seating Summary
   */
  seatingSummary: SeatingSummary;
  /**
   * Seating form
   */
  seatingForm: FormGroup;
  /**
   * Validation msg
   */
  validationMsg: any;
  /**
   * Invited by list
   */
  invitedByList: SettingField[];

  seatingTypeName: string;

  category: string;

  selectedSeatingListName: string;
  /**
   * Guest per seating
   */
  private guestPerSeating = this.seatingSettingService.getGuestPerSeating();
  /**
   * Invited by subcription
   */
  private invitedBySubscription: Subscription;
  /**
   * Seating List subscription
   */
  private seatingListSubscription: Subscription;

  /**
   * Constructor
   * @param formBuilder Form builder
   * @param modalController Modal Controller
   * @param translate Translate service
   * @param seatingService Setting Service
   * @param invitedByService Invited by service
   * @param guestManageService Guest manage service
   * @param guestListService Guest list service
   * @param userService User service
   * @param popupService Popup service
   * @param functionService Function service
   */
  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private translate: TranslateService,
    private settingFieldService: SettingFieldService,
    private invitedByService: InvitedByService,
    private guestService: GuestService,
    private seatingService: SeatingService,
    private seatingSettingService: SeatingSettingService,
    private seatingManageService: SeatingManageService,
    private guestManageService: GuestManageService,
    private seatingSummaryService: SeatingSummaryService,
    private updateByService: UpdateByService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {
    this.unwatch();
  }

  /**
   * Setup seating form.
   * Watch seating list and get seating summary if editing a single existing seating only
   */
  ionViewWillEnter() {
    this.setupForm();
    this.watchInvitedBy();

    this.seatingTypeName = this.getSeatingTypeName();

    if (!this.bulkEditMode && !this.newSeating && this.seating) {
      this.watchSeatingList();
      this.seatingSummary = this.getSeatingSummary(this.seating);
    } else {
      this.seatingSummary = this.getSeatingSummary(null);
    }

    this.selectedSeatingListName = this.getSelectedSeatingListName();
  }

  /**
   * Unwatch before view leave
   */
  ionViewWillLeave() {
    this.unwatch();
  }

  unwatch() {
    this.unwatchInvitedBy();
    this.unwatchSeatingList();
  }

  /**
   * Watch seating list and get seating
   */
  async watchSeatingList() {
    if (!this.seatingListSubscription) {
      this.seatingListSubscription = this.seatingService.observableSeatingList.subscribe(() => {
        this.getSeating();
      });
    }
    
  }

  /**
   * Unwatch seating list
   */
  async unwatchSeatingList() {
    if (this.seatingListSubscription) {
      this.seatingListSubscription.unsubscribe();
      this.seatingListSubscription = null;
    }
  }

  /**
   * Watch invited by list
   */
  async watchInvitedBy() {
    if (!this.invitedBySubscription) {
      this.invitedBySubscription = this.invitedByService.observableInvitedbyList.subscribe((invitedByList: SettingField[]) => {
        this.invitedByList = this.invitedByService.getInvitedByList();
      });
    }
    
  }

  /**
   * Unwatch invited by list
   */
  async unwatchInvitedBy() {
    if (this.invitedBySubscription) {
      this.invitedBySubscription.unsubscribe();
      this.invitedBySubscription = null;
    }
  }

  setupSettingField() {
    this.category = this.getSettingField('category', this.seatingForm?.value?.category);
  }

  /**
   * Setup form
   */
  setupForm() {
    this.seatingForm = this.formBuilder.group({
      count: new FormControl(this.seating && !this.bulkEditMode ? 1 : null, [
        this.seating && !this.bulkEditMode ? Validators.required : Validators.nullValidator,
        Validators.pattern('^[0-9]*$'),
        Validators.min(1)]
      ),
      maxGuest: new FormControl(this.seating && !this.bulkEditMode ? this.guestPerSeating : null, [
        this.seating && !this.bulkEditMode ? Validators.required : Validators.nullValidator,
        Validators.pattern('^[0-9]*$'),
        Validators.min(1)]
      ),
      invitedBy: new FormControl('', []),
      category: new FormControl('', []),
      reserved: new FormControl(false, []),
      name: new FormControl('', [ Validators.maxLength(this.seatingService.nameMaxLength),
        this.seating && !this.bulkEditMode ? Validators.required : Validators.nullValidator
      ])
    });
    this.setupValidationMsg();
    this.setupFormValue();
  }

  /**
   * Setup validation msg
   */
  setupValidationMsg() {
    this.validationMsg = {
      count: [
        { type: 'required', msg: this.translate.instant('VALIDATION.required', {
          field: this.translate.instant('SEATING.lbl.count', { seating: this.getSeatingTypeName() })
        }) },
        { type: 'min', msg: this.translate.instant('VALIDATION.min_number', { number: 1 }) },
        { type: 'pattern', msg: this.translate.instant('VALIDATION.number_only') }
      ],
      maxGuest: [
        { type: 'required', msg: this.translate.instant('VALIDATION.required', {
          field: this.translate.instant('SEATING.lbl.max_guest', { seating: this.getSeatingTypeName() })
        }) },
        { type: 'min', msg: this.translate.instant('VALIDATION.min_number', { number: 1 }) },
        { type: 'pattern', msg: this.translate.instant('VALIDATION.number_only') }
      ],
      name: [
        { type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('SEATING.lbl.name', { seating: this.getSeatingTypeName() }) }) },
        { type: 'maxlength', msg: this.translate.instant('VALIDATION.max_length', { number: this.seatingService.nameMaxLength }) },
      ]
    };
  }

  /**
   * Setup form value
   */
  setupFormValue() {
    if (this.seating && !this.bulkEditMode && this.seatingForm) {
      if (this.seating.name) {
        this.seatingForm.controls.name.setValue(this.seating.name);
      }
      if (this.seating.reserved) {
        this.seatingForm.controls.reserved.setValue(this.seating.reserved);
      }
      if (this.seating.count) {
        this.seatingForm.controls.count.setValue(this.seating.count);
      }
      if (this.seating.maxGuest) {
        this.seatingForm.controls.maxGuest.setValue(this.seating.maxGuest);
      }
      if (this.seating?.invitedBy?.length && this.seating.invitedBy[0]?.value) {
        this.seatingForm.controls.invitedBy.setValue(this.seating.invitedBy[0].value);
      }
      if (this.seating?.category?.length) {
        this.seatingForm.controls.category.setValue( this.seating.category );
      }
      this.seatingForm.updateValueAndValidity();
      this.seatingForm.markAllAsTouched();
    }
    this.setupSettingField();
  }

  /**
   * Add seating count
   */
  addSeating() {
    this.seatingForm.controls.count.setValue(this.seatingForm.value.count + 1);
    this.seatingForm.controls.maxGuest.setValue(this.seatingForm.value.count * this.guestPerSeating);
  }

  /**
   * Minus seating count
   */
  minusSeating() {
    if (this.seatingForm.value.count > 1) {
      this.seatingForm.controls.count.setValue(this.seatingForm.value.count - 1);
      this.seatingForm.controls.maxGuest.setValue(this.seatingForm.value.count * this.guestPerSeating);
    } else if (this.bulkEditMode) {
      this.seatingForm.controls.count.setValue(null);
      this.seatingForm.controls.maxGuest.setValue(null);
    }
  }

  /**
   * Add guest per seating
   */
  addMaxGuest() {
    this.seatingForm.controls.maxGuest.setValue(this.seatingForm.value.maxGuest + 1);
  }

  /**
   * Minus guest per seating
   */
  minusMaxGuest() {
    if (this.seatingForm.value.maxGuest > 1) {
      this.seatingForm.controls.maxGuest.setValue(this.seatingForm.value.maxGuest - 1);
    } else if (this.bulkEditMode) {
      this.seatingForm.controls.count.setValue(null);
    }
  }

  /**
   * Dismiss seating edit modal
   * @param seating Seating info
   */
  async dismissModal(seating?: Seating, dismiss?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ seating, dismiss }); }
    }
  }

  /**
   * Present setting field cmodal
   * @param settingFieldType Setting field type
   * @param selected Selected setting field
   */
  async presentSettingFieldModal(settingFieldType: SettingFieldType, selected: SettingField[]) {
    const modal = await this.modalController.create({
      component: SettingFieldComponent,
      componentProps: {
        settingFieldType,
        selected
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.selected) {
        if (settingFieldType === 'category') {
          if (this.seatingForm.value.category !== result.data.selected) {
            this.seatingForm.controls.category.setValue(result.data.selected);
            this.setupSettingField();
          }
        }
      }
    });
  }

  /**
   * Prompt delete confirmation for current seating
   * @param ionSlide Ion slide element, close if avaiable
   */
  async promptDelete(ionSlide?: any) {
    if (ionSlide) {
      ionSlide.close();
    }
    if (this.seating) {
      const guestList: Guest[] = this.guestService.getGuestList().filter((guest: Guest) => {
        if (guest?.seating === this.seating?.name) {
          return true;
        }
        return false;
      });
      if (guestList?.length) {
        this.popupService.presentAlert(
          this.translate.instant('CRUD.unable_delete_field', {
            field: this.getSeatingTypeName()
          })
          + '<br><br>' +
          this.translate.instant('CRUD.delete_setting_field_guest_count_prompt', {
            field: this.getSeatingTypeName(),
            count: guestList.length
          }), '', '', null, guestList, true
        );
      } else {
        const modal = await this.popupService.presentConfirm(
          this.translate.instant('CRUD.confirm_delete_field', {
            field: this.getSeatingTypeName() })
        );
        modal.onDidDismiss().then(async (result: any) => {
          if (result?.data?.confirm) {
            await this.popupService.presentLoading();
            await this.updateSeating(this.generateDeleteData());
            await this.popupService.dismissLoading();
          }
        });
      }
    }
  }

  checkDuplicateSeatingByName(seatName: string, seatingId: string): boolean {
    const index = this.seatingService.seatingList?.findIndex((x: Seating) => x.name?.toString().toLowerCase() === seatName?.toString().toLowerCase() && x.seatingId !== seatingId);
    if (index === -1) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Get seating info
   */
  getSeating() {
    if (this.seating && this.seating.seatingId) {
      this.seating = this.seatingService.getSeatingById(this.seating.seatingId);
      this.selectedSeatingListName = this.getSelectedSeatingListName();
    }
  }

  /**
   * Get seating summary
   */
  getSeatingSummary(seating: Seating): SeatingSummary {
    return this.seatingSummaryService.getSeatingSummary(seating);
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[]): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList);
  }

  getSelectedSeatingListName(): string {
    return this.seatingService.getSeatingListNameById(this.selectedSeatingList)?.join(', ');
  }

  getSeatingTypeName(): string {
    return this.seatingSettingService.getSeatingTypeName();
  }

  /**
   * Seating form submit
   */
  async seatingFormSubmit() {
    this.seatingForm.markAllAsTouched();
    if (this.seatingForm.valid) {
      await this.popupService.presentLoading();
      const data = this.generateData();
      let error = false;
      if (this.seating && !this.bulkEditMode) {
        if (data.name && this.checkDuplicateSeatingByName(data.name, this.seating.seatingId)) {
          error = true;
          this.popupService.presentAlert(
            this.translate.instant('VALIDATION.duplicate_field',
            { field: this.translate.instant('SEATING.lbl.name', { seating: this.getSeatingTypeName() }) }
          ));
        }
        if (!error) {
          await this.updateSeating(data);
        }
      } else {
        await this.updateSeatingList(data);
      }

      this.popupService.dismissLoading();
      if (!error) {
        this.popupService.saveSuccessToast();
      }
    }
  }

  /**
   * Update single seating
   * @param data new seating data to update
   */
  async updateSeating(data: any) {
    if (data) {
      const seatingSummary = this.getSeatingSummary(this.seating);
      const seating: Seating = this.generateNewSeatingData(this.seating, data);
      if (!this.newSeating && seating) {
        await this.seatingManageService.updateSeating(seating);
        if (seating.name && seatingSummary?.guestList?.length && ((data.name) || (data?.deleted))) {
          const guestIdList: string[] = seatingSummary.guestList.map((guest: Guest) => {
            return guest?.guestId;
          });
          await this.guestManageService.saveGuest(
            { seating: data?.deleted ? '' : data.name }, guestIdList, '', seatingSummary.guestList, true
          );
        }
      }
      this.dismissModal(seating, data && data.deleted ? true : false);
    }
  }

  /**
   * Bulk update seating
   * @param data new seating data to update
   */
  async updateSeatingList(data: any) {
    if (this.bulkEditMode && this.selectedSeatingList?.length) {
      let seatingList: Seating[] = [ ...this.seatingService.seatingList ];
      if (seatingList?.length) {
        seatingList = seatingList.map((seating: Seating) => {
          if (seating?.seatingId && this.selectedSeatingList?.indexOf(seating.seatingId) !== -1) {
            const seatingData = this.generateNewSeatingData(seating, data);
            // seatingData.updateBy = this.updateByService.getUpdateBy();
            // if (!seatingData.createBy) { seatingData.createBy = seatingData.updateBy; }
            return seatingData;
          } else {
            return seating;
          }
        });
        await this.seatingManageService.updateSeatingList(seatingList);
      }
    }
    this.dismissModal();
  }

  /**
   * Generate update seating data
   * @returns Data to be updated
   */
  generateData(): any {
    const data: any = {};
    Object.keys(this.seatingForm.value)?.forEach((key) => {
      if (key === 'invitedBy') {
        if (this.seatingForm?.value?.invitedBy) {
          const invitedByIndex = this.invitedByList?.findIndex((x: SettingField) => x?.value === this.seatingForm?.value?.invitedBy);
          if (invitedByIndex !== -1) {
            if ((this.bulkEditMode && this.invitedByList?.[invitedByIndex])
            || (this.seating && !this.functionService.isEqual([ this.invitedByList[invitedByIndex] ], this.seating?.[key]))) {
              data.invitedBy = [ this.invitedByList[invitedByIndex] ];
            }
          }
        }
      } else {
        if ((this.bulkEditMode && this.seatingForm?.value?.[key])
        || (this.seating && !this.functionService.isEqual(this.seatingForm?.value?.[key], this.seating?.[key]))) {
          data[key] = this.seatingForm.value[key];
        }
      }
    });
    return data;
  }

  /**
   * Generate deleted seating data
   * @returns Data to be updated
   */
  generateDeleteData(): any {
    const data: any = {};
    if (this.seating?.name) {
      data.name = this.seating.name;
      data.deleted = true;
    }
    return data;
  }

  /**
   * Generate new seating data
   * @param data Update data
   * @returns Seating data
   */
  generateNewSeatingData(seating: Seating, data: any): Seating {
    seating = { ...seating };
    if (seating && data) {
      Object.keys(data)?.forEach((key: string) => {
        seating[key] = data[key];
      });
    }
    return seating;
  }

}
