import { ModuleType } from 'src/app/types/general';
import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { GiftService } from 'src/app/services/gift/gift.service';
import { GiftListService } from 'src/app/services/gift/gift-list.service';

import { SettingField } from 'src/app/interfaces/database';
import { Gift } from 'src/app/interfaces/gift';
import { Status } from 'src/app/interfaces/status';
import { GiftType } from 'src/app/types/gift';

/**
 * Gift summary service
 */
@Injectable({
  providedIn: 'root'
})
export class GiftSummaryService implements OnInit, OnDestroy {

  /**
   * Constructor
   * @param giftService Gift service
   * @param giftListService Gift list service
   */
  constructor(
    private giftService: GiftService,
    private giftListService: GiftListService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Get gift list count
   * @param type Gift type
   * @param currency Gift currency
   * @param invitedBy Guest Invited by
   * @param category Guest category
   * @param dietaryReq Guest dietary request
   * @param specialReq Guest special request
   * @param seating Guest seating
   * @param group Guest group status
   * @param status Status
   * @param giftList Gift List
   * @returns Number of count
   */
  getGiftListCount(
    type?: GiftType,
    currency?: string,
    invitedBy?: SettingField,
    category?: SettingField,
    dietaryReq?: SettingField,
    specialReq?: SettingField,
    seating?: any,
    group?: string,
    status?: Status,
    giftList?: Gift[],
    module?: ModuleType,
  ): number {
    if (!giftList?.length) {
      giftList = this.giftService.getGiftList(module);
    }
    if ((type === 'other' || (type === 'cash' && currency)) ||
    invitedBy || category || dietaryReq || specialReq || seating || group || status) {
      const filter: any = {
        enable: true,
        type,
        currency: type === 'cash' && currency ? currency : null,
        invitedBy,
        category: category ? [ category ] : null,
        dietaryReq: dietaryReq ? [ dietaryReq ] : null,
        specialReq: specialReq ? [ specialReq ] : null,
        seating,
        group,
        status,
      };

      if (giftList?.length) {
        giftList = giftList?.filter((gift: Gift) => {
          return this.giftListService.filterGiftByCriteria(gift, filter);
        });
      }
    }
    return giftList?.length ? giftList.length : 0;
  }

  /**
   * Get gift list cash sum
   * @param giftList Gift list
   * @param currency Gift Currency
   * @param invitedBy Guest invited by
   * @param category Guest category
   * @param dietaryReq Guest dietary request
   * @param specialReq Guest special request
   * @param seating Guest seating
   * @param group Guest group
   * @param status Guest status
   * @returns Sum of cash
   */
  getGiftListSum(
    giftList?: Gift[],
    currency?: string,
    invitedBy?: SettingField,
    category?: SettingField,
    dietaryReq?: SettingField,
    specialReq?: SettingField,
    seating?: any,
    group?: string,
    status?: Status,
    module?: ModuleType,
  ): number {
    let filter: any = {};
    if (!giftList) { giftList = this.giftService.getGiftList(module); }

    if (currency || invitedBy || category || dietaryReq || specialReq || seating || group || status) {
      filter = {
        enable: true,
        currency,
        invitedBy,
        category: category ? [ category ] : null,
        dietaryReq: dietaryReq ? [ dietaryReq ] : null,
        specialReq: specialReq ? [ specialReq ] : null,
        seating,
        group,
        status,
      };
      giftList = giftList?.filter((gift: Gift) => {
        return this.giftListService.filterGiftByCriteria(gift, filter);
      });
    }

    return giftList.map((gift: Gift) => {
      return gift.amount;
    })?.reduce((a: number, b: number) => {
      return a + b;
    }, 0);
  }
}
