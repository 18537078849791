import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BlastMsg } from 'src/app/interfaces/blast';
import { Group } from 'src/app/interfaces/group';
import { Guest } from 'src/app/interfaces/guest';
import { BlastUsageDetail, BlastTemplate } from 'src/app/interfaces/blast';
import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { AccountPackageService } from 'src/app/services/account/account-package.service';
import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { BlastSendService } from 'src/app/services/blast/blast-send.service';
import { BlastUsageService } from 'src/app/services/blast/blast-usage.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { GuestListService } from 'src/app/services/guest/guest-list.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestListComponent } from '../../guest/guest-list/guest-list.component';
import { BlastPackageComponent } from '../credit/blast-package/blast-package.component';
import { BlastType } from 'src/app/types/blast';
import { BlastResumeService } from 'src/app/services/blast/blast-resume.service';

@Component({
  selector: 'app-blast-review',
  templateUrl: './blast-review.component.html',
  styleUrls: ['./blast-review.component.scss'],
})
export class BlastReviewComponent  implements OnInit, OnDestroy {

  blastType: BlastType;
  blastUsageDetailList: BlastUsageDetail[];

  blastTemplate: BlastTemplate;
  blastMsgList: BlastMsg[];
  selectedGuestIdList: string[];
  selectedGroupIdList: string[];

  guestList: Guest[];
  groupList: Group[];

  ready: boolean;

  total: number;

  minPayment: number;
  freeBlastCount: number;

  trialMode: boolean;

  estDeliveryTime: {
    min: number;
    max: number;
  };

  constructor(
    private translate: TranslateService,
    private modalController: ModalController,
    private accountInfoService: AccountInfoService,
    private accountPackageService: AccountPackageService,
    private privilegeService: PrivilegeService,
    private guestService: GuestService,
    private guestListService: GuestListService,
    private blastSendService: BlastSendService,
    private blastUsageService: BlastUsageService,
    private blastResumeService: BlastResumeService,
    private popupService: PopupService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
      this.ready = false;
  }

  async ionViewWillEnter() {
    this.setupTrialMode();
    if (this.blastTemplate && (this.selectedGuestIdList?.length || this.selectedGroupIdList?.length)) {
      await this.setupBlastGuestList();
    }
  }

  ionViewWilLeave() {
    this.ready = false;
  }

  setupTrialMode() {
    if (!this.accountPackageService.accountPackage?.accountStatus?.premium && this.accountPackageService.accountPackage?.accountStatus?.trial) {
      this.trialMode = true;
    } else {
      this.trialMode = false;
    }
  }

  async setupBlastGuestList() {
    await this.popupService.presentLoading();
    const { guestList, groupList } = this.blastSendService.setupBlastGuestList(this.blastType, this.selectedGuestIdList, this.selectedGroupIdList, this.blastTemplate);
    await this.setupBlastUsageDetailList(guestList, groupList);
  }

  async setupBlastUsageDetailList(guestList: Guest[], groupList: Group[]) {
    this.guestList = guestList;
    this.groupList = groupList;

    const { blastUsageDetailList, blastMsgList } = this.blastUsageService.calculateBlastUsageDetailList(
      this.blastType,
      guestList,
      groupList,
      { ...this.blastTemplate }
    );
    this.blastUsageDetailList = blastUsageDetailList;
    this.blastMsgList = blastMsgList;
    
    this.estDeliveryTime = this.estimateDeliveryTime();

    this.ready = true;
    setTimeout(() => {
      this.popupService.dismissLoading();
    }, 500);
  }

  /**
   * Dismiss modal
   */
  async dismissModal(saveBlastResumeData?: boolean) {
    if (this.modalController) {
      if (saveBlastResumeData) {
        await this.blastResumeService.saveBlastResumeData(this.blastType, this.blastTemplate, this.selectedGuestIdList, this.selectedGroupIdList);
      } else {
        await this.blastResumeService.clearBlastResumeData();
      }
      const modal = await this.modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    }
  }

  async presentGuestListModal() {
    if (this.selectedGuestIdList?.length || this.selectedGroupIdList?.length) {
      const previewGuestList = this.guestService.getGuestListById(this.selectedGuestIdList);
      const previewGroupList = this.guestService.getGroupListMember(this.selectedGroupIdList);
      const list = previewGuestList.concat(previewGroupList).filter((guest: Guest, index: number, guestList: Guest[]) => {
        return index === guestList.findIndex((x: Guest) => (
          x.guestId === guest.guestId
        ));
      });
      const guestList = this.guestListService.generateGroupList(list);
      const modal = await this.modalController.create({
        component: GuestListComponent,
        cssClass: '',
        componentProps: {
          guestList,
          mode: 'preview',
          editMode: false,
          showGuestSeating: false,
          disableClick: true,
          count: this.selectedGuestIdList?.length + this.selectedGroupIdList?.length,
        }
      });
      modal.present();
    }
  }

  async presentBlastPackageModal(requiredCredit?: number) {
    requiredCredit = Math.abs(requiredCredit);
    const modal = await this.modalController.create({
      component: BlastPackageComponent,
      componentProps: {
        requiredCredit
      }
    });
    modal.present();
    modal.onDidDismiss().then(async (result: any) => {
      if (result?.data?.checkout) {
        this.dismissModal(true);
      }
    })
  }

  setTotal(total: number) {
    this.total = total;
  }

  checkPrivilege(action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege('blast', action);
  }

  estimateDeliveryTime() {
    return this.blastUsageService.estimateDeliveryTime(this.blastUsageService.calculateTotalBlast(this.blastUsageDetailList));
  }

  generateParam() {
    const navigationExtras: NavigationExtras = {
      state: {
        total: this.total,
        blastTemplate: this.blastTemplate,
        blastMsgList: this.blastMsgList,
        guestList: this.guestList,
        groupList: this.groupList,
        selectedGuestIdList: this.selectedGuestIdList,
        selectedGroupIdList: this.selectedGroupIdList,
        transactionType: 'sms',
      }
    };
    return navigationExtras;
  }

  async next() {
    if (this.checkPrivilege('send')) {
      await this.initialBlastMsgCall();
    } else {
      this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
    }
  }

  async goUpgrade() {
    this.accountInfoService.goUpgradeSubscriptionPage();
  }

  async initialBlastMsgCall() {
    await this.popupService.presentLoading();
    await this.blastSendService.initialBlastMsgCall(this.blastType, this.blastTemplate, this.blastMsgList, this.guestList, this.groupList);
    await this.blastResumeService.clearBlastResumeData();
    this.popupService.dismissLoading();
  }

}
