import { Country } from '../interfaces/database';

/**
 * Preloaded country list database
 */
export const CountryList: Country[] = [ {
  name: 'Afghanistan',
  native: 'افغانستان',
  phone: 93,
  continent: 'AS',
  capital: 'Kabul',
  currency: 'AFN',
  languages: ['ps', 'uz', 'tk'],
  locale: [ 'fa-IR', 'uz' ],
  code: 'AF',
  timezones: ['Asia/Kabul'],
  id: 1
}, {
  name: 'Albania',
  native: 'Shqipëria',
  phone: 355,
  continent: 'EU',
  capital: 'Tirana',
  currency: 'ALL',
  languages: ['sq'],
  locale: [ 'sq' ],
  code: 'AL',
  timezones: ['Europe/Tirane'],
  id: 2
}, {
  name: 'Algeria',
  native: 'الجزائر',
  phone: 213,
  continent: 'AF',
  capital: 'Algiers',
  currency: 'DZD',
  languages: ['ar'],
  locale: [ 'ar-DZ' ],
  code: 'DZ',
  timezones: ['Africa/Algiers'],
  id: 3
}, {
  name: 'American Samoa',
  native: 'American Samoa',
  phone: 1684,
  continent: 'OC',
  capital: 'Pago Pago',
  currency: 'USD',
  languages: ['en', 'sm'],
  locale: [ 'en-US' ],
  code: 'AS',
  timezones: ['Pacific/Pago_Pago'],
  id: 4
}, {
  name: 'Andorra',
  native: 'Andorra',
  phone: 376,
  continent: 'EU',
  capital: 'Andorra la Vella',
  currency: 'EUR',
  languages: ['ca'],
  locale: [ 'ca' ],
  code: 'AD',
  timezones: ['Europe/Andorra'],
  id: 5
}, {
  name: 'Angola',
  native: 'Angola',
  phone: 244,
  continent: 'AF',
  capital: 'Luanda',
  currency: 'AOA',
  languages: ['pt'],
  locale: [ 'pt' ],
  code: 'AO',
  timezones: ['Africa/Luanda'],
  id: 6
}, {
  name: 'Anguilla',
  native: 'Anguilla',
  phone: 1264,
  continent: 'NA',
  capital: 'The Valley',
  currency: 'XCD',
  languages: ['en'],
  locale: [ 'en-GB' ],
  code: 'AI',
  timezones: ['America/Anguilla'],
  id: 7
}, {
  name: 'Antigua and Barbuda',
  native: 'Antigua and Barbuda',
  phone: 1268,
  continent: 'NA',
  capital: 'Saint John\'s',
  currency: 'XCD',
  languages: ['en'],
  locale: [ 'en-GB' ],
  code: 'AG',
  timezones: ['America/Antigua'],
  id: 9
}, {
  name: 'Argentina',
  native: 'Argentina',
  phone: 54,
  continent: 'SA',
  capital: 'Buenos Aires',
  currency: 'ARS',
  languages: ['es', 'gn'],
  locale: [ 'es' ],
  code: 'AR',
  timezones: ['America/Argentina/Buenos_Aires', 'America/Argentina/Cordoba', 'America/Argentina/Salta', 'America/Argentina/Jujuy', 'America/Argentina/Tucuman', 'America/Argentina/Catamarca', 'America/Argentina/La_Rioja', 'America/Argentina/San_Juan', 'America/Argentina/Mendoza', 'America/Argentina/San_Luis', 'America/Argentina/Rio_Gallegos', 'America/Argentina/Ushuaia'],
  id: 10
}, {
  name: 'Armenia',
  native: 'Հայաստան',
  phone: 374,
  continent: 'AS',
  capital: 'Yerevan',
  currency: 'AMD',
  languages: ['hy', 'ru'],
  locale: [ 'hy', 'ru' ],
  code: 'AM',
  timezones: ['Asia/Yerevan'],
  id: 11
}, {
  name: 'Aruba',
  native: 'Aruba',
  phone: 297,
  continent: 'NA',
  capital: 'Oranjestad',
  currency: 'AWG',
  languages: ['nl', 'pa'],
  locale: [ 'nl' ],
  code: 'AW',
  timezones: ['America/Aruba'],
  id: 12
}, {
  name: 'Australia',
  native: 'Australia',
  phone: 61,
  continent: 'OC',
  capital: 'Canberra',
  currency: 'AUD',
  languages: ['en'],
  locale: [ 'en-AU' ],
  code: 'AU',
  timezones: ['Australia/Lord_Howe', 'Antarctica/Macquarie', 'Australia/Hobart', 'Australia/Currie', 'Australia/Melbourne', 'Australia/Sydney', 'Australia/Broken_Hill', 'Australia/Brisbane', 'Australia/Lindeman', 'Australia/Adelaide', 'Australia/Darwin', 'Australia/Perth', 'Australia/Eucla'],
  id: 13
}, {
  name: 'Austria',
  native: 'Österreich',
  phone: 43,
  continent: 'EU',
  capital: 'Vienna',
  currency: 'EUR',
  languages: ['de'],
  locale: [ 'de-AT' ],
  code: 'AT',
  timezones: ['Europe/Vienna'],
  id: 14
}, {
  name: 'Azerbaijan',
  native: 'Azərbaycan',
  phone: 994,
  continent: 'AS',
  capital: 'Baku',
  currency: 'AZN',
  languages: ['az'],
  locale: [ 'az' ],
  code: 'AZ',
  timezones: ['Asia/Baku'],
  id: 15
}, {
  name: 'Bahamas',
  native: 'Bahamas',
  phone: 1242,
  continent: 'NA',
  capital: 'Nassau',
  currency: 'BSD',
  languages: ['en'],
  locale: [ 'en-US', 'en-GB' ],
  code: 'BS',
  timezones: ['America/Nassau'],
  id: 16
}, {
  name: 'Bahrain',
  native: '‏البحرين',
  phone: 973,
  continent: 'AS',
  capital: 'Manama',
  currency: 'BHD',
  languages: ['ar'],
  locale: [ 'ar' ],
  code: 'BH',
  timezones: ['Asia/Bahrain'],
  id: 17
}, {
  name: 'Bangladesh',
  native: 'Bangladesh',
  phone: 880,
  continent: 'AS',
  capital: 'Dhaka',
  currency: 'BDT',
  languages: ['bn'],
  locale: [ 'bn' ],
  code: 'BD',
  timezones: ['Asia/Dhaka'],
  id: 18
}, {
  name: 'Barbados',
  native: 'Barbados',
  phone: 1246,
  continent: 'NA',
  capital: 'Bridgetown',
  currency: 'BBD',
  languages: ['en'],
  locale: [ 'en-GB', 'en-US' ],
  code: 'BB',
  timezones: ['America/Barbados'],
  id: 19
}, {
  name: 'Belarus',
  native: 'Белару́сь',
  phone: 375,
  continent: 'EU',
  capital: 'Minsk',
  currency: 'BYN',
  languages: ['be', 'ru'],
  locale: [ 'be', 'ru' ],
  code: 'BY',
  timezones: ['Europe/Minsk'],
  id: 20
}, {
  name: 'Belgium',
  native: 'België',
  phone: 32,
  continent: 'EU',
  capital: 'Brussels',
  currency: 'EUR',
  languages: ['nl', 'fr', 'de'],
  locale: [ 'nl-BE', 'fr', 'de' ],
  code: 'BE',
  timezones: ['Europe/Brussels'],
  id: 21
}, {
  name: 'Belize',
  native: 'Belize',
  phone: 501,
  continent: 'NA',
  capital: 'Belmopan',
  currency: 'BZD',
  languages: ['en', 'es'],
  locale: [ 'en-GB', 'es' ],
  code: 'BZ',
  timezones: ['America/Belize'],
  id: 22
}, {
  name: 'Benin',
  native: 'Bénin',
  phone: 229,
  continent: 'AF',
  capital: 'Porto-Novo',
  currency: 'XOF',
  languages: ['fr'],
  locale: [ 'fr' ],
  code: 'BJ',
  timezones: ['Africa/Porto-Novo'],
  id: 23
}, {
  name: 'Bermuda',
  native: 'Bermuda',
  phone: 1441,
  continent: 'NA',
  capital: 'Hamilton',
  currency: 'BMD',
  languages: ['en'],
  locale: [ 'en-GB' ],
  code: 'BM',
  timezones: ['Atlantic/Bermuda'],
  id: 24
}, {
  name: 'Bhutan',
  native: 'ʼbrug-yul',
  phone: 975,
  continent: 'AS',
  capital: 'Thimphu',
  currency: 'BTN,INR',
  languages: ['dz'],
  locale: [ 'en-IN' ],
  code: 'BT',
  timezones: ['Asia/Thimphu'],
  id: 25
}, {
  name: 'Bolivia',
  native: 'Bolivia',
  phone: 591,
  continent: 'SA',
  capital: 'Sucre',
  currency: 'BOB,BOV',
  languages: ['es', 'ay', 'qu'],
  locale: [ 'es' ],
  code: 'BO',
  timezones: ['America/La_Paz'],
  id: 26
}, {
  name: 'Bosnia and Herzegovina',
  native: 'Bosna i Hercegovina',
  phone: 387,
  continent: 'EU',
  capital: 'Sarajevo',
  currency: 'BAM',
  languages: ['bs', 'hr', 'sr'],
  locale: [ 'bs', 'hr', 'sr' ],
  code: 'BA',
  timezones: ['Europe/Sarajevo'],
  id: 27
}, {
  name: 'Botswana',
  native: 'Botswana',
  phone: 267,
  continent: 'AF',
  capital: 'Gaborone',
  currency: 'BWP',
  languages: ['en', 'tn'],
  locale: [ 'en-GB', 'en-US' ],
  code: 'BW',
  timezones: ['Africa/Gaborone'],
  id: 28
}, {
  name: 'Bouvet Island',
  native: 'Bouvetøya',
  phone: 47,
  continent: 'AN',
  capital: '',
  currency: 'NOK',
  languages: ['no', 'nb', 'nn'],
  locale: [ 'nb', 'nn', 'en-GB', 'en-US' ],
  code: 'BV',
  timezones: ['Europe/Oslo'],
  id: 29
}, {
  name: 'Brazil',
  native: 'Brasil',
  phone: 55,
  continent: 'SA',
  capital: 'Brasília',
  currency: 'BRL',
  languages: ['pt'],
  locale: [ 'pt-BR' ],
  code: 'BR',
  timezones: ['America/Noronha', 'America/Belem', 'America/Fortaleza', 'America/Recife', 'America/Araguaina', 'America/Maceio', 'America/Bahia', 'America/Sao_Paulo', 'America/Campo_Grande', 'America/Cuiaba', 'America/Santarem', 'America/Porto_Velho', 'America/Boa_Vista', 'America/Manaus', 'America/Eirunepe', 'America/Rio_Branco'],
  id: 30
}, {
  name: 'British Indian Ocean Territory',
  native: 'British Indian Ocean Territory',
  phone: 246,
  continent: 'AS',
  capital: 'Diego Garcia',
  currency: 'USD',
  languages: ['en'],
  locale: [ 'en-GB' ],
  code: 'IO',
  timezones: ['Indian/Chagos'],
  id: 31
}, {
  name: 'British Virgin Islands',
  native: 'British Virgin Islands',
  phone: 1284,
  continent: 'NA',
  capital: 'Road Town',
  currency: 'USD',
  languages: ['en'],
  locale: [ 'en-GB' ],
  code: 'VG',
  timezones: ['America/Tortola'],
  id: 239
}, {
  name: 'Brunei',
  native: 'Negara Brunei Darussalam',
  phone: 673,
  continent: 'AS',
  capital: 'Bandar Seri Begawan',
  currency: 'BND',
  languages: ['ms'],
  locale: [ 'ms' ],
  code: 'BN',
  timezones: ['Asia/Brunei'],
  id: 32
}, {
  name: 'Bulgaria',
  native: 'България',
  phone: 359,
  continent: 'EU',
  capital: 'Sofia',
  currency: 'BGN',
  languages: ['bg'],
  locale: [ 'bg' ],
  code: 'BG',
  timezones: ['Europe/Sofia'],
  id: 33
}, {
  name: 'Burkina Faso',
  native: 'Burkina Faso',
  phone: 226,
  continent: 'AF',
  capital: 'Ouagadougou',
  currency: 'XOF',
  languages: ['fr', 'ff'],
  locale: [ 'fr' ],
  code: 'BF',
  timezones: ['Africa/Ouagadougou'],
  id: 34
}, {
  name: 'Burundi',
  native: 'Burundi',
  phone: 257,
  continent: 'AF',
  capital: 'Bujumbura',
  currency: 'BIF',
  languages: ['fr', 'rn'],
  locale: [ 'fr' ],
  code: 'BI',
  timezones: ['Africa/Bujumbura'],
  id: 35
}, {
  name: 'Cambodia',
  native: 'Kâmpŭchéa',
  phone: 855,
  continent: 'AS',
  capital: 'Phnom Penh',
  currency: 'KHR',
  languages: ['km'],
  locale: [ 'km' ],
  code: 'KH',
  timezones: ['Asia/Phnom_Penh'],
  id: 36
}, {
  name: 'Cameroon',
  native: 'Cameroon',
  phone: 237,
  continent: 'AF',
  capital: 'Yaoundé',
  currency: 'XAF',
  languages: ['en', 'fr'],
  locale: [ 'en-GB', 'fr' ],
  code: 'CM',
  timezones: ['Africa/Douala'],
  id: 37
}, {
  name: 'Canada',
  native: 'Canada',
  phone: 1,
  continent: 'NA',
  capital: 'Ottawa',
  currency: 'CAD',
  languages: ['en', 'fr'],
  locale: [ 'en-CA', 'fr-CA' ],
  code: 'CA',
  timezones: ['America/St_Johns', 'America/Halifax', 'America/Glace_Bay', 'America/Moncton', 'America/Goose_Bay', 'America/Blanc-Sablon', 'America/Toronto', 'America/Nipigon', 'America/Thunder_Bay', 'America/Iqaluit', 'America/Pangnirtung', 'America/Atikokan', 'America/Winnipeg', 'America/Rainy_River', 'America/Resolute', 'America/Rankin_Inlet', 'America/Regina', 'America/Swift_Current', 'America/Edmonton', 'America/Cambridge_Bay', 'America/Yellowknife', 'America/Inuvik', 'America/Creston', 'America/Dawson_Creek', 'America/Fort_Nelson', 'America/Vancouver', 'America/Whitehorse', 'America/Dawson'],
  id: 38
}, {
  name: 'Cape Verde',
  native: 'Cabo Verde',
  phone: 238,
  continent: 'AF',
  capital: 'Praia',
  currency: 'CVE',
  languages: ['pt'],
  locale: [ 'pt' ],
  code: 'CV',
  timezones: ['Atlantic/Cape_Verde'],
  id: 39
}, {
  name: 'Cayman Islands',
  native: 'Cayman Islands',
  phone: 1345,
  continent: 'NA',
  capital: 'George Town',
  currency: 'KYD',
  languages: ['en'],
  locale: [ 'en-GB' ],
  code: 'KY',
  timezones: ['America/Cayman'],
  id: 40
}, {
  name: 'Central African Republic',
  native: 'Ködörösêse tî Bêafrîka',
  phone: 236,
  continent: 'AF',
  capital: 'Bangui',
  currency: 'XAF',
  languages: ['fr', 'sg'],
  locale: [ 'fr' ],
  code: 'CF',
  timezones: ['Africa/Bangui'],
  id: 41
}, {
  name: 'Chad',
  native: 'Tchad',
  phone: 235,
  continent: 'AF',
  capital: 'N\'Djamena',
  currency: 'XAF',
  languages: ['fr', 'ar'],
  locale: [ 'fr', 'ar' ],
  code: 'TD',
  timezones: ['Africa/Ndjamena'],
  id: 42
}, {
  name: 'Chile',
  native: 'Chile',
  phone: 56,
  continent: 'SA',
  capital: 'Santiago',
  currency: 'CLF,CLP',
  languages: ['es'],
  locale: [ 'es' ],
  code: 'CL',
  timezones: ['America/Santiago', 'Pacific/Easter'],
  id: 43
}, {
  name: 'China',
  native: '中国',
  phone: 86,
  continent: 'AS',
  capital: 'Beijing',
  currency: 'CNY',
  languages: ['zh'],
  locale: [ 'zh-CN' ],
  code: 'CN',
  timezones: ['Asia/Shanghai', 'Asia/Urumqi'],
  id: 44
}, {
  name: 'Christmas Island',
  native: 'Christmas Island',
  phone: 61,
  continent: 'AS',
  capital: 'Flying Fish Cove',
  currency: 'AUD',
  languages: ['en'],
  locale: [ 'en-AU' ],
  code: 'CX',
  timezones: ['Indian/Christmas'],
  id: 45
}, {
  name: 'Cocos [Keeling] Islands',
  native: 'Cocos (Keeling) Islands',
  phone: 61,
  continent: 'AS',
  capital: 'West Island',
  currency: 'AUD',
  languages: ['en'],
  locale: [ 'en-AU' ],
  code: 'CC',
  timezones: ['Indian/Cocos'],
  id: 46
}, {
  name: 'Colombia',
  native: 'Colombia',
  phone: 57,
  continent: 'SA',
  capital: 'Bogotá',
  currency: 'COP',
  languages: ['es'],
  locale: [ 'es' ],
  code: 'CO',
  timezones: ['America/Bogota'],
  id: 47
}, {
  name: 'Comoros',
  native: 'Komori',
  phone: 269,
  continent: 'AF',
  capital: 'Moroni',
  currency: 'KMF',
  languages: ['ar', 'fr'],
  locale: [ 'ar', 'fr' ],
  code: 'KM',
  timezones: ['Indian/Comoro'],
  id: 48
}, {
  name: 'Cook Islands',
  native: 'Cook Islands',
  phone: 682,
  continent: 'OC',
  capital: 'Avarua',
  currency: 'NZD',
  languages: ['en'],
  locale: [ 'en-NZ' ],
  code: 'CK',
  timezones: ['Pacific/Rarotonga'],
  id: 51
}, {
  name: 'Costa Rica',
  native: 'Costa Rica',
  phone: 506,
  continent: 'NA',
  capital: 'San José',
  currency: 'CRC',
  languages: ['es'],
  locale: [ 'es' ],
  code: 'CR',
  timezones: ['America/Costa_Rica'],
  id: 52
}, {
  name: 'Croatia',
  native: 'Hrvatska',
  phone: 385,
  continent: 'EU',
  capital: 'Zagreb',
  currency: 'HRK',
  languages: ['hr'],
  locale: [ 'hr' ],
  code: 'HR',
  timezones: ['Europe/Zagreb'],
  id: 54
}, {
  name: 'Cuba',
  native: 'Cuba',
  phone: 53,
  continent: 'NA',
  capital: 'Havana',
  currency: 'CUC,CUP',
  languages: ['es'],
  locale: [ 'es' ],
  code: 'CU',
  timezones: ['America/Havana'],
  id: 55
}, {
  name: 'Cyprus',
  native: 'Κύπρος',
  phone: 357,
  continent: 'EU',
  capital: 'Nicosia',
  currency: 'EUR',
  languages: ['el', 'tr', 'hy'],
  locale: [ 'el', 'tr', 'hy' ],
  code: 'CY',
  timezones: ['Asia/Nicosia'],
  id: 56
}, {
  name: 'Czech Republic',
  native: 'Česká republika',
  phone: 420,
  continent: 'EU',
  capital: 'Prague',
  currency: 'CZK',
  languages: ['cs', 'sk'],
  locale: [ 'cs', 'sk' ],
  code: 'CZ',
  timezones: ['Europe/Prague'],
  id: 57
}, {
  name: 'Denmark',
  native: 'Danmark',
  phone: 45,
  continent: 'EU',
  capital: 'Copenhagen',
  currency: 'DKK',
  languages: ['da'],
  locale: [ 'da' ],
  code: 'DK',
  timezones: ['Europe/Copenhagen'],
  id: 58
}, {
  name: 'Djibouti',
  native: 'Djibouti',
  phone: 253,
  continent: 'AF',
  capital: 'Djibouti',
  currency: 'DJF',
  languages: ['fr', 'ar'],
  locale: [ 'fr', 'ar' ],
  code: 'DJ',
  timezones: ['Africa/Djibouti'],
  id: 59
}, {
  name: 'Dominica',
  native: 'Dominica',
  phone: 1767,
  continent: 'NA',
  capital: 'Roseau',
  currency: 'XCD',
  languages: ['en'],
  locale: [ 'en-US', 'en-GB' ],
  code: 'DM',
  timezones: ['America/Dominica'],
  id: 60
}, {
  name: 'Dominican Republic',
  native: 'República Dominicana',
  phone: null,
  continent: 'NA',
  capital: 'Santo Domingo',
  currency: 'DOP',
  languages: ['es'],
  locale: [ 'es' ],
  code: 'DO',
  timezones: ['America/Santo_Domingo'],
  id: 61
}, {
  name: 'Democratic Republic of the Congo',
  native: 'République démocratique du Congo',
  phone: 243,
  continent: 'AF',
  capital: 'Kinshasa',
  currency: 'CDF',
  languages: ['fr', 'ln', 'kg', 'sw', 'lu'],
  locale: [ 'fr' ],
  code: 'CD',
  timezones: ['Africa/Kinshasa', 'Africa/Lubumbashi'],
  id: 50
}, {
  name: 'Ecuador',
  native: 'Ecuador',
  phone: 593,
  continent: 'SA',
  capital: 'Quito',
  currency: 'USD',
  languages: ['es'],
  locale: [ 'es' ],
  code: 'EC',
  timezones: ['America/Guayaquil', 'Pacific/Galapagos'],
  id: 63
}, {
  name: 'Egypt',
  native: 'مصر‎',
  phone: 20,
  continent: 'AF',
  capital: 'Cairo',
  currency: 'EGP',
  languages: ['ar'],
  locale: [ 'ar-EG' ],
  code: 'EG',
  timezones: ['Africa/Cairo'],
  id: 64
}, {
  name: 'El Salvador',
  native: 'El Salvador',
  phone: 503,
  continent: 'NA',
  capital: 'San Salvador',
  currency: 'SVC,USD',
  languages: ['es'],
  locale: [ 'es' ],
  code: 'SV',
  timezones: ['America/El_Salvador'],
  id: 65
}, {
  name: 'Equatorial Guinea',
  native: 'Guinea Ecuatorial',
  phone: 240,
  continent: 'AF',
  capital: 'Malabo',
  currency: 'XAF',
  languages: ['es', 'fr'],
  locale: [ 'es', 'fr' ],
  code: 'GQ',
  timezones: ['Africa/Malabo'],
  id: 66
}, {
  name: 'Eritrea',
  native: 'ኤርትራ',
  phone: 291,
  continent: 'AF',
  capital: 'Asmara',
  currency: 'ERN',
  languages: ['ti', 'ar', 'en'],
  locale: [ 'ar', 'en-US', 'en-GB' ],
  code: 'ER',
  timezones: ['Africa/Asmara'],
  id: 67
}, {
  name: 'Estonia',
  native: 'Eesti',
  phone: 372,
  continent: 'EU',
  capital: 'Tallinn',
  currency: 'EUR',
  languages: ['et'],
  locale: [ 'et' ],
  code: 'EE',
  timezones: ['Europe/Tallinn'],
  id: 68
}, {
  name: 'Ethiopia',
  native: 'ኢትዮጵያ',
  phone: 251,
  continent: 'AF',
  capital: 'Addis Ababa',
  currency: 'ETB',
  languages: ['am'],
  locale: [ 'en-US', 'en-GB' ],
  code: 'ET',
  timezones: ['Africa/Addis_Ababa'],
  id: 69
}, {
  name: 'Falkland Islands',
  native: 'Falkland Islands',
  phone: 500,
  continent: 'SA',
  capital: 'Stanley',
  currency: 'FKP',
  languages: ['en'],
  locale: [ 'en-GB' ],
  code: 'FK',
  timezones: ['Atlantic/Stanley'],
  id: 71
}, {
  name: 'Faroe Islands',
  native: 'Føroyar',
  phone: 298,
  continent: 'EU',
  capital: 'Tórshavn',
  currency: 'DKK',
  languages: ['fo'],
  locale: [ 'da', 'en-US', 'en-GB' ],
  code: 'FO',
  timezones: ['Atlantic/Faroe'],
  id: 72
}, {
  name: 'Fiji',
  native: 'Fiji',
  phone: 679,
  continent: 'OC',
  capital: 'Suva',
  currency: 'FJD',
  languages: ['en', 'fj', 'hi', 'ur'],
  locale: [],
  code: 'FJ',
  timezones: ['Pacific/Fiji'],
  id: 73
}, {
  name: 'Finland',
  native: 'Suomi',
  phone: 358,
  continent: 'EU',
  capital: 'Helsinki',
  currency: 'EUR',
  languages: ['fi', 'sv'],
  locale: [ 'fi', 'sv' ],
  code: 'FI',
  timezones: ['Europe/Helsinki'],
  id: 74
}, {
  name: 'France',
  native: 'France',
  phone: 33,
  continent: 'EU',
  capital: 'Paris',
  currency: 'EUR',
  languages: ['fr'],
  locale: [ 'fr' ],
  code: 'FR',
  timezones: ['Europe/Paris'],
  id: 75
}, {
  name: 'French Guiana',
  native: 'Guyane française',
  phone: 594,
  continent: 'SA',
  capital: 'Cayenne',
  currency: 'EUR',
  languages: ['fr'],
  locale: [ 'fr' ],
  code: 'GF',
  timezones: ['America/Cayenne'],
  id: 76
}, {
  name: 'French Polynesia',
  native: 'Polynésie française',
  phone: 689,
  continent: 'OC',
  capital: 'Papeetē',
  currency: 'XPF',
  languages: ['fr'],
  locale: [ 'fr'],
  code: 'PF',
  timezones: ['Pacific/Tahiti', 'Pacific/Marquesas', 'Pacific/Gambier'],
  id: 77
}, {
  name: 'French Southern and Antarctic Lands',
  native: 'Territoire des Terres australes et antarctiques fr',
  phone: 262,
  continent: 'AN',
  capital: 'Port-aux-Français',
  currency: 'EUR',
  languages: ['fr'],
  locale: [ 'fr' ],
  code: 'TF',
  timezones: ['Indian/Kerguelen'],
  id: 78
}, {
  name: 'Gabon',
  native: 'Gabon',
  phone: 241,
  continent: 'AF',
  capital: 'Libreville',
  currency: 'XAF',
  languages: ['fr'],
  locale: [ 'fr' ],
  code: 'GA',
  timezones: ['Africa/Libreville'],
  id: 79
}, {
  name: 'Gambia',
  native: 'Gambia',
  phone: 220,
  continent: 'AF',
  capital: 'Banjul',
  currency: 'GMD',
  languages: ['en'],
  locale: [ 'en-US', 'en-GB' ],
  code: 'GM',
  timezones: ['Africa/Banjul'],
  id: 80
}, {
  name: 'Georgia',
  native: 'საქართველო',
  phone: 995,
  continent: 'AS',
  capital: 'Tbilisi',
  currency: 'GEL',
  languages: ['ka'],
  locale: [ 'ka' ],
  code: 'GE',
  timezones: ['Asia/Tbilisi'],
  id: 81
}, {
  name: 'Germany',
  native: 'Deutschland',
  phone: 49,
  continent: 'EU',
  capital: 'Berlin',
  currency: 'EUR',
  languages: ['de'],
  locale: [ 'de' ],
  code: 'DE',
  timezones: ['Europe/Berlin', 'Europe/Busingen'],
  id: 82
}, {
  name: 'Ghana',
  native: 'Ghana',
  phone: 233,
  continent: 'AF',
  capital: 'Accra',
  currency: 'GHS',
  languages: ['en'],
  locale: [ 'en-US', 'en-GB' ],
  code: 'GH',
  timezones: ['Africa/Accra'],
  id: 83
}, {
  name: 'Gibraltar',
  native: 'Gibraltar',
  phone: 350,
  continent: 'EU',
  capital: 'Gibraltar',
  currency: 'GIP',
  languages: ['en'],
  locale: [ 'en-GB' ],
  code: 'GI',
  timezones: ['Europe/Gibraltar'],
  id: 84
}, {
  name: 'Greece',
  native: 'Ελλάδα',
  phone: 30,
  continent: 'EU',
  capital: 'Athens',
  currency: 'EUR',
  languages: ['el'],
  locale: [ 'el' ],
  code: 'GR',
  timezones: ['Europe/Athens'],
  id: 85
}, {
  name: 'Greenland',
  native: 'Kalaallit Nunaat',
  phone: 299,
  continent: 'NA',
  capital: 'Nuuk',
  currency: 'DKK',
  languages: ['kl'],
  locale: [ 'da' ],
  code: 'GL',
  timezones: ['America/Godthab', 'America/Danmarkshavn', 'America/Scoresbysund', 'America/Thule'],
  id: 86
}, {
  name: 'Grenada',
  native: 'Grenada',
  phone: 1473,
  continent: 'NA',
  capital: 'St. George\'s',
  currency: 'XCD',
  languages: ['en'],
  locale: [ 'en-GB' ],
  code: 'GD',
  timezones: ['America/Grenada'],
  id: 87
}, {
  name: 'Guadeloupe',
  native: 'Guadeloupe',
  phone: 590,
  continent: 'NA',
  capital: 'Basse-Terre',
  currency: 'EUR',
  languages: ['fr'],
  locale: [ 'fr' ],
  code: 'GP',
  timezones: ['America/Guadeloupe'],
  id: 88
}, {
  name: 'Guam',
  native: 'Guam',
  phone: 1671,
  continent: 'OC',
  capital: 'Hagåtña',
  currency: 'USD',
  languages: ['en', 'ch', 'es'],
  locale: [ 'en-US', 'es' ],
  code: 'GU',
  timezones: ['Pacific/Guam'],
  id: 89
}, {
  name: 'Guatemala',
  native: 'Guatemala',
  phone: 502,
  continent: 'NA',
  capital: 'Guatemala City',
  currency: 'GTQ',
  languages: ['es'],
  locale: [ 'es' ],
  code: 'GT',
  timezones: ['America/Guatemala'],
  id: 90
}, {
  name: 'Guernsey',
  native: 'Guernsey',
  phone: 44,
  continent: 'EU',
  capital: 'St. Peter Port',
  currency: 'GBP',
  languages: ['en', 'fr'],
  locale: [ 'en-GB', 'fr' ],
  code: 'GG',
  timezones: ['Europe/Guernsey'],
  id: 91
}, {
  name: 'Guinea',
  native: 'Guinée',
  phone: 224,
  continent: 'AF',
  capital: 'Conakry',
  currency: 'GNF',
  languages: ['fr', 'ff'],
  locale: [ 'fr' ],
  code: 'GN',
  timezones: ['Africa/Conakry'],
  id: 92
}, {
  name: 'Guinea-Bissau',
  native: 'Guiné-Bissau',
  phone: 245,
  continent: 'AF',
  capital: 'Bissau',
  currency: 'XOF',
  languages: ['pt'],
  locale: [ 'pt' ],
  code: 'GW',
  timezones: ['Africa/Bissau'],
  id: 93
}, {
  name: 'Guyana',
  native: 'Guyana',
  phone: 592,
  continent: 'SA',
  capital: 'Georgetown',
  currency: 'GYD',
  languages: ['en'],
  locale: [ 'en-US', 'en-GB' ],
  code: 'GY',
  timezones: ['America/Guyana'],
  id: 94
}, {
  name: 'Haiti',
  native: 'Haïti',
  phone: 509,
  continent: 'NA',
  capital: 'Port-au-Prince',
  currency: 'HTG,USD',
  languages: ['fr', 'ht'],
  locale: [ 'fr', 'ht' ],
  code: 'HT',
  timezones: ['America/Port-au-Prince'],
  id: 95
}, {
  name: 'Honduras',
  native: 'Honduras',
  phone: 504,
  continent: 'NA',
  capital: 'Tegucigalpa',
  currency: 'HNL',
  languages: ['es'],
  locale: [ 'es' ],
  code: 'HN',
  timezones: ['America/Tegucigalpa'],
  id: 97
}, {
  name: 'Hong Kong',
  native: '香港',
  phone: 852,
  continent: 'AS',
  capital: 'City of Victoria',
  currency: 'HKD',
  languages: ['zh', 'en'],
  locale: [ 'zh-HK', 'en-GB' ],
  code: 'HK',
  timezones: ['Asia/Hong_Kong'],
  id: 98
}, {
  name: 'Hungary',
  native: 'Magyarország',
  phone: 36,
  continent: 'EU',
  capital: 'Budapest',
  currency: 'HUF',
  languages: ['hu'],
  locale: [ 'hu' ],
  code: 'HU',
  timezones: ['Europe/Budapest'],
  id: 99
}, {
  name: 'Iceland',
  native: 'Ísland',
  phone: 354,
  continent: 'EU',
  capital: 'Reykjavik',
  currency: 'ISK',
  languages: ['is'],
  locale: [ 'is' ],
  code: 'IS',
  timezones: ['Atlantic/Reykjavik'],
  id: 100
}, {
  name: 'India',
  native: 'भारत',
  phone: 91,
  continent: 'AS',
  capital: 'New Delhi',
  currency: 'INR',
  languages: ['hi', 'en'],
  locale: [ 'hi', 'en-GB' ],
  code: 'IN',
  timezones: ['Asia/Kolkata'],
  id: 101
}, {
  name: 'Indonesia',
  native: 'Indonesia',
  phone: 62,
  continent: 'AS',
  capital: 'Jakarta',
  currency: 'IDR',
  languages: ['id'],
  locale: [ 'id' ],
  code: 'ID',
  timezones: ['Asia/Jakarta', 'Asia/Pontianak', 'Asia/Makassar', 'Asia/Jayapura'],
  id: 102
}, {
  name: 'Iran',
  native: 'ایران',
  phone: 98,
  continent: 'AS',
  capital: 'Tehran',
  currency: 'IRR',
  languages: ['fa'],
  locale: [ 'fa-IR' ],
  code: 'IR',
  timezones: ['Asia/Tehran'],
  id: 103
}, {
  name: 'Iraq',
  native: 'العراق',
  phone: 964,
  continent: 'AS',
  capital: 'Baghdad',
  currency: 'IQD',
  languages: ['ar', 'ku'],
  locale: [ 'ar' ],
  code: 'IQ',
  timezones: ['Asia/Baghdad'],
  id: 104
}, {
  name: 'Ireland',
  native: 'Éire',
  phone: 353,
  continent: 'EU',
  capital: 'Dublin',
  currency: 'EUR',
  languages: ['ga', 'en'],
  locale: [ 'en-IE' ],
  code: 'IE',
  timezones: ['Europe/Dublin'],
  id: 105
}, {
  name: 'Isle of Man',
  native: 'Isle of Man',
  phone: 44,
  continent: 'EU',
  capital: 'Douglas',
  currency: 'GBP',
  languages: ['en', 'gv'],
  locale: [ 'en-GB', 'en-US' ],
  code: 'IM',
  timezones: ['Europe/Isle_of_Man'],
  id: 136
}, {
  name: 'Israel',
  native: 'יִשְׂרָאֵל',
  phone: 972,
  continent: 'AS',
  capital: 'Jerusalem',
  currency: 'ILS',
  languages: ['he', 'ar'],
  locale: [ 'he', 'ar' ],
  code: 'IL',
  timezones: ['Asia/Jerusalem'],
  id: 106
}, {
  name: 'Italy',
  native: 'Italia',
  phone: 39,
  continent: 'EU',
  capital: 'Rome',
  currency: 'EUR',
  languages: ['it'],
  locale: [ 'it' ],
  code: 'IT',
  timezones: ['Europe/Rome'],
  id: 107
}, {
  name: 'Ivory Coast',
  native: 'Côte d\'Ivoire',
  phone: 225,
  continent: 'AF',
  capital: 'Yamoussoukro',
  currency: 'XOF',
  languages: ['fr'],
  locale: [ 'fr' ],
  code: 'CI',
  timezones: ['Africa/Abidjan'],
  id: 53
}, {
  name: 'Jamaica',
  native: 'Jamaica',
  phone: 1876,
  continent: 'NA',
  capital: 'Kingston',
  currency: 'JMD',
  languages: ['en'],
  locale: [ 'en-US', 'en-GB' ],
  code: 'JM',
  timezones: ['America/Jamaica'],
  id: 108
}, {
  name: 'Japan',
  native: '日本',
  phone: 81,
  continent: 'AS',
  capital: 'Tokyo',
  currency: 'JPY',
  languages: ['ja'],
  locale: [ 'ja', 'ja-Hira' ],
  code: 'JP',
  timezones: ['Asia/Tokyo'],
  id: 109
}, {
  name: 'Jersey',
  native: 'Jersey',
  phone: 44,
  continent: 'EU',
  capital: 'Saint Helier',
  currency: 'GBP',
  languages: ['en', 'fr'],
  locale: [ 'en-GB', 'fr' ],
  code: 'JE',
  timezones: ['Europe/Jersey'],
  id: 110
}, {
  name: 'Jordan',
  native: 'الأردن',
  phone: 962,
  continent: 'AS',
  capital: 'Amman',
  currency: 'JOD',
  languages: ['ar'],
  locale: [ 'ar' ],
  code: 'JO',
  timezones: ['Asia/Amman'],
  id: 111
}, {
  name: 'Kazakhstan',
  native: 'Қазақстан',
  phone: null,
  continent: 'AS',
  capital: 'Astana',
  currency: 'KZT',
  languages: ['kk', 'ru'],
  locale: [ 'kk', 'ru' ],
  code: 'KZ',
  timezones: ['Asia/Almaty', 'Asia/Qyzylorda', 'Asia/Aqtobe', 'Asia/Aqtau', 'Asia/Oral'],
  id: 112
}, {
  name: 'Kenya',
  native: 'Kenya',
  phone: 254,
  continent: 'AF',
  capital: 'Nairobi',
  currency: 'KES',
  languages: ['en', 'sw'],
  locale: [ 'en-GB', 'en-US' ],
  code: 'KE',
  timezones: ['Africa/Nairobi'],
  id: 113
}, {
  name: 'Kiribati',
  native: 'Kiribati',
  phone: 686,
  continent: 'OC',
  capital: 'South Tarawa',
  currency: 'AUD',
  languages: ['en'],
  locale: [ 'en-AU' ],
  code: 'KI',
  timezones: ['Pacific/Tarawa', 'Pacific/Enderbury', 'Pacific/Kiritimati'],
  id: 114
}, {
  name: 'Kuwait',
  native: 'الكويت',
  phone: 965,
  continent: 'AS',
  capital: 'Kuwait City',
  currency: 'KWD',
  languages: ['ar'],
  locale: [ 'ar' ],
  code: 'KW',
  timezones: ['Asia/Kuwait'],
  id: 117
}, {
  name: 'Kyrgyzstan',
  native: 'Кыргызстан',
  phone: 996,
  continent: 'AS',
  capital: 'Bishkek',
  currency: 'KGS',
  languages: ['ky', 'ru'],
  locale: [ 'ru' ],
  code: 'KG',
  timezones: ['Asia/Bishkek'],
  id: 118
}, {
  name: 'Laos',
  native: 'ສປປລາວ',
  phone: 856,
  continent: 'AS',
  capital: 'Vientiane',
  currency: 'LAK',
  languages: ['lo'],
  locale: [ 'en-GB', 'th' ],
  code: 'LA',
  timezones: ['Asia/Vientiane'],
  id: 119
}, {
  name: 'Latvia',
  native: 'Latvija',
  phone: 371,
  continent: 'EU',
  capital: 'Riga',
  currency: 'EUR',
  languages: ['lv'],
  locale: [ 'lv' ],
  code: 'LV',
  timezones: ['Europe/Riga'],
  id: 120
}, {
  name: 'Lebanon',
  native: 'لبنان',
  phone: 961,
  continent: 'AS',
  capital: 'Beirut',
  currency: 'LBP',
  languages: ['ar', 'fr'],
  locale: [ 'ar', 'fr' ],
  code: 'LB',
  timezones: ['Asia/Beirut'],
  id: 121
}, {
  name: 'Lesotho',
  native: 'Lesotho',
  phone: 266,
  continent: 'AF',
  capital: 'Maseru',
  currency: 'LSL,ZAR',
  languages: ['en', 'st'],
  locale: [ 'en-ZA' ],
  code: 'LS',
  timezones: ['Africa/Maseru'],
  id: 122
}, {
  name: 'Liberia',
  native: 'Liberia',
  phone: 231,
  continent: 'AF',
  capital: 'Monrovia',
  currency: 'LRD',
  languages: ['en'],
  locale: [ 'en-US', 'en-GB' ],
  code: 'LR',
  timezones: ['Africa/Monrovia'],
  id: 123
}, {
  name: 'Libya',
  native: '‏ليبيا',
  phone: 218,
  continent: 'AF',
  capital: 'Tripoli',
  currency: 'LYD',
  languages: ['ar'],
  locale: [ 'ar' ],
  code: 'LY',
  timezones: ['Africa/Tripoli'],
  id: 124
}, {
  name: 'Liechtenstein',
  native: 'Liechtenstein',
  phone: 423,
  continent: 'EU',
  capital: 'Vaduz',
  currency: 'CHF',
  languages: ['de'],
  locale: [ 'de' ],
  code: 'LI',
  timezones: ['Europe/Vaduz'],
  id: 125
}, {
  name: 'Lithuania',
  native: 'Lietuva',
  phone: 370,
  continent: 'EU',
  capital: 'Vilnius',
  currency: 'EUR',
  languages: ['lt'],
  locale: [ 'lt' ],
  code: 'LT',
  timezones: ['Europe/Vilnius'],
  id: 126
}, {
  name: 'Luxembourg',
  native: 'Luxembourg',
  phone: 352,
  continent: 'EU',
  capital: 'Luxembourg',
  currency: 'EUR',
  languages: ['fr', 'de', 'lb'],
  locale: [ 'fr', 'de', 'lb' ],
  code: 'LU',
  timezones: ['Europe/Luxembourg'],
  id: 127
}, {
  name: 'Macao',
  native: '澳門',
  phone: 853,
  continent: 'AS',
  capital: '',
  currency: 'MOP',
  languages: ['zh', 'pt'],
  locale: [ 'zh-HK', 'zh-CN', 'pt' ],
  code: 'MO',
  timezones: ['Asia/Macau'],
  id: 128
}, {
  name: 'North Macedonia',
  native: 'Северна Македонија',
  phone: 389,
  continent: 'EU',
  capital: 'Skopje',
  currency: 'MKD',
  languages: ['mk'],
  locale: [ 'mk' ],
  code: 'MK',
  timezones: ['Europe/Skopje'],
  id: 129
}, {
  name: 'Madagascar',
  native: 'Madagasikara',
  phone: 261,
  continent: 'AF',
  capital: 'Antananarivo',
  currency: 'MGA',
  languages: ['fr', 'mg'],
  locale: [ 'fr' ],
  code: 'MG',
  timezones: ['Indian/Antananarivo'],
  id: 130
}, {
  name: 'Malawi',
  native: 'Malawi',
  phone: 265,
  continent: 'AF',
  capital: 'Lilongwe',
  currency: 'MWK',
  languages: ['en', 'ny'],
  locale: [ 'en-US', 'en-GB' ],
  code: 'MW',
  timezones: ['Africa/Blantyre'],
  id: 131
}, {
  name: 'Malaysia',
  native: 'Malaysia',
  phone: 60,
  continent: 'AS',
  capital: 'Kuala Lumpur',
  currency: 'MYR',
  languages: ['ms', 'en' ],
  locale: [ 'ms', 'en-GB', 'zh-CN', 'zh-TW' ],
  code: 'MY',
  timezones: ['Asia/Kuala_Lumpur', 'Asia/Kuching'],
  id: 132
}, {
  name: 'Maldives',
  native: 'Maldives',
  phone: 960,
  continent: 'AS',
  capital: 'Malé',
  currency: 'MVR',
  languages: ['dv'],
  locale: [ 'en-US' ],
  code: 'MV',
  timezones: ['Indian/Maldives'],
  id: 133
}, {
  name: 'Mali',
  native: 'Mali',
  phone: 223,
  continent: 'AF',
  capital: 'Bamako',
  currency: 'XOF',
  languages: ['fr'],
  locale: [ 'fr' ],
  code: 'ML',
  timezones: ['Africa/Bamako'],
  id: 134
}, {
  name: 'Malta',
  native: 'Malta',
  phone: 356,
  continent: 'EU',
  capital: 'Valletta',
  currency: 'EUR',
  languages: ['mt', 'en'],
  locale: [ 'mt', 'en-US', 'en-GB' ],
  code: 'MT',
  timezones: ['Europe/Malta'],
  id: 135
}, {
  name: 'Marshall Islands',
  native: 'M̧ajeļ',
  phone: 692,
  continent: 'OC',
  capital: 'Majuro',
  currency: 'USD',
  languages: ['en', 'mh'],
  locale: [ 'en-US', 'en-GB' ],
  code: 'MH',
  timezones: ['Pacific/Majuro', 'Pacific/Kwajalein'],
  id: 137
}, {
  name: 'Martinique',
  native: 'Martinique',
  phone: 596,
  continent: 'NA',
  capital: 'Fort-de-France',
  currency: 'EUR',
  languages: ['fr'],
  locale: [ 'fr' ],
  code: 'MQ',
  timezones: ['America/Martinique'],
  id: 138
}, {
  name: 'Mauritania',
  native: 'موريتانيا',
  phone: 222,
  continent: 'AF',
  capital: 'Nouakchott',
  currency: 'MRU',
  languages: ['ar'],
  locale: [ 'ar' ],
  code: 'MR',
  timezones: ['Africa/Nouakchott'],
  id: 139
}, {
  name: 'Mauritius',
  native: 'Maurice',
  phone: 230,
  continent: 'AF',
  capital: 'Port Louis',
  currency: 'MUR',
  languages: ['en'],
  locale: [ 'en-US', 'en-GB' ],
  code: 'MU',
  timezones: ['Indian/Mauritius'],
  id: 140
}, {
  name: 'Mayotte',
  native: 'Mayotte',
  phone: 262,
  continent: 'AF',
  capital: 'Mamoudzou',
  currency: 'EUR',
  languages: ['fr'],
  locale: [ 'fr' ],
  code: 'YT',
  timezones: ['Indian/Mayotte'],
  id: 141
}, {
  name: 'Mexico',
  native: 'México',
  phone: 52,
  continent: 'NA',
  capital: 'Mexico City',
  currency: 'MXN',
  languages: ['es'],
  locale: [ 'es' ],
  code: 'MX',
  timezones: ['America/Mexico_City', 'America/Cancun', 'America/Merida', 'America/Monterrey', 'America/Matamoros', 'America/Mazatlan', 'America/Chihuahua', 'America/Ojinaga', 'America/Hermosillo', 'America/Tijuana', 'America/Bahia_Banderas'],
  id: 142
}, {
  name: 'Federated States of Micronesia',
  native: 'Federated States of Micronesia',
  phone: 691,
  continent: 'OC',
  capital: 'Palikir',
  currency: 'USD',
  languages: ['en'],
  locale: [ 'en-US' ],
  code: 'FM',
  timezones: ['Pacific/Chuuk', 'Pacific/Pohnpei', 'Pacific/Kosrae'],
  id: 143
}, {
  name: 'Moldova',
  native: 'Moldova',
  phone: 373,
  continent: 'EU',
  capital: 'Chișinău',
  currency: 'MDL',
  languages: ['ro'],
  locale: [ 'ro' ],
  code: 'MD',
  timezones: ['Europe/Chisinau'],
  id: 144
}, {
  name: 'Monaco',
  native: 'Monaco',
  phone: 377,
  continent: 'EU',
  capital: 'Monaco',
  currency: 'EUR',
  languages: ['fr'],
  locale: [ 'fr' ],
  code: 'MC',
  timezones: ['Europe/Monaco'],
  id: 145
}, {
  name: 'Mongolia',
  native: 'Монгол улс',
  phone: 976,
  continent: 'AS',
  capital: 'Ulan Bator',
  currency: 'MNT',
  languages: ['mn'],
  locale: [ 'mn' ],
  code: 'MN',
  timezones: ['Asia/Ulaanbaatar', 'Asia/Hovd', 'Asia/Choibalsan'],
  id: 146
}, {
  name: 'Montserrat',
  native: 'Montserrat',
  phone: 1664,
  continent: 'NA',
  capital: 'Plymouth',
  currency: 'XCD',
  languages: ['en'],
  locale: [ 'en-US', 'en-GB' ],
  code: 'MS',
  timezones: ['America/Montserrat'],
  id: 147
}, {
  name: 'Morocco',
  native: 'المغرب',
  phone: 212,
  continent: 'AF',
  capital: 'Rabat',
  currency: 'MAD',
  languages: ['ar'],
  locale: [ 'ar-MA' ],
  code: 'MA',
  timezones: ['Africa/Casablanca'],
  id: 148
}, {
  name: 'Mozambique',
  native: 'Moçambique',
  phone: 258,
  continent: 'AF',
  capital: 'Maputo',
  currency: 'MZN',
  languages: ['pt'],
  locale: [ 'pt' ],
  code: 'MZ',
  timezones: ['Africa/Maputo'],
  id: 149
}, {
  name: 'Myanmar [Burma]',
  native: 'မြန်မာ',
  phone: 95,
  continent: 'AS',
  capital: 'Naypyidaw',
  currency: 'MMK',
  languages: ['my'],
  locale: [ 'bn' ],
  code: 'MM',
  timezones: ['Asia/Rangoon'],
  id: 150
}, {
  name: 'Namibia',
  native: 'Namibia',
  phone: 264,
  continent: 'AF',
  capital: 'Windhoek',
  currency: 'NAD,ZAR',
  languages: ['en', 'af'],
  locale: [ 'en-ZA', 'af' ],
  code: 'NA',
  timezones: ['Africa/Windhoek'],
  id: 151
}, {
  name: 'Nauru',
  native: 'Nauru',
  phone: 674,
  continent: 'OC',
  capital: 'Yaren',
  currency: 'AUD',
  languages: ['en', 'na'],
  locale: [ 'en-AU' ],
  code: 'NR',
  timezones: ['Pacific/Nauru'],
  id: 152
}, {
  name: 'Nepal',
  native: 'नपल',
  phone: 977,
  continent: 'AS',
  capital: 'Kathmandu',
  currency: 'NPR',
  languages: ['ne'],
  locale: [ 'en-IN' ],
  code: 'NP',
  timezones: ['Asia/Kathmandu'],
  id: 153
}, {
  name: 'Netherlands',
  native: 'Nederland',
  phone: 31,
  continent: 'EU',
  capital: 'Amsterdam',
  currency: 'EUR',
  languages: ['nl'],
  locale: [ 'nl', 'fy' ],
  code: 'NL',
  timezones: ['Europe/Amsterdam'],
  id: 155
}, {
  name: 'New Caledonia',
  native: 'Nouvelle-Calédonie',
  phone: 687,
  continent: 'OC',
  capital: 'Nouméa',
  currency: 'XPF',
  languages: ['fr'],
  locale: [ 'fr' ],
  code: 'NC',
  timezones: ['Pacific/Noumea'],
  id: 156
}, {
  name: 'New Zealand',
  native: 'New Zealand',
  phone: 64,
  continent: 'OC',
  capital: 'Wellington',
  currency: 'NZD',
  languages: ['en', 'mi'],
  locale: [ 'en-NZ' ],
  code: 'NZ',
  timezones: ['Pacific/Auckland', 'Pacific/Chatham'],
  id: 157
}, {
  name: 'Nicaragua',
  native: 'Nicaragua',
  phone: 505,
  continent: 'NA',
  capital: 'Managua',
  currency: 'NIO',
  languages: ['es'],
  locale: [ 'es' ],
  code: 'NI',
  timezones: ['America/Managua'],
  id: 158
}, {
  name: 'Niger',
  native: 'Niger',
  phone: 227,
  continent: 'AF',
  capital: 'Niamey',
  currency: 'XOF',
  languages: ['fr'],
  locale: [ 'fr' ],
  code: 'NE',
  timezones: ['Africa/Niamey'],
  id: 159
}, {
  name: 'Nigeria',
  native: 'Nigeria',
  phone: 234,
  continent: 'AF',
  capital: 'Abuja',
  currency: 'NGN',
  languages: ['en'],
  locale: [ 'en-ZA' ],
  code: 'NG',
  timezones: ['Africa/Lagos'],
  id: 160
}, {
  name: 'Niue',
  native: 'Niuē',
  phone: 683,
  continent: 'OC',
  capital: 'Alofi',
  currency: 'NZD',
  languages: ['en'],
  locale: [ 'en-NZ' ],
  code: 'NU',
  timezones: ['Pacific/Niue'],
  id: 161
}, {
  name: 'Norfolk Island',
  native: 'Norfolk Island',
  phone: 672,
  continent: 'OC',
  capital: 'Kingston',
  currency: 'AUD',
  languages: ['en'],
  locale: [ 'en-AU' ],
  code: 'NF',
  timezones: ['Pacific/Norfolk'],
  id: 162
}, {
  name: 'North Korea',
  native: '북한',
  phone: 850,
  continent: 'AS',
  capital: 'Pyongyang',
  currency: 'KPW',
  languages: ['ko'],
  locale: [ 'ko' ],
  code: 'KP',
  timezones: ['Asia/Pyongyang'],
  id: 115
}, {
  name: 'Northern Mariana Islands',
  native: 'Northern Mariana Islands',
  phone: 1670,
  continent: 'OC',
  capital: 'Saipan',
  currency: 'USD',
  languages: ['en', 'ch'],
  locale: [ 'en-US' ],
  code: 'MP',
  timezones: ['Pacific/Saipan'],
  id: 163
}, {
  name: 'Norway',
  native: 'Norge',
  phone: 47,
  continent: 'EU',
  capital: 'Oslo',
  currency: 'NOK',
  languages: ['no', 'nb', 'nn'],
  locale: [ 'nb', 'mn' ],
  code: 'NO',
  timezones: ['Europe/Oslo'],
  id: 164
}, {
  name: 'Oman',
  native: 'عمان',
  phone: 968,
  continent: 'AS',
  capital: 'Muscat',
  currency: 'OMR',
  languages: ['ar'],
  locale: [ 'ar' ],
  code: 'OM',
  timezones: ['Asia/Muscat'],
  id: 165
}, {
  name: 'Pakistan',
  native: 'Pakistan',
  phone: 92,
  continent: 'AS',
  capital: 'Islamabad',
  currency: 'PKR',
  languages: ['en', 'ur'],
  locale: [ 'en-IN' ],
  code: 'PK',
  timezones: ['Asia/Karachi'],
  id: 166
}, {
  name: 'Palau',
  native: 'Palau',
  phone: 680,
  continent: 'OC',
  capital: 'Ngerulmud',
  currency: 'USD',
  languages: ['en'],
  locale: [ 'en-US' ],
  code: 'PW',
  timezones: ['Pacific/Palau'],
  id: 167
}, {
  name: 'Palestine',
  native: 'فلسطين',
  phone: 970,
  continent: 'AS',
  capital: 'Ramallah',
  currency: 'ILS',
  languages: ['ar'],
  locale: [ 'ar' ],
  code: 'PS',
  timezones: ['Asia/Gaza', 'Asia/Hebron'],
  id: 168
}, {
  name: 'Panama',
  native: 'Panamá',
  phone: 507,
  continent: 'NA',
  capital: 'Panama City',
  currency: 'PAB,USD',
  languages: ['es'],
  locale: [ 'es' ],
  code: 'PA',
  timezones: ['America/Panama'],
  id: 169
}, {
  name: 'Papua New Guinea',
  native: 'Papua Niugini',
  phone: 675,
  continent: 'OC',
  capital: 'Port Moresby',
  currency: 'PGK',
  languages: ['en'],
  locale: [ 'en-US' ],
  code: 'PG',
  timezones: ['Pacific/Port_Moresby', 'Pacific/Bougainville'],
  id: 170
}, {
  name: 'Paraguay',
  native: 'Paraguay',
  phone: 595,
  continent: 'SA',
  capital: 'Asunción',
  currency: 'PYG',
  languages: ['es', 'gn'],
  locale: [ 'es' ],
  code: 'PY',
  timezones: ['America/Asuncion'],
  id: 171
}, {
  name: 'Peru',
  native: 'Perú',
  phone: 51,
  continent: 'SA',
  capital: 'Lima',
  currency: 'PEN',
  languages: ['es'],
  locale: [ 'es' ],
  code: 'PE',
  timezones: ['America/Lima'],
  id: 172
}, {
  name: 'Philippines',
  native: 'Pilipinas',
  phone: 63,
  continent: 'AS',
  capital: 'Manila',
  currency: 'PHP',
  languages: ['en'],
  locale: [ 'en-US' ],
  code: 'PH',
  timezones: ['Asia/Manila'],
  id: 173
}, {
  name: 'Pitcairn Islands',
  native: 'Pitcairn Islands',
  phone: 64,
  continent: 'OC',
  capital: 'Adamstown',
  currency: 'NZD',
  languages: ['en'],
  locale: [ 'en-NZ' ],
  code: 'PN',
  timezones: ['Pacific/Pitcairn'],
  id: 174
}, {
  name: 'Poland',
  native: 'Polska',
  phone: 48,
  continent: 'EU',
  capital: 'Warsaw',
  currency: 'PLN',
  languages: ['pl'],
  locale: [ 'pl' ],
  code: 'PL',
  timezones: ['Europe/Warsaw'],
  id: 175
}, {
  name: 'Portugal',
  native: 'Portugal',
  phone: 351,
  continent: 'EU',
  capital: 'Lisbon',
  currency: 'EUR',
  languages: ['pt'],
  locale: [ 'pt' ],
  code: 'PT',
  timezones: ['Europe/Lisbon', 'Atlantic/Madeira', 'Atlantic/Azores'],
  id: 176
}, {
  name: 'Puerto Rico',
  native: 'Puerto Rico',
  phone: null,
  continent: 'NA',
  capital: 'San Juan',
  currency: 'USD',
  languages: ['es', 'en'],
  locale: [ 'es', 'en-US' ],
  code: 'PR',
  timezones: ['America/Puerto_Rico'],
  id: 177
}, {
  name: 'Qatar',
  native: 'قطر',
  phone: 974,
  continent: 'AS',
  capital: 'Doha',
  currency: 'QAR',
  languages: ['ar'],
  locale: [ 'ar' ],
  code: 'QA',
  timezones: ['Asia/Qatar'],
  id: 178
}, {
  name: 'Republic of the Congo',
  native: 'République du Congo',
  phone: 242,
  continent: 'AF',
  capital: 'Brazzaville',
  currency: 'XAF',
  languages: ['fr', 'ln'],
  locale: [ 'fr' ],
  code: 'CG',
  timezones: ['Africa/Brazzaville'],
  id: 49
}, {
  name: 'Réunion',
  native: 'La Réunion',
  phone: 262,
  continent: 'AF',
  capital: 'Saint-Denis',
  currency: 'EUR',
  languages: ['fr'],
  locale: [ 'fr' ],
  code: 'RE',
  timezones: ['Indian/Reunion'],
  id: 179
}, {
  name: 'Romania',
  native: 'România',
  phone: 40,
  continent: 'EU',
  capital: 'Bucharest',
  currency: 'RON',
  languages: ['ro'],
  locale: [ 'ro' ],
  code: 'RO',
  timezones: ['Europe/Bucharest'],
  id: 180
}, {
  name: 'Russia',
  native: 'Россия',
  phone: 7,
  continent: 'EU',
  capital: 'Moscow',
  currency: 'RUB',
  languages: ['ru'],
  locale: [ 'ru' ],
  code: 'RU',
  timezones: ['Europe/Kaliningrad', 'Europe/Moscow', 'Europe/Simferopol', 'Europe/Volgograd', 'Europe/Kirov', 'Europe/Astrakhan', 'Europe/Samara', 'Europe/Ulyanovsk', 'Asia/Yekaterinburg', 'Asia/Omsk', 'Asia/Novosibirsk', 'Asia/Barnaul', 'Asia/Tomsk', 'Asia/Novokuznetsk', 'Asia/Krasnoyarsk', 'Asia/Irkutsk', 'Asia/Chita', 'Asia/Yakutsk', 'Asia/Khandyga', 'Asia/Vladivostok', 'Asia/Ust-Nera', 'Asia/Magadan', 'Asia/Sakhalin', 'Asia/Srednekolymsk', 'Asia/Kamchatka', 'Asia/Anadyr'],
  id: 181
}, {
  name: 'Rwanda',
  native: 'Rwanda',
  phone: 250,
  continent: 'AF',
  capital: 'Kigali',
  currency: 'RWF',
  languages: ['rw', 'en', 'fr'],
  locale: [ 'en-ZA', 'fr' ],
  code: 'RW',
  timezones: ['Africa/Kigali'],
  id: 182
}, {
  name: 'Saint Kitts and Nevis',
  native: 'Saint Kitts and Nevis',
  phone: 1869,
  continent: 'NA',
  capital: 'Basseterre',
  currency: 'XCD',
  languages: ['en'],
  locale: [ 'en-GB' ],
  code: 'KN',
  timezones: ['America/St_Kitts'],
  id: 184
}, {
  name: 'Saint Lucia',
  native: 'Saint Lucia',
  phone: 1758,
  continent: 'NA',
  capital: 'Castries',
  currency: 'XCD',
  languages: ['en'],
  locale: [ 'en-US' ],
  code: 'LC',
  timezones: ['America/St_Lucia'],
  id: 185
}, {
  name: 'Saint Pierre and Miquelon',
  native: 'Saint-Pierre-et-Miquelon',
  phone: 508,
  continent: 'NA',
  capital: 'Saint-Pierre',
  currency: 'EUR',
  languages: ['fr'],
  locale: [ 'fr' ],
  code: 'PM',
  timezones: ['America/Miquelon'],
  id: 186
}, {
  name: 'Saint Vincent and the Grenadines',
  native: 'Saint Vincent and the Grenadines',
  phone: 1784,
  continent: 'NA',
  capital: 'Kingstown',
  currency: 'XCD',
  languages: ['en'],
  locale: [ 'en-US' ],
  code: 'VC',
  timezones: ['America/St_Vincent'],
  id: 187
}, {
  name: 'Samoa',
  native: 'Samoa',
  phone: 685,
  continent: 'OC',
  capital: 'Apia',
  currency: 'WST',
  languages: ['sm', 'en'],
  locale: [ 'en-US' ],
  code: 'WS',
  timezones: ['Pacific/Apia'],
  id: 188
}, {
  name: 'San Marino',
  native: 'San Marino',
  phone: 378,
  continent: 'EU',
  capital: 'City of San Marino',
  currency: 'EUR',
  languages: ['it'],
  locale: [ 'it' ],
  code: 'SM',
  timezones: ['Europe/San_Marino'],
  id: 189
}, {
  name: 'São Tomé and Príncipe',
  native: 'São Tomé e Príncipe',
  phone: 239,
  continent: 'AF',
  capital: 'São Tomé',
  currency: 'STN',
  languages: ['pt'],
  locale: [ 'pt' ],
  code: 'ST',
  timezones: ['Africa/Sao_Tome'],
  id: 190
}, {
  name: 'Saudi Arabia',
  native: 'العربية السعودية',
  phone: 966,
  continent: 'AS',
  capital: 'Riyadh',
  currency: 'SAR',
  languages: ['ar'],
  locale: [ 'ar-SA' ],
  code: 'SA',
  timezones: ['Asia/Riyadh'],
  id: 191
}, {
  name: 'Senegal',
  native: 'Sénégal',
  phone: 221,
  continent: 'AF',
  capital: 'Dakar',
  currency: 'XOF',
  languages: ['fr'],
  locale: [ 'fr' ],
  code: 'SN',
  timezones: ['Africa/Dakar'],
  id: 192
}, {
  name: 'Serbia',
  native: 'Србија',
  phone: 381,
  continent: 'EU',
  capital: 'Belgrade',
  currency: 'RSD',
  languages: ['sr'],
  locale: [ 'sr' ],
  code: 'RS',
  timezones: ['Europe/Belgrade'],
  id: 193
}, {
  name: 'Seychelles',
  native: 'Seychelles',
  phone: 248,
  continent: 'AF',
  capital: 'Victoria',
  currency: 'SCR',
  languages: ['fr', 'en'],
  locale: [ 'fr', 'en-IN' ],
  code: 'SC',
  timezones: ['Indian/Mahe'],
  id: 194
}, {
  name: 'Sierra Leone',
  native: 'Sierra Leone',
  phone: 232,
  continent: 'AF',
  capital: 'Freetown',
  currency: 'SLL',
  languages: ['en'],
  locale: [ 'en-ZA' ],
  code: 'SL',
  timezones: ['Africa/Freetown'],
  id: 195
}, {
  name: 'Singapore',
  native: 'Singapore',
  phone: 65,
  continent: 'AS',
  capital: 'Singapore',
  currency: 'SGD',
  languages: ['en', 'ms', 'ta', 'zh'],
  locale: [ 'en-GB', 'zh-CN' ],
  code: 'SG',
  timezones: ['Asia/Singapore'],
  id: 196
}, {
  name: 'Slovakia',
  native: 'Slovensko',
  phone: 421,
  continent: 'EU',
  capital: 'Bratislava',
  currency: 'EUR',
  languages: ['sk'],
  locale: [ 'sk' ],
  code: 'SK',
  timezones: ['Europe/Bratislava'],
  id: 197
}, {
  name: 'Slovenia',
  native: 'Slovenija',
  phone: 386,
  continent: 'EU',
  capital: 'Ljubljana',
  currency: 'EUR',
  languages: ['sl'],
  locale: [ 'sl' ],
  code: 'SI',
  timezones: ['Europe/Ljubljana'],
  id: 198
}, {
  name: 'Solomon Islands',
  native: 'Solomon Islands',
  phone: 677,
  continent: 'OC',
  capital: 'Honiara',
  currency: 'SBD',
  languages: ['en'],
  locale: [ 'en-US', 'en-GB' ],
  code: 'SB',
  timezones: ['Pacific/Guadalcanal'],
  id: 200
}, {
  name: 'Somalia',
  native: 'Soomaaliya',
  phone: 252,
  continent: 'AF',
  capital: 'Mogadishu',
  currency: 'SOS',
  languages: ['so', 'ar'],
  locale: [ 'ar' ],
  code: 'SO',
  timezones: ['Africa/Mogadishu'],
  id: 201
}, {
  name: 'South Africa',
  native: 'South Africa',
  phone: 27,
  continent: 'AF',
  capital: 'Pretoria',
  currency: 'ZAR',
  languages: ['af', 'en', 'nr', 'st', 'ss', 'tn', 'ts', 've', 'xh', 'zu'],
  locale: [ 'af', 'en-ZA' ],
  code: 'ZA',
  timezones: ['Africa/Johannesburg'],
  id: 202
}, {
  name: 'South Georgia and the South Sandwich Islands',
  native: 'South Georgia',
  phone: 500,
  continent: 'AN',
  capital: 'King Edward Point',
  currency: 'GBP',
  languages: ['en'],
  locale: [ 'en-GB' ],
  code: 'GS',
  timezones: ['Atlantic/South_Georgia'],
  id: 203
}, {
  name: 'South Korea',
  native: '대한민국',
  phone: 82,
  continent: 'AS',
  capital: 'Seoul',
  currency: 'KRW',
  languages: ['ko'],
  locale: [ 'ko' ],
  code: 'KR',
  timezones: ['Asia/Seoul'],
  id: 116
}, {
  name: 'South Sudan',
  native: 'South Sudan',
  phone: 211,
  continent: 'AF',
  capital: 'Juba',
  currency: 'SSP',
  languages: ['en'],
  locale: [ 'en-ZA' ],
  code: 'SS',
  timezones: ['Africa/Juba'],
  id: 204
}, {
  name: 'Spain',
  native: 'España',
  phone: 34,
  continent: 'EU',
  capital: 'Madrid',
  currency: 'EUR',
  languages: ['es', 'eu', 'ca', 'gl', 'oc'],
  locale: [ 'es', 'eu', 'ca', 'gl', 'oc' ],
  code: 'ES',
  timezones: ['Europe/Madrid', 'Africa/Ceuta', 'Atlantic/Canary'],
  id: 205
}, {
  name: 'Sri Lanka',
  native: 'śrī laṃkāva',
  phone: 94,
  continent: 'AS',
  capital: 'Colombo',
  currency: 'LKR',
  languages: ['si', 'ta'],
  locale: [ 'ta', 'en-US' ],
  code: 'LK',
  timezones: ['Asia/Colombo'],
  id: 206
}, {
  name: 'Sudan',
  native: 'السودان',
  phone: 249,
  continent: 'AF',
  capital: 'Khartoum',
  currency: 'SDG',
  languages: ['ar', 'en'],
  locale: [ 'ar', 'en-ZA' ],
  code: 'SD',
  timezones: ['Africa/Khartoum'],
  id: 207
}, {
  name: 'Suriname',
  native: 'Suriname',
  phone: 597,
  continent: 'SA',
  capital: 'Paramaribo',
  currency: 'SRD',
  languages: ['nl'],
  locale: [ 'nl' ],
  code: 'SR',
  timezones: ['America/Paramaribo'],
  id: 208
}, {
  name: 'Svalbard and Jan Mayen',
  native: 'Svalbard og Jan Mayen',
  phone: 4779,
  continent: 'EU',
  capital: 'Longyearbyen',
  currency: 'NOK',
  languages: ['nb'],
  locale: [ 'nb' ],
  code: 'SJ',
  timezones: ['Arctic/Longyearbyen'],
  id: 209
}, {
  name: 'Swaziland',
  native: 'Swaziland',
  phone: 268,
  continent: 'AF',
  capital: 'Lobamba',
  currency: 'SZL',
  languages: ['en', 'ss'],
  locale: [ 'en-ZA' ],
  code: 'SZ',
  timezones: ['Africa/Mbabane'],
  id: 210
}, {
  name: 'Sweden',
  native: 'Sverige',
  phone: 46,
  continent: 'EU',
  capital: 'Stockholm',
  currency: 'SEK',
  languages: ['sv'],
  locale: [ 'sv' ],
  code: 'SE',
  timezones: ['Europe/Stockholm'],
  id: 211
}, {
  name: 'Switzerland',
  native: 'Schweiz',
  phone: 41,
  continent: 'EU',
  capital: 'Bern',
  currency: 'CHE,CHF,CHW',
  languages: ['de', 'fr', 'it'],
  locale: [ 'de', 'fr-CH', 'it-CH' ],
  code: 'CH',
  timezones: ['Europe/Zurich'],
  id: 212
}, {
  name: 'Syrian Arab Republic',
  native: 'سوريا',
  phone: 963,
  continent: 'AS',
  capital: 'Damascus',
  currency: 'SYP',
  languages: ['ar'],
  locale: [ 'ar' ],
  code: 'SY',
  timezones: ['Asia/Damascus'],
  id: 213
}, {
  name: 'Taiwan',
  native: '臺灣',
  phone: 886,
  continent: 'AS',
  capital: 'Taipei',
  currency: 'TWD',
  languages: ['zh'],
  locale: [ 'zh-TW' ],
  code: 'TW',
  timezones: ['Asia/Taipei'],
  id: 214
}, {
  name: 'Tajikistan',
  native: 'Тоҷикистон',
  phone: 992,
  continent: 'AS',
  capital: 'Dushanbe',
  currency: 'TJS',
  languages: ['tg', 'ru'],
  locale: [ 'ru' ],
  code: 'TJ',
  timezones: ['Asia/Dushanbe'],
  id: 215
}, {
  name: 'Tanzania',
  native: 'Tanzania',
  phone: 255,
  continent: 'AF',
  capital: 'Dodoma',
  currency: 'TZS',
  languages: ['sw', 'en'],
  locale: [ 'en-ZA' ],
  code: 'TZ',
  timezones: ['Africa/Dar_es_Salaam'],
  id: 216
}, {
  name: 'Thailand',
  native: 'ประเทศไทย',
  phone: 66,
  continent: 'AS',
  capital: 'Bangkok',
  currency: 'THB',
  languages: ['th'],
  locale: [ 'th' ],
  code: 'TH',
  timezones: ['Asia/Bangkok'],
  id: 217
}, {
  name: 'East Timor',
  native: 'Timor-Leste',
  phone: 670,
  continent: 'OC',
  capital: 'Dili',
  currency: 'USD',
  languages: ['pt'],
  locale: [ 'pt' ],
  code: 'TL',
  timezones: ['Asia/Dili'],
  id: 62
}, {
  name: 'Togo',
  native: 'Togo',
  phone: 228,
  continent: 'AF',
  capital: 'Lomé',
  currency: 'XOF',
  languages: ['fr'],
  locale: [ 'fr' ],
  code: 'TG',
  timezones: ['Africa/Lome'],
  id: 218
}, {
  name: 'Tokelau',
  native: 'Tokelau',
  phone: 690,
  continent: 'OC',
  capital: 'Fakaofo',
  currency: 'NZD',
  languages: ['en'],
  locale: [ 'en-NZ' ],
  code: 'TK',
  timezones: ['Pacific/Fakaofo'],
  id: 219
}, {
  name: 'Tonga',
  native: 'Tonga',
  phone: 676,
  continent: 'OC',
  capital: 'Nuku\'alofa',
  currency: 'TOP',
  languages: ['en', 'to'],
  locale: [ 'en-US' ],
  code: 'TO',
  timezones: ['Pacific/Tongatapu'],
  id: 220
}, {
  name: 'Trinidad and Tobago',
  native: 'Trinidad and Tobago',
  phone: 1868,
  continent: 'NA',
  capital: 'Port of Spain',
  currency: 'TTD',
  languages: ['en'],
  locale: [ 'en-US' ],
  code: 'TT',
  timezones: ['America/Port_of_Spain'],
  id: 221
}, {
  name: 'Tunisia',
  native: 'تونس',
  phone: 216,
  continent: 'AF',
  capital: 'Tunis',
  currency: 'TND',
  languages: ['ar'],
  locale: [ 'ar-TN' ],
  code: 'TN',
  timezones: ['Africa/Tunis'],
  id: 222
}, {
  name: 'Turkey',
  native: 'Türkiye',
  phone: 90,
  continent: 'AS',
  capital: 'Ankara',
  currency: 'TRY',
  languages: ['tr'],
  locale: [ 'tr' ],
  code: 'TR',
  timezones: ['Europe/Istanbul'],
  id: 223
}, {
  name: 'Turkmenistan',
  native: 'Türkmenistan',
  phone: 993,
  continent: 'AS',
  capital: 'Ashgabat',
  currency: 'TMT',
  languages: ['tk', 'ru'],
  locale: [ 'ru' ],
  code: 'TM',
  timezones: ['Asia/Ashgabat'],
  id: 224
}, {
  name: 'Turks and Caicos Islands',
  native: 'Turks and Caicos Islands',
  phone: 1649,
  continent: 'NA',
  capital: 'Cockburn Town',
  currency: 'USD',
  languages: ['en'],
  locale: [ 'en-US' ],
  code: 'TC',
  timezones: ['America/Grand_Turk'],
  id: 225
}, {
  name: 'Tuvalu',
  native: 'Tuvalu',
  phone: 688,
  continent: 'OC',
  capital: 'Funafuti',
  currency: 'AUD',
  languages: ['en'],
  locale: [ 'en-AU' ],
  code: 'TV',
  timezones: ['Pacific/Funafuti'],
  id: 226
}, {
  name: 'Uganda',
  native: 'Uganda',
  phone: 256,
  continent: 'AF',
  capital: 'Kampala',
  currency: 'UGX',
  languages: ['en', 'sw'],
  locale: [ 'en-ZA' ],
  code: 'UG',
  timezones: ['Africa/Kampala'],
  id: 227
}, {
  name: 'Ukraine',
  native: 'Україна',
  phone: 380,
  continent: 'EU',
  capital: 'Kyiv',
  currency: 'UAH',
  languages: ['uk'],
  locale: [ 'uk' ],
  code: 'UA',
  timezones: ['Europe/Kiev', 'Europe/Uzhgorod', 'Europe/Zaporozhye'],
  id: 228
}, {
  name: 'United Arab Emirates',
  native: 'دولة الإمارات العربية المتحدة',
  phone: 971,
  continent: 'AS',
  capital: 'Abu Dhabi',
  currency: 'AED',
  languages: ['ar'],
  locale: [ 'ar' ],
  code: 'AE',
  timezones: ['Asia/Dubai'],
  id: 229
}, {
  name: 'United Kingdom',
  native: 'United Kingdom',
  phone: 44,
  continent: 'EU',
  capital: 'London',
  currency: 'GBP',
  languages: ['en'],
  locale: [ 'en-GB' ],
  code: 'GB',
  timezones: ['Europe/London'],
  id: 230
}, {
  name: 'United States',
  native: 'United States',
  phone: 1,
  continent: 'NA',
  capital: 'Washington D.C.',
  currency: 'USD,USN,USS',
  languages: ['en'],
  locale: [ 'en-US' ],
  code: 'US',
  timezones: ['America/New_York', 'America/Detroit', 'America/Kentucky/Louisville', 'America/Kentucky/Monticello', 'America/Indiana/Indianapolis', 'America/Indiana/Vincennes', 'America/Indiana/Winamac', 'America/Indiana/Marengo', 'America/Indiana/Petersburg', 'America/Indiana/Vevay', 'America/Chicago', 'America/Indiana/Tell_City', 'America/Indiana/Knox', 'America/Menominee', 'America/North_Dakota/Center', 'America/North_Dakota/New_Salem', 'America/North_Dakota/Beulah', 'America/Denver', 'America/Boise', 'America/Phoenix', 'America/Los_Angeles', 'America/Anchorage', 'America/Juneau', 'America/Sitka', 'America/Metlakatla', 'America/Yakutat', 'America/Nome', 'America/Adak', 'Pacific/Honolulu'],
  id: 231
}, {
  name: 'U.S. Minor Outlying Islands',
  native: 'United States Minor Outlying Islands',
  phone: 1,
  continent: 'OC',
  capital: '',
  currency: 'USD',
  languages: ['en'],
  locale: [ 'en-US' ],
  code: 'UM',
  timezones: ['Pacific/Johnston', 'Pacific/Midway', 'Pacific/Wake'],
  id: 232
}, {
  name: 'U.S. Virgin Islands',
  native: 'United States Virgin Islands',
  phone: 1340,
  continent: 'NA',
  capital: 'Charlotte Amalie',
  currency: 'USD',
  languages: ['en'],
  locale: [ 'en-US' ],
  code: 'VI',
  timezones: ['America/St_Thomas'],
  id: 240
}, {
  name: 'Uruguay',
  native: 'Uruguay',
  phone: 598,
  continent: 'SA',
  capital: 'Montevideo',
  currency: 'UYI,UYU',
  languages: ['es'],
  locale: [ 'es' ],
  code: 'UY',
  timezones: ['America/Montevideo'],
  id: 233
}, {
  name: 'Uzbekistan',
  native: 'O‘zbekiston',
  phone: 998,
  continent: 'AS',
  capital: 'Tashkent',
  currency: 'UZS',
  languages: ['uz', 'ru'],
  locale: [ 'uz', 'ru' ],
  code: 'UZ',
  timezones: ['Asia/Samarkand', 'Asia/Tashkent'],
  id: 234
}, {
  name: 'Vanuatu',
  native: 'Vanuatu',
  phone: 678,
  continent: 'OC',
  capital: 'Port Vila',
  currency: 'VUV',
  languages: ['bi', 'en', 'fr'],
  locale: [ 'en-US', 'en-GB', 'fr' ],
  code: 'VU',
  timezones: ['Pacific/Efate'],
  id: 235
}, {
  name: 'Vatican City',
  native: 'Vaticano',
  phone: 379,
  continent: 'EU',
  capital: 'Vatican City',
  currency: 'EUR',
  languages: ['it', 'la'],
  locale: [ 'it' ],
  code: 'VA',
  timezones: ['Europe/Vatican'],
  id: 236
}, {
  name: 'Venezuela',
  native: 'Venezuela',
  phone: 58,
  continent: 'SA',
  capital: 'Caracas',
  currency: 'VES',
  languages: ['es'],
  locale: [ 'es' ],
  code: 'VE',
  timezones: ['America/Caracas'],
  id: 237
}, {
  name: 'Vietnam',
  native: 'Việt Nam',
  phone: 84,
  continent: 'AS',
  capital: 'Hanoi',
  currency: 'VND',
  languages: ['vi'],
  locale: [ 'vi' ],
  code: 'VN',
  timezones: ['Asia/Ho_Chi_Minh'],
  id: 238
}, {
  name: 'Wallis and Futuna',
  native: 'Wallis et Futuna',
  phone: 681,
  continent: 'OC',
  capital: 'Mata-Utu',
  currency: 'XPF',
  languages: ['fr'],
  locale: [ 'fr' ],
  code: 'WF',
  timezones: ['Pacific/Wallis'],
  id: 241
}, {
  name: 'Western Sahara',
  native: 'الصحراء الغربية',
  phone: 212,
  continent: 'AF',
  capital: 'El Aaiún',
  currency: 'MAD,DZD,MRU',
  languages: ['es'],
  locale: [ 'es' ],
  code: 'EH',
  timezones: ['Africa/El_Aaiun'],
  id: 242
}, {
  name: 'Yemen',
  native: 'اليَمَن',
  phone: 967,
  continent: 'AS',
  capital: 'Sana\'a',
  currency: 'YER',
  languages: ['ar'],
  locale: [ 'ar' ],
  code: 'YE',
  timezones: ['Asia/Aden'],
  id: 243
}, {
  name: 'Zambia',
  native: 'Zambia',
  phone: 260,
  continent: 'AF',
  capital: 'Lusaka',
  currency: 'ZMW',
  languages: ['en'],
  locale: [ 'en-ZA' ],
  code: 'ZM',
  timezones: ['Africa/Lusaka'],
  id: 245
}, {
  name: 'Zimbabwe',
  native: 'Zimbabwe',
  phone: 263,
  continent: 'AF',
  capital: 'Harare',
  currency: 'USD,ZAR,BWP,GBP,AUD,CNY,INR,JPY',
  languages: ['en', 'sn', 'nd'],
  locale: [ 'en-ZA', 'en-GB' ],
  code: 'ZW',
  timezones: ['Africa/Harare'],
  id: 246
}];
