import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription, map } from 'rxjs';

import { UpdateByService } from 'src/app/services/user/update-by.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { ErrorService } from 'src/app/services/general/error.service';

import { Role } from 'src/app/interfaces/account';
import { Privilege } from 'src/app/interfaces/privilege';
import { StdEventRoleList, StdRoleList } from 'src/app/commons/role';
import { StdEventRolePrivilege, StdRolePrivilege } from 'src/app/commons/rolePrivilege';
import { AccountEventModeService } from './account-event-mode.service';

/**
 * Account role service
 */
@Injectable({
  providedIn: 'root'
})
export class AccountRoleService implements OnInit, OnDestroy {

  /**
   * Standard role database list
   */
  stdRoleList: string[] =  StdRoleList;
  stdEventRoleList: string[] = StdEventRoleList;
  /**
   * Role list
   */
  roleList: Role[];
  /**
   * Observable role list
   */
  observableRoleList: any;
  /**
   * Account ID
   */
  private accountId: string;
  /**
   * Account role subcription
   */
  private accountRoleSubscription: Subscription;

  /**
   * Constructor
   */
  constructor(
    private afs: AngularFirestore,
    private translate: TranslateService,
    private accountEventModeService: AccountEventModeService,
    private updateByService: UpdateByService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
    this.observableRoleList = new BehaviorSubject<Role[]>(this.roleList);
    // this.setupRoleList();
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy() {
    this.unwatchAccountRole();
  }

  /**
   * Setup Account ID
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
    if (this.accountId) {
      await this.watchAccountRole();
    } else {
      await this.unwatchAccountRole();
    }
  }

  /**
   * Watch account role
   */
  async watchAccountRole() {
    if (this.accountId && !this.accountRoleSubscription) {
      this.accountRoleSubscription = this.afs.doc(`accounts/${ this.accountId }/accountDetail/role/`)
      .snapshotChanges().pipe(map(changes => {
        const data: any = changes.payload.data();
        return data?.roleList;
      })).subscribe({
        next: (accountRole: Role[]) => {
          this.setupRoleList(accountRole, this.accountEventModeService.eventMode);
        }, error: (err: any) => {
          this.errorService.logError(err);
        }
      });
    }
  }

  /**
   * Unwatch account role
   */
  async unwatchAccountRole() {
    if (this.accountRoleSubscription) {
      this.accountRoleSubscription.unsubscribe();
      this.accountRoleSubscription = null;
    }
    this.roleList = this.setupStdRoleList([], this.accountEventModeService.eventMode);
  }

  /**
   * Setup role list
   * @param roleList role list
   */
  setupRoleList(roleList?: Role[], eventMode?: boolean) {
    if (this.functionService.isEmpty(this.roleList) || (roleList?.length && !this.functionService.isEqual(roleList, this.roleList))) {
      if (!roleList?.length) {
        roleList = this.setupStdRoleList([], eventMode);
      }

      this.roleList = this.setupRolePrivilege(roleList);
      this.observableRoleList.next(this.roleList);
    }
  }

  setupStdRoleList(roleList?: Role[], eventMode?: boolean) {
    if (!roleList?.length) {
      roleList = [];
    }
    roleList = roleList.concat(this.getStdRoleList(eventMode || this.accountEventModeService.eventMode ? true : false));
    return roleList;
  }

  /**
   * Setup role privilege
   * @param roleList Role List
   */
  setupRolePrivilege(roleList: Role[]) {
    roleList?.forEach((role: Role) => {
      if (role?.role?.type && !role?.role.custom) {
        if (!role?.privilege) {
          if (StdRolePrivilege?.[role.role.type]) {
            role.privilege = StdRolePrivilege[role.role.type];
          } else if (StdEventRolePrivilege?.[role.role.type]) {
            role.privilege = StdEventRolePrivilege[role.role.type];
          }
        }
      }
    });
    roleList.sort((a: Role, b: Role) => {
      return this.functionService.compare(a?.role?.type?.toString()?.toLowerCase(), b?.role?.type?.toString()?.toLowerCase());
    });
    return roleList;
  }


  /**
   * Search role list
   * @param keyword Keyword
   * @param type Type
   * @param filter Filter
   * @returns List of role
   */
  searchRoleList(keyword?: string, eventMode?: boolean): Role[] {
    let roleList: Role[] = this.getRoleList(eventMode);
    if (roleList?.length) {
      if (keyword) {
        roleList = roleList.filter((role: Role) => {
          return this.searchRoleBykeyword(role, keyword);
        });
      }
    }
    return roleList;
  }

  /**
   * Search role by keyword
   * @param role Role
   * @param keyword Keyword
   */
   searchRoleBykeyword(role: Role, keyword: string): boolean {
    if (keyword && role?.role?.type) {
      const value = role.role.custom ? role.role.type : this.translate.instant('LIST.role.' + role.role.type)?.toString().toLowerCase();
      if (this.functionService.search(value, keyword)) {
        return true;
      } else if (this.functionService.chineseMath(value, keyword)) {
        return true;
      }
    }
    return false;
  }

  getRoleList(eventMode?: boolean) {
    if (!this.roleList?.length) {
      return this.setupStdRoleList([], eventMode);
    }
    return this.roleList;
  }

  getStdRoleList(eventMode?: boolean) {
    const roleList: Role[] = [];

    if (eventMode || this.accountEventModeService.eventMode) {
      this.stdEventRoleList?.forEach((roleType: string) => {
        if (roleType) {
          const obj: Role = {
            role: {
              custom: false,
              type: roleType,
              coupleId: 0
            },
            privilege: this.getRolePrivilege(roleType, true),
          };
          roleList.push(obj);
        }
      });
    } else {
      this.stdRoleList?.forEach((roleType: string) => {
        if (roleType) {
          const obj: Role = {
            role: {
              custom: false,
              type: roleType,
              coupleId: 0
            },
            privilege: this.getRolePrivilege(roleType, false),
          };
          roleList.push(obj);
        }
      });
    }
    
    return roleList;
  }

  /**
   * Get Role Privilege
   * @param role Role
   */
  getRolePrivilege(roleType: string, eventMode?: boolean): Privilege {
    if (roleType) {
      if (this.roleList?.length) {
        const roleIndex = this.roleList?.findIndex((x: Role) => x?.role?.type === roleType && !this.functionService.isEmpty(x?.privilege));
        if (roleIndex !== -1 && this.roleList[roleIndex]?.privilege && !this.functionService.isEmpty(this.roleList[roleIndex].privilege)) {
          return this.roleList[roleIndex].privilege;
        }
      }
      if (!eventMode) {
        eventMode = this.accountEventModeService.eventMode;
      }
      if (eventMode && StdEventRolePrivilege?.[roleType]) {
        return StdEventRolePrivilege[roleType];
      } else if (!eventMode && StdRolePrivilege?.[roleType]) {
        return StdRolePrivilege[roleType];
      }
    }
    return null;
  }

  /**
   * Update account role
   * @param data Data
   */
  async updateAccountRole(roleList: Role[]) {
    if (this.accountId && roleList?.length) {
      const data = {
        roleList,
        updateBy: this.updateByService.updateBy
      };
      const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountDetail/role`);
      accountsRef.set(data, { merge: true }).then(() => {
      }).catch((err: any) => {
        this.errorService.logError(err);
      });
    }
  }
}
