<ion-content>
  <div class="full-height vertical-center">
    <ion-card class="center">
      <ion-card-content class="ion-text-center" *ngIf="userSetupStep === 'name'">
        <ion-item class="ion-item-no-padding" lines="none">
          <ion-label class="font-med ion-text-wrap" color="dark" [innerHTML]="'USER.msg.setup_name' | translate"></ion-label>
        </ion-item>
        <div class="ion-margin-vertical">
          <ion-icon class="icon-greet" color="dark" src="./assets/wedding/icon/account/handshake.svg"></ion-icon>
        </div>
        <form [formGroup]="userSetupForm" (ngSubmit)="next()" *ngIf="userSetupForm">
          <ion-input class="prompt-input ion-text-left" formControlName="name" type="text" inputmode="text" autocapitalize="words" autocomplete="name" placeholder="{{ 'LBL.name' | translate }}" required #nameInput></ion-input>
          <div class="error-div ion-text-left">
            <ng-container *ngFor="let validation of validationMsg?.name">
              <div class="error-message" *ngIf="userSetupForm.get('name').hasError(validation.type) && (userSetupForm.get('name').dirty || userSetupForm.get('name').touched)">
                <ion-text><ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}</ion-text>
              </div>
            </ng-container>
          </div>
          <div class="ion-margin-bottom">
            <ion-button expand="block" color="primary" fill="solid" type="submit" [disabled]="!userSetupForm.valid">
              {{ 'BTN.continue' | translate }}
            </ion-button>
          </div>
        </form>
      </ion-card-content>
      <ion-card-content class="ion-text-center" *ngIf="userSetupStep === 'avatar'">
        <ion-item class="ion-item-no-padding" lines="none">
          <ion-icon color="dark" slot="start" (click)="userSetupStep = 'name'" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
          <ion-label class="font-med ion-text-wrap" color="dark" [innerHTML]="'USER.msg.setup_avatar' | translate"></ion-label>
        </ion-item>
        <div class="ion-text-center ion-margin-vertical ion-padding-vertical">
          <ion-avatar class="center big-avatar" (click)="selectPhoto()">
            <ng-container *ngIf="getUserAvatar() as userAvatar else localAvatar">
              <ion-img [src]="userAvatar.url" *ngIf="userAvatar?.url"></ion-img>
              <ion-icon color="medium" src="./assets/wedding/img/avatar/blank.svg" *ngIf="!userAvatar?.url"></ion-icon>
            </ng-container>
            <ng-template #localAvatar>
              <ion-img [src]="avatar.url" *ngIf="avatar?.url"></ion-img>
              <ion-icon color="medium" src="./assets/wedding/img/avatar/blank.svg" *ngIf="!avatar?.url"></ion-icon>
            </ng-template>
            
          </ion-avatar>
          <ion-text class="font-10 font-med" (click)="selectPhoto()">{{ 'CRUD.edit' | translate }}</ion-text>
        </div>
        <div class="ion-margin-vertical ion-padding-top">
          <ion-button expand="block" color="primary" fill="solid" type="button" (click)="next();">
            {{ 'BTN.enter' | translate }}
          </ion-button>
        </div>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>
