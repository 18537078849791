import { GeoLocationService } from 'src/app/services/location/geo-location.service';
import { IpLocationService } from './ip-location.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { UserService } from 'src/app/services/user/user.service';

import { Locality, LocationDetail } from 'src/app/interfaces/general';
import { Country } from 'src/app/interfaces/database';
import { CountryList } from 'src/app/commons/country';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { FunctionService } from '../general/function.service';
import { Subscription } from 'rxjs';


/**
 * Locality service.
 * For getting wedding account / user locality
 */
@Injectable({
  providedIn: 'root'
})
export class LocalityService implements OnInit, OnDestroy {

  /**
   * Country list
   */
  countryList: Country[] = CountryList;

  /**
   * Default locality
   */
  defaultLocality: Locality = {
    country: '',
    state: '',
    city: ''
  };

  deviceLocality: Locality;
  observableDeviceLocality: any;

  private localitySubscription: Subscription;
  private locationDetailSubscription: Subscription;

  /**
   * Constructor
   * @param userService user service
   * @param accountService account service
   */
  constructor(
    private userService: UserService,
    private accountInfoService: AccountInfoService,
    private ipLocationService: IpLocationService,
    private geoLocationService: GeoLocationService,
    private functionService: FunctionService,
  ) {
    this.observableDeviceLocality = new BehaviorSubject<Locality>(this.deviceLocality);
    this.watch();
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    this.unwatch();
  }

  async watch() {
    if (!this.localitySubscription) {
      this.localitySubscription = this.ipLocationService.observableLocality.subscribe((locality: Locality) => {
        if (locality) {
          this.deviceLocality = locality;
        }
      });
    }
    
    if (!this.locationDetailSubscription) {
      this.locationDetailSubscription = this.geoLocationService.observableLocationDetail.subscribe((locationDetail: LocationDetail) => {
        if (locationDetail?.locality && !this.deviceLocality) {
          this.deviceLocality = locationDetail?.locality;
        }
      });
    }
  }

  async unwatch() {
    if (this.localitySubscription) {
      this.localitySubscription.unsubscribe();
      this.localitySubscription = null;
    }

    if (this.locationDetailSubscription) {
      this.locationDetailSubscription.unsubscribe();
      this.locationDetailSubscription = null;
    }
  }

  getCountryList(): Country[] {
    let countryList = this.functionService.cloneDeep(this.countryList);
    const pereferCountryList = this.getPreferCountry();
    pereferCountryList?.forEach((x: Country) => {
      if (x?.id && x?.code && x?.name) {
        const index = countryList?.findIndex((y: Country) => {
          return x.code === y.code;
        });
        if (index !== -1) {
          countryList.splice(index, 1);
        }
        countryList.unshift(x);
      }
    })
    return countryList;
  }

  getPreferCountry(): Country[] {
    const deviceCountry = this.getDeviceCountry();
    const userCountry = this.getUserCountry();
    const accountCountry = this.getAccountCountry();

    const countryList: Country[] = [ deviceCountry ];
    
    if (userCountry?.code && userCountry.code !== deviceCountry?.code) {
      countryList.push(userCountry);
    } 
    
    if (accountCountry?.code && accountCountry.code !== userCountry?.code && accountCountry.code !== deviceCountry?.code) {
      countryList.push(accountCountry);
    }
    
    return countryList;
  }

  /**
   * Get country by code
   * @param countryCode country code
   * @returns country obj
   */
  getCountryByCode(countryCode: string): Country {
    if (countryCode) {
      const index = this.countryList?.findIndex((x: Country) => x?.code === countryCode);
      if (index !== -1) {
        return this.countryList[index];
      }
    }
    return null;
  }

  /**
   * Get device country by IP address.
   * @returns country obj
   */
  getDeviceCountry(): Country {
    if (this.ipLocationService?.locality?.country) {
      const country = this.getCountryByCode(this.ipLocationService.locality.country);
      if (country) {
        return country;
      }
    } else if (this.geoLocationService.locationDetail?.locality?.country) {
      const country = this.getCountryByCode(this.geoLocationService.locationDetail.locality.country);
      if (country) {
        return country;
      }
    }
    return null;
  }

  /**
   * Get user account country, alternate to device country if not present.
   * @returns country obj
   */
  getUserCountry(): Country {
    const locality = this.getUserLocality();
    if (locality?.country && this.getCountryByCode(locality.country)) {
      return this.getCountryByCode(locality.country);
    }
    return this.getDeviceCountry();
  }

  /**
   * Get wedding account country, alternate to user country if not present.
   * @returns country obj
   */
  getAccountCountry(): Country {
    const locality = this.getAccountLocality();
    if (locality?.country && this.getCountryByCode(locality.country)) {
      return this.getCountryByCode(locality.country);
    }
    return this.getUserCountry();
  }

  /**
   * Get device locality
   * @returns locality obj
   */
  getDeviceLocality(): Locality {
    if (this.ipLocationService?.locality?.country) {
      return this.ipLocationService.locality;
    } else if (this.geoLocationService.locationDetail?.locality?.country) {
      return this.geoLocationService.locationDetail.locality;
    }
    return this.defaultLocality;
  }

  /**
   * Get user account locality, alternate to device locality if not present.
   * @returns locality
   */
  getUserLocality(): Locality {
    if (this.userService?.user?.locality) {
      return this.userService.user.locality;
    }
    return this.getDeviceLocality();
  }

  /**
   * Get wedding account locality, alternate to device locality if not present.
   */
  getAccountLocality(): Locality {
    if (this.accountInfoService?.accountInfo?.location?.locality) {
      return this.accountInfoService.accountInfo.location.locality;
    }
    return this.getUserLocality();
  }

}
