/**
 * Preloaded standard dietary request list
 */
export const StdDietaryReqList: string[] = [
    'dairy_free',
    'diabetic',
    'gluten_free',
    'halal',
    'kosher',
    'lactose_free',
    'no_alcohol',
    'none',
    'peanut_allergies',
    'pregnant',
    'shellfish_allergies',
    'vegan',
    'vegetarian'
];

export const DefaultDietaryReqList: string[] = [
    'none',
];
