import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ConfigService } from 'src/app/services/general/config.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { AccountPackageService } from 'src/app/services/account/account-package.service';
import { AccountSubscribeService } from 'src/app/services/account/account-subscribe.service';
import { GuestService } from 'src/app/services/guest/guest.service';

@Injectable({
  providedIn: 'root'
})
export class GuestControlService implements OnInit, OnDestroy {

  constructor(
    private translate: TranslateService,
    private configService: ConfigService,
    private accountPackage: AccountPackageService,
    private accountSubscribeService: AccountSubscribeService,
    private moduleService: ModuleService,
    private guestService: GuestService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Check if exceed max guest limit
   * @param pax new pax
   * @returns true if exceed trial guest limit
   */
  exceedMaxGuest(pax?: number): boolean {
    if (!pax) { pax = 0; }
    if (this.accountPackage?.accountPackage?.accountStatus?.trial && !this.accountPackage?.accountPackage?.accountStatus?.premium) {
      if (this.moduleService.currentModule !== 'trash') {
        if (this.configService.trial.trial_guest) {
          if (pax + this.guestService.getGuestList()?.length > this.configService.trial.trial_guest) {
            this.accountSubscribeService.promptSubscribe(
              this.accountPackage?.accountId,
              this.translate.instant('ACCOUNT.msg.exceed_trial_guest')
            );
            return true;
          }
        }
      }
    }
    return false;
  }

}
