import { Injectable, OnDestroy, OnInit } from '@angular/core';
import QRCodeStyling, { FileExtension } from 'qr-code-styling';

/**
 * Guest QR code service, for QR code template update.
 */
@Injectable({
  providedIn: 'root'
})
export class QrcodeService implements OnInit, OnDestroy {

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  async generateQRcode(
    data: string,
    width: number,
    height: number,
    margin: number,
    extension?: FileExtension,
    canvas?: boolean,
    logo?: boolean
  ) {
    let image = '';
    if (logo) {
      image = './assets/wedding/img/logo/thebigday-logo-round-primary.webp';
    }
    // const image = ReceiptImg.images.logo;
    const qrcode = new QRCodeStyling({
      width,
      height,
      type: canvas ? 'canvas' : 'svg',
      data,
      image,
      margin,
      qrOptions: {
        typeNumber: 0,
        mode: 'Byte',
        errorCorrectionLevel: 'H'
      },
      imageOptions: {
        hideBackgroundDots: false,
        imageSize: 0.4,
        margin: margin / 2,
        crossOrigin: 'anonymous',
      },
      dotsOptions: {
        color: '#000000',
        // gradient: {
        //   type: 'linear', // 'radial'
        //   rotation: 0,
        //   colorStops: [{ offset: 0, color: '#8688B2' }, { offset: 1, color: '#77779C' }]
        // },
        type: 'rounded'
      },
      backgroundOptions: {
        // color: '#e9ebee',
        // gradient: {
        //   type: 'linear', // 'radial'
        //   rotation: 0,
        //   colorStops: [{ offset: 0, color: '#ededff' }, { offset: 1, color: '#e6e7ff' }]
        // },
      },
      cornersSquareOptions: {
        color: '#000000',
        type: 'extra-rounded',
        // gradient: {
        //   type: 'linear', // 'radial'
        //   rotation: 180,
        //   colorStops: [{ offset: 0, color: '#25456e' }, { offset: 1, color: '#4267b2' }]
        // },
      },
      cornersDotOptions: {
        color: '#000000',
        type: 'dot',
        // gradient: {
        //   type: 'linear', // 'radial'
        //   rotation: 180,
        //   colorStops: [{ offset: 0, color: '#00266e' }, { offset: 1, color: '#4060b3' }]
        // },
      }
    });

    if (canvas) {
      return qrcode;
    } else {
      const result = await qrcode.getRawData(extension);
      return result;
    }
  }

}
