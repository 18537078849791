import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, map } from 'rxjs';

import { UpdateByService } from 'src/app/services/user/update-by.service';
import { LanguageService } from 'src/app/services/general/language.service';
import { StorageService } from 'src/app/services/general/storage.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { QrcodeTemplate, SettingField } from 'src/app/interfaces/database';
import { QrcodeTemplateList } from 'src/app/commons/qrcodeTemplate';

/**
 * Guest QR code service, for QR code template update.
 */
@Injectable({
  providedIn: 'root'
})
export class QrcodeTemplateService implements OnInit, OnDestroy {

  /**
   * Selected Qrcode Template
   */
  selectedName: SettingField;
  /**
   * Qrcode Template list
   */
  qrcodeTemplateList: QrcodeTemplate[];
  /**
   * Observable Qrcode template list
   */
  observableQrcodeTemplateList: any;

  /**
   * Default Qrcode template
   */
  private defaultName: SettingField = {
    value: 'en',
    custom: false
  };
  /**
   * Account ID
   */
  private accountId: string;
  /**
   * Qrcode template subscription
   */
  private qrcodeTemplateSubscription: Subscription;

  /**
   * Constructor
   * @param afs Angular firestore
   * @param userService User service
   * @param languageService language service
   * @param storageService Storage service
   * @param functionService function service
   */
  constructor(
    private afs: AngularFirestore,
    private updateByService: UpdateByService,
    private languageService: LanguageService,
    private storageService: StorageService,
    private functionService: FunctionService,
  ) {
    this.qrcodeTemplateList = [];
    this.observableQrcodeTemplateList = new BehaviorSubject<QrcodeTemplate[]>(this.qrcodeTemplateList);
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.unwatchQrcodeTemplateList();
    this.qrcodeTemplateList = [];
  }

  /**
   * Setup Account ID and start watch seating list
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    if (accountId !== this.accountId) {
      this.accountId = accountId;
      if (this.accountId) {
        await this.watchQrcodeTemplateList();
      } else {
        await this.unwatchQrcodeTemplateList();
        this.qrcodeTemplateList = [];
      }
      this.setupSelectedTemplate();
    }
  }

  /**
   * Watch qrcode template list
   */
  async watchQrcodeTemplateList() {
    if (this.accountId && !this.qrcodeTemplateSubscription) {
      this.qrcodeTemplateSubscription = this.afs.doc(`accounts/${ this.accountId }/accountSetting/qrcodeTemplate/`)
      .snapshotChanges().pipe(map(changes => {
        const data: any = changes.payload.data();
        return data?.list ? data.list : QrcodeTemplateList;
      })).subscribe({
        next: (qrcodeTemplateList: QrcodeTemplate[]) => {
          this.setupQrcodeTemplateList(qrcodeTemplateList);
      }});
    }
  }

  /**
   * Unwatch qrcode template list
   */
  async unwatchQrcodeTemplateList() {
    if (this.qrcodeTemplateSubscription) {
      this.qrcodeTemplateSubscription.unsubscribe();
      this.qrcodeTemplateSubscription = null;
    }
  }

  /**
   * Setup QR code template list
   * @param qrcodeTemplateList qrcode template list
   */
  setupQrcodeTemplateList(list: QrcodeTemplate[]) {
    const qrcodeTemplateList: QrcodeTemplate[] = [];
    list?.forEach((qrcodeTemplate: QrcodeTemplate) => {
      if (qrcodeTemplate?.name.custom || this.languageService.checkValidLanguage(qrcodeTemplate?.name.value)) {
        qrcodeTemplateList.push(qrcodeTemplate);
      }
    });
    this.qrcodeTemplateList = qrcodeTemplateList;
    this.setupSelectedTemplate();
    this.observableQrcodeTemplateList.next(this.qrcodeTemplateList);
  }

  /**
   * Get selected template from local storage,
   * else then device language,
   * else default template
   */
  async setupSelectedTemplate() {
    if (this.accountId) {
      this.selectedName = await this.readSelectedTemplate();
      this.checkSelectedTemplate();
    }
  }

  /**
   * Read selected template from local storage
   */
  async readSelectedTemplate() {
    return await this.storageService.getObject('qr_template_' + this.accountId);
  }

  /**
   * Check selected template if valid else get default template
   */
  async checkSelectedTemplate() {
    const index = this.getTemplateIndex(this.selectedName);
    if (index === -1) {
      await this.setupDefaultTemplate();
    }
  }

  /**
   * Get & save default template
   */
  async setupDefaultTemplate(save?: boolean) {
    const language = this.languageService.getLanguageCode(this.languageService.getAccountLanguage());
    const languageTemplateIndex = this.qrcodeTemplateList?.findIndex((x: any) => x?.name?.value === language && x?.name?.custom === false);
    if (languageTemplateIndex !== -1) {
      this.selectedName = this.qrcodeTemplateList[languageTemplateIndex].name;
    } else {
      const index = this.getTemplateIndex(this.defaultName);
      if (index !== -1) {
        this.selectedName = this.defaultName;
      } else if (this.qrcodeTemplateList?.length && this.qrcodeTemplateList[0].name) {
        this.selectedName = this.qrcodeTemplateList[0].name;
      }
    }
    if (save && this.selectedName) {
      this.saveLocalStorage();
    }
  }

  /**
   * Save selected Qrcode Template into storage
   * @param selectedName Selected Qrcode Template
   */
  async saveSelectedTemplate(selectedName: SettingField) {
    if (this.accountId) {
      if (!this.functionService.isEqual(selectedName, this.selectedName)) {
        this.selectedName = selectedName;
        this.saveLocalStorage();
      }
    }
  }

  /**
   * Save selected to local storage
   */
  saveLocalStorage() {
    if (this.accountId && this.selectedName) {
      this.storageService.setObject('qr_template_' + this.accountId, this.selectedName);
    }
  }

  /**
   * Get Qrcode template by name
   * @param templateName Qrcode template name
   */
  getTemplateByName(templateName: SettingField): QrcodeTemplate {
    const qrcodeTemplateIndex = this.getTemplateIndex(templateName);
    if (qrcodeTemplateIndex !== -1) {
      return this.qrcodeTemplateList[qrcodeTemplateIndex];
    }
    return null;
  }

  /**
   * Get Qrcode Template index by name
   * @param templateName Qrcode template name
   */
  getTemplateIndex(templateName: SettingField): number {
    return this.qrcodeTemplateList?.findIndex((x: QrcodeTemplate) =>
      x?.name?.value === templateName?.value && x?.name?.custom === templateName?.custom);
  }

  /**
   * Get selected template
   */
  async selectedTemplate(): Promise<QrcodeTemplate> {
    await this.setupSelectedTemplate();
    return this.getTemplateByName(this.selectedName);
  }

  /**
   * Delete Qrcode template
   * @param qrcodeTemplate Qrcode template
   */
  async deleteQrcodeTemplate(templateName: SettingField) {
    let qrcodeTemplateList = [ ...this.qrcodeTemplateList ];
    qrcodeTemplateList = qrcodeTemplateList.filter((x: any) => {
      if (this.functionService.isEqual(x.name, templateName)) {
        return false;
      } else {
        return true;
      }
    });

    await this.saveQrcodeTemplateList(qrcodeTemplateList);

    if (this.functionService.isEqual(this.selectedName, templateName)) {
      await this.setupDefaultTemplate(true);
    }
  }

  /**
   * Save Qrcode template list
   * @param qrcodeTemplateList Qrcode template list
   */
  async saveQrcodeTemplateList(qrcodeTemplateList: QrcodeTemplate[]) {
    if (this.accountId && !this.functionService.isEqual(qrcodeTemplateList, this.qrcodeTemplateList)) {
      const data: any = {
        list: qrcodeTemplateList,
        updateBy: this.updateByService.updateBy
      };
      const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/qrcodeTemplate/`);
      accountsRef.set(data, { merge: true });

      this.setupQrcodeTemplateList(qrcodeTemplateList);
    }
  }

}
