import { FunctionService } from 'src/app/services/general/function.service';
import { QrcodeService } from 'src/app/services/qrcode/qrcode.service';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Share } from '@capacitor/share';
import { Clipboard } from '@capacitor/clipboard';

import { AccountCoupleService } from 'src/app/services/account/account-couple.service';
import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { AccountLoginService } from 'src/app/services/account/account-login.service';
import { CreditService } from 'src/app/services/subscription/credit.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { GiftCardTemplateComponent } from 'src/app/components/subscription/gift-card-template/gift-card-template.component';
import { AccountPrivilegeComponent } from 'src/app/components/account/account-privilege/account-privilege.component';

import { AccountLogin } from 'src/app/interfaces/login';
import { Credit } from 'src/app/interfaces/credit';
import { ShareMethod, ShareChannel } from 'src/app/types/share';
import { Options } from 'qr-code-styling';
import { AccountEventModeService } from 'src/app/services/account/account-event-mode.service';

@Component({
  selector: 'app-account-share',
  templateUrl: './account-share.component.html',
  styleUrls: ['./account-share.component.scss'],
})
export class AccountShareComponent implements OnInit, OnDestroy {

  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  config: Options = {
    width: 280,
    height: 280,
    type: 'canvas',
    margin: 10,
    qrOptions: {
      typeNumber: 0,
      mode: 'Byte',
      errorCorrectionLevel: 'H'
    },
    imageOptions: {
      hideBackgroundDots: false,
      imageSize: 0.4,
      margin: 0,
      crossOrigin: 'anonymous',
    },
    dotsOptions: {
      color: '#000000',
      type: 'dots'
    },
    backgroundOptions: {
    },
    cornersSquareOptions: {
      color: '#000000',
      type: 'extra-rounded',
    },
    cornersDotOptions: {
      color: '#000000',
      type: 'dot',

    }
  };
  /**
   * Invite type
   */
  shareMethod: ShareMethod;
  /**
   * gift card flag
   */
  giftCard: boolean;
  /**
   * invite msg
   */
  msg: string;
  /**
   * credit to share by gift card
   */
  credit: Credit;
  /**
   * Account login detail
   */
  accountLogin: AccountLogin;
  /**
   * QR Code
   */
  qrcodeData: string;

  isHybrid: boolean;

  /**
   * URL
   */
  private url: string;

  /**
   * Constructor
   * @param platform Platform
   * @param modalController Modal Controller
   * @param translate Translate Service
   * @param accountLoginService Account Login Service
   * @param accountInfoService Account Service
   * @param popupService Popup Service
   */
  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private translate: TranslateService,
    private accountLoginService: AccountLoginService,
    private accountInfoService: AccountInfoService,
    private accountCoupleService: AccountCoupleService,
    private accountEventModeService: AccountEventModeService,
    private creditService: CreditService,
    private popupService: PopupService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
  }

  /**
   * Before view enter
   */
  async ionViewWillEnter() {
    if (!this.shareMethod) {
      this.shareMethod = 'url';
    }
    this.isHybrid = this.platform.is('hybrid');
    this.setupMsg();
  }

  /**
   * Setup message
   */
  setupMsg() {
    const title = this.getTitle();
    const gift = this.getAccountProductName(this.credit?.productId);
    let role: string;
    if (!this.giftCard) {
      if (this.accountLogin?.role?.custom) {
        role = this.accountLogin.role.type;
      } else if (this.accountLogin?.role?.coupleId) {
        role = this.translate.instant('LIST.wedding_couple_type.' +
        this.accountCoupleService.getCoupleType(this.accountLogin.role.coupleId));
      } else if (this.accountLogin?.role?.type) {
        role = this.translate.instant('LIST.role.' + this.accountLogin.role.type);
      }
    }
    if (this.giftCard) {
      if (this.credit?.link?.short) {
        this.url = this.credit.link.short;
      }
      if (this.credit?.link?.url) {
        this.qrcodeData = this.credit.link.url;
      }
    } else {
      if (this.accountLogin?.link?.short) {
        this.url = this.accountLogin.link.short;
      }
      if (this.accountLogin?.link?.url) {
        this.qrcodeData = this.accountLogin.link.url;
      }
    }

    if (this.shareMethod === 'url') {
      if (this.giftCard) {
        this.msg = this.replaceEventType(this.translate.instant('ACCOUNT_SHARE.gift.share_msg.url', { url: this.url ? this.url : this.qrcodeData, gift, title }));
      } else {
        this.msg = this.replaceEventType(this.translate.instant('ACCOUNT_SHARE.account.share_msg.url', { url: this.url ? this.url : this.qrcodeData, title, role }));
      }
    } else if (this.shareMethod === 'password') {
      if (this.giftCard) {
        this.msg = title + '\n\n' + this.translate.instant('ACCOUNT_SHARE.gift.share_msg.pass', { gift });
      } else {
        this.msg = this.replaceEventType(this.translate.instant('ACCOUNT_SHARE.account.share_msg.pass',
        { weddingId: this.accountLogin?.login?.weddingId ? this.accountLogin.login.weddingId : '',
        weddingPass: this.accountLogin?.login?.weddingPass ? this.accountLogin?.login?.weddingPass : '', title, role }));
      }
    } else if (this.shareMethod === 'qrcode') {
      this.generateQrcode();
    }
  }


  /**
   * Dimiss invite modal
   */
  async dismissModal(dismiss?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { modal.dismiss({ dismiss }); }
    }
  }

  /**
   * Present gift card template modal
   */
  async presentGiftCardTemplateModal() {
    const modal = await this.modalController.create({
      component: GiftCardTemplateComponent,
      componentProps: {
        giftCardId: this.credit?.giftCardId,
        credit: this.credit
      }
    });
    modal.present();
  }

  /**
   * Present account privilege modal
   * @param accountLogin account login
   */
  async presentAccountPrivilegeModal(accountLogin: AccountLogin) {
    if (accountLogin) {
      const modal = await this.modalController.create({
        component: AccountPrivilegeComponent,
        componentProps: {
          accountLogin,
          type: 'edit',
          editRole: true,
          editPrivilege: true,
        }
      });
      modal.present();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Copy login message
   * @param type Share access type eg. weddingId / url
   */
  async copyMsg() {
    if (this.shareMethod) {
      if (this.msg) {
        try {
          await Clipboard.write({ string: this.msg })
          this.popupService.presentToast(this.translate.instant('COPY.success'));
          this.saveShareLog('copy');
        } catch (err: any) {
          this.popupService.presentToast(this.translate.instant('COPY.fail'), 'danger');
        }
      }
    }
  }

  /**
   * Copy specified field
   * @param type field type
   */
  async copyField(type: string) {
    let msg = '';
    if (type) {
      if (type === 'url') {
        msg = this.url;
      } else if (type === 'weddingId') {
        msg = this.accountLogin.login.weddingId;
      } else if (type === 'weddingPass') {
        msg = this.accountLogin.login.weddingPass;
      }
    }
    if (msg) {
      try {
        await Clipboard.write({ string: msg });
        this.popupService.presentToast(this.translate.instant('COPY.success'), 'success');
      } catch (err: any) {
        this.popupService.presentToast(this.translate.instant('COPY.fail'), 'danger');
      }
    }
  }

  /**
   * Get title for gift card / account title
   * @returns title string
   */
  getTitle(): string {
    if (this.giftCard) {
      return this.translate.instant('ACCOUNT_SHARE.gift.title');
    } else {
      return this.accountInfoService?.accountTitle
       ? this.accountInfoService.accountTitle
       : this.translate.instant('ACCOUNT_SHARE.account.title');
    }
  }

  /**
   * Get account product name
   * @param productId product id
   * @returns account product name
   */
  getAccountProductName(productId: string): string {
    if (productId) {
      return this.translate.instant('PRODUCT.account.title') + ' ' + this.translate.instant('PRODUCT.account.' + this.credit.productId + '.short');
    }
    return '';
  }

  /**
   * Get module count for current login
   * @param accountLogin account login
   */
  getModuleCount(accountLogin: AccountLogin): number {
    return this.accountLoginService.getLoginModuleCount(accountLogin);
  }

  async generateQrcode() {
    // if (this.qrcode) {
    //   const qrcode: QRCodeStyling = await this.qrcodeService.generateQRcode(this.qrcode, 300, 300, 10, 'svg', true) as QRCodeStyling;
    //   if (qrcode) {
    //     let flag = false;
    //     let counter = 0;
    //     do {
    //       counter++;
    //       if (this.canvas?.nativeElement) {
    //         qrcode.append(this.canvas.nativeElement);
    //         flag = true;
    //       } else {
    //         await this.functionService.delay(200);
    //       }
    //     } while (!flag && counter < 10);
    //   }
    // }
  }

  /**
   * Share action
   */
  async share() {
    if (this.platform.is('hybrid') && this.msg) {
      await this.popupService.presentLoading();
      await Share.share({
        title: this.getTitle(),
        text: this.msg
      }).then((result) => {
      }).catch((err) => {
        if (err?.message !== 'Share canceled') {
          this.errorService.logError(err);
        }
      });
      await this.saveShareLog('');
      this.popupService.dismissLoading();
    }
  }

  /**
   * Save share log
   * @param shareChannel Share channel
   */
  async saveShareLog(shareChannel?: ShareChannel) {
    if (!this.giftCard) {
      await this.accountLoginService.saveShareLog(this.accountLogin.login.weddingPass, shareChannel, this.shareMethod);
    } else {
      await this.creditService.saveShareLog(this.credit, shareChannel, this.shareMethod);
    }
  }

  replaceEventType(text: string) {
    return this.accountEventModeService.replaceEventType(text);
  }

}
