import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController, ActionSheetController, IonList } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { GuestDeleteService } from 'src/app/services/guest/guest-delete.service';
import { GroupService } from 'src/app/services/group/group.service';
import { GroupManageService } from 'src/app/services/group/group-manage.service';
import { GroupDeleteService } from 'src/app/services/group/group-delete.service';
import { CheckinSettingService } from 'src/app/services/checkin/checkin-setting.service';
import { CheckinService } from 'src/app/services/checkin/checkin.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { GuestListComponent } from 'src/app/components/guest/guest-list/guest-list.component';
import { GuestNewComponent } from 'src/app/components/guest/guest-new/guest-new.component';
import { GuestEditComponent } from 'src/app/components/guest/guest-edit/guest-edit.component';
import { SeatingListComponent } from 'src/app/components/seating/seating-list/seating-list.component';

import { CheckinSetting } from 'src/app/interfaces/setting';
import { Guest } from 'src/app/interfaces/guest';
import { Group } from 'src/app/interfaces/group';
import { CheckinMethodType, GuestFilterType, GuestListMode } from 'src/app/types/guest';
import { ModuleType } from 'src/app/types/general';
import { GroupLogComponent } from 'src/app/components/log/group-log/group-log.component';
import { CurrentPrivilege } from 'src/app/interfaces/privilege';

/**
 * Guest group component
 */
@Component({
  selector: 'app-guest-group',
  templateUrl: './guest-group.component.html',
  styleUrls: ['./guest-group.component.scss'],
})
export class GuestGroupComponent implements OnInit, OnDestroy {

  /**
   * Ion list for close slide item
   */
  @ViewChild('list') list: IonList;

  absentCount: number;

  checkinMethod: CheckinMethodType;
  /**
   * AccountModule
   */
  module: ModuleType;
  /**
   * Guest list mode
   */
  mode: GuestListMode;
  /**
   * Edit mode flag, trigger bulk action FAB
   */
  editMode: boolean;
  /**
   * Select all flag
   */
  selectAll: boolean;
  /**
   * Guest group
   */
  group: Group;
  /**
   * Member list
   */
  memberList: Guest[];
  /**
   * Watch group of this guest
   */
  guest: Guest;
  /**
   * Selected guest list
   */
  selectedGuestList: string[];
  /**
   * Selected group list
   */
  selectedGroupList: string[];
  /**
   * Check-in setting
   */
  checkinSetting: CheckinSetting;

  currentPrivilege: CurrentPrivilege;
  /**
   * Disable watching group
   */
  private disableGroupWatch: boolean;
  /**
   * Guest list subscription
   */
  private guestListSubscription: Subscription;
  /**
   * Group list subscription
   */
  private groupListSubscription: Subscription;
  /**
   * Check-in setting subscription
   */
  private checkinSettingSubscription: Subscription;
  
  private privilegeSubscription: Subscription;

  /**
   * Constructor
   * @param modalController modal controller
   * @param actionSheetController action sheet controller
   * @param translate translate service
   * @param moduleService module service
   * @param guestService guest service
   * @param guestManageService guest manage service
   * @param guestDeleteService guest delete service
   * @param groupService group service
   * @param groupManageService group manage service
   * @param checkinService checkin service
   * @param checkinSettingService checkin setting service
   * @param giftCheckinService gift checkin service
   * @param popupService popup service
   * @param functionService function service
   */
  constructor(
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private translate: TranslateService,
    private moduleService: ModuleService,
    private privilegeService: PrivilegeService,
    private guestService: GuestService,
    private guestManageService: GuestManageService,
    private guestDeleteService: GuestDeleteService,
    private groupService: GroupService,
    private groupManageService: GroupManageService,
    private groupDeleteService: GroupDeleteService,
    private checkinService: CheckinService,
    private checkinSettingService: CheckinSettingService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unwatch();
  }

  /**
   * Before view enter
   */
  async ionViewWillEnter() {
    if (!this.module) {
      this.module = this.moduleService.currentModule;
    }
    if (!this.selectedGuestList) { this.selectedGuestList = []; }
    if (!this.selectedGroupList) { this.selectedGroupList = []; }
    this.watch();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatch();
  }

  watch() {
    if (!this.disableGroupWatch) { this.watchGroupList(); }
    this.watchGuest();
    this.watchCheckinSetting();
    this.watchPrivilege();
  }

  unwatch() {
    this.unwatchGuest();
    this.unwatchGroupList();
    this.unwatchCheckinSetting();
    this.unwatchPrivilege();
  }

  ionViewDidLeave() {
    this.module = null;
  }

  /**
   * Watch guest
   */
  async watchGuest() {
    if (!this.guestListSubscription) {
      this.guestListSubscription = this.guestService.observableGuestList.subscribe(() => {
        this.setupGuest();
      });
    }
    
  }

  /**
   * Unwatch guest
   */
  async unwatchGuest() {
    if (this.guestListSubscription) {
      this.guestListSubscription.unsubscribe();
      this.guestListSubscription = null;
    }
  }

  /**
   * Watch group list
   */
  async watchGroupList() {
    if (!this.groupListSubscription) {
      this.groupListSubscription = this.groupService.observableGroupList.subscribe(() => {
        this.setupGroup();
      });
    }
    
  }

  /**
   * Unwatch group list
   */
  async unwatchGroupList() {
    if (this.groupListSubscription) {
      this.groupListSubscription.unsubscribe();
      this.groupListSubscription = null;
    }
  }

  /**
   * watch check-in setting
   */
  async watchCheckinSetting() {
    if (!this.checkinSettingSubscription) {
      this.checkinSettingSubscription = this.checkinSettingService.observableCheckinSetting.subscribe((checkinSetting: CheckinSetting) => {
        this.checkinSetting = checkinSetting;
      });
    }
    
  }

  /**
   * Unwatch check-in setting
   */
  async unwatchCheckinSetting() {
    if (this.checkinSettingSubscription) {
      this.checkinSettingSubscription.unsubscribe();
      this.checkinSettingSubscription = null;
    }
  }

  async watchPrivilege() {
    if (!this.privilegeSubscription) {
      this.privilegeSubscription = this.privilegeService.observableCurrentPrivilege.subscribe(() => {
        this.setupPrivilege();
      })
    }
  }

  async unwatchPrivilege() {
    if (this.privilegeSubscription) {
      this.privilegeSubscription.unsubscribe();
      this.privilegeSubscription = null;
    }
  }

  setupPrivilege() {
    if (!this.currentPrivilege) {
      this.currentPrivilege = {};
    }
    if (this.module) {
      this.currentPrivilege = {
        [this.module]: {
          'checkin': this.checkPrivilege(this.module, 'checkin'),
          'uncheck': this.checkPrivilege(this.module, 'uncheck'),
        }
      };
    }
  }

  /**
   * Setup guest of this group
   */
  setupGuest() {
    if (this.guest?.guestId) {
      const guest = this.guestService.getGuest(this.guest.guestId);
      // if (guest?.groupId !== this.guest.groupId) {
      //   this.setupGroup();
      // }
      this.setupGroup();
      this.guest = guest;
    }
  }

  /**
   * Setup group
   */
  setupGroup() {
    if (this.guest?.groupId) {
      this.group = this.groupService.getGroup(this.guest.groupId);
    } else if (this.group?.groupId) {
      this.group = this.groupService.getGroup(this.group.groupId);
    }

    if (this.group?.groupId) {
      if (this.group?.memberList?.length) {
        this.memberList = this.guestService.getGuestListById(this.group.memberList);
      } else {
        // this.memberList = this.guestService.getGuestListById(this.guestService.getGroupMemberId(this.group.groupId));
      }
    }
    
    this.setupAbsentCount();
  }

  setupAbsentCount() {
    this.absentCount = this.getAbsentCount();
  }

  /**
   * Get total of checked-in guest
   * @returns number of check-in guest
   */
  getCheckinCount(): number {
    return this.memberList?.length ? this.memberList.filter((guest: Guest) => guest.status.checkin).length : 0;
  }

  /**
   * Get total of absent guest
   * @returns number of absent guest
   */
  getAbsentCount(): number {
    return this.memberList?.length ? this.memberList.filter((guest: Guest) => !guest.status.checkin).length : 0;
  }

  /**
   * Reset select all
   */
  resetSelectAll() {
    this.selectAll = false;
    this.selectedGuestList = [];
    this.selectedGroupList = [];
  }

  /**
   * Toggle edit mode
   */
  toggleEditMode(editMode?: boolean) {
    if (this.functionService.isUndefined(editMode)) {
      this.editMode = !this.editMode;
    } else {
      this.editMode = editMode;
    }
    this.resetSelectAll();
    this.closeSliding();
  }

  /**
   * Toggle select all
   */
  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    this.selectedGuestList = [];
    this.selectedGroupList = [];
    if (this.selectAll) {
      this.selectedGroupList.push(this.group.groupId);
      this.selectedGuestList = [ ...this.group?.memberList ];
    }
  }

  /**
   * Check select all
   */
  checkSelectAll() {
    if (this.selectedGuestList?.length === this.group?.memberList?.length) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  }

  /**
   * Select guest
   * @param guestId Guest ID
   */
  setSelectedGuest(guestId: string) {
    const index = this.selectedGuestList?.indexOf(guestId);
    if (index === -1) {
      this.selectedGuestList.push(guestId);
    } else {
      this.selectedGuestList?.splice(index, 1);
    }
    this.checkSelectAll();
  }

  /**
   * Select group
   * @param groupId Group ID
   * @param selected Selected flag
   * @param skipCheck Skip check flag
   */
  setSelectedGroup(groupId: string, selected?: boolean, skipCheck?: boolean) {
    const index = this.selectedGroupList?.indexOf(groupId);
    if (index === -1) {
      if (this.functionService.isUndefined(selected) || selected === true) {
        this.selectedGroupList.push(groupId);
        if (!skipCheck) {
          this.checkSelectedGroup(groupId, true, this.functionService.isUndefined(selected) ? false : true);
        }
      }
    } else {
      if (this.functionService.isUndefined(selected) || selected === false) {
        if (this.selectedGroupList?.length) {
          this.selectedGroupList?.splice(index, 1);
        }
        if (!skipCheck) {
          this.checkSelectedGroup(groupId, false, this.functionService.isUndefined(selected) ? false : true);
        }
      }
    }
  }

  /**
   * Check selected group
   * @param groupId Group ID
   * @param selected Selected Flag
   */
  checkSelectedGroup(groupId: string, selected?: boolean, skipCheck?: boolean) {
    const group: Group = this.groupService.getGroup(groupId);
    if (group?.memberList) {
      let flag = true;
      group.memberList?.forEach((guestId: string) => {
        const index = this.selectedGuestList?.indexOf(guestId);
        if (index === -1) {
          flag = false;
          if (!this.functionService.isUndefined(selected) && selected === true) {
            this.selectedGuestList.push(guestId);
          }
        } else {
          if (!this.functionService.isUndefined(selected) && selected === false) {
            this.selectedGuestList?.splice(index, 1);
          }
        }
      });
      if (this.functionService.isUndefined(selected)) {
        this.setSelectedGroup(groupId, flag, true);
      }
    }

    if (!skipCheck) {
      this.checkSelectAll();
    }
  }

  /**
   * Check if single record then trigger select all
   */
  checkSingleRecord() {
    if (!this.selectAll && this.group?.memberList?.length === 1) {
      this.toggleSelectAll();
    }
  }

  /**
   * Close ion item sliding
   */
  closeSliding() {
    if (this.list) {
      this.list?.closeSlidingItems();
    }
  }

  /**
   * Bulk action
   * @param type Action type
   */
  async bulkAction(type: string) {
    if (type) {
      if (type === 'add') {
        this.editMode = false;
        await this.presentGuestListModal();
        this.toggleEditMode(false);
      } else {
        this.checkSingleRecord();
        if (type === 'delete') {
          if (this.module === 'trash') {
            this.promptDeleteGuest();
          } else {
            this.presentGroupDeleteActionSheet();
          }
        } else if (type === 'info') {
          await this.presentGuestBulkEditModal();
          this.toggleEditMode(false);
        } else if (type === 'assign') {
          await this.presentSeatingListModal(true, false);
          this.toggleEditMode(false);
        } else if (type === 'restore') {
          this.promptRestore();
        } else if (type === 'checkin') {
          this.checkin();
        } else if (type === 'uncheck') {
          this.uncheck();
        }
      }
    }
  }

  /**
   * Dismiss guest group modal
   * @param selectedGuestList Selected guest list
   * @param guest Guest
   */
  async dismissModal(selectedGuestList?: string[], guest?: Guest) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ selectedGuestList, guest }); }
    }
  }

  /**
   * Present add new guest modal
   * @param group Guest group
   */
  async presentGuestNewModal() {
    const defaultValue: any = {};
    if (this.group?.memberList?.length) {
      const groupAdmin = this.guestService.getGuest(this.group.memberList[0]);
      if (groupAdmin) {
        if (groupAdmin?.invitedBy?.length) {
          defaultValue.invitedBy = [ groupAdmin.invitedBy[0] ];
        }
        if (groupAdmin?.category?.length) {
          defaultValue.category = [ groupAdmin.category[0] ];
        }
        if (groupAdmin?.dietaryReq?.length) {
          defaultValue.dietaryReq = [ groupAdmin.dietaryReq[0] ];
        }
      }
    }

    const modal = await this.modalController.create({
      component: GuestNewComponent,
      cssClass: '',
      componentProps: {
        setupGroup: this.group,
        defaultValue
      }
    });
    modal.present();
  }

  /**
   * Present guest list modal
   */
  async presentGuestListModal() {
    let group = { ...this.group };

    if (!group?.memberList?.length) {
      if (!group) {
        group = {
          groupId: '',
          groupName: '',
          memberList: [ this.guest?.guestId ],
        };
      } else {
        group.memberList = [ this.guest?.guestId ];
      }
    }

    const type: GuestFilterType = {
      group: 'individual'
    };

    const modal = await this.modalController.create({
      component: GuestListComponent,
      cssClass: '',
      componentProps: {
        mode: 'group',
        type,
        group,
        selectMode: true,
        excludeGuestIdList: this.guest?.guestId ? [ this.guest.guestId ] : [],
        excludeGroupIdList: this.group?.groupId ? [ this.group?.groupId ] : [],
        defaultFilter: {
          enable: true,
          status: {
            attending: 'attending'
          }
        }
      }
    });
    modal.present();
    modal.onDidDismiss().then(async (result: any) => {
      if (!result?.data?.newGuest && result?.data?.selectedGuestList?.length) {
        const selectedGuestList = result.data.selectedGuestList;
        if (this.group?.groupId) {
          await this.popupService.presentLoading();
          await this.updateGuest(this.group, selectedGuestList);
          this.popupService.dismissLoading();
          this.popupService.saveSuccessToast();
        } else {
          this.presentNewGroupNameInput(selectedGuestList);
        }
      }
    });
  }

  /**
   * Present guest bulk edit modal
   */
  async presentGuestBulkEditModal() {
    if (this.checkPrivilege(this.module, 'edit')) {
      let guest: Guest;
      let bulkEditMode = true;

      if (this.selectedGuestList?.length === 1) {
        bulkEditMode = false;
        guest = this.guestService.getGuest(this.selectedGuestList[0]);
      }

      const modal = await this.modalController.create({
        component: GuestEditComponent,
        cssClass: '',
        componentProps: {
          bulkEditMode,
          guest,
          selectedGuestList: this.selectedGuestList
        }
      });
      modal.present();
    } else {
      this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
    }
  }

  /**
   * Present seating list modal
   * @param assignMode Assign mode
   * @param swapMode Swap mode
   */
  async presentSeatingListModal(assignMode: boolean, swapMode: boolean) {
    const newGuestList: Guest[] = this.selectedGuestList.map((guestId: string) => {
      return this.guestService.getGuest(guestId);
    });

    const modal = await this.modalController.create({
      component: SeatingListComponent,
      cssClass: '',
      componentProps: {
        assignMode,
        swapMode,
        newGuestList
      }
    });
    modal.present();
  }

  /**
   * Present group log modal
   */
  async presentGroupLogModal() {
    if (this.group?.groupId) {
      const modal = await this.modalController.create({
        component: GroupLogComponent,
        componentProps: {
          groupId: this.group?.groupId,
        }
      });
      modal.present();
    }
  }

  /**
   * Present group name input for new group.
   * @param selectedGuestList Selected guest list
   */
  async presentNewGroupNameInput(selectedGuestList: string[]) {
    const modal = await this.popupService.presentInput(this.translate.instant('GROUP.lbl.name'));
    modal.onDidDismiss().then(async (result: any) => {
      if (result?.data?.input) {
        await this.popupService.presentLoading();
        const group: Group = {
          groupId: this.guestManageService.groupId,
          groupName: result.data.input
        };
        if (this.guest?.guestId) {
          selectedGuestList.push(this.guest.guestId);
        }
        await this.groupManageService.saveGroupList([ group.groupId ], group, [ group ]);
        await this.updateGuest(group, selectedGuestList);
        this.popupService.dismissLoading();
        this.popupService.saveSuccessToast();
      }
    });
  }

  /**
   * Present group delete action sheet
   * @param guest Guest
   */
  async presentGroupDeleteActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: this.translate.instant('GROUP.delete.guest_action_sheet.title'),
      buttons: [{
        text: this.translate.instant('GROUP.delete.guest_action_sheet.remove_group'),
        handler: () => {
          this.promptRemoveGuestGuestFromGroup();
        }
      }, {
        text: this.translate.instant('GROUP.delete.guest_action_sheet.delete_guest'),
        handler: () => {
          this.promptRemoveGuest();
        }
      }, {
        text: this.translate.instant('BTN.cancel'),
        icon: 'close',
        role: 'cancel',
        handler: () => {
        }
      }]
    });
    actionSheet.present();
  }

  /**
   * Prompt to confirm remove guest from group
   */
  async promptRemoveGuestGuestFromGroup() {
    const guestList = this.guestService.getGuestListById(this.selectedGuestList);
    const modal = await this.popupService.presentConfirm(
      this.translate.instant('GROUP.delete.msg', { count: this.selectedGuestList?.length }),
      null, null, null, null, null, null, null, guestList
    );
    modal.onDidDismiss().then(async (result: any) => {
      if (result?.data?.confirm) {
        await this.removeMemberFromGroup(this.selectedGuestList);
      }
      this.toggleEditMode(false);
    });
  }

  /**
   * Prompt confirm restore modal
   */
  async promptRestore(group?: boolean) {
    const guestList = this.guestService.getGuestListById(this.selectedGuestList);
    const modal = await this.popupService.presentConfirm(
      this.translate.instant('CRUD.confirm_restore_field_count', {
        field: group ? this.translate.instant('GROUP.lbl.guest_group') : this.translate.instant('GUEST.lbl.guest'),
        count: group ? 1 : this.selectedGuestList?.length
      }),
      null, null, null, null, null, null, null, guestList
    );
    modal.onDidDismiss().then(async (result: any) => {
      if (result?.data?.confirm) {
        if (group) {
          await this.restoreGroup();
        } else {
          await this.saveRestoreGuest();
        }
      }
      this.toggleEditMode(false);
    });
  }

  /**
   * Prompt remove guest modal
   */
   async promptDeleteGuest() {
    const guestList = this.guestService.getGuestListById(this.selectedGuestList);
    const modal = await this.popupService.presentConfirm(
      this.translate.instant('CRUD.confirm_delete_db_field_count', {
        field: this.translate.instant('GUEST.lbl.guest'), count: this.selectedGuestList?.length }),
        null, null, null, null, null, null, null, guestList
    );
    modal.onDidDismiss().then(async (result: any) => {
      if (result?.data?.confirm) {
        await this.deleteGuest();
      }
      this.toggleEditMode(false);
    });
  }

  /**
   * Prompt remove guest modal
   */
  async promptRemoveGuest() {
    const guestList: Guest[] = this.guestService.getGuestListById(this.selectedGuestList);
    const modal = await this.popupService.presentConfirm(
      this.translate.instant('CRUD.confirm_delete_field_count', {
        field: this.selectedGuestList?.length > 1
        ? this.translate.instant('GUEST.lbl.guests')
        : this.translate.instant('GUEST.lbl.guest'),
        count: this.selectedGuestList?.length }), '', '',  '', '', '', false, '', guestList
    );
    modal.onDidDismiss().then(async (result: any) => {
      if (result?.data?.confirm) {
        await this.removeGuest();
      }
      this.toggleEditMode(false);
    });
  }

  /**
   * Prompt delete
   */
  async promptRemoveGroup() {
    const guestList = this.guestService.getGuestListById(this.selectedGuestList);
    const modal = await this.popupService.presentConfirm(
      this.translate.instant('CRUD.confirm_delete_db_field', {
        field: this.translate.instant('GROUP.lbl.guest_group') }),
        null, null, null, null, null, null, null, guestList
    );
    modal.onDidDismiss().then(async (result: any) => {
      if (result?.data?.confirm) {
        await this.deleteGroupAndMember(this.group);
      }
    });
  }

  /**
   * Checkin function
   * @param group checkin whole group
   */
  async checkin(group?: boolean) {
    const groupIdList = this.group?.groupId ? [ this.group.groupId ] : [];
    // const groupList = this.group ? [ this.group ] : [];
    if (group && this.group?.memberList?.length) {
      await this.checkinService.checkin(this.checkinMethod, this.group.memberList, this.memberList, groupIdList, [], true, true, true, true);
    } else if (this.selectedGuestList?.length) {
      await this.checkinService.checkin(this.checkinMethod, this.selectedGuestList, null, groupIdList, [], true, true, true, true);
    }
    // this.dismissModal();
    this.toggleEditMode(false);
  }

  /**
   * Uncheck function
   * @param group uncheck whole group
   */
  async uncheck(group?: boolean) {
    const groupIdList = this.group?.groupId ? [ this.group.groupId ] : [];
    // const groupList = this.group ? [ this.group ] : [];
    if (group && this.group?.memberList?.length) {
      await this.checkinService.uncheck(this.group.memberList, this.memberList, groupIdList, [], true);
    } else if (this.selectedGuestList?.length) {
      await this.checkinService.uncheck(this.selectedGuestList, [], groupIdList, [], true);
    }
    // this.dismissModal();
    this.toggleEditMode(false);
  }

  /**
   * Check user privilege
   * @param action Action
   */
  checkPrivilege(module: ModuleType, action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege(module, action);
  }

  /**
   * Save gift status
   * @param gift gift received status
   */
  async saveGift(gift: boolean) {
    // this.giftCheckinService.checkinGift(gift, null, this.group);
    // await this.checkinService.receiveGift(gift, [], [ this.group.groupId ], [], [ this.group ], true, false, true, true);
    const guestList = this.guestService.getGuestListById(this.group?.memberList);
    await this.checkinService.receiveGift(this.checkinMethod, gift, this.group?.memberList, [ this.group.groupId ], guestList, [ this.group ], true, false, true, true);
    // this.dismissModal();
  }

  /**
   * Save restore guest
   */
  async saveRestoreGuest() {
    await this.popupService.presentLoading();
    if (this.selectedGuestList?.length) {
      await this.guestDeleteService.restoreGuestList(this.selectedGuestList);
    }
    await this.popupService.dismissLoading();
    await this.popupService.saveSuccessToast();
  }

  /**
   * Remove guest from db
   */
  async removeGuest() {
    await this.popupService.presentLoading();
    if (this.selectedGuestList?.length) {
      await this.guestDeleteService.removeGuestList(this.selectedGuestList);
    }
    await this.popupService.dismissLoading();
    await this.popupService.saveSuccessToast();
  }

  async deleteGuest() {
    await this.popupService.presentLoading();
    await this.guestDeleteService.deleteGuestList(this.selectedGuestList);
    await this.popupService.dismissLoading();
    await this.popupService.saveSuccessToast();
  }

  /**
   * Restore group
   */
  async restoreGroup() {
    await this.popupService.presentLoading();
    if (this.group?.groupId) {
      await this.groupDeleteService.restoreGroupList([ this.group.groupId ]);
      if (this.group?.memberList?.length) {
        await this.guestDeleteService.restoreGuestList(this.group.memberList);
      }
    }
    await this.popupService.dismissLoading();
    await this.popupService.saveSuccessToast();
    await this.dismissModal();
  }

  /**
   * Update new member for group
   * @param memberList Group member list
   * @param selectedGuestList Selected guest list
   */
  async updateGuest(group: Group, selectedGuestList: string[]) {
    if (group?.groupId && selectedGuestList?.length) {
      await this.guestManageService.saveGuest({ groupId: group.groupId }, selectedGuestList, '', [], true);
      if (this.guest?.guestId) {
        this.guest.groupId = group.groupId;
      }
    }
  }

  /**
   * Save remove guest from group
   * @param deleteGuestList Guest ID
   */
  async removeMemberFromGroup(deleteGuestList?: string[]) {
    if (deleteGuestList?.length && this.group?.memberList?.length) {
      await this.popupService.presentLoading();
      await this.groupDeleteService.removeMemberFromGroup(this.group.groupId, this.group, deleteGuestList, false);
      await this.popupService.dismissLoading();
      await this.popupService.saveSuccessToast();
      if (deleteGuestList.length === this.group.memberList.length) {
        await this.dismissModal();
      }
    }
  }

  /**
   * delete group and member
   * @param group Group
   */
  async deleteGroupAndMember(group: Group) {
    await this.popupService.presentLoading();
    if (group?.groupId) {
      await this.groupDeleteService.deleteGroupListMember([ group.groupId ], [ group ]);
      await this.groupDeleteService.deleteGroupList([ group.groupId ]);
    }
    await this.popupService.dismissLoading();
    await this.popupService.saveSuccessToast();
    await this.dismissModal();
  }

}
