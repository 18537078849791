import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { GuestListService } from 'src/app/services/guest/guest-list.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { GuestListComponent } from 'src/app/components/guest/guest-list/guest-list.component';
import { Guest } from 'src/app/interfaces/guest';
import { Gift } from 'src/app/interfaces/gift';
import { GiftListComponent } from 'src/app/components/gift/gift-list/gift-list.component';

/**
 * Confirm component
 */
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit, OnDestroy {

  hideIcon: boolean;
  /**
   * Title
   */
  title: string;
  /**
   * Subtitle
   */
  subtitle: string;
  /**
   * Message
   */
  msg: string;
  /**
   * type
   */
  type: string;
  /**
   * Yes btn color
   */
  yesBtnColor: string;
  /**
   * Yes btn text
   */
  yesBtnText: string;
  /**
   * No btn text
   */
  noBtnText: string;
  /**
   * Show cancel btn
   */
  cancelBtn: boolean;
  /**
   * Cancel btn text
   */
  cancelBtnText: string;
  /**
   * Preivew guest list
   */
  previewGuestList: Guest[];
  previewGiftList: Gift[];
  /**
   * Show guest seating
   */
  private showGuestSeating: boolean;

  /**
   * Constructor
   * @param modalController Modal Controller
   */
  constructor(
    private modalController: ModalController,
    private guestListService: GuestListService,
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  /**
   * Confirm
   */
  async confirm() {
    await this.dismissModal(true);
  }

  /**
   * Cancel
   */
  async cancel() {
    await this.dismissModal(false);
  }

  /**
   * Dismiss Confirm modal
   * @param confirm confirm
   */
  async dismissModal(confirm: boolean, cancel?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { await modal.dismiss({ confirm, cancel }); }
    }
  }

  async previewList() {
    if (this.previewGuestList?.length) {
      this.presentGuestListModal();
    } else if (this.previewGiftList?.length) {
      this.presentGiftListModal();
    }
  }

  /**
   * Present guest list modal for preview
   */
  async presentGuestListModal() {
    if (this.previewGuestList?.length) {
      const guestList = this.guestListService.generateGroupList(this.previewGuestList);
      const modal = await this.modalController.create({
        component: GuestListComponent,
        cssClass: '',
        componentProps: {
          guestList,
          mode: 'preview',
          editMode: true,
          showGuestSeating: this.showGuestSeating,
          count: this.previewGuestList.length,
        }
      });
      modal.present();
    }
  }

  async presentGiftListModal() {
    if (this.previewGiftList?.length) {
      const modal = await this.modalController.create({
        component: GiftListComponent,
        cssClass: '',
        componentProps: {
          giftList: this.previewGiftList,
          mode: 'preview',
          editMode: true,
        }
      });
      modal.present();
    }
  }

}
