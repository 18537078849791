<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start" *ngIf="!promptMode">
      <ion-button (click)="goBack()">
        <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'AUP.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [style.height]="pageMode ? 'calc(100vh - 44px - 88px)' : '100%'">
  <div class="content-div">
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-text [innerHTML]="'AUP.intro.comply' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'AUP.intro.reserve' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'AUP.intro.violation' | translate"></ion-text></p>
      </ion-label>
    </ion-item>
    
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-label class="ion-text-uppercase" [innerHTML]="'AUP.general.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'AUP.general.intro' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'AUP.general.point.1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'AUP.general.point.2' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'AUP.general.point.3' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'AUP.general.point.4' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'AUP.general.point.5' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'AUP.general.point.6' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'AUP.general.point.7' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'AUP.general.point.8' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'AUP.general.point.9' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'AUP.general.point.10' | translate"></ion-text></p></li>
          </ul>
        </div>
      </ion-label>
    </ion-item>
  </div>
</ion-content>
<ion-footer *ngIf="promptMode">
  <ion-toolbar>
    <div class="content-div ion-padding">
      <ion-button expand="block" (click)="agree()">
        {{ 'AUP.btn.agree_prompt' | translate }}
      </ion-button>
    </div>
  </ion-toolbar>
</ion-footer>
