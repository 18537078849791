// import { PopupService } from 'src/app/services/general/popup.service';
import { Platform } from '@ionic/angular';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { IsDebug } from '@awesome-cordova-plugins/is-debug/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { ServiceWorkerModule } from '@angular/service-worker';
@Injectable({
  providedIn: 'root'
})
export class DebugModeService implements OnInit, OnDestroy {

  debugMode: boolean;

  constructor(
    private platform: Platform,
    private isDebug: IsDebug,
    // private popupService: PopupService,
  ) {
    this.check();
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
  }

  async check() {
    await this.platform.ready();
    this.debugMode = false;
    if (this.platform.is('hybrid')) {
      this.isDebug.getIsDebug().then((isDebug: boolean) => {
        this.debugMode = isDebug;
        // if (!this.debugMode) {
        //   ServiceWorkerModule.register('combined-sw.js', { enabled: true });
        // }
      });
    } else {
      if (window?.location?.hostname === 'localhost') {
        this.debugMode = true;
      } else {
        // ServiceWorkerModule.register('combined-sw.js', { enabled: true });
      }
    }
  }

}
