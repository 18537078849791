import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { BlastPrice } from 'src/app/interfaces/blast';
import { AccountInfoService } from '../account/account-info.service';
import { BlastType } from 'src/app/types/blast';
import { SmsPriceList } from 'src/app/commons/smsPrice';
import { WhatsAppOtherCredit, WhatsAppPriceList } from 'src/app/commons/whatsappPrice';


@Injectable({
  providedIn: 'root'
})
export class BlastPricingService implements OnInit, OnDestroy {

  constructor(
    private accountInfoService: AccountInfoService,
  ) { }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  getBlastCredit(blastType: BlastType, country?: string, phone?: number) {
    if (blastType === 'sms') {
      return this.getSmsCredit(country, phone);
    } else if (blastType === 'whatsapp') {
      return this.getWhatsAppCredit(country, phone);
    }
    return -1;
  }

  getSmsCredit(country?: string, phone?: number): number {
    if (country) {
      const index = SmsPriceList.findIndex((x: BlastPrice) => x.country.toUpperCase() === country.toUpperCase());
      if (index !== -1 && SmsPriceList?.[index]?.blastCredit) {
        return SmsPriceList[index].blastCredit;
      }
    } else if (phone) {
      const index = SmsPriceList.findIndex((x: BlastPrice) => x.phone === phone);
      if (index !== -1 && SmsPriceList?.[index]?.blastCredit) {
        return SmsPriceList[index].blastCredit;
      }
    }
    return -1;
  }

  getWhatsAppCredit(country?: string, phone?: number): number {
    if (country) {
      const index = WhatsAppPriceList.findIndex((x: BlastPrice) => x.country === country);
      if (index !== -1 && WhatsAppPriceList?.[index]?.blastCredit) {
        return WhatsAppPriceList[index].blastCredit;
      }
    } else if (phone) {
      const index = WhatsAppPriceList.findIndex((x: BlastPrice) => x.phone === phone);
      if (index !== -1 && WhatsAppPriceList?.[index]?.blastCredit) {
        return WhatsAppPriceList[index].blastCredit;
      }
    }
    return WhatsAppOtherCredit;
  }

  getBlastCount(blastType: BlastType, totalBlastCredit: number, country?: string) {
    if (blastType === 'sms') {
      return this.getSmsCount(totalBlastCredit, country);
    } else if (blastType === 'whatsapp') {
      return this.getWhatsAppCount(totalBlastCredit, country);
    }
    return 0;
  }

  getSmsCount(totalBlastCredit: number, country?: string) {
    let result = 0;
    if (!country && this.accountInfoService.accountInfo?.location?.locality?.country) {
      country = this.accountInfoService.accountInfo.location.locality.country;
    }
    if (country && totalBlastCredit) {
      const blastCredit: number = this.getSmsCredit(country);
      if (blastCredit) {
        result = Math.floor(totalBlastCredit / blastCredit);
      }
    }
    return result && result > 0 ? result : 0;
  }

  getWhatsAppCount(totalBlastCredit: number, country?: string) {
    let result = 0;
    if (!country && this.accountInfoService.accountInfo?.location?.locality?.country) {
      country = this.accountInfoService.accountInfo.location.locality.country;
    }
    if (country && totalBlastCredit) {
      const blastCredit: number = this.getWhatsAppCredit(country);
      if (blastCredit) {
        result = Math.floor(totalBlastCredit / blastCredit);
      }
    }
    return result && result > 0 ? result : 0;
  }

  validatSmsPhone(phone?: number, country?: string) {
    const blastCredit = this.getSmsCredit(country, phone);
    if (blastCredit && blastCredit > 0) {
      return true;
    }
    return false;
  }

  validatWhatsAppPhone(phone?: number, country?: string) {
    const blastCredit = this.getWhatsAppCredit(country, phone);
    if (blastCredit && blastCredit > 0) {
      return true;
    }
    return false;
  }
}
