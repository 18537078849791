/**
 * Account gift card tempalte list
 */
export const GiftCardTemplateList: any = {
    en: [
        'en-3',
        'en-4',
        'en-5',
        'en-6',
        'en-7',
        'en-8'
    ],
    zh: [
        ''
    ],
    'zh-tw': [
        ''
    ],
    ms: [
        ''
    ]
};
