import { AccountTask } from '../interfaces/task';

/**
 * Standard account task list
 */
export const StdAccountTaskList: AccountTask[] = [
    {
        name: 'setup_visitor_page',
        help: 'https://thebigdays.com/how-to-setup-guest-landing-page/',
        order: 1,
    },
    {
        name: 'add_guest',
        help: 'https://thebigdays.com/how-to-add-new-guest/',
        order: 2,
    },
    {
        name: 'send_qr',
        help: 'https://thebigdays.com/how-to-send-guest-qr-code/',
        order: 3,
    },
    {
        name: 'invite_member',
        help: 'https://thebigdays.com/how-to-invite-members-to-access-the-wedding-account/',
        order: 4,
    },
    {
        name: 'assign_seating',
        help: 'https://thebigdays.com/how-to-assign-guest-seating/',
        order: 5,
    },
    {
        name: 'review_setting',
        help: 'https://thebigdays.com/how-to-change-or-review-check-in-settings/',
        order: 6,
    },
    {
        name: 'checkin_guest',
        help: 'https://thebigdays.com/help-center/check-in/',
        order: 7,
    },
    {
        name: 'record_gift',
        help: 'https://thebigdays.com/help-center/gift-log/',
        order: 8,
    }
];
