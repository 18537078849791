import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { InvitedByService } from 'src/app/services/setting/invited-by.service';
import { CategoryService } from 'src/app/services/setting/category.service';
import { SpecialReqService } from 'src/app/services/setting/special-req.service';
import { DietaryReqService } from 'src/app/services/setting/dietary-req.service';

import { SettingField } from 'src/app/interfaces/database';
import { GiftType } from 'src/app/types/gift';
import { GiftStatisticType, GuestStatisticType } from 'src/app/types/statistic';
import Swiper from 'swiper';
import { ModuleType } from 'src/app/types/general';
import { FunctionService } from 'src/app/services/general/function.service';

/**
 * Guest statistic component.
 */
@Component({
  selector: 'app-gift-statistic',
  templateUrl: './gift-statistic.component.html',
  styleUrls: ['./gift-statistic.component.scss'],
})
export class GiftStatisticComponent implements OnInit, OnDestroy {

  /**
   * Content for scroll to top
   */
  @ViewChild('content') private content: IonContent;

  module: ModuleType;

  listType: GuestStatisticType;
  /**
   * Gift Statistic type
   */
  statisticType: GiftStatisticType;
  /**
   * Gift type
   */
  giftType: GiftType;
  /**
   * Currency
   */
  currency: string;

  /**
   * Invited By
   */
  invitedBy: SettingField | string;
  /**
   * Invited by list
   */
  invitedByList: SettingField[];
  /**
   * Category list
   */
  categoryList: SettingField[];
  /**
   * Dietary request list
   */
  dietaryReqList: SettingField[];
  /**
   * Special request list
   */
  specialReqList: SettingField[];

  // contentSwiper: Swiper;
  swiper: Swiper;

  /**
   * Invited by subscription
   */
  private invitedBySubscription: Subscription;
  /**
   * Category list subscription
   */
  private categoryListSubscription: Subscription;
  /**
   * Dietary request list subscription
   */
  private dietaryReqListSubscription: Subscription;
  /**
   * Special request list subscription
   */
  private specialReqListSubscription: Subscription;

  /**
   * Constructor
   * @param modalController modal controller
   * @param invitedByService invited by service
   * @param categoryService category service
   * @param dietaryReqService dietary request service
   * @param specialReqService special request service
   */
  constructor(
    private ngZone: NgZone,
    private modalController: ModalController,
    private invitedByService: InvitedByService,
    private categoryService: CategoryService,
    private dietaryReqService: DietaryReqService,
    private specialReqService: SpecialReqService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unwatch();
  }

  /**
   * Start watching and setup slider
   */
  ionViewWillEnter() {
    if (!this.invitedBy) {
      this.invitedBy = '';
    }
    if (!this.listType) {
      this.listType = 'category';
    }
    this.watch();
  }

  ionViewDidEnter() {
  }

  /**
   * Unwatch before view leave
   */
  ionViewWillLeave() {
    this.unwatch();
  }

  watch() {
    this.watchInvitedBy();
    this.watchCategory();
    this.watchDietaryReq();
    this.watchSpecialReq();
  }

  unwatch() {
    this.unwatchInvitedBy();
    this.unwatchCategory();
    this.unwatchDietaryReq();
    this.unwatchSpecialReq();
  }

  /**
   * Get statistic type on slide changed - category / dietary request / special request.
   */
  async ionSlideChange() {
  }

  /**
   * Watch invited by changes
   */
  async watchInvitedBy() {
    if (!this.invitedBySubscription) {
      this.invitedBySubscription = this.invitedByService.observableInvitedbyList.subscribe((invitedByList: SettingField[]) => {
        this.invitedByList = this.invitedByService.getInvitedByList();
      });
    }
    
  }

  /**
   * Unwatch invited by
   */
  async unwatchInvitedBy() {
    if (this.invitedBySubscription) {
      this.invitedBySubscription.unsubscribe();
      this.invitedBySubscription = null;
    }
  }

  /**
   * Watch category
   */
  async watchCategory() {
    if (!this.categoryListSubscription) {
      this.categoryListSubscription = this.categoryService.observableCategoryList.subscribe(() => {
        this.categoryList = this.categoryService.getCategoryList();
      });
    }
    
  }

  /**
   * Unwatch category
   */
  async unwatchCategory() {
    if (this.categoryListSubscription) {
      this.categoryListSubscription.unsubscribe();
      this.categoryListSubscription = null;
    }
  }

  /**
   * Watch dietary request
   */
  async watchDietaryReq() {
    if (!this.dietaryReqListSubscription) {
      this.dietaryReqListSubscription = this.dietaryReqService.observableDietaryReqList.subscribe((dietaryReqList: SettingField[]) => {
        this.dietaryReqList = this.dietaryReqService.getDietaryReqList();
      });
    }
    
  }

  /**
   * Unwatch dietary request
   */
  async unwatchDietaryReq() {
    if (this.dietaryReqListSubscription) {
      this.dietaryReqListSubscription.unsubscribe();
      this.dietaryReqListSubscription = null;
    }
  }

  /**
   * Watch special request
   */
  async watchSpecialReq() {
    if (!this.specialReqListSubscription) {
      this.specialReqListSubscription = this.specialReqService.observableSpecialReqList.subscribe((specialReqList: SettingField[]) => {
        this.specialReqList = this.specialReqService.getSpecialReqList();
      });
    }
    
  }

  /**
   * Unwatch special request
   */
  async unwatchSpecialReq() {
    if (this.specialReqListSubscription) {
      this.specialReqListSubscription.unsubscribe();
      this.specialReqListSubscription = null;
    }
  }

  // setContentSwiper(swiper: Swiper) {
  //   this.contentSwiper = swiper;
  //   this.contentSwiperChange();
  // }



  /**
   * Next slide
   */
  nextSlide() {
    this.swiper?.slideNext();
  }

  /**
   * Previous slide
   */
  prevSlide() {
    this.swiper?.slidePrev();
  }

  onActiveIndexChange(event: any) {
    if (!this.functionService.isUndefined(event?.detail?.[0]?.activeIndex)) {
      const index = event.detail[0].activeIndex;
      this.ngZone.run(() => {
        if (index === 0) {
          this.listType = 'category';
        } else if (index === 1) {
          this.listType = 'dietaryReq';
        } else if (index === 2) {
          this.listType = 'specialReq';
        } else if (index === 3) {
          this.listType = 'session';
        }
      });
      this.scrollToTop();
    }
  }

  /**
   * Scroll to top
   * @param time scroll animation duration
   */
  scrollToTop(time?: number) {
    this.content?.scrollToTop(time ? time : 0);
  }

  /**
   * Dismiss modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  trackByFn(index: number, item: SettingField) {
    if (item?.id) {
      return item.id;
    } else if (item?.value) {
      return item.value;
    }
    return index;
  }

}
