import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { WebsiteItinerary } from 'src/app/interfaces/website';
import { FunctionService } from '../general/function.service';
import { SettingField } from 'src/app/interfaces/database';
import { StdEventWebsiteItineraryList, StdWebsiteItineraryList } from 'src/app/commons/website';

@Injectable({
  providedIn: 'root'
})
export class WebsiteItineraryService  implements OnInit, OnDestroy {

  accountId: string;

  websiteItinerary: WebsiteItinerary;

  observable: any;

  constructor(
    private afs: AngularFirestore,
    private translate: TranslateService,
    private functionService: FunctionService,
  ) {
    this.observable = new BehaviorSubject<WebsiteItinerary>(this.websiteItinerary);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  setup(websiteItinerary: WebsiteItinerary) {
    if (!this.functionService.isEqual(this.websiteItinerary, websiteItinerary)) {
      this.websiteItinerary = websiteItinerary;
      this.observable.next(this.websiteItinerary);
    }
  }

  getWebsiteItinerary(): WebsiteItinerary {
    if (this.websiteItinerary) {
      return { ...this.websiteItinerary };
    } else {
      return {
        title: this.getTitle(),
        msg: this.getMsg(),
        btn: this.getButton(),
        remark: '',
        enable: false,
        itineraryList: {},
        media: {
          url: '',
          type: '',
          mimeType: '',
        },
      };
    }
  }

  getStdItineraryList(eventMode?: boolean): SettingField[] {
    const stdTimeSegmentList: SettingField[] = [];
    if (eventMode) {
      StdEventWebsiteItineraryList?.forEach((value: string) => {
        const settingField: SettingField = {
          id: value,
          value,
          custom: false,
        }
        stdTimeSegmentList.push(settingField);
      });
    } else {
      StdWebsiteItineraryList?.forEach((value: string) => {
        const settingField: SettingField = {
          id: value,
          value,
          custom: false,
        }
        stdTimeSegmentList.push(settingField);
      });
    }
    
    return { ...stdTimeSegmentList };
  }

  getTitle(text?: boolean) {
    const title = this.translate.instant('WEBSITE.itinerary.title');
    if (text) {
      return title;
    }
    return '<p class="ql-align-center"><span class="ql-size-large">' + title + '</span></p>';
  }

  getButton() {
    return this.translate.instant('WEBSITE.itinerary.btn');
  }

  getMsg() {
    return this.translate.instant('WEBSITE.itinerary.msg');
  }

  async save(itinerary: WebsiteItinerary) {
    if (this.accountId && !this.functionService.isEqual(itinerary, this.websiteItinerary)) {
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ itinerary }, { merge: true });
    }
  }

}
