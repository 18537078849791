import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { AccountsListService } from 'src/app/services/accounts/accounts-list.service';
import { AccountsPasswordLoginComponent } from '../accounts-password-login/accounts-password-login.component';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-accounts-add-component',
  templateUrl: './accounts-add.component.html',
  styleUrls: ['./accounts-add.component.scss'],
})
export class AccountsAddComponent  implements OnInit {

  isTrialAvailable: boolean;
  isHyrbid: boolean;

  constructor(
    private router: Router,
    private platform: Platform,
    private modalController: ModalController,
    private accountsListService: AccountsListService,
  ) { }

  ngOnInit() {}

  ionViewWillEnter() {
    this.isTrialAvailable = this.checkIsTrialAvailable();
    this.isHyrbid = this.platform.is('hybrid');
  }

  checkIsTrialAvailable() {
    if (this.accountsListService.isNewTrialAvailable()) {
      return true;
    } else {
      return false;
    }
  }

  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  /**
   * Present account password login
   */
  async presentAccountsPasswordLoginModal() {
    await this.dismissModal();
    const modal = await this.modalController.create({
      component: AccountsPasswordLoginComponent,
      componentProps: {
      }
    });
    modal.present();
  }

  /**
   * Go account scan login page
   */
  async goScan() {
    await this.router.navigate(['/accounts/scan']);
    await this.dismissModal();
  }

  async goTrialPage() {
    const navigationExtras: NavigationExtras = {
      state: { backAddPage: true }
    };
    await this.router.navigate(['subscription/trial'], navigationExtras);
    await this.dismissModal();
  }

  async goPremiumPage() {
    const navigationExtras: NavigationExtras = {
      state: { backPremiumPage: true, backAddPage: true }
    };
    await this.router.navigate([ '/subscription/premium' ], navigationExtras);
    await this.dismissModal();
  }

}
