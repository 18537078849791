<ng-container *ngIf="pageMode">
  <div #content>
    <ng-container *ngIf="true then tips"></ng-container>
  </div>
</ng-container>

<ng-container *ngIf="!pageMode">
  <ion-header>
    <ion-toolbar color="light">
      <ion-buttons slot="start">
        <ion-button (click)="dismissModal()">
          <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>
        <app-offline-indicator></app-offline-indicator>
        {{ 'BLAST.tips.title' | translate }}
      </ion-title>
    </ion-toolbar>
    <ion-toolbar class="segment-toolbar">
      <ion-segment class="full-width" scrollable [(ngModel)]="blastType">
        <ion-segment-button [value]="'sms'">
          <ion-label>{{ 'BLAST.lbl.sms' | translate }}</ion-label>
        </ion-segment-button>
        <ion-segment-button [value]="'whatsapp'">
          <ion-label>{{ 'BLAST.lbl.whatsapp' | translate }}</ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding" color="light" #content>
    <ng-container *ngIf="true then tips"></ng-container>
  </ion-content>
</ng-container>

<ng-template #tips>
  <ng-container *ngIf="blastType === 'sms'">
    <ion-item class="tips-card" color="secondary" lines="none">
      <ion-icon class="font-24 vertical-middle" slot="start" src="./assets/wedding/icon/general/warning.svg"></ion-icon>
      <ion-text class="font-12 line-100">
        {{ 'BLAST.msg.sms_not_support' | translate }}
      </ion-text>
    </ion-item>
    <ion-card class="tips-card">
      <ion-card-header>
        <ion-text color="black">{{ 'BLAST.tips.us_restriction.title' | translate }}</ion-text>
      </ion-card-header>
      <ion-card-content class="line-100">
        <ion-text class="font-10 line-100" color="black">{{ 'BLAST.tips.us_restriction.msg' | translate }}</ion-text>
      </ion-card-content>
    </ion-card>
  
    <ion-card class="tips-card">
      <ion-card-header>
        <ion-text color="black">{{ 'BLAST.tips.sg_restriction.title' | translate }}</ion-text>
      </ion-card-header>
      <ion-card-content class="line-100">
        <ion-text class="font-10 line-100" color="black">{{ 'BLAST.tips.sg_restriction.msg' | translate }}</ion-text>
      </ion-card-content>
    </ion-card>
  
    <ion-card class="tips-card">
      <ion-card-header>
        <ion-text color="black">{{ 'BLAST.tips.my_restriction.title' | translate }}</ion-text>
      </ion-card-header>
      <ion-card-content class="line-100">
        <ion-text class="font-10 line-100" color="black">{{ 'BLAST.tips.my_restriction.msg' | translate }}</ion-text>
      </ion-card-content>
    </ion-card>

    <ion-card class="tips-card">
      <ion-card-header>
        <ion-text color="black">{{ 'BLAST.tips.daytime.title' | translate }}</ion-text>
      </ion-card-header>
      <ion-card-content class="line-100">
        <ion-text class="font-10 line-100" color="black">{{ 'BLAST.tips.daytime.msg' | translate }}</ion-text>
      </ion-card-content>
    </ion-card>
  
    <ion-card class="tips-card">
      <ion-card-header>
        <ion-text color="black">{{ 'BLAST.tips.max_char.title' | translate }}</ion-text>
      </ion-card-header>
      <ion-card-content class="line-100">
        <ion-text class="font-10 line-100" color="black">{{ 'BLAST.tips.max_char.msg' | translate }}</ion-text>
      </ion-card-content>
    </ion-card>
  
    <ion-card class="tips-card">
      <ion-card-header>
        <ion-text color="black">{{ 'BLAST.tips.unicode.title' | translate }}</ion-text>
      </ion-card-header>
      <ion-card-content class="line-100">
        <ion-text class="font-10 line-100" color="black">{{ 'BLAST.tips.unicode.msg' | translate }}</ion-text>
      </ion-card-content>
    </ion-card>
  
    <ion-card class="tips-card">
      <ion-card-header>
        <ion-text color="black">{{ 'BLAST.tips.escape_char.title' | translate }}</ion-text>
      </ion-card-header>
      <ion-card-content class="line-100">
        <ion-text class="font-10 line-100" color="black">{{ 'BLAST.tips.escape_char.msg' | translate }}</ion-text>
      </ion-card-content>
    </ion-card>
  
    <ion-card class="tips-card">
      <ion-card-header>
        <ion-text color="black">{{ 'BLAST.tips.group.title' | translate }}</ion-text>
      </ion-card-header>
      <ion-card-content class="line-100">
        <ion-text class="font-10 line-100" color="black">{{ 'BLAST.tips.group.msg' | translate }}</ion-text>
      </ion-card-content>
    </ion-card>
  
    <ion-card class="tips-card">
      <ion-card-header>
        <ion-text color="black">{{ 'BLAST.tips.country.title' | translate }}</ion-text>
      </ion-card-header>
      <ion-card-content class="line-100">
        <ion-text class="font-10 line-100" color="black">{{ 'BLAST.tips.country.msg' | translate }}</ion-text>
      </ion-card-content>
    </ion-card>
  
    <ion-card class="tips-card">
      <ion-card-header>
        <ion-text color="black">{{ 'BLAST.tips.my.title' | translate }}</ion-text>
      </ion-card-header>
      <ion-card-content class="line-100">
        <ion-text class="font-10 line-100" color="black">{{ 'BLAST.tips.my.msg' | translate }}</ion-text>
      </ion-card-content>
    </ion-card>
  </ng-container>
  <ng-container *ngIf="blastType === 'whatsapp'">
    <ion-card class="tips-card">
      <ion-card-header>
        <ion-text color="black">{{ 'BLAST.tips.whatsapp_template.title' | translate }}</ion-text>
      </ion-card-header>
      <ion-card-content class="line-100">
        <ion-text class="font-10 line-100" color="black">{{ 'BLAST.tips.whatsapp_template.msg' | translate }}</ion-text>
      </ion-card-content>
    </ion-card>

    <ion-card class="tips-card">
      <ion-card-header>
        <ion-text color="black">{{ 'BLAST.tips.whatsapp_max_content.title' | translate }}</ion-text>
      </ion-card-header>
      <ion-card-content class="line-100">
        <ion-text class="font-10 line-100" color="black">{{ 'BLAST.tips.whatsapp_max_content.msg' | translate }}</ion-text>
      </ion-card-content>
    </ion-card>
  </ng-container>
  
</ng-template>