import { StripeCurrencyList } from 'src/app/commons/stripe';
import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { FunctionService } from 'src/app/services/general/function.service';
import { LocalityService } from 'src/app/services/location/locality.service';
import { StorageService } from 'src/app/services/general/storage.service';

import { Country, Currency } from 'src/app/interfaces/database';
import { CurrencyList, AccountProductCurrencyList, StdCurrencyList } from 'src/app/commons/currency';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService implements OnInit, OnDestroy {

  /**
   * Currency
   */
  currency: Currency;
  /**
   * Currency list
   */
  currencyList = CurrencyList;
  /**
   * Product currency list
   */
  productCurrencyList = AccountProductCurrencyList;

  stdCurrencyList = StdCurrencyList;

  stripeCurrencyList = StripeCurrencyList;
  /**
   * Default currency
   */
  defaultCurrency: Currency = {
    symbol: '$',
    name: 'US Dollar',
    symbolNative: '$',
    decimalDigits: 2,
    rounding: 0,
    code: 'USD',
    namePlural: 'US dollars',
    label: 'USD - US Dollar'
  };

  /**
   * Constructor
   * @param localityService locality service
   * @param storageService storage service
   * @param functionService function  service
   */
  constructor(
    private localityService: LocalityService,
    private functionService: FunctionService,
  ) { }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Get currency
   */
  // async getCurrency() {
  //   this.currency = await this.storageService.getObject('currency');
  // }
  /**
   * Set currency
   * @param currency currency
   */
  async setCurrency(currency: Currency) {
    this.currency = currency;
  }

  getCurrencyList() {
    let currencyList = this.functionService.cloneDeep(this.currencyList);
    const pereferCurrencyList = this.getPreferedCurrencyList();
    pereferCurrencyList?.forEach((x: Currency) => {
      if (x?.code && x?.name) {
        const index = currencyList?.findIndex((y: Country) => {
          return x.code === y.code;
        });
        if (index !== -1) {
          currencyList.splice(index, 1);
        }
        currencyList.unshift(x);
      }
    });
    return currencyList;
  }

  getPreferedCurrencyList(): Currency[] {
    const deviceCountry = this.localityService.getDeviceCountry();
    const userCountry = this.localityService.getUserCountry();
    const accountCountry = this.localityService.getAccountCountry();

    const currencyList: Currency[] = [];
    
    if (deviceCountry?.currency) {
      const list = deviceCountry.currency.split(',');
      list?.forEach((code: string) => {
        const currency = this.getCurrencyByCode(code);
        const index = currencyList.findIndex((x: Currency) => {
          x.code === code;
        });
        if (index === -1) {
          currencyList.push(currency);
        }
      })
    }

    if (userCountry?.code && userCountry.code !== deviceCountry?.code) {
      if (userCountry?.currency) {
        const list = userCountry.currency.split(',');
        list?.forEach((code: string) => {
          const currency = this.getCurrencyByCode(code);
          const index = currencyList.findIndex((x: Currency) => {
            x.code === code;
          });
          if (index === -1) {
            currencyList.push(currency);
          }
        })
      }
    } 
    
    if (accountCountry?.code && accountCountry.code !== userCountry?.code && accountCountry.code !== deviceCountry?.code) {
      if (accountCountry?.currency) {
        const list = accountCountry.currency.split(',');
        list?.forEach((code: string) => {
          const currency = this.getCurrencyByCode(code);
          const index = currencyList.findIndex((x: Currency) => {
            x.code === code;
          });
          if (index === -1) {
            currencyList.push(currency);
          }
        })
      }
    }
    
    return currencyList;
  }

  // let countryList = this.functionService.cloneDeep(this.countryList);
  //   const pereferCountryList = this.getPreferCountry();
  //   pereferCountryList.forEach((x: Country) => {
  //     if (x?.id && x?.code && x?.name) {
  //       const index = countryList?.findIndex((y: Country) => {
  //         return x.code === y.code;
  //       });
  //       if (index !== -1) {
  //         countryList.splice(index, 1);
  //       }
  //       countryList.unshift(x);
  //     }
  //   })
  //   return countryList;
  /**
   * Round currency amount based on currency code
   * @param currencyCode currency code
   * @param amount amount
   * @returns rounded currency
   */
  rounding(currencyCode: string, amount: number): number {
    if (currencyCode) {
      const currency: Currency = this.getCurrencyByCode(currencyCode.toUpperCase());
      if (currency?.decimalDigits === 0) {
        amount = Math.round(amount);
      } else if (currency?.decimalDigits === 1) {
        amount = Math.round(amount * 10) / 10;
      } else if (currency?.decimalDigits === 2) {
        amount = Math.round(amount * 100) / 100;
      }
      if (currency.rounding) {
        amount = Math.round(amount / currency.rounding) * currency.rounding;
      }
      return Number(amount.toFixed(currency?.decimalDigits ? currency.decimalDigits : 0));
    }
    return 0;
  }

  /**
   * Format currency
   * @param currency currency code
   * @param amount amount
   * @returns formatted currency amount
   */
  formatCurrency(currency: string, amount: number): string {
    if (currency) {
      amount = this.rounding(currency, amount);
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
      });
      return formatter.format(amount);
    }
    return amount.toString();
  }

  /**
   * Get currency by code
   * @param currencyCode currency code
   * @returns currency obj
   */
  getCurrencyByCode(currencyCode: string): Currency {
    if (currencyCode) {
      
      if (currencyCode?.length > 3 && currencyCode.indexOf(',') !== -1) {
        let currency: Currency;
        const list = currencyCode.split(',');
        list?.forEach((code: string) => {
          if (!currency?.code) {
            const index = CurrencyList?.findIndex((x: Currency) => x?.code === code);
            if (index !== -1) {
              currency = CurrencyList[index];
            }
          }
        });
        return currency;
      } else {
        const index = CurrencyList?.findIndex((x: Currency) => x?.code === currencyCode);
        if (index !== -1) {
          return CurrencyList[index];
        }
      }
    }
    return null;
  }

  getCurrencyByCountry(countryCode: string): Currency {
    if (countryCode) {
      const country: Country = this.localityService.getCountryByCode(countryCode);
      if (country?.currency && this.getCurrencyByCode(country.currency)) {
        return this.getCurrencyByCode(country.currency);
      }
    }
    return null;
  }

  /**
   * Get device currency by IP address.
   * @returns currency obj
   */
  getDeviceCurrency(): Currency {
    const country: Country = this.localityService.getDeviceCountry();
    if (country?.currency && this.getCurrencyByCode(country.currency)) {
      return this.getCurrencyByCode(country.currency);
    }
    return this.defaultCurrency;
  }

  /**
   * Get user account currency, alternate to device currency if not present.
   * @returns currency obj
   */
  getUserCurrency(): Currency {
    const country: Country = this.localityService.getUserCountry();
    if (country?.currency && this.getCurrencyByCode(country.currency)) {
      return this.getCurrencyByCode(country.currency);
    }
    return this.getDeviceCurrency();
  }

  /**
   * Get wedding account currency, alternate to user currency if not present.
   * @returns currency obj
   */
  getAccountCurrency(): Currency {
    const country: Country = this.localityService.getAccountCountry();
    if (country?.currency && this.getCurrencyByCode(country.currency)) {
      return this.getCurrencyByCode(country.currency);
    }
    return this.getUserCurrency();
  }

  getStripeCurrency(): Currency {
    let currency: Currency = this.defaultCurrency;
    const accountCurrency = this.getAccountCurrency();
    const deviceCurrency = this.getDeviceCurrency();
    const userCurrency = this.getUserCurrency();

    if (accountCurrency?.code && this.checkStripeCurrency(accountCurrency.code)) {
      currency = accountCurrency;
    } else if (deviceCurrency?.code && deviceCurrency?.code !== accountCurrency?.code && this.checkStripeCurrency(deviceCurrency.code)) {
      currency = deviceCurrency;
    } else if (userCurrency?.code && userCurrency?.code !== deviceCurrency?.code && this.checkStripeCurrency(userCurrency.code)) {
      currency = userCurrency;
    }
    return currency;
  }

  checkCurrency(currency: string) {
    if (this.checkStdCurrency(currency) || this.checkStripeCurrency(currency)) {
      return true;
    }
    return false;
  }

  checkStdCurrency(currency: string) {
    const index = this.stdCurrencyList.findIndex((x: Currency) => {
      return x.code === currency;
    });
    return index !== -1;
  }

  checkStripeCurrency(currency: string) {
    return this.stripeCurrencyList?.indexOf(currency) !== -1;
  }

  /**
   * Get product currency
   * @returns Currency
   */
  getProductCurrency(): Currency {
    let currency: Currency = this.defaultCurrency;
    const userCurrency = this.getAccountCurrency();
    if (this.productCurrencyList?.findIndex((productCurrency: Currency) => productCurrency?.code === userCurrency?.code) !== -1) {
      currency = userCurrency;
    } else if (this.stripeCurrencyList?.indexOf(userCurrency?.code) !== -1) {
      currency = userCurrency;
    } else {
      const deviceCurrency = this.getDeviceCurrency();
      if (this.functionService.isEqual(userCurrency, deviceCurrency) &&
      this.productCurrencyList?.findIndex((productCurrency: Currency) => productCurrency?.code === deviceCurrency?.code) !== -1) {
        currency = deviceCurrency;
      } else if (this.stripeCurrencyList?.indexOf(deviceCurrency?.code) !== -1) {
        currency = deviceCurrency;
      }
    }
    return currency;
  }

}
;