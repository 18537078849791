
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { OfflineComponentsModule } from 'src/app/components/general/offline/offline.components.module';
import { LoginComponent } from 'src/app/components/login/login/login.component';
import { LoginPhoneComponent } from './login-phone/login-phone.component';
import { GeneralComponentsModule } from '../general/general.components.module';
import { CountdownModule } from 'ngx-countdown';
// import { SwiperModule } from 'swiper/angular';

@NgModule({
    declarations: [
        LoginComponent,
        LoginPhoneComponent,
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        // SwiperModule,
        OfflineComponentsModule,
        TranslateModule,
        GeneralComponentsModule,
        CountdownModule,
    ],
    exports: [
        LoginComponent,
        LoginPhoneComponent,
    ]
})
export class LoginComponentsModule { }
