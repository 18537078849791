/**
 * Visitor guest test data
 */
export const VisitorGuestTestData: any = {
    guestId: 'test',
    pax: 1,
    seating: '1'
};

// export const VisitorVariable: string[] = [
//     'guest_name',
//     'guest_alternate_name',
//     'guest_seating',
//     'number_of_guest',
//     'group_name',
//     'guest_session',
//     'wedding_title',
//     'wedding_date',
//     'wedding_start_time',
//     'wedding_timezone',
// ];

