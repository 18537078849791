<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'GIFT.title.group' | translate }}
    </ion-title>
  </ion-toolbar>
  <ion-toolbar class="ion-no-padding" color="light" *ngIf="group?.memberList?.length">
    <div class="container-div">
      <app-guest-card [disableGroupMemberList]="true" [mode]="mode" [setGuest]="{ group: group }" [index]="0" [groupModal]="true"></app-guest-card>
    </div>
  </ion-toolbar>
  <ion-toolbar class="toolbar-select-all" color="light"  *ngIf="group?.giftList?.length && selectedGiftList">
    <app-select-all [total]="group.giftList.length" [selected]="selectedGiftList.length" [selectAll]="selectAll" [editMode]="editMode" (toggleSelect)="toggleSelectAll()"></app-select-all>
  </ion-toolbar>
</ion-header>
<ion-content color="light" #content>
  <div class="container-div">
    <div class="lbl-div font-12 font-italic" color="medium">{{ 'GIFT.lbl.gift_records' | translate }}:</div>
    <div class="guest-list" *ngIf="group?.giftList?.length else notFound">
      <div class="full-width viewport-div" [style.height.px]="viewportHeight">
        <cdk-virtual-scroll-viewport [itemSize]="87">
          <ion-list class="full-width-height background-transparent ion-padding-bottom" lines="none" #list>
            <app-gift-item *cdkVirtualFor="let giftId of group.giftList; let i = index;" [giftModal]="giftModal" [setGiftId]="giftId" [index]="i" [editMode]="editMode" [selectedGiftList]="selectedGiftList" (setSelectedGift)="setSelectedGift($event)"></app-gift-item>
          </ion-list>
        </cdk-virtual-scroll-viewport>
      </div>
      <ion-card class="ion-no-margin ion-padding-vertical" (click)="presentGroupLogModal()" *ngIf="group?.groupId" button>
        <ion-item lines="none" detail>
          <ion-icon class="icon-small" slot="start" src="./assets/wedding/icon/guest/log.svg" style="vertical-align: middle;"></ion-icon>
          {{ 'GROUP.lbl.log' | translate}}
        </ion-item>
      </ion-card>
    </div>
  </div>
  <ng-template #notFound>
    <div class="full-width-height ion-padding vertical-center">
      <ion-card class="background-transparent card-margin">
        <ion-item class="background-transparent ion-item-no-padding ion-text-center ion-padding" lines="none">
          <ion-label class="ion-no-margin" color="medium">
            <ion-icon class="center icon-cactus" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
            <div class="ion-margin-top font-14">{{ 'LBL.no_found' | translate }}</div>
          </ion-label>
        </ion-item>
      </ion-card>
    </div>
  </ng-template>
  <ion-fab horizontal="end" vertical="bottom" slot="fixed" *ngIf="module !== 'account' && group?.giftList?.length">
    <ion-fab-button color="primary" (click)="toggleEditMode()">
      <ion-icon src="./assets/wedding/icon/general/chevron-up.svg" *ngIf="!editMode"></ion-icon>
      <ion-icon src="./assets/wedding/icon/general/close.svg" *ngIf="editMode"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
<ion-footer class="ion-no-border" #footer>
  <ng-container *ngIf="editMode">
    <app-bulk-action [mode]="'group'" [selectedList]="selectedGiftList" [count]="group?.memberList?.length" (action)="bulkAction($event)" (toggleEditMode)="toggleEditMode($event)"></app-bulk-action>
  </ng-container>
  <ion-toolbar *ngIf="!editMode">
    <ion-button class="ion-margin" expand="block" (click)="presentGiftManageModal(true)">{{ 'CRUD.add_field' | translate: { field: 'GIFT.lbl.gift' | translate } }}</ion-button>
  </ion-toolbar>
</ion-footer>