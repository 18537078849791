import { ReadContactsComponent } from './read-contacts/read-contacts.component';
import { MobileComponent } from './mobile/mobile.component';
import { DatetimeComponent } from './datetime/datetime.component';
import { LoadingComponent } from './loading/loading.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { ScrollingModule } from '@angular/cdk/scrolling';
// import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';

import { OfflineComponentsModule } from 'src/app/components/general/offline/offline.components.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SearchComponentsModule } from './search/search.components.module';
import { SearchSelectListComponent } from './search-select-list/search-select-list.component';
import { QuillModule } from 'ngx-quill';
import { HtmlEditorComponent } from './html-editor/html-editor.component';
import { DateTimeFormatComponent } from './date-time-format/date-time-format.component';
import { CountDownComponent } from './count-down/count-down.component';
import { YoutubeComponent } from './youtube/youtube.component';
import { TextFitComponent } from './fit-text/fit-text.component';

@NgModule({
    declarations: [
        LoadingComponent,
        DatetimeComponent,
        DateTimeFormatComponent,
        MobileComponent,
        ReadContactsComponent,
        SearchSelectListComponent,
        HtmlEditorComponent,
        CountDownComponent,
        YoutubeComponent,
        TextFitComponent,
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        NgxIntlTelInputModule,
        OfflineComponentsModule,
        TranslateModule,
        SearchComponentsModule,
        ScrollingModule,
        QuillModule,
        // ExperimentalScrollingModule,
    ],
    exports: [
        LoadingComponent,
        DatetimeComponent,
        DateTimeFormatComponent,
        MobileComponent,
        ReadContactsComponent,
        SearchSelectListComponent,
        HtmlEditorComponent,
        CountDownComponent,
        YoutubeComponent,
        TextFitComponent,
    ]
})
export class GeneralComponentsModule { }
