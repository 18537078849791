import { LocationDetailService } from './location-detail.service';
import { Coordinate } from 'src/app/interfaces/general';
import { OnlineService } from 'src/app/services/general/online.service';

import { Locality } from 'src/app/interfaces/general';

import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ErrorService } from 'src/app/services/general/error.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';
import { User } from 'src/app/interfaces/user';
import { Platform } from '@ionic/angular';
import { StorageService } from 'src/app/services/general/storage.service';
import { NativeGeocoder, NativeGeocoderOptions, NativeGeocoderResult } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { UrlService } from '../general/url.service';
import { FunctionService } from '../general/function.service';
import { CapacitorHttp, HttpOptions, HttpResponse } from '@capacitor/core';

const STORAGE_KEY = 'locality';

@Injectable({
  providedIn: 'root'
})
export class IpLocationService implements OnInit, OnDestroy {

  locality: Locality;

  finished: boolean;

  private checking: boolean;

  private user: User;

  private userSubscription: Subscription;

  observableLocality: any;

  constructor(
    private platform: Platform,
    private nativeGeocoder: NativeGeocoder,
    private locationDetailService: LocationDetailService,
    private afs: AngularFirestore,
    private userService: UserService,
    private onlineService: OnlineService,
    private storageService: StorageService,
    private urlService: UrlService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
    this.observableLocality = new BehaviorSubject<Locality>(this.locality);
    this.initial();
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy() {
    this.unwatchUser();
  }

  async initial() {
    await this.platform.ready();
    // await this.functionService.delay(1000);
    if (!this.urlService.checkVipHostName(true)) {
      if (!this.locality) {
        await this.read();
        await this.ipGeocode();
      }
      await this.watchUser();
    }
  }

  async watchUser() {
    if (!this.userSubscription) {
      this.userSubscription = this.userService.observableUser.subscribe((user: User) => {
        this.user = user;
        this.checkUser();
      });
    }
  }

  async unwatchUser() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
      this.userSubscription = null;
    }
  }

  async read() {
    await this.platform.ready();
    const locality = await this.storageService.getObject(STORAGE_KEY);
    if (locality) {
      this.locality = locality;
      this.observableLocality.next(this.locality);
    }
    return locality;
  }

  async save(locality: Locality) {
    this.locality = locality;
    this.observableLocality.next(this.locality);
    this.checking = false;
    this.finished = true;
    await this.storageService.setObject(STORAGE_KEY, locality);
    this.checkUser();
  }

  /**
   * IP Geocode
   */
   async ipGeocode() {
    await this.platform.ready();
    if (this.onlineService.online) {
      if (!this.locality && !this.checking) {
        this.checking = true;
        const options: HttpOptions = {
          url: 'https://ipapi.co/json/',
        };
        try {
          const response: HttpResponse = await CapacitorHttp.get(options);
          if (response?.status === 200 && response?.data?.country) {
            const locality: Locality = {
              country: response?.data?.country ? response.data.country : '',
              state: response?.data?.region ? response.data.region : '',
              city: response?.data?.city ? response.data.city : '',
              postalCode: response?.data?.postal ? response.data.postal : ''
            };
            this.save(locality);
          } else {
            this.errorService.logError(response);
            this.googleGeocode();
          }
        } catch (err: any) {
          this.errorService.logError(err);
          this.googleGeocode();
        }
      }
    } else {
      await this.functionService.delay(1000);
      await this.ipGeocode();
    }
  }

  async googleGeocode() {
    await this.platform.ready();
    if (this.onlineService.online) {
      const options: HttpOptions = {
        url: 'https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyDeeOVRHeYboeZrVg9Y0JHQeCmVY4_D7wg',
      };
      try {
        const response: HttpResponse = await CapacitorHttp.post(options);
        if (response?.status === 200 && response?.data?.location) {
          const coord: Coordinate = {
            lat: response.data.location?.lat,
            lng: response.data.location?.lng,
          };
          this.getGeocoder(coord);
        } else {
          this.checking = false;
          this.finished = true;
        }
      } catch (err: any) {
        this.errorService.logError(err);
        this.checking = false;
        this.finished = true;
      }
    } else {
      await this.functionService.delay(1000);
      await this.googleGeocode();
    }
  }

  async getGeocoder(coord: Coordinate) {
    if (coord) {
      if (this.platform.is('hybrid')) {
        if (coord) {
          const options: NativeGeocoderOptions = {
            useLocale: false,
            maxResults: 1
          };
          const result = await this.nativeGeocoder.reverseGeocode(
            coord.lat,
            coord.lng,
            options
          );
          if (result?.length) {
            const geocoderResult: NativeGeocoderResult = result[0];
            this.setupLocationDetail(coord, geocoderResult);
          } else {
            this.setupLocationDetail(coord);
          }
        }
      } else {
        const latlng = coord.lat + ',' + coord.lng;
        const options: HttpOptions = {
          url: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + encodeURI(latlng) + '&language=en&key=AIzaSyDeeOVRHeYboeZrVg9Y0JHQeCmVY4_D7wg',
        };
        try {
          const response: HttpResponse = await CapacitorHttp.get(options)
          if (response?.status === 200 && response?.data?.results?.length) {
            const googleMapResult: google.maps.GeocoderResult = response.data.results[0];
            this.setupLocationDetail(coord, null, googleMapResult);
          } else {
            this.setupLocationDetail(coord);
          }
        } catch (err: any) {
          this.errorService.logError(err);
          this.setupLocationDetail(coord);
        }
      }
    }
  }

  setupLocationDetail(coords?: Coordinate, geocoderResult?: NativeGeocoderResult, googleMapResult?: google.maps.GeocoderResult) {
    const locationDetail = this.locationDetailService.setupLocationDetail(coords, geocoderResult, googleMapResult);
    if (locationDetail?.locality) {
      this.save(locationDetail.locality);
    }
  }

  checkUser() {
    if (this.user?.uid && !this.user.locality?.country) {
      if (this.locality?.country) {
        const locality = this.locality;
        const userRef = this.afs.firestore.doc(`users/${ this.user.uid }/`);
        userRef.set({ locality }, { merge: true }).then(result => {
        }).catch((err: any) => {
          this.errorService.logError(err);
        });
      } else {
        setTimeout(() => {
          this.checkUser();
        }, 3000);
      }
    }
  }
}
