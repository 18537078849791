import { AupComponent } from './../../about/aup/aup.component';
import { PrivacyComponent } from 'src/app/components/about/privacy/privacy.component';
import { AccountService } from 'src/app/services/account/account.service';
import { LinkService } from 'src/app/services/general/link.service';
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

import { FunctionService } from 'src/app/services/general/function.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { OnlineService } from 'src/app/services/general/online.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { LoginService } from 'src/app/services/general/login.service';

import { LoginPhoneComponent } from 'src/app/components/login/login-phone/login-phone.component';
import { Router } from '@angular/router';
import { TermComponent } from 'src/app/components/about/term/term.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

  /**
   * Content
   */
  @ViewChild('content', { read: ElementRef }) content: ElementRef;

  @Input() pageMode: boolean;
  /**
   * Link provider
   */
  @Input() linkProvider: boolean;
  /**
   * Login required
   */
  @Input() loginRequired: boolean;

  /**
   * Set ready state
   */
  @Input() set ready(flag: boolean) {
    if (flag) {
      this.initialize();
    } else {
      // this.unwatchUrl();
    }
  }

  /**
   * Slider Configuration
   */
  sliderConfig: any;

  slidesPerView: number;
  /**
   * Show apple login flag, hide apple login for hybrid android App
   */
  showAppleLogin: boolean;

  /**
   * Watch screen resize change for login slider setup
   */
  @HostListener('window:resize', ['$event'])onResize(event) {
    this.setupSliderConfig(event?.target?.innerWidth);
  }
  /**
   * Watch screen orientation change for login slider setup
   */
  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event) {
    this.setupSliderConfig(event?.target?.innerWidth);
  }

  /**
   * Constructor
   * @param ngZone Ng Zone
   * @param platform Platform
   * @param modalController Modal Controller
   * @param route Route
   * @param router Router
   * @param authService Authentication Service
   * @param onlineService Online Service
   */
  constructor(
    private platform: Platform,
    private router: Router,
    private modalController: ModalController,
    private authService: AuthService,
    private accountService: AccountService,
    private onlineService: OnlineService,
    private loginService: LoginService,
    private linkService: LinkService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {

  }

  /**
   * Before view enter
   */
  ionViewWillEnter() {
    this.initialize();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
  }

  /**
   * Initialize
   */
  async initialize() {
    await this.platform.ready();
    if (this.platform.is('hybrid') && this.platform.is('android')) {
      this.showAppleLogin = false;
    } else {
      this.showAppleLogin = true;
    }
    // this.setupSliderConfig();
  }

  /**
   * Setup ionic slider config
   */
  async setupSliderConfig(cardWidth?: number) {
    await this.platform.ready();
    await this.functionService.delay(200);
    
    const itemWidth = 70;
    let maxSlide = 5;

    // this.disablePrevBtn = true;
    // this.disableNextBtn = true;
    let slidesPerView = 4;
    if (!cardWidth) {
      cardWidth = this.content?.nativeElement?.offsetWidth ? this.content.nativeElement.offsetWidth : window?.innerWidth;
    }

    if (!this.pageMode) {
      cardWidth = cardWidth * 0.8;
      if (cardWidth > 600) {
        cardWidth = 600;
      }
    }

    // if (!this.showAnonymousLogin) {
    //   maxSlide = maxSlide - 1;
    // }
    if (!this.showAppleLogin) {
      maxSlide = maxSlide - 1;
    }

    slidesPerView = Math.floor((cardWidth - 55) / itemWidth);
    if (!slidesPerView || slidesPerView < 1) {
      slidesPerView = 1;
    }
    if (slidesPerView >= maxSlide) {
      slidesPerView = maxSlide;
    } else {
      slidesPerView = slidesPerView + 0.5;
    }

    // if (maxSlide > slidesPerView) {
    //   this.disableNextBtn = false;
    // }

    const sliderConfig = {
      slidesPerView,
      // slidesPerGroup: slidesPerView
    };

    if (!this.sliderConfig) {
      this.sliderConfig = sliderConfig;
    } else if (!this.functionService.isEqual(sliderConfig, this.sliderConfig)) {
      this.sliderConfig = null;
      setTimeout(() => { this.sliderConfig = sliderConfig; }, 20);
    }
  }

  /**
   * Ion Slide Change
   */
  // ionSlideChange() {
  //   const prom1 = this.ionSlides.isBeginning();
  //   const prom2 = this.ionSlides.isEnd();

  //   Promise.all([prom1, prom2]).then((data) => {
  //     data[0] ? this.disablePrevBtn = true : this.disablePrevBtn = false;
  //     data[1] ? this.disableNextBtn = true : this.disableNextBtn = false;
  //   });
  // }

  /**
   * Previous ion slider
   */
  // slidePrev() {
  //   this.ionSlides.slidePrev();
  // }

  // /**
  //  * Next ion slider
  //  */
  // swipeNext() {
  //   this.ionSlides.slideNext();
  // }

  /**
   * Login action
   * @param type login type
   */
  async login(type: string) {
    if (this.onlineService.isOnline()) {
      this.authService.setAuthType(type);
      if (type) {
        if (!this.pageMode) {
          // await this.popupService.presentLoading();
          this.dismissModal();
        }
        switch (type) {
          case 'google.com':
            await this.authService.googleSignIn();
            break;
          case 'facebook.com':
            await this.authService.facebookSignIn();
            break;
          case 'apple.com':
            await this.authService.appleSignIn();
            break;
          case 'twitter.com':
            await this.authService.twitterSignIn();
            break;
          case 'microsoft.com':
            await this.authService.microsoftSignIn();
            break;
          case 'yahoo.com':
            await this.authService.yahooSignIn();
            break;
          case 'mobile':
            await this.openPhoneSignupModal();
            break;
          // case 'anonymous':
          //   await this.openAnonymousModal();
          //   break;
          default:
            break;
        }
      }
    }
  }

  /**
   * Dismiss modal
   */
  async dismissModal(back?: boolean) {
    if (this.loginService.loginModal) {
      await this.loginService.dismissLoginModal();
    } else if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) {
        modal.dismiss({ back });
      }
    }
    if (back) {
      if (await this.accountService?.readAccountId()) {
        await this.accountService.logoutAccount();
      }
      this.linkService.goMainPage();
    }
  }

  /**
   * Open anonymous modal
   */
  // async openAnonymousModal() {
  //   this.authService.updateLoginReady(false);
  //   if (!this.pageMode) {
  //     await this.popupService.dismissLoading();
  //   }
  //   const modal = await this.modalController.create({
  //     component: LoginAnonymousComponent,
  //     cssClass: 'modal-transparent',
  //     componentProps: { }
  //   });
  //   modal.present();
  //   modal.onWillDismiss().then(async (result) => {
  //     if (result?.data?.anonymous) {
  //       await this.anonymousSignIn();
  //       this.dismissModal();
  //     } else {
  //       this.authService.updateLoginReady(true);
  //     }
  //   });
  // }

  /**
   * Anonymous sign in
   */
  // async anonymousSignIn() {
  //   await this.authService.anonymousSignIn();
  // }

  /**
   * Open mobile authentication modal
   */
  async openPhoneSignupModal() {
    this.authService.updateLoginReady(false);
    if (!this.pageMode) {
      await this.popupService.dismissLoading();
    }
    const modal = await this.modalController.create({
      component: LoginPhoneComponent,
      cssClass: 'modal-transparent',
      componentProps: { }
    });
    modal.present();
    modal.onWillDismiss().then(async (result) => {
      if (result?.data?.login) {
        this.authService.updateLoginReady(false);
      } else {
        this.authService.updateLoginReady(true);
      }
      this.popupService.dismissLoading();
    });
  }

  async presentPrivacyModal() {
    const modal = await this.modalController.create({
      component: PrivacyComponent,
      componentProps: { }
    });
    modal.present();
  }

  async presentAupModal() {
    const modal = await this.modalController.create({
      component: AupComponent,
      componentProps: { }
    });
    modal.present();
  }

  async presentTermModal() {
    const modal = await this.modalController.create({
      component: TermComponent,
      componentProps: { }
    });
    modal.present();
  }

  goHelpPage() {
    this.router.navigate(['/about/help']);
  }

}
