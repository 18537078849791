import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

/**
 * Tab service to control visibility of tab bar.
 */
@Injectable({
  providedIn: 'root'
})
export class TabService implements OnInit, OnDestroy {

  /**
   * Show tab flag, indicate show / hide tab menu bar.
   */
  showTab: boolean;
  /**
   * Observable show tab flag
   */
  observableShowTab: any;

  /**
   * Constructor.
   * Default show tab set to true.
   */
  constructor() {
    this.showTab = true;
    this.observableShowTab = new BehaviorSubject<boolean>(this.showTab);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Update show tab status based on param.
   * @param showTab Show tab flag
   */
  updateShowTab(showTab: boolean) {
    if (this.showTab !== showTab) {
      this.showTab = showTab;
      this.observableShowTab.next(showTab);
      // setTimeout(() => {
      //   this.showTab = showTab;
      // }, 20);
    }
  }
}
