import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Filesystem, Directory, ReadFileResult, WriteFileResult } from '@capacitor/filesystem';

import { ErrorService } from 'src/app/services/general/error.service';
import { Platform } from '@ionic/angular';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { CapacitorHttp, HttpOptions, HttpResponse } from '@capacitor/core';

const CACHE_FOLDER = 'CACHED-IMG';
const DEFAULT_TYPE = 'png';

@Component({
  selector: 'app-cached-image',
  templateUrl: './cached-image.component.html',
  styleUrls: ['./cached-image.component.scss'],
})
export class CachedImageComponent implements OnInit, OnDestroy {

  /**
   * Image src to be display
   */
  imgSrc: string;

  @Input() type: string;
  /**
   * Show spinner while image loading
   */
  @Input() spinner: boolean;
  /**
   * Set Image url, and initial read / download image
   */
  @Input() set src(imageUrl: string) {
    this.setSrc(imageUrl);
  }

  /**
   * Constructor
   * @param errorService error service
   */
  constructor(
    private platform: Platform,
    private afStorage: AngularFireStorage,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  async setSrc(imageUrl: string) {
    if (this.platform.is('hybrid')) {
      const imageName = this.generateNameFromUrl(imageUrl);
      let fileType = DEFAULT_TYPE;
      if (imageName.substr(imageName.length - 4) === '.jpg') {
        fileType = 'jpg';
      } else if (imageName.substr(imageName.length - 5) === '.jpeg') {
        fileType = 'jpeg';
      } else {
        fileType = await this.getFirebaseStorageType(imageUrl);
      }

      try {
        const contents: ReadFileResult = await Filesystem.readFile({
          directory: Directory.Data,
          path: `${CACHE_FOLDER}/${imageName}`
        });

        if (contents?.data) {
          this.imgSrc = `data:image/${ fileType };base64,${ contents.data }`;
        } else {
          this.getImage(imageUrl, imageName, fileType);
        }
      } catch (err: any) {
        // this.errorService.logError(err);
        this.getImage(imageUrl, imageName, fileType);
      }
    } else {
      this.imgSrc = imageUrl;
    }
  }

  /**
   * Generate file name from URL, replace usless characters with underscore
   * @param url URL
   * @returns clean filename
   */
  generateNameFromUrl(url: string): string {
    const uniqueName = url.replace('://', '_').replace(/\./g, '_').replace(/\//g, '_');
    return uniqueName;
  }

  /**
   * Get image from cloud as blob type.
   * Save into local storage if get image successfully.
   * Else use live image url directly.
   * @param url URL
   * @param filename file name
   * @param fileType file type
   */
  async getImage(url: string, filename: string, fileType: string) {
    const options: HttpOptions = {
      url,
      responseType: 'blob'
    };

    try {
      const response: HttpResponse = await CapacitorHttp.get(options);
      if (response?.status === 200 && response?.data) {
        this.imgSrc = `data:image/${ fileType };base64,${ response.data }`;
        this.saveImage(response.data, filename);
      } else {
        this.imgSrc = url;
      }
    } catch (err: any) {
      this.errorService.logError(err);
      this.imgSrc = url;
    }
  }

  /**
   * Save image to device
   * @param data base64 data
   * @param filename file name
   */
  async saveImage(data: string, filename: string, count?: number) {
    if (data && filename) {
      try {
        const result: WriteFileResult = await Filesystem.writeFile({
          path: `${CACHE_FOLDER}/${ filename }`,
          data,
          directory: Directory.Data,
          recursive: true
        });
        if (!result?.uri) {
          if (!count || count < 3) {
            setTimeout(() => {
              this.saveImage(data, filename, count ? count++ : 1);
            }, 2000);
          }
        }
      } catch (err: any) {
        this.errorService.logError(err);
      }
    }
  }

  async getFirebaseStorageType(url: string) {
    let type = DEFAULT_TYPE;
    const firebaseUrl = 'https://firebasestorage.googleapis.com/v0/b/thebigday-wedding.appspot.com/o/';

    if (url && url.indexOf(firebaseUrl) !== -1) {
      try {
        url = decodeURIComponent(url.replace(firebaseUrl, '')).split('?')[0];
        const folder = url.split('/');
  
        const storageRef = this.afStorage.storage.ref();
        if (folder?.length) {
          let imageRef;
          folder.forEach((x: string) => {
            if (!imageRef) {
              imageRef = storageRef.child(x);
            } else {
              imageRef = imageRef.child(x);
            }
          });
  
          if (imageRef) {
            type = await imageRef.getMetadata().then((metadata) => {
              if (metadata?.contentType && metadata.contentType?.indexOf('image/') !== -1) {
                const type = metadata.contentType.replace('image/', '');
                if (type) {
                  return type;
                }
              }
            }).catch((err) => {
              this.errorService.logError(err);
              return '';
            });
          }
        }
      } catch (err) {
        this.errorService.logError(err);
      }
    }

    return type ? type : DEFAULT_TYPE;
  }

}
