import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BlastCreditService } from './blast-credit.service';
import { BlastMsgService } from './blast-msg.service';
import { BlastRedeemService } from './blast-redeem.service';
import { BlastTemplateService } from './blast-template.service';
import { BlastUsageService } from './blast-usage.service';

@Injectable({
  providedIn: 'root'
})
export class BlastSetupService implements OnInit, OnDestroy {

  constructor(
    private blastTemplateService: BlastTemplateService,
    private blastUsageService: BlastUsageService,
    private blastMsgService: BlastMsgService,
    private blastCreditService: BlastCreditService,
  ) { }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  async setup(accountId: string) {
    await this.blastTemplateService.setupAccountId(accountId);
    await this.blastUsageService.setupAccountId(accountId);
    await this.blastMsgService.setupAccountId(accountId);
    await this.blastCreditService.setupAccountId(accountId);
  }
}
