import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Platform, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { AccountUserService } from 'src/app/services/account/account-user.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { AccountUserComponent } from 'src/app/components/account/account-user/account-user.component';

import { SettingField } from 'src/app/interfaces/database';
import { SettingFieldSortingType, SettingFieldType } from 'src/app/types/general';

/**
 * Setting field filter component
 */
@Component({
  selector: 'app-setting-field-filter',
  templateUrl: './setting-field-filter.component.html',
  styleUrls: ['./setting-field-filter.component.scss'],
})
export class SettingFieldFilterComponent implements OnInit, OnDestroy {

  /**
   * Sorting type
   */
  sorting: SettingFieldSortingType;
  /**
   * Descending flag
   */
  desc: boolean;
  /**
   * Selected filter conditions
   */
  filter: any;
  /**
   * Filter form
   */
  filterForm: FormGroup;

  /**
   * Constructor
   * @param platform Platform service
   * @param formBuilder Form builder
   * @param modalController Modal controller
   * @param translate Translate service
   * @param accountUserService Account user service
   */
  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private accountUserService: AccountUserService,
    private popupService: PopupService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
  }

  /**
   * Start before view enter
   */
  ionViewWillEnter() {
    this.initialize();
  }

  /**
   * Initial component.
   * Setup form & setup form value if applicable.
   * Start watch invited By.
   */
  async initialize() {
    await this.setupForm();
    if (this.filter) {
      this.setupFormValue();
    }
  }

  /**
   * Setup filter form
   */
  async setupForm() {
    this.filterForm = this.formBuilder.group({
      type: new FormControl('', []),
      createBy: new FormControl('', []),
      updateBy: new FormControl('', []),
    });
  }

  /**
   * Setup filter form value for edit filter conditions
   */
  async setupFormValue() {
    await this.platform.ready();
    if (this.filter && this.filterForm) {
      if (this.filter.type) {
        this.filterForm.controls.type.setValue(this.filter.type);
      }
      if (this.filter?.createBy) {
        this.filterForm.controls.createBy.setValue(this.filter.createBy);
      }
      if (this.filter.updateBy) {
        this.filterForm.controls.updateBy.setValue(this.filter.updateBy);
      }
    }
  }

  /**
   * Check is form is empty
   * @returns true if form is empty
   */
  isEmptyForm(): boolean {
    let empty = true;
    if (this.filterForm && this.filterForm.value) {
      Object.keys(this.filterForm.value)?.forEach(key => {
        if (key && this.filterForm?.value?.[key] && empty) { empty = false; }
      });
    }
    return empty;
  }

  /**
   * Reset filter and sorting
   */
  reset() {
    this.filterForm.reset();
    this.sorting = '';
    this.desc = true;
  }



  /**
   * Dismiss current modal
   * @param filter Apply filter here
   */
  async dismissModal(filter?: any) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) {
        this.modalController.dismiss({
          filter,
          sorting: this.sorting,
          desc: this.desc
        });
      }
    }
  }

  /**
   * Present account user modal to select user
   * @param type type for filter, created by / updated by uid.
   */
  async presentAccountUserModal(type: string, showSystem?: boolean) {
    if (type) {
      const modal = await this.modalController.create({
        component: AccountUserComponent,
        componentProps: {
          selectedUid: this.filterForm.value[type],
          showSystem
        }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result?.data?.uid) {
          this.filterForm.controls[type].setValue(result.data.uid);
        }
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Get user name by uid
   * @param uid User ID
   * @returns User name
   */
  getUserName(uid: string): string {
    if (uid  === 'system') {
      return this.translate.instant('LBL.system');
    } else if (uid) {
      return this.accountUserService.getUserByUid(uid)?.name;
    } else {
      return null;
    }
  }

  /**
   * Filter form submit.
   * Mark form as touched and check if is valid.
   * Set filter enable if empty is not empy.
   */
  filterFormSubmit() {
    this.filterForm.markAllAsTouched();
    if (this.filterForm.valid) {
      const filter = this.filterForm.value;

      if (filter.attending) {
        filter.status = {
          attending: filter.attending
        };
      }

      if (!this.isEmptyForm()) {
        filter.enable = true;
      }

      this.dismissModal(filter);
    }
  }
}
