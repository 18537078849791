import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Group } from 'src/app/interfaces/group';
import { FunctionService } from '../general/function.service';
import { GroupManageService } from './group-manage.service';
import { AccountPackageService } from '../account/account-package.service';
import { ErrorService } from '../general/error.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root'
})
export class GroupFixService implements OnInit, OnDestroy {

  constructor(
    private fns: AngularFireFunctions,
    private accountPackageService: AccountPackageService,
    private groupManageService: GroupManageService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
  
  async fixGroup(groupList: Group[]) {
    const updateGroupList: Group[] = [];
    const mobileGroupList: Group[] = [];
    const list = this.functionService.cloneDeep(groupList);
    list?.forEach((group: Group) => {
      let flag = false;
      if (group?.status?.deleteBy?.time) {
        flag = true;
        if (!group.deleteBy?.time?.seconds || (group?.deleteBy?.time?.seconds && group.status.deleteBy.time.seconds > group.deleteBy.time.seconds)) {
          group.deleteBy = group.status.deleteBy;
        }
        group.status.deleteBy = this.functionService.firestoreDeleteField;
      }
      if (group?.status?.updateBy?.time) {
        flag = true;
        if (!group.updateBy?.time?.seconds || (group?.updateBy?.time?.seconds && group.status.updateBy.time.seconds > group.updateBy.time.seconds)) {
          group.updateBy = group.status.updateBy;
        }
        group.status.updateBy = this.functionService.firestoreDeleteField;
      }
      if (flag) {
        group.memberList = [];
        updateGroupList.push(group);
      }
      if (group?.mobile?.no && group?.mobile?.code) {
        if (!group?.mobile?.country) {
          mobileGroupList.push(group);
        } else if (group?.mobile?.country === 'CA' && group.mobile.code !== 1) {
          mobileGroupList.push(group);
        }
      }
    });

    if (updateGroupList?.length) {
      const groupIdList = updateGroupList.map((group: Group) => {
        return group?.groupId;
      });
      await this.groupManageService.saveGroupList(groupIdList, null, updateGroupList);
    }
    if (mobileGroupList?.length) {
      this.lookupMobile(mobileGroupList);
    }
  }

  async lookupMobile(groupList: Group[]) {
    const accountId = this.accountPackageService.accountId;
    if (accountId && groupList?.length) {
      // console.log(groupList);
      await this.fns.httpsCallable('mobileLookupCall')({ accountId, groupList })
      .toPromise().then(async (result: any) => {
      }).catch((err: any) => {
        this.errorService.logError(err);
      });
    }
  }
}
