import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { GroupService } from 'src/app/services/group/group.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { GuestDeleteService } from 'src/app/services/guest/guest-delete.service';
import { SeatingService } from 'src/app/services/seating/seating.service';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';

import { GuestGroupComponent } from 'src/app/components/group/guest-group/guest-group.component';
import { GuestQrcodeComponent } from 'src/app/components/qrcode/guest-qrcode/guest-qrcode.component';
import { SeatingDetailComponent } from 'src/app/components/seating/seating-detail/seating-detail.component';
import { SeatingListComponent } from 'src/app/components/seating/seating-list/seating-list.component';
import { GuestEditComponent } from 'src/app/components/guest/guest-edit/guest-edit.component';
import { GuestLogComponent } from 'src/app/components/log/guest-log/guest-log.component';

import { SettingField } from 'src/app/interfaces/database';
import { Group } from 'src/app/interfaces/group';
import { Guest } from 'src/app/interfaces/guest';
import { Seating } from 'src/app/interfaces/seating';
import { ModuleType, SettingFieldType } from 'src/app/types/general';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';
import { CurrentPrivilege } from 'src/app/interfaces/privilege';

@Component({
  selector: 'app-guest-profile',
  templateUrl: './guest-profile.component.html',
  styleUrls: ['./guest-profile.component.scss'],
})
export class GuestProfileComponent implements OnInit, OnDestroy {

  /**
   * Guest
   */
  guest: Guest;
  /**
   * Guest group
   */
  group: Group;
  /**
   * AccountModule
   */
  module: ModuleType;

  guestSeating: Seating;

  currentPrivilege: CurrentPrivilege;

  seatingTypeName: string;

  invitedBy: string;
  category: string;
  dietaryReq: string;
  specialReq: string;
  session: string;
  /**
   * Guest list subscription
   */
  private guestListSubscription: Subscription;
  /**
   * Group list subscription
   */
  private groupListSubscription: Subscription;
  /**
   * Guest group modal
   */
  private groupModal: boolean;
  /**
   * Seating detail flag
   */
  private seatingDetail: boolean;

  private privilegeSubscription: Subscription;

  /**
   * Constructor
   * @param modalController modal controller
   * @param guestListService guest list service
   * @param groupService guest group service
   * @param guestManageService guest manage service
   * @param seatingService seating service
   * @param translate translate service
   * @param popupService popup service
   * @param functionService function service
   */
  constructor(
    private modalController: ModalController,
    private settingFieldService: SettingFieldService,
    private guestService: GuestService,
    private groupService: GroupService,
    private guestManageService: GuestManageService,
    private guestDeleteService: GuestDeleteService,
    private seatingService: SeatingService,
    private seatingSettingService: SeatingSettingService,
    private moduleService: ModuleService,
    private privilegeService: PrivilegeService,
    private translate: TranslateService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {
    this.unwatchGuest();
    this.unwatchGroupList();
    this.unwatchPrivilege();
  }

  /**
   * Before view enter
   */
  async ionViewWillEnter() {
    if (!this.module) {
      this.module = this.moduleService.currentModule;
    }

    this.seatingTypeName = this.getSeatingTypeName();
    this.watchGuest();
    this.watchGroupList();
    this.watchPrivilege();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatchGuest();
    this.unwatchGroupList();
    this.unwatchPrivilege();
  }

  /**
   * Watch guest list
   */
  async watchGuest() {
    if (!this.guestListSubscription) {
      this.guestListSubscription = this.guestService.observableGuestList.subscribe(() => {
        this.setupGuest();
      });
    }
   
  }

  /**
   * Unwatch guest list
   */
  async unwatchGuest() {
    if (this.guestListSubscription) {
      this.guestListSubscription.unsubscribe();
      this.guestListSubscription = null;
    }
  }

  /**
   * Watch group list
   */
  async watchGroupList() {
    if (!this.groupListSubscription) {
      this.groupListSubscription = this.groupService.observableGroupList.subscribe(() => {
        this.setupGroup(this.guest?.groupId);
      });
    }
    
  }

  /**
   * Unwatch group list
   */
  async unwatchGroupList() {
    if (this.groupListSubscription) {
      this.groupListSubscription.unsubscribe();
      this.groupListSubscription = null;
    }
  }

  async watchPrivilege() {
    if (!this.privilegeSubscription) {
      this.privilegeSubscription = this.privilegeService.observableCurrentPrivilege.subscribe(() => {
        this.setupPrivilege();
      })
    }
  }

  async unwatchPrivilege() {
    if (this.privilegeSubscription) {
      this.privilegeSubscription.unsubscribe();
      this.privilegeSubscription = null;
    }
  }

  setupPrivilege() {
    if (!this.currentPrivilege) {
      this.currentPrivilege = {};
    }
    if (this.module) {
      this.currentPrivilege = {
        [this.module]: {
          'edit': this.checkPrivilege(this.module, 'edit'),
          'seating': this.checkPrivilege(this.module, 'seating'),
          'qrcode': this.checkPrivilege(this.module, 'qrcode'),
          'restore': this.checkPrivilege(this.module, 'restore'),
          'delete': this.checkPrivilege(this.module, 'delete'),
        }
      };
    }
  }

  /**
   * Setup guest
   */
  setupGuest() {
    if (this.guest?.guestId) {
      const guest = this.guestService.getGuest(this.guest.guestId);
      if (guest && !this.functionService.isEqual(this.guest, guest)) {
        if (guest.groupId !== this.guest?.groupId) {
          this.setupGroup(guest.groupId);
        }
        this.guest = guest;
        this.guestSeating = this.getSeatingByName(guest?.seating);
      }
      this.setupSettingField();
    }
  }

  /**
   * Setup group
   * @param groupId Group id
   */
  setupGroup(groupId: string) {
    if (this.guest?.groupId) {
      const group = this.groupService.getGroup(groupId);
      if (!this.functionService.isEqual(this.group, group)) {
        this.group = group;
      }
    }
  }

  setupSettingField() {
    this.invitedBy = this.getSettingField('invited_by', this.guest?.invitedBy);
    this.category = this.getSettingField('category', this.guest?.category);
    this.dietaryReq = this.getSettingField('dietary_req', this.guest?.dietaryReq);
    this.specialReq = this.getSettingField('special_req', this.guest?.specialReq);
    this.session = this.getSettingField('session', this.guest?.session);
  }

  /**
   * Get seating by name
   * @param seatingName Seating name
   */
  getSeatingByName(seatingName: string): Seating {
    return this.seatingService.getSeatingByName(seatingName);
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[]): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList);
  }

  getSeatingTypeName(): string {
    return this.seatingSettingService.getSeatingTypeName();
  }

  /**
   * Dismiss guest profile modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  /**
   * Present guest group modal
   * @param group Guest group
   */
  async presentGroupModal(group: Group) {
    if (!this.groupModal) {
      if (this.module !== 'trash' || group?.groupId) {
        const modal = await this.modalController.create({
          component: GuestGroupComponent,
          cssClass: '',
          componentProps: {
            group,
            guest: this.guest
          }
        });
        modal.present();
        modal.onWillDismiss().then((result: any) => {
          if (result && result.data && result.data.guest) {
            this.guest = result.data.guest;
            this.setupSettingField();
          }
        });
      }
    } else {
      this.dismissModal();
    }
  }

  /**
   * Present guest qrcode modal
   * @param guest guest
   */
  async presentGuestQrcodeModal(guest: Guest) {
    if (this.checkPrivilege(this.module, 'qrcode') || this.module === 'rsvp') {
      const modal = await this.modalController.create({
        component: GuestQrcodeComponent,
        cssClass: '',
        componentProps: {
          guest
        }
      });
      modal.present();
    }
  }

  /**
   * Present guest edit modal
   * @param guest guest
   */
  async presentGuestEditModal(guest: Guest) {
    if (this.checkPrivilege(this.module, 'edit')) {
      const modal = await this.modalController.create({
        component: GuestEditComponent,
        cssClass: '',
        componentProps: {
          guest,
          groupModal: this.groupModal
        }
      });
      modal.present();
      modal.onDidDismiss().then((result: any) => {
        if (result?.data?.dismiss) {
          this.dismissModal();
        }
      });
    } else {
      this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
    }
  }

  /**
   * Present seating detail modal
   * @param seatingName seating name
   */
  async presentSeatingDetailModal(seatingName: string) {
    if (this.module !== 'trash') {
      if (this.seatingDetail) {
        this.dismissModal();
      } else {
        if (seatingName) {
          const seating = this.seatingService.getSeatingByName(seatingName);
          const modal = await this.modalController.create({
            component: SeatingDetailComponent,
            cssClass: '',
            componentProps: {
              seating,
              guest: this.guest
            }
          });
          modal.present();
        } else {
          this.presentSeatingListModal();
        }
      }
    }
  }

  /**
   * Present seating list modal
   */
  async presentSeatingListModal() {
    if (this.module !== 'trash') {
      if (this.checkPrivilege(this.module, 'seating')) {
        const modal = await this.modalController.create({
          component: SeatingListComponent,
          cssClass: '',
          componentProps: {
            assignMode: true,
            newGuestList: [ this.guest ]
          }
        });
        modal.present();
      } else {
        this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
      }
    }
  }

  /**
   * Prompt unassign seating confirm modal
   */
  async unassignSeatingConfirm() {
    if (this.checkPrivilege(this.module, 'seating')) {
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('SEATING.msg.confirm_unassign_seating', { seating: this.getSeatingTypeName() })
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          this.saveUnassignSeating();
        }
      });
    } else {
      this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
    }
  }

  /**
   * Present guest log modal
   */
  async presentGuestLogModal() {
    if (this.guest?.guestId) {
      const modal = await this.modalController.create({
        component: GuestLogComponent,
        cssClass: '',
        componentProps: {
          guestId: this.guest.guestId
        }
      });
      modal.present();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Prompt confirm restore modal
   */
  async promptRestore() {
    if (this.module === 'trash' && this.guest?.guestId && this.guest?.name) {
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_restore_field', {
          field: this.guest.name })
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.restoreGuest();
          this.dismissModal();
        }
      });
    }
  }

  /**
   * Prompt confirm restore modal
   */
  async promptDelete() {
    if (this.module === 'trash' && this.guest?.guestId && this.guest?.name) {
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_db_field', {
          field: this.guest.name })
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.deleteGuest();
          this.dismissModal();
        }
      });
    }
  }

  /**
   * Restore guest from trash bin
   */
  async restoreGuest() {
    if (this.guest?.guestId) {
      await this.popupService.presentLoading();
      await this.guestDeleteService.restoreGuestList([ this.guest.guestId ]);
      await this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
  }

  /**
   * Delete guest from db permenantely
   */
  async deleteGuest() {
    if (this.guest?.guestId) {
      await this.popupService.presentLoading();
      await this.guestDeleteService.deleteGuestList([ this.guest.guestId ]);
      await this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
  }

  /**
   * Check user privilege
   * @param action Action
   */
  checkPrivilege(module: ModuleType, action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege(module, action);
  }

  /**
   * Save unassign seating
   */
  async saveUnassignSeating() {
    if (this.guest?.guestId) {
      await this.popupService.presentLoading();
      await this.guestManageService.saveGuest({ seating: ''}, [ this.guest.guestId ]);
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
  }

}
