import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { TemplateVariableType } from 'src/app/types/template';

@Injectable({
  providedIn: 'root'
})
export class BlastWhatsappService implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  getWhatsappStyling(variable: TemplateVariableType, value: string, preview?: boolean) {
    if (variable && value) {
      value = value.trim();
      if (variable !== 'seating_type' && variable !== 'guest_url') {
        if (preview) {
          value = '<strong>' + value + '</strong>'
        } else {
          value = '*' + value + '*';
        }
      }

      if (variable === 'couple1' || variable === 'couple2') {
        if (preview) {
          value = '<i>' + value + '</i>'
        } else {
          value = '_' + value + '_';
        }
      }
    }
    
    return value;
  }
}
