import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SafeArea } from 'capacitor-plugin-safe-area';

@Injectable({
  providedIn: 'root'
})
export class DeviceSafeAreaService implements OnInit, OnDestroy {


  safeArea: any;
  // statusBarHeight: number;

  constructor(
    private platform: Platform,
  ) {
    this.initial();
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    this.unwatchOrentation();
  }

  async initial() {
    await this.platform.ready();
    this.watchOrentation();
    this.getSafeAreaInsets();
  }

  watchOrentation() {
    // const mqPortrait = window.matchMedia("(orientation: portrait)");
    // // Listen for changes in orientation
    // mqPortrait.addEventListener("change", (e) => {
    //   this.getSafeAreaInsets();
    // });

    window.addEventListener('orientationchange', (ev: Event) => {
      this.getSafeAreaInsets();
    });
  }

  unwatchOrentation() {
    window.removeEventListener('orientationchange', (ev: Event) => {});
  }

  getSafeAreaInsets() {
    if (this.platform.is('hybrid') && (this.platform.is('ios'))) {
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        this.safeArea = insets;
      });
    }
  }

  // getStatusBarHeight() {
  //   SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => {
  //     this.statusBarHeight = statusBarHeight;
  //   });
  // }

  getSafeArea(): number {
    let safeArea = 0;
    // if (this.safeArea?.top) {
    //   safeArea += this.safeArea.top;
    // }
    if (this.platform.is('ios')) {
      if (this.safeArea?.bottom) {
        safeArea += this.safeArea.bottom;
      }
  
      if (safeArea) {
        safeArea -= 1;
      }
    }
    
    return safeArea;
  }


}
