import { ModuleType } from 'src/app/types/general';
import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { GiftService } from 'src/app/services/gift/gift.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GroupService } from 'src/app/services/group/group.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { Gift } from 'src/app/interfaces/gift';
import { Group } from 'src/app/interfaces/group';
import { Guest } from 'src/app/interfaces/guest';
import { GiftSortType } from 'src/app/types/gift';

/**
 * Gift list service.
 * For search / filter gift list.
 */
@Injectable({
  providedIn: 'root'
})
export class GiftListService implements OnInit, OnDestroy {

  /**
   * Gift list service.
   * @param guestService Guest service
   * @param groupService Group service
   * @param giftService Gift service
   * @param functionService Function service
   */
  constructor(
    private guestService: GuestService,
    private groupService: GroupService,
    private giftService: GiftService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Search gift list
   * @param keyword keyword
   * @param type gift type
   * @param filter filter criteria
   * @param excludedGiftList excluded gift id list
   * @param sorting sorting
   * @param desc sorting desc type
   * @returns Gift list
   */
  searchGiftList(
    keyword?: string,
    type?: any,
    filter?: any,
    excludedGiftList?: string[],
    sorting?: GiftSortType,
    desc?: boolean,
    module?: ModuleType
  ): Gift[] {
    let giftList: Gift[] = this.giftService.getGiftList(module);
    if (giftList) {
      if (excludedGiftList?.length) {
        giftList = giftList?.filter((gift: Gift) => {
          return this.excludeGiftList(gift, excludedGiftList);
        });
      }

      if (keyword) {
        giftList = giftList?.filter((gift: Gift) => {
          return this.searchGiftByKeyword(gift, keyword);
        });
      }

      if (type) {
        giftList = giftList?.filter((gift: Gift) => {
          return this.filterGiftByType(gift, type);
        });
      }

      if (filter?.enable) {
        giftList = giftList?.filter((gift: Gift) => {
          return this.filterGiftByCriteria(gift, filter);
        });
      }
    }
    return this.sortList(giftList, sorting, desc);
  }


  /**
   * Exclude gift list
   * @param gift Gift
   * @param excludedGiftList Gift id to be excluded
   */
   excludeGiftList(gift: Gift, excludedGiftList: string[]) {
    if (excludedGiftList && excludedGiftList.length) {
      if (gift && gift.giftId && excludedGiftList?.indexOf(gift.giftId) !== -1) {
        return false;
      }
    }
    return true;
  }

  /**
   * Search gift by keyword
   * @param gift Gift
   * @param keyword Keyword
   */
  searchGiftByKeyword(gift: Gift, keyword: string): boolean {
    if (keyword) {
      if (gift.name) {
        if (this.functionService.search(gift.name, keyword)) {
          return true;
        } else if (this.functionService.chineseMath(gift.name, keyword)) {
          return true;
        }
      }

      if (gift.amount) {
        if (gift.amount.toString()?.indexOf(keyword) !== -1) {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  /**
   * Filter gift by type
   * @param gift gift
   * @param type filter type
   */
  filterGiftByType(gift: Gift, type: any): boolean {
    if (gift && type) {
      if (type.giftType && gift.giftType !== type.giftType) {
        return false;
      }
      if (type.currency && gift.currency !== type.currency) {
        return false;
      }
    }
    return true;
  }

  /**
   * Filter gift by criteria
   * @param gift gift
   * @param filter filter criteria
   */
  filterGiftByCriteria(gift: Gift, filter: any): boolean {
    if (filter?.enable) {
      if (filter.type) {
        if (gift.giftType !== filter.type) {
          return false;
        }
      }

      if (filter.currency) {
        if (gift.giftType !== 'cash' || gift.currency !== filter.currency) {
          return false;
        }
      }

      if (filter.amount) {
        if (gift.giftType !== 'cash') {
          return false;
        }
        if (filter.amount.lower) {
          if (gift.amount < filter.amount.lower) {
            return false;
          }
        }
        if (filter.amount.upper) {
          if (gift.amount > filter.amount.upper) {
            return false;
          }
        }
      }

      if (filter.group) {
        if (filter.group === 'individual' && !gift?.guestIdList?.length) {
          return false;
        } else if (filter.group === 'group' && !gift.groupIdList?.length) {
          return false;
        }
      }

      if (filter.createBy) {
        if (!gift?.createBy || !gift?.createBy?.uid || gift.createBy.uid !== filter?.createBy) {
          return false;
        }
      }

      if (filter.updateBy) {
        if (!gift.updateBy || !gift.updateBy.uid || gift.updateBy.uid !== filter.updateBy) {
          return false;
        }
      }

      if (filter.deleteBy) {
        if (!gift.deleteBy || !gift.deleteBy.uid || gift.deleteBy.uid !== filter.deleteBy) {
          return false;
        }
      }
      let flag = true;
      if (filter?.invitedBy || filter?.category || filter?.dietaryReq || filter?.specialReq
        || filter?.seating || !this.functionService.isEmpty(filter?.status) || filter?.checkinBy) {
        let guestList = gift?.guestIdList?.length ? this.guestService.getGuestListById(gift.guestIdList) : [];
        const groupList = gift?.groupIdList?.length ? this.groupService.getGroupListById(gift.groupIdList) : [];
        if (groupList?.length) {
          groupList?.forEach((group: Group) => {
            if (group?.memberList?.length) {
              guestList = [...new Set([...guestList, ...this.guestService.getGuestListById(group.memberList)])];
            }
          });
        }

        if (!guestList?.length && !groupList?.length) {
          return false;
        }

        guestList?.forEach((guest: Guest) => {
          if (filter?.invitedBy) {
            if (guest?.invitedBy?.[0]?.custom !== filter?.invitedBy?.custom || guest?.invitedBy?.[0]?.value !== filter?.invitedBy?.value) {
              flag = false;
              return flag;
            }
          }

          if (filter?.category) {
            if (guest?.category?.[0]?.custom !== filter?.category?.[0]?.custom || guest?.category?.[0]?.value !== filter?.category?.[0]?.value) {
              flag = false;
              return flag;
            }
          }

          if (filter.dietaryReq) {
            if (guest?.dietaryReq?.[0]?.custom !== filter?.dietaryReq?.[0]?.custom
              || guest?.dietaryReq?.[0]?.value !== filter?.dietaryReq?.[0]?.value) {
                flag = false;
                return flag;
            }
          }

          if (filter?.specialReq) {
            if (guest?.specialReq?.[0]?.custom !== filter?.specialReq?.[0]?.custom
              || guest?.specialReq?.[0]?.value !== filter?.specialReq?.[0]?.value) {
                flag = false;
                return flag;
            }
          }

          if (filter?.seating) {
            if (filter.seating === 'assigned' && !guest.seating) {
              flag = false;
              return flag;
            } else if (filter.seating === 'not_assign' && guest.seating) {
              flag = false;
              return flag;
            }
          }
          if (filter?.status) {
            if (!this.functionService.isUndefined(filter?.status?.attending) && !filter?.status?.checkin) {
              if (guest?.status?.attending !== filter.status.attending) {
                flag = false;
                return flag;
              }
            }
            if (!this.functionService.isUndefined(filter?.status?.deleted)) {
              if (guest?.status?.deleted !== filter.status.deleted) {
                flag = false;
                return flag;
              }
            }
            if (!this.functionService.isUndefined(filter?.status?.checkin)) {
              if (guest?.status?.checkin !== filter.status.checkin) {
                flag = false;
                return flag;
              }
            }
            if (!this.functionService.isUndefined(filter?.status?.gift)) {
              if (guest?.status?.gift !== filter.status.gift) {
                flag = false;
                return flag;
              }
            }
          }
          if (filter?.checkinBy) {
            if (guest.status.checkin && guest?.checkinBy?.uid !== filter.checkinBy) {
              flag = false;
              return flag;
            }
          }
        });
        if (flag) { return flag; }
      }
      return flag;
    }
    return true;
  }

  /**
   * Sort gift list
   * @param giftList gift list to be sorted
   * @param field sorting field
   * @param desc descending flag
   */
  sortList(giftList: Gift[], field?: GiftSortType, desc?: boolean): Gift[] {
    if (giftList?.length) {
      giftList.sort((a: Gift, b: Gift) => {
        const nameA = a?.name ? a.name.toString() : a?.name ? a.name.toString() : a.giftType === 'cash' && a.amount ? a.amount.toString() : '';
        const nameB = b?.name ? b.name.toString() : b?.name ? b.name.toString() : b.giftType === 'cash' && b.amount ? b.amount.toString() : '';

        if (field) {
          if (field === 'name') {
            if (a[field] !== b[field]) {
              return this.functionService.compare(nameA.toLowerCase(), nameB.toLowerCase(), desc);
            }
          }  else if (field === 'deletedTime') {
            const timeA: number = a?.deleteBy?.time?.seconds ? a.deleteBy.time.seconds : 0;
            const timeB: number =  b?.deleteBy?.time?.seconds ? b.deleteBy.time.seconds : 0;
            if (timeA !== timeB) {
              return this.functionService.compare(timeA, timeB, desc);
            }
          } else if (field === 'createdTime') {
            const timeA: number = a?.createBy?.time?.seconds ? a.createBy.time.seconds : 0;
            const timeB: number =  b?.createBy?.time?.seconds ? b.createBy.time.seconds : 0;
            if (timeA !== timeB) {
              return this.functionService.compare(timeA, timeB, desc);
            }
          } else if (field === 'updatedTime') {
            const timeA: number = a?.updateBy?.time?.seconds ? a.updateBy.time.seconds : 0;
            const timeB: number = b?.updateBy?.time?.seconds ? b.updateBy.time.seconds : 0;

            if (timeA !== timeB) {
              return this.functionService.compare(timeA, timeB, desc);
            }
          } else if (field === 'amount') {
            if (a.giftType === 'other') {
              return 1;
            } else if (a.amount !== b.amount) {
              return this.functionService.compare(a.amount, b.amount, desc);
            } else if (a.currency !== b.currency) {
              return this.functionService.compare(a.currency, b.currency);
            }
          } else if (field === 'currency') {
            if (a.giftType === 'other') {
              return 1;
            } else {
              return this.functionService.compare(a.currency, b.currency);
            }
          }
        } else {
          if (nameA === nameB) {
            const timeA: number = a?.updateBy?.time?.seconds ? a.updateBy.time.seconds : 0;
            const timeB: number =  b?.updateBy?.time?.seconds ? b.updateBy.time.seconds : 0;
            return this.functionService.compare(timeA, timeB, desc);
          } else {
            return this.functionService.compare(nameA.toLowerCase(), nameB.toLowerCase(), desc);
          }
        }

        if (nameA === nameB) {
          const timeA: number = a?.updateBy?.time?.seconds ? a.updateBy.time.seconds : 0;
          const timeB: number =  b?.updateBy?.time?.seconds ? b.updateBy.time.seconds : 0;
          return this.functionService.compare(timeA, timeB, desc);
        } else {
          return this.functionService.compare(nameA.toLowerCase(), nameB.toLowerCase(), false);
        }
      });
    }

    return giftList;
  }
}
