
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

import { ActionService } from 'src/app/services/account/privilege/action.service';
import { AccountRoleService } from 'src/app/services/account/account-role.service';
import { UserService } from 'src/app/services/user/user.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { Privilege } from 'src/app/interfaces/privilege';
import { AccountUser } from 'src/app/interfaces/account';
import { ModuleType } from 'src/app/types/general';

/**
 * Account privilege service.
 */
@Injectable({
  providedIn: 'root'
})
export class PrivilegeService implements OnInit, OnDestroy {

  /**
   * Observable current user privilege
   */
  observableCurrentPrivilege: any;
  /**
   * Account ID
   */
  private accountId: string;
  /**
   * Current user privilege
   */
  private currentUserPrivilegeList: Privilege;

  /**
   * Constructor
   * @param afs angualr firestore
   * @param actionService action service
   * @param AccountRoleService role service
   * @param userService user service
   * @param functionService function service
   */
  constructor(
    private afs: AngularFirestore,
    private actionService: ActionService,
    private accountRoleService: AccountRoleService,
    private userService: UserService,
    private functionService: FunctionService,
  ) {
    this.observableCurrentPrivilege = new BehaviorSubject<Privilege>(this.currentUserPrivilegeList);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  /**
   * Initialize privilege service
   * @param accountId Account ID
   */
  async initialize(accountId: string) {
    this.accountId = accountId;
    const uid = this.userService.uid;
    this.currentUserPrivilegeList = null;
    if (uid && this.accountId) {
      const doc: any = await this.afs.doc(`accounts/${ this.accountId }/accountUser/${ uid }/`).ref.get();
      if (doc.exists && doc.data()) {
        const currentUser: AccountUser = doc.data() as AccountUser;
        await this.prepareCurrentUserPrivilege(currentUser);
      }
    }
  }

  /**
   * Setup current user privilege
   */
  async prepareCurrentUserPrivilege(currentUser: AccountUser) {
    if (currentUser) {
      if (currentUser?.owner || currentUser?.role?.coupleId) {
        await this.setupCurrentUserPrivilege(this.actionService.actionList);
      } else if (currentUser?.privilege && !this.functionService.isEmpty(currentUser.privilege)) {
        await this.setupCurrentUserPrivilege(currentUser.privilege);
      } else {
        await this.setupCurrentUserPrivilege(this.getRolePrivilege(currentUser?.role?.type));
      }
    }
  }

  /**
   * Setup current user privilege
   * @param privilege privilege list
   */
  async setupCurrentUserPrivilege(privilege: Privilege) {
    this.currentUserPrivilegeList = privilege;
    this.observableCurrentPrivilege.next(this.currentUserPrivilegeList);
  }

  /**
   * Check current user privilege with module / action
   * @param module AccountModule
   * @param action Action (optional)
   * @returns true if current user having privilege to access the module / action
   */
  checkCurrentUserPrivilege(module: ModuleType, action?: string): boolean {
    if (module && this.currentUserPrivilegeList?.[module]) {
      if (!this.functionService.isEmpty(this.currentUserPrivilegeList[module])) {
        if (action) {
          if (this.currentUserPrivilegeList?.[module]?.indexOf(action) !== -1) {
            return true;
          }
        } else {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Get privilege based on role
   * @param role Role
   * @returns privilege of the role
   */
  getRolePrivilege(role: string): Privilege {
    const privilege: Privilege = {};
    if (role) {
      let rolePrivilege: Privilege;
      rolePrivilege = this.accountRoleService.getRolePrivilege(role);
      const actionList = this.actionService.actionList;
      if (actionList && !this.functionService.isEmpty(actionList)) {
        Object.keys(actionList)?.forEach((module: string) => {
          privilege[module] = [];
          actionList?.[module]?.forEach((func: string) => {
            if ((rolePrivilege?.[module]?.length && rolePrivilege?.[module]?.indexOf(func) !== -1) || role === 'groom' || role === 'bride') {
              if (rolePrivilege?.[module]?.indexOf(func) !== -1) {
                privilege[module].push(func);
              }
            }
          });
        });
      }
    }
    return privilege;
  }

}
