import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Provider } from 'src/app/interfaces/user';
import { ProviderId } from 'src/app/types/user';

/**
 * Firebase authentication provider data
 */
@Injectable({
  providedIn: 'root'
})
export class ProviderService implements OnInit, OnDestroy {

  /**
   * User provider list
   */
  providerList: Provider[];

  /**
   * Constructor
   */
  constructor() {}
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * User provider list
   * @param providerList Provider List
   */
  setupProviderList(providerList: Provider[]) {
    this.providerList = providerList?.length ? providerList : [];
  }

  /**
   * Get Provider data by provider ID
   * @param providerId Provider ID, eg. 'facebook.com', 'google.com', 'apple.com', 'twitter.com', 'mobile'.
   * @returns Provider data
   */
  getProviderData(providerId: ProviderId): Provider {
    if (this.providerList?.length) {
      const index = this.providerList?.findIndex(x => x?.providerId === providerId);
      if (index !== -1) {
        return this.providerList[index];
      }
    }
    return null;
  }

}
