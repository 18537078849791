import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { StdVisitorPageSetting } from 'src/app/commons/setting';
import { Language } from 'src/app/interfaces/database';
import { PageSetting } from 'src/app/interfaces/visitor';
import { VistorInfoFieldType } from 'src/app/types/visitor';
import { VisitorLanguageService } from './visitor-language.service';
import { VisitorVariableService } from './visitor-variable.service';

@Injectable({
  providedIn: 'root'
})
export class VisitorDefaultService implements OnInit, OnDestroy {

  translation: any;
  langCode: string;

  constructor(
    private visitorVariableService: VisitorVariableService,
    private visitorLanguageService: VisitorLanguageService,
  ) { }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  getDefaultTheme(): string {
    return '1.1';
  }

  getDefaultSetting(): PageSetting {
    let pageSetting: PageSetting = StdVisitorPageSetting;
    return pageSetting;
  }

  getDefaultPageInfo(pageLangList?: Language[]) {
    if (!pageLangList?.length) {
      pageLangList = this.visitorLanguageService.getPangeLangList();
    }
    const pageInfo: any = {};
    pageLangList?.forEach((language: Language) => {
      const langCode = language?.code;
      if (langCode) {
        pageInfo[langCode] = this.getDefaultPageInfoByLanguge(langCode);
      }
    });
    
    return pageInfo;
  }

  getDefaultPageInfoByLanguge(langCode: string) {
    let pageInfo: any = {};
    if (langCode) {
      pageInfo = {
        greeting: '',
        msg: '',
        title: '',
        title2: '',
        pax: '',
        seating: '',
        session: '',
        groupName: '',
        groupType: '',
        groupMemberType: '',
        date: '',
        startTime: '',
        endTime: '',
        cocktailTime: '',
        timezone: '',
        venue: '',
        hall: '',
        address: '',
        remark: '',
        guestPass: '',
        itinerary: '',
        floorplan: '',
        menu: '',
        attachment: '',
      };
      Object.keys(pageInfo)?.forEach((key: VistorInfoFieldType) => {
        pageInfo[key] = this.visitorVariableService.getVisitorVariable(langCode, key);
      });
    }
    
    return pageInfo;
  }
}
