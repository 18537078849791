import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalController, Platform } from '@ionic/angular';

import { Subscription } from 'rxjs';

import { AccountUserService } from 'src/app/services/account/account-user.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { InvitedByService } from 'src/app/services/setting/invited-by.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { AccountUserComponent } from 'src/app/components/account/account-user/account-user.component';

import { SettingField } from 'src/app/interfaces/database';
import { ModuleType, SettingFieldType } from 'src/app/types/general';
import { Range } from 'src/app/interfaces/general';
import { GiftSortType } from 'src/app/types/gift';
import { GuestListMode } from 'src/app/types/guest';
import { SettingFieldComponent } from 'src/app/components/setting/setting-field/setting-field.component';

/**
 * Gift list filter component
 */
@Component({
  selector: 'app-gift-list-filter',
  templateUrl: './gift-list-filter.component.html',
  styleUrls: ['./gift-list-filter.component.scss'],
})
export class GiftListFilterComponent implements OnInit, OnDestroy {

  /**
   * Currency ionic selectable viewchild
   */
  // @ViewChild('currencyIonicSelect', { static: false }) currencyIonicSelect: IonicSelectableComponent;

  /**
   * Sorting type
   */
  sorting: GiftSortType;
  /**
   * Descending flag
   */
  desc: boolean;
  /**
   * Sorting field
   */
  sortingField: {
    name?: boolean,
    type?: boolean,
    currency?: boolean,
    amount?: boolean,
    giftTime?: boolean,
    checkinTime?: boolean,
    createdTime?: boolean,
    updatedTime?: boolean,
    deletedTime?: boolean
  };

  /**
   * Filter field
   */
  showField: {
    type?: boolean,
    currency?: boolean,
    amount?: boolean,
    invitedBy?: boolean,
    category?: boolean,
    checkinStatus?: boolean,
    status?: boolean,
    group?: boolean,
    seating?: boolean,
    dietaryReq?: boolean,
    specialReq?: boolean,
    giftBy?: boolean,
    createdBy?: boolean,
    updatedBy?: boolean,
    checkinBy?: boolean,
    deleteBy?: boolean
  };

  /**
   * Filter form
   */
  filterForm: FormGroup;
  /**
   * Invited by list
   */
  invitedByList: SettingField[];
  /**
   * Currency list
   */
  currencyList: string[];
  /**
   * Min / Max amount
   */
  amount: Range;
  
  category: string;

  /**
   * Selected filter conditions
   */
  private filter: any;
  /**
   * Guest list mode
   */
  private guestListMode: GuestListMode;
  /**
   * AccountModule
   */
  private module: ModuleType;
  /**
   * Invited by subscription
   */
  private invitedBySubscription: Subscription;
  /**
   * Currency list subscription
   */
  private currencyListSubscription: Subscription;
  /**
   * Min / Max Amount subscription
   */
  private amountSubscription: Subscription;

  /**
   * Constructor
   * @param platform platform
   * @param formBuilder form builder
   * @param modalController modal controller
   * @param moduleService module service
   * @param accountUserService account user service
   * @param settingFieldService setting field service
   * @param invitedByService invited by service
   * @param giftService gift service
   * @param functionService function service
   */
  constructor(
    private platform: Platform,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private moduleService: ModuleService,
    private accountUserService: AccountUserService,
    private settingFieldService: SettingFieldService,
    private invitedByService: InvitedByService,
    private giftService: GiftService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.unwatch();
  }

  /**
   * Before view enter
   */
   ionViewWillEnter() {
    this.watch();
    this.initialize();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatch();
  }

  watch() {
    this.watchInvitedBy();
    this.watchCurrencyList();
    this.watchAmount();
  }

  unwatch() {
    this.unwatchInvitedBy();
    this.unwatchCurrencyList();
    this.unwatchAmount();
  }

  /**
   * Initialize
   */
  async initialize() {
    this.sorting = '';
    this.showField = {};
    this.sortingField = {};
    if (!this.module) {
      this.module = this.moduleService.currentModule;
    }
    this.setupForm();
    this.setupShowField();
    if (this.filter) {
      this.setupFormValue();
    }

    this.showField.type = true;
    this.showField.currency = true;
    this.showField.amount = true;
    this.showField.invitedBy = true;
    this.showField.category = true;
    this.showField.checkinStatus = true;
    this.showField.giftBy = true;
    this.showField.checkinBy = true;
    this.showField.createdBy = true;
    this.showField.updatedBy = true;

    this.sortingField.name = true;
    this.sortingField.type = true;
    this.sortingField.currency = true;
    this.sortingField.amount = true;
    this.sortingField.giftTime = true;
    this.sortingField.checkinTime = true;
    this.sortingField.createdTime = true;
    this.sortingField.updatedTime = true;

    if (this.module === 'gift') {

    } else if (this.module === 'trash') {
      this.showField.deleteBy = true;
      this.sortingField.deletedTime = true;
    }
  }

  /**
   * Watch invited by list
   */
  async watchInvitedBy() {
    if (!this.invitedBySubscription) {
      this.invitedBySubscription = this.invitedByService.observableInvitedbyList.subscribe((invitedByList: SettingField[]) => {
        this.invitedByList = this.invitedByService.getInvitedByList();
      });
    }
  }

  /**
   * Unwatch invited by list
   */
  async unwatchInvitedBy() {
    if (this.invitedBySubscription) {
      this.invitedBySubscription.unsubscribe();
      this.invitedBySubscription = null;
    }
  }

  /**
   * Watch currency list
   */
  async watchCurrencyList() {
    if (!this.currencyListSubscription) {
      this.currencyListSubscription = this.giftService.observableCurrencyList.subscribe((currencyList: string[]) => {
        this.currencyList = currencyList;
      });
    }
  }

  /**
   * Unwatch currency list
   */
  async unwatchCurrencyList() {
    if (this.currencyListSubscription) {
      this.currencyListSubscription.unsubscribe();
      this.currencyListSubscription = null;
    }
  }

  /**
   * Watch min / max amount
   */
  async watchAmount() {
    if (!this.amountSubscription) {
      this.amountSubscription = this.giftService.observableAmount.subscribe((amount: any) => {
        this.amount = amount;
      });
    }
  }

  /**
   * Unwatch min / max amount
   */
  async unwatchAmount() {
    if (this.amountSubscription) {
      this.amountSubscription.unsubscribe();
      this.amountSubscription = null;
    }
  }

  /**
   * Setup fitler form
   */
  setupForm() {
    this.filterForm = this.formBuilder.group({
      type: new FormControl('', []),
      currency: new FormControl('', []),
      amount: new FormControl('', []),
      invitedBy: new FormControl('', []),
      category: new FormControl('', []),
      group: new FormControl('', []),
      seating: new FormControl('', []),
      checkinStatus: new FormControl('', []),
      dietaryReq: new FormControl('', []),
      specialReq: new FormControl('', []),
      attending: new FormControl('', []),
      giftBy: new FormControl('', []),
      checkinBy: new FormControl('', []),
      createBy: new FormControl('', []),
      updateBy: new FormControl('', []),
      deleteBy: new FormControl('', [])
    });
  }

  /**
   * Setup fitler form value
   */
  async setupFormValue() {
    await this.platform.ready();
    if (this.filter && this.filterForm) {
      if (this.filter?.type) {
        this.filterForm.controls.type.setValue(this.filter.type);
      }
      if (this.filter?.currency) {
        this.filterForm.controls.currency.setValue(this.filter.currency);
      }
      if (this.filter?.amount) {
        this.filterForm.controls.amount.setValue(this.filter.amount);
      }
      if (this.filter?.invitedBy) {
        this.filterForm.controls.invitedBy.setValue(this.filter.invitedBy);
      }
      if (this.filter?.category) {
        this.filterForm.controls.category.setValue(this.filter.category);
      }
      if (this.filter?.group) {
        this.filterForm.controls.group.setValue(this.filter.group);
      }
      if (this.filter?.seating) {
        this.filterForm.controls.seating.setValue(this.filter.seating);
      }
      if (this.filter?.dietaryReq) {
        this.filterForm.controls.dietaryReq.setValue(this.filter.dietaryReq);
      }
      if (this.filter?.specialReq) {
        this.filterForm.controls.specialReq.setValue(this.filter.specialReq);
      }
      if (this.filter?.status?.attending) {
        this.filterForm.controls.attending.setValue(this.filter.status.attending);
      }
      if (this.filter?.checkinStatus) {
        this.filterForm.controls.checkinStatus.setValue(this.filter.checkinStatus);
      }
      if (!this.functionService.isUndefined(this.filter?.status?.checkinStatus)) {
        this.filterForm.controls.checkinStatus.setValue(this.filter.status.checkinStatus ? 'attended' : 'not_attend');
      }
      if (this.filter?.giftBy) {
        this.filterForm.controls.giftBy.setValue(this.filter.giftBy);
      }
      if (this.filter?.checkinBy) {
        this.filterForm.controls.checkinBy.setValue(this.filter.checkinBy);
      }
      if (this.filter?.createBy) {
        this.filterForm.controls.createBy.setValue(this.filter.createBy);
      }
      if (this.filter?.updateBy) {
        this.filterForm.controls.updateBy.setValue(this.filter.updateBy);
      }
      if (this.filter?.deleteBy) {
        this.filterForm.controls.deleteBy.setValue(this.filter.deleteBy);
      }
    }
  }

  /**
   * Check is form is empty
   * @returns true if form is empty
   */
  isEmptyForm(): boolean {
    let empty = true;
    if (this.filterForm?.value) {
      Object.keys(this.filterForm.value)?.forEach((key: string) => {
        if (key && this.filterForm?.value?.[key] && empty) { empty = false; }
      });
    }
    return empty;
  }

  /**
   * Reset filter form
   */
  reset() {
    this.filterForm.reset();
  }

  /**
   * Setup show field based on guest list modeonDidDismiss.
   */
  setupShowField() {
    if (this.guestListMode === 'attending') {
      this.showField.status = false;
    } else if (this.guestListMode === 'group') {
      this.showField.group = false;
    } else if (this.guestListMode === 'seating') {
      this.showField.seating = false;
    }
  }

  /**
   * Present setting field cmodal
   * @param settingFieldType Setting field type
   */
   async presentSettingFieldModal(settingFieldType: SettingFieldType) {
    const modal = await this.modalController.create({
      component: SettingFieldComponent,
      componentProps: {
        settingFieldType,
        selected: this.filterForm.value.category
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.selected) {
        if (settingFieldType === 'category') {
          if (this.filterForm?.value?.category !== result.data.selected) {
            this.filterForm.controls.category.setValue(result.data.selected);
            this.category = this.getSettingField('category', this.filterForm.value.category);
          }
        }
      }
    });
  }

  /**
   * Present account user modal to select user
   * @param type type - createBy / updateBy
   */
  async presentAccountUserModal(type: string) {
    if (type) {
      const modal = await this.modalController.create({
        component: AccountUserComponent,
        componentProps: { selectedUid: this.filterForm.value[type] }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result?.data?.uid) {
          this.filterForm.controls[type].setValue(result.data.uid);
        }
      });
    }
  }

  /**
   * Dismiss Guest list filter modal
   * @param filter filter
   */
  async dismissModal(filter?: any) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({
        filter,
        sorting: this.sorting,
        desc: this.desc
      }); }
    }
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[]): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList);
  }

  /**
   * Get user name by uid
   * @param uid User ID
   * @returns User name
   */
  getUserName(uid: string): string {
    if (uid) {
      return this.accountUserService.getUserByUid(uid)?.name;
    } else {
      return null;
    }
  }

  /**
   * Submit filter form.
   */
  filterFormSubmit() {
    this.filterForm.markAllAsTouched();
    if (this.filterForm.valid) {
      const filter = this.filterForm.value;
      if (filter.attending) {
        filter.status = {
          attending: filter.attending
        };
      }

      if (filter.checkinStatus) {
        filter.status = {
          checkin: filter.checkinStatus === 'attended' ? true : false
        };
      }

      if (!this.isEmptyForm()) {
        filter.enable = true;
      }
      this.dismissModal(filter);
    }
  }

}
