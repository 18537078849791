import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { NativeGeocoderResult } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { GoogleMapService } from './google-map.service';
import { Coordinate, LocationDetail } from 'src/app/interfaces/general';

@Injectable({
  providedIn: 'root'
})
export class LocationDetailService implements OnInit, OnDestroy {

  constructor(
    private platform: Platform,
    private googleMapService: GoogleMapService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  setupLocationDetail(coords?: Coordinate, geocoder?: NativeGeocoderResult, googleMapResult?: google.maps.GeocoderResult): LocationDetail {
    const locationDetail: LocationDetail = {
      locality: {
        country: ''
      }
    };
    if (coords) {
      locationDetail.coordinate = {
        lat: coords.lat,
        lng: coords.lng,
      };
    }

    if (this.platform.is('hybrid')) {
      if (geocoder) {
        if (geocoder?.areasOfInterest?.[0]) {
          locationDetail.name = geocoder.areasOfInterest[0];
        }
        locationDetail.locality = {
          country: geocoder?.countryCode ? geocoder?.countryCode : ''
        };
        if (geocoder?.administrativeArea) {
          locationDetail.locality.state = geocoder?.administrativeArea;
        }
        if (geocoder?.subAdministrativeArea) {
          locationDetail.locality.district = geocoder?.subAdministrativeArea;
        }
        if (geocoder?.locality) {
          locationDetail.locality.city = geocoder?.locality;
        }
        if (geocoder?.subLocality) {
          locationDetail.locality.town = geocoder?.subLocality;
        }
        if (geocoder?.thoroughfare) {
          locationDetail.locality.area = geocoder?.thoroughfare;
        }
        if (geocoder?.postalCode) {
          locationDetail.locality.postalCode = geocoder?.postalCode;
        }
        locationDetail.gps = true;
      }
    } else {
      if (googleMapResult) {
        locationDetail.locality = this.googleMapService.generateLocality(googleMapResult.address_components);
        locationDetail.name = this.googleMapService.generateLocationName(googleMapResult.address_components);
        locationDetail.gps = true;
      }
    }
    return locationDetail;
  }
}
