import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Subscription } from 'rxjs';

import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';

import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GroupService } from 'src/app/services/group/group.service';
import { SeatingService } from 'src/app/services/seating/seating.service';
import { CheckinService } from 'src/app/services/checkin/checkin.service';
import { CheckinSettingService } from 'src/app/services/checkin/checkin-setting.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { GuestEditComponent } from 'src/app/components/guest/guest-edit/guest-edit.component';
import { GuestLogComponent } from 'src/app/components/log/guest-log/guest-log.component';
import { GuestGroupComponent } from 'src/app/components/group/guest-group/guest-group.component';
import { SeatingListComponent } from 'src/app/components/seating/seating-list/seating-list.component';
import { SeatingDetailComponent } from 'src/app/components/seating/seating-detail/seating-detail.component';

import { SettingField } from 'src/app/interfaces/database';
import { Guest } from 'src/app/interfaces/guest';
import { Seating } from 'src/app/interfaces/seating';
import { Group } from 'src/app/interfaces/group';
import { CheckinSetting } from 'src/app/interfaces/setting';
import { SettingFieldType, ModuleType } from 'src/app/types/general';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';
import { CheckinMethodType } from 'src/app/types/guest';
import { CurrentPrivilege } from 'src/app/interfaces/privilege';

/**
 * Check-in profile component
 */
@Component({
  selector: 'app-checkin-profile',
  templateUrl: './checkin-profile.component.html',
  styleUrls: ['./checkin-profile.component.scss'],
})
export class CheckinProfileComponent implements OnInit, OnDestroy {

  checkinMethod: CheckinMethodType;
  /**
   * Module type
   */
  module: ModuleType;
  /**
   * Guest
   */
  guest: Guest;
  /**
   * Guest group
   */
  group: Group;
  /**
   * Seating
   */
  seating: Seating;
  /**
   * Check-in guest count
   */
  checkinCount: number;
  /**
   * Total count of guest / group member
   */
  totalCount: number;
  /**
   * Check-in setting
   */
  checkinSetting: CheckinSetting;

  currentPrivilege: CurrentPrivilege;

  seatingTypeName: string;

  invitedBy: string;
  category: string;
  specialReq: string;
  dietaryReq: string;

  /**
   * Check-in setting subscription
   */
  private checkinSettingSubscription: Subscription;
  /**
   * Guest list subscription
   */
  private guestListSubscription: Subscription;
  /**
   * Group list subscription
   */
  private groupListSubscription: Subscription;

  private privilegeSubscription: Subscription;
  /**
   * Guest group modal
   */
  private groupModal: boolean;
  /**
   * Seating detail flag
   */
  private seatingDetail: boolean;

  /**
   * Constructor
   * @param modalController modal controller
   * @param settingFieldService setting field service
   * @param groupService group service
   * @param guestService guest service
   * @param seatingService seating service
   * @param checkinService checkin service
   * @param checkinSettingService checkin setting service
   * @param giftCheckinService gift checkin service
   * @param moduleService module service
   * @param privilegeService privilege service
   * @param functionService function service
   */
  constructor(
    private modalController: ModalController,
    private settingFieldService: SettingFieldService,
    private groupService: GroupService,
    private guestService: GuestService,
    private seatingService: SeatingService,
    private seatingSettingService: SeatingSettingService,
    private checkinService: CheckinService,
    private checkinSettingService: CheckinSettingService,
    private privilegeService: PrivilegeService,
    private functionService: FunctionService,
    private popupService: PopupService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unwatch();
  }

  /**
   * Before view enter
   */
  async ionViewWillEnter() {
    this.module = 'checkin';
    this.watch();
    this.seatingTypeName = this.getSeatingTypeName();
  }

  /**
   * Before view leave
   */
  ionViewDidLeave() {
    this.unwatch();
  }

  watch() {
    this.watchGuest();
    this.watchCheckinSetting();
    this.watchGroupList();
    this.watchPrivilege();
  }

  unwatch() {
    this.unwatchGuest();
    this.unwatchCheckinSetting();
    this.unwatchGroupList();
    this.unwatchPrivilege();
  }

  /**
   * Watch guest list
   */
  async watchGuest() {
    if (!this.guestListSubscription) {
      this.guestListSubscription = this.guestService.observableCheckinGuestList.subscribe(() => {
        this.setupGuest();
      });
    }
  }

  /**
   * Unwatch guest list
   */
  async unwatchGuest() {
    if (this.guestListSubscription) {
      this.guestListSubscription.unsubscribe();
      this.guestListSubscription = null;
    }
  }

  /**
   * Watch check-in setting
   */
  async watchCheckinSetting() {
    if (!this.checkinSettingSubscription) {
      this.checkinSettingSubscription = this.checkinSettingService.observableCheckinSetting.subscribe((checkinSetting: CheckinSetting) => {
        this.checkinSetting = checkinSetting;
      });
    }
  }

  /**
   * Unwatch check-in setting
   */
  async unwatchCheckinSetting() {
    if (this.checkinSettingSubscription) {
      this.checkinSettingSubscription.unsubscribe();
      this.checkinSettingSubscription = null;
    }
  }

  /**
   * Watch group list
   */
  async watchGroupList() {
    if (!this.groupListSubscription) {
      this.groupListSubscription = this.groupService.observableGroupList.subscribe(() => {
        this.setupGroup();
      });
    }
  }

  /**
   * Unwatch group list
   */
  async unwatchGroupList() {
    if (this.groupListSubscription) {
      this.groupListSubscription.unsubscribe();
      this.groupListSubscription = null;
    }
  }

  async watchPrivilege() {
    if (!this.privilegeSubscription) {
      this.privilegeSubscription = this.privilegeService.observableCurrentPrivilege.subscribe(() => {
        this.setupPrivilege();
      })
    }
  }

  async unwatchPrivilege() {
    if (this.privilegeSubscription) {
      this.privilegeSubscription.unsubscribe();
      this.privilegeSubscription = null;
    }
  }

  setupPrivilege() {
    if (!this.currentPrivilege) {
      this.currentPrivilege = {};
    }
    this.currentPrivilege = {
      'checkin': {
        'checkin': this.checkPrivilege('checkin', 'checkin'),
        'uncheck': this.checkPrivilege('checkin', 'uncheck'),
        'edit': this.checkPrivilege('checkin', 'edit'),
      }
    };
  }

  /**
   * Setup guest
   */
  setupGuest() {
    if (this.guest?.guestId) {
      const guest = this.guestService.getGuest(this.guest.guestId, 'checkin');
      if (guest && !this.functionService.isEqual(this.guest, guest)) {
        if (this.guest?.seating !== guest?.seating) {
          this.setupSeating();
        }
        if (guest.groupId !== this.guest?.groupId) {
          this.setupGroup();
        } else {
          this.setupCheckinCount();
        }
        this.guest = guest;
      }
      if (this.guest?.guestId && !this.guest?.groupId) {
        this.setupCheckinCount();
      }
      this.setupSettingField();
    }
  }

  setupSettingField() {
    this.invitedBy = this.getSettingField('invited_by', this.guest?.invitedBy);
    this.category = this.getSettingField('category', this.guest?.category);
    this.dietaryReq = this.getSettingField('dietary_req', this.guest?.dietaryReq);
    this.specialReq = this.getSettingField('special_req', this.guest?.specialReq);

  }

  /**
   * Setup group
   */
  setupGroup() {
    if (this.guest?.groupId) {
      const group = this.groupService.getGroup(this.guest.groupId);
      if (!this.functionService.isEqual(this.group, group)) {
        this.group = group;
        this.setupCheckinCount();
      }
    }
  }

  /**
   * Setup seating
   */
  setupSeating() {
    if (this.guest?.seating) {
      this.seating = this.seatingService.getSeatingByName(this.guest.seating);
    }
  }

  /**
   * Setup check-in count
   */
  setupCheckinCount() {
    if (this.group?.memberList?.length) {
      this.checkinCount = this.checkinService.getGroupCheckinCount([...this.group.memberList]);
      this.totalCount = this.group.memberList.filter((guestId: string) => {
        return this.guestService.getGuest(guestId, 'checkin') ? true : false;
      }).length;
    } else if (this.guest?.guestId) {
      this.checkinCount = this.checkinService.getGuestCheckinStatus(this.guest.guestId) ? 1 : 0;
      this.totalCount = 1;
    }
  }

  /**
   * Get seating by name
   * @param seatingName Seating name
   */
  getSeatingByName(seatingName: string): Seating {
    return this.seatingService.getSeatingByName(seatingName);
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[]): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList);
  }

  getSeatingTypeName(): string {
    return this.seatingSettingService.getSeatingTypeName();
  }

  /**
   * Check user privilege
   * @param action Action
   */
  checkPrivilege(module: ModuleType, action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege(module, action);
  }

  /**
   * Dismiss guest profile modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  /**
   * Present guest edit modal
   * @param guest guest
   */
  async presentGuestEditModal(guest: Guest) {
    if (guest?.guestId) {
      const modal = await this.modalController.create({
        component: GuestEditComponent,
        cssClass: '',
        componentProps: {
          guest,
          groupModal: this.groupModal
        }
      });
      modal.present();
      modal.onDidDismiss().then((result: any) => {
        if (result?.data?.dismiss) {
          this.dismissModal();
        }
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Present guest group modal
   * @param group Guest group
   */
  async presentGroupModal(group: Group) {
    if (!this.groupModal) {
      const modal = await this.modalController.create({
        component: GuestGroupComponent,
        cssClass: '',
        componentProps: {
          group,
          guest: this.guest,
          checkinMethod: this.checkinMethod,
        }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result && result.data && result.data.guest) {
          this.guest = result.data.guest;
          this.setupSettingField();
        }
      });
    } else {
      this.dismissModal();
    }
  }

  /**
   * Present seating detail modal
   * @param seatingName seating name
   */
  async presentSeatingDetailModal(seatingName: string) {
    if (this.seatingDetail) {
      this.dismissModal();
    } else {
      if (seatingName) {
        const seating = this.seatingService.getSeatingByName(seatingName);
        const modal = await this.modalController.create({
          component: SeatingDetailComponent,
          cssClass: '',
          componentProps: {
            seating,
            guest: this.guest
          }
        });
        modal.present();
      } else {
        this.presentSeatingListModal();
      }
    }
  }

  /**
   * Present seating list modal
   */
  async presentSeatingListModal() {
    if (this.checkPrivilege(this.module, 'seating')) {
      const modal = await this.modalController.create({
        component: SeatingListComponent,
        cssClass: '',
        componentProps: {
          assignMode: true,
          newGuestList: [ this.guest ]
        }
      });
      modal.present();
    }
  }

  /**
   * Present guest log modal
   */
  async presentGuestLogModal() {
    const modal = await this.modalController.create({
      component: GuestLogComponent,
      componentProps: {
        guestId: this.guest?.guestId,
        groupId: this.group?.groupId,
        type: 'checkin'
      }
    });
    modal.present();
  }

  /**
   * Prompt confirm uncheck modal
   */
  async promptUncheck() {
    const groupIdList: string[] = this.group?.groupId ? [ this.group.groupId ] : [];
    const groupList: Group[] = this.group ? [ this.group ] : [];
    if (this.guest?.guestId) {
      await this.checkinService.uncheck([ this.guest.guestId ], [ this.guest ], groupIdList, groupList, true);
    }
    // this.dismissModal();
  }

  /**
   * Checkin function
   */
  async checkin() {
    const skipGroupCheckin = this.totalCount - this.checkinCount >= 1 ? false : true;
    const groupIdList: string[] = this.group?.groupId ? [ this.group.groupId ] : [];
    const groupList: Group[] = this.group ? [ this.group ] : [];
    if (this.guest?.guestId) {
      await this.checkinService.checkin(this.checkinMethod, [ this.guest.guestId ], [ this.guest ], groupIdList, groupList, true, true, skipGroupCheckin, true);
    }
    // this.dismissModal();
  }

  async checkinGroupMember() {
    const groupList: Group[] = this.group ? [ this.group ] : [];
    const guestIdList = this.group?.memberList?.length ? this.group.memberList : [];
    await this.checkinService.presentCheckinBulkModal(this.checkinMethod, guestIdList, groupList, true);
  }

  /**
   * Save gift status
   * @param gift gift receveid status
   */
  async saveGift(gift: boolean) {
    // this.checkinService.receiveGift(gift, [ this.guest.guestId ], groupIdList, [ this.guest ], groupList, true, false, true, true);
    if (this.guest?.guestId) {
      const groupIdList: string[] = this.group?.groupId ? [ this.group.groupId ] : [];
      const groupList: Group[] = this.group ? [ this.group ] : [];
      // await this.checkinService.receiveGift(gift, [ this.guest.guestId ], [], [ this.guest ], [], true, false, true, true);
      await this.checkinService.receiveGift(this.checkinMethod, gift, [ this.guest.guestId ], groupIdList, [ this.guest ], groupList, true, false, true, true);
    }
    // this.dismissModal();
  }

}
