import { GiftSummaryComponent } from './gift-summary/gift-summary.component';
import { GiftStatisticComponent } from './gift-statistic/gift-statistic.component';
import { GiftGroupComponent } from 'src/app/components/group/gift-group/gift-group.component';
import { GiftGuestProfileComponent } from './gift-guest-profile/gift-guest-profile.component';
import { GiftListComponent } from './gift-list/gift-list.component';
import { BulkActionComponentsModule } from 'src/app/components/general/bulk-action/bulk-action.components.module';
import { GuestComponentsModule } from 'src/app/components/guest/guest.components.module';
import { GiftProfileComponent } from './gift-profile/gift-profile.component';
import { GiftItemComponent } from 'src/app/components/gift/gift-item/gift-item.component';

import { GiftManageComponent } from './gift-manage/gift-manage.component';
import { GiftStatisticItemComponent } from './gift-statistic-item/gift-statistic-item.component';

import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { OfflineComponentsModule } from 'src/app/components/general/offline/offline.components.module';
import { GiftNewComponent } from './gift-new/gift-new.component';
import { SearchComponentsModule } from 'src/app/components/general/search/search.components.module';
import { SelectAllComponentsModule } from 'src/app/components/general/select-all/select-all.components.module';
import { NgxFitTextModule } from '@pikselin/ngx-fittext';
// import { SwiperModule } from 'swiper/angular';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { InboxComponentsModule } from '../inbox/inbox.components.module';
// import { Ng2FittextModule } from 'ng2-fittext';

@NgModule({
    declarations: [
        GiftNewComponent,
        GiftManageComponent,
        GiftStatisticComponent,
        GiftStatisticItemComponent,
        GiftItemComponent,
        GiftListComponent,
        GiftProfileComponent,
        GiftSummaryComponent,
        GiftGroupComponent,
        GiftGuestProfileComponent,
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        // Ng2FittextModule,
        NgxFitTextModule,
        // SwiperModule,
        OfflineComponentsModule,
        // IonicSelectableModule,
        GuestComponentsModule,
        BulkActionComponentsModule,
        SelectAllComponentsModule,
        SearchComponentsModule,
        TranslateModule,
        InboxComponentsModule,
        ScrollingModule,
        ExperimentalScrollingModule,
    ],
    exports: [
        GiftNewComponent,
        GiftManageComponent,
        GiftStatisticComponent,
        GiftStatisticItemComponent,
        GiftItemComponent,
        GiftListComponent,
        GiftProfileComponent,
        GiftSummaryComponent,
        GiftGroupComponent,
        GiftGuestProfileComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
})
export class GiftComponentsModule { }
