import { SessionService } from 'src/app/services/setting/session.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';

import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { InvitedByService } from 'src/app/services/setting/invited-by.service';
import { CategoryService } from 'src/app/services/setting/category.service';
import { SpecialReqService } from 'src/app/services/setting/special-req.service';
import { DietaryReqService } from 'src/app/services/setting/dietary-req.service';

import { SettingField } from 'src/app/interfaces/database';
import { ModuleType, SettingFieldType } from 'src/app/types/general';
import { GuestStatisticType } from 'src/app/types/statistic';
// import Swiper, { SwiperOptions } from 'swiper';
import { SettingFieldComponent } from 'src/app/components/setting/setting-field/setting-field.component';
import { AttendingType, CheckinType, GiftReceivedType, GroupType, QrcodeType, SeatingType } from 'src/app/types/guest';
import { FunctionService } from 'src/app/services/general/function.service';
import Swiper from 'swiper';
import { CurrentPrivilege } from 'src/app/interfaces/privilege';

/**
 * Guest statistic component.
 */
@Component({
  selector: 'app-guest-statistic',
  templateUrl: './guest-statistic.component.html',
  styleUrls: ['./guest-statistic.component.scss'],
})
export class GuestStatisticComponent implements OnInit, OnDestroy {
  /**
   * Content for scroll to top
   */
  @ViewChild('content') private content: IonContent;

  module: ModuleType;
  headerSwiper: Swiper;
  // contentSwiper: Swiper;

  listType: GuestStatisticType;
  /**
   * Statistic type
   */
  statisticType: GuestStatisticType;
  /**
   * Attending type
   */
  attendingType: AttendingType;
  /**
   * Seating type
   */
  seatingType: SeatingType;
  /**
   * QR code type
   */
  qrcodeType: QrcodeType;
  /**
   * Group type
   */
  groupType: GroupType;
  /**
   * Checkin type
   */
  checkinType: CheckinType;
  /**
   * Gift received type
   */
  giftReceivedType: GiftReceivedType;
  /**
   * Slider config for category / dietary request / special request
   */
  // sliderConfig: SwiperOptions;
  // /**
  //  * Slider config for attending type
  //  */
  // attendingSliderConfig: SwiperOptions;
  // /**
  //  * Slider config for seating type
  //  */
  // seatingSliderConfig: SwiperOptions;
  // /**
  //  * Slider config for QR code type
  //  */
  // qrcodeSliderConfig: SwiperOptions;
  // /**
  //  * Slider config for group type
  //  */
  // groupSliderConfig: SwiperOptions;
  // /**
  //  * Slider config for checkin
  //  */
  // checkinSliderConfig: SwiperOptions;
  // /**
  //  * Slider config for gift received
  //  */
  // giftReceivedSliderConfig: SwiperOptions;
  /**
   * Invited By
   */
  invitedBy: SettingField | string;
  /**
   * Invited by list
   */
  invitedByList: SettingField[];
  /**
   * Category list
   */
  categoryList: SettingField[];
  /**
   * Dietary request list
   */
  dietaryReqList: SettingField[];
  /**
   * Special request list
   */
  specialReqList: SettingField[];
  sessionList: SettingField[];

  currentPrivilege: CurrentPrivilege;

  swipeEnable: boolean;
  /**
   * Invited by subscription
   */
  private invitedBySubscription: Subscription;
  /**
   * Category list subscription
   */
  private categoryListSubscription: Subscription;
  /**
   * Dietary request list subscription
   */
  private dietaryReqListSubscription: Subscription;
  /**
   * Special request list subscription
   */
  private specialReqListSubscription: Subscription;

  private sessionListSubscription: Subscription;

  private privilegeSubscription: Subscription;

  /**
   * Constructor
   * @param ngZone ng zone
   * @param modalController modal controller
   * @param invitedByService invited by service
   * @param categoryService category service
   * @param dietaryReqService dietary request service
   * @param specialReqService special request service
   */
  constructor(
    private ngZone: NgZone,
    private modalController: ModalController,
    private moduleService: ModuleService,
    private invitedByService: InvitedByService,
    private categoryService: CategoryService,
    private dietaryReqService: DietaryReqService,
    private specialReqService: SpecialReqService,
    private sessionService: SessionService,
    private privilegeService: PrivilegeService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {
    this.unwatch();
  }

  /**
   * Start watching and setup slider
   */
  async ionViewWillEnter() {
    if (!this.module) {
      this.module = this.moduleService.currentModule;
    }
    if (!this.invitedBy) {
      this.invitedBy = '';
    }
    
    this.watch();

    this.setupSlider();
    this.swipeEnable = this.checkSwipe();
  }

  /**
   * Unwatch before view leave
   */
  ionViewWillLeave() {
    this.unwatch();
  }

  watch() {
    this.watchInvitedBy();
    this.watchCategory();
    this.watchDietaryReq();
    this.watchSpecialReq();
    this.watchSession();
    this.watchPrivilege();
  }

  unwatch() {
    this.unwatchInvitedBy();
    this.unwatchCategory();
    this.unwatchDietaryReq();
    this.unwatchSpecialReq();
    this.unwatchSession();
    this.unwatchPrivilege();
  }

  /**
   * Setup slider config
   */
  async setupSlider() {
    if (this.statisticType === 'dietaryReq') {
      this.listType = 'dietaryReq'
    } else if (this.statisticType === 'specialReq') {
      this.listType = 'specialReq'
    } else if (this.statisticType === 'session') {
      this.listType = 'session';
    } else {
      this.listType = 'category';
    }
    this.setupHeaderSlider();

  }

  setupHeaderSlider() {
    if (this.headerSwiper) {
      if (this.statisticType === 'attending') {
        if (this.attendingType === 'attending') {
          // this.attendingSliderConfig.initialSlide = 0;
          this.headerSwiper.activeIndex = 0;
        } else if (this.attendingType === 'pending') {
          this.headerSwiper.activeIndex = 1;
        } else if (this.attendingType === 'not_attending') {
          this.headerSwiper.activeIndex = 2;
        }
  
      } else if (this.statisticType === 'seating') {
        if (this.seatingType === 'assigned') {
          this.headerSwiper.activeIndex = 0;
        } else if (this.seatingType === 'not_assign') {
          this.headerSwiper.activeIndex = 1;
        }
  
      } else if (this.statisticType === 'qrcode') {
        if (this.qrcodeType === 'sent') {
          this.headerSwiper.activeIndex = 0;
        } else if (this.qrcodeType === 'not_send') {
          this.headerSwiper.activeIndex = 1;
        }
  
      } else if (this.statisticType === 'group') {
        if (this.groupType === 'group') {
          this.headerSwiper.activeIndex = 0;
        } else if (this.groupType === 'individual') {
          this.headerSwiper.activeIndex = 1;
        }
  
      } else if (this.statisticType === 'checkin') {
        if (this.checkinType === 'attended') {
          this.headerSwiper.activeIndex = 0;
        } else if (this.checkinType === 'not_attend') {
          this.headerSwiper.activeIndex = 1;
        }
  
      } else if (this.statisticType === 'giftReceived') {
        if (this.giftReceivedType === 'received') {
          this.headerSwiper.activeIndex = 0;
        }
        // else if (this.giftReceivedType === 'not_receive') {
        //   this.giftReceivedSliderConfig.initialSlide = 1;
        // }
      }

    } else {
      setTimeout(() => {
        this.setupHeaderSlider();
      }, 500);
    }
  }

  // setHeaderSwiper(swiper: Swiper) {
  //   this.headerSwiper = swiper;
  // }

  // setContentSwiper(swiper: Swiper) {
  //   this.contentSwiper = swiper;
  //   this.contentSwiperChange();
  // }

  /**
   * Get statistic type on slide changed - category / dietary request / special request.
   */
  // async contentSwiperChange() {
  //   const index = this.contentSwiper?.activeIndex;
  //   this.ngZone.run(() => {
  //     if (index === 0) {
  //       this.listType = 'category';
  //     } else if (index === 1) {
  //       this.listType = 'dietaryReq';
  //     } else if (index === 2) {
  //       this.listType = 'specialReq';
  //     } else if (index === 3) {
  //       this.listType = 'session';
  //     }
  //   });
  //   this.scrollToTop();
  // }

  // /**
  //  * Get attending type on slide changed
  //  */
  // async attendingSlideChange() {
  //   const index = this.headerSwiper?.activeIndex;
  //   this.ngZone.run(() => {
  //     if (index === 0) {
  //       this.attendingType = 'attending';
  //     } else if (index === 1) {
  //       this.attendingType = 'pending';
  //     } else if (index === 2) {
  //       this.attendingType = 'not_attending';
  //     }
  //   });
  // }

  // /**
  //  * Get seating type on slide changed
  //  */
  // async seatingSlideChange() {
  //   const index = this.headerSwiper?.activeIndex;
  //   this.ngZone.run(() => {
  //     if (index === 0) {
  //       this.seatingType = 'assigned';
  //     } else if (index === 1) {
  //       this.seatingType = 'not_assign';
  //     }
  //   });
  // }

  // /**
  //  * Get QR code type on slide changed
  //  */
  // async qrcodeSlideChange() {
  //   const index = this.headerSwiper?.activeIndex;
  //   this.ngZone.run(() => {
  //     if (index === 0) {
  //       this.qrcodeType = 'sent';
  //     } else if (index === 1) {
  //       this.qrcodeType = 'not_send';
  //     }
  //   });
  // }

  // /**
  //  * Get group type on slide changed
  //  */
  // async groupSlideChange() {
  //   const index = this.headerSwiper?.activeIndex;
  //   this.ngZone.run(() => {
  //     if (index === 0) {
  //       this.groupType = 'group';
  //     } else if (index === 1) {
  //       this.groupType = 'individual';
  //     }
  //   });
  // }

  // /**
  //  * Checkin slide change
  //  */
  // async checkinSlideChange() {
  //   const index = this.headerSwiper?.activeIndex;
  //   this.ngZone.run(() => {
  //     if (index === 0) {
  //       this.checkinType = 'attended';
  //     } else if (index === 1) {
  //       this.checkinType = 'not_attend';
  //     }
  //   });
  // }

  // async giftReceivedSlideChange() {
  //   const index = this.headerSwiper?.activeIndex;
  //   this.ngZone.run(() => {
  //     if (index === 0) {
  //       this.giftReceivedType = 'received';
  //     } else if (index === 1) {
  //       this.giftReceivedType = 'not_receive';
  //     }
  //   });
  // }

  setupHeaderSwiper(event: any) {
    // this.headerSwiper = event;
    if (event?.target?.swiper) {
      this.headerSwiper = event.target.swiper;
    }
  }

  onHeaderSlideChange(event: any) {
    if (this.statisticType && !this.functionService.isUndefined(event?.detail?.[0]?.activeIndex)) {
      const index = event?.detail?.[0]?.activeIndex;
      this.ngZone.run(() => {
        if (this.statisticType === 'attending') {
          if (index === 0) {
            this.attendingType = 'attending';
          } else if (index === 1) {
            this.attendingType = 'pending';
          } else if (index === 2) {
            this.attendingType = 'not_attending';
          }
        } else if (this.statisticType === 'seating') {
          if (index === 0) {
            this.seatingType = 'assigned';
          } else if (index === 1) {
            this.seatingType = 'not_assign';
          }
        } else if (this.statisticType === 'qrcode') {
          if (index === 0) {
            this.qrcodeType = 'sent';
          } else if (index === 1) {
            this.qrcodeType = 'not_send';
          }
        } else if (this.statisticType === 'group') {
          if (index === 0) {
            this.groupType = 'group';
          } else if (index === 1) {
            this.groupType = 'individual';
          }
        } else if (this.statisticType === 'checkin') {
          if (index === 0) {
            this.checkinType = 'attended';
          } else if (index === 1) {
            this.checkinType = 'not_attend';
          }
        } else if (this.statisticType === 'giftReceived') {
          if (index === 0) {
            this.giftReceivedType = 'received';
          } else if (index === 1) {
            this.giftReceivedType = 'not_receive';
          }
        }
        this.scrollToTop();
      });
    }
  }

  contentSwiperChange(event: any) {
    if (!this.functionService.isUndefined(event?.detail?.[0]?.activeIndex)) {
      const index = event?.detail?.[0]?.activeIndex;
      this.ngZone.run(() => {
        if (index === 0) {
          this.listType = 'category';
        } else if (index === 1) {
          this.listType = 'dietaryReq';
        } else if (index === 2) {
          this.listType = 'specialReq';
        } else if (index === 3) {
          this.listType = 'session';
        }
      });
      this.scrollToTop();
    }
  }

  /**
   * Check user privilege
   * @param action Action
   */
  checkPrivilege(module: ModuleType, action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege(module, action);
  }

  /**
   * Watch invited by changes
   */
  async watchInvitedBy() {
    if (!this.invitedBySubscription) {
      this.invitedBySubscription = this.invitedByService.observableInvitedbyList.subscribe((invitedByList: SettingField[]) => {
        this.invitedByList = this.invitedByService.getInvitedByList();
      });
    }
    
  }

  /**
   * Unwatch invited by
   */
  async unwatchInvitedBy() {
    if (this.invitedBySubscription) {
      this.invitedBySubscription.unsubscribe();
      this.invitedBySubscription = null;
    }
  }

  /**
   * Watch category
   */
  async watchCategory() {
    if (!this.categoryListSubscription) {
      this.categoryListSubscription = this.categoryService.observableCategoryList.subscribe((categoryList: SettingField[]) => {
        this.categoryList = this.categoryService.getCategoryList();
      });
    }
    
  }

  /**
   * Unwatch category
   */
  async unwatchCategory() {
    if (this.categoryListSubscription) {
      this.categoryListSubscription.unsubscribe();
      this.categoryListSubscription = null;
    }
  }

  /**
   * Watch dietary request
   */
  async watchDietaryReq() {
    if (!this.dietaryReqListSubscription) {
      this.dietaryReqListSubscription = this.dietaryReqService.observableDietaryReqList.subscribe((dietaryReqList: SettingField[]) => {
        this.dietaryReqList = this.dietaryReqService.getDietaryReqList();
      });
    }
    
  }

  /**
   * Unwatch dietary request
   */
  async unwatchDietaryReq() {
    if (this.dietaryReqListSubscription) {
      this.dietaryReqListSubscription.unsubscribe();
      this.dietaryReqListSubscription = null;
    }
  }

  /**
   * Watch special request
   */
  async watchSpecialReq() {
    if (!this.specialReqListSubscription) {
      this.specialReqListSubscription = this.specialReqService.observableSpecialReqList.subscribe((specialReqList: SettingField[]) => {
        this.specialReqList = this.specialReqService.getSpecialReqList();
      });
    }
    
  }

  /**
   * Unwatch special request
   */
  async unwatchSpecialReq() {
    if (this.specialReqListSubscription) {
      this.specialReqListSubscription.unsubscribe();
      this.specialReqListSubscription = null;
    }
  }

  /**
   * Watch special request
   */
  async watchSession() {
    if (!this.sessionListSubscription) {
      this.sessionListSubscription = this.sessionService.observable.subscribe((sessionList: SettingField[]) => {
        this.sessionList = sessionList;
      });
    }
    
  }

  /**
   * Unwatch special request
   */
  async unwatchSession() {
    if (this.sessionListSubscription) {
      this.sessionListSubscription.unsubscribe();
      this.sessionListSubscription = null;
    }
  }

  async watchPrivilege() {
    if (!this.privilegeSubscription) {
      this.privilegeSubscription = this.privilegeService.observableCurrentPrivilege.subscribe(() => {
        this.setupPrivilege();
      })
    }
  }

  async unwatchPrivilege() {
    if (this.privilegeSubscription) {
      this.privilegeSubscription.unsubscribe();
      this.privilegeSubscription = null;
    }
  }

  setupPrivilege() {
    if (!this.currentPrivilege) {
      this.currentPrivilege = {};
    }
    this.currentPrivilege = {
      'account': {
        'setting': this.checkPrivilege('account', 'setting'),
      }
    };
  }

  checkSwipe(): boolean {
    if (this.statisticType !== 'category' && this.statisticType !== 'dietaryReq' && this.statisticType !== 'specialReq' && this.statisticType !== 'session') {
      return true;
    }
    return false;
  }

  /**
   * Open setting
   */
  openSetting() {
    if (this.statisticType) {
      if (this.statisticType === 'invitedBy') {
        this.presentSettingFieldModal('invited_by', true);
      } else if (this.statisticType === 'category') {
        this.presentSettingFieldModal('category', true);
      } else if (this.statisticType === 'dietaryReq') {
        this.presentSettingFieldModal('dietary_req', true);
      } else if (this.statisticType === 'specialReq') {
        this.presentSettingFieldModal('special_req', true);
      } else if (this.statisticType === 'session') {
        this.presentSettingFieldModal('session', true);
      }
    }
  }

  /**
   * Next slide
   */
  nextSlide() {
    // this.contentSwiper?.slideNext();
  }

  // /**
  //  * Previous slide
  //  */
  prevSlide() {
    // this.contentSwiper?.slidePrev();
  }

  /**
   * Dismiss guest statistic modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  /**
   * Present setting field cmodal
   * @param settingFieldType Setting field type
   * @param settingMode Setting mode
   */
   async presentSettingFieldModal(settingFieldType: SettingFieldType, settingMode: boolean) {
    const modal = await this.modalController.create({
      component: SettingFieldComponent,
      componentProps: {
        settingMode,
        settingFieldType
      }
    });
    modal.present();
  }

  /**
   * Scroll to top
   * @param time scroll animation duration
   */
  scrollToTop(time?: number) {
    this.content?.scrollToTop(time ? time : 0);
  }

  /**
   * Track setting field item
   * @param index Index
   * @param item item
   */
  trackByFn(index: number, item: SettingField) {
    if (item?.id) {
      return item.id;
    } else if (item?.value) {
      return item.value;
    }
    return index;
  }

}
