import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { InboxCountComponent } from './inbox-count/inbox-count.component';

@NgModule({
    declarations: [
      InboxCountComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
    ],
    exports: [
        InboxCountComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class InboxComponentsModule { }
