import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-term',
  templateUrl: './term.component.html',
  styleUrls: ['./term.component.scss'],
})
export class TermComponent implements OnInit, OnDestroy {

  /**
   * page mode
   */
  @Input() pageMode: boolean;

  @Output() back = new EventEmitter<boolean>();

  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
  }

  goBack() {
    if (this.pageMode) {
      this.back.next(true);
    } else {
      this.dismissModal();
    }
  }

  /**
   * Dismiss modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { await modal.dismiss(); }
    }
  }

}
