import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { getTime, set } from 'date-fns';

import { AccountRoleService } from 'src/app/services/account/account-role.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { DatetimeComponent } from 'src/app/components/general/datetime/datetime.component';
import { DateTimeService } from 'src/app/services/general/date-time.service';

/**
 * Account filter component
 */
@Component({
  selector: 'app-accounts-filter',
  templateUrl: './accounts-filter.component.html',
  styleUrls: ['./accounts-filter.component.scss'],
})
export class AccountsFilterComponent implements OnInit, OnDestroy {

  /**
   * Fitler form
   */
  filterForm: FormGroup;
  /**
   * Role List
   */
  roleList: string[] = this.accountRoleService.stdRoleList;
  /**
   * Filter
   */
  private filter: any;

  /**
   * Constructor
   * @param platform platform
   * @param formBuilder form builder
   * @param modalController modal controller
   * @param AccountRoleService role service
   * @param functionService function service
   */
  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private accountRoleService: AccountRoleService,
    private dateTimeService: DateTimeService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    
  }

  /**
   * Before view enter
   */
  ionViewWillEnter() {
    this.initialize();
  }

  initialize() {
    this.roleList = [ ...this.accountRoleService.stdRoleList ];
    this.roleList.push('owner', 'groom', 'bride');
    this.roleList.sort((a: string, b: string) => {
      return this.functionService.compare(a?.toString()?.toLowerCase(), b?.toString()?.toLowerCase());
    });
    this.setupForm();
  }

  /**
   * Setup form
   */
  setupForm() {
    this.filterForm = this.formBuilder.group({
      type: new FormControl('', []),
      startDate: new FormControl('', []),
      endDate: new FormControl('', []),
      role: new FormControl('', [])
    });
    this.setupFilter(this.filter);
  }

  /**
   * Setup filter
   * @param filter Filter
   */
  async setupFilter(filter: any) {
    if (filter) {
      if (filter.type) {
        this.filterForm.controls.type.setValue(filter.type);
      }
      if (filter.startDate) {
        this.filterForm.controls.startDate.setValue(this.dateTimeService.formatISO(filter.startDate));
      }
      if (filter.endDate) {
        this.filterForm.controls.endDate.setValue(this.dateTimeService.formatISO(filter.endDate));
      }
      if (filter.role) {
        this.filterForm.controls.role.setValue(filter.role);
      }
    }
  }

  /**
   * Check is form emtpy
   * @returns true if form is empty
   */
  isEmptyForm(): boolean {
    let empty = true;
    if (this.filterForm?.value) {
      Object?.keys(this.filterForm?.value)?.forEach((key: string) => {
        if (key && this.filterForm.value[key] && empty) {
          empty = false;
        }
      });
    }
    return empty;
  }

  /**
   * Reset form value
   */
  reset() {
    this.filterForm.reset();
  }

  /**
   * Dismiss account user modal
   * @param uid User UID
   */
  async dismissModal(filter?: any) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ filter }); }
    }
  }

  /**
   * Present date time picker modal
   */
  async presentDatetimeModal(type: string) {
    if (type) {
      const modal = await this.modalController.create({
        component: DatetimeComponent,
        cssClass: 'modal-transparent',
        componentProps: {
          time: this.dateTimeService.formatIsoByTimestamp(this.filterForm?.value?.[type]),
        }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result?.data?.timestamp?.seconds) {
          this.filterForm.controls[type].setValue(result.data.timestamp.seconds);
        }
      });
    }
  }

  /**
   * Fitler form submit
   */
  filterFormSubmit() {
    this.filterForm.markAllAsTouched();
    if (this.filterForm.valid) {
      const filter = this.filterForm.value;
      if (filter.startDate) {
        filter.startDate = getTime(set(new Date(filter.startDate), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }));
      }

      if (filter.endDate) {
        filter.endDate = getTime(set(new Date(filter.endDate), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 }));
      }

      Object?.keys(this.filterForm.value)?.forEach(key => {
        if (key && this.filterForm?.value?.[key]) { filter.enable = true; }
      });

      this.dismissModal(filter);
    }
  }

  format(timestamp: number, dateTimeFormat: string, timeZone?: string) {
    return this.dateTimeService.format(timestamp, dateTimeFormat, '', timeZone);
  }

}
