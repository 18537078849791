/**
 * Preloaded standard invited by list
 */
export const StdInvitedByList: string[] = [
    'bride',
    'groom',
    'mutual',
    'bride_parent',
    'groom_parent',
];

export const DefaultInvitedByList: string[] = [
    'bride',
    'groom',
];

export const StdEventInvitedByList: string[] = [
    'internal',
];

export const DefaultEventInvitedByList: string[] = [
    'internal',
];