import { QrcodeTemplate } from '../interfaces/database';

/**
 * Qr Template list
 */
export const QrcodeTemplateList: QrcodeTemplate[] = [
    {
        name: {
            value: 'en',
            custom: false
        },
        msg: 'Hi [GUEST_NAME], I would like to request the honour of your presence at our wedding.\n\nPlease save your guest QR code on the link below and present at the reception for a smoother check-in.\n\nLooking forward to your attendance.\nThank you very much.\n\n[GUEST_URL]'
    },
    {
        name: {
            value: 'ms',
            custom: false
        },
        msg: 'Hai [GUEST_NAME], saya ingin memohon penghormatan untuk kehadiran anda di majlis perkahwinan kami.\n\nSila simpan kod QR tetamu anda pada pautan di bawah dan tunjukkan di kaunter penyambut tetamu untuk daftar masuk yang lebih lancar.\n\nNantikan kehadiran anda.\nTerima kasih banyak.\n\n[GUEST_URL]'
    },
    {
        name: {
            value: 'zh',
            custom: false
        },
        msg: '[GUEST_NAME] 您好, 诚邀您出席我们的婚礼。\n\n请通过以下链接保存宾客 QR code，并在接待处出示您的 QR code，以快速签到和查询桌位。\n\n期待您的出席，非常感谢。\n\n[GUEST_URL]'
    },
    {
        name: {
            value: 'zh-TW',
            custom: false
        },
        msg: '[GUEST_NAME] 您好 , 誠邀您出席我們的婚禮。\n\n請通過以下連結保存賓客 QR code，並在接待處出示您的 QR code，以快速簽到和查詢桌位。\n\n期待您的出席，非常感謝。\n\n[GUEST_URL]'
    }
];

