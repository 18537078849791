
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonList, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { GiftService } from 'src/app/services/gift/gift.service';
import { GiftDeleteService } from 'src/app/services/gift/gift-delete.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GroupService } from 'src/app/services/group/group.service';
import { SeatingService } from 'src/app/services/seating/seating.service';
import { CheckinSettingService } from 'src/app/services/checkin/checkin-setting.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { GiftManageComponent } from 'src/app/components/gift/gift-manage/gift-manage.component';
import { GuestGroupComponent } from 'src/app/components/group/guest-group/guest-group.component';
import { GuestLogComponent } from 'src/app/components/log/guest-log/guest-log.component';
import { SeatingDetailComponent } from 'src/app/components/seating/seating-detail/seating-detail.component';

import { Guest } from 'src/app/interfaces/guest';
import { Seating } from 'src/app/interfaces/seating';
import { CheckinSetting } from 'src/app/interfaces/setting';
import { Gift } from 'src/app/interfaces/gift';
import { Group } from 'src/app/interfaces/group';
import { ModuleType, SettingFieldType } from 'src/app/types/general';
import { SettingField } from 'src/app/interfaces/database';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

/**
 * Gift guest profile component
 */
@Component({
  selector: 'app-gift-guest-profile',
  templateUrl: './gift-guest-profile.component.html',
  styleUrls: ['./gift-guest-profile.component.scss'],
})
export class GiftGuestProfileComponent implements OnInit, OnDestroy {

  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  /**
   * Ion list for closing opened item
   */
  @ViewChild('list') list: IonList;

  /**
   * Gift modal
   */
  giftModal: boolean;
  /**
   * Edit mode flag
   */
  editMode: boolean;
  /**
   * Select all flag
   */
  selectAll: boolean;
  /**
   * Selected gift list
   */
  selectedGiftList: string[];
  /**
   * Checkin setting
   */
  checkinSetting: CheckinSetting;
  guestSeating: Seating;
  /**
   * Guest
   */
  guest: Guest;
  /**
   * Guest group
   */
  group: Group;
  /**
   * Gift List
   */
  giftList: Gift[];
  /**
   * AccountModule
   */
  module: ModuleType;

  viewportHeight: number;

  seatingTypeName: string;

  invitedBy: string;
  category: string;
  dietaryReq: string;
  specialReq: string;
  session: string;
  /**
   * Guest group modal
   */
  private groupModal: boolean;
  /**
   * Seating detail flag
   */
  private seatingDetail: boolean;
  /**
   * Guest list subscription
   */
  private guestListSubscription: Subscription;
  /**
   * Group list subscription
   */
  private groupListSubscription: Subscription;
  /**
   * Gift list subscription
   */
  private giftListSubscription: Subscription;
  /**
   * checkin setting subscription
   */
  private checkinSettingSubscription: Subscription;

  /**
   * Constructor
   * @param modalController modal controller
   * @param guestService guest service
   * @param guestManageService guest manage service
   * @param groupService guest group service
   * @param seatingService seating service
   * @param giftService gift service
   * @param giftDeleteService gift delete service
   * @param translate translate service
   * @param popupService popup service
   * @param functionService function service
   */
  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private settingFieldService: SettingFieldService,
    private guestService: GuestService,
    private groupService: GroupService,
    private seatingService: SeatingService,
    private seatingSettingService: SeatingSettingService,
    private giftService: GiftService,
    private giftDeleteService: GiftDeleteService,
    private checkinSettingService: CheckinSettingService,
    private moduleService: ModuleService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unwatch();
  }

  /**
   * Before view enter
   */
  async ionViewWillEnter() {
    if (!this.module) {
      this.module = this.moduleService.currentModule;
    }
    this.selectedGiftList = [];
    this.seatingTypeName = this.getSeatingTypeName();
    this.watch();
    this.setupGift();
  }

  /**
   * Before view leave
   */
  ionViewDidLeave() {
    this.unwatch();
  }

  watch() {
    this.watchCheckinSetting();
    this.watchGuest();
    this.watchGroupList();
    this.watchGiftList();
  }

  unwatch() {
    this.unwatchCheckinSetting();
    this.unwatchGuest();
    this.unwatchGroupList();
    this.unwatchGiftList();
  }

  /**
   * Watch guest list
   */
  async watchGuest() {
    if (!this.guestListSubscription) {
      this.guestListSubscription = this.guestService.observableGuestList.subscribe(() => {
        this.setupGuest();
      });
    }
  }

  /**
   * Unwatch guest list
   */
  async unwatchGuest() {
    if (this.guestListSubscription) {
      this.guestListSubscription.unsubscribe();
      this.guestListSubscription = null;
    }
  }

  /**
   * Watch group list
   */
  async watchGroupList() {
    if (!this.groupListSubscription) {
      this.groupListSubscription = this.groupService.observableGroupList.subscribe(() => {
        this.setupGroup(this.guest?.groupId);
      });
    }
  }

  /**
   * Unwatch group list
   */
  async unwatchGroupList() {
    if (this.groupListSubscription) {
      this.groupListSubscription.unsubscribe();
      this.groupListSubscription = null;
    }
  }

  /**
   * Watch gift list
   */
  async watchGiftList() {
    if (!this.giftListSubscription) {
      this.giftListSubscription = this.giftService.observableGiftList.subscribe(() => {
        this.setupGift();
      });
    }
  }

  /**
   * Unwatch gift list
   */
  async unwatchGiftList() {
    if (this.giftListSubscription) {
      this.giftListSubscription.unsubscribe();
      this.giftListSubscription = null;
    }
  }

  /**
   * Watch check-in setting
   */
  async watchCheckinSetting() {
    if (!this.checkinSettingSubscription) {
      this.checkinSettingSubscription = this.checkinSettingService.observableCheckinSetting.subscribe((checkinSetting: CheckinSetting) => {
        this.checkinSetting = checkinSetting;
      });
    }
  }

  /**
   * Unwatch check-in setting
   */
  async unwatchCheckinSetting() {
    if (this.checkinSettingSubscription) {
      this.checkinSettingSubscription.unsubscribe();
      this.checkinSettingSubscription = null;
    }
  }

  /**
   * Setup guest
   */
  setupGuest() {
    if (this.guest?.guestId) {
      const guest = this.guestService.getGuest(this.guest.guestId);
      if (guest && !this.functionService.isEqual(this.guest, guest)) {
        if (guest.groupId !== this.guest?.groupId) {
          this.setupGroup(guest.groupId);
        }
        this.guest = guest;
        this.guestSeating = this.getSeatingByName(guest?.seating);
        this.setupGift();
      }
      this.setupSettingField();
    }
  }

  /**
   * Setup group
   * @param groupId Group id
   */
  setupGroup(groupId: string) {
    if (this.guest?.groupId) {
      const group = this.groupService.getGroup(groupId);
      if (!this.functionService.isEqual(this.group, group)) {
        this.group = group;
      }
    }
  }

  /**
   * Setup gift
   */
  setupGift() {
    this.giftList = this.guest.giftList ? this.giftService.getGiftListById(this.guest.giftList) : [];
    this.setupViewportHeight();
    this.setupViewport();
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport) {
      this.cdkVirtualScrollViewport?.checkViewportSize();
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  setupSettingField() {
    this.invitedBy = this.getSettingField('invited_by', this.guest?.invitedBy);
    this.category = this.getSettingField('category', this.guest?.category);
    this.dietaryReq = this.getSettingField('dietary_req', this.guest?.dietaryReq);
    this.specialReq = this.getSettingField('special_req', this.guest?.specialReq);
    this.session = this.getSettingField('session', this.guest?.session);
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[]): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList, true);
  }

  getSeatingTypeName(): string {
    return this.seatingSettingService.getSeatingTypeName();
  }

  /**
   * Close opened sliding item.
   */
  closeSliding() {
    if (this.list) {
      this.list?.closeSlidingItems();
    }
  }

  /**
   * Toggle edit mode
   */
  toggleEditMode(editMode?: boolean) {
    if (this.functionService.isUndefined(editMode)) {
      this.editMode = !this.editMode;
    } else {
      this.editMode = editMode;
    }
    this.resetSelectAll();
    this.closeSliding();
  }

  /**
   * Reset select all
   */
  resetSelectAll() {
    this.selectAll = false;
    this.selectedGiftList = [];
  }

  /**
   * Toggle select all
   */
  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    this.selectedGiftList = [];
    if (this.selectAll) {
      this.giftList?.forEach((gift: Gift) => {
        this.selectedGiftList.push(gift.giftId);
      });
    }
  }

  /**
   * Check select all
   */
  checkSelectAll() {
    if (this.giftList?.length && this.selectedGiftList?.length === this.giftList.length) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  }

  /**
   * Set selected gift
   * @param giftId gift id
   */
  setSelectedGift(giftId: string) {
    const index = this.selectedGiftList?.indexOf(giftId);
    if (index === -1) {
      this.selectedGiftList.push(giftId);
    } else {
      this.selectedGiftList?.splice(index, 1);
    }
    this.checkSelectAll();
  }

  /**
   * Get seating by name
   * @param seatingName Seating name
   */
  getSeatingByName(seatingName: string): Seating {
    return this.seatingService.getSeatingByName(seatingName);
  }

  /**
   * Dismiss guest profile modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  /**
   * Present guest group modal
   * @param group Guest group
   */
  async presentGroupModal(group: Group) {
    if (group) {
      if (!this.groupModal) {
        const modal = await this.modalController.create({
          component: GuestGroupComponent,
          cssClass: '',
          componentProps: {
            group,
            guest: this.guest
          }
        });
        modal.present();
        modal.onWillDismiss().then((result: any) => {
          if (result && result.data && result.data.guest) {
            this.guest = result.data.guest;
            this.setupSettingField();
          }
        });
      } else {
        this.dismissModal();
      }
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Present seating detail modal
   * @param seatingName seating name
   */
  async presentSeatingDetailModal(seatingName: string) {
    if (this.seatingDetail) {
      this.dismissModal();
    } else {
      if (seatingName) {
        const seating = this.seatingService.getSeatingByName(seatingName);
        const modal = await this.modalController.create({
          component: SeatingDetailComponent,
          cssClass: '',
          componentProps: {
            seating,
            guest: this.guest
          }
        });
        modal.present();
      }
    }
  }

  /**
   * Present gift manage modal
   */
  async presentGiftManageModal() {
    const modal = await this.modalController.create({
      component: GiftManageComponent,
      cssClass: '',
      componentProps: {
        newGiftMode: true,
        disableGuestList: true,
        guestIdList: [ this.guest?.guestId ]
      }
    });
    modal.present();
  }

  /**
   * Present guest log modal
   */
  async presentGuestLogModal() {
    if (this.guest?.guestId) {
      const modal = await this.modalController.create({
        component: GuestLogComponent,
        cssClass: '',
        componentProps: {
          guestId: this.guest?.guestId,
        }
      });
      modal.present();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Prompt remove
   */
  async promptRemove() {
    if (this.editMode) {
      const giftList: Gift[] = this.giftService.getGiftListById(this.selectedGiftList);
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_field_count', {
          field: this.translate.instant('GIFT.lbl.gift'), count: this.selectedGiftList?.length }),
          null, null, null, null, null, null, null, null, null, giftList
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.removeGift();
        }
        this.toggleEditMode(false);
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Save remove gift
   */
  async removeGift() {
    if (this.editMode) {
      await this.popupService.presentLoading();
      await this.giftDeleteService.removeGiftList(this.selectedGiftList);
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Bulk action
   * @param type action type
   */
  async bulkAction(type: string) {
    if (type) {
      if (type === 'add') {
        await this.presentGiftManageModal();
        this.toggleEditMode(false);
      } else {
        this.checkSingleRecord();
        this.promptRemove();
      }
    }
  }

  /**
   * Check if single record then trigger select all
   */
  checkSingleRecord() {
    if (!this.selectAll && this.giftList.length === 1) {
      this.toggleSelectAll();
    }
  }

  setupViewportHeight() {
    this.viewportHeight = this.calculateViewportHeight(this.giftList) + 16;
  }
  calculateViewportHeight(giftList: Gift[]) {
    let height = 10;
    height += giftList?.length ? giftList.length * 87 : 0;
    return height;
  }

  /**
   * Ion virtual scroll track by index
   * @param index index
   * @param item gift item / gift id
   * @returns index
   */
  trackByFn(index: number, item: any) {
    return item ? item : index;
  }

}
