import { OnlineService } from 'src/app/services/general/online.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Platform } from '@ionic/angular';
import { Subscription, Observable, of } from 'rxjs';
import { DeviceService } from 'src/app/services/device/device.service';
import { AppService } from 'src/app/services/general/app.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { UserService } from './user.service';

import { LanguageService } from '../general/language.service';
import { DeviceTokenService } from '../device/device-token.service';
import { DeviceRtdb, UserRtdb } from 'src/app/interfaces/rtdb';
import { TimezoneService } from '../general/timezone.service';

import { User } from 'src/app/interfaces/user';
import { UserRtdbService } from './user-rtdb.service';
import { UserOnlineRtdbUrl } from 'src/app/commons/db';


@Injectable({
  providedIn: 'root'
})
export class OnlineDbService implements OnInit, OnDestroy {
  /**
   * User ID
   */
  uid: string;
  /**
   * Online status
   */
  online: boolean;

  private user: User;

  private userOnline: boolean;
  /**
   * Firestore network status
   */
  private firestoreNetwork: boolean;
  /**
   * User online subscription
   */
  private userStatusSubscription: Subscription;
  private userSubscription: Subscription;
  private onlineSubscription: Subscription;
  private deviceTokenSupscription: Subscription;


  private userOnDisconnect: any;
  private deviceOnDisconnect: any;
  // private userDeviceOnDisconnect: any;

  constructor(
    private platform: Platform,
    private db: AngularFireDatabase,
    private userService: UserService,
    private userRtdbService: UserRtdbService,
    private appService: AppService,
    private onlineService: OnlineService,
    private deviceService: DeviceService,
    private deviceTokenService: DeviceTokenService,
    private timezoneService: TimezoneService,
    private languageService: LanguageService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
    this.initialize();
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy() {
    this.unwatchUser();
    this.unwatchDeviceToken();
    this.unwatchOnline();
    this.unwatchUserStatus();
  }

  async initialize() {
    this.firestoreNetwork = true;
    this.online = true;
    await this.platform.ready();
    this.watchOnline();
    this.watchDeviceToken();
    this.updateOnDisconnect();
  }

  /**
   * Set user UID once authenticated
   * @param uid UID
   */
  async setUID(uid: string) {
    if (uid !== this.uid) {
      this.uid = uid;
      this.setPresence(true, true);
      this.watchUserStatus();
      this.watchUser();
    }
  }

  /**
   * Watch user online status.
   * Update user to online if any device is online
   */
  async watchUserStatus() {
    if (this.uid && !this.userStatusSubscription) {
      const obj = this.getUserOnlineObj(this.uid);
      this.userStatusSubscription = obj.valueChanges().subscribe((doc: any) => {
        if (doc) {
          this.userOnline = doc.online;
          if (!doc.online && this.online) {
            setTimeout(() => {
              if (this.online) { this.setUserPresence(true); }
            }, 500);
          }
        }
      });
    }
  }

  /**
   * Unwatch user status
   */
  async unwatchUserStatus() {
    if (this.userStatusSubscription) {
      this.userStatusSubscription.unsubscribe();
      this.userStatusSubscription = null;
    }
  }

  /**
   * Watch online
   */
  async watchOnline() {
    if (!this.onlineSubscription) {
      this.onlineSubscription = this.onlineService.observableOnline.subscribe((online: boolean) => {
        this.online = online;
      });
    }
  }

  /**
   * Unwatch Online
   */
  async unwatchOnline() {
    if (this.onlineSubscription) {
      this.onlineSubscription.unsubscribe();
      this.onlineSubscription = null;
    }
  }

  async watchDeviceToken() {
    if (!this.deviceTokenSupscription) {
      this.deviceTokenSupscription = this.deviceTokenService.observableToken.subscribe((token: string) => {
        if (token) {
          this.setDevicePresence(this.online);
        }
      });
    }
    
  }

  async unwatchDeviceToken() {
    if (this.deviceTokenSupscription) {
      this.deviceTokenSupscription.unsubscribe();
      this.deviceTokenSupscription = null;
    }
  }

  async watchUser() {
    if (!this.userSubscription) {
      this.userSubscription = this.userService.observableUser.subscribe((user: User) => {
        // if (user?.language !== this.user?.language || user?.timezone?.offset !== this.user?.timezone?.offset) {
        //   this.setDevicePresence(this.online);
        // }
        this.user = user;
      });
    }
  }

  async unwatchUser() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
      this.userSubscription = null;
    }
  }

  /**
   * Set Firestore network status based on online status
   * @param enable Enable
   */
  async setFirestoreNetwork(enable: boolean) {
    if (this.userService?.user?.uid) {
      try {
        if (enable) {
          if (!this.firestoreNetwork) {
            this.firestoreNetwork = true;
            // await this.afs.firestore.enableNetwork();
          }
        } else {
          if (this.firestoreNetwork) {
            this.firestoreNetwork = false;
            // await this.afs.firestore.disableNetwork();
          }
        }
      } catch (err) {
        this.errorService.logError(err);
      }
    }
  }

  /**
   * Update on user away, eg. navigates to a new tab.
   */
  updateOnAway() {
    this.setPresence(false);
    // this.setFirestoreNetwork(false);
    this.onlineService.unwatchFirebase();
  }

  updateOnResume() {
    this.setPresence(true);
    // this.setFirestoreNetwork(true);
    this.onlineService.resetWatchFirebase();
    this.onlineService.checkPendingWrites();
  }

  /**
   * Update on user disconnect, eg. user close the app.
   */
  async updateOnDisconnect() {
    await this.cancelOnDisconnect();
    if (this.uid) {
      const obj = this.getUserOnlineObj(this.uid);
      this.userOnDisconnect = obj.query.ref.onDisconnect().update({
        online: false,
        timestamp: this.timestamp
      });
    }
    if (this.deviceService.uuid) {
      // if (this.uid) {
      //   this.userDeviceOnDisconnect = this.db.object(`database/users/${ this.uid }/devices/${ this.deviceService.uuid }`).query.ref.onDisconnect().update({
      //     online: false,
      //     timestamp: this.timestamp
      //   });
      // }
      const deviceObj = this.getDeviceOnlineObj(this.deviceService.uuid);
      this.deviceOnDisconnect = deviceObj.query.ref.onDisconnect().update({
        online: false,
        timestamp: this.timestamp
      }).then(() => {
        this.online = false;
        this.setFirestoreNetwork(false);
        this.onlineService.unwatchFirebase();
      });
    }
  }

  async cancelOnDisconnect() {
    if (this.userOnDisconnect && this.uid) {
      const obj = this.getUserOnlineObj(this.uid);
      await obj.query.ref.onDisconnect().cancel();
      this.userOnDisconnect = null;
    }

    if (this.deviceService.uuid) {
      // if (this.userDeviceOnDisconnect && this.uid) {
      //   await this.db.object(`database/users/${ this.uid }/devices/${ this.deviceService.uuid }`).query.ref.onDisconnect().cancel();
      //   this.deviceOnDisconnect = null;
      // }
      if (this.deviceOnDisconnect) {
        await this.db.object(`database/devices/${ this.deviceService.uuid }`).query.ref.onDisconnect().cancel();
        this.deviceOnDisconnect = null;
      }
    }
  }

  /**
   * Set online status
   * @param online Online status
   */
  async setPresence(online: boolean, active: boolean = true) {
    this.setUserPresence(online);
    this.setDevicePresence(online, active);
  }

  /**
   * Set User online status
   * @param online Online status
   */
  async setUserPresence(online: boolean) {
    if (this.uid && this.userOnline !== online) {
      const timestamp = this.timestamp;
      const userRtdb: UserRtdb = {
        timezoneOffset: this.timezoneService.getUserTimezone()?.offset,
        uid: this.uid,
      };
      if (this.user?.locality) {
        userRtdb.locality = this.user.locality;
      }
      await this.userRtdbService.updateUserRtdb(this.uid, userRtdb);

      const ref = this.getUserOnlineRef(this.uid);
      await ref.update({ online, timestamp });
      // await this.db.object(`database/users/${ this.uid }`).update(userRtdb).catch((err: any) => {
      //   this.errorService.logError(err);
      // });
    }
  }

  /**
   * Set device online status
   * @param online Online status
   */
  async setDevicePresence(online: boolean, active: boolean = true) {
    if (this.deviceService.uuid) {
      const timestamp = this.timestamp;
      const deviceRtdb: DeviceRtdb = {
        timestamp,
        active,
        uid: this.uid ? this.uid : '',
        platform: this.deviceService.deviceInfo.platform ? this.deviceService.deviceInfo.platform : '',
        operatingSystem: this.deviceService.deviceInfo.operatingSystem ? this.deviceService.deviceInfo.operatingSystem : '',
        appVersion: await this.appService.getAppVersion(),
        token: this.deviceTokenService.token ? this.deviceTokenService.token : '',
        userLanguage: this.languageService.getUserLanguage()?.code,
        uuid: this.deviceService.uuid,
        timezoneOffset: this.timezoneService.getUserTimezone()?.offset,
      };

      await this.userRtdbService.updateDevicesRtdb(this.deviceService.uuid, deviceRtdb);
      // await this.db.object(`database/devices/${ this.deviceService.uuid }/`).update(deviceRtdb).catch((err: any) => {
      //   this.errorService.logError(err);
      // });

      // console.log(this.uid);
      // console.log(this.deviceService.uuid);
      if (this.uid) {
        await this.userRtdbService.updateUserDeviceRtdb(this.uid, this.deviceService.uuid, deviceRtdb);
        // await this.db.object(`database/users/${ this.uid }/devices/${ this.deviceService.uuid }/`).update(deviceRtdb).catch((err: any) => {
        //   this.errorService.logError(err);
        // });
        const ref = this.getDeviceOnlineRef(this.deviceService.uuid);
        await ref.update({ online, timestamp });

        // const userDeviceRef = this.getUserDeviceOnlineRef(this.uid, this.deviceService.uuid);
        // await userDeviceRef.update({ online, timestamp });
      }
    } else {
      await this.functionService.delay(500);
      this.setDevicePresence(online, active);
    }
  }

  /**
   * Signout user
   */
  async signOut() {
    this.online = false;
    await this.onlineService.unwatchFirebase();
    await this.setPresence(false, false);
    await this.setUID('');
  }

  /**
   * Get Firebase realtime database server timestamp
   * @returns Firebase realtime database server timestamp
   */
  get timestamp() {
    return this.functionService.firebaseServerTime;
  }

  /**
   * Get user online status by UID
   * @param uid User UID
   * @returns observable of user online status
   */
  getPresence(uid: string): Observable<any> {
    if (uid) {
      return this.getUserOnlineObj(uid).valueChanges();
    } else {
      return of(null);
    }
  }

  getUserOnlineDb() {
    return this.db.database.app.database(UserOnlineRtdbUrl);
  }

  getUserOnlineRef(uid?: string) {
    const db = this.getUserOnlineDb();
    if (uid) {
      return db.ref(`users/${ uid }`);
    } else {
      return db.ref(`users/`);
    }
  }

  getUserOnlineObj(uid?: string) {
    if (uid) {
      return this.db.object(this.getUserOnlineRef(uid));
    }
    return null;
  }

  getDeviceOnlineRef(uuid?: string) {
    const db = this.getUserOnlineDb();
    if (uuid) {
      return db.ref(`devices/${ uuid }`);
    } else {
      return db.ref(`devices/`);
    }
  }

  getDeviceOnlineObj(uuid?: string) {
    if (uuid) {
      return this.db.object(this.getDeviceOnlineRef(uuid));
    }
    return null;
  }

  // getUserDeviceOnlineRef(uid: string, uuid: string) {
  //   const db = this.getUserOnlineDb();
  //   if (uid && uuid) {
  //     return db.ref(`users/${ uid }/devices/${ uuid }/`);
  //   } else if (uid) {
  //     return this.getUserOnlineRef(uid);
  //   } else if (uuid) {
  //     return this.getDeviceOnlineRef(uuid);
  //   } else {
  //     return this.getUserOnlineRef();
  //   }
  // }

}
