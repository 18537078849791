import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { InboxType } from 'src/app/types/inbox';
import { InboxListService } from './inbox-list.service';
import { Inbox } from 'src/app/interfaces/inbox';
import { SupportCountService } from '../support/support-count.service';

@Injectable({
  providedIn: 'root'
})
export class InboxCountService implements OnInit, OnDestroy {

  constructor(
    private inboxListService: InboxListService,
    private supportCountService: SupportCountService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  getUnreadCount(inboxType?: InboxType): number {
    let count = 0;

    if (!inboxType || inboxType !== 'support') {
      const unreadList: string[] = [];

      const inboxList: Inbox[] = this.inboxListService.getInboxListByType(inboxType);
      inboxList?.forEach((inbox: Inbox) => {
        const inboxStatus = this.inboxListService.getInboxStatusById(inbox.inboxId, inbox.inboxType);
        if (!inboxType || inboxType === 'notification' || inboxType === inbox?.inboxType) {
          if (inbox?.inboxId && !inboxStatus?.deleted && !inboxStatus?.read) {
            unreadList.push(inbox.inboxId);
          }
        }
      });

      count += unreadList?.length ? unreadList.length : 0;
    }

    if (!inboxType || inboxType === 'support') {
      count += this.supportCountService.getSupportCount();
    }
  
    return count;
  }
}
