import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { SeatingService } from 'src/app/services/seating/seating.service';

/**
 * Seating new service
 */
@Injectable({
  providedIn: 'root'
})
export class SeatingNewService implements OnInit, OnDestroy {

  /**
   * Constructor
   * @param seatingService seating service
   */
  constructor(
    private seatingService: SeatingService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Generate new seating
   * @param name Seating name
   * @param order Seating order
   * @param skip4 Skip 4 flag
   * @param replace4 replace 4 flag
   * @param alphabet Running alphabet flag
   * @param firstRecord First record flag
   */
   newSeating(name?: string, order?: number, skip4?: boolean, replace4?: boolean, alphabet?: boolean, firstRecord?: boolean) {
    do {
      order += 1;

      if (!name) {
        name = this.seatingService.getMaxSeatingName(alphabet);
      }
      if (firstRecord) {
        firstRecord = false;
      } else {
        name = this.increaseStringValue(name, alphabet);
      }

      if (replace4) {
        if (name.charAt(name.length - 1) === '4') {
          name = name.slice(0, -1) + '3A';
          if (this.seatingService.checkDuplicateSeatingByName(name)) {
            name = name.slice(0, -2) + '4';
          }
        }
      }

      if (skip4) {
        if (name?.indexOf('4') !== -1) {
          do {
            order += 1;
            name = this.increaseStringValue(name, alphabet);
          } while (name?.indexOf('4') !== -1);
        }
      }
    } while (this.seatingService.checkDuplicateSeatingByName(name));
    return { name, order };
  }

  /**
   * Increate seating name by 1
   * @param name Current seating name
   */
  // increaseNumber(str: any) {
  //   // const text = str;
  //   const getPart = str.replace( /[^\d.]/g, '' ); // returns 0023
  //   console.log(getPart);
  //   const num = Number( getPart ); // returns 23
  //   console.log(num);
  //   const newVal = num + 1; // returns 24
  //   const reg = new RegExp( num.toString() ); // create dynamic regexp
  //   const newstring = str.replace( reg, newVal ); // returns MPG_0024
  //   return newstring;
  // }

  increaseNumber(inputString: any) {
    let numericString = '';
    let nonNumericString = '';
    for (let i = inputString.length - 1; i >= 0; i--) {
      const currentChar = inputString.charAt(i);
      if (!isNaN(parseInt(currentChar))) {
        numericString = currentChar + numericString;
      } else {
        nonNumericString = inputString.substring(0, i + 1);
        break;
      }
    }
    const incrementedNumeric = (parseInt(numericString) + 1).toString();
    const paddingLength = Math.max(0, numericString.length - incrementedNumeric.length);
    const paddedIncrementedNumeric = '0'.repeat(paddingLength) + incrementedNumeric;
    return nonNumericString + paddedIncrementedNumeric;
  }

  /**
   * Increase seating name by next alphabet
   * @param str string
   */
  increaseLetter(str: any) {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz';
    const length = alphabet.length;
    let result = str;
    let i = str.length;
    const value = str;
    let next: any;

    while (i >= 0) {
      const last = str.charAt(--i);
      next = '';
      let carry = false;

      if (isNaN(last)) {
        const index = alphabet?.indexOf(last?.toString().toLowerCase());

        if (index === -1) {
          next = last;
          carry = true;
        } else {
          const isUpperCase = last === last.toUpperCase();
          next = alphabet.charAt((index + 1) % length);
          if (isUpperCase) {
            next = next.toUpperCase();
          }

          carry = index + 1 >= length;
          if (carry && i === 0) {
            const added = isUpperCase ? 'A' : 'a';
            result = added + next + result.slice(1);
            break;
          }
        }
      } else {
        next = +last + 1;
        if (next > 9) {
          next = 0;
          carry = true;
        }

        if (carry && i === 0) {
          result = '1' + next + result.slice(1);
          break;
        }
      }

      result = result.slice(0, i) + next + result.slice(i + 1);
      if (!carry) {
        break;
      }
    }
    return result;
  }

  /**
   * Increase seating name to next value
   * @param str Current Seating name
   * @param alphabet Running alphabet flag
   * @returns Next seating name
   */
  increaseStringValue(str: any, alphabet: boolean): string {
    if (alphabet) {
      const letter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      if (letter?.indexOf(str.slice(-1).toUpperCase()) !== -1) {
        return this.increaseLetter(str);
      } else {
        let index = 0;
        do {
          if (letter[index]) {
            str = str + letter[index];
            index++;
          } else {
            throw new Error('error');
          }
        } while (this.seatingService.checkDuplicateSeatingByName(str));
        return this.increaseLetter(str);
      }
    } else {
      if ('01234567890'.indexOf(str.slice(-1)) !== -1) {
        return this.increaseNumber(str);
      } else {
        let counter = 0;
        const ori = str;
        do {
          counter++;
          str = ori + counter.toString();
        } while (this.seatingService.checkDuplicateSeatingByName(str));
        return str;
        // return this.increaseNumber(str);
      }
    }
  }

  // increaseLastLetter(input: string, increaseAsLetter: boolean, numIncrements = 1, existingList = []) {
  //   let largestChar = '';
  //   let newChar = '';
  //   let result = '';
  //   let inputPattern = '';
  //   let lastChar = '';
  //   let lastCharIsLetter = false;
  //   let lastCharIsNumeric = false;
  
  //   if (input) {
  //     inputPattern = input.match(/[a-zA-Z0-9]+/g)[0];
  //     lastChar = inputPattern.charAt(inputPattern.length - 1);
  //     lastCharIsLetter = isNaN(lastChar);
  //     lastCharIsNumeric = !lastCharIsLetter;
  //   }
  
  //   if (lastCharIsLetter && increaseAsLetter) {
  //     let charCode = lastChar.charCodeAt(0);
  //     newChar = String.fromCharCode(charCode + numIncrements);
  //     if (newChar > 'Z') {
  //       newChar = 'A';
  //       numIncrements--;
  //       if (numIncrements > 0) {
  //         newChar = this.increaseLastLetter(input, true, numIncrements, existingList);
  //       }
  //     }
  //   } else if (lastCharIsNumeric && !increaseAsLetter) {
  //     let num = parseInt(lastChar) + numIncrements;
  //     if (num > 9) {
  //       num = 0;
  //       numIncrements--;
  //       if (numIncrements > 0) {
  //         newChar = this.increaseLastLetter(input, false, numIncrements, existingList);
  //       }
  //     } else {
  //       newChar = num.toString();
  //     }
  //   } else {
  //     let startCharCode = 'A'.charCodeAt(0);
  //     let endCharCode = 'Z'.charCodeAt(0);
  //     let charCode = startCharCode - 1;
  //     if (inputPattern.length > 0) {
  //       largestChar = inputPattern.split('').sort().pop();
  //       charCode = largestChar.charCodeAt(0);
  //       if (charCode >= startCharCode && charCode <= endCharCode) {
  //         newChar = String.fromCharCode(charCode + 1);
  //         while (existingList.includes(newChar)) {
  //           newChar = this.increaseLastLetter(input, true, 1, existingList);
  //         }
  //         if (newChar > 'Z') {
  //           newChar = 'A';
  //           numIncrements--;
  //           if (numIncrements > 0) {
  //             newChar = this.increaseLastLetter(input, true, numIncrements, existingList);
  //           }
  //         }
  //       } else {
  //         let num = parseInt(largestChar) + 1;
  //         if (num > 9) {
  //           num = 1;
  //           newChar = 'A';
  //         } else {
  //           newChar = num.toString();
  //         }
  //       }
  //     } else {
  //       newChar = 'A';
  //     }
  //   }
  
  //   if (lastCharIsLetter && newChar > 'Z') {
  //     let prevChar = String.fromCharCode(lastChar.charCodeAt(0) - 1);
  //     result = this.increaseLastLetter(input.slice(0, -1) + prevChar, increaseAsLetter, numIncrements, existingList);
  //   } else {
  //     result = inputPattern.slice(0, -1) + newChar;
  //     if (numIncrements > 1) {
  //       result = this.increaseLastLetter(result, increaseAsLetter, numIncrements - 1, existingList);
  //     }
  //   }
  
  //   return result;
  // }
  

}
