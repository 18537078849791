import { GuestComponentsModule } from 'src/app/components/guest/guest.components.module';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { OfflineComponentsModule } from 'src/app/components/general/offline/offline.components.module';
import { VisitorGroupComponent } from './visitor-group/visitor-group.component';
import { VisitorPageComponent } from './visitor-page/visitor-page.component';
import { NgxFitTextModule } from '@pikselin/ngx-fittext';
// import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxQrcodeStylingModule } from 'ngx-qrcode-styling';
// import { Ng2FittextModule } from 'ng2-fittext';
// import { SwiperModule } from 'swiper/angular';

@NgModule({
    declarations: [
        VisitorPageComponent,
        VisitorGroupComponent,
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        // Ng2FittextModule,
        NgxFitTextModule,
        // NgxQRCodeModule,
        NgxQrcodeStylingModule,
        GuestComponentsModule,
        OfflineComponentsModule,
        TranslateModule,
        // SwiperModule,
    ],
    exports: [
        VisitorPageComponent,
        VisitorGroupComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class VisitorComponentsModule { }
