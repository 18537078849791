<ion-content>
  <div class="full-height vertical-center">
    <ion-card class="center">
      <ion-card-header class="ion-no-padding">
        <ion-item class="close-item" lines="none">
          <ion-icon class="font-24 vertical-middle" slot="end" color="dark" src="./assets/wedding/icon/general/close.svg" (click)="dismissModal()"></ion-icon>
        </ion-item>
      </ion-card-header>
      <ion-card-content>
        <div class="ion-text-center">
          <ion-text class="font-16">{{ 'BTN.language' | translate }}</ion-text>
        </div>
        <ion-item class="prompt-input-item" [color]="selected && lang?.code === selected ? 'tertiary' : 'white'" *ngFor="let lang of languageList" (click)="select(lang?.code)" lines="none">
          {{ lang.name }}
          <ion-note slot="end" *ngIf="selected && lang?.code === selected">
            <ion-icon class="font-24 vertical-middle" color="success" src="./assets/wedding/icon/general/checkmark-circle.svg"></ion-icon>
          </ion-note>
        </ion-item>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>