/**
 * Preloaded standard seating request list
 */
export const StdSpecialReqList: string[] = [
    'booster_seat',
    'high_chair',
    'wheelchair',
    'none'
];

export const DefaultSpecialReqList: string[] = [
    'none',
];

export const StdEventSpecialReqList: string[] = [
    'wheelchair',
    'none'
];

export const DefaultEventSpecialReqList: string[] = [
    'none',
];