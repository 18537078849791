import { GiftDeleteService } from 'src/app/services/gift/gift-delete.service';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from 'src/app/services/general/popup.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonList, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GroupService } from 'src/app/services/group/group.service';
import { CheckinSettingService } from 'src/app/services/checkin/checkin-setting.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { GuestNewComponent } from 'src/app/components/guest/guest-new/guest-new.component';
import { GiftManageComponent } from 'src/app/components/gift/gift-manage/gift-manage.component';
import { GroupLogComponent } from 'src/app/components/log/group-log/group-log.component';

import { Guest } from 'src/app/interfaces/guest';
import { Group } from 'src/app/interfaces/group';
import { CheckinSetting } from 'src/app/interfaces/setting';
import { GuestListMode } from 'src/app/types/guest';
import { ModuleType } from 'src/app/types/general';
import { Gift } from 'src/app/interfaces/gift';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

/**
 * Gift group component
 */
@Component({
  selector: 'app-gift-group',
  templateUrl: './gift-group.component.html',
  styleUrls: ['./gift-group.component.scss'],
})
export class GiftGroupComponent implements OnInit, OnDestroy {

  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  /**
   * Ion list for close slide item
   */
  @ViewChild('list') list: IonList;

  /**
   * Gift modal
   */
  giftModal: boolean;
  /**
   * AccountModule
   */
  module: ModuleType;
  /**
   * Guest list mode
   */
  mode: GuestListMode;
  /**
   * Edit mode flag, trigger bulk action FAB
   */
  editMode: boolean;
  /**
   * Select all flag
   */
  selectAll: boolean;
  /**
   * Guest group
   */
  group: Group;
  /**
   * Member list
   */
  memberList: Guest[];
  /**
   * Watch group of this guest
   */
  guest: Guest;
  /**
   * Selected gift list
   */
  selectedGiftList: string[];
  /**
   * Checkin setting
   */
  checkinSetting: CheckinSetting;

  viewportHeight: number;
  /**
   * Disable watching group
   */
  private disableGroupWatch: boolean;
  /**
   * Guest list subscription
   */
  private guestListSubscription: Subscription;
  /**
   * Group list subscription
   */
  private groupListSubscription: Subscription;
  /**
   * Checkin setting subscription
   */
  private checkinSettingSubscription: Subscription;

  /**
   * Constructor
   * @param modalController modal controller
   * @param moduleService module service
   * @param guestService guest service
   * @param groupService group service
   * @param checkinSettingService check-in setting service
   * @param functionService function service
   */
  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private moduleService: ModuleService,
    private guestService: GuestService,
    private groupService: GroupService,
    private giftService: GiftService,
    private giftDeleteService: GiftDeleteService,
    private checkinSettingService: CheckinSettingService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
      this.unwatch();
  }

  /**
   * Before view enter
   */
  async ionViewWillEnter() {
    if (!this.module) {
      this.module = this.moduleService.currentModule;
    }
    if (!this.selectedGiftList) { this.selectedGiftList = []; }
    this.watch();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatch();
  }

  watch() {
    if (!this.disableGroupWatch) { this.watchGroupList(); }
    this.watchGuest();
    this.watchCheckinSetting();
  }

  unwatch() {
    this.unwatchGuest();
    this.unwatchGroupList();
    this.unwatchCheckinSetting();
  }

  /**
   * Watch guest
   */
  async watchGuest() {
    if (!this.guestListSubscription) {
      this.guestListSubscription = this.guestService.observableGuestList.subscribe(() => {
        this.setupGuest();
      });
    }
    
  }

  /**
   * Unwatch guest
   */
  async unwatchGuest() {
    if (this.guestListSubscription) {
      this.guestListSubscription.unsubscribe();
      this.guestListSubscription = null;
    }
  }

  /**
   * Watch group list
   */
  async watchGroupList() {
   if (!this.groupListSubscription) {
    this.groupListSubscription = this.groupService.observableGroupList.subscribe(() => {
      this.setupGroup();
    });
   }
    
  }

  /**
   * Unwatch group list
   */
  async unwatchGroupList() {
    if (this.groupListSubscription) {
      this.groupListSubscription.unsubscribe();
      this.groupListSubscription = null;
    }
  }

  /**
   * Watch checkin setting
   */
  async watchCheckinSetting() {
    if (!this.checkinSettingSubscription) {
      this.checkinSettingSubscription = this.checkinSettingService.observableCheckinSetting.subscribe((checkinSetting: CheckinSetting) => {
        this.checkinSetting = checkinSetting;
      });
    }
    
  }

  /**
   * Unwatch checkin setting
   */
  async unwatchCheckinSetting() {
    if (this.checkinSettingSubscription) {
      this.checkinSettingSubscription.unsubscribe();
      this.checkinSettingSubscription = null;
    }
  }

  /**
   * Setup guest of this group
   */
  setupGuest() {
    if (this.guest?.guestId) {
      const guest = this.guestService.getGuest(this.guest.guestId);
      if (guest?.groupId !== this.guest?.groupId) {
        this.setupGroup();
      }
      this.guest = guest;
    }
  }

  /**
   * Setup group
   */
  setupGroup() {
    if (this.guest?.groupId) {
      this.group = this.groupService.getGroup(this.guest.groupId);
    } else if (this.group?.groupId) {
      this.group = this.groupService.getGroup(this.group.groupId);
    }
    if (this.group?.memberList?.length) {
      this.memberList = this.guestService.getGuestListById(this.group.memberList);
    }
    this.setupViewport();
  }

  setupViewportHeight() {
    this.viewportHeight = this.calculateViewportHeight(this.group?.giftList) + 16;
  }

  setupViewport() {
    this.setupViewportHeight();
    if (this.cdkVirtualScrollViewport) {
      this.cdkVirtualScrollViewport?.checkViewportSize();
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  /**
   * Reset select all
   */
  resetSelectAll() {
    this.selectAll = false;
    this.selectedGiftList = [];
  }

  /**
   * Toggle edit mode
   */
  toggleEditMode(editMode?: boolean) {
    if (this.functionService.isUndefined(editMode)) {
      this.editMode = !this.editMode;
    } else {
      this.editMode = editMode;
    }
    this.resetSelectAll();
    this.closeSliding();
  }

  /**
   * Toggle select all
   */
  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    this.selectedGiftList = [];
    if (this.selectAll) {
      this.group.giftList?.forEach((giftId: string) => {
        this.selectedGiftList.push(giftId);
      });
    }
  }

  /**
   * Check select all
   */
  checkSelectAll() {
    if (this.group.giftList.length && this.selectedGiftList?.length === this.group.giftList.length) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  }

  /**
   * Set selected gift
   * @param giftId gift id
   */
  setSelectedGift(giftId: string) {
    const index = this.selectedGiftList?.indexOf(giftId);
    if (index === -1) {
      this.selectedGiftList.push(giftId);
    } else {
      this.selectedGiftList?.splice(index, 1);
    }
    this.checkSelectAll();
  }

  /**
   * Close ion item sliding
   */
  closeSliding() {
    if (this.list) {
      this.list?.closeSlidingItems();
    }
  }

  /**
   * Bulk action
   * @param type Action type
   */
  async bulkAction(type: string) {
    if (type) {
      if (type === 'add') {
        await this.presentGiftManageModal(true);
        this.toggleEditMode(false);
      } else {
        this.checkSingleRecord();
        if (type === 'info') {
          await this.presentGiftManageModal(false, true, this.selectedGiftList);
          this.toggleEditMode(false);
        } else if (type === 'delete') {
          if (this.module === 'trash') {
            this.promptDelete();
          } else {
            this.promptRemove();
          }
        } else if (type === 'restore') {
          this.promptRestore();
        }
      }
    }
  }

  /**
   * Check if single record then trigger select all
   */
   checkSingleRecord() {
    if (!this.selectAll && this.group?.memberList?.length === 1) {
      this.toggleSelectAll();
    }
  }

  /**
   * Dismiss modal
   * @param selectedGiftList Selected gift list
   * @param guest Guest
   */
  async dismissModal(selectedGiftList?: string[], guest?: Guest) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ selectedGiftList, guest }); }
    }
  }

  /**
   * Present add new guest modal
   * @param group Guest group
   */
  async presentGuestNewModal() {
    const defaultValue: any = {};
    if (this.group?.memberList?.length) {
      const groupAdmin = this.guestService.getGuest(this.group.memberList[0]);
      if (groupAdmin) {
        if (groupAdmin?.invitedBy?.length) {
          defaultValue.invitedBy = [ groupAdmin.invitedBy[0] ];
        }
        if (groupAdmin?.category?.length) {
          defaultValue.category = [ groupAdmin.category[0] ];
        }
        if (groupAdmin?.dietaryReq?.length) {
          defaultValue.dietaryReq = [ groupAdmin.dietaryReq[0] ];
        }
      }
    }

    const modal = await this.modalController.create({
      component: GuestNewComponent,
      cssClass: '',
      componentProps: {
        setupGroup: this.group,
        defaultValue
      }
    });
    modal.present();
  }

  /**
   * Present gift manage modal
   * @param newGiftMode new gift mode
   * @param bulkEditMode bulk edit modal
   * @param selectedGiftList selected gift list
   */
   async presentGiftManageModal(newGiftMode?: boolean, bulkEditMode?: boolean, selectedGiftList?: string[]) {
    let gift: Gift;
    if (!newGiftMode) {
      if (this.selectedGiftList?.length === 1) {
        bulkEditMode = false;
        gift = this.giftService.getGiftById(this.selectedGiftList[0]);
      }
    }
    const modal = await this.modalController.create({
      component: GiftManageComponent,
      cssClass: '',
      componentProps: {
        newGiftMode,
        gift,
        bulkEditMode,
        selectedGiftList,
        disableGuestList: true,
        groupIdList: [ this.group.groupId ]
      }
    });
    modal.present();
  }

  async presentGroupLogModal() {
    const modal = await this.modalController.create({
      component: GroupLogComponent,
      componentProps: {
        groupId: this.group?.groupId,
      }
    });
    modal.present();
  }

  /**
   * Prompt confirm delete modal
   */
   async promptRemove() {
    if (this.editMode) {
      const giftList: Gift[] = this.giftService.getGiftListById(this.selectedGiftList);
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_field_count', {
          field: this.translate.instant('GIFT.lbl.gift'), count: this.selectedGiftList?.length }),
          null, null, null, null, null, null, null, null, null, giftList
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.removeGift();
        }
        this.toggleEditMode(false);
      });
    }
  }

  /**
   * Prompt confirm restore modal
   */
  async promptRestore() {
    if (this.editMode) {
      const giftList: Gift[] = this.giftService.getGiftListById(this.selectedGiftList);
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_restore_field', {
          field: this.translate.instant('GIFT.lbl.gift'), count: this.selectedGiftList?.length }),
          null, null, null, null, null, null, null, null, null, giftList
      );
      modal.onDidDismiss().then((result: any) => {
        if (result?.data?.confirm) {
          this.restoreGift();
        }
      });
    }
  }

  /**
   * Prompt confirm restore modal
   */
  async promptDelete() {
    if (this.editMode) {
      const giftList: Gift[] = this.giftService.getGiftListById(this.selectedGiftList);
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_db_field', {
          field: this.translate.instant('GIFT.lbl.gift'), count: this.selectedGiftList?.length }),
          null, null, null, null, null, null, null, null, null, giftList
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.deleteGift();
        }
        this.toggleEditMode(false);
      });
    }
  }

  /**
   * Save remove gift
   */
  async removeGift() {
    if (this.editMode) {
      await this.popupService.presentLoading();
      if (this.selectedGiftList?.length) {
        await this.giftDeleteService.removeGiftList(this.selectedGiftList);
      }
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
  }

  /**
   * Remove gift from db
   */
  async deleteGift() {
    if (this.editMode) {
      await this.popupService.presentLoading();
      if (this.selectedGiftList?.length) {
        await this.giftDeleteService.deleteGiftList(this.selectedGiftList);
      }
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
  }

  /**
   * Save restore guest
   */
  async restoreGift() {
    if (this.editMode) {
      await this.popupService.presentLoading();
      if (this.selectedGiftList?.length) {
        await this.giftDeleteService.restoreGiftList(this.selectedGiftList);
      }
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
  }

  calculateViewportHeight(giftList: string[]) {
    let height = 10;
    height += giftList?.length ? giftList.length * 87 : 0;
    return height;
  }

  /**
   * Ion virtual scroll track by index
   * @param index index
   * @param item gift id
   * @returns index
   */
  trackByFn(index: number, item: any) {
    return item ? item : index;
  }

}
