<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'BTN.preview' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" color="light">
  <div class="ion-padding ion-text-center">
    <div class="center my-img ion-padding">
      <ng-container *ngIf="base64">
        <image-cropper class="background-img" *ngIf="photoType === 'account_gallery'"
          [imageBase64]="imageBase64"
          [format]="format"
          [hideResizeSquares]="hybrid"
          [canvasRotation]="rotation"
          [aspectRatio]="16 / 9"
          output="base64"
          resizeToWidth="2048"
          cropperMinWidth="50"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()">
        </image-cropper>
        <image-cropper class="background-img" *ngIf="photoType === 'user_avatar' || photoType === 'couple1_avatar' || photoType === 'couple2_avatar' || photoType === 'host_avatar'"
          [imageBase64]="imageBase64"
          [format]="format"
          [hideResizeSquares]="hybrid"
          [canvasRotation]="rotation"
          output="base64"
          resizeToWidth="400"
          resizeToHeight="400"
          cropperMinWidth="50"
          cropperMinHeight="50"
          roundCropper="true"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()">
        </image-cropper>
        <image-cropper class="background-img" *ngIf="photoType === 'background_img'"
          [imageBase64]="imageBase64"
          [format]="format"
          [hideResizeSquares]="hybrid"
          [canvasRotation]="rotation"
          [aspectRatio]="9 / 16"
          output="base64"
          resizeToHeight="1024"
          cropperMinHeight="100"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()">
        </image-cropper>
        <image-cropper class="background-img" *ngIf="photoType === 'website_cover'"
          [imageBase64]="imageBase64"
          [format]="format"
          [hideResizeSquares]="hybrid"
          [canvasRotation]="rotation"
          [aspectRatio]="9 / 16"
          output="base64"
          resizeToHeight="2048"
          cropperMinHeight="100"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()">
        </image-cropper>
        <ion-img class="full-width-height background-img" [src]="imageBase64"  *ngIf="photoType === 'support_attachment'"></ion-img>
      </ng-container>
    </div>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button expand="block" color="primary" fill="solid" type="button" (click)="dismissModal(true);">
      {{ 'BTN.confirm' | translate }}
    </ion-button>
    <!-- <ion-grid class="full-width">
      <ion-row>
        <ion-col class="ion-text-left" size="3">
          <ion-button>
            <ion-icon name="arrow-undo-outline" (click)="rotate(false)"></ion-icon>
          </ion-button>
        </ion-col>
        <ion-col class="ion-text-center" size="6">
          <ion-button expand="block" color="primary" fill="solid" type="button" (click)="dismissModal(true);">
            {{ 'BTN.confirm' | translate }}
          </ion-button>
        </ion-col>
        <ion-col class="ion-text-right" size="3">
          <ion-button>
            <ion-icon name="arrow-redo-outline" (click)="rotate(true)"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid> -->
  </ion-toolbar>
</ion-footer>