import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, distinctUntilChanged, firstValueFrom, map } from 'rxjs';

import { ConfigService } from 'src/app/services/general/config.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { UserRole, CoupleList, UserAccounts } from 'src/app/interfaces/account';
import { AccountsListType } from 'src/app/types/accounts';
import { getTime } from 'date-fns';
import { CreditService } from '../subscription/credit.service';
import { UserRtdbService } from '../user/user-rtdb.service';

/**
 * Accounts list service
 */
@Injectable({
  providedIn: 'root'
})
export class AccountsListService implements OnInit, OnDestroy {

  /**
   * User logged account user info list
   */
  // accountUserList: AccountUser[];
  /**
   * User account list
   */
  userAccountsList: UserAccounts[];
  /**
   * Observable user account list
   */
  observableUserAccountsList: any;

  checked: boolean;
  /**
   * UID
   */
  uid: string;

  // accountsListSearchMode: boolean;
  // observableAccountsListSearchMode: any;

  /**
   * User account list subscription
   */
  private userAccountsListSubscription: Subscription;

  /**
   * Constructor
   * @param afs angular firestore
   * @param configService config Service
   * @param errorService error Service
   * @param functionService function service
   */
  constructor(
    private afs: AngularFirestore,
    private configService: ConfigService,
    private creditService: CreditService,
    private userRtdbService: UserRtdbService,
    private errorService: ErrorService,
    private functionService: FunctionService,
  ) {
    this.observableUserAccountsList = new BehaviorSubject<UserAccounts[]>(this.userAccountsList);
    // this.observableAccountsListSearchMode = new BehaviorSubject<boolean>(this.accountsListSearchMode);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy() {
    this.unwatchUserAccountsList();
  }

  /**
   * Setup UID
   * @param uid UID
   */
  setUid(uid: string) {
    this.checked = false;
    this.uid = uid;
    this.userAccountsList = [];
    this.observableUserAccountsList.next(this.userAccountsList);
    if (this.uid) {
      // this.readUserAccountsList();
      this.watchUserAccountsList();
    } else {
      this.unwatchUserAccountsList();
    }
  }

  /**
   * Watch user account list
   */
  async watchUserAccountsList() {
    if (this.uid) {
      if (!this.userAccountsListSubscription) {
        this.userAccountsListSubscription = this.afs.collection(`users/${ this.uid }/accounts/`,
        ref => ref.where('enable', '==', true))
        .snapshotChanges().pipe(distinctUntilChanged(), map(actions => actions.map( a => {
          const userAccounts: UserAccounts = a.payload.doc.data() as UserAccounts;
          if (!userAccounts.accountId) { userAccounts.accountId = a.payload.doc.id; }
          return userAccounts;
        }))).subscribe({
          next: (userAccountsList: UserAccounts[]) => {
            this.checked = true;
            this.userAccountsList = userAccountsList;
            this.userRtdbService.updateUserAccountListRtdb(this.uid, this.userAccountsList);
            this.observableUserAccountsList.next(this.userAccountsList);
          }, error: (err: any) => {
            this.errorService.logError(err);
          }
        });
      }
    } else {
      setTimeout(() => {
        this.watchUserAccountsList();
      }, 500);
    }
  }

  /**
   * Unwatch user account list
   */
  async unwatchUserAccountsList() {
    if (this.userAccountsListSubscription) {
      this.userAccountsListSubscription.unsubscribe();
      this.userAccountsListSubscription = null;
    }
  }

  async readUserAccountsList() {
    if (this.uid) {
      try {
        const querySnapshot = await firstValueFrom(this.afs.collection(`users/${ this.uid }/accounts/`, ref => ref.where('enable', '==', true)).get());
        const userAccountsList: UserAccounts[] = querySnapshot.docs.map(doc => {
          const data = doc.data() as UserAccounts;
          if (!data.accountId) {
            data.accountId = doc.id;
          }
          return data;
        });
        this.checked = true;
        this.userAccountsList = userAccountsList;
        this.userRtdbService.updateUserAccountListRtdb(this.uid, this.userAccountsList);
        this.observableUserAccountsList.next(this.userAccountsList);
      } catch (error) {
        this.errorService.logError(error);
      }
    } else {
      setTimeout(() => {
        this.readUserAccountsList();
      }, 500);
    }
  }

  /**
   * Search Accounts List
   * @param keyword Keyword
   * @param type Type
   * @param filter Filter
   * @returns List of account info
   */
  searchAccountsList(keyword?: string, type?: AccountsListType, filter?: any): UserAccounts[] {
    let userAccountsList: UserAccounts[] = this.userAccountsList;
    if (userAccountsList) {
      if (keyword) {
        userAccountsList = userAccountsList.filter((account: UserAccounts) => {
          return this.searchAccountBykeyword(account, keyword);
        });
      }
      if (type) {
        userAccountsList = userAccountsList.filter((account: UserAccounts) => {
          return this.filterAccountByType(account, type);
        });
      }

      if (filter?.enable) {
        userAccountsList = userAccountsList.filter((account: UserAccounts) => {
          return this.filterAccountByCriteria(account, filter);
        });
      }
    }

    userAccountsList = userAccountsList?.sort((a: UserAccounts, b: UserAccounts) => {
      if (type === 'passed') {
        return this.functionService.compare(a.time.seconds, b.time.seconds, true);
      } else {
        const countDownA = this.functionService.getCountDown(a?.time);
        const countDownB = this.functionService.getCountDown(b?.time);
        if (countDownA < 0) {
          return 1;
        } else if (countDownB < 0) {
          return -1;
        } else if (countDownA === countDownB) {
          return this.functionService.compare(a?.time, b?.time, false);
        } else if (countDownA === 0) {
          return -1;
        } else if (countDownB === 0) {
          return 1;
        } else {
          return this.functionService.compare(countDownA, countDownB, false);
        }
      }
      
    });
    return userAccountsList;
  }

  /**
   * Search account account by keyword
   * @param account Account
   * @param keyword Keyword
   * @returns true if match keyword found
   */
  searchAccountBykeyword(account: UserAccounts, keyword: string): boolean {
    if (keyword && account) {
      if (this.functionService.search(account?.title?.value, keyword)) {
        return true;
      } else if (this.functionService.chineseMath(account?.title?.value, keyword)) {
        return true;
      } else if (this.searchCoupleNameByKeyword(account?.coupleList, keyword)) {
        return true;
      } else if (this.searchVenueByKeyword(account?.location?.name, keyword)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Search couple name by keyword
   * @param coupleList Couple list
   * @param keyword keyword
   * @returns true if match keyword found
   */
  searchCoupleNameByKeyword(coupleList: CoupleList[], keyword: string) {
    if (coupleList && keyword) {
      const index = coupleList?.findIndex((couple: CoupleList) => {
        if (couple?.name) {
          if (this.functionService.search(couple.name, keyword)) {
            return true;
          } else if (this.functionService.chineseMath(couple.name, keyword)) {
            return true;
          }
        }
        return false;
      });
      if (index !== -1) {
        return true;
      }
    }
    return false;
  }

  /**
   * Search venue by keyword
   * @param venue venue
   * @param keyword keyword
   * @returns true if match keyword found
   */
  searchVenueByKeyword(venue: string, keyword: string) {
    if (venue && keyword) {
      if (this.functionService.search(venue, keyword)) {
        return true;
      } else if (this.functionService.chineseMath(venue, keyword)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Filter account account by type
   * @param account Account
   * @param type Type
   */
  filterAccountByType(account: UserAccounts, type: AccountsListType): boolean {
    const countDown = this.functionService.getCountDown(account.time);
    if (type === 'upcoming') {
      if (countDown > 0) {
        return true;
      }
    } else if (type === 'passed') {
      if (countDown < 0) {
        return true;
      }
    } else if (type === 'today') {
      if (countDown === 0) {
        return true;
      }
    } else if (type === 'refund') {
      if (!account?.accountStatus?.premium || !account?.owner) {
        return false;
      } else if (countDown <= 0) {
        return false;
      } else {
        const credit = this.creditService.getCreditByAccountId(account?.accountId);
        if (!credit?.creditId) {
          return false;
        } else {
          const creditCreatedCountDown = this.functionService.getCountDown(credit?.createBy?.time);
          if (creditCreatedCountDown < -14) {
            return false;
          }
        }
      }
      return true;
    } else {
      return true;
    }
    return false;
  }

  /**
   * Filter account by criteria
   * @param account account
   * @param filter filter criteria
   */
  filterAccountByCriteria(account: UserAccounts, filter: any): boolean {
    let flag = true;
    if (filter?.enable) {
      if (filter.type) {
        if (filter.type === 'premium' && !account?.accountStatus?.premium) {
          flag = false;
        } else if (filter.type === 'trial' && account?.accountStatus?.premium) {
          flag = false;
        }
      }

      if (filter.role) {
        const role: UserRole = this.getUserRole(account.accountId);
        if (filter.role === 'owner' && !this.checkIsOwner(account.accountId)) {
          flag = false;
        } else if (filter.role !== role.type) {
          flag = false;
        }
      }

      const accountTime = account.time.seconds * 1000;
      if (filter.startDate && filter.endDate) {
        if (accountTime < filter.startDate || accountTime > filter.endDate) {
          flag = false;
        }
      } else if (filter.startDate) {
        if (accountTime < filter.startDate) {
          flag = false;
        }
      } else if (filter.endDate) {
        if (accountTime > filter.endDate) {
          flag = false;
        }
      }

      const createTime = account.time.seconds * 1000;
      if (filter.createDate && filter.endDate) {
        if (accountTime < filter.startDate || accountTime > filter.endDate) {
          flag = false;
        }
      } else if (filter.startDate) {
        if (accountTime < filter.startDate) {
          flag = false;
        }
      } else if (filter.endDate) {
        if (accountTime > filter.endDate) {
          flag = false;
        }
      }
    }
    return flag;
  }

  /**
   * Get user role in the account
   * @param accountId Account ID
   * @returns User role
   */
  getUserRole(accountId: string): UserRole {
    if (accountId && this.userAccountsList) {
      const accountUserIndex = this.userAccountsList?.findIndex(x => x?.accountId === accountId);
      if (accountUserIndex !== -1) {
        const user: UserAccounts = this.userAccountsList[accountUserIndex];
        return user.role;
      }
    }
    return null;
  }

  /**
   * Check if user is owner of the account
   * @param accountId Account ID
   * @returns true if user is owner of the account
   */
  checkIsOwner(accountId?: string): boolean {
    if (this.userAccountsList) {
      if (accountId) {
        const accountUserIndex = this.userAccountsList?.findIndex(x => x?.accountId === accountId);
        if (accountUserIndex !== -1) {
          const user: UserAccounts = this.userAccountsList[accountUserIndex];
          return user.owner;
        }
      } else {
        let owner = false;
        this.userAccountsList.forEach((x: UserAccounts) => {
          if (x?.owner) {
            owner = true;
          }
        });
        if (owner) {
          return true;
        }
      }
    }
    return false;
  }

  checkIsTrial(accountId: string): boolean {
    if (accountId && this.userAccountsList) {
      const index = this.userAccountsList?.findIndex(x => x?.accountId === accountId);
      if (index !== -1) {
        const userAccount: UserAccounts = this.userAccountsList[index];
        if (userAccount?.accountStatus?.trial && !userAccount?.accountStatus?.premium) {
          return true;
        }
      }
    }
    return false;
  }

  checkIsTrialExpired(accountId: string): boolean {
    if (accountId && this.userAccountsList?.length && this.checkIsTrial(accountId)) {
      const index = this.userAccountsList?.findIndex(x => x?.accountId === accountId);
      if (index !== -1) {
        const userAccount: UserAccounts = this.userAccountsList[index];
        const now = getTime(new Date()) / 1000;
        if (userAccount?.trialData?.end?.seconds < now) {
          return  true;
        }
      }
    }
    return false;
  }

  checkTrialSuggestion(): boolean {
    let result = true;
    if (this.userAccountsList?.length) {
      this.userAccountsList.forEach((x: UserAccounts) => {
        if (x?.accountStatus?.premium || x?.accountStatus?.trial) {
          if (x?.owner || x.role?.coupleId) {
            result = false;
          }
        }
      });
    }
    return result;
  }

  /**
   * Check is new trial account is available
   * @returns boolean
   */
  isNewTrialAvailable(): boolean {
    if (this.getNewTrialLimit()) {
      const count = this.getOwnTrialAccount().length;
      if (count < this.configService.trial.trial_new) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  getOwnAccount(): UserAccounts[] {
    let userAccountsList: UserAccounts[] = [];
    if (this.userAccountsList?.length) {
      userAccountsList = this.userAccountsList.filter((userAccounts: UserAccounts) => {
        if (userAccounts?.enable && userAccounts.owner) {
          return true;
        }
        return false;
      });
    }
    return userAccountsList;
  }

  getOwnMigrateAccount(): UserAccounts[] {
    let userAccountsList: UserAccounts[] = [];
    if (this.userAccountsList?.length) {
      userAccountsList = this.userAccountsList.filter((userAccounts: UserAccounts) => {
        if (userAccounts?.enable && userAccounts.owner && userAccounts?.accountStatus?.migrate) {
          return true;
        }
        return false;
      });
    }
    return userAccountsList;
  }
  
  /**
   * Get owned trial account
   * @returns User owned trial wedding account list
   */
  getOwnTrialAccount(): UserAccounts[] {
    let userAccountsList: UserAccounts[] = [];
    if (this.userAccountsList?.length) {
      userAccountsList = this.userAccountsList.filter((userAccounts: UserAccounts) => {
        if (userAccounts?.enable && userAccounts.owner &&  userAccounts?.accountStatus?.trial && !userAccounts?.accountStatus?.premium) {
          return true;
        }
        return false;
      });
    }
    return userAccountsList;
  }

  getCoupleTrialAccount(excludeOwnAccount?: boolean): UserAccounts[] {
    let userAccountsList: UserAccounts[] = [];
    if (this.userAccountsList?.length) {
      userAccountsList = this.userAccountsList.filter((userAccounts: UserAccounts) => {
        if (userAccounts?.enable && userAccounts?.role?.coupleId
        && userAccounts?.accountStatus?.trial && !userAccounts?.accountStatus?.premium) {
          if (!excludeOwnAccount || !userAccounts.owner) {
            return true;
          }
        }
        return false;
      });
    }
    return userAccountsList;
  }

  /**
   * Get new trial account limit
   * @returns number of trial limit
   */
  getNewTrialLimit(): number {
    if (this.configService.trial?.trial_new) {
      return Number(this.configService.trial.trial_new);
    } else {
      return 0;
    }
  }

  getAccountTitle(accountId: string): string {
    if (accountId && this.userAccountsList?.length) {
      const index = this.userAccountsList.findIndex((userAccounts: UserAccounts) => {
        if (userAccounts?.accountId === accountId) {
          return true;
        }
        return false;
      });
      if (index !== -1) {
        return this.userAccountsList[index]?.title?.value;
      }
    }
    return '';
  }

  /**
   * Get join trial account limit
   * @returns number of trial limit
   */
  getJoinTrialLimit(): number {
    if (this.configService.trial?.trial_join) {
      return Number(this.configService.trial.trial_join);
    } else {
      return 0;
    }
  }

  checkDuplicateAccount(accountId: string) {
    if (accountId) {
      if (this.userAccountsList?.length) {
        const index = this.userAccountsList?.findIndex((userAccounts: UserAccounts) => {
          return userAccounts?.accountId === accountId ? true : false;
        });
        if (index !== -1) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Delete account from list
   * @param accountId Account ID
   */
  async deleteAccountsList(accountId: string) {
    const userAccountsRef = this.afs.firestore.doc(`users/${ this.uid }/accounts/${ accountId }`);
    userAccountsRef.set({ enable: false }, { merge: true }).then(result => {
    }).catch((err: any) => {
      this.errorService.logError(err);
    });
  }

  // setSearchMode(searchMode: boolean) {
  //   this.accountsListSearchMode = searchMode;
  //   this.observableAccountsListSearchMode.next(this.accountsListSearchMode);
  // }
}
