import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, firstValueFrom, map } from 'rxjs';
import { RefundTransaction } from 'src/app/interfaces/transaction';
import { FunctionService } from '../general/function.service';
import { ErrorService } from '../general/error.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root'
})
export class RefundService implements OnInit, OnDestroy {

  refundList: RefundTransaction[];

  observableRefundList: any;
  
  private uid: string;

  private refundSubscription: Subscription;

  constructor(
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
    this.refundList = [];
    this.observableRefundList = new BehaviorSubject<RefundTransaction[]>(this.refundList);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unwatchUserRefund();
  }

  /**
   * Set UID
   * @param uid uid
   */
  setUid(uid?: string) {
    this.uid = uid;
    this.refundList = [];
    this.observableRefundList.next(this.refundList);
    if (this.uid) {
      this.watchUserRefund();
    } else {
      this.unwatchUserRefund();
    }
  }

  /**
   * Watch user transaction
   */
  async watchUserRefund() {
    if (this.uid) {
      if (!this.refundSubscription) {
        this.refundSubscription = this.afs.collection(`refunds`,
        ref => ref.where('createBy.uid', '==', this.uid))
        .snapshotChanges().pipe(map(changes => changes.map( a => {
          const data: RefundTransaction = a.payload.doc.data() as RefundTransaction;
          if (!data.refundId) { data.refundId = a.payload.doc.id; }
          return data;
        }).sort((a: RefundTransaction, b: RefundTransaction) => {
          return this.functionService.compare(a?.createBy?.time?.seconds, b?.createBy?.time?.seconds, true);
        }))).subscribe({
          next: (refundList: RefundTransaction[]) => {
            this.refundList = refundList;
            this.observableRefundList.next(this.refundList);
          }, error: (err: any) => {
            this.errorService.logError(err);
          }
        });
      }
    }
  }

  /**
   * unwatch user transaction
   */
  async unwatchUserRefund() {
    if (this.refundSubscription) {
      this.refundSubscription.unsubscribe();
      this.refundSubscription = null;
    }
  }

  async readRefund() {
    if (this.uid) {
      try {
        const querySnapshot = await firstValueFrom(this.afs.collection('refunds', ref => ref.where('createBy.uid', '==', this.uid)).get());
        const refundList: RefundTransaction[] = querySnapshot.docs.map(doc => {
          const data = doc.data() as RefundTransaction;
          if (!data.transactionId) {
            data.transactionId = doc.id;
          }
          return data;
        }).sort((a: RefundTransaction, b: RefundTransaction) => {
          return this.functionService.compare(
            a?.createBy?.time?.seconds,
            b?.createBy?.time?.seconds,
            true
          );
        });
  
        this.refundList = refundList;
        this.observableRefundList.next(this.refundList);
      } catch (error) {
        this.errorService.logError(error);
      }
    }
  }

  getRefundById(refundId: string) {
    const index = this.refundList?.findIndex((x: RefundTransaction) => {
      return x.refundId === refundId;
    })
    return index !== -1 && this.refundList?.[index] ? this.refundList[index] : null;
  }

  getRefundListByTransactionId(transactionId: string): RefundTransaction[] {
    return [ ...this.refundList ].filter((x: RefundTransaction) => {
      return transactionId && x?.transactionId === transactionId;
    });
  }

  getRefundAmountByTransactionId(transactionId: string, currency: string): number {
    const refundList = this.getRefundListByTransactionId(transactionId);
    if (refundList?.length) {
      return refundList.reduce((a: number, b: RefundTransaction) => {
        if (b?.refund?.currency === currency && b.refund.amount) {
          return a + b.refund.amount;
        }
        return a;
      }, 0);
    }
    return 0;
  }

  async getRefundByIdCall(refundId: string): Promise<RefundTransaction> {
    return await this.fns.httpsCallable('getRefundByIdCall')({ refundId })
    .toPromise().then(async (response: RefundTransaction) => {
      return response;
    }).catch((err: any) => {
      this.errorService.logError(err);
      return null;
    });
  }
}
