<ng-container *ngIf="unreadCount">
  <ng-container *ngIf="!showNumber then alertDot"></ng-container>
  <ng-container *ngIf="showNumber">
    <ng-container *ngIf="showBadge">
      <ion-badge [color]="badgeColor ? badgeColor : 'tertiary'">
        {{ unreadCount }}
      </ion-badge>
    </ng-container>
    <ng-container *ngIf="!showBadge">
      {{ unreadCount ? unreadCount : 0 }}
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #alertDot>
  <div class="alert-dot" [style.top]="dotTop ? dotTop : '-8px'"></div>
</ng-template>