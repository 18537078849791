import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { SeatingService } from 'src/app/services/seating/seating.service';
import { SeatingManageService } from 'src/app/services/seating/seating-manage.service';

/**
 * Seating setup service
 */
@Injectable({
  providedIn: 'root'
})
export class SeatingSetupService implements OnInit, OnDestroy {

  /**
   * Constructor
   * @param seatingService seating service
   * @param seatingManageService seating manage service
   */
  constructor(
    private seatingService: SeatingService,
    private seatingManageService: SeatingManageService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Setup Account ID
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    await this.seatingService.setupAccountId(accountId);
    await this.seatingManageService.setupAccountId(accountId);
  }
}
