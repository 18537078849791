import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';

import { AuthService } from 'src/app/services/user/auth.service';
import { Observable } from 'rxjs';

/**
 * User Guard.
 * Allow access for authorize user.
 */
@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate, CanLoad {

  /**
   * Constructor
   * @param userService User Service
   * @param authService Authentication Service
   */
  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private authService: AuthService
  ) { }

  /**
   * Check if user is authenticated
   * @returns Boolean true only if current user is logged in
   */
  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this.afAuth.onAuthStateChanged((currentUser) => {
        if (currentUser) {
          resolve(true);
        } else {
          this.router.navigate(['/main']);
          resolve(false);
        }
      });
    });
  }

  /**
   * Check if user is authenticated
   * @returns Boolean true only if current user is logged in
   */
  async canLoad(): Promise<boolean> {
    const currentUser = await this.authService.getCurrentUser();
    if (currentUser) {
      return true;
    } else {
      return false;
    }
  }
}
