<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="goBack()">
        <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'MENU.term' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="content-div">
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-label class="ion-text-uppercase" [innerHTML]="'TERM.intro.title' | translate"></ion-label></p>
        <p><ion-text class="font-bold font-16" [innerHTML]="'TERM.intro.header' | translate"></ion-text></p>
        <p><ion-text class="font-bold font-16" [innerHTML]="'TERM.intro.header2' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.intro.msg' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'TERM.intro.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.intro.point2' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.intro.point3' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.intro.point4' | translate"></ion-text></p></li>
          </ul>
        </div>
        <p><ion-text [innerHTML]="'TERM.intro.msg2' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.intro.msg3' | translate"></ion-text></p>
  
        <p><ion-text class="font-bold font-16" [innerHTML]="'TERM.intro.provider.title' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.intro.provider.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'COMPANY.hq.name' | translate"></ion-text>&nbsp;(<ion-text [innerHTML]="'COMPANY.hq.code' | translate"></ion-text>)</p>
        <p><ion-text [innerHTML]="'COMPANY.hq.address_html' | translate"></ion-text></p>
        <p><ion-text>support&#64;thebigdays.com</ion-text></p>
        <p><ion-text [innerHTML]="'TERM.intro.provider.msg2' | translate"></ion-text></p>
      </ion-label>
    </ion-item>
  
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-label class="ion-text-uppercase" [innerHTML]="'TERM.relationship.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'TERM.relationship.msg' | translate"></ion-text></p>
        
        <p><ion-text class="font-bold font-16" [innerHTML]="'TERM.relationship.you_expect.title' | translate"></ion-text></p>
  
        <p><ion-text class="font-bold font-16" [innerHTML]="'TERM.relationship.you_expect.range.title' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.relationship.you_expect.range.msg' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'TERM.relationship.you_expect.range.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.relationship.you_expect.range.point2' | translate"></ion-text></p></li>
          </ul>
        </div>
        <p><ion-text [innerHTML]="'TERM.relationship.you_expect.range.msg2' | translate"></ion-text></p>
  
        <p><ion-text class="font-bold font-16" [innerHTML]="'TERM.relationship.you_expect.improve.title' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.relationship.you_expect.improve.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.relationship.you_expect.improve.msg2' | translate"></ion-text></p>
  
        <p><ion-text class="font-bold font-16" [innerHTML]="'TERM.relationship.we_expect.title' | translate"></ion-text></p>
  
  
        <p><ion-text class="font-bold font-16" [innerHTML]="'TERM.relationship.we_expect.term.title' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.relationship.we_expect.term.msg' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'TERM.relationship.we_expect.term.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.relationship.we_expect.term.point2' | translate"></ion-text></p></li>
          </ul>
        </div>
        <p><ion-text [innerHTML]="'TERM.relationship.we_expect.term.msg2' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.relationship.we_expect.term.msg3' | translate"></ion-text></p>
  
  
        <p><ion-text class="font-bold font-16" [innerHTML]="'TERM.relationship.we_expect.respect.title' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.relationship.we_expect.respect.msg' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'TERM.relationship.we_expect.respect.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.relationship.we_expect.respect.point2' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.relationship.we_expect.respect.point3' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.relationship.we_expect.respect.point4' | translate"></ion-text></p></li>
          </ul>
        </div>
        <p><ion-text [innerHTML]="'TERM.relationship.we_expect.respect.msg2' | translate"></ion-text></p>
  
  
        <p><ion-text class="font-bold font-16" [innerHTML]="'TERM.relationship.we_expect.permission.title' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.relationship.we_expect.permission.msg' | translate"></ion-text></p>
      </ion-label>
    </ion-item>
  
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-label class="ion-text-uppercase" [innerHTML]="'TERM.using.title' | translate"></ion-label></p>
        
        <p><ion-text class="font-bold font-16" [innerHTML]="'TERM.using.account.title' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.using.account.msg' | translate"></ion-text></p>
        
        <p><ion-text class="font-bold font-16" [innerHTML]="'TERM.using.using.title' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.using.using.msg' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'TERM.using.using.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.using.using.point2' | translate"></ion-text></p></li>
          </ul>
        </div>
  
  
        <p><ion-text class="font-bold font-16" [innerHTML]="'TERM.using.communication.title' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.using.communication.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.using.communication.msg2' | translate"></ion-text></p>
      </ion-label>
    </ion-item>
  
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-label class="ion-text-uppercase" [innerHTML]="'TERM.content.title' | translate"></ion-label></p>
        
        <p><ion-text class="font-bold font-16" [innerHTML]="'TERM.content.your_content.title' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.content.your_content.msg' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'TERM.content.your_content.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.content.your_content.point2' | translate"></ion-text></p></li>
          </ul>
        </div>
        <p><ion-text [innerHTML]="'TERM.content.your_content.msg2' | translate"></ion-text></p>
        
        <p><ion-text class="font-bold font-16" [innerHTML]="'TERM.content.our_contet.title' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.content.our_contet.msg' | translate"></ion-text></p>
        
        <p><ion-text class="font-bold font-16" [innerHTML]="'TERM.content.other_content.title' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.content.other_content.msg' | translate"></ion-text></p>
      </ion-label>
    </ion-item>
  
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-label class="ion-text-uppercase" [innerHTML]="'TERM.software.title' | translate"></ion-label></p>
        
        <p><ion-text [innerHTML]="'TERM.software.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.software.msg2' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'TERM.software.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.software.point2' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.software.point3' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.software.point4' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.software.point5' | translate"></ion-text></p></li>
          </ul>
        </div>
        <p><ion-text [innerHTML]="'TERM.software.msg3' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.software.msg4' | translate"></ion-text></p>
      </ion-label>
    </ion-item>
  
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-label class="ion-text-uppercase" [innerHTML]="'TERM.problem.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'TERM.problem.msg' | translate"></ion-text></p>
  
        <p><ion-label class="ion-text-uppercase" [innerHTML]="'TERM.problem.warranty.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'TERM.problem.warranty.msg' | translate"></ion-text></p>
  
        <p><ion-label class="ion-text-uppercase" [innerHTML]="'TERM.problem.disclaimers.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'TERM.problem.disclaimers.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.problem.disclaimers.msg2' | translate"></ion-text></p>
  
        <p><ion-label class="ion-text-uppercase" [innerHTML]="'TERM.problem.liabilities.title' | translate"></ion-label></p>
  
        <p><ion-label class="ion-text-uppercase" [innerHTML]="'TERM.problem.liabilities.all.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'TERM.problem.liabilities.all.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.problem.liabilities.all.msg2' | translate"></ion-text></p>
  
        <p><ion-label class="ion-text-uppercase" [innerHTML]="'TERM.problem.liabilities.business.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'TERM.problem.liabilities.business.msg' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'TERM.problem.liabilities.business.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.problem.liabilities.business.point2' | translate"></ion-text></p></li>
            <ul>
              <li><p><ion-text [innerHTML]="'TERM.problem.liabilities.business.point2.a' | translate"></ion-text></p></li>
              <li><p><ion-text [innerHTML]="'TERM.problem.liabilities.business.point2.b' | translate"></ion-text></p></li>
              <li><p><ion-text [innerHTML]="'TERM.problem.liabilities.business.point2.c' | translate"></ion-text></p></li>
            </ul>
            <li><p><ion-text [innerHTML]="'TERM.problem.liabilities.business.point3' | translate"></ion-text></p></li>
          </ul>
        </div>
        <p><ion-text [innerHTML]="'TERM.problem.liabilities.business.msg2' | translate"></ion-text></p>
  
        <p><ion-label class="ion-text-uppercase" [innerHTML]="'TERM.problem.problem.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'TERM.problem.problem.msg' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'TERM.problem.problem.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.problem.problem.point2' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.problem.problem.point3' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.problem.problem.point4' | translate"></ion-text></p></li>
          </ul>
        </div>
  
        <p><ion-label class="ion-text-uppercase" [innerHTML]="'TERM.problem.problem.remove.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'TERM.problem.problem.remove.msg' | translate"></ion-text></p>
  
        <p><ion-label class="ion-text-uppercase" [innerHTML]="'TERM.problem.problem.suspend.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'TERM.problem.problem.suspend.msg' | translate"></ion-text></p>
        <div>
          <ul>
            <li><p><ion-text [innerHTML]="'TERM.problem.problem.suspend.point1' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.problem.problem.suspend.point2' | translate"></ion-text></p></li>
            <li><p><ion-text [innerHTML]="'TERM.problem.problem.suspend.point3' | translate"></ion-text></p></li>
          </ul>
        </div>
        <p><ion-text [innerHTML]="'TERM.problem.problem.suspend.msg2' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.problem.problem.suspend.msg3' | translate"></ion-text></p>
  
        <p><ion-label class="ion-text-uppercase" [innerHTML]="'TERM.problem.problem.dispute.title' | translate"></ion-label></p>
        <p><ion-text [innerHTML]="'TERM.problem.problem.dispute.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.problem.problem.dispute.msg2' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.problem.problem.dispute.msg3' | translate"></ion-text></p>
      </ion-label>
    </ion-item>
  
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">
        <p><ion-label class="ion-text-uppercase" [innerHTML]="'TERM.about.title' | translate"></ion-label></p>
        
        <p><ion-text [innerHTML]="'TERM.about.msg' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.about.msg2' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.about.msg3' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.about.msg4' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.about.msg5' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.about.msg6' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.about.msg7' | translate"></ion-text></p>
        <p><ion-text [innerHTML]="'TERM.about.msg8' | translate"></ion-text></p>
      </ion-label>
    </ion-item>
  </div>
</ion-content>
