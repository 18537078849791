/**
 * Receipt header
 * 
 * TRANID - Transaction ID
 * TIME - Transaction time
 * COMPANY - Company name
 * ADDRESS - Company Address
 * TITLE - Receipt title
 */
export const ReceiptHeader = {
    header: [
        {
            table: {
                widths: [ 120, '*'],
                body: [
                    [
                        { rowSpan: 2, image: 'logo', link: 'https://wedding.thebigdays.com', width: 100 },
                        { text: '{{TRANID}}', style: ['right', 'bold'], fontSize: 16}
                    ],
                    [
                        '',
                        { text: '{{TIME}}', style: ['right'], fontSize: 14}
                    ],
                ]
            },
            margin: [40, 25, 40, 0],
            layout: 'noBorders',
        },
        // { text: '{{COMPANY}}', fontSize: 10, style: ['margin'] },
        // { text: '{{COMPANY_NO}}', fontSize: 10, style: ['margin'] },
        // { text: '{{ADDRESS}}', fontSize: 10, style: ['margin'] },
        // { text: 'support@thebigdays.com', fontSize: 10, style: ['margin'], link: 'mailto:support@thebigdays.com' },
        { text: '{{TITLE}}', fontSize: 20, margin: [ 40, 0, 40, 0], style: ['bold', 'center'] },
        // { text: '{{SUMMARY}}', fontSize: 16, margin: [ 40, 0, 0, 0], style: ['bold', 'left'] },
        {
            canvas: [
                {
                    type: 'line',
                    x1: 40,
                    y1: 1,
                    x2: 555.28,
                    y2: 1,
                    lineWidth: 1
                }
            ],
            margin: [0, 10]
        },
    ],
};
