import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { LanguageService } from '../general/language.service';

import { InboxContent } from 'src/app/interfaces/inbox';
import { Language } from 'src/app/interfaces/database';

@Injectable({
  providedIn: 'root'
})
export class InboxContentService implements OnInit, OnDestroy {

  constructor(
    private languageService: LanguageService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  getMsgContent(inboxContentList: InboxContent[], language?: Language): InboxContent {
    if (!language?.code) {
      language = this.languageService.getUserLanguage();
    }
    if (inboxContentList?.length && language?.code) {
      const index = inboxContentList.findIndex((x: InboxContent) => {
        return x.language?.code === language?.code;
      });
      if (index !== -1 && inboxContentList?.[index]) {
        return inboxContentList[index];
      } else {
        const englishIndex = inboxContentList.findIndex((x: InboxContent) => {
          return x.language?.code === 'en';
        });
        if (englishIndex !== -1 && inboxContentList?.[englishIndex]) {
          return inboxContentList[englishIndex];
        } else {
          return inboxContentList?.[0]
        }
      }
    }
    return null;
  }
}
