export const BlastDeliveredStatus: any = {
    esms: [
        'DELIVRD'
    ],
    twilio: [
        'delivered',
    ],
    aws: [
        'sent'
    ]
};

export const BlastSentStatus: any = {
    esms: [
        'sent'
    ],
    twilio: [
        'sent',
    ],
    aws: [
        'sent'
    ]
};

export const BlastReadStatus: any = {
    esms: [
    ],
    twilio: [
        'read',
    ],
    aws: [
    ]
};

export const BlastFailedStatus: any = {
    esms: [
        'UNDELIV',
        'EXPIRED'
    ],
    twilio: [
        'undelivered',
        'failed',
    ],
    aws: [
        'failed',
    ]
};
