import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { BehaviorSubject, Subscription } from "rxjs";
import { Inbox, InboxContent, InboxStatus } from "src/app/interfaces/inbox";
import { DateTimeService } from "../general/date-time.service";

import { FunctionService } from "../general/function.service";
import { UserService } from "../user/user.service";

import { InboxDbService } from "./inbox-db.service";
import { InboxTargetService } from "./inbox-target.service";
import { InboxType } from "src/app/types/inbox";
import { AccountsListService } from "../accounts/accounts-list.service";

@Injectable({
  providedIn: 'root'
})
export class InboxService implements OnInit, OnDestroy {

  list: Inbox[];

  observableList: any;

  private inboxList: Inbox[];

  private releaseNoteList: Inbox[];

  private oriInboxList: Inbox[];

  releaseNoteStatusList: InboxStatus[];

  // observableInboxList: any;

  // observableReleaseNoteList: any;

  observableReleaseNoteStatusList: any;
  
  private inboxSubscription: Subscription;

  private releaseNoteSubscription: Subscription;
  
  private releaseNoteStatusSubscription: Subscription;

  private accountsListSubscription: Subscription;

  private uid: string;

  constructor(
    private db: AngularFireDatabase,
    private inboxDbService: InboxDbService,
    private inboxTargetService: InboxTargetService,
    private accountsListService: AccountsListService,
    private userService: UserService,
    private dateTimeService :DateTimeService,
    private functionService: FunctionService,
  ) {
    this.list = [];
    this.observableList = new BehaviorSubject<Inbox[]>(this.list);

    this.inboxList = [];
    // this.observableInboxList = new BehaviorSubject<Inbox[]>(this.inboxList);

    this.releaseNoteList = [];
    this.releaseNoteStatusList = [];
    // this.observableReleaseNoteList = new BehaviorSubject<Inbox[]>(this.releaseNoteList);
    this.observableReleaseNoteStatusList = new BehaviorSubject<InboxStatus[]>(this.releaseNoteStatusList);
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.unwatch();
  }

  setup(uid: string) {
    this.uid = uid;
    if (this.uid) {
      this.watch();
    } else {
      this.unwatch();
    }
  }

  watch() {
    if (this.uid) {
      this.watchInbox();
      this.watchReleaseNote();
      this.watchReleaseNoteStatus();
      this.watchAccountsList();
    }
  }

  unwatch() {
    this.unwatchInbox();
    this.unwatchReleaseNote();
    this.unwatchReleaseNoteStatus();
    this.unwatchAccountsList();
  }
  
  async watchInbox() {
    if (this.uid && !this.inboxSubscription) {
      this.inboxSubscription = this.db.object(this.inboxDbService.getInboxDbRef(this.uid)).valueChanges().subscribe((doc: any) => {
        const inboxList: Inbox[] = [];
        if (doc) {
          Object.keys(doc)?.forEach((key: string) => {
            const inbox: Inbox = doc[key];
            if (inbox?.enable) {
              if (!inbox?.inboxStatus) {
                inbox.inboxStatus = {
                  read: false,
                }
              }
              inboxList.push(inbox);
              // const timestamp = new Date().getTime();
              // if (!inbox?.startTimestamp || inbox?.startTimestamp < timestamp) {
              //   if (!inbox?.endTimestamp || inbox?.endTimestamp > timestamp) {
              //     inboxList.push(inbox);
              //   }
              // }
            }
          });
        }
        this.oriInboxList = inboxList;
        this.setupInboxList(inboxList);
      });
    }
    
  }

  async unwatchInbox() {
    if (this.inboxSubscription) {
      this.inboxSubscription?.unsubscribe();
      this.inboxSubscription = null;
      // this.observableList.next(this.list);
    }
  }

  async watchReleaseNote() {
    if (this.uid && !this.releaseNoteSubscription) {
      this.releaseNoteSubscription = this.db.object(this.inboxDbService.getReleaseNoteDbRef()).valueChanges().subscribe((doc: any) => {
        const releaseNoteList: Inbox[] = [];
        if (doc) {
          Object.keys(doc)?.forEach((key: string) => {
            const inbox: Inbox = doc[key];
            if (inbox?.enable) {
              releaseNoteList.push(inbox);
              // const timestamp = new Date().getTime();
              // if (!inbox?.startTimestamp || inbox?.startTimestamp < timestamp) {
              //   if (!inbox?.endTimestamp || inbox?.endTimestamp > timestamp) {
              //     releaseNoteList.push(inbox);
              //   }
              // }
            }
          });
        }
        this.setupInboxList(releaseNoteList, 'releaseNote')
      });
    }
  }

  async unwatchReleaseNote() {
    if (this.releaseNoteSubscription) {
      this.releaseNoteSubscription.unsubscribe();
      this.releaseNoteSubscription = null;
      // this.observableReleaseNoteList.next(this.releaseNoteList);
    }
  }

  async watchReleaseNoteStatus() {
    if (this.uid && !this.releaseNoteStatusSubscription) {
      this.releaseNoteStatusSubscription = this.db.object(this.inboxDbService.getReleaseNoteStatusDbRef(this.uid)).valueChanges().subscribe((doc: any) => {
        const releaseNoteStatusList: InboxStatus[] = [];
        if (doc) {
          Object.keys(doc)?.forEach((key: string) => {
            const inboxStatus: InboxStatus = doc[key];
            if (!inboxStatus?.inboxId) {
              inboxStatus.inboxId = key;
            }
            releaseNoteStatusList.push(inboxStatus);
          });
        }
        this.releaseNoteStatusList = releaseNoteStatusList;
        this.observableReleaseNoteStatusList.next(this.releaseNoteStatusList);
      });
    }
  }

  async unwatchReleaseNoteStatus() {
    if (this.releaseNoteStatusSubscription) {
      this.releaseNoteStatusSubscription.unsubscribe();
      this.releaseNoteStatusSubscription = null;
    }
  }

  async watchAccountsList() {
    if (!this.accountsListSubscription) {
      this.accountsListSubscription = this.accountsListService.observableUserAccountsList.subscribe(() => {
        this.setupInboxList(this.oriInboxList);
      });
    }
  }

  async unwatchAccountsList() {
    if (this.accountsListSubscription) {
      this.accountsListSubscription.unsubscribe();
      this.accountsListSubscription = null;
    }
  }

  setupInboxList(inboxList: Inbox[], inboxType?: InboxType) {
    inboxList = inboxList?.filter((inbox: Inbox) => {
      if (inbox?.inboxTargetList?.length) {
        const valid = this.inboxTargetService.checkInboxTargetList(inbox.inboxTargetList);
        return valid;
      }
      return true;
    }).map((inbox: Inbox) => {
      inbox.inboxContent = inbox.inboxContent.map((inboxContent: InboxContent) => {
        if (inboxContent?.timestamp?.length) {
          const timestamp0 = this.dateTimeService.format(inboxContent.timestamp[0], 'do MMM yyyy h:mm a');
          inboxContent.msg = this.functionService.replaceAll(inboxContent.msg, '[TIMESTAMP_0]', timestamp0);
        }
        // inboxContent.youtube = inboxContent?.youtube?.map((x: string) => {
        //   return this.functionService.replaceAll(x, 'www.youtube.com', 'www.youtube-nocookie.com');
        // })
        if (this.userService?.user?.name) {
          inboxContent.msg = this.functionService.replaceAll(inboxContent.msg, '{{USER_NAME}}', this.userService.user.name);
        }
        return inboxContent;
      })
      return inbox;
    });
    if (inboxType === 'releaseNote') {
      this.releaseNoteList = inboxList;
    } else {
      this.inboxList = inboxList;
    }
    if (!this.inboxList?.length) {
      this.inboxList = [];
    }
    if (!this.releaseNoteList?.length) {
      this.releaseNoteList = [];
    }

    this.list = [...new Set([ ...this.inboxList, ...this.releaseNoteList ])].sort((a: Inbox, b: Inbox) => {
      return this.functionService.compare(a?.createBy?.time, b?.createBy?.time, true);
    });

    this.observableList.next(this.list);
  }
}
