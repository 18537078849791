import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { UserService } from '../user/user.service';
import { SupportTicketService } from './support-ticket.service';
import { User } from 'src/app/interfaces/user';
import { SupportAdminService } from './support-admin.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupportSetupService implements OnInit, OnDestroy {

  uid: string;

  private userSubscription: Subscription;

  constructor(
    private userService: UserService,
    private supportTicketService: SupportTicketService,
    private supportAdminService: SupportAdminService,
  ) {
    this.watchUser();
  }
  
  ngOnInit() {
    
  }

  ngOnDestroy(): void {
    this.unwatchUser();
  }

  async watchUser() {
    if (!this.userSubscription) {
      this.userSubscription = this.userService.observableUser.subscribe((user: User) => {
        if (this.uid !== user?.uid) {
          this.uid = user?.uid;
          this.setup(this.uid);
        } 
      });
    }
    
  }

  async unwatchUser() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
      this.userSubscription = null;
    }
  }

  setup(uid: string) {
    if (this.supportAdminService.checkAdmin()) {
      this.supportAdminService.setup();
    } else {
      this.supportTicketService.setup(uid);
    }
  }
}
