import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { FunctionService } from './function.service';
import { Platform } from '@ionic/angular';
import { LinkService } from './link.service';
import { LocationDetail } from 'src/app/interfaces/general';

@Injectable({
  providedIn: 'root'
})
export class GpsService implements OnInit, OnDestroy {

  constructor(
    private platform: Platform,
    private linkService: LinkService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Test google navigation
   */
  openGoogleNav(pageLocation: LocationDetail) {
    if (pageLocation && !this.functionService.isEmpty(pageLocation)) {
      if (pageLocation.coordinate) {
        let url = 'https://www.google.com/maps/search/?api=1';
        url = url + '&query=' + pageLocation.coordinate.lat + ',' + pageLocation.coordinate.lng;
        if (pageLocation.placeId) {
          url = url + '&query_place_id=' + pageLocation.placeId;
        }
        this.openGps(url);
      }
    }
  }

  /**
   * Test waze navigation
   */
  openWazeNav(pageLocation: LocationDetail) {
    if (pageLocation && !this.functionService.isEmpty(pageLocation)) {
      if (pageLocation.name) {
        let url = 'https://waze.com/ul?navigate=yes';
        url = url + '&q=' + pageLocation.name;
        if (pageLocation.coordinate) {
          url = url + '&ll=' + pageLocation.coordinate.lat + ',' + pageLocation.coordinate.lng;
        }
        this.openGps(url);
      }
    }
  }

  /**
   * Test uber navigation
   */
  openUberNav(pageLocation: LocationDetail) {
    if (pageLocation && !this.functionService.isEmpty(pageLocation)) {
      let url = 'https://m.uber.com/ul/?action=setPickup&pickup=my_location';
      if (pageLocation?.coordinate) {
        url = url + '&dropoff[latitude]=' + pageLocation.coordinate.lat + '&dropoff[longitude]=' + pageLocation.coordinate.lng;
      }
      if (pageLocation?.name) {
        url = url + '&dropoff[nickname]=' + pageLocation.name;
      }
      if (pageLocation?.address) {
        url = url + '&dropoff[formatted_address]=' + pageLocation.address;
      }
      this.openGps(url);
    }
  }

  openGrab(pageLocation: LocationDetail) {
    if (pageLocation && !this.functionService.isEmpty(pageLocation)) {
      let url = 'https://grab.onelink.me/2695613898?af_dp=grab://open?screenType=BOOKING&sourceID=&c=&pid=';
      if (this.platform.is('ios')) {
        url = 'grab://open?screenType=BOOKING';
      }
      if (pageLocation?.coordinate) {
        url = url + '&dropOffLatitude=' + pageLocation.coordinate.lat + '&dropOffLongitude=' + pageLocation.coordinate.lng;
      }
      if (pageLocation.address) {
        url = url + '&dropOffAddress=' + this.functionService.replaceAll(pageLocation.address, ' ', '+');
      }
      this.openGps(url);
    }
  }

  openAppleNav(pageLocation: LocationDetail) {
    if (pageLocation && !this.functionService.isEmpty(pageLocation)) {
      if (pageLocation.coordinate) {
        let url = 'http://maps.apple.com/?';
        url = url + 'll=' + pageLocation.coordinate.lat + ',' + pageLocation.coordinate.lng;
        if (pageLocation?.name) {
          url = url + '&q=' + pageLocation.name;
        }
        if (pageLocation?.address) {
          url = url + '&daddr=' + pageLocation.address;
        }
        url = this.functionService.replaceAll(url, ' ', '+');
        this.openGps(url);
      }
    }
  }

  async openGps(url: string) {
    url = encodeURI(url);
    if (this.platform.is('ios') && this.platform.is('hybrid')) {
      window.open(url, '_system');
    } else {
      this.openUrl(url);
    }
  }

  async openUrl(url: string) {
    this.linkService.openUrl(url);
  }
}
