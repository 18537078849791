import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BulkActionComponent } from './bulk-action.component';

@NgModule({
    declarations: [
        BulkActionComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
        TranslateModule
    ],
    exports: [
        BulkActionComponent
    ]
})
export class BulkActionComponentsModule { }
