import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class KeyboardService implements OnInit, OnDestroy {

  private keyboardHeight: number;

  constructor(
    private platform: Platform,
  ) {
    // this.watchKeyboard();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  async hide() {
    if (Keyboard) {
      await Keyboard.hide();
    }
  }

  async watchKeyboard() {
    await this.platform.ready();
    if (this.platform.is('hybrid') && this.platform.is('ios')) {
      Keyboard.addListener('keyboardDidShow', (info: KeyboardInfo) => {
        this.keyboardHeight = info.keyboardHeight;
      });
      Keyboard.addListener('keyboardDidHide', () => {
        this.keyboardHeight = 0;
      });
    }
  }

  getKeyboardHeight(): number {
    let height = 0;
    if (this.platform.is('hybrid') && this.platform.is('ios')) {
      height = 40;
    }
    return height;
  }
}
