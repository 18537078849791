import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { FunctionService } from '../general/function.service';
import { VisitorManageService } from './visitor-manage.service';

@Injectable({
  providedIn: 'root'
})
export class VisitorThemeService implements OnInit, OnDestroy {

  /**
   * Page theme
   */
  pageTheme: string;
  /**
   * Observable page theme
   */
  observablePageTheme: any;

  constructor(
    private visitorManageService: VisitorManageService,
    private functionService: FunctionService,
  ) {
    this.observablePageTheme = new BehaviorSubject<string>(this.pageTheme);
  }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
  
  setupPageTheme(pageTheme?: string) {
    if (!this.functionService.isEqual(pageTheme, this.pageTheme)) {
      this.pageTheme = pageTheme;
      this.visitorManageService.themeId = pageTheme;
      this.observablePageTheme.next(this.pageTheme);
    }
  }

  getPageTheme() {
    return this.pageTheme ? this.pageTheme : '1.1';
  }
}
