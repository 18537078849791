import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { BehaviorSubject, Subscription } from 'rxjs';

import { FunctionService } from '../general/function.service';
import { VisitorRtdbUrl } from 'src/app/commons/db';
import { CheckinMethodType } from 'src/app/types/guest';

@Injectable({
  providedIn: 'root'
})
export class VisitorRealtimeService implements OnInit, OnDestroy {

  observableCheckinStatus: any;

  private rtdbSubcription: Subscription;

  constructor(
    private db: AngularFireDatabase,
    private functionService: FunctionService,
  ) {
    this.observableCheckinStatus = new BehaviorSubject<boolean>(false);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy() {
    this.unwatchRtdb();
  }

  getPath(accountId: string, guestId: string, groupId?: string) {
    if (guestId) {
      return `/accounts/${ accountId }/guests/${ guestId }/`;
    } else if (groupId) {
      return `/accounts/${ accountId }/groups/${ groupId }/`;
    }
    return '';
  }
  
  getDb() {
    return this.db.database.app.database(VisitorRtdbUrl);
  }

  getDbRef(accountId: string, guestId: string, groupId?: string) {
    if (accountId) {
      const db = this.getDb();
      const path = this.getPath(accountId, guestId, groupId);
      if (db && path) {
        return db.ref(path);
      }
    }
    
    return null;
  }

  async watchRtdb(accountId: string, guestId: string, groupId?: string) {
    const dbRef = this.getDbRef(accountId, guestId, groupId);
    if (dbRef && !this.rtdbSubcription) {
      this.rtdbSubcription = this.db.object(dbRef).valueChanges().subscribe((value: any) => {
        this.observableCheckinStatus.next(value?.checkin ? true : false);
      });
    }
  }

  async unwatchRtdb() {
    if (this.rtdbSubcription) {
      this.rtdbSubcription.unsubscribe();
      this.rtdbSubcription = null;
    }
  }

  async updateRtdb(checkin: boolean, accountId: string, guestId: string, groupId?: string) {
    const dbRef = this.getDbRef(accountId, guestId, groupId);
    if (dbRef) {
      await dbRef.update({ checkin });
    }
  }
  async batchUpdateRtdb(checkin: boolean, checkinMethod: CheckinMethodType, accountId: string, guestIdList: string[], groupIdList?: string[]) {   
    const updates: any = {};
    guestIdList?.forEach((guestId: string) => {
      updates[this.getPath(accountId,  guestId, '') + 'checkin/'] = checkin;
      if (checkin && checkinMethod) {
        updates[this.getPath(accountId,  guestId, '') + 'checkinMethod/'] = checkinMethod;
      }
    });
    groupIdList?.forEach((groupId: string) => {
      updates[this.getPath(accountId,  '', groupId) + 'checkin/'] = checkin;
      if (checkin && checkinMethod) {
        updates[this.getPath(accountId,  '', groupId) + 'checkinMethod/'] = checkinMethod;
      }
    });
 
    if (updates && !this.functionService.isEmpty(updates)) {
      const db = this.getDb();
      await db.ref().update(updates);
    }
  }
}
