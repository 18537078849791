import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Transaction } from 'src/app/interfaces/transaction';
import { ReceiptService } from 'src/app/services/pdf/receipt.service';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

// import * as pdfFontsChinese from 'pdfmake-chinese-songti-font/vfs_fonts';

@Component({
  selector: 'app-user-receipt',
  templateUrl: './user-receipt.component.html',
  styleUrls: ['./user-receipt.component.scss'],
})
export class UserReceiptComponent implements OnInit, OnDestroy {

  @ViewChild('content', { read: ElementRef }) contentElement: ElementRef;
  /**
   * Transaction
   */
  transaction: Transaction;
  /**
   * PDF Obj
   */
  pdfObj: any;

  showPdf: boolean;
  src: any;

  contentHeight: number;

  /**
   * Constructor
   * @param router router
   * @param route route
   * @param platform platform
   * @param http http
   * @param fileOpener file opener
   * @param receiptService receipt service
   * @param popupService popup service
   */
  constructor(
    private modalController: ModalController,
    private receiptService: ReceiptService,
  ) { }

  ngOnInit() {

  }

  ngOnDestroy() {
    
  }

  ionViewWillEnter() {
    if (this.transaction) {
      this.createPdf();
    } else {
      this.dismissModal();
    }

    this.setupContentHeight();
  }

  /**
   * Dismiss Modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    }
  }

  /**
   * Credit PDF
   */
  async createPdf() {
    this.src = '';
    if (this.transaction?.transactionId && this.transaction?.createBy?.time?.seconds) {
      const dd: any = this.receiptService.setupPdfDD(this.transaction);
      if (dd) {
        // const language = this.languageService.getUserLanguage();
        
        // if (language?.code === 'zh' || language?.code === 'zh-TW') {
        //   pdfMake.vfs = pdfFontsChinese;
        //   pdfMake.fonts = {
        //     st: {
        //       normal: 'st.ttf',
        //       bold: 'st.ttf',
        //       italics: 'st.ttf',
        //       bolditalics: 'st.ttf'
        //     },
        //   };
        //   dd.defaultStyle.font = 'st';
        // } else {
        //   pdfMake.vfs = pdfFonts.pdfMake.vfs;
        // }
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        this.pdfObj = await pdfMake.createPdf(dd);
        this.openPdf();
      } else {
        this.dismissModal();
      }
    }
  }

  /**
   * Open PDF
   */
  async openPdf() {
    this.showPdf = false;
    if (this.pdfObj) {
      this.pdfObj.getBase64(async (base64) => {
        this.showPdf = true;
        this.src = base64;
        // console.log('base64: ', base64);
        // if (this.platform.is('hybrid') && this.platform.is('ios')) {
        //   const fileName = 'receipt.pdf';
        //   const result = await Filesystem.writeFile({
        //     path: fileName,
        //     data: 'data:application/pdf;base64,' + base64,
        //     directory: Directory.Documents,
        //     encoding: Encoding.UTF8,
        //   });
        //   console.log('result: ', result);

        //   const fileOpenerOptions: FileOpenerOptions = {
        //     filePath: result.uri,
        //     contentType: 'application/pdf',
        //     openWithDefault: true,
        //   };
        //   await FileOpener.open(fileOpenerOptions);
        //   // this.linkService.openUrl(result.uri);
        //   this.showPdf = true;
        //   this.src = base64;
        // } else {
        //   this.showPdf = true;
        //   this.src = base64;
        // }
      });
      // this.pdfObj.getBuffer((buffer) => {
      //   const blob = new Blob([buffer], { type: 'application/pdf' });
      //   // Use the blob as needed

      //   console.log('data:', blob);
      //   this.showPdf = true;
      //   this.src = blob;
      // });

      // this.pdfObj.getBlob(async (data: any) => {
      //   console.log('data:', data);
      //   this.showPdf = true;
      //   this.src = data;
      // }, (err: any) => {
      //   console.error('err');
      //   console.error(err);
      // });
    } else {
      this.dismissModal();
    }
  }

  setupContentHeight() {
    if (this.contentElement?.nativeElement?.offsetHeight) {
      this.contentHeight = this.contentElement?.nativeElement?.offsetHeight;
    } else {
      setTimeout(() => {
        this.setupContentHeight();
      }, 200);
    }
  }

}
