import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';

import { OnlineService } from '../general/online.service';
import { FunctionService } from '../general/function.service';
import { UserAccounts } from 'src/app/interfaces/account';

@Injectable({
  providedIn: 'root'
})
export class UserRtdbService implements OnInit, OnDestroy {

  constructor(
    private db: AngularFireDatabase,
    private onlineService: OnlineService,
    private functionService: FunctionService,
  ) { }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  getDb() {
    return this.db.database;
  }

  getRef() {
    const db = this.getDb();
    const ref = db.ref(`database`);
    return ref;
  }

  getUserRef(uid?: string) {
    const ref = this.getRef();
    if (uid) {
      return ref.child(`users`).child(uid);
    } else {
      return ref.child(`users`);
    }
  }

  getDevicesRef(uuid?: string, uid?: string) {
    if (uid && uuid) {
      return this.getUserRef(uid).child(`devices`).child(uuid);
    } else if (uid) {
      return this.getUserRef(uid).child(`devices`);
    } else if (uuid) {
      return this.getRef().child(`devices`).child(uuid);
    } else {
      return this.getRef().child(`devices`);
    }
  }

  async updateUserAccountsRtdb(uid: any, account: UserAccounts) {
    if (uid && account?.accountId) {
      if (this.onlineService.online) {
        const ref = this.getUserRef(uid).child(`accountList`).child(account.accountId);
        await ref.update(account);
      } else {
        await this.functionService.delay(1000);
        this.updateUserAccountsRtdb(uid, account);
      }
    }
  }

  async updateUserAccountListRtdb(uid: any, accountList: UserAccounts[]) {
    if (uid && accountList?.length) {
      if (this.onlineService.online) {
        accountList?.forEach(async (account: UserAccounts) => {
          const ref = this.getUserRef(uid).child(`accountList`).child(account.accountId);
          await ref.update(account);
        })
      } else {
        await this.functionService.delay(1000);
        this.updateUserAccountListRtdb(uid, accountList);
      }
    }
  }


  async updateUserRtdb(uid: any, data: any) {
    if (uid && data) {
      if (this.onlineService.online) {
        const ref = this.getUserRef(uid);
        await ref.update(data);
      } else {
        await this.functionService.delay(1000);
        this.updateUserRtdb(uid, data);
      }
    }
  }

  async updateDevicesRtdb(uuid: any, data: any) {
    if (uuid && data) {
      if (this.onlineService.online) {
        const ref = this.getDevicesRef(uuid);
        await ref.update(data);
      } else {
        await this.functionService.delay(1000);
        this.updateDevicesRtdb(uuid, data);
      }
    }
  }

  async updateUserDeviceRtdb(uid: string, uuid: string, data: any) {
    if (uid && uuid && data) {
      if (this.onlineService.online) {
        const ref = this.getDevicesRef(uuid, uid);
        await ref.update(data);
      } else {
        await this.functionService.delay(1000);
        this.updateUserDeviceRtdb(uid, uuid, data);
      }
    }
  }

}
