import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, map, Subscription } from 'rxjs';
import { SettingField } from 'src/app/interfaces/database';
import { SettingFieldSortingType } from 'src/app/types/general';
import { ErrorService } from 'src/app/services/general/error.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { UpdateByService } from 'src/app/services/user/update-by.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService implements OnInit, OnDestroy {
  reservedField: SettingField = {
    custom: false,
    value: 'none',
  };
  /**
   * Sepcial request list
   */
  list: SettingField[];
  /**
   * Observable special request list
   */
  observable: any;
  /**
   * Special request subcription
   */
  private subscription: Subscription;
  /**
   * Account ID
   */
  private accountId: string;

  constructor(
    private afs: AngularFirestore,
    private updateByService: UpdateByService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
    this.list = [];
    this.observable = new BehaviorSubject<SettingField[]>(this.list);
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.unwatchSession();
    this.list = [];
  }

  /**
   * Setup Account ID and watch / unwatch special request
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
    
    if (this.accountId) {
      await this.watchSession();
    } else {
      await this.unwatchSession();
      this.list = [];
    }
  }

  /**
   * Watch special request from firestore
   */
  async watchSession() {
    
    if (this.accountId && !this.subscription) {
      this.subscription = this.afs.doc(`accounts/${ this.accountId }/accountSetting/session`)
      .snapshotChanges().pipe(map(changes => {
        const data: any = changes.payload.data();
        return data?.list;
      }), map((list: SettingField[]) => {
        if (list?.length) {
          return list.map((settingField: SettingField) => {
            if (!settingField?.id) {
              settingField.id = this.functionService.randomId() + '_' + settingField.value;
            }
            // if (settingField?.createBy) {
            //   delete settingField.createBy;
            // }
            // if (settingField?.updateBy) {
            //   delete settingField.updateBy;
            // }
            return settingField;
          });
        } else {
          return [];
        }
      })).subscribe({
        next: (list: SettingField[]) => {
          if (!this.checkReservedField(list)) {
            list.push(this.reservedField);
          }
          this.list = list;
          this.observable.next(this.list);
        }});
      }
  }

  /**
   * Unwatch special request
   */
  async unwatchSession() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  checkReservedField(settingFieldList: SettingField[]) {
    const index = settingFieldList?.findIndex((x: SettingField) => {
      return x.custom === this.reservedField.custom && x.value === this.reservedField.value;
    });
    return index !== -1;
  }

  /**
   * Search special request list
   * @param keyword Keyword
   * @param type Type
   * @param filter Filter
   * @returns List of fulfiled special request
   */
 search(keyword?: string, filter?: any, sorting?: SettingFieldSortingType, desc?: boolean): SettingField[] {
  let list: SettingField[] = this.list;
  if (list?.length) {
    if (keyword) {
      list = list.filter((specialReq: SettingField) => {
        return this.searchBykeyword(specialReq, keyword);
      });
    }

    if (filter?.enable) {
      list = list.filter((specialReq: SettingField) => {
        return this.filterByCriteria(specialReq, filter);
      });
    }
  }
  return list;
}

/**
 * Search special request by keyword
 * @param specialReq specialReq
 * @param keyword Keyword
 * @returns sorted list
 */
searchBykeyword(specialReq: SettingField, keyword: string): boolean {
  if (keyword && specialReq?.value) {
    const value = specialReq.custom ? specialReq.value :
      this.functionService.getTranslate('LIST.special_req.' + specialReq.value)?.toString().toLowerCase();
    if (this.functionService.search(value, keyword)) {
      return true;
    } else if (this.functionService.chineseMath(value, keyword)) {
      return true;
    }
  }
  return false;
}

/**
 * Filter special request by criteria
 * @param specialReq sepcial request
 * @param filter filter criteria
 */
filterByCriteria(specialReq: SettingField, filter: any): boolean {
  if (filter?.enable) {
    if (filter.type) {
      if (filter.type === 'system' && specialReq.custom) {
        return false;
      } else if (filter.type === 'user' && !specialReq.custom) {
        return false;
      }
    }

    // if (filter.createBy) {
    //   if (filter.createBy === 'system') {
    //     if (specialReq.custom) {
    //       return false;
    //     }
    //   } else {
    //     if (!specialReq.createBy || !specialReq.createBy.uid || specialReq.createBy.uid !== filter.createBy) {
    //       return false;
    //     }
    //   }
    // }

    // if (filter.updateBy) {
    //   if (!specialReq.updateBy || !specialReq.updateBy.uid || specialReq.updateBy.uid !== filter.updateBy) {
    //     return false;
    //   }
    // }
  }
  return true;
}

/**
 * Sort special request list
 * @param list list to be sorted
 * @param field Sorting type
 * @param desc Descending flag
 */
sortList(list: SettingField[], field?: SettingFieldSortingType, desc?: boolean): SettingField[] {
  if (list?.length) {
    list.sort((a: SettingField, b: SettingField) => {
      const nameA = a.custom ? a.value.toString() : this.functionService.getTranslate('LIST.special_req.' + a.value).toString();
      const nameB = b.custom ? b.value.toString() : this.functionService.getTranslate('LIST.special_req.' + b.value).toString();
      if (field) {
        // if (field === 'createdTime') {
        //   const timeA: number = a?.createBy?.time?.seconds ? a.createBy.time.seconds : 0;
        //   const timeB: number = b?.createBy?.time?.seconds ? b.createBy.time.seconds : 0;
        //   if (timeA !== timeB) {
        //     return this.functionService.compare(timeA, timeB, desc);
        //   }
        // } else if (field === 'updatedTime') {
        //   const timeA: number = a?.updateBy?.time?.seconds ? a.updateBy.time.seconds : 0;
        //   const timeB: number = b?.updateBy?.time?.seconds ? b.updateBy.time.seconds : 0;
        //   if (timeA !== timeB) {
        //     return this.functionService.compare(timeA, timeB, desc);
        //   }
        // }
        return this.functionService.compare(nameA.toLowerCase(), nameB.toLowerCase(), desc);
      } else {
        return this.functionService.compare(nameA.toLowerCase(), nameB.toLowerCase(), desc);
      }
      return this.functionService.compare(nameA.toLowerCase(), nameB.toLowerCase(), false);
    });
  }
  return list;
}

/**
 * Save special request into firestore
 * @param list Sepcial request list
 */
async save(list: SettingField[]) {
  if (this.accountId && list?.length) {
    const data: any = {
      list,
      updateBy: this.updateByService.updateBy
    };

    const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/session/`);
    accountsRef.set(data, { merge: true }).then(result => {
    }).catch((err: any) => {
      this.errorService.logError(err);
    });
  }
}
}
