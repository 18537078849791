import { ProductAccountPageParam } from 'src/app/interfaces/product';
import { DebugModeService } from 'src/app/services/general/debug-mode.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { PlatformType } from 'src/app/types/device';
import { StripeCheckoutSessionPaymentStatus, StripeCheckoutSessionStatus, TransactionType } from 'src/app/types/transaction';

@Injectable({
  providedIn: 'root'
})
export class StripeStatusService implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private debugModeService: DebugModeService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  async goStripeStatusPage(sessionId: string, currency: string, transactionType: TransactionType,
    platformType: PlatformType, sessionUid: string, productAccountPageParam?: ProductAccountPageParam,
    sessionStatus?: StripeCheckoutSessionStatus, paymentStatus?: StripeCheckoutSessionPaymentStatus,
    creditIdList?: string[], smsUsageId?: string, smsUnpaidIdList?: string[], time?: any) {
    const navigationExtras: NavigationExtras = {
      replaceUrl: true,
      state: {
        debugMode: this.debugModeService.debugMode,
        sessionId,
        currency,
        platformType,
        transactionType,
        sessionUid,
        productAccountPageParam,
        sessionStatus,
        paymentStatus,
        creditIdList,
        smsUsageId,
        smsUnpaidIdList,
        time,
      }
    };
    await this.router.navigate(['/payment/stripe-status'], navigationExtras);
  }
}
