import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalController, ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Subscription } from 'rxjs';

import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { GiftManageService } from 'src/app/services/gift/gift-manage.service';
import { GiftDeleteService } from 'src/app/services/gift/gift-delete.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GroupService } from 'src/app/services/group/group.service';
import { GroupListService } from 'src/app/services/group/group-list.service';
import { CurrencyService } from 'src/app/services/general/currency.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { GuestListComponent } from 'src/app/components/guest/guest-list/guest-list.component';

import { Currency } from 'src/app/interfaces/database';
import { Guest } from 'src/app/interfaces/guest';
import { Gift } from 'src/app/interfaces/gift';
import { Group } from 'src/app/interfaces/group';
import { GiftType } from 'src/app/types/gift';
import { ModuleType } from 'src/app/types/general';
import { KeyboardService } from 'src/app/services/general/keyboard.service';
import { SearchSelectListComponent } from 'src/app/components/general/search-select-list/search-select-list.component';
import { CurrentPrivilege } from 'src/app/interfaces/privilege';

/**
 * Gift manage componennt
 */
@Component({
  selector: 'app-gift-manage',
  templateUrl: './gift-manage.component.html',
  styleUrls: ['./gift-manage.component.scss'],
})
export class GiftManageComponent implements OnInit, OnDestroy {

  /**
   * Currency ionic selectable viewchild
   */
  // @ViewChild('currencyIonicSelect', { static: false }) currencyIonicSelect: IonicSelectableComponent;

  /**
   * Component mode for add new gift
   */
  @Input() componentMode: boolean;
  /**
   * Index of current gift
   */
  @Input() index: number;
  /**
   * Total gift list
   */
  @Input() total: number;
  /**
   * Set gift
   */
  @Input() set setGift(gift: Gift) {
    if (gift) {
      this.gift = gift;
      this.initialize();
    } else {
      this.unwatch();
    }
  }
  /**
   * Output of current gift
   */
  @Output() output  = new EventEmitter<any>();

  currentPrivilege: CurrentPrivilege;
  /**
   * Module
   */
  module: ModuleType;
  /**
   * Currency list
   */
  // currencyList: Currency[] = this.currencyService.currencyList;
  /**
   * Bulk edit mode
   */
  bulkEditMode: boolean;
  /**
   * New gift mode
   */
  newGiftMode: boolean;
  /**
   * Gift type
   */
  giftType: GiftType;
  /**
   * Form
   */
  form: FormGroup;
  /**
   * Validation msg
   */
  validationMsg: any;
  /**
   * Footer height
   */
  footerHeight = 44;
  /**
   * Gift guest / group name
   */
  from: string;
  /**
   * Selected Gift List
   */
  selectedGiftList: string[];

  /**
   * Gift
   */
  private gift: Gift;
  /**
   * Gift Guest ID list
   */
  private guestIdList: string[];
  /**
   * Gift Group ID list
   */
   private groupIdList: string[];
  /**
   * Disable guest list
   */
  private disableGuestList: boolean;
  /**
   * Selected currency
   */
  private selectedCurrency: Currency;
  /**
   * Gift list subscription
   */
  private giftListSubscription: Subscription;

  private privilegeSubscription: Subscription;

  /**
   * Constructor
   * @param formBuilder form builder
   * @param modalController modal controller
   * @param actionSheetController action sheet controller
   * @param translate translate service
   * @param groupService group service
   * @param giftService gift service
   * @param giftManageService gift manage service
   * @param giftDeleteService gift delete service
   * @param guestService guest service
   * @param currencyService currency service
   * @param popupService popup service
   * @param functionService function service
   */
  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private translate: TranslateService,
    private groupService: GroupService,
    private groupListService: GroupListService,
    private giftService: GiftService,
    private giftManageService: GiftManageService,
    private giftDeleteService: GiftDeleteService,
    private guestService: GuestService,
    private currencyService: CurrencyService,
    private moduleService: ModuleService,
    private privilegeService: PrivilegeService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unwatch();
  }

  /**
   * Before view will enter
   */
  ionViewWillEnter() {
    if (!this.componentMode && !this.newGiftMode && !this.bulkEditMode) {
      this.watchGiftList();
    } else if (this.newGiftMode) {

    }
    this.initialize();
  }

  /**
   * After view will enter
   */
  ionViewWillLeave() {
    this.unwatch();
  }

  /**
   * Initial gift component
   */
  initialize() {
    if (this.module) {
      this.module = this.moduleService.currentModule;
    }
    if (this.gift?.giftType) { this.giftType = this.gift.giftType; }
    if (!this.giftType) { this.giftType = 'cash'; }
    this.from = '';
    this.selectedCurrency = this.currencyService.getAccountCurrency();

    this.setupForm();
    if (!this.bulkEditMode) {
      this.setupFromName();
    }

    this.watchPrivilege();
  }

  unwatch() {
    this.unwatchGiftList();
    this.unwatchPrivilege();
  }

  /**
   * Watch gift list
   */
  async watchGiftList() {
    if (!this.giftListSubscription) {
      this.giftListSubscription = this.giftService.observableGiftList.subscribe(() => {
        this.setupGift();
      });
    }
  }

  /**
   * Unwatch gift list
   */
  async unwatchGiftList() {
    if (this.giftListSubscription) {
      this.giftListSubscription.unsubscribe();
      this.giftListSubscription = null;
    }
  }

  async watchPrivilege() {
    if (!this.privilegeSubscription) {
      this.privilegeSubscription = this.privilegeService.observableCurrentPrivilege.subscribe(() => {
        this.setupPrivilege();
      })
    }
  }

  async unwatchPrivilege() {
    if (this.privilegeSubscription) {
      this.privilegeSubscription.unsubscribe();
      this.privilegeSubscription = null;
    }
  }

  setupPrivilege() {
    if (!this.currentPrivilege) {
      this.currentPrivilege = {};
    }
    this.currentPrivilege = {
      'gift': {
        'delete': this.checkPrivilege('gift', 'delete'),
      }
    };
  }

  /**
   * Setup gift
   */
  setupGift() {
    if (this.gift?.giftId) {
      const gift = this.giftService.getGiftById(this.gift.giftId);
      if (!this.isEqual(this.gift, gift)) {
        this.setupFormValue(gift);
      }
    }
  }

  /**
   * Setup form
   */
  setupForm() {
    this.form = this.formBuilder.group({
      name: new FormControl('',
        [ this.giftType === 'other' && !this.bulkEditMode ? Validators.required : Validators.nullValidator ]),
      qty: new FormControl(
        this.bulkEditMode ? '' : 1,
        [ !this.bulkEditMode ? Validators.required : Validators.nullValidator, Validators.pattern('^[0-9]*$'), Validators.min(1) ]),
      amount: new FormControl(
        this.bulkEditMode ? '' : 0,
        [ !this.bulkEditMode ? Validators.required : Validators.nullValidator, Validators.pattern('^[0-9]*$'), Validators.min(0) ]),
      currency: new FormControl(
        !this.bulkEditMode && this.selectedCurrency?.code ? this.selectedCurrency.code : '',
        [ !this.bulkEditMode ? Validators.required : Validators.nullValidator ])
    });
    this.setupValidationMsg();
    this.setupAmountValidator();
    if (!this.bulkEditMode) {
      this.setupFormValue();
    }
  }

  /**
   * Setup validation msg
   */
  setupValidationMsg() {
    this.validationMsg = {
      amount: [
        { type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('LBL.amount') }) },
        { type: 'pattern', msg: this.translate.instant('VALIDATION.currency') },
        { type: 'min', msg: this.translate.instant('VALIDATION.min_number', { number: 0 }) },
      ],
      currency: [
        { type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('LBL.currency') }) }
      ],
      name: [
        { type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('GIFT.lbl.name') }) }
      ],
      qty: [
        { type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('LBL.qty') }) },
        { type: 'pattern', msg: this.translate.instant('VALIDATION.number_only') },
        { type: 'min', msg: this.translate.instant('VALIDATION.min_number', { number: 1 }) },
      ],
    };
  }

  /**
   * Setup form value
   * @param gift gift
   */
  setupFormValue(gift?: Gift) {
    if (this.form) {
      if (gift) {
        if (gift.name !== this.gift.name && this.gift.name === this.form.value.name) {
          this.form.controls.name.setValue(gift.name);
        }
        if (gift.qty !== this.gift.qty && this.gift.qty === this.form.value.qty) {
          this.form.controls.qty.setValue(gift.qty);
        }
        if (gift.amount !== this.gift.amount && this.gift.amount === this.form.value.amount) {
          this.form.controls.amount.setValue(gift.amount);
        }
        if (gift.currency !== this.gift.currency && this.gift.currency === this.form.value.currency) {
          this.form.controls.currency.setValue(gift.currency);
        }
        if (gift.giftType !== this.gift.giftType && this.gift.giftType === this.giftType) {
          this.giftType = gift.giftType;
        }
        if (!this.isEqual(gift.guestIdList, this.gift.guestIdList) && this.isEqual(this.gift.guestIdList, this.guestIdList)) {
          this.guestIdList = gift.guestIdList;
          this.setupFromName();
        }
        if (!this.isEqual(gift.groupIdList, this.gift.groupIdList) && this.isEqual(this.gift.groupIdList, this.groupIdList)) {
          this.groupIdList = gift.groupIdList;
          this.setupFromName();
        }
        this.gift = gift;
      } else if (this.gift) {
        this.giftType = this.gift?.giftType ? this.gift.giftType : 'cash';
        this.form.controls.name.setValue(this.gift.name ? this.gift.name : '');
        this.form.controls.qty.setValue(this.gift.qty ? this.gift.qty : 1);
        this.form.controls.currency.setValue(
          this.gift.currency ? this.gift.currency : this.selectedCurrency?.code ? this.selectedCurrency.code : 'SGD');
        this.form.controls.amount.setValue(this.gift.qty ? this.gift.amount : 0);
        if (!this.newGiftMode) {
          this.guestIdList = this.gift?.guestIdList ? [ ...this.gift.guestIdList ] : [];
          this.groupIdList = this.gift?.groupIdList ? [ ...this.gift.groupIdList ] : [];
        }
        this.setupFromName();
      }
    }
  }

  /**
   * Setup output for component mode
   * @param next next flag
   */
  setupOutput(next: boolean) {
    if (this.form.valid && this.componentMode) {
      this.output.next({ gift: this.getGiftData(), next });
    }
  }

  giftTypeChanged() {
    if (this.giftType === 'cash') {
      this.form.controls.amount.setValidators([ Validators.required ]);
      this.form.controls.name.setValidators([ ]);
    } else if (this.giftType === 'other') {
      this.form.controls.amount.setValidators([ ]);
      this.form.controls.name.setValidators([ Validators.required ]);
    }
    this.form.updateValueAndValidity();
  }

  /**
   * Check is two variable is equal
   * @param a variable a
   * @param b variable b
   * @returns true if two varible is equal
   */
  isEqual(a: any, b: any): boolean {
    return this.functionService.isEqual(a, b);
  }

  /**
   * Dismiss modal
   * @param gift gift
   * @param dismiss dismiss
   */
  async dismissModal(gift?: Gift, dismiss?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ gift, dismiss }); }
    }
  }

  /**
   * Present guest list modal
   */
  async presentGuestListModal() {
    // await this.popupService.presentLoading();
    if (!this.disableGuestList) {
      const selectedGuestList = this.groupIdList?.length
      ? this.guestIdList.concat(this.groupService.getGroupListMember(this.groupIdList))
      : this.guestIdList;
      const modal = await this.modalController.create({
        component: GuestListComponent,
        componentProps: {
          selectMode: true,
          selectedGuestList,
          selectedGroupList: this.groupIdList,
          mode: 'gift'
        }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result?.data?.selectedGroupList?.length) {
          this.groupIdList = result.data.selectedGroupList;
        }
        if (result?.data?.selectedGuestList?.length) {
          this.guestIdList = this.groupListService.excludeSelectedGroupMember(result.data.selectedGuestList, this.groupIdList);
        }
        this.setupFromName();
      });
    } else {
      this.popupService.presentActionError();
    }
    // this.popupService.dismissLoading();
  }

  /**
   * Setup from name
   */
  setupFromName() {
    this.from = '';
    if (this.groupIdList?.length) {
      const groupList: Group[] = this.groupService.getGroupListById(this.groupIdList);
      groupList?.forEach((group: Group) => {
        if (group?.groupId && group?.groupName && group?.memberList?.length) {
          if (this.from) {
            this.from += ', ';
          }
          this.from += group.groupName;
        }
      });
    }
    if (this.guestIdList?.length) {
      const guestList: Guest[] = this.guestService.getGuestListById(this.guestIdList);
      guestList?.forEach((guest: Guest) => {
        if (guest?.guestId && guest?.name && !guest?.status?.deleted) {
          if (this.from) {
            this.from += ', ';
          }
          this.from += guest.name;
        }
      });
    }
  }

  /**
   * Discard
   */
  async discard() {
    let flag = true;
    if (this.gift) {
      Object?.keys(this.form.value)?.forEach((key: string) => {
        if (this.form.value[key] !== this.gift[key]) {
          flag = false;
        }
      });
      if (this.gift.giftType !== this.giftType) {
        flag = false;
      }
      if (!this.isEqual(this.gift.guestIdList, this.guestIdList)) {
        flag = false;
      }
      if (!this.isEqual(this.gift.groupIdList, this.groupIdList)) {
        flag = false;
      }
    }

    if (!flag) {
      const actionSheet = await this.actionSheetController.create({
        header: this.translate.instant('MSG.discard_msg'),
        buttons: [{
          text: this.translate.instant('BTN.confirm'),
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            this.dismissModal();
          }
        }, {
          text: this.translate.instant('BTN.cancel'),
          icon: 'close',
          role: 'cancel',
          handler: () => {
          }
        }]
      });
      actionSheet.present();
    }  else {
      this.dismissModal();
    }
  }

  /**
   * Add gift qty
   */
  addQty() {
    if (this.form.value?.qty) {
      this.form.controls.qty.setValue(this.form.value.qty + 1);
    } else {
      this.form.controls.qty.setValue(1);
    }
  }

  /**
   * Minus gift qty
   */
  minusQty() {
    if (this.form.value?.qty > 1) {
      this.form.controls.qty.setValue(this.form.value.qty - 1);
    } else {
      this.popupService.presentToast(this.translate.instant('VALIDATION.min_number', { number: 1 }), 'warning');
    }
  }

  /**
   * Currency change and set selected currency
   * @param event event
   */
  currencyChange(currency: Currency) {
    if (currency?.code) {
      this.form.controls.currency.setValue(currency.code);
      if (this.selectedCurrency?.code !== currency.code) {
        this.selectedCurrency = currency;
        this.setupAmountValidator();
      }
    }
  }

  /**
   * Setup amount validator
   */
  setupAmountValidator() {
    if (this.selectedCurrency) {
      let currencyValidator = Validators.pattern('^[0-9]*$');
      if (this.selectedCurrency?.decimalDigits >= 1) {
        currencyValidator = Validators.pattern('^[0-9]+(.[0-9]{0,' + this.selectedCurrency.decimalDigits + '})?$');
      }
      this.form.controls.amount.setValidators([ currencyValidator, Validators.required, Validators.min(0) ]);
      this.form.controls.amount.updateValueAndValidity();
    }
  }

  /**
   * Check amount
   */
  checkAmount() {
    this.form.controls.amount.setValue(Number(this.form.value.amount));
  }

  /**
   * Check user privilege
   * @param action Action
   */
  checkPrivilege(module: ModuleType, action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege(module, action);
  }

  /**
   * Open currency
   */
  async openCurrency() {
    const modal = await this.modalController.create({
      component: SearchSelectListComponent,
      componentProps: {
        items: this.currencyService.getCurrencyList(),
        selected: this.selectedCurrency,
        title: this.translate.instant('LBL.currency'),
        itemTextField: 'label',
        placeholder: this.translate.instant('BTN.search'),
        closeButtonText: this.translate.instant('BTN.cancel'),
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.item) {
        this.currencyChange(result.data.item);
      }
    });
  }

  /**
   * Get gift data
   * @returns gift
   */
  getGiftData(): Gift {
    const guestIdList: string[] = this.gift?.guestIdList ? [ ...this.gift.guestIdList ] : [];
    const groupIdList: string[] = this.gift?.groupIdList ? [ ...this.gift.groupIdList ] : [];
    if (this.guestIdList?.length) {
      this.guestIdList?.forEach((guestId: string) => {
        if (guestIdList?.indexOf(guestId) === -1) {
          guestIdList.push(guestId);
        }
      });
    }
    if (this.groupIdList?.length) {
      this.groupIdList?.forEach((groupId: string) => {
        if (groupIdList?.indexOf(groupId) === -1) {
          groupIdList.push(groupId);
        }
      });
    }
    return {
      giftId: this.gift?.giftId ? this.gift.giftId : this.giftService.getNewGiftId(),
      giftType: this.giftType,
      name: this.form?.value?.name ? this.form.value.name : '',
      qty: this.form?.value?.qty ? this.form.value.qty : 1,
      currency: this.form?.value?.currency ? this.form.value.currency : '',
      amount: this.form?.value?.amount ? this.form.value.amount : 0,
      guestIdList,
      groupIdList,
      delete: this.gift?.delete ? true : false,
      createBy: this.gift?.createBy ? this.gift.createBy : null,
      updateBy: this.gift?.updateBy ? this.gift.updateBy : null
    };
  }

  /**
   * Form submit
   */
  async formSubmit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      if (this.giftType === 'cash' && !this.form?.value.amount) {
        this.form.controls.amount.setErrors({ required: true });
      } else if (this.bulkEditMode || this.guestIdList?.length || this.groupIdList?.length) {
        if (this.componentMode) {
          this.setupOutput(true);
        } else {
          await this.popupService.presentLoading();
          const data: any = {};
          data.delete = false;
          Object?.keys(this.form?.value)?.forEach((key: string) => {
            if (this.bulkEditMode) {
              if (this.form?.value?.[key]) {
                data[key] = this.form.value[key];
              }
            } else {
              if (!this.gift || !this.gift[key] || this.form.value[key] !== this.gift[key]) {
                data[key] = this.form.value[key];
              }
            }
          });
          if ((this.bulkEditMode  && this.guestIdList?.length) ||
          (!this.bulkEditMode && !this.functionService.isEqual(this.gift?.guestIdList, this.guestIdList))) {
            if (this.gift?.guestIdList?.length) {
              data.guestIdList = [ ...this.gift.guestIdList ];
              this.guestIdList?.forEach((guestId: string) => {
                if (data.guestIdList?.indexOf(guestId) === -1) {
                  data.guestIdList.push(guestId);
                }
              });
            } else {
              data.guestIdList = [ ...this.guestIdList ];
            }
          } else if (this.newGiftMode) {
            data.guestIdList = [];
          }
          if ((this.bulkEditMode  && this.groupIdList.length) ||
          (!this.bulkEditMode && !this.functionService.isEqual(this.gift?.groupIdList, this.groupIdList))) {
            if (this.gift?.guestIdList?.length) {
              data.groupIdList = [ ...this.gift.groupIdList ];
              this.groupIdList?.forEach((groupId: string) => {
                if (data.groupIdList?.indexOf(groupId) === -1) {
                  data.groupIdList.push(groupId);
                }
              });
            } else {
              data.groupIdList = [ ...this.groupIdList ];
            }
          } else if (this.newGiftMode) {
            data.groupIdList = [];
          }
          if ((this.bulkEditMode  && this.giftType) || (!this.bulkEditMode && this.gift?.giftType !== this.giftType)) {
            data.giftType = this.giftType;
          }
          if (data) {
            if (this.bulkEditMode) {
              if (this.selectedGiftList?.length) {
                await this.giftManageService.saveGiftList(this.selectedGiftList, data);
              }
            } else {
              const giftId = this.gift?.giftId ? this.gift.giftId : this.giftService.getNewGiftId();
              if (this.newGiftMode) {
                data.giftId = giftId;
              }
              if (giftId) {
                await this.giftManageService.saveGiftList([ giftId ], data, null, this.newGiftMode ? 'new' : '');
              }
            }
          }
          this.dismissModal();
          this.popupService.dismissLoading();
          this.popupService.saveSuccessToast();
        }
      }
    }
  }

  /**
   * Prompt delete
   * @param slidingItem sliding item
   */
  async promptRemove(slidingItem?: any) {
    if (this.gift?.giftId) {
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_field', {
          field: this.translate.instant('GIFT.lbl.gift')
        })
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.giftDeleteService.removeGiftList([ this.gift.giftId ], [ this.gift ]);
          this.dismissModal(null, true);
        }
      });
    }
    if (slidingItem) { slidingItem.close(); }
  }

}
