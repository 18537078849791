import { Component, OnDestroy, OnInit } from '@angular/core';

/**
 * Offline popover component
 */
@Component({
  selector: 'app-offline-popover',
  templateUrl: './offline-popover.component.html',
  styleUrls: ['./offline-popover.component.scss'],
})
export class OfflinePopoverComponent implements OnInit, OnDestroy {

  /**
   * Constructor
   */
  constructor() { }

  ngOnInit() {}

  ngOnDestroy() {}

}
