<ion-content *ngIf="!pageMode">
  <div class="full-height vertical-center">
    <ion-card class="center">
      <ion-card-header class="ion-no-padding ion-padding-vertical">
        <ion-toolbar class="background-transparent">
          <ion-buttons slot="start">
            <ion-button (click)="back()">
              <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-title>
            <app-offline-indicator></app-offline-indicator>
            {{ 'MOBILE.lbl.authentication' | translate }}
          </ion-title>
        </ion-toolbar>
      </ion-card-header>
      <ion-card-content class="ion-margin-vertical">
        <ng-container *ngIf="true then form"></ng-container>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>

<ng-container *ngIf="pageMode">
  <ng-container *ngIf="true then form"></ng-container>
</ng-container>

<ng-template #form>
  <ng-container *ngIf="actionType === 'mobile'">
    <div class="ion-margin-vertical ion-text-left"><ion-text>{{ 'MOBILE.msg.mobile' | translate }}</ion-text></div>
    <div class="ion-padding-vertical">
      <app-mobile class="full-width-height" [otpMode]="true" [setCssClass]="''" (outputMobile)="updateMobile($event)" (outputMobileInvalid)="updateMobileInvalid($event)"></app-mobile>
      <ion-button class="ion-margin-top" expand="block" color="primary" fill="solid" type="button" (click)="requestOtp()" [disabled]="!mobile?.no || mobileInvalid">
        {{ 'MOBILE.btn.request_otp' | translate }}
      </ion-button>
    </div>
  </ng-container >
  
  <form [formGroup]="otpForm" (ngSubmit)="verifyOtp()" *ngIf="otpForm && actionType === 'otp'">
    <div class="ion-margin-bottom ion-text-left"><ion-text color="dark">{{ 'MOBILE.msg.otp' | translate }}</ion-text></div>
    <div class="ion-text-left">
      <ion-input class="prompt-input" formControlName="otp" type="tel" inputmode="numeric" autocomplete="one-time-code" placeholder="{{ 'MOBILE.lbl.otp' | translate }}" (ionInput)="autoSubmitOTP($event.target.value)" clearInput #otpInput></ion-input>
      <div class="error-div">
        <ng-container *ngFor="let validation of validationMsg?.otp">
          <div class="error-message" *ngIf="otpForm.get('otp').hasError(validation.type) && (otpForm.get('otp').dirty || otpForm.get('otp').touched)">
            <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
          </div>
        </ng-container>
      </div>
      <div>
        <div class="ion-text-left font-12" *ngIf="countdownConfig">
          <ion-text color="dark">{{ 'MOBILE.msg.resend_in' | translate }} <countdown #cd [config]="countdownConfig" (event)="handleEvent($event)"></countdown> {{ 'LBL.second_s' | translate }}</ion-text>
        </div>
        <div class="ion-text-left" *ngIf="!countdownConfig">
          <span class="font-12" (click)="requestOtp()"><u>{{ 'MOBILE.btn.resend_otp' | translate }}</u></span>
        </div>
      </div>
      
      <ion-button class="ion-margin-top" expand="block" color="primary" fill="solid" type="submit" [disabled]="!otpForm.valid">
        {{ 'BTN.verify' | translate }}
      </ion-button>
    </div>
  </form>
</ng-template>

<div id="recaptcha-container"></div>