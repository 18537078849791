import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SettingField } from 'src/app/interfaces/database';
import { BlastType } from 'src/app/types/blast';


@Component({
  selector: 'app-blast-template-type',
  templateUrl: './blast-template-type.component.html',
  styleUrls: ['./blast-template-type.component.scss'],
})
export class BlastTemplateTypeComponent  implements OnInit, OnDestroy {

  blastType: BlastType;
  templateTypeList: SettingField[];
  selected: SettingField;

  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  async dismissModal(selected?: SettingField) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { modal.dismiss({ selected }); }
    }
  }

  checkSelected(settingField: SettingField) {
    return settingField?.value === this.selected?.value && settingField?.custom === this.selected?.custom;
  }

  select(selected: SettingField) {
    this.selected = selected;
    this.dismissModal(selected);
  }

}
