<ion-header>
  <ion-toolbar [color]="module === 'trash' ? 'danger' : 'light'">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'GROUP.lbl.guest_group' | translate }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="presentGroupLogModal()" *ngIf="group?.groupId">
        <ion-icon src="./assets/wedding/icon/guest/log.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="deleted-titlebar" color="danger" *ngIf="module === 'trash'">
    <ion-title class="font-12">{{ 'MSG.deleted_title_field' | translate: { field: 'GROUP.lbl.guest_group' | translate } }}</ion-title>
  </ion-toolbar>
  <ion-toolbar class="toolbar-select-all" color="light">
    <app-select-all [total]="group?.memberList?.length" [selected]="selectedGuestList?.length" [selectAll]="selectAll" [editMode]="editMode" (toggleSelect)="toggleSelectAll()"></app-select-all>
  </ion-toolbar>
</ion-header>
<ion-content color="light" #content>
  <div class="full-width-height viewport-div ion-padding-horizontal">
    <ng-container *ngIf="group?.memberList?.length else groupMemberBlock">
      <ion-list class="full-width-height viewport-div background-transparent ion-padding-bottom" lines="none" #list>
        <app-guest-card class="full-width-height" [mode]="mode" [setGuest]="{ group: group }" [guestId]="guest?.guestId" [editMode]="editMode" [selectedGuestList]="selectedGuestList" [selectedGroupList]="selectedGroupList" [index]="0" [groupModal]="true" [showGuestSeating]="module === 'checkin' ? true : false" (setSelectedGuest)="setSelectedGuest($event)" (setSelectedGroup)="setSelectedGroup($event)" (checkSelectedGroup)="checkSelectedGroup($event)"></app-guest-card>
      </ion-list>
    </ng-container>
    <ng-template #groupMemberBlock>
      <div class="full-width-height center vertical-center">
        <ion-label class="ion-text-center ion-margin ion-padding" color="medium">
          <ion-icon class="center icon-cactus" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
          <div class="ion-margin-top font-14">{{ 'LBL.no_found' | translate }}</div>
        </ion-label>
      </div>
    </ng-template>
  </div>
  <ion-fab horizontal="end" vertical="bottom" slot="fixed" *ngIf="module !== 'gift' && module !== 'account' && group?.groupId">
    <ion-fab-button color="primary" (click)="toggleEditMode()">
      <ion-icon src="./assets/wedding/icon/general/chevron-up.svg" *ngIf="!editMode"></ion-icon>
      <ion-icon src="./assets/wedding/icon/general/close.svg" *ngIf="editMode"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
<ion-footer>
  <ion-toolbar *ngIf="!editMode else bulkActionBlock">
    <ion-button class="ion-margin" expand="block" *ngIf="module === 'guest'" (click)="presentGuestListModal()">
      {{ 'CRUD.add_field' | translate: { field: 'GROUP.lbl.member' | translate } }}
    </ion-button>
    <ng-container *ngIf="module === 'checkin'">
      <ion-grid fixed>
        <ion-row>
          <ng-container *ngIf="checkinSetting?.giftRecord">
            <ion-col *ngIf="!group?.status?.gift && currentPrivilege?.[module]?.['checkin']">
              <ion-button class="ion-margin" expand="block" color="tertiary" (click)="saveGift(true)">
                {{ 'GIFT.btn.group_receive' | translate }}
              </ion-button>
            </ion-col>
            <ion-col *ngIf="group?.status?.gift && currentPrivilege?.[module]?.['uncheck']">
              <ion-button class="ion-margin" expand="block" color="grey" (click)="saveGift(false)">
                {{ 'GIFT.btn.group_delete' | translate }}
              </ion-button>
            </ion-col>
          </ng-container>
          <ion-col *ngIf="absentCount === 0 && currentPrivilege?.[module]?.['uncheck']">
            <ion-button class="ion-margin" expand="block" color="grey" (click)="uncheck(true)">
              {{ 'CHECKIN.btn.group_uncheck' | translate }}
            </ion-button>
          </ion-col>
          <ion-col *ngIf="absentCount > 0 && currentPrivilege?.[module]?.['checkin']">
            <ion-button class="ion-margin" expand="block" color="primary" (click)="checkin(true)">
              {{ 'CHECKIN.btn.group_checkin' | translate }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ng-container>
    <ng-container *ngIf="module === 'trash' && group?.groupId">
      <ion-grid class="full-width" fixed>
        <ion-row>
          <ion-col>
            <ion-button expand="block" color="success" (click)="promptRestore(true)">
              {{ 'CRUD.restore' | translate }}
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button expand="block" color="danger" (click)="promptRemoveGroup()">
              {{ 'CRUD.delete' | translate }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ng-container>
  </ion-toolbar>
  <ng-template #bulkActionBlock>
    <app-bulk-action [mode]="'group'" [selectedList]="selectedGuestList" [count]="group?.memberList?.length" (action)="bulkAction($event)" (toggleEditMode)="toggleEditMode($event)"></app-bulk-action>
  </ng-template>
</ion-footer>
