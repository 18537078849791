<ion-header class="header-light">
  <ion-toolbar class="gradient-primary-right">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'LBL.profile' | translate }}
    </ion-title>
    <ion-buttons slot="end" *ngIf="guest?.guestId && currentPrivilege?.[module]?.['edit']">
      <ion-button (click)="presentGuestEditModal(guest)">
        <ion-icon  class="font-20" color="dark" src="./assets/wedding/icon/general/edit.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="gradient-primary-right ion-text-center">
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col style="padding: 0 10px;" *ngIf="guest?.status?.checkin">
          <div>
            <ion-icon class="icon-large" src="./assets/wedding/icon/checkin/tick-round.svg"></ion-icon>
            <div class="font-10">{{ 'LIST.checkin.attended' | translate }}</div>
          </div>
        </ion-col>
        <ion-col style="padding: 0 10px;" *ngIf="!guest?.status?.checkin">
          <div>
            <ion-icon class="icon-large" src="./assets/wedding/icon/checkin/close-round.svg"></ion-icon>
            <div class="font-10">{{ 'LIST.checkin.not_attend' | translate }}</div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div class="ion-margin-top">
      <div class="ion-text-wrap margin-5" *ngIf="guest?.name">
        <ion-text class="font-20 line-110 font-bold">{{ guest.name }}</ion-text>
      </div>
      <div class="ion-text-wrap margin-5" *ngIf="guest?.nickname">
        <ion-text class="font-12 line-110 font-italic">{{ guest.nickname }}</ion-text>
      </div>
      <div class="font-10 margin-5">
        <ion-text class="font-10 line-110 ion-text-wrap">
          <ng-container *ngIf="invitedBy">
            {{ invitedBy }}
          </ng-container>
          <ng-container *ngIf="guest.invitedBy?.length && guest.category?.length">&nbsp;|&nbsp;</ng-container>
          <ng-container *ngIf="category">
            {{ category }}
          </ng-container>
        </ion-text>
      </div>
    </div>
  </ion-toolbar>
  <ion-toolbar class="gradient-primary-right ion-no-padding">
    <ion-card class="table-card ion-padding ion-text-center" (click)="presentSeatingDetailModal(guest?.seating)" button>
      <div>
        <ion-label class="font-18 font-bold" color="dark">
          <ion-icon class="icon-small" style="margin-right: 5px;" src="./assets/wedding/icon/guest/seating-outline.svg"></ion-icon>
        </ion-label>
      </div>
      <fit-line [minFontSize]="10" [maxFontSize]="50" [calcOnResize]="true">
        <div class="text-ellipsis font-bold" style="margin: 5px 0;">
          <ion-label class="text-ellipsis font-bold" color="dark">{{ guest?.seating ? guest.seating : '-'}}</ion-label>
        </div>
      </fit-line>
      <div>
        <ion-label class="font-10 font-bold" color="dark">
          <ng-container *ngIf="seating else seatingTitle">
            <ng-container *ngIf="seating?.count > 1 else seatingTitle">
              <span class="font-bold ion-text-uppercase font-20 free-seating">
                {{ 'SEATING.lbl.free_seating' | translate }}
              </span>
            </ng-container>
          </ng-container>
          <ng-template #seatingTitle>
            {{ seatingTypeName }}
          </ng-template>
        </ion-label>
      </div>
    </ion-card>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <div class="padding-6 ion-text-center ion-padding-bottom" *ngIf="guest">
    <ion-grid class="full-width" fixed>
      <ion-row>
        <ion-col>
          <ion-card class="ion-no-margin vertical-center card-status" button>
            <ion-item class="" lines="none">
              <ion-label>
                <div>
                  <ion-icon class="font-36" src="./assets/wedding/icon/guest/status/pending-round-background.svg" *ngIf="guest?.status.attending === 'pending'"></ion-icon>
                  <ion-icon class="font-36" src="./assets/wedding/icon/guest/status/attending-round-background.svg" *ngIf="guest?.status.attending === 'attending'"></ion-icon>
                  <ion-icon class="font-36" src="./assets/wedding/icon/guest/status/not-attending-round-background.svg" *ngIf="guest?.status.attending === 'not_attending'"></ion-icon> 
                </div>
                <ion-label class="font-10 font-med ion-text-wrap line-100 status-lbl" color="dark">{{ 'LBL.status' | translate }}</ion-label>
              </ion-label>
              <ion-text class="font-14 font-med ion-text-wrap line-100" color="dark">
                <ng-container *ngIf="guest?.status?.attending === 'pending'">{{ 'LIST.attending_status.pending' | translate }}</ng-container>
                <ng-container *ngIf="guest?.status?.attending === 'attending'">{{ 'LIST.attending_status.attending' | translate }}</ng-container>
                <ng-container *ngIf="guest?.status?.attending === 'not_attending'">{{ 'LIST.attending_status.not_attending' | translate }}</ng-container>
              </ion-text>
            </ion-item>
          </ion-card>
        </ion-col>
        <ion-col>
          <ion-card class="ion-no-margin vertical-center card-status" (click)="presentGroupModal(group)" button>
            <ion-item class="" lines="none">
              <ion-label>
                
                <div>
                  <ion-icon class="font-30" src="./assets/wedding/icon/guest/group-green.svg" *ngIf="guest?.groupId"></ion-icon>
                  <ion-icon class="font-30" src="./assets/wedding/icon/guest/individual.svg" *ngIf="!guest?.groupId"></ion-icon>
                </div>
                <ion-label class="font-10 font-med ion-text-wrap line-100" color="dark">{{ 'LIST.group.group' | translate }}</ion-label>
              </ion-label>
              <ion-text class="font-14 font-med ion-text-wrap line-100" color="dark">
                <ng-container *ngIf="guest?.groupId && group else groupBlock">
                  {{ group?.memberList?.length }}&nbsp;{{ 'GUEST.lbl.guests' | translate}}
                </ng-container>
                <ng-template #groupBlock>-</ng-template>
              </ion-text>
            </ion-item>
          </ion-card>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-card class="detail-card ion-no-margin ion-no-padding" button>
            <ion-item class="ion-item-no-padding" lines="none">
              <ion-icon class="icon-small" src="./assets/wedding/icon/guest/dietary-req.svg" style="vertical-align: middle; margin-right: 5px;"></ion-icon>
              <ion-label class="font-10 font-med line-110 ion-text-wrap" color="dark">{{ 'GUEST.lbl.dietary_req' | translate }}</ion-label>
              <ion-label class="font-14 font-med ion-text-right line-110 ion-text-wrap" color="dark" slot="end">{{ dietaryReq }}</ion-label>
            </ion-item>
          </ion-card>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-card class="detail-card ion-no-margin ion-no-padding" button>
            <ion-item class="ion-item-no-padding" lines="none">
              <ion-icon class="icon-small" src="./assets/wedding/icon/guest/special-req.svg" style="vertical-align: middle; margin-right: 5px;"></ion-icon> 
              <ion-label class="font-10 font-med line-110 ion-text-wrap" color="dark">{{ 'GUEST.lbl.special_req' | translate }}</ion-label>
              <ion-label class="font-14 font-med ion-text-right line-110 ion-text-wrap" color="dark" slot="end">{{ specialReq }}</ion-label>
            </ion-item>
          </ion-card>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-card class="detail-card ion-no-margin ion-no-padding" button>
            <ion-item class="ion-item-no-padding" lines="none">
              <ion-icon class="icon-small" src="./assets/wedding/icon/guest/remark.svg" style="vertical-align: middle; margin-right: 5px;"></ion-icon> 
              <ion-label class="font-10 font-med line-110 ion-text-wrap" color="dark">{{ 'GUEST.lbl.remark' | translate }}</ion-label>
              <ion-label class="font-14 font-med ion-text-right line-110 ion-text-wrap" color="dark" slot="end">{{ guest?.remark ? guest.remark : '-' }}</ion-label>
            </ion-item>
          </ion-card>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-card class="detail-card ion-no-margin ion-no-padding" button>
            <ion-item class="ion-item-no-padding" lines="none" (click)="presentGuestLogModal()" detail>
              <ion-icon class="icon-small" color="dark" src="./assets/wedding/icon/guest/log.svg" style="vertical-align: middle; margin-right: 5px;"></ion-icon>
              <ion-text>
                <ion-text class="font-12" color="dark">
                  {{ 'CHECKIN.lbl.log' | translate }}
                </ion-text>
              </ion-text>
            </ion-item>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar color="white">
    <div class="ion-text-center">
      <div><ion-label class="font-30 font-med" color="dark">{{ checkinCount }} / {{ totalCount }}</ion-label></div>
      <div>
        <ion-label class="font-10 font-med" color="dark">
          <ng-container *ngIf="totalCount === 1">{{ 'CHECKIN.lbl.guest_checkin' | translate }}</ng-container>
          <ng-container *ngIf="totalCount > 1">{{ 'CHECKIN.lbl.guests_checkin' | translate }}</ng-container>
        </ion-label>
      </div>
    </div>
    <ion-grid class="btn-grid" *ngIf="currentPrivilege?.[module]?.['checkin'] || currentPrivilege?.[module]?.['uncheck']" fixed>
      <ion-row>
        <ion-col *ngIf="checkinSetting?.giftRecord && ((guest?.status?.gift && currentPrivilege?.[module]?.['uncheck']) || (!guest?.status?.gift && currentPrivilege?.[module]?.['checkin']))">
          <ion-button expand="block" color="light" *ngIf="guest?.status?.gift && currentPrivilege?.[module]?.['uncheck']" (click)="saveGift(false)">{{ 'MODULE.action.gift.delete' | translate }}</ion-button>
          <ion-button expand="block" color="primary" *ngIf="!guest?.status?.gift && currentPrivilege?.[module]?.['checkin']" (click)="saveGift(true)">{{ 'GIFT.btn.receive' | translate }}</ion-button>
        </ion-col>
        <ion-col *ngIf="(guest?.status?.checkin && currentPrivilege?.[module]?.['uncheck']) || (!guest?.status?.checkin && currentPrivilege?.[module]?.['checkin'])">
          <ion-button expand="block" color="light" *ngIf="guest?.status?.checkin && currentPrivilege?.[module]?.['uncheck']" (click)="promptUncheck()">{{ 'MODULE.action.checkin.uncheck' | translate }}</ion-button>
          <ion-button expand="block" color="primary" *ngIf="!guest?.status?.checkin && currentPrivilege?.[module]?.['checkin']" (click)="checkin()">{{ 'MODULE.list.checkin' | translate }}</ion-button>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="group && guest?.status?.checkin && checkinCount && totalCount > checkinCount && currentPrivilege?.[module]?.['checkin']">
        <ion-col>
          <div class="ion-text-center">
            <ion-text class="font-10 font-underline font-italic" color="dark" (click)="checkinGroupMember()">
              {{ 'CHECKIN.btn.member_checkin' | translate }}
            </ion-text>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>