<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <ng-container *ngIf="shareMode">
        {{ 'WEBSITE.link.title.share' | translate }}
      </ng-container>
      <ng-container *ngIf="!shareMode">
        {{ 'WEBSITE.link.title.' + (websiteLink?.linkId ? 'edit' : 'new') | translate }}
      </ng-container>
    </ion-title>
    <ion-buttons slot="end" *ngIf="websiteForm?.enable">
      <ng-container *ngIf="shareMode && websiteLink?.linkId">
        <ion-button (click)="shareMode = false;">
          <ion-icon slot="icon-only" color="success" src="./assets/wedding/icon/general/edit.svg"></ion-icon>
        </ion-button>
      </ng-container>
      <ng-container *ngIf="!shareMode">
        <ion-button (click)="save()" [disabled]="!form?.valid">
          <ion-icon slot="icon-only" color="success" src="./assets/wedding/icon/general/checkmark-circle.svg"></ion-icon>
        </ion-button>
      </ng-container>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <ng-container *ngIf="websiteForm?.enable">
    <ng-container *ngIf="!shareMode">
      <form class="ion-padding-horizontal" [formGroup]="form" *ngIf="form">
        <ion-item class="ion-item-no-padding" color="light" lines="none" (click)="presentSettingFieldModal('invited_by', form?.value?.invitedBy)">
          <ion-label position="stacked" color="dark">{{ 'GUEST.lbl.invited_by' | translate }}&nbsp;</ion-label>
          <ion-item class="prompt-input-item" lines="none">
            <ion-label class="ion-no-margin" >{{ getSettingField('invited_by', form.value.invitedBy) }}</ion-label>
            <ion-note slot="end"><ion-icon src="./assets/wedding/icon/general/chevron-down.svg" class="font-18"></ion-icon></ion-note>
          </ion-item>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.invitedBy">
              <div class="error-message" *ngIf="form?.get('invitedBy').hasError(validation.type) && (form?.get('invitedBy').dirty || form?.get('invitedBy').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </ion-item>
      
        <ion-item class="ion-item-no-padding" color="light" lines="none" (click)="presentSettingFieldModal('category', form?.value?.category)">
          <ion-label position="stacked" color="dark">{{ 'LBL.category' | translate }}&nbsp;</ion-label>
          <ion-item class="prompt-input-item" lines="none">
            <ion-label class="ion-no-margin" >{{ getSettingField('category', form.value.category) }}</ion-label>
            <ion-note slot="end"><ion-icon src="./assets/wedding/icon/general/chevron-down.svg" class="font-18"></ion-icon></ion-note>
          </ion-item>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.category">
              <div class="error-message" *ngIf="form?.get('category').hasError(validation.type) && (form?.get('category').dirty || form?.get('category').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </ion-item>
    
        <!-- <ion-item class="prompt-input-item plus-one-item" lines="none">
          <div class="full-width">
            <ion-toggle formControlName="group" (ionChange)="setupMaxGuest()">
              <ion-label>
                &nbsp;
              </ion-label>
              <div>
                <ion-text class="font-12 ion-text-wrap" *ngIf="form.value.group">Allow guest to plus additional guest.</ion-text>
                <ion-text class="font-12 ion-text-wrap" *ngIf="!form.value.group">Not allow guest to plus additional guest.</ion-text>
              </div>
            </ion-toggle>
          </div>
        </ion-item>
        <div class="error-div">
          <ng-container *ngFor="let validation of validationMsg?.group">
            <div class="error-message" *ngIf="form?.get('group').hasError(validation.type) && (form?.get('group').dirty || form?.get('group').touched)">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
            </div>
          </ng-container>
        </div> -->
    
        <ion-item class="ion-item-no-padding" color="light" lines="none">
          <ion-label position="stacked" color="dark">{{ 'WEBSITE.link.lbl.max_guest' | translate }} <ion-text color="danger">*</ion-text></ion-label>
          <ion-item class="background-transparent full-width ion-item-no-padding" lines="none">
            <ion-input class="prompt-input ion-text-center" formControlName="maxGuest" [min]="1" type="number" inputmode="numeric" (ionChange)="checkMaxGuest()" tabindex="1">
              <ion-button class="qty-btn" slot="start" (click)="minusPax()" fill="clear">
                <ion-icon src="./assets/wedding/icon/general/remove.svg"></ion-icon>
              </ion-button>
              <ion-button class="qty-btn" slot="end" (click)="addPax()" fill="clear">
                <ion-icon src="./assets/wedding/icon/general/add.svg"></ion-icon>
              </ion-button>
            </ion-input>
          </ion-item>
        </ion-item>
        <div class="error-div">
          <ng-container *ngFor="let validation of validationMsg?.maxGuest">
            <div class="error-message" *ngIf="form?.get('maxGuest').hasError(validation.type) && (form?.get('maxGuest').dirty || form?.get('maxGuest').touched)">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
            </div>
          </ng-container>
        </div>
      </form>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="shareMode">
    <div class="ion-margin">
      <ion-item class="background-transparent ion-item-no-padding" lines="none">
        <ion-label position="stacked" color="dark">{{ 'LBL.url' | translate }}</ion-label>
        <ion-item class="prompt-input-item" lines="none" [detail]="true" [button]="true" (click)="copyUrl()">
          <ion-label class="full-width text-ellipsis ion-no-margin">
            <ng-container *ngIf="websiteUrl">{{ websiteUrl }}</ng-container>
            <ng-container *ngIf="websiteLink?.link?.short">{{ websiteLink.link.short }}</ng-container>
            <ng-container *ngIf="!websiteLink?.link?.short && websiteLink?.link?.url">{{ websiteLink.link.url }}</ng-container>
          </ion-label>
        </ion-item>
      </ion-item>
    </div>

    <!-- <div class="ion-margin">
      <ion-card class="ion-no-margin">
        <ion-card-header class="ion-no-padding" >
          <ion-item class="ion-no-padding" lines="none" color="tertiary">
            <ion-icon class="icon-small ion-padding-horizontal" color="dark" src="./assets/wedding/icon/general/msg-quote.svg"></ion-icon>
            <ion-text class="font-10 font-bold" color="dark">{{ 'QRCODE.lbl.msg_template' | translate }}</ion-text>
          </ion-item>
        </ion-card-header>
        <ion-card-content>
          <div class="full-width font-10 ion-padding-top">
            <ion-textarea [(ngModel)]="msg" autoGrow="true"></ion-textarea>
          </div>
        </ion-card-content>
      </ion-card>
    </div> -->
  </ng-container>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ng-container *ngIf="shareMode">
      <ng-container *ngIf="hybrid">
        <ion-button class="ion-margin" expand="block" (click)="share()">
          {{ 'BTN.send' | translate }}
        </ion-button>
      </ng-container>
      <ng-container *ngIf="!hybrid">
        <ion-button class="ion-margin" expand="block" (click)="copyMsg()">
          {{ 'BTN.copy' | translate }}
        </ion-button>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!shareMode">
      <ion-grid>
        <ion-row>
          <ion-col *ngIf="websiteLink?.linkId && websiteLink?.link?.url">
            <ion-button class="ion-margin" expand="block" color="tertiary" (click)="share()">
              {{ 'BTN.send' | translate }}
            </ion-button>
          </ion-col>
          <ion-col *ngIf="websiteForm?.enable">
            <ion-button class="ion-margin" expand="block" (click)="save()">
              {{ (websiteLink?.linkId ? 'WEBSITE.link.btn.edit' : 'WEBSITE.link.btn.new') | translate }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ng-container>   
  </ion-toolbar>
</ion-footer>
