import { UserManageService } from 'src/app/services/user/user-manage.service';
import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-aup',
  templateUrl: './aup.component.html',
  styleUrls: ['./aup.component.scss'],
})
export class AupComponent implements OnInit, OnDestroy {

  /**
   * page mode
   */
  @Input() pageMode: boolean;

  @Output() back = new EventEmitter<boolean>();

  promptMode: boolean;

  constructor(
    private modalController: ModalController,
    private userManageService: UserManageService
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
  }

  goBack() {
    if (this.pageMode) {
      this.back.next(true);
    } else {
      this.dismissModal();
    }
  }

  /**
   * Dismiss modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { await modal.dismiss(); }
    }
  }

  agree() {
    this.userManageService.updateUser({ prompt: { aup: true }});
    this.goBack();
  }

}
