import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription, map } from 'rxjs';

import { getTime } from 'date-fns';

import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { AccountSubscribeService } from 'src/app/services/account/account-subscribe.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { UrlService } from 'src/app/services/general/url.service';

import { AccountPackage } from 'src/app/interfaces/account';
import { AccountAupService } from './account-aup.service';


/**
 * Account package service
 */
@Injectable({
  providedIn: 'root'
})
export class AccountPackageService implements OnInit, OnDestroy {

  /**
   * Account package
   */
  accountPackage: AccountPackage;
  /**
   * Observable Account package
   */
  observableAccountPackage: any;

  /**
   * Account ID
   */
  accountId: string;

  ready: boolean;

  /**
   * Account package subscription
   */
  private accountPackageSubscription: Subscription;
  /**
   * URL subscription
   */
  private urlSubscription: Subscription;
  /**
   * Constructor
   * @param router router
   * @param modalController modal controller
   * @param translate translate service
   * @param afs angular firestore
   * @param moduleService module Service
   * @param popupService popup service
   * @param functionService function service
   */
  constructor(
    private router: Router,
    private platform: Platform,
    private translate: TranslateService,
    private afs: AngularFirestore,
    private moduleService: ModuleService,
    private accountAupService: AccountAupService,
    private accountSubscribeService: AccountSubscribeService,
    private urlService: UrlService,
    private popupService: PopupService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
    this.ready = false;
    this.accountPackage = null;

    this.observableAccountPackage = new BehaviorSubject<AccountPackage>(this.accountPackage);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy() {
    this.unwatchUrl();
    this.unwatchAccountPackage();

    this.ready = false;
    this.accountPackage = null;
  }

  /**
   * Watch URL
   */
  async watchUrl() {
    if (!this.urlSubscription) {
      this.urlSubscription = this.urlService.observableUrl.subscribe(() => {
        this.validateAccount();
      });
    }
  }

  /**
   * Unwatch URL
   */
  async unwatchUrl() {
    if (this.urlSubscription) {
      this.urlSubscription.unsubscribe();
      this.urlSubscription = null;
    }
  }

  /**
   * Setup Account Package
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
    
    if (this.accountId) {
      await this.watchAccountPackage();
      await this.watchUrl();
    } else {
      await this.unwatchAccountPackage();
      await this.unwatchUrl();
      
      this.ready = false;
      this.accountPackage = null;
    }
  }

  /**
   * Watch Account Package
   */
  async watchAccountPackage() {
    if (this.accountId && !this.accountPackageSubscription) {
      this.accountPackageSubscription = this.afs.doc(`accounts/${ this.accountId }/accountPackage/package/`)
      .snapshotChanges().pipe(map(changes => {
        const data: AccountPackage = changes.payload.data() as AccountPackage;
        return data;
      })).subscribe({
        next: (accountPackage: AccountPackage) => {
          this.setupAccountPackage(accountPackage);
          this.ready = true;
        }, error: (err: any) => {
          this.errorService.logError(err);
          this.ready = true;
        }
      });
    }
  }

  /**
   * Unwatch Account Package
   */
  async unwatchAccountPackage() {
    if (this.accountPackageSubscription) {
      this.accountPackageSubscription.unsubscribe();
      this.accountPackageSubscription = null;
    }
  }

  /**
   * Setup account package
   * @param accountPackage account package
   */
  setupAccountPackage(accountPackage: AccountPackage) {
    if (!this.functionService.isEqual(this.accountPackage, accountPackage)) {
      this.accountPackage = accountPackage;
      this.observableAccountPackage.next(this.accountPackage);
      this.validateAccount();
      this.validateAccountAup();
      this.moduleService.setupModuleList();
    }
  }

  /**
   * Validate account
   */
  validateAccount() {
    if (this.router.url?.indexOf('/subscription/') === -1 && this.router.url?.indexOf('/accounts/') === -1 && this.router.url !== '/user/credit') {
      this.validateAccountTime();
    }
  }

  /**
   * Validate trial account time for start and expired
   */
  validateAccountTime() {
    const now = getTime(new Date()) / 1000;
    if (!this.accountPackage?.accountStatus?.premium && this.accountPackage?.accountStatus?.trial && this.accountPackage?.trialData) {
      const trailData = this.accountPackage.trialData;
      if (trailData?.end?.seconds < now) {
        this.presentTimeAlert('trial_end');
      } else if (trailData?.start?.seconds > now) {
        this.presentTimeAlert('trial_start');
      } else {
        this.validateAccountType();
      }
    } else {
      this.validateAccountType();
    }
  }

  /**
   * Present account time alert.
   * Proceed to account subscription page if trial end and user choose to subscribe.
   * @param type alert type
   */
  async presentTimeAlert(type: string) {
    if (type === 'trial_start') {
      const modal = await this.popupService.presentAlert(this.translate.instant('ACCOUNT.msg.trial_yet_start'));
      modal.onDidDismiss().then(() => {
        this.logout();
      });
    } else if (type === 'trial_end') {
      this.accountSubscribeService.promptExpired(this.accountId);
    }
  }

  /**
   * Validate Account type
   */
  async validateAccountType() {
    if (this.accountPackage?.accountStatus?.premium && this.accountPackage?.accountType) {
      let msg = '';
      if (this.accountPackage.accountType === 'web' && this.platform.is('hybrid')) {
        msg = this.translate.instant('ACCOUNTS_LOGIN.web_access_only');
      } else if (this.accountPackage.accountType === 'app' && !this.platform.is('hybrid')) {
        msg = this.translate.instant('ACCOUNTS_LOGIN.app_access_only');
      }
      if (msg) {
        const modal = await this.popupService.presentAlert(msg);
        modal.onDidDismiss().then(() => {
          this.logout();
        });
      }
    }
  }

  validateAccountAup() {
    this.accountAupService.setupAup(this.accountPackage?.aup?.exceed);
  }

  /**
   * Logout wedding account
   */
  async logout() {
    this.router.navigate(['/accounts/logout']);
  }
}
