import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, distinctUntilChanged, map } from 'rxjs';
import { WebsiteLink } from 'src/app/interfaces/website';
import { ErrorService } from '../general/error.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { OnlineService } from '../general/online.service';
import { FunctionService } from '../general/function.service';
import { UpdateByService } from '../user/update-by.service';
import { GuestService } from '../guest/guest.service';
import { GroupService } from '../group/group.service';

import { Guest } from 'src/app/interfaces/guest';
import { Group } from 'src/app/interfaces/group';
import { PopupService } from '../general/popup.service';
import { Platform } from '@ionic/angular';
import { Share } from '@capacitor/share';
import { Clipboard } from '@capacitor/clipboard';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class WebsiteLinksService implements OnInit, OnDestroy {

  websiteLinks: WebsiteLink[];
  
  observable: any;

  observableGuestCount: any;
  observableGroupCount: any;

  accountId: string;

  guestCount: any = {};
  groupCount: any = {};

  private websiteLinkSubscription: Subscription;

  private guestListSubscription: Subscription;

  private groupListSubscription: Subscription;

  constructor(
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private platform: Platform,
    private translate: TranslateService,
    private guestService: GuestService,
    private groupService: GroupService,
    private updateByService: UpdateByService,
    private onlineService: OnlineService,
    private functionService: FunctionService,
    private popupService: PopupService,
    private errorService: ErrorService,
  ) {
    this.websiteLinks = [];
    this.observable = new BehaviorSubject<WebsiteLink[]>(this.websiteLinks);

    this.observableGuestCount = new BehaviorSubject<any>(this.guestCount);
    this.observableGroupCount = new BehaviorSubject<any>(this.groupCount);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
    this.unwatchWebsiteLinks();
    this.websiteLinks = [];
  }

  async setup(accountId: string) {
    this.accountId = accountId;
    
    if (accountId) {
      this.watchWebsiteLinks();
      this.watchGuestList();
      this.watchGroupList();
    } else {
      await this.unwatchWebsiteLinks();
      this.unwatchGuestList();
      this.unwatchGroupList();
      this.websiteLinks = [];
    }
  }

  async watchWebsiteLinks() {
    if (this.accountId) {
      if (!this.websiteLinkSubscription) {
        this.websiteLinkSubscription = this.afs.collection(`accounts/${ this.accountId }/accountSetting/website/links/`)
        .snapshotChanges().pipe(distinctUntilChanged(), map(actions => actions.map( a => {
          const data: WebsiteLink = a.payload.doc.data() as WebsiteLink;
          if (!data.linkId) { data.linkId = a.payload.doc.id; }
          return data;
        }))).subscribe({
          next: (websiteLinks: WebsiteLink[]) => {
            this.websiteLinks = websiteLinks;
            this.observable.next(this.websiteLinks);
          }, error: (err: any) => {
            this.errorService.logError(err);
          }
        });
      }
    } else {
      setTimeout(() => {
        this.watchWebsiteLinks();
      }, 500);
    }
  }

  /**
   * Unwatch page setting
   */
  async unwatchWebsiteLinks() {
    if (this.websiteLinkSubscription) {
      this.websiteLinkSubscription.unsubscribe();
      this.websiteLinkSubscription = null;
    }
  }

  async watchGuestList() {
    if (!this.guestListSubscription) {
      this.guestListSubscription = this.guestService.observableGuestList.subscribe(() => {
        this.setupGuestCount();
      });
    }
  }

  async unwatchGuestList() {
    if (this.guestListSubscription) {
      this.guestListSubscription.unsubscribe();
      this.guestListSubscription = null;
    }
  }

  async watchGroupList() {
    if (!this.groupListSubscription) {
      this.groupListSubscription = this.groupService.observableGroupList.subscribe(() => {
        // this.setupGroupCount();
      });
    }
  }

  async unwatchGroupList() {
    if (this.groupListSubscription) {
      this.groupListSubscription.unsubscribe();
      this.groupListSubscription = null;
    }
  }

  setupGuestCount() {
    const guestCount = {};
    const groupCount = {};
    const guestList = this.guestService.getGuestList();
    // console.log(guestList);
    guestList.forEach((guest: Guest) => {
      if (guest?.rsvp?.linkId ) {
        if (guest?.groupId) {
          if (!groupCount?.[guest.rsvp.linkId]) {
            groupCount[guest.rsvp.linkId] = 0;
          }
          groupCount[guest.rsvp.linkId] += 1;
        } else {
          if (!guestCount?.[guest.rsvp.linkId]) {
            guestCount[guest.rsvp.linkId] = 0;
          }
          guestCount[guest.rsvp.linkId] += 1;
        }

      }
    });
    this.guestCount = guestCount;
    this.observableGuestCount.next(this.guestCount);

    this.groupCount = groupCount;
    this.observableGroupCount.next(this.groupCount);
  }

  setupGroupCount() {
    const result = {};
    const groupList = this.groupService.groupList;
    // console.log(groupList);
    groupList.forEach((group: Group) => {
      if (group?.rsvp?.linkId) {
        if (!result?.[group.rsvp.linkId]) {
          result[group.rsvp.linkId] = 0;
        }

        result[group.rsvp.linkId] += 1; 
      }
    });
    this.groupCount = result;
    this.observableGroupCount.next(this.groupCount);
  }

  get linkId(): string {
    return this.accountId ? this.afs.collection(`accounts/${ this.accountId }/accountSetting/website/links/`).ref.doc().id : '';
  }

  getWebsiteLink(linkId: string) {
    const index = this.websiteLinks.findIndex((websiteLink: WebsiteLink) => {
      return websiteLink.linkId === linkId;
    });
    return index !== -1 ? { ...this.websiteLinks[index] } : null;
  }

  async share(websiteLink: WebsiteLink) {
    if (websiteLink.link?.short || websiteLink.link?.url) {
      const url = websiteLink.link?.short ? websiteLink.link.short : websiteLink.link.url;
      if (this.platform.is('hybrid')) {
        await this.popupService.presentLoading();
        await Share.share({
          // title: this.getTitle(),
          text: url,
        }).then((result) => {
        }).catch((err) => {
          if (err?.message !== 'Share canceled') {
            this.errorService.logError(err);
          }
        });
        await this.popupService.dismissLoading();
      } else {
        try {
          await Clipboard.write({ string: url });
          this.popupService.presentToast(this.translate.instant('COPY.success'), 'success');
        } catch (err: any) {
          this.popupService.presentToast(this.translate.instant('COPY.fail'), 'danger');
        }
      }
    }
  }

  async saveWebsiteLink(websiteLink: WebsiteLink) {
    if (this.accountId && websiteLink) {
      if (!websiteLink?.link?.url || !websiteLink?.linkId) {
        websiteLink.updateBy = this.updateByService.getUpdateBy();
        return await this.generateWebsiteLinkCall(websiteLink);
      } else if (!this.functionService.isEqual(websiteLink, this.getWebsiteLink(websiteLink.linkId))) {
        const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website/links/${ websiteLink.linkId}`);
        ref.set(websiteLink, { merge: true });
      }
    }
    return websiteLink;
  }

  async generateWebsiteLinkCall(websiteLink: WebsiteLink) {
    if (this.onlineService.online) {
      if (websiteLink) {
        // await this.popupService.presentLoading();
        return await this.fns.httpsCallable('generateWebsiteLinkCall')({ websiteLink })
        .toPromise().then(async (result: any) => {
          return result;
        }).catch((err: any) => {
          this.errorService.logError(err);
          // this.popupService.dismissLoading();
          // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
        });
      } else {
        // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
      }
    }
  }
}
