import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BlastCredit } from 'src/app/interfaces/blast';
import { BlastPackageType } from 'src/app/types/blast';
import { BlastUsageService } from './blast-usage.service';
import { FunctionService } from '../general/function.service';
import { ErrorService } from '../general/error.service';
import { BehaviorSubject, Subscription, distinctUntilChanged, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlastCreditService implements OnInit, OnDestroy {

  accountId: string;
  private blastCreditSubscription: Subscription;

  observableBlastCredit: any;

  blastCreditList: BlastCredit[];

  constructor(
    private afs: AngularFirestore,
    private blastUsageService: BlastUsageService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
    this.observableBlastCredit = new BehaviorSubject<BlastCredit[]>(this.blastCreditList);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy() {
    this.unwatchBlastCreditList();
    this.blastCreditList = [];
  }

  async setupAccountId(accountId: string) {
    this.accountId = accountId;

    if (this.accountId) {
      await this.watchBlastCreditList();
    } else {
      await this.unwatchBlastCreditList();
      this.blastCreditList = [];
    }
  }

  async watchBlastCreditList() {
    if (this.accountId) {
      if (!this.blastCreditSubscription) {
        this.blastCreditSubscription = this.afs.collection(`accounts/${ this.accountId }/blast/msg/blastCredit/`,
        ref => ref.where('enable', '==', true))
        .snapshotChanges().pipe(distinctUntilChanged(), map(actions => actions.map( a => {
          const data = a.payload.doc.data();
          return data;
        }).sort((a: BlastCredit, b: BlastCredit) => {
          return this.functionService.compare(a?.createBy?.time?.seconds, b?.createBy?.time?.seconds, true);
        }))).subscribe({
          next: (blastCreditList: BlastCredit[]) => {
            this.blastCreditList = blastCreditList;
            this.observableBlastCredit.next(this.blastCreditList);
          }, error: (err: any) => {
            this.errorService.logError(err);
          }
        });
      }
    } else {
      setTimeout(() => {
        this.watchBlastCreditList();
      }, 500);
    }
  }

  async unwatchBlastCreditList() {
    if (this.blastCreditSubscription) {
      this.blastCreditSubscription.unsubscribe();
      this.blastCreditSubscription = null;
    }
  }

  getTotalBlastCredit(): number {
    const blastCredit = this.blastCreditList?.reduce((a: number, b: BlastCredit) => {
      return a + (b?.blastCredit ? b?.blastCredit : 0);
    }, 0);
    return this.functionService.isNumber(blastCredit) ? blastCredit : 0;
  }

  getTotalPurchaseBlastCredit(): number {
    return this.blastCreditList?.filter((blastCredit: BlastCredit) => {
      return blastCredit.type === 'purchase';
    })?.reduce((a: number, b: BlastCredit) => {
      return a + (b?.blastCredit ? b?.blastCredit : 0);
    }, 0);
  }

  getTotalRedeemBlastCredit(): number {
    return this.blastCreditList?.filter((blastCredit: BlastCredit) => {
      return blastCredit.type === 'redeem' || blastCredit.type === 'account' || blastCredit.type === 'partner' || blastCredit.type === 'rating' || blastCredit.type === 'loyalty';
    })?.reduce((a: number, b: BlastCredit) => {
      return a + (b?.blastCredit ? b?.blastCredit : 0);
    }, 0);
  }

  getBlastCreditByType(type: BlastPackageType): number {
    return this.blastCreditList?.filter((blastCredit: BlastCredit) => {
      return blastCredit.type === type;
    })?.reduce((a: number, b: BlastCredit) => {
      return a + (b?.blastCredit ? b?.blastCredit : 0);
    }, 0);
  }

  getAvailableBlastCredit(): number {
    return this.getTotalBlastCredit() - this.blastUsageService.getTotalUsedBlastCredit();
  }

  getBlastCreditById(blastCreditId: string): BlastCredit {
    const index = this.blastCreditList?.findIndex((blastCredit: BlastCredit) => {
      return blastCredit.blastCreditId === blastCreditId;
    });
    return index !== -1 ? this.blastCreditList[index] : null;
  }

  getBlastCreditListById(blastCreditIdList: string[]): BlastCredit[] {
    return this.blastCreditList?.filter((blastCredit: BlastCredit) => {
      return blastCreditIdList.indexOf(blastCredit.blastCreditId) !== -1;
    });
  }
}
