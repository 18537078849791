import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActionSheetController, IonContent, IonList, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { CheckinService } from 'src/app/services/checkin/checkin.service';
import { SeatingService } from 'src/app/services/seating/seating.service';
import { SeatingListService } from 'src/app/services/seating/seating-list.service';
import { SeatingManageService } from 'src/app/services/seating/seating-manage.service';
import { SeatingSummaryService } from 'src/app/services/seating/seating-summary.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { TabService } from 'src/app/services/general/tab.service';

import { SeatingNewComponent } from 'src/app/components/seating/seating-new/seating-new.component';
import { SeatingEditComponent } from 'src/app/components/seating/seating-edit/seating-edit.component';
import { SeatingDetailComponent } from 'src/app/components/seating/seating-detail/seating-detail.component';

import { Seating, SeatingSummary } from 'src/app/interfaces/seating';
import { Guest } from 'src/app/interfaces/guest';
import { ModuleType } from 'src/app/types/general';
import { GuestListMode } from 'src/app/types/guest';
import { SeatingView, SeatingSortType } from 'src/app/types/seating';
import { Group } from 'src/app/interfaces/group';
import { DeviceSafeAreaService } from 'src/app/services/device/device-safe-area.service';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';
import { CurrentPrivilege } from 'src/app/interfaces/privilege';

/**
 * Seating list component
 */
@Component({
  selector: 'app-seating-list-component',
  templateUrl: './seating-list.component.html',
  styleUrls: ['./seating-list.component.scss'],
})
export class SeatingListComponent implements OnInit, OnDestroy {
  /**
   * Ion content
   */
  @ViewChild('content', { read: ElementRef, static: false }) contentElement: ElementRef;
  /**
   * Header element.
   * To get header height.
   */
  @ViewChild('header', { read: ElementRef, static: false }) header: ElementRef;
  /**
   * Footer element.
   * To get footer height.
   */
  @ViewChild('footer', { read: ElementRef, static: false }) footer: ElementRef;
  /**
   * Content element.
   * Scroll to content top.
   */
  @ViewChild('content') private content: IonContent;
  /**
   * List element.
   * Close ion-slide-options.
   */
  @ViewChild('list') private list: IonList;

  /**
   * Guest list mode
   */
  @Input() mode: GuestListMode;
  /**
   * Seating type
   */
  @Input() type: string;
  /**
   * Page mode.
   */
  @Input() pageMode: boolean;
  /**
   * Clear search, filter, reset component to initial state and scroll to top.
   */
  @Input() set reset(flag: boolean) {
    this.resetSearch = flag;
    if (flag) {
      this.searchTerm = '';
      this.filter = null;
      this.initialize();
      this.scrollToTop();
    } else {
      this.unwatch();
    }
  }

   /**
   * Watch screen resize change
   */
  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.resizeLimit();
    this.setupContentWidth();
    this.setupContentHeight();
  }
  /**
   * Watch screen orientation change
   */
  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event: any) {
    this.resizeLimit();
    this.setupContentWidth();
    this.setupContentHeight();
  }
  

  ready: boolean;
  /**
   * Edit mode.
   */
  editMode: boolean;
  /**
   * Assign mode.
   */
  assignMode: boolean;
  /**
   * Swap mode.
   */
  swapMode: boolean;
  /**
   * Select all flag.
   */
  selectAll: boolean;
  /**
   * Expand all flag.
   */
  expandAll: boolean;
  /**
   * Reset search conditions
   */
  resetSearch: boolean;
  /**
   * Selected seating list.
   */
  selectedSeatingList: string[];
  /**
   * List limit for infinite load.
   */
  listLimit: number;
  /**
   * Grid limit for infinite load.
   */
  gridLimit: number;
  /**
   * View type.
   */
  seatingView: SeatingView;
  /**
   * Seating list.
   */
  seatingList: Seating[];

  seatingSummaryList: { [seatingId: string]: SeatingSummary };
  /**
   * Seating count
   */
  count: number;
  /**
   * Selected seating count
   */
  selectedCount: number;

  /**
   * Search term.
   */
  searchTerm: string;

  currentModule: ModuleType;

  contentWidth: number;
  contentHeight: string;

  gridSize: number;
  gridNameHeight: number;

  currentPrivilege: CurrentPrivilege;

  /**
   * Filter conditions.
   */
  private filter: any;
  /**
   * Sorting type
   */
  private sorting: SeatingSortType;
  /**
   * Descending flag
   */
  private desc: boolean;
  /**
   * Excluded seating list.
   * For assign mode, exclude current seating.
   */
  private excludeSeatingList: Seating[];
  /**
   * New guest list.
   * For assign / swap action.
   */
  private newGuestList: Guest [];
  private newGroupList: Group [];
  /**
   * Seating list subscription
   */
  private seatingListSubscription: Subscription;

  private moduleSubscription: Subscription;

  private privilegeSubscription: Subscription;

  triggering: boolean;

  showTab: boolean;

  seatingTypeName: string;
  /**
   * Constructor
   * @param platform Platform
   * @param translate Translate service
   * @param modalController Modal Controller
   * @param guestListService Guest list service
   * @param groupService Guest group service
   * @param seatingService Seating service
   * @param tabService Tab service
   * @param popupService Popup service
   */
  constructor(
    private translate: TranslateService,
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private deviceSafeAreaService: DeviceSafeAreaService,
    private guestService: GuestService,
    private guestManageService: GuestManageService,
    private checkinService: CheckinService,
    private seatingService: SeatingService,
    private seatingSettingService: SeatingSettingService,
    private seatingListService: SeatingListService,
    private seatingManageService: SeatingManageService,
    private seatingSummaryService: SeatingSummaryService,
    private moduleService: ModuleService,
    private privilegeService: PrivilegeService,
    private tabService: TabService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  /**
   * Component initial
   */
  ngOnInit() {

  }

  ngOnDestroy() {
    this.unwatch();
  }

  unwatch() {
    this.unwatchSeatingList();
    this.unwatchModule();
    this.unwatchPrivilege();
  }

  /**
   * Initialize state
   */
  ionViewWillEnter() {
    this.initialize();
    this.expandAll = true;
  }

  /**
   * Unwatch seating list
   */
  ionViewWillLeave() {
    this.unwatchSeatingList();
    this.unwatchModule();
    this.unwatchPrivilege();
  }

  /**
   * Initial state
   */
  async initialize() {
    this.showTab = true;
    this.triggering = false;
    this.editMode = false;
    this.seatingView = 'grid';
    this.count = 0;
    this.selectedCount = 0;
    this.seatingTypeName = this.getSeatingTypeName();

    if (!this.seatingList?.length) {
      this.seatingList = [];
    }
    this.selectedSeatingList = [];
    if (this.assignMode) {
      this.sorting = 'availableSeating';
    }
    this.setupContentWidth();
    this.setupContentHeight();
    await this.setupExcludeList();
    this.watchSeatingList();
    this.watchModule();
    this.watchPrivilege();
    this.setupLimit();
  }

  async setupExcludeList() {
    if (this.newGuestList?.length && !this.excludeSeatingList?.length) {
      const excludeSeatingList: Seating[] = this.newGuestList?.filter((guest: Guest) => guest?.seating)
      .map((guest: Guest) => this.seatingService.getSeatingByName(guest.seating));
      if (excludeSeatingList?.length) {
        if (!this.excludeSeatingList) { this.excludeSeatingList = []; }
        this.excludeSeatingList = this.excludeSeatingList.concat(excludeSeatingList);
      }
    }
  }

  /**
   * Watch seating list and load seating by condtions
   */
  async watchSeatingList() {
    if (!this.seatingListSubscription) {
      this.seatingListSubscription = this.seatingService.observableSeatingList.subscribe(() => {
        this.loadSeatingList();
      });
    }
  }

  /**
   * Unwatch seating list
   */
  async unwatchSeatingList() {
    if (this.seatingListSubscription) {
      this.seatingListSubscription.unsubscribe();
      this.seatingListSubscription = null;
    }
  }

  async watchModule() {
    if (!this.moduleSubscription) {
      this.moduleSubscription = this.moduleService.observableCurrentModule.subscribe((module: ModuleType) => {
        this.currentModule = module;
        this.setupPrivilege();
      });
    }
  }

  async unwatchModule() {
    if (this.moduleSubscription) {
      this.moduleSubscription.unsubscribe();
      this.moduleSubscription = null;
    }
  }

  async watchPrivilege() {
    if (!this.privilegeSubscription) {
      this.privilegeSubscription = this.privilegeService.observableCurrentPrivilege.subscribe(() => {
        this.setupPrivilege();
      })
    }
  }

  async unwatchPrivilege() {
    if (this.privilegeSubscription) {
      this.privilegeSubscription.unsubscribe();
      this.privilegeSubscription = null;
    }
  }

  setupPrivilege() {
    if (!this.currentPrivilege) {
      this.currentPrivilege = {};
    }
    if (this.currentModule) {
      this.currentPrivilege = {
        [this.currentModule]: {
          'seating': this.checkPrivilege(this.currentModule, 'seating'),
        },
      };
    }
    
  }

  // isIosApp() {
  //   return this.platform.is('hybrid') && this.platform.is('ios');
  // }

  /**
   * Search action
   * @param data Search data, keyword & filter
   */
  search(data: any) {
    this.searchTerm = data?.keyword;
    this.filter = data?.filter?.enable ? data.filter : null;
    this.sorting = data?.sorting;
    this.desc = data?.desc ? true : false;
    this.loadSeatingList();
  }

  /**
   * Search seating list by conditions
   */
  loadSeatingList() {
    const result = this.seatingListService.searchSeatingList(
      this.searchTerm,
      this.filter,
      null,
      this.excludeSeatingList,
      this.swapMode,
      this.sorting,
      this.desc,
      this.newGuestList?.length
    );
    this.seatingList = result.list.sort((a: Seating, b: Seating) => {
      return this.functionService.compare(a.count, b.count, true);
    });

    this.count = result.count;

    this.setupSeatingSumaryList();
  }

  setupLimit() {
    if (this.contentElement?.nativeElement?.offsetHeight) {
      this.gridLimit = this.getGridLimit();
      this.listLimit = this.getListLimit();
    } else {
      setTimeout(() => {
        this.setupLimit();
      }, 200);
    }
  }

  /**
   * Infinite load for grid view
   * @param event event
   */
  loadGridData(event: any) {
    if (!this.triggering) {
      this.triggering = true;
      setTimeout(() => {
        if (this.gridLimit >= this.seatingList.length) {
          // event.target.disabled = true;
        } else {
          this.gridLimit += this.getGridLimit();
        }
        event.target.complete();
        this.triggering = false;
      }, 200);
    }
  }

  /**
   * Infinite load for list view
   * @param event event
   */
  loadListData(event: any) {
    setTimeout(() => {
      if (this.listLimit >= this.seatingList.length) {
        // event.target.disabled = true;
      } else {
        this.listLimit += this.getListLimit();
      }
      event.target.complete();
    }, 200);
  }

  /**
   * Scroll to top
   * @param duration Scroll animation duration
   */
  scrollToTop(duration?: number) {
    if (this.content) {
      this.content.scrollToTop(duration ? duration : 0);
    }
  }

  /**
   * Close ion-item-sliding
   */
  closeSliding() {
    if (this.list) {
      this.list?.closeSlidingItems();
    }
  }

  getCurrentModule(): ModuleType {
    return this.moduleService.currentModule;
  }

  getScreenHeight() {
    return '100vh';
    // if (window?.innerHeight) {
    //   return window.innerHeight.toString() + 'px';
    // } else {
    //   return '100vh';
    // }
  }

  getContentWidthHeight(): { width: number, height: number } {
    const width: number = this.contentElement?.nativeElement?.offsetWidth ? this.contentElement.nativeElement.offsetWidth : window?.innerWidth ? window.innerWidth : 0;
    const height: number = this.contentElement?.nativeElement?.offsetHeight ? this.contentElement.nativeElement.offsetHeight : window?.innerHeight ? window.innerHeight : 0;
    return { width, height };
  }

  getGridLimit() {
    const { width, height } = this.getContentWidthHeight();
    return this.seatingSettingService.calculateGridLimit(width, height);
  }

  getListLimit() {
    const { height } = this.getContentWidthHeight();
    return this.seatingSettingService.calculateListLimit(height);
  }

  resizeLimit() {
    if (this.seatingView === 'grid') {
      const gridLimit = this.getGridLimit();
      if (gridLimit > this.gridLimit) {
        this.gridLimit = gridLimit;
        this.scrollToTop();
      }
    } else if (this.seatingView === 'list') {
      const listLimit = this.getListLimit();
      if (listLimit > this.listLimit) {
        this.listLimit = listLimit;
        this.scrollToTop();
      }
    }
  }

  /**
   * Get header height
   */
  getHeaderHeight() {
    let header = 0;
    if (this.pageMode) {
      if (this.header?.nativeElement?.offsetHeight) {
        header = this.header.nativeElement.offsetHeight;
        // if (header < 236) {
        //   header = 236;
        // }
      }
      if (this.tabService.showTab) {
        header += this.deviceSafeAreaService.getSafeArea();
      }
    }
    return header;
  }

  /**
   * Get footer height
   */
  getFooterHeight() {
    let footer = 0;
    if (this.pageMode) {
      if (this.editMode) {
        footer += this.footer?.nativeElement?.offsetHeight ? this.footer.nativeElement.offsetHeight : 106;
      }
      if (this.tabService.showTab) {
        footer += 50;
      }
    }
    return footer;
  }

  
  setupFooter() {
    if (this.pageMode) {
      this.showTab = !this.editMode;
      this.tabService.updateShowTab(!this.editMode);
    }
  }

  doRefresh(event: any) {
    this.ready = false;
    setTimeout(() => {
      this.ready = true;
      event.target.complete();
    }, 500);
  }

  /**
   * Reset select all
   */
  resetSelectAll() {
    this.selectAll = false;
    this.selectedSeatingList = [];
    this.selectedCount = 0;
  }

  /**
   * Toggle edit mode
   * Close ion-item-sliding
   * Get footer height
   */
  toggleEditMode(editMode?: boolean) {
    if (this.functionService.isUndefined(editMode)) {
      this.editMode = !this.editMode;
    } else {
      this.editMode = editMode;
    }
    this.resetSelectAll();
    this.closeSliding();
    this.setupFooter();
    this.setupContentHeight();
  }

  /**
   * Toogle expand all
   */
  toggleExpandAll() {
    this.expandAll = !this.expandAll;
  }

  /**
   * Toggle select all
   */
  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    if (this.selectAll) {
      this.selectedSeatingList = this.seatingList.filter((seating: Seating) => {
        if (seating?.seatingId) {
          return true;
        }
        return false;
      }).map((seating: Seating) => {
        return seating.seatingId;
      });
      this.selectedCount = this.seatingService.getSeatingCount(null, this.selectedSeatingList);
    } else {
      this.selectedSeatingList = [];
      this.selectedCount = 0;
    }
  }

  /**
   * Check select all
   */
  checkSelectAll() {
    if (this.count === this.selectedCount) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  }

  /**
   * Seating click
   * @param seating Seating
   */
  seatingClick(seating: Seating) {
    if (this.editMode) {
      this.setSelectedSeating(seating.seatingId);
      this.checkSelectAll();
    } else if (this.assignMode) {
      const seatingSummary = this.seatingSummaryService.getSeatingSummary(seating);
      const newGuestCount = this.newGuestList?.filter((guest: Guest) => {
        const index = seatingSummary.guestList?.findIndex((x: Guest) => x?.guestId === guest?.guestId );
        return index === -1 ? true : false;
      }).length;
      this.promptSeatingOverload(seating, seatingSummary, newGuestCount);
    } else if (this.swapMode) {
      const seatingSummary = this.seatingSummaryService.getSeatingSummary(seating);
      if (seatingSummary?.guestList?.length) {
        this.presentSeatingDetailModal(seating);
      } else {
        this.popupService.presentToast('error', 'danger');
      }
    } else {
      if (this.seatingView === 'grid') {
        this.presentSeatingDetailModal(seating);
      }
    }
  }

  /**
   * Set selected seating
   * @param seatingId Seating ID
   */
  setSelectedSeating(seatingId: string) {
    if (this.editMode && seatingId) {
      const index = this.selectedSeatingList?.indexOf(seatingId);
      if (index === -1) {
        this.selectedSeatingList.push(seatingId);
      } else {
        this.selectedSeatingList?.splice(index, 1);
      }
      this.selectedCount = this.seatingService.getSeatingCount(null, this.selectedSeatingList);
    }
  }

  /**
   * Get selected guest list
   */
  getSelectedGuestList(): Guest[] {
    const selectedSeatingList: Seating[] = this.seatingService.getSeatingListById(this.selectedSeatingList);
    return this.guestService.getSeatingListGuest(selectedSeatingList);
  }

  getSeatingSummary(seating: Seating): SeatingSummary {
    return this.seatingSummaryService.getSeatingSummary(seating);
  }

  getSeatingTypeName(): string {
    return this.seatingSettingService.getSeatingTypeName();
  }

  getContentHeight(): number {
    return this.contentElement?.nativeElement?.offsetHeight ? this.contentElement.nativeElement.offsetHeight : 0;
  }

  getContentWidth(): number {
    return this.contentElement?.nativeElement?.offsetWidth ? this.contentElement.nativeElement.offsetWidth : 0;
  }

  setupSeatingSumaryList() {
    if (!this.seatingSummaryList) {
      this.seatingSummaryList = {};
    }
    this.seatingList.forEach((x: Seating) => {
      if (x?.name) {
        this.seatingSummaryList[x.name] = this.getSeatingSummary(x);
      }
    });
  }

  async setupContentHeight() {
    if (this.pageMode) {
      await this.functionService.delay(200);
      const contentHeight = this.getContentHeight();
      const headerHeight = this.getHeaderHeight();

      if (contentHeight && headerHeight) {
        this.contentHeight = 'calc(100vh - ' + (headerHeight + this.getFooterHeight()) + 'px)';
      } else {
        await this.functionService.delay(200);
        this.setupContentHeight();
      }
    } else {
      this.contentHeight = '100%';
    }
  }

  async setupContentWidth(contentWidth?: number) {
    if (!contentWidth) {
      await this.functionService.delay(200);
      contentWidth = this.getContentWidth();
    }
    
    if (contentWidth) {
      this.contentWidth = (contentWidth);
      this.gridSize = this.seatingService.calculateGridItemSize(this.contentWidth);
      this.gridNameHeight = this.seatingService.calculateGridNameHeight(this.gridSize);

    } else {
      setTimeout(() => {
        this.setupContentWidth(contentWidth);
      }, 200);
    }
  }

  /**
   * Check user privilege
   * @param action Action
   */
  checkPrivilege(module: ModuleType, action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege(module, action);
  }

  /**
   * Dismiss Seating list modal
   */
  async dismissModal(done?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ done }); }
    }
  }

  /**
   * Present new seating modal
   */
  async presentSeatingNewModal() {
    const modal = await this.modalController.create({
      component: SeatingNewComponent,
      cssClass: '',
      componentProps: {
      }
    });
    modal.present();
  }

  /**
   * Present seating detail modal
   * @param seating seating
   */
  async presentSeatingDetailModal(seating: Seating) {
    if (seating) {
      const modal = await this.modalController.create({
        component: SeatingDetailComponent,
        cssClass: '',
        componentProps: {
          seating,
          assignMode: this.assignMode && this.newGuestList?.length ? true : false,
          swapMode: this.swapMode && this.newGuestList?.length ? true : false,
          editMode: this.swapMode && this.newGuestList?.length ? true : false,
          newGuestList: this.newGuestList?.length ? this.newGuestList : null,
          newGroupList: this.newGroupList?.length ? this.newGroupList : null,
        }
      });
      modal.present();
      modal.onDidDismiss().then((result: any) => {
        if (result?.data?.dismiss) {
          this.dismissModal(true);
        }
      });
    }
  }

  /**
   * Present seating edit modal
   * @param seating Seating info
   */
  async presentSeatingEditModal() {
    let seating: Seating;
    let bulkEditMode = true;

    if (this.selectedSeatingList?.length) {
      if (this.selectedSeatingList.length === 1) {
        bulkEditMode = false;
        seating = this.seatingService.getSeatingById(this.selectedSeatingList[0]);
      }
    }
    const modal = await this.modalController.create({
      component: SeatingEditComponent,
      cssClass: '',
      componentProps: {
        bulkEditMode,
        seating,
        selectedSeatingList: this.selectedSeatingList
      }
    });
    modal.present();
  }

  /**
   * Present prompt delete modal
   */
  async promptDelete() {
    if (this.editMode) {
      const seatingList: Seating[] = this.functionService.cloneDeep(
        this.seatingService.seatingList)?.filter(
          (seating: Seating) => this.selectedSeatingList?.indexOf(seating?.seatingId) !== -1);
      const guestList: Guest[] = this.guestService.getGuestList()?.filter((guest: Guest) => {
        if (guest?.seating && seatingList?.findIndex((seating: Seating) => seating?.name === guest.seating ) !== -1) {
          return true;
        }
        return false;
      });
      if (guestList?.length) {
        this.popupService.presentAlert(
          this.translate.instant('CRUD.unable_delete_field', {
            field: this.getSeatingTypeName()
          })
          + '<br><br>' +
          this.translate.instant('CRUD.delete_setting_field_guest_count_prompt', {
            field: this.getSeatingTypeName(),
            count: guestList.length
          }), '', '', null, guestList, true
        );
      } else {
        const modal = await this.popupService.presentConfirm(
          this.translate.instant('CRUD.confirm_delete_field_count', {
            field: this.getSeatingTypeName(), count: this.selectedSeatingList?.length })
        );
        modal.onDidDismiss().then(async (result: any) => {
          if (result?.data?.confirm) {
            await this.saveGuestEmptySeating(true);
          }
          this.toggleEditMode(false);
        });
      }
    }
  }

  /**
   * Present prompt unassign modal
   */
  async promptUnassign() {
    const modal = await this.popupService.presentConfirm(
      this.translate.instant('SEATING.msg.confirm_unassign_seating_count', { seating: this.getSeatingTypeName(), count: this.selectedSeatingList?.length, field: '' })
    );
    modal.onDidDismiss().then(async (result: any) => {
      if (result?.data?.confirm) {
        await this.saveGuestEmptySeating(false);
      }
      this.toggleEditMode(false);
    });
  }


  /**
   * Prompt confirm restore modal
   */
  async promptCheckin() {
    const guestList  = this.getSelectedGuestList();
    const guestIdList = guestList.map((guest: Guest) => {
      return guest?.guestId;
    });
    await this.checkinService.checkin('', guestIdList, guestList, null, null, true);
    this.toggleEditMode(false);
  }

  /**
   * Prompt confirm restore modal
   */
  async promptUncheck() {
    const guestList  = this.getSelectedGuestList();
    const guestIdList = guestList.map((guest: Guest) => {
      return guest?.guestId;
    });
    await this.checkinService.uncheck(guestIdList, guestList);
    this.toggleEditMode(false);
  }

  /**
   * Prompt seating overload alert
   * @param seating Seating
   * @param seatingSummary seating summary
   * @param newGuestCount new guest count
   */
  async promptSeatingOverload(seating: Seating, seatingSummary: SeatingSummary, newGuestCount: number) {
    if (seatingSummary?.guestList?.length + newGuestCount > seating?.maxGuest) {
      const confirm = await this.popupService.presentConfirm(
        this.translate.instant('SEATING.msg.confirm_assign_seating_full', { seating: this.getSeatingTypeName() })
      );
      confirm.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          this.presentSeatingDetailModal(seating);
        }
      });
    }  else {
      this.presentSeatingDetailModal(seating);
    }
  }

  /**
   * Present seating action sheet.
   * Check which option to present based on selected seating.
   * Unassign - if any seating is not empty.
   * Favorite - if any seating is not set as favorite.
   * Unfavorite - if any seating is set as favorite.
   */
  async presentSeatingActionSheet() {
    if (this.selectedSeatingList?.length) {
      await this.popupService.presentLoading();
      const selectedSeatingList = this.selectedSeatingList.map((seatingId: string) => {
        return this.seatingService.getSeatingById(seatingId);
      });
      const assginCount = this.seatingListService.searchSeatingList(
        '', { enable: true, assign: 'assign' }, selectedSeatingList ).count;
      const favoriteCount  = this.seatingListService.searchSeatingList(
        '', { enable: true, favorite: 'favorite' }, selectedSeatingList ).count;
      const unfavoriteCount  = this.seatingListService.searchSeatingList(
        '', { enable: true, favorite: 'none' }, selectedSeatingList ).count;

      const buttons: any[] = [{
        text: this.translate.instant('BTN.cancel'),
        icon: 'close',
        role: 'cancel',
        handler: () => {
        }
      }];

      if (assginCount >= 1) {
        buttons.push({
          text: this.translate.instant('SEATING.btn.unassign', { seating: this.getSeatingTypeName() }),
          handler: () => {
            this.promptUnassign();
          }
        });
      }

      if (unfavoriteCount >= 1) {
        buttons.push({
          text: this.translate.instant('LBL.pin'),
          handler: () => {
            this.saveSeatingFavorite(true);
          }
        });
      }

      if (favoriteCount >= 1) {
        buttons.push({
          text: this.translate.instant('LBL.unpin'),
          handler: () => {
            this.saveSeatingFavorite(false);
          }
        });
      }

      const actionSheet = await this.actionSheetController.create({
        header: this.getSeatingTypeName(),
        buttons
      });
      this.popupService.dismissLoading();
      actionSheet.present();
    }
  }

  /**
   * Bulk action
   * @param type bulk action type
   */
  async bulkAction(type: string) {
    if (type) {
      if (type === 'add') {
        await this.presentSeatingNewModal();
        this.toggleEditMode(false);
      } else {
        this.checkSingleRecord();
        if (type === 'delete') {
          this.promptDelete();
        } else if (type === 'info') {
          await this.presentSeatingEditModal();
          this.toggleEditMode(false);
        } else if (type === 'assign') {
          this.presentSeatingActionSheet();
        } else if (type === 'checkin') {
          this.promptCheckin();
        } else if (type === 'uncheck') {
          this.promptUncheck();
        }
      }
    }
  }

  /**
   * Check if single record then trigger select all
   */
  checkSingleRecord() {
    if ( !this.selectAll && this.seatingList?.length === 1) {
      this.toggleSelectAll();
    }
  }

  /**
   * Save seating favorite status
   * @param favorite favorite status
   */
  async saveSeatingFavorite(favorite: boolean) {
    await this.popupService.presentLoading();
    let seatingList: Seating[] = this.functionService.cloneDeep(this.seatingService.seatingList);
    if (seatingList?.length) {
      seatingList = seatingList.map((seating: Seating) => {
        if (seating?.seatingId && this.selectedSeatingList?.indexOf(seating.seatingId) !== -1) {
          seating.favorite = favorite;
          // seating.updateBy = this.updateByService.getUpdateBy();
          // if (!seating.createBy) { seating.createBy = seating.updateBy; }
        }
        return seating;
      });
      await this.seatingManageService.updateSeatingList(seatingList);
    }
    this.toggleEditMode(false);
    this.popupService.dismissLoading();
    this.popupService.saveSuccessToast();
  }

  /**
   * Save guest empty seating
   * @param deleteSeating true if want to delete the seating
   */
  async saveGuestEmptySeating(deleteSeating?: boolean) {
    if (this.editMode) {
      await this.popupService.presentLoading();
      let seatingList: Seating[] = this.functionService.cloneDeep(this.seatingService.seatingList);
      let guestIdList: string[] = [];
      if (seatingList?.length) {
        seatingList = seatingList.map((seating: Seating) => {
          if (seating?.seatingId && this.selectedSeatingList?.indexOf(seating.seatingId) !== -1) {
            if (deleteSeating) {
              seating.deleted = true;
              // seating.updateBy = this.updateByService.getUpdateBy();
              // if (!seating.createBy) { seating.createBy = seating.updateBy; }
            }
            const seatingSummary = this.seatingSummaryService.getSeatingSummary(seating);
            if (seatingSummary?.guestList?.length) {
              guestIdList = guestIdList.concat(seatingSummary.guestList.map((guest: Guest) => {
                return guest?.guestId;
              }));
            }
          }
          return seating;
        });
        if (deleteSeating) {
          await this.seatingManageService.updateSeatingList(seatingList);
        }
        if (guestIdList?.length) {
          await this.guestManageService.saveGuest({ seating: '' }, guestIdList);
        }
      }
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
  }

  trackByFn(index: number, item: Seating) {
    if (item?.seatingId) {
      return item?.seatingId;
    }
    return index;
  }

}
