import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionService } from '../general/function.service';

@Injectable({
  providedIn: 'root'
})
export class AccountEventModeService implements OnInit, OnDestroy {

  eventMode: boolean;

  constructor(
    private translate: TranslateService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  replaceEventType(text: string, eventMode?: boolean) {
    if (eventMode || this.eventMode) {
      text = this.functionService.replaceAll(text, this.translate.instant('FOR_EVENT.lbl.wedding'), this.translate.instant('FOR_EVENT.lbl.event'));
      text = this.functionService.replaceAll(text, this.translate.instant('FOR_EVENT.lbl.wedding').toLowerCase(), this.translate.instant('FOR_EVENT.lbl.event').toLowerCase());
      text = this.functionService.replaceAll(text, this.translate.instant('FOR_EVENT.lbl.wedding').toUpperCase(), this.translate.instant('FOR_EVENT.lbl.event').toUpperCase());
    }
    return text;
  }
}
