import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { Privilege } from 'src/app/interfaces/privilege';
import { DefaultActionList } from 'src/app/commons/action';
import { StdActionList } from 'src/app/commons/action';

/**
 * Action service for standard system action list.
 */
@Injectable({
  providedIn: 'root'
})
export class ActionService implements OnInit, OnDestroy {

  /**
   * Standard action list database
   */
  stdActionList: Privilege = StdActionList;
  /**
   * Default action list for new login
   */
  defaultActionList: Privilege = DefaultActionList;
  /**
   * Action list based on available module
   */
  actionList: Privilege;

  /**
   * Constructor
   */
  constructor() {}

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }


  /**
   * Setup Action List
   * @param moduleList AccountModule List
   */
  async setupActionList(moduleList: string[]) {
    const list: Privilege = {};
    if (moduleList) {
      moduleList?.forEach((module: string) => {
        if (this.stdActionList?.[module]) {
          list[module] = this.stdActionList[module];
        }
      });
    }
    this.actionList = list;
  }

}
