import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { UpdateByService } from 'src/app/services/user/update-by.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { GroupService } from 'src/app/services/group/group.service';
import { GroupManageService } from 'src/app/services/group/group-manage.service';
import { UserService } from 'src/app/services/user/user.service';
import { OnlineService } from 'src/app/services/general/online.service';
import { ErrorService } from 'src/app/services/general/error.service';

import { Gift } from 'src/app/interfaces/gift';
import { Group } from 'src/app/interfaces/group';
import { Guest } from 'src/app/interfaces/guest';
import { UpdateBy } from 'src/app/interfaces/user';

/**
 * Gift manage service
 */
@Injectable({
  providedIn: 'root'
})
export class GiftManageService implements OnInit, OnDestroy {

  /**
   * Account ID
   */
  private accountId: string;

  /**
   * Constructor
   * @param afs angular firestore
   * @param fns firebase cloud functions
   * @param guestService guest service
   * @param guestManageService guest manage service
   * @param groupService group service
   * @param groupManageService group manage service
   * @param userService user service
   * @param onlineService online service
   */
  constructor(
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private guestService: GuestService,
    private guestManageService: GuestManageService,
    private groupService: GroupService,
    private groupManageService: GroupManageService,
    private userService: UserService,
    private updateByService: UpdateByService,
    private onlineService: OnlineService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Setup Account ID
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
  }

  /**
   * Save guest gift
   * @param giftList gift list
   */
  async saveGuestGift(giftList?: Gift[]) {
    let guestIdList: string[] = [];
    let groupIdList: string[] = [];

    giftList?.forEach((gift: Gift) => {
      if (gift?.groupIdList?.length) {
        groupIdList = [...new Set([...groupIdList, ...gift.groupIdList])];
      }
      if (gift?.guestIdList?.length) {
        guestIdList = [...new Set([...guestIdList, ...gift.guestIdList])];
      }
    });

    const guestList: Guest[] = this.guestService.getGuestListById(guestIdList);
    const groupList: Group[] = this.groupService.getGroupListById(groupIdList);

    if (guestList?.length) {
      await this.guestManageService.saveGuest(null, guestIdList, '', guestList, true);
    }

    if (groupList?.length) {
      await this.groupManageService.saveGroupList(groupIdList, null, groupList);
    }
  }

  /**
   * Save gift list
   * @param giftIdList gift id list
   * @param data new gift data
   * @param giftList gift list
   * @param type save type
   */
  async saveGiftList(giftIdList: string[], data: any, giftList?: Gift[], type?: string) {
    if (giftList?.length) {
      giftList = giftList.map((gift: Gift) => {
        if (gift?.guestIdList?.length) {
          if (!gift.guestNameList) {
            gift.guestNameList = {};
          }
          gift?.guestIdList?.forEach((guestId: string) => {
            if (!gift?.guestNameList?.[guestId]?.name) {
              gift.guestNameList[guestId] = {
                name: this.guestService.getGuestName(guestId),
                delete: false
              };
            }
          });
        }

        if (gift?.groupIdList?.length) {
          if (!gift.groupNameList) {
            gift.groupNameList = {};
          }
          gift?.groupIdList?.forEach((groupId: string) => {
            if (!gift?.groupNameList?.[groupId]?.name) {
              gift.groupNameList[groupId] = {
                name: this.groupService.getGroupName(groupId),
                delete: false
              };
            }
          });
        }
        return gift;
      });
    } else {
      if (data?.guestIdList?.length) {
        if (!data.guestNameList) {
          data.guestNameList = {};
        }
        data?.guestIdList?.forEach((guestId: string) => {
          if (!data?.guestNameList?.[guestId]?.name) {
            data.guestNameList[guestId] = {
              name: this.guestService.getGuestName(guestId),
              delete: false
            };
          }
        });
      }
      if (data?.groupIdList?.length) {
        if (!data.groupNameList) {
          data.groupNameList = {};
        }
        data?.groupIdList?.forEach((guestId: string) => {
          if (!data?.groupNameList?.[guestId]?.name) {
            data.groupNameList[guestId] = {
              name: this.groupService.getGroupName(guestId),
              delete: false
            };
          }
        });
      }
    }

    const updateBy: UpdateBy = this.updateByService.updateBy;
    if (this.accountId && giftIdList?.length) {
      if (this.onlineService.online && giftIdList.length > 100) {
        await this.fns.httpsCallable('batchSaveGift')({
          giftIdList,
          giftList,
          data,
          type,
          accountId: this.accountId,
          updateBy,
          uid: this.userService.uid
        }).toPromise().then((response) => {
        }).catch((err) => {
          this.errorService.logError(err);
        });
      } else if (this.onlineService.online && giftIdList?.length > 1) {
        const batch = this.afs.firestore.batch();
        giftIdList?.forEach((giftId: string) => {
          if (giftList) {
            const giftIndex = giftList?.findIndex((x: Gift) => x?.giftId === giftId);
            if (giftIndex !== -1) {
              data = giftList[giftIndex];
            }
          }

          if (type === 'delete') {
            data.deleteBy = updateBy;
          } else {
            data.updateBy = updateBy;
            if (type === 'new') { data.createBy = updateBy; }
          }

          const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/gifts/${ giftId }`);
          batch.set(ref, data, { merge: true });
        });
        await batch.commit();
      } else {
        for (const giftId of giftIdList) {
          if (giftList) {
            const giftIndex = giftList?.findIndex((x: Gift) => x?.giftId === giftId);
            if (giftIndex !== -1) {
              data = giftList[giftIndex];
            }
          }

          if (type === 'delete') {
            data.deleteBy = updateBy;
          } else {
            data.updateBy = updateBy;
            if (type === 'new') { data.createBy = updateBy; }
          }
          await this.saveGift(giftId, data);
        }
      }
    }
  }

  /**
   * Save gift
   * @param giftId gift id
   * @param data gift data
   */
  async saveGift(giftId: string, data: any) {
    if (this.accountId && giftId && data) {
      const groupRef = this.afs.firestore.doc(`accounts/${ this.accountId }/gifts/${ giftId }`);
      groupRef.set(data, { merge: true });
    }
  }

}
