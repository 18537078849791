import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';

import { BehaviorSubject, async } from 'rxjs';
import { WebsiteInfo } from 'src/app/interfaces/website';
import { text } from 'stream/consumers';
import { FunctionService } from '../general/function.service';
import { AccountInfoService } from '../account/account-info.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteInfoService implements OnInit, OnDestroy {

  accountId: string;

  websiteInfo: WebsiteInfo;

  observable: any;

  constructor(
    private afs: AngularFirestore,
    private translate: TranslateService,
    private accountInfoService: AccountInfoService,
    private functionService: FunctionService,
  ) {
    this.observable = new BehaviorSubject<WebsiteInfo>(this.websiteInfo);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  setup(websiteInfo: WebsiteInfo) {
    if (!this.functionService.isEqual(this.websiteInfo, websiteInfo)) {
      this.websiteInfo = websiteInfo;
      this.observable.next(this.websiteInfo);
    }
  }

  getWebsiteInfo(): WebsiteInfo {
    if (this.websiteInfo) {
      return { ...this.websiteInfo };
    } else {
      return {
        title: this.getTitle(),
        msg: this.getMsg(),
      };
    }
  }

  getTitle(empty?: boolean) {
    const title = this.accountInfoService.accountTitle;
    if (empty) {
      return '<p class="ql-align-center"><span class="ql-size-huge"> </span></p>';
    }
    return '<p class="ql-align-center"><span class="ql-size-huge">' + title + '</span></p>';
  }

  getMsg(empty?: boolean) {
    if (empty) {
      return '<p class="ql-align-center"> </p>';
    }
    return '<p class="ql-align-center">' + this.translate.instant('WEBSITE.info.msg') + '</p>';
  }

  async save(info: WebsiteInfo) {
    if (this.accountId && !this.functionService.isEqual(info, this.websiteInfo)) {
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ info }, { merge: true });
    }
  }

}
