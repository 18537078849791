import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { SeatingSummaryService } from 'src/app/services/seating/seating-summary.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { SeatingSummary } from 'src/app/interfaces/seating';
import { Seating } from 'src/app/interfaces/seating';
import { Guest } from 'src/app/interfaces/guest';
import { FunctionService } from 'src/app/services/general/function.service';
import { SeatingService } from 'src/app/services/seating/seating.service';

/**
 * Preview swap seating component
 */
@Component({
  selector: 'app-seating-swap-preview',
  templateUrl: './seating-swap-preview.component.html',
  styleUrls: ['./seating-swap-preview.component.scss'],
})
export class SeatingSwapPreviewComponent implements OnInit, OnDestroy {

  /**
   * Watch screen resize change
   */
  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.setupContentWidth();
  }
  /**
   * Watch screen orientation change
   */
  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event: any) {
    this.setupContentWidth();
  }
  
  @ViewChild('content', { read: ElementRef }) contentElement: ElementRef;
  /**
   * Origin seating
   */
  selectedSeating: Seating;
  /**
   * Target seating
   */
  targetSeating: Seating;
  /**
   * Orgin seating summary
   */
  selectedSeatingSummary: SeatingSummary;
  /**
   * Target seating summary
   */
  targetSeatingSummary: SeatingSummary;

  /**
   * Origin guest list
   */
  selectedGuestList: Guest[];
  /**
   * Target guest list
   */
  targetGuestList: Guest[];
  /**
   * Selected guest ID list
   */
  selectedGuestIdList: string[];
  /**
   * Target guest ID list
   */
  targetGuestIdList: string[];

  contentWidth: number;
  gridSize: number;
  gridNameHeight: number;

  /**
   * Constructor
   * @param modalController Modal controller
   * @param guestListService Guest list service
   * @param groupService Guest group servivce
   * @param guestManageService Gust manage service
   * @param popupService Popup service
   */
  constructor(
    private modalController: ModalController,
    private guestManageService: GuestManageService,
    private seatingService: SeatingService,
    private seatingSummaryService: SeatingSummaryService,
    private functionService: FunctionService,
    private popupService: PopupService
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
  }

  /**
   * Setup seating summary
   */
  ionViewWillEnter() {
    this.setupContentWidth();
    this.setupSeatingSummary();
  }

  /**
   * Setup seating summary
   */
  setupSeatingSummary() {
    if (this.selectedSeating) {
      this.selectedSeatingSummary = { ...this.getSeatingSummary(this.selectedSeating) };
    }
    if (this.targetSeating) {
      this.targetSeatingSummary = { ...this.getSeatingSummary(this.targetSeating) };
    }

    this.setupSelectedSeating();
  }

  /**
   * Setup origin seating guest with target seating preview
   */
  setupSelectedSeating() {
    if (this.selectedSeatingSummary?.guestList?.length) {
      this.selectedGuestList?.forEach((guest: Guest) => {
        const index = this.selectedSeatingSummary.guestList?.findIndex((x: Guest) => x?.guestId === guest?.guestId);
        if (index !== -1) {
          this.selectedSeatingSummary.guestList?.splice(index, 1);
        }
        this.targetSeatingSummary.guestList.push(guest);
      });
    }
    this.setupTargetSeating();
  }

  /**
   * Setup target seating guest with origin seating preview
   */
  setupTargetSeating() {
    if (this.targetSeatingSummary?.guestList?.length) {
      this.targetGuestList?.forEach((guest: Guest) => {
        const index = this.targetSeatingSummary.guestList?.findIndex((x: Guest) => x?.guestId === guest?.guestId);
        if (index !== -1) {
          this.targetSeatingSummary.guestList?.splice(index, 1);
        }
        this.selectedSeatingSummary.guestList.push(guest);
      });
    }
  }

  /**
   * Get seating summary
   * @param seating Seating
   */
  getSeatingSummary(seating: Seating): SeatingSummary {
    return this.seatingSummaryService.getSeatingSummary(seating);
  }

  getContentWidth(): number {
    return this.contentElement?.nativeElement?.offsetWidth ? this.contentElement.nativeElement.offsetWidth : 0;
  }

  async setupContentWidth() {
    await this.functionService.delay(200);
    const contentWidth = this.getContentWidth();
    if (contentWidth) {
      this.contentWidth = (contentWidth);
      this.gridSize = this.seatingService.calculateGridItemSize(this.contentWidth);
      this.gridNameHeight = this.seatingService.calculateGridNameHeight(this.gridSize);
    } else {
      setTimeout(() => {
        this.setupContentWidth();
      }, 500);
    }
  }

  /**
   * Dismiss current modal
   * @param dismiss Dimiss previous modal flag
   */
  async dismissModal(dismiss?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ dismiss }); }
    }
  }

  /**
   * Confirm to save new guest seating
   */
  async confirm() {
    await this.popupService.presentLoading();

    let guestDataList: any[] = [];
    guestDataList = guestDataList.concat(this.selectedGuestList.map((guest: Guest) => {
      return { guestId: guest?.guestId, seating: this.targetSeating.name };
    }));

    guestDataList = guestDataList.concat(this.targetGuestList.map((guest: Guest) => {
      return { guestId: guest?.guestId, seating: this.selectedSeating.name };
    }));

    const guestIdList: string[] = guestDataList.map((x: any) => {
      return x?.guestId;
    });

    if (guestIdList?.length && guestDataList?.length) {
      await this.guestManageService.saveGuest(null, guestIdList, '', guestDataList);
    }

    await this.popupService.dismissLoading();
    await this.dismissModal(true);
    this.popupService.saveSuccessToast();
  }

}
