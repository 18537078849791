import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SearchSelectListComponent } from 'src/app/components/general/search-select-list/search-select-list.component';
import { BlastUsageDetail } from 'src/app/interfaces/blast';
// import { Country } from 'src/app/interfaces/database';
import { BlastPackageService } from 'src/app/services/blast/blast-package.service';
import { BlastPricingService } from 'src/app/services/blast/blast-pricing.service';
import { CurrencyService } from 'src/app/services/general/currency.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { LocalityService } from 'src/app/services/location/locality.service';
import { BlastType } from 'src/app/types/blast';
import { BlastPackageComponent } from '../blast-package/blast-package.component';
import { BlastCreditService } from 'src/app/services/blast/blast-credit.service';

@Component({
  selector: 'app-blast-credit-calculator',
  templateUrl: './blast-credit-calculator.component.html',
  styleUrls: ['./blast-credit-calculator.component.scss'],
})
export class BlastCreditCalculatorComponent  implements OnInit, OnDestroy {

  // countryList: Country[] = this.localityService.getCountryList();

  blastType: BlastType;

  smsBlastUsageDetailList: BlastUsageDetail[];
  whatsappBlastUsageDetailList: BlastUsageDetail[];

  currency: string;
  packageMode: boolean;

  minCredit: number = this.blastPackageService.minCredit;

  countryList: {
    [countryCode: string]: string;
  };

  smsCount: number;
  whatsAppCount: number;

  availableCredit: number;
  totalBlastCredit: number;
  totalAmount: number;

  minusAvailableCredit: boolean;


  constructor(
    private translate: TranslateService,
    private modalController: ModalController,
    private localityService: LocalityService,
    private currencyService: CurrencyService,
    private blastPackageService: BlastPackageService,
    private blastPricingService: BlastPricingService,
    private blastCreditService: BlastCreditService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  ionViewWillEnter() {
    if (!this.blastType) {
      this.blastType = 'sms';
    }
    if (!this.currency && this.currencyService.getAccountCurrency()?.code) {
      this.currency = this.currencyService.getAccountCurrency().code;
    }
    this.setup();
  }

  setup() {
    if (!this.smsBlastUsageDetailList?.length) {
      this.smsBlastUsageDetailList = [];
      this.smsBlastUsageDetailList.push({
        country: '',
        phone: 0,
        blastType: 'sms',
        blastCredit: 0,
        qty: 0,
      });
    }
    if (!this.whatsappBlastUsageDetailList?.length) {
      this.whatsappBlastUsageDetailList = [];
      this.whatsappBlastUsageDetailList.push({
        country: '',
        phone: 0,
        blastType: 'whatsapp',
        blastCredit: 0,
        qty: 0,
      });
    }
    this.setupTotalBlastCredit();
  }

  setupTotalBlastCredit() {

    this.smsCount = this.getTotalBlastCreditByType('sms');
    this.whatsAppCount = this.getTotalBlastCreditByType('whatsapp');
    this.setupTotal();
  }

  setupTotal() {
    this.availableCredit = this.getAvailableCredit();
    this.totalBlastCredit = this.getTotalBlastCredit();
    this.totalAmount = this.getTotalAmount(this.totalBlastCredit);
  }

  addItem() {
    const blastUsageDetail: BlastUsageDetail = {
      country: '',
      phone: 0,
      blastType: 'sms',
      blastCredit: 0,
      qty: 0,
    };
    if (this.blastType === 'sms') {
      if (!this.smsBlastUsageDetailList?.length) {
        this.smsBlastUsageDetailList = [];
      }
      this.smsBlastUsageDetailList.push(blastUsageDetail);
    } else if (this.blastType === 'whatsapp') {
      if (!this.whatsappBlastUsageDetailList?.length) {
        this.whatsappBlastUsageDetailList = [];
      }
      blastUsageDetail.blastType = 'whatsapp';
      this.whatsappBlastUsageDetailList.push(blastUsageDetail);
    }
  }

  removeItem(index: number) {
    if (this.blastType === 'sms') {
      this.smsBlastUsageDetailList.splice(index, 1);
    } else if (this.blastType === 'whatsapp') {
      this.whatsappBlastUsageDetailList.splice(index, 1);
    }
  }

  /**
   * Dismiss modal
   */
  async dismissModal(requiredCredit?: number) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { modal.dismiss({ requiredCredit }); }
    }
  }

  async presentBlastPackageModal(requiredCredit: number) {
    if (this.minusAvailableCredit) {
      requiredCredit = requiredCredit + this.availableCredit;
    }
    const modal = await this.modalController.create({
      component: BlastPackageComponent,
      componentProps: {
        requiredCredit,
        minusAvailableCredit: this.minusAvailableCredit,
      }
    });
    modal.present();
  }

  async openCountry(blastUsageDetail: BlastUsageDetail) {
    const selected = this.localityService.getCountryByCode(blastUsageDetail.country);
    const modal = await this.modalController.create({
      component: SearchSelectListComponent,
      componentProps: {
        items: this.localityService.getCountryList(),
        selected,
        title: this.translate.instant('LOCATION.lbl.country'),
        itemTextField: 'name',
        placeholder: this.translate.instant('BTN.search'),
        closeButtonText: this.translate.instant('BTN.cancel'),
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.item && !this.functionService.isEqual(selected, result.data.item)) {
        blastUsageDetail.country = result.data.item.code;
        blastUsageDetail.blastCredit = this.getBlastCredit(blastUsageDetail);
        if (!this.countryList) {
          this.countryList = {};
        }
        this.countryList[blastUsageDetail.country] = this.getCountryName(blastUsageDetail.country);
        this.setupTotalBlastCredit();
      }
    });
  }

  getCountryName(code: string) {
    return this.localityService.getCountryByCode(code)?.name;
  }

  getBlastUsageDetailList() {
    if (this.blastType === 'sms') {
      return this.smsBlastUsageDetailList;
    } else if (this.blastType === 'whatsapp') {
      return this.whatsappBlastUsageDetailList;
    }
  }

  addQty(blastUsageDetail: BlastUsageDetail) {
    if (!blastUsageDetail?.qty) {
      blastUsageDetail.qty = 0;
    } 
    blastUsageDetail.qty += 1;
    this.setupTotalBlastCredit();
  }

  minusQty(blastUsageDetail: BlastUsageDetail) {
    if (!blastUsageDetail?.qty) {
      blastUsageDetail.qty = 0;
    }
    if (blastUsageDetail.qty > 0) {
      blastUsageDetail.qty -= 1;
    }
    this.setupTotalBlastCredit();
  }

  getBlastCredit(blastUsageDetail: BlastUsageDetail): number {
    return this.blastPricingService.getBlastCredit(blastUsageDetail.blastType, blastUsageDetail.country);
  }

  getTotalBlastCreditByType(blastType: BlastType) {
    if (blastType === 'sms') {
      return this.smsBlastUsageDetailList?.reduce((a: number, b: BlastUsageDetail) => {
        return a + (b.blastCredit * b.qty);
      }, 0);
    } else if (blastType === 'whatsapp') {
      return this.whatsappBlastUsageDetailList?.reduce((a: number, b: BlastUsageDetail) => {
        return a + (b.blastCredit * b.qty);
      }, 0);
    }
  }

  getTotalBlastCredit() {
    if (this.minusAvailableCredit) {
      return this.getTotalBlastCreditByType('sms') + this.getTotalBlastCreditByType('whatsapp') - this.availableCredit;
    } else {
      return this.getTotalBlastCreditByType('sms') + this.getTotalBlastCreditByType('whatsapp');
    }
    
  }

  getTotalAmount(blastCredit: number): number {
    const price = this.blastPackageService.calculateBlastPackagePrice(blastCredit, this.currency);
    return price?.amount ? price.amount : 0;
  }

  getAvailableCredit() {
    return this.blastCreditService.getAvailableBlastCredit();
  }

  purchase(blastCredit: number) {
    if (blastCredit) {
      if (this.packageMode) {
        this.dismissModal(blastCredit);
      } else {
        this.presentBlastPackageModal(blastCredit);
      }
    }
  }
}
