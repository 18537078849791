import { LinkService } from 'src/app/services/general/link.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-chrome',
  templateUrl: './chrome.component.html',
  styleUrls: ['./chrome.component.scss'],
})
export class ChromeComponent implements OnInit, OnDestroy {

  facebookApp: boolean;

  constructor(
    private platform: Platform,
    private linkService: LinkService,
  ) {
  }

  ngOnInit(): void {
    this.checkBrowser();
  }

  ngOnDestroy(): void {
  }


  async checkBrowser() {
    await this.platform.ready();
    this.facebookApp = false;
    if (!this.platform.is('hybrid')) {
      if (this.isFacebookApp()) {
        this.facebookApp = true;
      }
    }
  }

  isFacebookApp() {
    const ua = navigator.userAgent || navigator.vendor;
    return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1) || (ua.indexOf('FB_IAB') > -1);
  }
  /**
   * Download App
   */
  async downloadApp() {
    this.linkService.openUrl('https://www.google.com/chrome');
  }

}
