import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { getTime, set } from 'date-fns';
import { UserAccounts } from 'src/app/interfaces/account';
import { AccountsListService } from 'src/app/services/accounts/accounts-list.service';
import { DateTimeService } from 'src/app/services/general/date-time.service';
import { DatetimeComponent } from '../../general/datetime/datetime.component';
import { FunctionService } from 'src/app/services/general/function.service';

@Component({
  selector: 'app-inbox-filter',
  templateUrl: './inbox-filter.component.html',
  styleUrls: ['./inbox-filter.component.scss'],
})
export class InboxFilterComponent  implements OnInit, OnDestroy {

  /**
   * Fitler form
   */
  filterForm: FormGroup;
  /**
   * Role List
   */
  userAccountsList: UserAccounts[] = this.accounstListService.userAccountsList;

  hideAccountSelect: boolean;
  /**
   * Filter
   */
  private filter: any;

  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private accounstListService: AccountsListService,
    private dateTimeService: DateTimeService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  ionViewWillEnter() {
    this.initialize();
  }

  initialize() {
    this.setupForm();
  }

  /**
   * Setup form
   */
  setupForm() {
    this.filterForm = this.formBuilder.group({
      accountId: new FormControl('', []),
      readStatus: new FormControl(null, []),
      startDate: new FormControl('', []),
      endDate: new FormControl('', []),
      createdBy: new FormControl('', []),
    });
    this.setupFilter(this.filter);
  }

  /**
   * Setup filter
   * @param filter Filter
   */
  async setupFilter(filter: any) {
    if (filter) {
      if (filter?.accountId) {
        this.filterForm.controls.accountId.setValue(filter.accountId);
      }
      if (filter?.startDate) {
        this.filterForm.controls.startDate.setValue(filter.startDate);
      }
      if (filter?.endDate) {
        this.filterForm.controls.endDate.setValue(filter.endDate);
      }
      if (!this.functionService.isUndefined(filter?.readStatus)) {
        this.filterForm.controls.readStatus.setValue(filter.readStatus);
      }
      if (filter?.createdBy) {
        this.filterForm.controls.createdBy.setValue(filter.createdBy);
      }
    }
  }

  isEmptyForm(): boolean {
    let empty = true;
    if (this.filterForm?.value) {
      Object?.keys(this.filterForm?.value)?.forEach((key: string) => {
        if (key && this.filterForm.value[key] && empty) {
          empty = false;
        }
      });
    }
    return empty;
  }

  /**
   * Reset form value
   */
  reset() {
    this.filterForm.reset();
  }

  async presentDatetimeModal(type: string) {
    if (type) {
      let time = ''
      let min = '';
      let max = '';
      if (this.filterForm?.value?.[type]) {
        time = this.dateTimeService.formatIsoByTimestamp(this.filterForm.value[type] / 1000);
      }
      if (type === 'startDate') {
        if (this.filterForm?.value?.endDate) {
          max = this.dateTimeService.formatIsoByTimestamp(this.filterForm.value.endDate / 1000);
        }
      } else if (type === 'endDate') {
        if (this.filterForm?.value?.startDate) {
          min = this.dateTimeService.formatIsoByTimestamp(this.filterForm.value.startDate / 1000);
        }
      }
      const modal = await this.modalController.create({
        component: DatetimeComponent,
        cssClass: 'modal-transparent',
        componentProps: {
          time,
          max,
          min,
        }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result?.data?.timestamp?.seconds) {
          this.filterForm.controls[type].setValue(result.data.timestamp.seconds * 1000);
        }
      });
    }
  }

  /**
   * Dismiss account user modal
   * @param uid User UID
   */
  async dismissModal(filter?: any) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ filter }); }
    }
  }

  filterFormSubmit() {
    this.filterForm.markAllAsTouched();
    if (this.filterForm.valid) {
      const filter = this.filterForm.value;
      if (filter.startDate) {
        filter.startDate = getTime(set(new Date(filter.startDate), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }));
      }

      if (filter.endDate) {
        filter.endDate = getTime(set(new Date(filter.endDate), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 }));
      }

      Object?.keys(this.filterForm.value)?.forEach(key => {
        if (key && !this.functionService.isUndefined(this.filterForm?.value?.[key])) { filter.enable = true; }
      });

      this.dismissModal(filter);
    }
  }

  format(timestamp: number, dateTimeFormat: string, timeZone?: string) {
    return this.dateTimeService.format(timestamp, dateTimeFormat, '', timeZone);
  }


}
