import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { DeviceService } from 'src/app/services/device/device.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { UpdateBy } from 'src/app/interfaces/user';

/**
 * update by service
 */
@Injectable({
  providedIn: 'root'
})
export class UpdateByService implements OnInit, OnDestroy {

  /**
   * uid
   */
  private uid: string;
  private uuid: string;

  /**
   * Constructor
   * @param functionService function service
   */
  constructor(
    private functionService: FunctionService
  ) { }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Set uid
   * @param uid uid
   */
  setUid(uid: string) {
    this.uid = uid;
  }

  setUuid(uuid: string) {
    this.uuid = uuid;
  }

  generateUpdateBy(): UpdateBy {
    return {
      uid: this.uid ? this.uid : '',
      uuid: this.uuid ? this.uuid : '',
      time: this.functionService.firestoreServerTime
    };
  }

  /**
   * Get Updated By object with UID, UUID, time, for firestore server timestamp
   * @returns Updated by obj with UID, UUID, time
   */
  get updateBy(): UpdateBy {
    return this.generateUpdateBy();
  }

  /**
   * Get updated by object with UID, UUID, time, for firestore array
   */
  getUpdateBy(): UpdateBy {
    return this.generateUpdateBy();
  }

}
