<ng-container *ngIf="pageMode">
  <ng-container *ngIf="true then list"></ng-container>
</ng-container>
<ng-container *ngIf="!pageMode">
  <ion-header #header>
    <ion-toolbar color="light">
      <ion-buttons slot="start">
        <ion-button (click)="back()">
          <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>
        <app-offline-indicator></app-offline-indicator>
        {{ 'BTN.history' | translate }}
      </ion-title>
    </ion-toolbar>
    <ion-toolbar class="ion-no-padding">
      <div class="seg-div full-width">
        <ion-segment class="seg-bar" [(ngModel)]="blastCreditType" scrollable>
          <ion-segment-button value="out">
            <ion-label>{{ 'LIST.blast_credit_type.out' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="in">
            <ion-label>{{ 'LIST.blast_credit_type.in' | translate }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </div>
    </ion-toolbar>
  </ion-header>
  
  <ion-content class="full-width ion-padding" color="light" #content>
    <div class="full-width-height" *ngIf="true then list"></div>
  </ion-content>
  
  <ion-footer #footer>
    <ion-toolbar color="white">    
      <ion-item class="total-item" lines="none">
        <ion-label class="font-20">
          <ng-container *ngIf="blastCreditType === 'out'">
            {{ 'BLAST.credit.total_used' | translate }}
          </ng-container>
          <ng-container *ngIf="blastCreditType === 'in'">
            {{ 'BLAST.credit.total_purchased' | translate }}
          </ng-container>
        </ion-label>
        <ion-text slot="end">
          <div class="font-20 font-med ion-text-wrap ion-text-center">{{ getTotal() }}</div>
          <div class="font-12 ion-text-center">
            {{ 'BLAST.credit.title' | translate }}
          </div>
        </ion-text>
      </ion-item>
    </ion-toolbar>
  </ion-footer>
</ng-container>

<ng-template #list>
  
  <ng-container *ngIf="blastCreditType === 'out'">
    <div class="full-width-height viewport-div" *ngIf="blastUsageList?.length">
      <cdk-virtual-scroll-viewport [itemSize]="86" #blastUsageListViewport>
        <ion-list class="full-width-height background-transparent ion-padding-bottom" lines="none" #list>
          <ion-card class="card" *cdkVirtualFor="let blastUsage of blastUsageList; trackBy: blastUsageTrackByFn; let i = index;" (click)="presentBlastUsageModal(blastUsage)" button>
            <ion-item class="usage-item item" lines="none" detail>
              <ion-icon src="./assets/wedding/icon/blast/credit/out.svg" slot="start"></ion-icon>
              <ion-label class="ion-text-wrap ion-no-margin">
                <div class="ion-text-wrap line-120">
                  <ion-text class="font-14 font-med" color="black">{{ 'BLAST.credit.count_type' | translate: { count: blastUsageItem?.[blastUsage?.usageId]?.count, type: ('BLAST.lbl.' + blastUsage.blastType | translate) } }}</ion-text>
                </div>
                <div class="line-120" *ngIf="blastUsage?.createBy?.time?.seconds">
                  <ion-text class="font-10" color="dark">{{ format(blastUsage.createBy.time.seconds * 1000, 'do MMM yyyy, EEE h:mm a') }}</ion-text>
                </div>
                <div class="line-120 ion-text-wrap">
                  <ion-text class="font-12" color="black">{{ blastUsageItem?.[blastUsage?.usageId]?.status }}</ion-text>
                </div>
              </ion-label>
              <ion-note class="ion-text-wrap ion-no-margin" slot="end">
                <div class="ion-text-right">
                  <ion-text class="font-14 font-med" color="danger">- {{ blastUsage.blastCredit }}</ion-text>
                </div>
              </ion-note>
            </ion-item>
          </ion-card>
        </ion-list>
      </cdk-virtual-scroll-viewport>
    </div>
    <ng-container *ngIf="!blastUsageList?.length && !pageMode then no_found"></ng-container>
  </ng-container>

  <ng-container *ngIf="blastCreditType === 'in'">
    <div class="full-width-height viewport-div" *ngIf="blastCreditList?.length">
      <cdk-virtual-scroll-viewport [itemSize]="86" #blastCreditListViewport>
        <ion-list class="full-width-height background-transparent ion-padding-bottom" lines="none" #list>
          <ion-card class="card" *cdkVirtualFor="let blastCredit of blastCreditList; trackBy: blastCreditTrackByFn; let i = index;" (click)="presentBlastCreditDetailModal(blastCredit)" button>
            <ion-item class="credit-item item" lines="none" detail>
              <ion-icon src="./assets/wedding/icon/blast/credit/in.svg" slot="start"></ion-icon>
              <ion-text class="ion-no-margin" slot="start">
                <div class="line-120">
                  <ion-text class="font-14 font-med" color="black">
                    <ng-container *ngIf="blastCredit?.type === 'redeem'">
                      {{ 'CREDIT.lbl.redeemed' | translate }}
                    </ng-container>
                    <ng-container *ngIf="blastCredit?.type === 'free' || blastCredit?.type === 'account'">
                      {{ 'PAYMENT.type.free' | translate }}
                    </ng-container>
                    <ng-container *ngIf="blastCredit?.type === 'purchase'">
                      {{ 'TRANSACTION.lbl.purchased' | translate }}
                    </ng-container>
                    <ng-container *ngIf="blastCredit?.type === 'refund'">
                      {{ 'REFUND.lbl.refunded' | translate }}
                    </ng-container>
                  </ion-text>
                </div>
                <div class="line-120" *ngIf="blastCredit?.createBy?.time?.seconds">
                  <ion-text class="font-10" color="dark">{{ format(blastCredit.createBy.time.seconds * 1000, 'do MMM yyyy, EEE h:mm a') }}</ion-text>
                </div>
              </ion-text>
              <ion-label class="ion-text-wrap ion-no-margin" slot="end">
                <div class="ion-text-right">
                  <ion-text class="font-14 font-med" [color]="blastCredit.blastCredit >= 0 ? 'success' : 'danger'">
                    <ng-container *ngIf="blastCredit.blastCredit >= 0">+</ng-container>
                    {{ blastCredit.blastCredit }}
                  </ion-text>
                </div>
              </ion-label>
            </ion-item>
          </ion-card>
        </ion-list>
      </cdk-virtual-scroll-viewport>
    </div>
    <ng-container *ngIf="!blastCreditList?.length && !pageMode then no_found"></ng-container>
  </ng-container>
</ng-template>

<ng-template #no_found>
  <div class="full-width-height vertical-center ion-padding">
    <div class="center">
      <ion-label class="ion-text-center ion-margin-horizontal ion-padding-horizontal" color="medium">
        <ion-icon class="center icon-cactus" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
        <div class="ion-margin-top font-14">{{ 'LBL.no_found' | translate }}</div>
        <div class="ion-margin" *ngIf="!pageMode">
          <ion-button expand="block" (click)="presentBlastPackageModal()" *ngIf="blastCreditType === 'in'">
            {{ 'BLAST.credit.purchase' | translate }}
          </ion-button>
          <ion-button expand="block" (click)="presentTemplateManageModal()" *ngIf="blastCreditType === 'out'">
            {{ 'BLAST.lbl.send' | translate }}
          </ion-button>
        </div>
      </ion-label>
    </div>
  </div>
</ng-template>
