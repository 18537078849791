import { GroupManageService } from 'src/app/services/group/group-manage.service';
import { GroupService } from 'src/app/services/group/group.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, ActionSheetController, Platform } from '@ionic/angular';
import { Group } from 'src/app/interfaces/group';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Mobile } from 'src/app/interfaces/general';
import { ModuleType } from 'src/app/types/general';
import { Subscription } from 'rxjs';
import { KeyboardService } from 'src/app/services/general/keyboard.service';

import { ReadContactsComponent } from 'src/app/components/general/read-contacts/read-contacts.component';

@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.scss'],
})
export class GroupEditComponent implements OnInit, OnDestroy {
  newGroup: boolean;
  group: Group;
  /**
   * Guest edit form
   */
  form: FormGroup;
  /**
   * Validation msg
   */
  validationMsg: any;

  mobile: Mobile;
  mobileInvalid: boolean;

  module: ModuleType;

  isHybrid: boolean;

  private groupListSubscription: Subscription;

  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private moduleService: ModuleService,
    private groupService: GroupService,
    private groupManageService: GroupManageService,
    private keyboardService: KeyboardService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.unwatch();
  }
  

  /**
   * Before view enter
   */
  ionViewWillEnter() {
    this.isHybrid = this.platform.is('hybrid');
    if (!this.module) {
      this.module = this.moduleService.currentModule;
    }
    this.watch();
    this.setupForm();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatch();
  }

  watch() {
    this.watchGroupList();
  }

  unwatch() {
    this.unwatchGroupList();
  }

  /**
   * Watch guest list changes
   */
  async watchGroupList() {
    if (!this.groupListSubscription) {
      this.groupListSubscription = this.groupService.observableGroupList.subscribe(() => {
        this.setupGroup();
      });
    }
    
  }

  /**
   * Unwatch guest list
   */
  async unwatchGroupList() {
    if (this.groupListSubscription) {
      this.groupListSubscription.unsubscribe();
      this.groupListSubscription = null;
    }
  }

  setupGroup() {
    if (this.group?.groupId) {
      const group = this.groupService.getGroup(this.group.groupId);
      if (!this.isEqual(this.group, group)) {
        this.setupFormValue(group);
      }
    }
  }

  /**
   * Setup form
   */
  setupForm() {
    this.form = this.formBuilder.group({
      groupName: new FormControl('', [ Validators.required ]),
      email: new FormControl('', [ Validators.email ]),
    });

    this.validationMsg = {
      groupName: [{ type: 'required', msg: this.translate.instant('VALIDATION.required',
        { field: this.translate.instant('GUEST.lbl.guest_name') }) }],
      email: [{ type: 'email', msg: this.translate.instant('VALIDATION.invalid_format',
        { field: this.translate.instant('LBL.email') }) }],
    };
    this.setupFormValue();
  }

  /**
   * Setup form vale
   */
  setupFormValue(group?: Group) {
    if (this.form) {
      if (group) {
        if (group.groupName !== this.group.groupName && this.group.groupName === this.form.value.groupName) {
          this.form.controls.groupName.setValue(group.groupName);
        }
        if (group.email !== this.group.email && this.group.email === this.form.value.email) {
          this.form.controls.email.setValue(group.email);
        }
        if (group?.mobile?.no !== this.group?.mobile?.no || group?.mobile?.code !== this.group?.mobile?.code) {
          const mobile: string = this.mobile?.no ? this.mobile?.no : '';
          const code: number = this.mobile?.code ? this.mobile.code : 0;
          if (this.group?.mobile?.no === mobile && this.group?.mobile?.code === code) {
            this.mobile = group.mobile;
          }
        }
        this.group = group;
      } else if (this.group) {
        this.form.controls.groupName.setValue(this.group?.groupName ? this.group.groupName : '');
        this.form.controls.email.setValue(this.group.email ? this.group.email : '');
        if (this.group?.mobile?.no) {
          this.mobile = this.group.mobile;
        }
      }
      this.form.updateValueAndValidity();
    }
  }

  updateMobile(mobile: Mobile) {
    this.mobile = mobile;
  }

  updateMobileInvalid(invalid: boolean) {
    this.mobileInvalid = invalid;
  }

  /**
   * Dismiss guest edit modal
   * @param data new data
   * @param dismiss dismiss previous modal
   */
  async dismissModal(data?: any, dismiss?: boolean) {
    if (this.modalController) {
      if (data) {
        Object?.keys(data)?.forEach(key => {
          if (!this.isEqual(data[key], this.group[key])) {
            this.group[key] = data[key];
          }
        });
      }
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ group: data ? this.group : null, dismiss }); }
    }
  }

  async presentReadContactsModal() {
    const modal = await this.modalController.create({
      component: ReadContactsComponent,
      cssClass: '',
      componentProps: {
        editMode: true,
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.mobile?.no) {
        this.mobile = result.data.mobile;
      }
      if (result?.data?.email && this.functionService.validateEmail(result.data.email)) {
        this.form.controls?.email.setValue(result.data.email);
      }
    });
  }

  /**
   * Discard changes, prompt confirm action sheet if changes detect
   */
  async discard() {
    let flag = true;
    if (this.group) {
      Object.keys(this.form?.value)?.forEach((key: string) => {
        if (!this.isEqual(this.form.value[key], this.group[key])) {
          flag = false;
        }
      });
    }

    if (!flag) {
      const actionSheet = await this.actionSheetController.create({
        header: this.translate.instant('MSG.discard_msg'),
        buttons: [{
          text: this.translate.instant('BTN.confirm'),
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            this.dismissModal();
          }
        }, {
          text: this.translate.instant('BTN.cancel'),
          icon: 'close',
          role: 'cancel',
          handler: () => {
          }
        }]
      });
      actionSheet.present();
    }  else {
      this.dismissModal();
    }
  }

  /**
   * Check is two variable is equal
   * @param a variable a
   * @param b variable b
   * @returns true if two varible is equal
   */
  isEqual(a: any, b: any): boolean {
    return this.functionService.isEqual(a, b);
  }

  async groupEditFormSubmit() {
    this.form.markAllAsTouched();
    if (this.form.valid && !this.mobileInvalid) {
      await this.popupService.presentLoading();
      const data: any = {};
      Object.keys(this.form?.value)?.forEach((key: string) => {
        if (!this.isEqual(this.form.value?.[key], this.group?.[key])) {
          data[key] = this.form.value[key];
        }
      });
      if (this.mobile?.no !== this.group?.mobile?.no || this.mobile?.code !== this.group?.mobile?.code) {
        data.mobile = this.mobile;
        if (!data.mobile) {
          data.mobile = {
            code: 0,
            country: '',
            no: ''
          };
        }
        if (!data?.mobile?.no) {
          data.mobile.no = '';
        }
        if (!data?.mobile?.code) {
          data.mobile.code = 0;
        }
      }
      await this.prepareSave(data);
    }
  }

  /**
   * Prepare save
   * @param data data
   */
  async prepareSave(data: any) {
    if (this.group.groupId && !this.functionService.isEmpty(data)) {
      await this.groupManageService.saveGroup(this.group.groupId, data);
      this.saveDone(data);
    } else {
      this.saveDone(data);
    }
  }

  /**
   * Save done, dismiss modal and present success toast msg.
   * @param data new data to be update
   */
  async saveDone(data: any) {
    await this.popupService.dismissLoading();
    if (data) {
      await this.dismissModal(data);
    }
    this.popupService.saveSuccessToast();
  }

}
