import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';

import { FunctionService } from 'src/app/services/general/function.service';

import { PageData } from 'src/app/interfaces/visitor';

import { VisitorSettingService } from './visitor-setting.service';
import { VisitorThemeService } from './visitor-theme.service';
import { VisitorInfoService } from './visitor-info.service';
import { VisitorLogoService } from './visitor-logo.service';
import { VisitorManageService } from './visitor-manage.service';
import { VisitorLanguageService } from './visitor-language.service';


/**
 * Visitor service
 */
@Injectable({
  providedIn: 'root'
})
export class VisitorService implements OnInit, OnDestroy {

  /**
   * Observable page data
   */
  observablePageData: any;

  /**
   * Page data
   */
  pageData: PageData;

  quickSetup: boolean;

  /**
   * Page setting subscription
   */
  private pageSettingSubscription: Subscription;
  
  /**
   * Account ID
   */
  private accountId: string;

  /**
   * Constructor
   * @param afs Angular firestore
   * @param languageService device language service
   * @param userService user service
   * @param functionService function service
   */
  constructor(
    private afs: AngularFirestore,
    private visitorSettingService: VisitorSettingService,
    private visitorInfoService: VisitorInfoService,
    private visitorThemeService: VisitorThemeService,
    private visitorLanguageService: VisitorLanguageService,
    private visitorLogoService: VisitorLogoService,
    private visitorManageService: VisitorManageService,
    private functionService: FunctionService,
  ) {
    this.observablePageData = new BehaviorSubject<PageData>(this.pageData);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy() {
    this.unwatchPageSetting();
    this.pageData = null;
  }

  /**
   * Setup Account ID and watch / unwatch page setting
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
    this.visitorManageService.setupAccountId(this.accountId);

    this.visitorLogoService.setupAccountId(this.accountId);
    if (this.accountId) {
      await this.watchPageSetting();
    } else {
      await this.unwatchPageSetting();
      this.pageData = null;
    }
  }

  async readPageSetting() {
    if (this.accountId) {
      const docSnapshot = await firstValueFrom(this.afs.doc(`accounts/${this.accountId}/accountSetting/page`).get());
      const result = docSnapshot.data();
      if (result) {
        const pageData: PageData = result as PageData;
        this.quickSetup = pageData?.quickSetup ? true : false;

        if (!this.functionService.isEqual(pageData, this.pageData)) {
          this.pageData = pageData;
          this.observablePageData.next(this.pageData);
        }
        
        if (this.functionService.isUndefined(pageData?.setting?.qrcode) && pageData?.setting?.backgroundUrl) {
          const setting = this.visitorSettingService.setupPageSetting();
          setting.backgroundUrl = pageData?.setting?.backgroundUrl;
        }

        if (!this.functionService.isEqual(pageData.setting, this.visitorSettingService.pageSetting)) {
          if (!this.functionService.isUndefined(pageData.setting?.timezone)) {
            delete pageData.setting.timezone;
          }
          if (!this.functionService.isUndefined(pageData.setting?.cocktail)) {
            delete pageData.setting.cocktail;
          }
          if (this.functionService.isUndefined(pageData.setting?.backgroundUrl)) {
            if (pageData?.setting) {
              pageData.setting.backgroundUrl = '';
            }
          }
          if (this.functionService.isUndefined(pageData.setting?.floorplan)) {
            if (pageData?.setting) {
              pageData.setting.floorplan = null;
            }
          }
          if (this.functionService.isUndefined(pageData.setting?.menu)) {
            if (pageData?.setting) {
              pageData.setting.menu = null;
            }
          }
          if (this.functionService.isUndefined(pageData.setting?.attachment)) {
            if (pageData?.setting) {
              pageData.setting.attachment = [];
            }
          }
          if (this.functionService.isUndefined(pageData.setting?.session)) {
            if (pageData?.setting) {
              pageData.setting.session = false;
            }
          }
          if (this.functionService.isUndefined(pageData.setting?.seatingBeforeCheckin)) {
            if (pageData?.setting) {
              pageData.setting.seatingBeforeCheckin = false;
              if (pageData.setting.seating && !pageData.setting?.checkinSeating) {
                pageData.setting.seatingBeforeCheckin = true;
              }
            }
          }
          if (!this.functionService.isUndefined(pageData.setting?.seating)) {
            delete pageData.setting.seating;
          }
          if (!this.functionService.isUndefined(pageData.setting?.checkinSeating)) {
            delete pageData.setting.checkinSeating;
          }
          if (this.functionService.isUndefined(pageData.setting?.groupMember)) {
            if (pageData?.setting) {
              pageData.setting.groupMember = true;
            }
          }

          if (pageData?.setting?.floorplan?.url && this.functionService.isUndefined(pageData?.setting?.floorplan?.enable)) {
            if (pageData?.setting) {
              pageData.setting.floorplan.enable = true;
            }
          }

          if (pageData?.setting?.menu?.url && this.functionService.isUndefined(pageData?.setting?.menu?.enable)) {
            if (pageData?.setting) {
              pageData.setting.menu.enable = true;
            }
          }

          if (pageData?.setting?.attachment?.[0]?.url && this.functionService.isUndefined(pageData?.setting?.attachment?.[0]?.enable)) {
            if (pageData?.setting) {
              pageData.setting.attachment[0].enable = true;
            }
          }
          if (this.functionService.isUndefined(pageData.setting?.grab)) {
            if (pageData?.setting) {
              pageData.setting.grab = true;
            }
          }
          if (this.functionService.isUndefined(pageData.setting?.apple)) {
            if (pageData?.setting) {
              pageData.setting.apple = true;
            }
          }
          
          this.visitorSettingService.setupPageSetting(pageData.setting);
        }

        this.visitorThemeService.setupPageTheme(pageData?.themeId);
        this.visitorInfoService.setupPageInfo(pageData?.info);
        this.visitorLanguageService.setupPageLangList(pageData?.languageList);
      }
    }
  }

  /**
   * Watch page setting
   */
  async watchPageSetting() {
    if (this.accountId) {
      if (!this.pageSettingSubscription) {
        this.pageSettingSubscription = this.afs.doc(`accounts/${ this.accountId }/accountSetting/page`)
      .snapshotChanges().subscribe({
        next: (result) => {
          if (result?.payload?.data()) {
            const pageData: PageData = result.payload.data() as PageData;
            this.quickSetup = pageData?.quickSetup ? true : false;

            if (!this.functionService.isEqual(pageData, this.pageData)) {
              this.pageData = pageData;
              this.observablePageData.next(this.pageData);
            }
            
            if (this.functionService.isUndefined(pageData?.setting?.qrcode) && pageData?.setting?.backgroundUrl) {
              const setting = this.visitorSettingService.setupPageSetting();
              setting.backgroundUrl = pageData?.setting?.backgroundUrl;
            }

            if (!this.functionService.isEqual(pageData.setting, this.visitorSettingService.pageSetting)) {
              if (!this.functionService.isUndefined(pageData.setting?.timezone)) {
                delete pageData.setting.timezone;
              }
              if (!this.functionService.isUndefined(pageData.setting?.cocktail)) {
                delete pageData.setting.cocktail;
              }
              if (this.functionService.isUndefined(pageData.setting?.backgroundUrl)) {
                if (pageData?.setting) {
                  pageData.setting.backgroundUrl = '';
                }
              }
              if (this.functionService.isUndefined(pageData.setting?.floorplan)) {
                if (pageData?.setting) {
                  pageData.setting.floorplan = null;
                }
              }
              if (this.functionService.isUndefined(pageData.setting?.menu)) {
                if (pageData?.setting) {
                  pageData.setting.menu = null;
                }
              }
              if (this.functionService.isUndefined(pageData.setting?.attachment)) {
                if (pageData?.setting) {
                  pageData.setting.attachment = [];
                }
              }
              if (this.functionService.isUndefined(pageData.setting?.session)) {
                if (pageData?.setting) {
                  pageData.setting.session = false;
                }
              }
              if (this.functionService.isUndefined(pageData.setting?.seatingBeforeCheckin)) {
                if (pageData?.setting) {
                  pageData.setting.seatingBeforeCheckin = false;
                  if (pageData.setting.seating && !pageData.setting?.checkinSeating) {
                    pageData.setting.seatingBeforeCheckin = true;
                  }
                }
              }
              if (!this.functionService.isUndefined(pageData.setting?.seating)) {
                delete pageData.setting.seating;
              }
              if (!this.functionService.isUndefined(pageData.setting?.checkinSeating)) {
                delete pageData.setting.checkinSeating;
              }
              if (this.functionService.isUndefined(pageData.setting?.groupMember)) {
                if (pageData?.setting) {
                  pageData.setting.groupMember = true;
                }
              }

              if (pageData?.setting?.floorplan?.url && this.functionService.isUndefined(pageData?.setting?.floorplan?.enable)) {
                if (pageData?.setting) {
                  pageData.setting.floorplan.enable = true;
                }
              }

              if (pageData?.setting?.menu?.url && this.functionService.isUndefined(pageData?.setting?.menu?.enable)) {
                if (pageData?.setting) {
                  pageData.setting.menu.enable = true;
                }
              }

              if (pageData?.setting?.attachment?.[0]?.url && this.functionService.isUndefined(pageData?.setting?.attachment?.[0]?.enable)) {
                if (pageData?.setting) {
                  pageData.setting.attachment[0].enable = true;
                }
              }
              if (this.functionService.isUndefined(pageData.setting?.grab)) {
                if (pageData?.setting) {
                  pageData.setting.grab = true;
                }
              }
              if (this.functionService.isUndefined(pageData.setting?.apple)) {
                if (pageData?.setting) {
                  pageData.setting.apple = true;
                }
              }
              
              this.visitorSettingService.setupPageSetting(pageData.setting);
            }

            this.visitorThemeService.setupPageTheme(pageData?.themeId);
            this.visitorInfoService.setupPageInfo(pageData?.info);
            this.visitorLanguageService.setupPageLangList(pageData?.languageList);

          }
        }
      });
      }
    }
  }

  /**
   * Unwatch page setting
   */
  async unwatchPageSetting() {
    if (this.pageSettingSubscription) {
      this.pageSettingSubscription.unsubscribe();
      this.pageSettingSubscription = null;
      // this.visitorSettingService.setupPageSetting();

      // this.pageData = null;
      // this.observablePageData.next(this.pageData);

      // this.visitorLanguageService.setupPageLangList();
      // this.visitorThemeService.setupPageTheme();
      // this.visitorInfoService.setupPageInfo();
      
    }
  }

  getQuickSetupStatus() {
    if (this.pageData?.quickSetup || this.pageData?.info || (this.pageData?.setting && this.pageData?.themeId)) {
      return true;
    }
    return false;
  }

}
