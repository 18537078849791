// import { AccountsListService } from './../accounts/accounts-list.service';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from 'src/app/services/general/popup.service';
// import { AccountService } from 'src/app/services/account/account.service';

import { UserManageService } from 'src/app/services/user/user-manage.service';
import { Platform } from '@ionic/angular';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
// import { UserWelcomeComponent } from 'src/app/components/user/user-welcome/user-welcome.component';
import { UserService } from './user.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/interfaces/user';
import { DeviceService } from '../device/device.service';
import { FunctionService } from '../general/function.service';
import { StorageService } from '../general/storage.service';


@Injectable({
  providedIn: 'root'
})
export class UserPromptService implements OnInit, OnDestroy {

  private checked: boolean;
  private aupModal: HTMLIonModalElement;
  // private welcomeModal: HTMLIonModalElement;
  private userSubscription: Subscription;

  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private userService: UserService,
    private userManageService: UserManageService,
    private deviceService: DeviceService,
    private functionService: FunctionService,
    private storageService: StorageService,
    private popupService: PopupService,
  ) { }

  ngOnInit(): void {
      
  }

  ngOnDestroy() {
    this.unwatchUser();
  }

  async initial() {
    await this.watchUser();
  }

  async watchUser() {
    if (!this.userSubscription) {
      this.userSubscription = this.userService.observableUser.subscribe((user: User) => {
        if (user?.uid && user?.name && !this.checked) {
          this.checked = true;
          setTimeout(() => {
            this.checkIos16Prompt();
          }, 800);
        }
      });
    }
    
  }

  async unwatchUser() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
      this.userSubscription = null;
    }
  }

  // async checkAupPrompt() {
  //   if (this.userService.user?.uid) {
  //     if (!this.userService.user?.prompt?.aup) {
  //       this.presentAupConfirm();
  //     } else if (!this.userService.user?.prompt?.welcome) {
  //       this.checkWelcomePrompt();
  //     } else {
  //       this.checkIos16Prompt();
  //     }
  //   }
  // }

  // async checkWelcomePrompt() {
  //   if (this.userService.user?.uid) {
  //     if (!this.userService.user?.prompt?.welcome) {
  //       this.updateUser({ prompt: { welcome: true }});
  //       this.checkIos16Prompt();
  //     } else {
  //       this.checkIos16Prompt();
  //     }
  //   }
  // }

  // async presentAupConfirm() {
  //   if (!this.aupModal) {
  //     this.aupModal = await this.popupService.presentConfirm(
  //       this.translate.instant('AUP.prompt_new'),
  //       '', '', '',
  //       this.translate.instant('AUP.btn.agree_prompt'),
  //       this.translate.instant('AUP.btn.more'),
  //     );
  //     this.aupModal.onDidDismiss().then((result: any) => {
  //       if (result?.data?.confirm) {
  //         this.updateUser({ prompt: { aup: true }});
  //         this.checkWelcomePrompt();
  //       } else {
  //         this.presentAupModal();
  //       }
  //     });
  //   }
  // }

  // async presentWelcomeModal() {
  //   if (!this.welcomeModal) {
  //     this.welcomeModal = await this.modalController.create({
  //       component: UserWelcomeComponent,
  //       cssClass: 'modal-transparent',
  //       componentProps: { }
  //     });
  //     this.welcomeModal.present();
  //     this.welcomeModal.onDidDismiss().then(() => {
  //       this.updateUser({ prompt: { welcome: true }});
  //       this.checkIos16Prompt();
  //     });
  //   }
  // }

  updateUser(data: any) {
    this.userManageService.updateUser(data);
  }

  // async presentAupModal() {
  //   const modal = await this.modalController.create({
  //     component: AupComponent,
  //     cssClass: 'modal-transparent',
  //     componentProps: {
  //       promptMode: true,
  //     }
  //   });
  //   modal.present();
  //   modal.onDidDismiss().then(() => {
  //     this.checkWelcomePrompt();
  //   });
  // }

  async checkIos16Prompt() {
    let flag = false;
    if (this.platform.is('hybrid') && this.platform.is('ios')) {
      const version = this.deviceService.deviceInfo.osVersion;
      if (this.functionService.compareVersion(version, '16.0.0', '>=') && this.functionService.compareVersion(version, '16.1.0', '<')) {
        if (!await this.storageService.get('ios16_update')) {
          this.popupService.presentAlert(this.translate.instant('IOS.16_update'));
          this.storageService.set('ios16_update', 'true');
        } else {
          flag = true;
        }
      } else {
        flag = true;
      }
    } else {
      flag = true;
    }
  }

  // checkBlastNoticePrompt() {
  //   const accountCountry = this.localictyService.getAccountCountry()?.code;
  //   const userCountry = this.localictyService.getUserCountry()?.code;
  //   if (accountCountry === 'US') {
  //     this.presentBlastNoticeModal(accountCountry);
  //   } else if (accountCountry === 'MY') {
  //     this.presentBlastNoticeModal(accountCountry);
  //   } else if (accountCountry === 'SG') {
  //     this.presentBlastNoticeModal(accountCountry);
  //   } else if (userCountry === 'US') {
  //     this.presentBlastNoticeModal(userCountry);
  //   } else if (userCountry === 'MY') {
  //     this.presentBlastNoticeModal(userCountry);
  //   } else if (userCountry === 'SG') {
  //     this.presentBlastNoticeModal(userCountry);
  //   }
  // }

  // async presentBlastNoticeModal(country: string) {
  //   const modal = await this.modalController.create({
  //     component: BlastNoticeComponent,
  //     cssClass: 'modal-transparent',
  //     componentProps: {
  //       type: 'sms-notice',
  //       country,
  //     }
  //   });
  //   modal.present();
  // }

}
