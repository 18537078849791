import { Platform } from '@ionic/angular';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { DeviceDownloadService } from 'src/app/services/device/device-download.service';
import { AppService } from 'src/app/services/general/app.service';
import { PopupService } from 'src/app/services/general/popup.service';

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss'],
})
export class DownloadAppComponent implements OnInit, OnDestroy {

  facebookApp: boolean;
  /**
   * Constructor
   * @param platform Platform
   * @param deviceDownloadService device donwload service
   */
  constructor(
    private platform: Platform,
    private appService: AppService,
    private deviceDownloadService: DeviceDownloadService,
    private popupService: PopupService,
  ) {
    
  }

  ngOnInit(): void {
    this.checkBrowser();
  }

  ngOnDestroy(): void {
  }


  async checkBrowser() {
    await this.platform.ready();
    this.facebookApp = false;
    if (!this.platform.is('hybrid')) {
      if (this.isFacebookApp()) {
        this.facebookApp = true;
      }
    }
  }

  isFacebookApp() {
    const ua = navigator.userAgent || navigator.vendor;
    return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1) || (ua.indexOf('FB_IAB') > -1);
  }
  /**
   * Download App
   */
  async downloadApp() {
    await this.popupService.presentLoading();
    this.appService.goStore();
    this.popupService.dismissLoading();
  }

  /**
   * Dismiss popover
   */
  async dismissPopover() {
    this.deviceDownloadService.dismissAppPopover();
  }

}
