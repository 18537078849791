import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, map } from 'rxjs';

import { FunctionService } from 'src/app/services/general/function.service';

import { Seating } from 'src/app/interfaces/seating';
import { SettingField } from 'src/app/interfaces/database';
import { SeatingSettingService } from './seating-setting.service';

/**
 * Seating service, watching seating, search seating, generate new seating / update seating.
 */
@Injectable({
  providedIn: 'root'
})
export class SeatingService implements OnInit, OnDestroy {

  readonly nameMaxLength = 40;
  /**
   * Seating list
   */
  seatingList: Seating[];
  /**
   * Guest per seating
   */
  // guestPerSeating: number;

  // seatingType: SettingField;
  // seatingTypeOther: string;

  /**
   * Observable seating list
   */
  observableSeatingList: any;
  /**
   * Observable guest per seating
   */
  // observableGuestPerSeating: any;

  // observableSeatingType: any;
  // observableSeatingTypeOther: any;

  /**
   * Account ID
   */
  private accountId: string;
  /**
   * Seating list subscription
   */
  private seatingtListSubscription: Subscription;

  /**
   * Constructor
   * @param afs angular firestore
   * @param functionService function service
   */
  constructor(
    private afs: AngularFirestore,
    private seatingSettingService: SeatingSettingService,
    private functionService: FunctionService,
  ) {
    this.seatingList = [];
    this.observableSeatingList = new BehaviorSubject<Seating[]>(this.seatingList);
    // this.observableGuestPerSeating = new BehaviorSubject<number>(this.guestPerSeating);
    // this.observableSeatingType = new BehaviorSubject<SettingField>(this.seatingType);
    // this.observableSeatingTypeOther = new BehaviorSubject<string>(this.seatingTypeOther);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy() {
    this.unwatchSeatingList();
    this.seatingList = [];
  }

  /**
   * Setup Account ID and watch / unwatch seating list
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
    
    if (this.accountId) {
      await this.watchSeatingList();
    } else {
      await this.unwatchSeatingList();
      this.seatingList = [];
    }
  }

  /**
   * Watch seating list from firestore
   */
  async watchSeatingList() {
    if (this.accountId && !this.seatingtListSubscription) {
      this.seatingtListSubscription = this.afs.doc(`accounts/${ this.accountId }/accountSetting/seating/`)
      .snapshotChanges().pipe(map(changes => {
        const data: any = changes.payload.data();
        this.seatingSettingService.setupGuestPerSeating(data?.guestPerSeating);
        this.seatingSettingService.setupSeatingType(data?.seatingType);
        this.seatingSettingService.setupSeatingTypeOther(data?.seatingTypeOther);
        this.seatingSettingService.setupAlphabet(data?.alphabet ? true : false);
        this.seatingSettingService.setupSkip4(data?.skip4 ? true : false);
        this.seatingSettingService.setupReplace4(data?.replace4 ? true : false);
        return data?.list;
      }), map((seatingList: Seating[]) => {
        seatingList = seatingList?.map((seating: Seating) => {
          if (seating?.createBy) {
            delete seating.createBy;
          }
          if (seating.updateBy) {
            delete seating.updateBy;
          }
          if (!seating?.invitedBy?.length) {
            seating.invitedBy = [];
          }
          if (!seating?.category?.length) {
            seating.category = [];
          }
          return seating;
        });
        return seatingList?.length ? seatingList?.filter((seating: Seating) => {
          if (seating?.deleted || !seating?.name) {
            return false;
          } else {
            return true;
          }
        }) : [];
      })).subscribe({
        next: (seatingList: Seating[]) => {
          if (!seatingList?.length || !this.functionService.isEqual(this.seatingList, seatingList)) {
            this.setupSeatingList(seatingList);
          }
        }, error: (err: any) => {
          console.log(err);
        }
      });
    }
  }

  setupSeatingList(seatingList: Seating[]) {
    // if (seatingList?.length) {
    //   const index = seatingList?.findIndex((seating: Seating) => {
    //     if (!seating?.name) {
    //       console.log(seating);
    //       return true;
    //     }
    //     return false;
    //   });
    //   console.log(index);
    //   if (index === -1) {
    //     const seating: Seating = {
    //       seatingId: '',
    //       name: '',
    //       invitedBy: [],
    //       category: [],
    //       maxGuest: 0,
    //       count: 0,
    //       reserved: false,
    //       order: 0
    //     };
    //     seatingList.push(seating);
    //   }
    // }

    this.seatingList = seatingList;
    this.observableSeatingList.next(this.seatingList);
  }

  /**
   * Unwatch seating list
   */
  async unwatchSeatingList() {
    if (this.seatingtListSubscription) {
      this.seatingtListSubscription.unsubscribe();
      this.seatingtListSubscription = null;
    }
  }

  /**
   * Check duplciate seating by name
   * @param seatingName Seating name
   * @returns true if duplicate
   */
  checkDuplicateSeatingByName(seatingName: string): boolean {
    const index = this.seatingList?.findIndex((x: Seating) => x.name?.toString().toLowerCase() === seatingName?.toString().toLowerCase() && !x.deleted);
    if (index === -1) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Check duplciate seating by ID
   * @param seatId Seating id
   * @returns true if duplicate
   */
  checkDuplicateSeatingById(seatId: string): boolean {
    const index = this.seatingList?.findIndex((x: Seating) => x.seatingId === seatId);
    if (index === -1) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Get max seating order
   * @returns seating order number
   */
  getMaxSeatingOrder(): number {
    return this.seatingList?.length ? Math.max.apply(Math, this.seatingList.map((seating: Seating) => {
      if (!seating.deleted) { return seating.order; }
      else { return 0; }
    })) : 0;
  }

  getMaxSeatingName(alphabet?: boolean): string {
    if (this.seatingList?.length) {
      if (alphabet) {
        let max = 'A';
        this.seatingList?.forEach((seating: Seating) => {
          if (seating?.name && !this.functionService.isNumber(seating.name.toString()) && this.functionService.compare(seating.name, max)) {
            max = seating.name;
          }
        });
        return max;
      } else {
        let max = 1;
        this.seatingList?.forEach((seating: Seating) => {
          if (seating?.name && this.functionService.isNumber(seating.name.toString())) {
            const seatingName = Number(seating.name);
            if (seatingName > max) {
              max = seatingName;
            }
          }
        });
        return max.toString();
      }
    } else {
      if (alphabet) {
        return 'A';
      } else {
        return '1';
      }
    }
  }

  /**
   * Get seating by seating name
   * @param name Seating name
   * @returns Seating info
   */
  getSeatingByName(name: string): Seating {
    let seating: Seating;
    const index = this.seatingList?.findIndex((x: Seating) => x.name === name);
    if (index !== -1) {
      seating = this.seatingList[index];
    }
    return seating;
  }

  /**
   * Get seating by seating id
   * @param seatingId Seating ID
   * @reutrns Seating info
   */
  getSeatingById(seatingId: string): Seating {
    let seating: Seating;
    const index = this.seatingList?.findIndex((x: Seating) => x.seatingId === seatingId);
    if (index !== -1) {
      seating = this.seatingList[index];
    }
    return seating;
  }

  /**
   * Get seating list by seating id
   * @param seatingIdList seating id list
   * @returns seating list
   */
  getSeatingListById(seatingIdList: string[]): Seating[] {
    return seatingIdList?.map((seatingId: string) => {
      return this.getSeatingById(seatingId);
    });
  }

  getSeatingListNameById(seatingList: string[]): string[] {
    return this.getSeatingListById(seatingList)?.map((seating: Seating) => {
      return seating?.name;
    });
  }

  /**
   * Get seating count by seating list / seating id list
   * @param seatingList Seating list
   * @param seatingIdList Seating ID list
   * @returns number of seating count
   */
  getSeatingCount(seatingList?: Seating[], seatingIdList?: string[]): number {
    let count = 0;
    if (seatingList?.length) {
      count = seatingList?.reduce((a, b) => {
        return a + b.count;
      }, 0);
    } else if (seatingIdList?.length) {
      count = seatingIdList?.reduce((a, b) => {
        const seating = this.getSeatingById(b);
        return a + seating.count;
      }, 0);
    }
    return count;
  }

  /**
   * Setup guest per seating
   * @param guestPerSeating guest per seating
   */
  // setupGuestPerSeating(guestPerSeating?: number) {
  //   if (guestPerSeating) {
  //     this.guestPerSeating = guestPerSeating;
  //   } else {
  //     this.guestPerSeating = this.getDefaultGuestPerSeating();
  //   }
  //   this.observableGuestPerSeating.next(this.guestPerSeating);
  // }

  // setupSeatingType(seatingType?: SettingField) {
  //   if (seatingType) {
  //     this.seatingType = seatingType;
  //   } else {
  //     this.seatingType = this.getDefaultSeatingType();
  //   }
  //   this.observableSeatingType.next(this.seatingType);
  // }

  // setupSeatingTypeOther(seatingTypeOther?: string) {
  //   if (!seatingTypeOther) {
  //     seatingTypeOther = '';
  //   }
  //   this.seatingTypeOther = seatingTypeOther;
  //   this.observableSeatingTypeOther.next(this.seatingTypeOther);
  // }

  /**
   * Get default guest per seating
   * @returns Guest per seating
   */
  // getDefaultGuestPerSeating(): number {
  //   const country = this.localityService.getAccountCountry()?.code;
  //   if (country === 'HK') {
  //     return 12;
  //   } else {
  //     return 10;
  //   }
  // }

  // getDefaultSeatingType(): SettingField {
  //   return {
  //     value: 'table',
  //     custom: false,
  //   }
  // }

  /**
   * Get guest per seating
   * @returns number of guest per seating
   */
  // getGuestPerSeating(): number {
  //   return this.guestPerSeating ? this.guestPerSeating : this.getDefaultGuestPerSeating();
  // }

  // getSeatingType(): SettingField {
  //   return this.seatingType?.value ? this.seatingType : this.getDefaultSeatingType();
  // }

  // getSeatingTypeName(seatingType?: SettingField): string {
  //   if (!seatingType?.value) {
  //     seatingType = this.getSeatingType();
  //   }
  //   if (seatingType?.value) {
  //     if (seatingType?.custom) {
  //       return seatingType.value;
  //     }
  //     if (seatingType.value === 'others') {
  //       if (this.seatingTypeOther) {
  //         return this.seatingTypeOther;
  //       }
  //     } else {
  //       return this.translate.instant('LIST.seating_type.' + seatingType.value);
  //     }
  //   }
  //   return this.translate.instant('MODULE.action.guest.seating');
  // }

  // getSeatingTypeList(): SettingField[] {
  //   const seatingType = this.getSeatingType();
  //   const seatingTypeList = SeatingTypeList;
  //   const index = seatingTypeList.findIndex((x: SettingField) => {
  //     return x.value === seatingType.value && x.custom === seatingType.custom;
  //   });
  //   if (index === -1) {
  //     seatingTypeList.push(seatingType);
  //   }
  //   return seatingTypeList;
  // }

  // calculateGridLimit(width: number, height: number) {
  //   const seatingCard = 110;
  //   const cardPerRow = Math.floor(width / seatingCard);
  //   const rowLimit = Math.floor(height / seatingCard);
  //   const limit = Math.floor(cardPerRow * rowLimit);
  //   return limit ? limit : this.gridLimit;
  // }

  // calculateListLimit(height: number) {
  //   const limit = Math.floor(height / 140);
  //   return limit ? limit : this.listLimit;
  // }

  // /**
  //  * Get grid limit for infinite load
  //  * @returns number of grid load limit
  //  */
  // get gridLimit(): number {
  //   return 15;
  // }

  // /**
  //  * Get list limit for infinite load
  //  * @returns number of list load limit
  //  */
  // get listLimit(): number {
  //   return 5;
  // }

  calculateGridNameHeight(gridSize: number) {
    if (!gridSize) {
      gridSize = 100;
    }
    gridSize = gridSize - 10 - 16 - 26 - 16;

    return gridSize > 57 ? gridSize - 5 : gridSize;
    // return (gridSize - 10) / 2;
  }

  calculateGridItemSize(containerWidth: number): number {
    // Define constants
    const minItemSize = 100;
    const maxItemSize = 160;
    const padding = 5;
    const outerPadding = 5;
    const minColumns = 3;

    // Calculate the effective width of the container after removing outer padding
    const effectiveWidth = containerWidth - (2 * outerPadding);
    let bestSize = minItemSize;

    // Iterate over possible numbers of columns, starting from minColumns
    for (let columns = minColumns; columns <= Math.floor(effectiveWidth / minItemSize); columns++) {
      // Calculate the potential item size for the current number of columns
      let potentialSize = (effectiveWidth / columns) - (2 * padding);

      // Check if the potential size is within the allowed range
      if (potentialSize >= minItemSize && potentialSize <= maxItemSize) {
        bestSize = Math.max(bestSize, Math.floor(potentialSize));
      } else if (potentialSize < minItemSize) {
        // If the potential size falls below the minimum item size, stop the loop
        break;
      }
    }

    return bestSize;
  }
}
