import { ModalController } from '@ionic/angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Group } from 'src/app/interfaces/group';
import { Guest } from 'src/app/interfaces/guest';

@Component({
  selector: 'app-visitor-group',
  templateUrl: './visitor-group.component.html',
  styleUrls: ['./visitor-group.component.scss'],
})
export class VisitorGroupComponent implements OnInit, OnDestroy {

  /**
   * Group
   */
  group: Group;
  /**
   * Guest list
   */
  guestList: Guest[];
  /**
   * Show guest seating
   */
  showGuestSeating: boolean;

  seatingTypeName: string;

  /**
   * Constructor
   * @param modalController modal controller
   */
  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Dismiss visitor page modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

}
