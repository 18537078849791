import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { ImageCroppedEvent } from 'ngx-image-cropper';

import { PopupService } from 'src/app/services/general/popup.service';
import { CropType, PhotoType } from 'src/app/types/general';

@Component({
  selector: 'app-crop',
  templateUrl: './crop.component.html',
  styleUrls: ['./crop.component.scss'],
})
export class CropComponent implements OnInit, OnDestroy {

  /**
   * Crop type
   */
  photoType: PhotoType;
  /**
   * base64 image
   */
  base64: string;

  imageBase64: string;
  /**
   * rotation
   */
  rotation: number;
  /**
   * Is hybrid flag
   */
  hybrid: boolean;
  /**
   * Format
   */
  format: string;
  /**
   * Cropped Image
   */
  private croppedImage: string;

  /**
   * Constructor
   * @param platform Platform
   * @param modalController Modal controller
   * @param popupService Popup service
   */
  constructor(
    private platform: Platform,
    private modalController: ModalController
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Before view enter
   */
  async ionViewWillEnter() {
    this.rotation = 0;
    // await this.popupService.presentLoading();
    this.hybrid = this.platform.is('hybrid');
    this.imageBase64 = this.base64;
    // setTimeout(() => {
    //   this.popupService.dismissLoading();
    // }, 1000);
  }


  /**
   * Image cropped
   * @param event Event
   */
  imageCropped(event: ImageCroppedEvent) {
    // this.popupService.dismissLoading();
    this.croppedImage = event.base64;
  }

  /**
   * Image loaded
   */
  imageLoaded() {
    // show cropper
    // this.popupService.dismissLoading();
  }

  /**
   * Cropper ready
   */
  cropperReady() {
    // cropper ready
    // this.popupService.dismissLoading();
  }

  /**
   * Load image failed
   */
  loadImageFailed() {
    // show message
    // this.popupService.dismissLoading();
  }

  /**
   * Rotate image
   * @param clockwise Clockwise flag
   */
  rotate(clockwise: boolean) {
    if (clockwise) {
      if (this.rotation < 4) {
        this.rotation += 1;
      } else {
        this.rotation = 0;
      }
    } else {
      if (this.rotation > 0) {
        this.rotation -= 1;
      } else {
        this.rotation = 4;
      }
    }
  }

  /**
   * Dismiss Crop Modal
   * @param confirm Confirm flag
   */
  async dismissModal(confirm?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) {
        if (confirm) {
          const img = this.croppedImage ? this.croppedImage : this.base64;
          modal.dismiss({ img });
        } else {
          modal.dismiss();
        }
      }
    }
  }

}
