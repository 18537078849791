import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Clipboard } from '@capacitor/clipboard';

import { QrcodeTemplateService } from 'src/app/services/qrcode/qrcode-template.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { QrcodeTemplate, SettingField } from 'src/app/interfaces/database';
import { TemplateVariableEvent, TemplateVariableWedding } from 'src/app/commons/template';
import { TemplateVariableType } from 'src/app/types/template';
import { KeyboardService } from 'src/app/services/general/keyboard.service';

import { AccountEventModeService } from 'src/app/services/account/account-event-mode.service';


/**
 * Qr code template component
 */
@Component({
  selector: 'app-qrcode-template',
  templateUrl: './qrcode-template.component.html',
  styleUrls: ['./qrcode-template.component.scss'],
})
export class QrcodeTemplateComponent implements OnInit, OnDestroy {
  /**
   * Edit mode for template
   */
  editMode: boolean;
  /**
   * Qrcode template list
   */
  qrcodeTemplateList: QrcodeTemplate[];
  /**
   * Selected Qr template
   */
  selected: SettingField;

  currentIndex: number;
  /**
   * Qr template subscription
   */
  private qrcodeTemplateSubscription: Subscription;

  /**
   * Constructor
   * @param translate Translate service
   * @param modalController Modal controller
   * @param qrcodeTemplateService Qr Code service
   * @param userService User service
   * @param popupService Popup service
   * @param functionService General function service
   */
  constructor(
    private translate: TranslateService,
    private modalController: ModalController,
    private accountEventModeService: AccountEventModeService,
    private qrcodeTemplateService: QrcodeTemplateService,
    private keyboardService: KeyboardService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {
    this.unwatchQrcodeTemplateList();
  }

  /**
   * Before view enter
   */
  ionViewWillEnter() {
    this.initialize();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatchQrcodeTemplateList();
    // this.qrcodeTemplateService.unwatchQrcodeTemplateList();
  }

  /**
   * Initialize QR code template
   */
  initialize() {
    this.editMode = false;
    this.qrcodeTemplateList = this.functionService.cloneDeep(this.qrcodeTemplateService.qrcodeTemplateList);
    this.selected = { ...this.qrcodeTemplateService.selectedName };
    this.setSelected(this.qrcodeTemplateService.getTemplateByName(this.selected));
    this.currentIndex = this.getIndex();
    this.watchQrcodeTemplateList();
    // this.qrcodeTemplateService.watchQrcodeTemplateList();
  }

  /**
   * Watch Qr code template list
   */
  async watchQrcodeTemplateList() {
    if (!this.qrcodeTemplateSubscription) {
      this.qrcodeTemplateSubscription = this.qrcodeTemplateService.observableQrcodeTemplateList.subscribe((qrcodeTemplateList: any) => {
        this.qrcodeTemplateList = this.functionService.cloneDeep(qrcodeTemplateList);
        this.selected = { ...this.qrcodeTemplateService.selectedName };
        this.setSelected(this.qrcodeTemplateService.getTemplateByName(this.selected));
        this.currentIndex = this.getIndex();
      });
    }
  }

  /**
   * Unwatch Qr code template list
   */
  async unwatchQrcodeTemplateList() {
    if (this.qrcodeTemplateSubscription) {
      this.qrcodeTemplateSubscription.unsubscribe();
      this.qrcodeTemplateSubscription = null;
    }
  }

  /**
   * Set selected Qr code template
   * @param qrcodeTemplate Qr code Template
   * @param index Index
   */
  setSelected(qrcodeTemplate?: any) {
    if (qrcodeTemplate?.name) {
      this.selected = qrcodeTemplate.name;
      this.currentIndex = this.getIndex();
    } else if (this.qrcodeTemplateList?.length) {
      this.selected = this.qrcodeTemplateList[0].name;
      this.currentIndex = this.getIndex();
    }

    this.editMode = false;
  }

  getTemplateVariableList() {
    return this.accountEventModeService.eventMode ? TemplateVariableEvent : TemplateVariableWedding;
  }

  /**
   * Get QR code template in html format
   * @param template Qr code Template msg
   */
  getHtml(template: string) {
    const variableList = this.getTemplateVariableList();
    variableList.forEach((variable: string) => {
      const field = '[' + variable.toUpperCase() + ']';
      const newField = '<span class="variable">' + this.replaceEventType(this.translate.instant('TEMPLATE.variable.' + variable)) + '</span>';
      template = this.functionService.replaceAll(template, field, newField);
    });

    return template;
  }

  /**
   * Get index of selected template
   */
  getIndex() {
    return this.qrcodeTemplateService.getTemplateIndex(this.selected);
  }

  /**
   * Copy Qr template variable
   * @param field Qr template variable field
   */
  async copy(field: TemplateVariableType) {
    const variableList = this.getTemplateVariableList();
    if (field && variableList?.indexOf(field) !== -1) {
      try {
        await Clipboard.write({ string: '[' + field.toUpperCase() + ']' });
        this.popupService.presentToast(this.translate.instant('COPY.success'), 'success');
      } catch (err: any) {
        this.popupService.presentToast(this.translate.instant('COPY.fail'), 'danger');
      }
    }
  }

  /**
   * New Qr code template
   */
  async newTemplate() {
    this.presentInputPrompt();
  }

  /**
   * Edit Qr template name
   * @param templateName Qr Template name
   */
  async editTemplate(templateName: SettingField, slidingItem?: any) {
    if (templateName.value) {
      this.presentInputPrompt(templateName.custom ?
        templateName.value : this.translate.instant('LIST.language.' + templateName.value), templateName.custom);
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Dismiss Qr template modal
   * @param selected Selected Qr template
   */
  async dismissModal(selected?: QrcodeTemplate) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ selected }); }
    }
  }

  /**
   * Present confirm delete modal
   * @param templateName Qrcode Template name
   */
  async presentConfirmDeleteModal(templateName: SettingField, slidingItem?: any) {
    if (this.qrcodeTemplateList?.length > 1) {
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_field', {
          field: this.translate.instant('QRCODE.lbl.msg_template') } )
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.popupService.presentLoading();
          await this.qrcodeTemplateService.deleteQrcodeTemplate(templateName);
          if (this.functionService.isEqual(this.selected, templateName)) {
            this.selected = { ...this.qrcodeTemplateService.selectedName };
            this.currentIndex = this.getIndex();
          }
          this.popupService.dismissLoading();
          this.popupService.saveSuccessToast();
        }
      });
    } else {
      this.popupService.presentAlert(this.translate.instant('QRCODE.msg.template_required'));
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Present Qr template name input prompt
   * @param value Qr template name for update template, blank for new template
   * @param custom Custom Qr template
   */
  async presentInputPrompt(value?: any, custom?: boolean) {
    const modal = await this.popupService.presentInput(this.translate.instant('QRCODE.lbl.qrcode_template'), '', '', '', value);
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.input) {
        if (value) {
          this.setupCurrentTemplate(result.data.input, value, custom);
        } else {
          this.setupNewTemplate(result.data.input);
        }
      }
    });
  }

  /**
   * Setup new template data
   * @param input Input field
   */
  async setupNewTemplate(input: string) {
    const templateIndex = this.qrcodeTemplateList?.findIndex((x: QrcodeTemplate) => x.name.value?.toString().toLowerCase() === input?.toString().toLowerCase() );
    if (templateIndex === -1) {
      // const updateBy: UpdateBy = this.updateByService.getUpdateBy();
      const qrcodeTemplate: QrcodeTemplate = {
        name: {
          value: input,
          custom: true,
          // createBy: updateBy,
          // updateBy
        },
        msg: ''
      };

      this.qrcodeTemplateList.push(qrcodeTemplate);
      await this.qrcodeTemplateService.saveQrcodeTemplateList(this.qrcodeTemplateList);
      this.setSelected(qrcodeTemplate);
    } else {
      this.popupService.presentToast(this.translate.instant('VALIDATION.duplicate_field', {
        field: this.translate.instant('QRCODE.lbl.qrcode_template') }), 'danger');
    }
  }

  /**
   * Setup current template data update
   */
  async setupCurrentTemplate(input: string, value?: any, custom?: boolean) {
    const templateIndex = this.qrcodeTemplateList?.findIndex((x: any) => x?.name?.value === value && x?.name?.custom === custom);
    if (templateIndex !== -1) {
      // const createBy = this.qrcodeTemplateList?.[templateIndex]?.name?.createBy
      // ? this.qrcodeTemplateList[templateIndex].name.createBy : this.updateByService.getUpdateBy();
      // const updateBy: UpdateBy = this.updateByService.getUpdateBy();
      const qrcodeTemplateName: SettingField = {
        value: input,
        custom: true,
        // createBy,
        // updateBy
      };
      this.qrcodeTemplateList[templateIndex].name = qrcodeTemplateName;
      await this.qrcodeTemplateService.saveQrcodeTemplateList(this.qrcodeTemplateList);
      this.setSelected(this.qrcodeTemplateList[templateIndex]);
    } else {
      this.popupService.presentToast(this.translate.instant('VALIDATION.duplicate_field', {
        field: this.translate.instant('QRCODE.lbl.qrcode_template') }), 'danger');
    }
  }

  /**
   * Save & select QR template
   */
  async selectTemplate() {
    await this.qrcodeTemplateService.saveQrcodeTemplateList(this.qrcodeTemplateList);
    await this.qrcodeTemplateService.saveSelectedTemplate(this.selected);

    this.dismissModal(this.qrcodeTemplateService.getTemplateByName(this.selected));
  }

  replaceEventType(text: string) {
    return this.accountEventModeService.replaceEventType(text);
  }
}
