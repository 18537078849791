/**
 * Receipt item
 * 
 * QTY - Item qty
 * ITEM_NAME - Item name
 * UNIT_PRICE - unit price
 * DESCRIPTION - Item description
 */
export const AccountReceiptItem = {
    table: {
        widths: [ 50, '*', 200],
        body: [
            [
                { rowSpan: 2, text: '{{QTY}} x ', fontSize: 16 },
                { text: '{{ITEM_NAME}}', fontSize: 16 },
                { rowSpan: 2, text: '{{UNIT_PRICE}}', style: ['right'], fontSize: 16, margin: [0, 0, 5, 0] }
            ],
            ['', '{{DESCRIPTION}}', ''],
            [
                {
                    colSpan: 3,
                    canvas: [
                        {
                            type: 'line',
                            x1: 0,
                            y1: 1,
                            x2: 515.28,
                            y2: 1,
                            lineWidth: 0.5,
                            lineColor: 'grey',
                            dash: {length: 2, space: 1}
                        }
                    ],
                    margin: [0, 0, 0, 10]
                }
            ]
        ]
    },
    layout: 'noBorders',
};

export const BlastReceiptItem = {
    table: {
        widths: [ 50,'*', 200],
        body: [
            [
                { text: '{{QTY}} x ', fontSize: 14 },
                { text: '{{ITEM_NAME}}', fontSize: 16 },
                { text: '{{UNIT_PRICE}}', style: ['right'], fontSize: 16, margin: [0, 0, 5, 0] }
            ],
            [
                {
                    colSpan: 3,
                    canvas: [
                        {
                            type: 'line',
                            x1: 0,
                            y1: 1,
                            x2: 515.28,
                            y2: 1,
                            lineWidth: 0.5,
                            lineColor: 'grey',
                            dash: {length: 2, space: 1}
                        }
                    ],
                    margin: [0, 0, 0, 10]
                }
            ]
        ]
    },
    layout: 'noBorders',
};

// export const BlastReceiptItemGroup = { text: '{{ITEM_GROUP}}', fontSize: 16, margin: [ 0, 20, 0, 0], style: ['left'] };


export const LineItem = {
    canvas: [
        {
            type: 'line',
            x1: 0,
            y1: 1,
            x2: 515.28,
            y2: 1,
            lineWidth: 0.5,
            lineColor: 'grey',
            dash: {length: 2, space: 1}
        }
    ],
    margin: [0, 0, 0, 10]
};