<ion-content>
  <div class="full-height vertical-center" *ngIf="ready">
    <ion-card class="center ion-no-margin">
      <ion-datetime
        #popoverDatetime
        name="time"
        [hourCycle]="hourCycle ? hourCycle : 'h12'"
        [presentation]="presentation ? presentation : 'date-time'"
        [min]="min"
        [max]="max"
        [value]="time ? time : ''"
        [size]="'cover'"
        [locale]="locale"
      >
      <!-- <ion-buttons slot="buttons">
        <ion-button slots="buttons" class="btn full-width ion-no-margin" expand="block" color="grey" fill="solid" type="button" (click)="dismissModal()">
          <ion-icon slot="icon-only" src="./assets/wedding/icon/general/close.svg"></ion-icon>
        </ion-button>
        <ion-button slots="buttons" class="btn full-width ion-no-margin" expand="block" [color]="'primary'" fill="solid" type="button" (click)="dismissModal(true)">
          <ion-icon slot="icon-only" src="./assets/wedding/icon/general/checkmark.svg"></ion-icon>
        </ion-button>
      </ion-buttons> -->
      </ion-datetime>
      <ion-grid fixed>
        <ion-row class="ion-align-items-center ion-justify-content-center">
          <ion-col size-xs="0" size-sm="1"></ion-col>
          <ion-col class="ion-align-self-center ion-text-center">
            <ion-button slots="buttons" class="ion-no-margin" expand="block" color="grey" fill="solid" type="button" (click)="dismissModal()">
              <ion-icon slot="icon-only" src="./assets/wedding/icon/general/close.svg"></ion-icon>
            </ion-button>
          </ion-col>
          <ion-col size-xs="1" size-sm="2"></ion-col>
          <ion-col class="ion-align-self-center center">
            <ion-button slots="buttons" class="ion-no-margin" expand="block" [color]="'primary'" fill="solid" type="button" (click)="dismissModal(true)">
              <ion-icon slot="icon-only" src="./assets/wedding/icon/general/checkmark.svg"></ion-icon>
            </ion-button>
          </ion-col>
          <ion-col size-xs="0" size-sm="1"></ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center ion-justify-content-center" *ngIf="clearBtn">
          <ion-col class="ion-align-self-center ion-text-center">
            <ion-text class="font-12 font-underline" (click)="dismissModal(false, true)">
              {{ 'BTN.clear' | translate }}
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
  </div>
</ion-content>