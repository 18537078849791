import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Language } from 'src/app/interfaces/database';
import { LocationDetail } from 'src/app/interfaces/general';
import { VisitorInfoService } from './visitor-info.service';
import { VisitorLanguageService } from './visitor-language.service';
import { VisitorManageService } from './visitor-manage.service';
import { AccountInfoService } from '../account/account-info.service';

@Injectable({
  providedIn: 'root'
})
export class VisitorLocationService implements OnInit, OnDestroy {

  constructor(
    private accountInfoService: AccountInfoService,
    private visitorInfoService: VisitorInfoService,
    private visitorLanguageService: VisitorLanguageService,
    private visitorManageService: VisitorManageService,
  ) { }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  updateLocation(location: LocationDetail) {
    const pageLangList = this.visitorLanguageService.getPangeLangList();
    const pageInfo = this.visitorInfoService.getPageInfo();
    if (pageInfo && pageLangList?.length) {
      pageLangList?.forEach((lang: Language) => {
        if (pageInfo?.[lang?.code]) {
          pageInfo[lang.code].venue = location?.name ? location?.name : '';
          pageInfo[lang.code].address = location?.address ? location?.address : '';
        }
      });
      this.visitorManageService.savePageSetting({ info: pageInfo });
    }
  }

  getDefaultLangCode() {
    const defaultLanguage = this.visitorLanguageService.getDefaultPageLang();
    if (defaultLanguage?.code) {
      return defaultLanguage.code;
    }
    return 'en';
  }

  getLocation(langCode?: string) {
    if (!langCode) {
      langCode = this.getDefaultLangCode();
    }
    const pageLangList = this.visitorLanguageService.getPangeLangList();
    const pageInfo = this.visitorInfoService.getPageInfo();
    
    const location: LocationDetail = this.accountInfoService.accountInfo?.location;
    const result = {
      name: location?.name,
      hall: '',
      address: location?.address,
    };
    const pageLangIndex = pageLangList.findIndex((language: Language) => {
      return language.code === langCode;
    });
    if (pageLangIndex === -1) {
      langCode = this.getDefaultLangCode();
    }
    if (pageInfo && pageLangList?.length) {
      pageLangList?.forEach((lang: Language) => {
        if (pageInfo?.[lang?.code] && lang?.code === langCode) {
          result.name = pageInfo[langCode].venue;
          result.hall = pageInfo[langCode].hall;
          result.address = pageInfo[langCode].address;
        }
      });
    }
    return result;
  }
}
