import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { SettingField } from 'src/app/interfaces/database';
import { Guest } from 'src/app/interfaces/guest';
import { FunctionService } from '../general/function.service';
import { CategoryService } from '../setting/category.service';
import { DietaryReqService } from '../setting/dietary-req.service';
import { InvitedByService } from '../setting/invited-by.service';
import { SessionService } from '../setting/session.service';
import { SpecialReqService } from '../setting/special-req.service';
import { AccountPackageService } from '../account/account-package.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ErrorService } from '../general/error.service';

@Injectable({
  providedIn: 'root'
})
export class GuestFixService implements OnInit, OnDestroy {

  constructor(
    private fns: AngularFireFunctions,
    private accountPackageService: AccountPackageService,
    private invitedByService: InvitedByService,
    private categoryService: CategoryService,
    private dietaryReqService: DietaryReqService,
    private specialReqService: SpecialReqService,
    private sessionService: SessionService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  checkSettingField(guestList: Guest[]) {
    const invitedByList = this.invitedByService.invitedByList ? [ ...this.invitedByService.invitedByList ] : [];
    const categoryList = this.categoryService.categoryList ? [ ...this.categoryService.categoryList ] : [];
    const dietaryReqList = this.dietaryReqService.dietaryReqList ? [ ...this.dietaryReqService.dietaryReqList ] : [];
    const specialReqList = this.specialReqService.specialReqList ? [ ...this.specialReqService.specialReqList ] : [];
    const sessionList = this.sessionService.list ? [ ...this.sessionService.list ] : [];

    const newInitedByList: SettingField[] = [];
    const newCategoryList: SettingField[] = [];
    const newDietaryReqList: SettingField[] = [];
    const newSpecialReqList: SettingField[] = [];
    const newSessionList: SettingField[] = [];

    if (guestList?.length && invitedByList?.length && categoryList?.length && dietaryReqList?.length && specialReqList?.length && sessionList?.length) {
      guestList?.forEach((guest: Guest) => {
        if (guest?.invitedBy?.length) {
          const invitedBy: SettingField = guest.invitedBy[0];
          const index = invitedByList.findIndex((x: SettingField) => {
            return x.custom === invitedBy.custom && x.value === invitedBy.value;
          });
          if (index === -1) {
            const newIndex = newInitedByList.findIndex((x: SettingField) => {
              return x.custom === invitedBy.custom && x.value === invitedBy.value;
            });
            if (newIndex === -1) {
              if (!invitedBy?.id) {
                invitedBy.id = '';
                if (invitedBy?.custom) {
                  invitedBy.id = this.functionService.randomId();
                }
                invitedBy.id += invitedBy.value
              }
              newInitedByList.push(invitedBy);
            }
          }
        }
  
        if (guest?.category?.length) {
          const category: SettingField = guest.category[0];
          const index = categoryList.findIndex((x: SettingField) => {
            return x.custom === category.custom && x.value === category.value;
          });
          if (index === -1) {
            const newIndex = newCategoryList.findIndex((x: SettingField) => {
              return x.custom === category.custom && x.value === category.value;
            });
            if (newIndex === -1) {
              if (!category?.id) {
                category.id = '';
                if (category?.custom) {
                  category.id = this.functionService.randomId();
                }
                category.id += category.value
              }
              newCategoryList.push(category);
            }
          }
        }
  
        if (guest?.dietaryReq?.length) {
          const dietaryReq: SettingField = guest.dietaryReq[0];
          const index = dietaryReqList.findIndex((x: SettingField) => {
            return x.custom === dietaryReq.custom && x.value === dietaryReq.value;
          });
          if (index === -1) {
            const newIndex = newDietaryReqList.findIndex((x: SettingField) => {
              return x.custom === dietaryReq.custom && x.value === dietaryReq.value;
            });
            if (newIndex === -1) {
              if (!dietaryReq?.id) {
                dietaryReq.id = '';
                if (dietaryReq?.custom) {
                  dietaryReq.id = this.functionService.randomId();
                }
                dietaryReq.id += dietaryReq.value
              }
              newDietaryReqList.push(dietaryReq);
            }
          }
        }
  
        if (guest?.specialReq?.length) {
          const specialReq: SettingField = guest.specialReq[0];
          const index = specialReqList.findIndex((x: SettingField) => {
            return x.custom === specialReq.custom && x.value === specialReq.value;
          });
          if (index === -1) {
            const newIndex = newSpecialReqList.findIndex((x: SettingField) => {
              return x.custom === specialReq.custom && x.value === specialReq.value;
            });
            if (newIndex === -1) {
              if (!specialReq?.id) {
                specialReq.id = '';
                if (specialReq?.custom) {
                  specialReq.id = this.functionService.randomId();
                }
                specialReq.id += specialReq.value
              }
              newSpecialReqList.push(specialReq);
            }
          }
        }
  
        if (guest?.session?.length) {
          const session: SettingField = guest.session[0];
          const index = sessionList.findIndex((x: SettingField) => {
            return x.custom === session.custom && x.value === session.value;
          });
          if (index === -1) {
            const newIndex = newSessionList.findIndex((x: SettingField) => {
              return x.custom === session.custom && x.value === session.value;
            });
            if (newIndex === -1) {
              if (!session?.id) {
                session.id = '';
                if (session?.custom) {
                  session.id = this.functionService.randomId();
                }
                session.id += session.value
              }
              newSessionList.push(session);
            }
          }
        }
      });

      if (newInitedByList?.length) {
        const list = [ ...invitedByList.concat(newInitedByList) ];
        this.invitedByService.saveInvitedBy(list);
      }

      if (newCategoryList?.length) {
        const list = [ ...categoryList.concat(newCategoryList) ];
        this.categoryService.saveCategory(list);
      }

      if (newDietaryReqList?.length) {
        const list = [ ...dietaryReqList.concat(newDietaryReqList) ];
        this.dietaryReqService.saveDietaryReq(list);
      }

      if (newSpecialReqList?.length) {
        const list = [ ...specialReqList.concat(newSpecialReqList) ];
        this.specialReqService.saveSpecialReq(list);
      }

      if (newSessionList?.length) {
        const list = [ ...sessionList.concat(newSessionList) ];
        this.sessionService.save(list);
      }
    } else {
      setTimeout(() => {
        this.checkSettingField(guestList);
      }, 1000);
    }
  }

  fixMobile(guestList: Guest[]) {
    if (guestList?.length) {
      const mobileGuestList: Guest[] = [];
      guestList?.forEach((guest: Guest) => {
        if (guest?.mobile?.no && guest?.mobile?.code) {
          if (!guest?.mobile?.country) {
            mobileGuestList.push(guest);
          } else if (guest?.mobile?.country === 'CA' && guest.mobile.code !== 1) {
            mobileGuestList.push(guest);
          }
        }
      });
      if (mobileGuestList?.length) {
        this.lookupMobile(mobileGuestList);
      }
    }
  }

  async lookupMobile(guestList: Guest[]) {
    const accountId = this.accountPackageService.accountId;
    if (accountId && guestList?.length) {
      await this.fns.httpsCallable('mobileLookupCall')({ accountId, guestList })
      .toPromise().then(async (result: any) => {
      }).catch((err: any) => {
        this.errorService.logError(err);
      });
    }
  }


}
