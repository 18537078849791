/**
 * Preloaded standard category list
 */
export const StdCategoryList: string[] = [
    'classmates',
    'colleagues',
    'families',
    'father_friends',
    'friends',
    'mother_friends',
    'others',
    'relatives',
    'vips',
];


export const DefaultCategoryList: string[] = [
    'classmates',
    'colleagues',
    'families',
    'father_friends',
    'friends',
    'mother_friends',
    'others',
    'relatives',
    'vips',
];

export const StdEventCategoryList: string[] = [
    'business_partners',
    'clients',
    'medias',
    'speakers',
    'staffs',
    'suppliers',
    'vips',
    'others'
];

export const DefaultEventCategoryList: string[] = [
    'business_partners',
    'clients',
    'medias',
    'speakers',
    'staffs',
    'suppliers',
    'vips',
    'others'
];