import { Router, Event, RouterEvent } from '@angular/router';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DynamicUrl, GuestPageHostName, GuestPageHostNameOld, WeddingWebsiteUrl } from 'src/app/commons/url';

@Injectable({
  providedIn: 'root'
})
export class UrlService implements OnInit, OnDestroy {

  /**
   * url
   */
  url: string;
  /**
   * Url observable
   */
  observableUrl: any;

  private urlSubscription: Subscription;
  /**
   * constructor
   * @param router router
   */
  constructor(
    private router: Router,
  ) {
    this.observableUrl = new BehaviorSubject<string>(this.url);
    this.watchUrl();
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy() {
    this.unwatchUrl();
  }

  /**
   * Watch url changes
   */
  private async watchUrl() {
    if (!this.urlSubscription) {
      this.urlSubscription = this.router.events.subscribe((event: Event | RouterEvent) => {
        if (event instanceof RouterEvent) {
          this.url = event.url;
          this.redirectVipHostname(this.url);
          this.observableUrl.next(this.url);
        }
      });
    }
  }

  async unwatchUrl() {
    if (this.urlSubscription) {
      this.urlSubscription.unsubscribe();
      this.urlSubscription = null;
    }
  }


  /**
   * Check current URL is existed in URL list
   * @param urlList Url List to be checked
   * @param currentUrl current URL
   * @returns true if URL existed in url list
   */
  checkUrl(urlList: string[], currentUrl?: string): boolean {
    let flag = false;
    if (!currentUrl) {
      currentUrl = this.url ? this.url : this.router?.url;
    }
    if (urlList?.length && currentUrl) {
      urlList?.forEach((url: string) => {
        if (currentUrl?.indexOf(url) !== -1) {
          flag = true;
        }
      });
    }
    return flag;
  }

  checkVipHostName(log?: boolean): boolean {
    if (window?.location?.hostname) {
      const hostname = window.location.hostname;
      if (hostname === GuestPageHostName || hostname.indexOf(GuestPageHostName) !== -1) {
        return true;
      } else if (hostname === GuestPageHostNameOld || hostname.indexOf(GuestPageHostNameOld) !== -1) {
        return true;
      } else if (hostname === WeddingWebsiteUrl || hostname.indexOf(WeddingWebsiteUrl) !== -1) {
        return true;
      }
    }
    return false;
  }

  redirectVipHostname(url?: string) {
    if (this.checkVipHostName() && url?.indexOf('/vip/') === -1 && url?.indexOf('/website') === -1) {
      window.location.href = DynamicUrl.long.home + '/#' + url;
    }
  }

  isUrlExisted(text: string): boolean {
    // Regular expression to match URLs, excluding "https://tbd.im/"
    const urlRegex = /https?:\/\/(?!tbd\.im\/)\S+|www\.\S+/gi;

    // Array to store matched URLs
    const urls: string[] = [];

    // Find matches in the input string
    const matches = text.match(urlRegex);

    if (matches) {
      // Remove duplicates by converting the array to a Set and back to an array
      const uniqueMatches = Array.from(new Set(matches));

      // Push the unique URLs to the result array
      urls.push(...uniqueMatches);
    }

    return urls?.length ? true : false;
  }
}
