import { PopupService } from 'src/app/services/general/popup.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { UserService } from 'src/app/services/user/user.service';
import { UpdateByService } from 'src/app/services/user/update-by.service';
import { OnlineService } from 'src/app/services/general/online.service';

import { Group } from 'src/app/interfaces/group';

/**
 * Group manage service
 */
@Injectable({
  providedIn: 'root'
})
export class GroupManageService implements OnInit, OnDestroy {

  /**
   * Account ID
   */
  private accountId: string;

  /**
   * Constructor
   * @param afs Angular firestore
   * @param fns Angular cloud functions
   * @param userService user service
   * @param onlineService online service
   */
  constructor(
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private userService: UserService,
    private updateByService: UpdateByService,
    private onlineService: OnlineService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Setup Account ID
   * @param Account ID
   */
  async setupAccountId(accountId?: string) {
    this.accountId = accountId;
  }

  /**
   * Save group list to firestore.
   * Bulk list, if online & > 100 records then save by firebase cloud function.
   * Batch commit, if online & > 1 records then save by batch commit.
   * Loop to save individually.
   * @param groupList group list
   */
  async saveGroupList(groupIdList: string[], data: any, groupList?: Group[]) {
    if (this.accountId && groupIdList?.length) {
      if (this.onlineService.online && groupIdList.length > 100) {
        await this.fns.httpsCallable('batchSaveGroupCall')({
          groupIdList,
          groupList,
          data,
          accountId: this.accountId,
          updateBy: this.updateByService.updateBy,
          uid: this.userService.uid
        }).toPromise().then((response) => {
        }).catch(async (err) => {
          this.errorService.logError(err);
          await this.saveIndividually(groupIdList, data, groupList);
        });
      } else if (this.onlineService.online && groupIdList?.length > 1) {
        const batch = this.afs.firestore.batch();
        groupIdList?.forEach((groupId: string) => {
          if (groupList) {
            const groupIndex = groupList?.findIndex((x: Group) => x?.groupId === groupId);
            if (groupIndex !== -1) {
              data = groupList[groupIndex];
            }
          }
          if (data?.memberList?.length) {
            data.memberList = [];
          }
          data.updateBy = this.updateByService.updateBy;
          const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/groups/${ groupId }`);
          batch.set(ref, data, { merge: true });
        });
        try {
          await batch.commit();
        } catch (err: any) {
          console.log(data);
          this.errorService.logError(err);
          await this.saveIndividually(groupIdList, data, groupList);
        }
      } else {
        await this.saveIndividually(groupIdList, data, groupList);
      }
    }
  }

  async saveIndividually(groupIdList: string[], data: any, groupList?: Group[]) {
    let status = '';
    if (data?.status) {
      status = data.status;
    }
    
    for (const groupId of groupIdList) {
      if (groupList) {
        const groupIndex = groupList?.findIndex((x: Group) => x?.groupId === groupId);
        if (groupIndex !== -1) {
          data = groupList[groupIndex];
        }
      }
      if (data?.memberList?.length) {
        data.memberList = [];
      }
      if (status) {
        data.status = status;
      }
      data.updateBy = this.updateByService.updateBy;
      await this.saveGroup(groupId, data);
    }
  }

  /**
   * Save guest group
   * @param group Guest group
   */
  async saveGroup(groupId: string, data: any) {
    if (this.accountId && groupId && data) {
      data.updateBy = this.updateByService.updateBy;
      const groupRef = this.afs.firestore.doc(`accounts/${ this.accountId }/groups/${ groupId }`);
      groupRef.set(data, { merge: true }).then((result: any) => {
      }).catch((err: any) => {
        console.log(data);
        this.errorService.logError(err);
      });
    }
  }
}
