import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Language } from 'src/app/interfaces/database';
import { LanguageService } from 'src/app/services/general/language.service';

@Component({
  selector: 'app-blast-template-language',
  templateUrl: './blast-template-language.component.html',
  styleUrls: ['./blast-template-language.component.scss'],
})
export class BlastTemplateLanguageComponent  implements OnInit, OnDestroy {

  languageList: Language[] = this.languageService.languageList;
  selected: string;

  constructor(
    private modalController: ModalController,
    private languageService: LanguageService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  async dismissModal(selected?: string) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { modal.dismiss({ selected }); }
    }
  }

  select(selected: string) {
    this.selected = selected;
    this.dismissModal(selected);
  }

}
