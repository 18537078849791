import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';

import { AccountUserService } from 'src/app/services/account/account-user.service';
import { AccountCoupleService } from 'src/app/services/account/account-couple.service';

import { AccountUser, AccountCouple } from 'src/app/interfaces/account';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

/**
 * Account user list component
 */
@Component({
  selector: 'app-account-user',
  templateUrl: './account-user.component.html',
  styleUrls: ['./account-user.component.scss'],
})
export class AccountUserComponent implements OnInit, OnDestroy {

  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  
  @ViewChild('content', { read: ElementRef }) contentElement: ElementRef;
  /**
   * Excluded uid
   */
  excludedUid: string[];
  /**
   * Show system
   */
  showSystem: boolean;
  /**
   * Selected user UID of account user list
   */
  selectedUid: string;
  /**
   * Couple type
   */
  accountCouple: AccountCouple[];
  /**
   * Account user list obvervable
   */
  // accountUser: Observable<AccountUser[]>;
  accountUserList: AccountUser[];

  coupleTypeList: {
    [coupleId: number]: string;
  };
  /**
   * Account user subscription
   */
  private accountUserSubscription: Subscription;
  /**
   * Couple type subscription
   */
  private accountCoupleSubscription: Subscription;

  /**
   * Constructor
   * @param modalController Modal controller
   * @param accountUserService Account user service
   * @param accountCoupleService Couple service
   */
  constructor(
    private modalController: ModalController,
    private accountUserService: AccountUserService,
    private accountCoupleService: AccountCoupleService
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.unwatch();
  }

  /**
   * Before view enter, get selected UID as params
   */
  async ionViewWillEnter() {
    if (!this.excludedUid?.length) {
      this.excludedUid = [];
    }
    this.watch();
  }

  /**
   * Before view leave, unwatch all subscription.
   */
  ionViewWillLeave() {
    this.unwatch();
  }

  watch() {
    this.watchAccountUser();
    this.watchCouple();
  }

  unwatch() {
    this.unwatchAccountUser();
    this.unwatchCouple();
  }

  /**
   * Watch account user
   */
  async watchAccountUser() {
    if (!this.accountUserSubscription) {
      this.accountUserSubscription = this.accountUserService.observableAccountUser.subscribe((accountUserList: AccountUser[]) => {
        this.accountUserList = accountUserList;
        this.setupViewport();
      });
    }
    
  }

  /**
   * Unwatch account user
   */
  async unwatchAccountUser() {
    if (this.accountUserSubscription) {
      this.accountUserSubscription.unsubscribe();
      this.accountUserSubscription = null;
    }
  }

  /**
   * Watch couple type
   */
  async watchCouple() {
    if (!this.accountCoupleSubscription) {
      this.accountCoupleSubscription = this.accountCoupleService.observableCoupleType.subscribe((accountCouple: AccountCouple[]) => {
        this.accountCouple = accountCouple;
        this.setupCoupleType();
      });
    }
    
  }

  /**
   * Unwatch couple type
   */
  async unwatchCouple() {
    if (this.accountCoupleSubscription) {
      this.accountCoupleSubscription.unsubscribe();
      this.accountCoupleSubscription = null;
    }
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport) {
      this.cdkVirtualScrollViewport?.checkViewportSize();
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  setupCoupleType() {
    if (!this.coupleTypeList) {
      this.coupleTypeList = {};
    }
    this.coupleTypeList[1] = this.getCoupleType(1);
    this.coupleTypeList[2] = this.getCoupleType(2);
  }

  /**
   * Get couple type by couple ID
   * @param coupleId Couple ID
   * @returns Couple type
   */
  getCoupleType(coupleId: number): string {
    return this.accountCoupleService.getCoupleType(coupleId);
  }

  /**
   * Select user by UID
   * @param uid User UID
   */
  selectUser(uid: string) {
    if (uid) {
      this.dismissModal(uid);
    }
  }

  /**
   * Dismiss account user modal
   * @param uid User UID
   */
  async dismissModal(uid?: string) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ uid }); }
    }
  }

  /**
   * Track item by account login
   * @param index Index
   * @param item item
   */
  trackByFn(index: number, item: AccountUser) {
    if (item?.uid) {
      return item?.uid;
    }
    return index;
  }

  getContentHeight(): number {
    return this.contentElement?.nativeElement?.offsetHeight ? this.contentElement.nativeElement.offsetHeight : window?.innerHeight ? window.innerHeight : 0;
  }

}
