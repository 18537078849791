import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { TranslateService } from '@ngx-translate/core';

import { Language } from 'src/app/interfaces/database';
import { LanguageList } from 'src/app/commons/language';

/**
 * Device Language service.
 * Default using user device language.
 * If language not supported will default to english.
 */
@Injectable({
  providedIn: 'root'
})
export class DeviceLanguageService implements OnInit, OnDestroy {

  language: string;
  /**
   * Device language
   */
  deviceLanguage: string;

  private userLanguage: string;
  /**
   * Language list
   */
  private languageList: Language[] = LanguageList;


  /**
   * Constructor
   * @param translate translate
   * @param languageService language service
   */
  constructor(
    private afAuth: AngularFireAuth,
    private translate: TranslateService
  ) {
    this.initialize();
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
  }

  /**
   * Initialize
   */
  async initialize() {
    this.deviceLanguage = 'en';
    this.translate.getDefaultLang();
    this.getDeviceLanguage();
  }

  /**
   * Get Device Language
   */
  getDeviceLanguage(): string {
    const language = this.checkDeviceLanguage();
    return this.setLanguage(language);
  }

  checkDeviceLanguage(): string {
    let language = 'en';
    const browserLanguage = this.translate.getBrowserCultureLang();
    if (browserLanguage !== null) {
      language = browserLanguage;
    }
    return language;
  }
  
  /**
   * Set device language
   * @param language language
   * @returns current language code
   */
  setLanguage(language?: string, userLang?: boolean): string {
    if (language && language?.indexOf('-') !== -1) {
      const lang = language.split('-');
      if (lang[0] === 'zh') {
        if (lang[1]) {
          const cultulreLang = lang[1]?.toString().toLowerCase();
          if (cultulreLang === 'cht' || cultulreLang === 'hant' || cultulreLang === 'hk' || cultulreLang === 'mo' || cultulreLang === 'tw') {
            language = 'zh-TW';
          } else {
            language = 'zh';
          }
        } else {
          language = 'zh';
        }
      } else if (lang.length) {
        language = lang[0].toString();
      }
    }

    if (!language || this.languageList?.findIndex((x: Language) => x?.code === language) === -1) {
      language = 'en';
    }

    if (userLang) {
      this.userLanguage = language;
    }

    if (!this.userLanguage || this.userLanguage === language) {
      this.language = language;
      this.translate.use(language);
      this.translate.setDefaultLang(language);
      this.afAuth.languageCode = new Promise(() => language);
    }
    return language;
  }

}
