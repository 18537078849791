import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { AccountStatus, TrialData } from 'src/app/interfaces/account';
import { FunctionService } from 'src/app/services/general/function.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-status',
  templateUrl: './account-status.component.html',
  styleUrls: ['./account-status.component.scss'],
})
export class AccountStatusComponent implements OnInit, OnDestroy {

  @Input() set setupTrialData(trialData: TrialData) {
    this.trialData = trialData;
    this.trialEnd = this.getCountDown(this.trialData?.end);
  }
  @Input() accountStatus: AccountStatus;
  @Input() showSubscribe: boolean;

  trialData: TrialData;
  trialEnd: string;

  constructor(
    private accountInfoService: AccountInfoService,
    private functionService: FunctionService
  ) { }

  ngOnInit(): void {}

  ngOnDestroy(): void {

  }

  /**
   * Get count down to current date
   * @param time Time
   * @returns count down in string
   */
  getCountDown(time: any): string {
    const countdown: number = this.functionService.getCountDown(time);
    if (countdown === 0) {
      return 'today';
    } else if (countdown === 1) {
      return 'tomorrow';
    } else if (countdown < 0) {
      return 'expired';
    } else {
      return countdown.toString();
    }
  }

  async goUpgrade() {
    this.accountInfoService.goUpgradeSubscriptionPage();
  }

}
