import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { StorageService } from '../general/storage.service';
import { BlastTemplate, BlastResumeData } from 'src/app/interfaces/blast';
import { BlastType } from 'src/app/types/blast';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../general/popup.service';
import { ModalController } from '@ionic/angular';
import { BlastReviewComponent } from 'src/app/components/blast/blast-review/blast-review.component';
import { PrivilegeService } from '../account/privilege/privilege.service';

const BlastResumeDataKey = 'blastResumeData';

@Injectable({
  providedIn: 'root'
})
export class BlastResumeService implements OnInit, OnDestroy {

  constructor(
    private translate: TranslateService,
    private modalController: ModalController,
    private privilegeService: PrivilegeService,
    private popupService: PopupService,
    private storageService: StorageService,
  ) { }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  async promptBlastResumeData(blastType: BlastType, blastTemplate: BlastTemplate, guestIdList: string[] = [], groupIdList: string[] = []) {
    const confirm = await this.popupService.presentConfirm(this.translate.instant('BLAST.review.resume'));
    confirm.onDidDismiss().then((result: any) => {
      if (result?.data?.confirm) {
        this.presentBlastReviewModal(blastType, blastTemplate, guestIdList, groupIdList);
      } else {
        this.clearBlastResumeData();
      }
    })
    
  }

  async presentBlastReviewModal(blastType: BlastType, blastTemplate: BlastTemplate, selectedGuestIdList: string[] = [], selectedGroupIdList: string[] = []) {
    if (this.checkPrivilege('send')) {
      const modal = await this.modalController.create({
        component: BlastReviewComponent,
        componentProps: {
          selectedGuestIdList,
          selectedGroupIdList,
          blastTemplate,
          blastType,
        }
      });
      modal.present();
    } else {
      this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
    }
  }

  checkPrivilege(action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege('blast', action);
  }

  async checkBlastResumeData() {
    if (this.checkPrivilege('send')) {
      const blastResumeData: BlastResumeData = await this.getBlastResumeData();
      if (blastResumeData?.blastType && blastResumeData?.blastTemplate?.msg?.length && (blastResumeData?.guestIdList?.length || blastResumeData?.groupIdList?.length)) {
        this.promptBlastResumeData(blastResumeData.blastType, blastResumeData.blastTemplate, blastResumeData.guestIdList, blastResumeData.groupIdList);
      }
    }
  }

  async getBlastResumeData(): Promise<BlastResumeData> {
    return await this.storageService.getObject(BlastResumeDataKey);
  }

  async saveBlastResumeData(blastType: BlastType, blastTemplate: BlastTemplate, guestIdList: string[] = [], groupIdList: string[] = []) {
    if (blastType && blastTemplate?.msg?.length && (guestIdList?.length || groupIdList?.length)) {
      const blastResumeData: BlastResumeData = {
        blastType,
        blastTemplate,
        guestIdList,
        groupIdList,
      };
      await this.storageService.setObject(BlastResumeDataKey, blastResumeData);
    }
  }

  async clearBlastResumeData() {
    await this.storageService.set(BlastResumeDataKey, null);
  }
}
