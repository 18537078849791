import { Injectable } from '@angular/core';
import { PopupService } from '../general/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { GuestService } from '../guest/guest.service';
import { AccountInfoService } from './account-info.service';

import { AccountUserService } from './account-user.service';
import { GroupService } from '../group/group.service';
import { BehaviorSubject } from 'rxjs';
import { LinkService } from '../general/link.service';

@Injectable({
  providedIn: 'root'
})
export class AccountLoadingService {

  ready: boolean;

  observableReady: any;

  private toast: HTMLIonToastElement;

  private count: number;

  constructor(
    private translate: TranslateService,
    private accountInfoService: AccountInfoService,
    // private accountPackageService: AccountPackageService,
    private accountUserService: AccountUserService,
    private guestService: GuestService,
    private groupService: GroupService,
    private linkService: LinkService,
    private popupService: PopupService,
  ) {
    this.observableReady = new BehaviorSubject<any>(this.ready);
  }

  async startLoading() {
    if (!this.toast) {
      this.count = 1;
      this.ready = false;
      this.observableReady.next(this.ready);
      this.toast = await this.popupService.presentToast(this.translate.instant('LOADING.data'), '', false, 0, '');
      setTimeout(() => {
        this.checkLoading();
      }, 500);
    }
  }

  async stopLoading() {
    if (this.toast) {
      await this.toast?.dismiss();
      this.toast = null;
    }
    await this.popupService.dismissToast();
    await this.popupService.dismissAllToast();
    this.ready = true;
    this.observableReady.next(this.ready);
  }

  checkLoading() {
    if (this.getReady()) {
      this.stopLoading();
    } else {
      this.count += 1;
      if (this.count < 600) {
        setTimeout(() => {
          this.checkLoading();
        }, 500);
      } else {
        this.linkService.reload();
      }
    }
  }

  getReady(): boolean {
    return this.guestService.ready && this.groupService.ready && this.accountInfoService.ready && this.accountUserService.ready;
  }
}
