import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subscription, distinctUntilChanged, firstValueFrom, map } from 'rxjs';
import { BlastMsg, BlastTemplate } from 'src/app/interfaces/blast';
import { SettingField } from 'src/app/interfaces/database';
import { AccountInfoService } from '../account/account-info.service';

import { GuestService } from '../guest/guest.service';
import { GroupService } from '../group/group.service';
import { UpdateByService } from '../user/update-by.service';
import { FunctionService } from '../general/function.service';
import { ErrorService } from '../general/error.service';
import { BlastSampleUrl, StdBlastTemplateTypeList } from 'src/app/commons/blastTemplate';
import { TemplateVariableType } from 'src/app/types/template';
import { TemplateVariableEvent, TemplateVariableWedding } from 'src/app/commons/template';
import { Guest } from 'src/app/interfaces/guest';
import { Group } from 'src/app/interfaces/group';
import { Mobile } from 'src/app/interfaces/general';
import { BlastProvider, BlastType } from 'src/app/types/blast';
import { UpdateBy } from 'src/app/interfaces/user';
import { BlastCountService } from './blast-count.service';
import { StdEventWhatsAppTemplateList, StdWhatsAppTemplateList, StdWhatsAppTemplateNoReply } from 'src/app/commons/whatsappTemplate';
import { DateTimeService } from '../general/date-time.service';
import { SeatingSettingService } from '../seating/seating-setting.service';
import { VisitorLocationService } from '../visitor/visitor-location.service';

import { AccountEventModeService } from '../account/account-event-mode.service';
import { BlastWhatsappService } from './blast-whatsapp.service';
import { StdEventSmsTemplateList, StdSmsTemplateList } from 'src/app/commons/smsTemplate';

@Injectable({
  providedIn: 'root'
})
export class BlastTemplateService implements OnInit, OnDestroy {

  stdTemplateList: BlastTemplate[];
  templateList: BlastTemplate[];
  // templateTypeList: SettingField[];

  // observableTemplateList: any;
  // observableTemplateTypeList: any;

  private accountId: string;
  private stdTemplateListSubscription: Subscription;
  private templateListSubscription: Subscription;

  constructor(
    private afs: AngularFirestore,
    private accountInfoService: AccountInfoService,
    private accountEventModeService: AccountEventModeService,
    private blastCountService: BlastCountService,
    private blastWhatsappService: BlastWhatsappService,
    private guestService: GuestService,
    private groupService: GroupService,
    private visitorLocationService: VisitorLocationService,
    private seatingSettingService: SeatingSettingService,
    private dateTimeService: DateTimeService,
    private updateByService: UpdateByService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
    // this.observableTemplateList = new BehaviorSubject<BlastTemplate[]>(this.templateList);
    // this.observableTemplateTypeList = new BehaviorSubject<SettingField[]>(this.templateTypeList);
  }

  ngOnInit(): void {
      
  }
  
  ngOnDestroy() {
    this.unwatchStdTemplateList();
    this.unwatchTemplateList();
    this.templateList = [];
    this.stdTemplateList = [];
  }


  async setupAccountId(accountId: string) {
    this.accountId = accountId;
    
    if (this.accountId) {
      // this.setupStdTemplate();
      await this.watch();
      // this.saveStdTemplateList(StdSmsTemplateList.concat(StdEventSmsTemplateList).concat(StdWhatsAppTemplateList.concat(StdEventWhatsAppTemplateList)));
    } else {
      await this.unwatch();
      this.templateList = [];
      this.stdTemplateList = [];
    }
  }

  getTemplateIdList() {
    let list = '';
    StdWhatsAppTemplateList?.forEach((x: BlastTemplate) => {
      list += x.templateId + '<br>';
    });
    StdEventWhatsAppTemplateList?.forEach((x: BlastTemplate) => {
      list += x.templateId + '<br>';
    });
    return list;
  }

  // setupStdTemplate() {
  //   this.templateTypeList = this.setupStdTemplateTypeList();
  //   this.observableTemplateTypeList.next(this.templateTypeList);
  //   this.templateList = this.getStdSmsTemplateList();
  //   this.observableTemplateList.next(this.templateList);
  // }

  async watch() {
    this.watchStdTemplateList();
    // this.watchTemplateSetting();
    this.watchTemplateList();
  }

  async unwatch() {
    this.unwatchStdTemplateList();
    // this.unwatchTemplateSetting();
    this.unwatchTemplateList();
  }

  async readBlastTemplateList() {
    try {
      const querySnapshot = await firstValueFrom(this.afs.collection(`accounts/${ this.accountId }/blast/msg/blastTemplate/`, ref => ref.where('enable', '==', true)).get());
      const templateList: BlastTemplate[] = querySnapshot.docs.map(doc => {
        const data = doc.data() as BlastTemplate;
        if (!data.templateId) {
          data.templateId = doc.id;
        }
        return data;
      }).filter((blastTemplate: BlastTemplate) => {
        return blastTemplate?.enable && blastTemplate?.templateId !== 'blastTemplateType';
      });

      this.templateList = templateList.sort((a: BlastTemplate, b: BlastTemplate) => {
        return this.functionService.compare(a.templateNo, b.templateNo);
      });
    } catch (error) {
      this.errorService.logError(error);
    }
  }

  async readBlastStdTemplate() {
    try {
      const docSnapshot = await firstValueFrom(this.afs.doc(`blast/stdTemplate/`).get());
      const data: any = docSnapshot.data();
      const stdTemplateList: BlastTemplate[] = data?.list?.length ? data.list : [];
      this.stdTemplateList = stdTemplateList.filter((blastTemplate: BlastTemplate) => {
        return blastTemplate.enable;
      });
    } catch (error) {
      this.errorService.logError(error);
    }
  }


  // async watchTemplateSetting() {
  //   await this.unwatchTemplateSetting();
  //   if (this.accountId) {
  //     this.templateSettingSubscription = this.afs.doc(`accounts/${ this.accountId }/blast/msg/blastTemplate/blastTemplateType/`)
  //     .snapshotChanges().pipe(map(changes => {
  //       const data: any = changes.payload.data();
  //       return data;
  //     })).subscribe({
  //       next: (data: any) => {
  //         if (!data?.templateTypeList) {
  //           this.templateTypeList = this.setupStdTemplateTypeList();
  //           this.observableTemplateTypeList.next(this.templateTypeList);
  //         } else if (data?.templateTypeList && !this.functionService.isEqual(this.templateTypeList, data?.templateTypeList)) {
  //           this.templateTypeList = this.setupStdTemplateTypeList(data.templateTypeList);
  //           this.observableTemplateTypeList.next(this.templateTypeList);
  //         }
  //       }
  //     });
  //   }
  // }

  // async unwatchTemplateSetting() {
  //   if (this.templateSettingSubscription) {
  //     this.templateSettingSubscription.unsubscribe();
  //     this.templateSettingSubscription = null;
  //     this.observableTemplateTypeList.next([]);
  //     this.observableTemplateList.next([]);
  //   }
  // }

  async watchTemplateList() {
    if (this.accountId) {
      if (!this.templateListSubscription) {
        this.templateListSubscription = this.afs.collection(`accounts/${ this.accountId }/blast/msg/blastTemplate/`,
        ref => ref.where('enable', '==', true))
        .snapshotChanges().pipe(distinctUntilChanged(), map(actions => actions.map( a => {
          const data: BlastTemplate = a.payload.doc.data() as BlastTemplate;
          if (!data?.templateId) { data.templateId = a.payload.doc.id; }
          return data;
        }).filter((blastTemplate: BlastTemplate) => {
          // return blastTemplate?.enable
          return blastTemplate?.enable && blastTemplate?.templateId !== 'blastTemplateType';
        }))).subscribe({
          next: (templateList: BlastTemplate[]) => {
            this.templateList = templateList.sort((a: BlastTemplate, b: BlastTemplate) => {
              return this.functionService.compare(a.templateNo, b.templateNo);
            });
            // this.setupMsgTemplate(templateList);
          }, error: (err: any) => {
            this.errorService.logError(err);
          }
        });
      }
    } else {
      setTimeout(() => {
        this.watchTemplateList();
      }, 500);
    }
  }

  async unwatchTemplateList() {
    if (this.templateListSubscription) {
      this.templateListSubscription.unsubscribe();
      this.templateListSubscription = null;
      // this.setupMsgTemplate([]);
    }
  }

  async watchStdTemplateList() {
    if (this.accountId) {
      if (!this.stdTemplateListSubscription) {
        this.stdTemplateListSubscription = this.afs.doc(`blast/stdTemplate/`)
        .snapshotChanges().pipe(map(changes => {
          const data: any = changes.payload.data();
          return data?.list?.length ? data.list : [];
        })).subscribe({
          next: (stdTemplateList: BlastTemplate[]) => {
            this.stdTemplateList = stdTemplateList.filter((blastTemplate: BlastTemplate) => {
              return blastTemplate.enable;
            });
            // this.setupMsgTemplate(templateList);
          }, error: (err: any) => {
            this.errorService.logError(err);
          }
        });
      }
    } else {
      setTimeout(() => {
        this.watchStdTemplateList();
      }, 500);
    }
  }

  async unwatchStdTemplateList() {
    if (this.stdTemplateListSubscription) {
      this.stdTemplateListSubscription.unsubscribe();
      this.stdTemplateListSubscription = null;
    }
  }


  // setupStdTemplateTitle(): SettingField{
  //   return {
  //     custom: false,
  //     value: this.accountInfoService.accountTitle
  //   };
  // }

  setupStdTemplateTypeList(templateTypeList?: SettingField[]) {
    if (!templateTypeList?.length) {
      templateTypeList = [];
    }
    StdBlastTemplateTypeList?.forEach((value: string) => {
      const index = templateTypeList.findIndex((templateType: SettingField) => {
        return templateType?.value === value && !templateType?.custom;
      });
      if (index === -1) {
        templateTypeList.push({
          value, custom: false
        });
      }
    });
    return templateTypeList;
  }

  setupTemplateList(stdTemplateList: BlastTemplate[], customTemplateList: BlastTemplate[]) {
    if (customTemplateList?.length) {
      customTemplateList?.forEach((x: BlastTemplate) => {
        const index = stdTemplateList?.findIndex((blastTemplate: BlastTemplate) => {
          if (blastTemplate?.templateId === x?.templateId) {
            return true;
          }
          return false;
        });
        if (index === -1) {
          stdTemplateList.push(x);
        }
      });
    }
    return stdTemplateList;
  }

  // setupMsgTemplate(templateList: BlastTemplate[]) {
  //   if (!templateList?.length) {
  //     templateList = [];
  //   }
  //   StdSmsTemplateList?.forEach((stdMsgTemplate: BlastTemplate) => {
  //     const index = templateList?.findIndex((blastTemplate: BlastTemplate) => {
  //       if (blastTemplate?.templateId === stdMsgTemplate?.templateId) {
  //         return true;
  //       }
  //       return false;
  //     });
  //     if (index === -1) {
  //       templateList.push(stdMsgTemplate);
  //     }
  //   });
  //   this.templateList = templateList;
  //   this.observableTemplateList.next(this.templateList);
  // }

  // getTemplateTypeList(blastType: BlastType): SettingField[] {
  //   if (blastType === 'sms') {
  //     return this.templateTypeList?.length ? this.templateTypeList : this.setupStdTemplateTypeList();
  //   } else {
  //     return this.setupStdTemplateTypeList([]);
  //   }
   
  // }

  // getTemplateList(blastType: BlastType): BlastTemplate[] {
  //   if (this.accountEventModeService.eventMode) {
  //     if (blastType === 'sms') {
  //       return this.getSmsTemplateList();
  //     } else if (blastType === 'whatsapp') {
  //       return this.functionService.cloneDeep(StdEventWhatsAppTemplateList).map((blastTemplate: BlastTemplate) => {
  //         blastTemplate.msg = StdWhatsAppTemplateTitle + '\n\n' + blastTemplate.msg;
  //         if (!blastTemplate?.language?.custom && blastTemplate?.language?.value && StdWhatsAppTemplateNoReply?.[blastTemplate.language.value]) {
  //           blastTemplate.msg = blastTemplate.msg + '\n\n' + StdWhatsAppTemplateNoReply[blastTemplate.language.value];
  //         }
  //         return blastTemplate;
  //       });
  //     }
  //   } else {
  //     if (blastType === 'sms') {
  //       return this.getSmsTemplateList();
  //     } else if (blastType === 'whatsapp') {
  //       return this.functionService.cloneDeep(StdWhatsAppTemplateList).map((blastTemplate: BlastTemplate) => {
  //         blastTemplate.msg = StdWhatsAppTemplateTitle + '\n\n' + blastTemplate.msg;
  //         if (!blastTemplate?.language?.custom && blastTemplate?.language?.value && StdWhatsAppTemplateNoReply?.[blastTemplate.language.value]) {
  //           blastTemplate.msg = blastTemplate.msg + '\n\n' + StdWhatsAppTemplateNoReply[blastTemplate.language.value];
  //         }
  //         return blastTemplate;
  //       });
  //     }
  //   }
  //   return [];
  // } 

  getStdTemplate(blastType: BlastType, lang?: SettingField, templateType?: SettingField): BlastTemplate[] {
    const eventMode = this.accountEventModeService.eventMode;
    let stdTemplateList: BlastTemplate[] = this.functionService.cloneDeep(this.stdTemplateList);

    if (stdTemplateList?.length) {
      stdTemplateList = stdTemplateList.filter((stdTemplate: BlastTemplate) => {
        return stdTemplate.eventMode === eventMode;
      });
  
      if (blastType) {
        return stdTemplateList.filter((stdTemplate: BlastTemplate) => {
          if (blastType) {
            if (stdTemplate?.blastType !== blastType) {
              return false;
            }
          }
          if (lang?.value) {
            if (stdTemplate?.language?.value !== lang.value || stdTemplate?.language?.custom !== lang.custom) {
              return false;
            }
          }
          if (templateType?.value) {
            if (stdTemplate?.templateType?.value !== templateType?.value || stdTemplate?.templateType?.custom !== templateType?.custom) {
              return false;
            }
          }
          return true;
        });
      }
    }
    
    return stdTemplateList;
  }

  getTemplateList(blastType: BlastType, lang?: SettingField, templateType?: SettingField): BlastTemplate[] {
    let stdTemplateList: BlastTemplate[] = this.getStdTemplate(blastType, lang, templateType);
    if (stdTemplateList?.length) {
      if (blastType === 'sms') {
        if (this.templateList?.length) {
          stdTemplateList = this.setupTemplateList(stdTemplateList, this.templateList);
        }
      } else if (blastType === 'whatsapp') {
        stdTemplateList = this.functionService.cloneDeep(this.setupTemplateList(stdTemplateList, this.templateList));
        stdTemplateList = stdTemplateList.map((blastTemplate: BlastTemplate) => {
          if (!blastTemplate?.custom && !blastTemplate?.language?.custom && blastTemplate?.language?.value && StdWhatsAppTemplateNoReply?.[blastTemplate.language.value]) {
            blastTemplate.msg = blastTemplate.msg + '\n\n' + StdWhatsAppTemplateNoReply[blastTemplate.language.value];
          }
          return blastTemplate;
        });
      }
    }

    return stdTemplateList?.length ? stdTemplateList.filter((x: BlastTemplate) => {
      if (blastType) {
        if (x?.blastType !== blastType) {
          return false;
        }
      }
      if (lang?.value) {
        if (x?.language?.value !== lang.value || x?.language?.custom !== lang.custom) {
          return false;
        }
      }
      if (templateType?.value) {
        if (x?.templateType?.value !== templateType?.value || x?.templateType?.custom !== templateType?.custom) {
          return false;
        }
      }
      return true;
    }) : [];
  }

  getTemplateTypeList(blastType?: BlastType): SettingField[] {
    const templateTypeList: SettingField[] = [];
    const templateList = this.getTemplateList(blastType);

    if (templateList?.length) {
      templateList?.forEach((blastTemplate: BlastTemplate) => {
        if (blastTemplate?.blastType === blastType || (!blastType && !blastTemplate?.templateType?.custom)) {
          const index = templateTypeList.findIndex((x: SettingField) => {
            return x.custom === blastTemplate.templateType.custom && x.value === blastTemplate.templateType.value;
          });
          if (index === -1) {
            templateTypeList.push(blastTemplate.templateType);
          }
        }
      });
    }
    
    if (!templateTypeList?.length && !blastType) {
      return this.setupStdTemplateTypeList();
    }
    
    return templateTypeList;
  }

  // getStdSmsTemplateList(): BlastTemplate[] {
  //   return this.accountEventModeService.eventMode ? StdEventSmsTemplateList : StdSmsTemplateList;
  // }

  // getSmsTemplateList(): BlastTemplate[] {
  //   return this.templateList?.length ? this.templateList : this.getStdSmsTemplateList();
  // }

  getTemplateNoList(blastType: BlastType, templateType: SettingField, langCode: string): string[] {
    const list: string[] = [];
    const templateList: BlastTemplate[] = this.getTemplateList(blastType);
    if (templateList?.length) {
      templateList?.forEach((blastTemplate: BlastTemplate) => {
        if (blastTemplate?.blastType === blastType && blastTemplate?.templateType?.value === templateType?.value && !blastTemplate?.language?.custom && blastTemplate?.language.value === langCode && blastTemplate?.templateNo) {
          if (list.indexOf(blastTemplate.templateNo) === -1) {
            list.push(blastTemplate.templateNo);
          }
        }
      });
    }
    
    // const eventMode = this.accountEventModeService.eventMode;
    // if (templateType?.value && !templateType?.custom && langCode) {
    //   if (eventMode) {
    //     if (WhatsAppTemplateTypeNoList.event?.[templateType?.value]?.[langCode]) {
    //       return WhatsAppTemplateTypeNoList.event[templateType?.value][langCode];
    //     }
    //   } else {
    //     if (WhatsAppTemplateTypeNoList.wedding?.[templateType?.value]?.[langCode]) {
    //       return WhatsAppTemplateTypeNoList.wedding[templateType?.value][langCode];
    //     }
    //   }
    // }
    // return [];
    return list;
  }

  getTemplateVariableList() {
    return this.accountEventModeService.eventMode ? TemplateVariableEvent : TemplateVariableWedding;
  }

  setupBlastContent(blastType: BlastType, blastTemplate: BlastTemplate, langCode: string, phoneCode: number, guest?: Guest, group?: Group) {
    let url = '';
    if (blastTemplate?.url || blastType === 'whatsapp') {
      // if (guest) {
      //   if (guest?.link?.short) {
      //     url = guest.link.short;
      //   }
      // } else if (group) {
      //   if (group?.link?.short) {
      //     url = group.link.short;
      //   }
      // }
      if (!url) {
        url = BlastSampleUrl;
      }
    }

    if (blastTemplate?.msg ) {
      const variableList = this.getTemplateVariableList();
      variableList?.forEach((variable: TemplateVariableType) => {
        let value = '';
        if (guest?.guestId) {
          if (variable === 'guest_name' && guest.name) {
            value = guest.name;
          }
          if (variable === 'guest_alternate_name' && guest.nickname) {
            value = guest.nickname;
          }
          if (variable === 'guest_seating' && guest.seating) {
            value = guest.seating;
          }
          if (variable === 'number_of_guest') {
            if (guest.groupId) {
              const guestGroup = this.groupService.getGroup(guest.groupId);
              if (guestGroup?.memberList?.length) {
                value = guestGroup.memberList.length.toString();
              } else {
                value = '1';
              }
            } else {
              value = '1';
            }
          }
          if (variable === 'group_name') {
            if (guest.groupId) {
              if (!group?.groupId) {
                group = this.groupService.getGroup(guest.groupId);
              }
              if (group?.groupName) {
                value = group.groupName;
              }
            }
          }
          if (variable === 'guest_session') {
            if (guest?.session?.[0]?.value) {
              value = guest.session[0].value;
            }
          }
        } else if (group?.groupId) {
          if (variable === 'guest_name' && group.groupName) {
            value = group.groupName;
          }
          if (variable === 'group_name' && group?.groupName) {
            value = group.groupName;
          }
          if (variable === 'number_of_guest') {
            if (group?.memberList) {
              value = group.memberList.length.toString();
            } else {
              value = '1';
            }
          }
          if (variable === 'guest_seating') {
            value = this.getGroupSeating(group?.memberList);
          }
          if (variable === 'guest_session') {
            value = this.getGroupSession(group?.memberList);
          }
        }

        if (variable === 'wedding_title' && this.accountInfoService.accountTitle) {
          value = this.accountInfoService.accountTitle;
        }
        if (variable === 'wedding_date') {
          if (this.accountInfoService.accountInfo?.time?.seconds) {
            value = this.dateTimeService.format(this.accountInfoService.accountInfo.time.seconds * 1000, 'do MMM yyyy, EEEE', langCode);
          }
        }
        if (variable === 'wedding_start_time') {
          if (this.accountInfoService.accountInfo?.time?.seconds) {
            value = this.dateTimeService.format(this.accountInfoService.accountInfo.time.seconds * 1000, 'p', langCode);
          }
        }
        if (variable === 'wedding_timezone') {
          if (this.accountInfoService.accountInfo?.timezone?.utc) {
            value = this.accountInfoService.accountInfo.timezone.utc;
          }
        }

        if (variable === 'seating_type') {
          value = this.seatingSettingService.getSeatingTypeName(langCode);
        }

        if (variable === 'wedding_venue_name' || variable === 'wedding_venue_hall' || variable === 'wedding_venue_address') {
          // let langCode = 'en';
          // if (!blastTemplate.templateType?.custom && blastTemplate.templateType?.value) {
          //   langCode = blastTemplate.templateType.value;
          // }
          const venue = this.visitorLocationService.getLocation(langCode);
          if (variable === 'wedding_venue_name') {
            value = venue?.name ? venue.name : '';
          }
          if (variable === 'wedding_venue_hall') {
            value = venue?.hall ? venue.hall : '';
          }
          if (variable === 'wedding_venue_address') {
            value = venue?.address ? venue.address : '';
          }
        }

        if (variable === 'couple1' || variable === 'couple2') {
          if (!this.accountEventModeService.eventMode) {
            if (variable === 'couple1') {
              value = this.accountInfoService.getCoupleName(1);
            } else if (variable === 'couple2') {
              value = this.accountInfoService.getCoupleName(2);
            }
          }
        }

        if (variable === 'organizer') {
          if (this.accountEventModeService.eventMode) {
            value = this.accountInfoService.accountInfo?.organizer ? this.accountInfoService.accountInfo.organizer : '';
          }
        }

        const field = '[' + variable.toUpperCase() + ']';

        if (variable === 'guest_url') {
          value = url ? url : field;
        }

        if (blastType === 'whatsapp' && value) {
          value = this.blastWhatsappService.getWhatsappStyling(variable, value);
        }

        if (!value) {
          value = '-';
        }
        blastTemplate.msg = this.functionService.replaceAll(blastTemplate.msg, field, value);
        if (variable === 'wedding_title') {
          blastTemplate.templateTitle = this.functionService.replaceAll(blastTemplate.templateTitle, field, value);
        }
      });
    }
    
    return this.generateBlastContent(blastType, blastTemplate, url, phoneCode);
  }

  generateBlastContent(blastType: BlastType, blastTemplate: BlastTemplate, url: string, phoneCode: number): { content: string[], blastCount: number, url: string } {
    const content: string[] = [];
    
    const { blastCount, splitUrl } = this.blastCountService.getBlastCount(
      blastType,
      blastTemplate?.templateTitle,
      blastTemplate?.msg,
      url,
      phoneCode,
      blastTemplate?.dynamicVariable
    );

    let msg: string = blastTemplate.msg;

    if (blastType === 'sms' && blastTemplate?.templateTitle && msg?.indexOf(blastTemplate.templateTitle) !== 0) {
      msg = blastTemplate.templateTitle + '\n' + msg;
    }
    if (blastType === 'sms' && phoneCode === 60 && msg?.indexOf('RM0') !== 0) {
      msg = 'RM0 ' + msg;
    }

    if (blastType === 'sms' && url) {
      if (splitUrl) {
        content.push(msg);
        let urlMsg = url;
        if (blastTemplate?.templateTitle && urlMsg?.indexOf(blastTemplate.templateTitle) !== 0) {
          urlMsg = blastTemplate.templateTitle + '\n' + urlMsg;
        }
        if (phoneCode === 60 && urlMsg?.indexOf('RM0') !== 0) {
          urlMsg = 'RM0 ' + urlMsg;
        }
        content.push(urlMsg);
      } else {
        msg = msg + '\n' + url;
        content.push(msg);
      }
    } else {
      content.push(msg);
    }
    return { content, blastCount, url };
  }

  checkBlastContentSampleUrl(content: string[]) {
    let generateUrl = false;
    if (content?.length) {
        content?.forEach((value: string) => {
            if (value.indexOf(BlastSampleUrl) !== -1) {
                generateUrl = true;
            }
        });
    }
    return generateUrl;
  }

  generateBlastMsg(blastType: BlastType, templateId: string, templateTitle: string, templateType: SettingField, content: string[],
                 mobile: Mobile, provider: BlastProvider, count: number, blastCredit: number,
                 createBy: UpdateBy, guestId: string, groupId: string, url: boolean) {
                  if (!url) { url = false; }
    const blastMsg: BlastMsg = {
        msgId: '',
        templateId,
        templateTitle,
        templateType,
        blastType,
        content,
        url,
        mobile,
        guestId,
        groupId,
        accountId: this.accountId,
        usageId: '',
        scheduleId: '',
        provider,
        count,
        blastCredit,
        createBy,
        updateBy: createBy,
        enable: false,
    };
    return blastMsg;
  }

  getGroupSeating(memberIdList: string[]): string {
    const result = this.guestService.getGroupSeating(memberIdList);
    return result.join(', ');
  }

  getGroupSession(memberIdList: string[]): string {
    const result = this.guestService.getGroupSession(memberIdList);
    return result.join(', ');
  }

  // async saveSetting(templateTypeList?: SettingField[]) {
  //   if (this.accountId) {
  //     const data: any = {};
  //     if (!this.functionService.isUndefined(templateTypeList) && !this.functionService.isEqual(templateTypeList, this.templateTypeList)) {
  //       data.templateTypeList = templateTypeList;
  //     }
  //     if (!this.functionService.isEmpty(data)) {
  //       data.updateBy = this.updateByService.updateBy;
  //       const settingRef = this.afs.firestore.doc(`accounts/${ this.accountId }/blast/msg/blastTemplate/blastTemplateType/`);
  //       settingRef.set(data, { merge: true }).then(result => {
  //       }).catch((err: any) => {
  //         this.errorService.logError(err);
  //       });
  //     }
  //   }
  // }

  async saveTemplate(blastTemplate: BlastTemplate) {
    if (this.accountId && blastTemplate?.templateId) {
      const index = this.templateList.findIndex((x: BlastTemplate) => {
        return x?.templateId === blastTemplate.templateId;
      });
      blastTemplate.updateBy = this.updateByService.updateBy;
      if (!blastTemplate?.createBy || index === -1) {
        blastTemplate.createBy = blastTemplate.updateBy;
      }
      const templateRef = this.afs.firestore.doc(`accounts/${ this.accountId }/blast/msg/blastTemplate/${ blastTemplate.templateId }`);
      templateRef.set(blastTemplate, { merge: true });
    }
  }
  
  async saveStdTemplateList(list: BlastTemplate[]) {
    if (list?.length) {
      const templateRef = this.afs.firestore.doc(`blast/stdTemplate/`);
      templateRef.set({ list }, { merge: true });
    }
  }
}
