<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'CRUD.new_field' | translate: { field: 'LBL.role' | translate } }}
    </ion-title>
  </ion-toolbar>
  <ion-toolbar class="input-toolbar ion-no-padding" color="light">
    <ion-item class="prompt-input-item" lines="none">
      <ion-input [(ngModel)]="newValue" class="font-14 ion-text-left" type="text" inputmode="text" [placeholder]="'CRUD.enter_new_field' | translate: { field: 'LBL.role' | translate }" [autocapitalize]="true" autocomplete="on" clearInput>
        <ion-button class="btn" size="large" color="primary" fill="solid" slot="end" (click)="checkRole(newValue, -1)" [disabled]="!newValue">
          {{ 'CRUD.add' | translate }}
        </ion-button>
      </ion-input>
    </ion-item>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <div *ngIf="stdList?.length">
    <div class="ion-padding">
      <ion-text class="font-12"> {{ 'SETTING.lbl.select_new_field' | translate: { field: 'LBL.role' | translate } }}</ion-text>
    </div>
    <ion-list class="full-width-height ion-padding-bottom">
      <ion-item-sliding *ngFor="let role of stdList; let i = index;" [disabled]="true" #slidingItem>
        <ion-item class="item" [color]="checkSelected(role) ? 'tertiary' : 'white'" lines="none" (click)="select(role)">
          <ion-reorder slot="start" class="reorder-button">
            <ion-icon name="reorder-two-outline" color="medium"></ion-icon>
          </ion-reorder>
          <ion-label class="font-14 ion-text-wrap">
            {{ getValue(role) }}
          </ion-label>
          <ion-text slot="end" *ngIf="checkSelected(role)">
            <ion-icon src="./assets/wedding/icon/general/checkmark.svg"></ion-icon>
          </ion-text>
        </ion-item>
      </ion-item-sliding>
    </ion-list>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar class="ion-no-padding">
    <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" (click)="save()" [disabled]="!selected?.length">
      {{ 'BTN.select' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>
