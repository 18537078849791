import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { BehaviorSubject, Subscription, map, distinctUntilChanged, firstValueFrom } from 'rxjs';

import { UpdateByService } from 'src/app/services/user/update-by.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { AccountLogin, LoginCredential } from 'src/app/interfaces/login';
import { Privilege } from 'src/app/interfaces/privilege';
import { UserRole } from 'src/app/interfaces/account';
import { ShareRecord } from 'src/app/interfaces/share';
import { ShareChannel, ShareMethod } from 'src/app/types/share';
import { Timezone } from 'src/app/interfaces/database';

/**
 * Account login service
 */
@Injectable({
  providedIn: 'root'
})
export class AccountLoginService implements OnInit, OnDestroy {

  /**
   * Wedding ID
   */
  weddingId: string;
  /**
   * Observable login list
   */
  observableLoginList: any;
  /**
   * Account ID
   */
  private accountId: string;
  /**
   * Account login list
   */
  private loginList: AccountLogin[];
  /**
   * Account login subscription
   */
  private accountLoginSubscription: Subscription;

  /**
   * Constructor
   * @param afs Angular Firestore
   * @param moduleService AccountModule service
   * @param userService User Service
   * @param errorService Error Service
   * @param functionService Function Service
   */
  constructor(
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private moduleService: ModuleService,
    private updateByService: UpdateByService,
    private errorService: ErrorService,
    private functionService: FunctionService,
  ) {
    this.loginList = [];
    this.observableLoginList = new BehaviorSubject<AccountLogin[]>(this.loginList);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy() {
    this.unwatchAccountLogin();
    this.loginList = [];
  }

  /**
   * Setup account login
   * @param accountId Account ID
   * @param weddingId Wedding ID
   */
  async setupAccountLogin(accountId: string, weddingId: string) {
    this.accountId = accountId;
    this.weddingId = weddingId;

    if (this.accountId && this.weddingId) {
      await this.watchAccountLogin();
    } else {
      await this.unwatchAccountLogin();
      this.loginList = [];
    }
  }

  async readAccountLogin() {
    try {
      const querySnapshot = await firstValueFrom(this.afs.collection(`accounts/${ this.accountId }/accountLogin/`).get());
      const loginList: AccountLogin[] = querySnapshot.docs.map(doc => {
        const data = doc.data() as AccountLogin;
        if (!data.accountLoginId) {
          data.accountLoginId = doc.id;
        }
        return data;
      });

      this.loginList = loginList;
      this.observableLoginList.next(this.loginList);
    } catch (error) {
      this.errorService.logError(error);
    }
  }

  /**
   * Watch account login
   */
  async watchAccountLogin() {
    if (this.accountId) {
      if (!this.accountLoginSubscription) {
        this.accountLoginSubscription = this.afs.collection(`accounts/${ this.accountId }/accountLogin/`)
        .snapshotChanges().pipe(distinctUntilChanged(), map(actions => actions.map( a => {
          const data: AccountLogin = a.payload.doc.data() as AccountLogin;
          if (!data.accountLoginId) { data.accountLoginId = a.payload.doc.id; }
          return data;
        }))).subscribe({
          next: (loginList: AccountLogin[]) => {
            this.loginList = loginList;
            this.observableLoginList.next(this.loginList);
          }, error: (err: any) => {
            this.errorService.logError(err);
          }
        });
      } 
    }
  }

  /**
   * Unwatch account login
   */
  async unwatchAccountLogin() {
    if (this.accountLoginSubscription) {
      this.accountLoginSubscription.unsubscribe();
      this.accountLoginSubscription = null;
    }
  }

  async generateAccountLoginCall(role: UserRole, privilege: Privilege, title?: string, time?: any, timezone?: Timezone)
  : Promise<AccountLogin> {
    // if (this.onlineService.isOnline()) {
      return await this.fns.httpsCallable('generateAccountLoginCall')({
        accountId: this.accountId,
        weddingId: this.weddingId,
        role,
        privilege,
        title,
        time,
        timezone,
        createBy: this.updateByService.updateBy
      }).toPromise().then(async (accountLogin: AccountLogin) => {
        return accountLogin;
      }).catch((err: any) => {
        this.errorService.logError(err);
        return null;
      });
    // } else {
    //   return null;
    // }
  }


  /**
   * Update account login status
   * @param weddingId Wedding ID
   * @param weddingPass Wedding Password
   * @param data data
   * @returns true if update successfully
   */
  async updateAccountLogin(login: LoginCredential, data: any): Promise<boolean> {
    if (!login.weddingId) { login.weddingId = this.weddingId; }
    if (login?.weddingPass && this.accountId && this.loginList) {
      const accountLoginIndex = this.loginList?.findIndex(
        (x: AccountLogin) => x.login.weddingPass === login.weddingPass && x.login.weddingId === login.weddingId
      );

      if (!this.functionService.isEmpty(data) && this.loginList[accountLoginIndex].accountLoginId) {
        if (await this.saveDb(data, this.loginList[accountLoginIndex].accountLoginId)) {
          return true;
        }
      } else {
        return true;
      }
    }

    return false;
  }

  /**
   * Save DB
   * @param data Data
   * @param docId Document ID (optional) Provided for account login update, else generate new account login
   * @returns true if save successfully
   */
  async saveDb(data: any, docId?: string): Promise<boolean> {
    data.updateBy = this.updateByService.updateBy;
    if (docId) {
      const accountsAccessRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountLogin/${docId}`);
      return accountsAccessRef.set(data, { merge: true }).then(() => {
        return true;
      }).catch((err: any) => {
        this.errorService.logError(err);
        return false;
      });
    } else {
      const accountsAccessRef = this.afs.firestore.collection(`accounts/${ this.accountId }/accountLogin/`).doc();
      return accountsAccessRef.set(data, { merge: true }).then(() => {
        return true;
      }).catch((err: any) => {
        this.errorService.logError(err);
        return false;
      });
    }
  }

  /**
   * Save share log
   * @param weddingPass Wedding Pass
   * @param channel Channel
   * @param type Type
   */
  async saveShareLog(weddingPass: string, channel: ShareChannel, method: ShareMethod) {
    if (weddingPass && this.loginList) {
      const share: ShareRecord = {
        channel,
        method,
        type: 'account_login',
        by: this.updateByService.updateBy
      };

      const accountLoginIndex = this.loginList?.findIndex(
        (x: AccountLogin) =>
        x?.login?.weddingPass === weddingPass &&
        x?.login?.weddingId === this.weddingId
      );

      if (accountLoginIndex !== -1) {
        if (!this.loginList[accountLoginIndex].share) {
          this.loginList[accountLoginIndex].share = [];
        } else {
          this.loginList[accountLoginIndex].share = Object.keys(this.loginList[accountLoginIndex].share).map((key) => {
            return this.loginList[accountLoginIndex].share[key];
          });
        }
        this.loginList[accountLoginIndex].share.push(share);

        const data: any = {};
        data.share = { ...this.loginList[accountLoginIndex].share };
        this.afs.firestore.doc(`accounts/${ this.accountId }/accountLogin/${ this.loginList[accountLoginIndex].accountLoginId }/`)
        .set(data, { merge: true }).then(() => {
        }).catch((err: any) => {
          this.errorService.logError(err);
        });
      }
    }
  }

  /**
   * Get account login module access count
   * @param accountLogin Account login info
   */
  getLoginModuleCount(accountLogin: AccountLogin): number {
    let count = 0;
    if (accountLogin?.privilege) {
      this.moduleService.moduleList?.forEach((module: string) => {
        if (accountLogin?.privilege?.[module]?.length) {
          count++;
        }
      });
    }
    return count;
  }

}
