
import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { SeatingService } from 'src/app/services/seating/seating.service';
import { SeatingSummaryService } from 'src/app/services/seating/seating-summary.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { SettingField } from 'src/app/interfaces/database';
import { Guest } from 'src/app/interfaces/guest';
import { Seating } from 'src/app/interfaces/seating';
import { Gift } from 'src/app/interfaces/gift';

import { SeatingSortType } from 'src/app/types/seating';

/**
 * Seating list service
 */
@Injectable({
  providedIn: 'root'
})
export class SeatingListService implements OnInit, OnDestroy {

  /**
   * Constructor service
   * @param moduleService module service
   * @param guestService guest service
   * @param guestSummaryService guest summary service
   * @param seatingService seating service
   * @param giftService gift service
   * @param functionService function service
   */
  constructor(
    private moduleService: ModuleService,
    private guestService: GuestService,
    private seatingService: SeatingService,
    private seatingSummaryService: SeatingSummaryService,
    private giftService: GiftService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Search seating List
   * @param keyword Keyword
   * @param filter Filter conditions
   * @param excludeSeatingList Excluded seating list for assign / swap mode. Exclude origin seating.
   * @param swapMode Swap mode flag, hide all empty seating for swap mode.
   * @returns list - Seating list, count - Count of seating list
   */
   searchSeatingList(
    keyword?: string,
    filter?: any,
    includeSeatingList?: Seating[],
    excludeSeatingList?: Seating[],
    swapMode?: boolean,
    sortType?: SeatingSortType,
    desc?: boolean,
    newGuestCount?: number,
  ): {
    list: Seating[],
    count: number
  } {
    let seatingList: Seating[] = this.seatingService.seatingList;
    if (seatingList) {
      if (includeSeatingList?.length) {
        seatingList = seatingList.filter((seating: Seating) => {
          return this.includeSeatingList(seating, includeSeatingList);
        });
      }

      if (excludeSeatingList?.length) {
        seatingList = seatingList.filter((seating: Seating) => {
          return this.excludeSeatingList(seating, excludeSeatingList);
        });
      }

      if (swapMode) {
        seatingList = seatingList.filter((seating: Seating) => {
          return this.filterSeatingEmpty(seating);
        });
      }

      if (keyword) {
        seatingList = seatingList.filter((seating: Seating) => {
          return this.searchSeatingByKeyword(seating, keyword);
        });
      }

      if (filter?.enable) {
        seatingList = seatingList.filter((seating: Seating) => {
          return this.filterSeatingByCriteria(seating, filter);
        });
      }
    }
    return {
      list: this.sortList(seatingList, sortType, desc, newGuestCount),
      count: this.seatingService.getSeatingCount(seatingList)
    };
  }

  /**
   * Filter seating list based on included seating list only.
   * @param seating Seating
   * @param includeSeatingList include seating list
   * @returns true if valid seating
   */
  includeSeatingList(seating: Seating, includeSeatingList?: Seating[]): boolean {
    if (includeSeatingList?.length) {
      if (includeSeatingList?.findIndex((x: Seating) => x?.seatingId === seating?.seatingId) !== -1) {
        return true;
      }
    }
    return false;
  }

  /**
   * Exclude seating list
   * @param seating Seating
   * @param excludeSeatingList Exclude seating list
   */
  excludeSeatingList(seating: Seating, excludeSeatingList?: Seating[]): boolean {
    if (seating && excludeSeatingList?.length) {
      if (excludeSeatingList?.findIndex((x: Seating) => x?.seatingId === seating?.seatingId) !== -1) {
        return false;
      }
    }
    return true;
  }

  /**
   * Search seating by keyword
   * @param guest Guest
   * @param keyword Keyword
   */
  searchSeatingByKeyword(seating: Seating, keyword: string): boolean {
    if (this.functionService.search(seating?.name, keyword)) {
      return true;
    } else if (this.functionService.chineseMath(seating.name, keyword)) {
      return true;
    } else {
      const guestList = this.guestService.getSeatingGuest(seating?.name);
      let flag = false;
      guestList?.forEach((guest: Guest) => {
        if (guest?.name?.toLocaleLowerCase()?.indexOf(keyword?.toString()?.toLowerCase()) > -1) {
          flag = true;
          return true;
        } else if (this.functionService.chineseMath(guest?.name, keyword)) {
          flag = true;
          return true;
        }
      });
      if (flag) { return true; }
    }
    return false;
  }

  /**
   * Filter seating by filter conditions
   * @param seating Seating
   * @param filter Filter conditions
   */
  filterSeatingByCriteria(seating: Seating, filter: any): boolean {
    if (filter?.enable) {
      const seatingSummary = this.seatingSummaryService.getSeatingSummary(seating);
      let flag = true;
      if (filter.invitedBy) {
        if (seatingSummary.invitedBy) {
          if (seatingSummary.invitedBy?.findIndex(
            (x: SettingField) => x.custom === filter.invitedBy.custom && x.value === filter.invitedBy.value) === -1) {
            return false;
          }
        } else if (!seating.invitedBy || seating.invitedBy?.findIndex(
          (x: SettingField) => x.custom === filter.invitedBy.custom && x.value === filter.invitedBy.value) === -1) {
          return false;
        }
      }

      if (filter?.category) {
        if (seatingSummary?.category?.length) {
          filter?.category?.forEach((settingField: SettingField) => {
            const index = seatingSummary?.category?.findIndex(
              (x: SettingField) =>
              x.value === settingField?.value && x?.custom === settingField?.custom);
            if (index === -1) {
              flag = false;
            }
          });
          if (!flag) {
            return false;
          }
        } else if (seating.category?.length &&
          (seating.category?.[0]?.custom !== filter.category.custom || seating.category?.[0]?.value !== filter.category.value)) {
          return false;
        }
      }

      if (filter?.dietaryReq) {
        if (seatingSummary?.dietaryReq?.length) {
          filter?.dietaryReq?.forEach((settingField: SettingField) => {
            const index = seatingSummary?.dietaryReq?.findIndex(
              (x: SettingField) =>
              x.value === settingField?.value && x?.custom === settingField?.custom);
            if (index === -1) {
              flag = false;
            }
          });
          if (!flag) {
            return false;
          }
        } else {
          return false;
        }
      }

      if (filter?.specialReq) {
        if (seatingSummary?.specialReq?.length) {
          filter?.specialReq?.forEach((settingField: SettingField) => {
            const index = seatingSummary?.specialReq?.findIndex((x: SettingField) =>
              x?.value === settingField?.value && x?.custom === settingField?.custom);
            if (index === -1) {
              flag = false;
            }
          });
          if (!flag) {
            return false;
          }
        } else {
          return false;
        }
      }

      if (filter?.session) {
        if (seatingSummary?.guestList?.length) {
          filter?.session?.forEach((settingField: SettingField) => {
            const index = seatingSummary?.guestList?.findIndex((guest: Guest) => {
              const sessionIndex = guest?.session?.findIndex((x: SettingField) => {
                return x?.value === settingField?.value && x?.custom === settingField?.custom;
              });
              return sessionIndex === -1 ? false : true;
            });
            if (index === -1) {
              flag = false;
            }
          });
          if (!flag) {
            return false;
          }
        } else {
          return false;
        }
      }

      if (filter?.mobile) {
        if (filter?.mobile === 'assigned') {
          const index = seatingSummary?.guestList?.findIndex((guest: Guest) => {
            return guest?.mobile?.no ? true : false;
          });
          if (index === -1) {
            return false;
          }
        } else if (filter?.mobile === 'not_assign') {
          const index = seatingSummary?.guestList?.findIndex((guest: Guest) => {
            return !guest?.mobile?.no ? true : false;
          });
          if (index === -1) {
            return false;
          }
        }
      }

      if (filter?.group) {
        if (filter.group === 'group' && seating?.count === 1) {
          return false;
        } else if (filter.group === 'individual' && seating?.count > 1) {
          return false;
        }
      }

      if (filter?.reserved) {
        if (filter.reserved === 'reserved' && seating?.reserved !== true) {
          return false;
        } else if (filter.reserved === 'none' && seating?.reserved !== false) {
          return false;
        }
      }

      if (filter?.favorite) {
        if (filter.favorite === 'favorite' && !seating?.favorite) {
          return false;
        } else if (filter.favorite === 'none' && seating?.favorite) {
          return false;
        }
      }

      if (filter?.assign) {
        if (filter.assign === 'assign' && !seatingSummary?.guestList?.length) {
          return false;
        } else if (filter.assign === 'not_assign' && seatingSummary?.guestList?.length) {
          return false;
        }
      }

      if (filter?.seating) {
        if (filter.seating === 'full' && seatingSummary?.guestList?.length < seating?.maxGuest) {
          return false;
        }
        if (filter.seating === 'half' && (!seatingSummary?.guestList?.length || seatingSummary?.guestList?.length >= seating?.maxGuest)) {
          return false;
        }
        if (filter.seating === 'empty' && seatingSummary?.guestList?.length && seatingSummary?.guestList?.length > 1) {
          return false;
        }
        if (filter.seating === 'overload' && seatingSummary?.guestList?.length <= seating?.maxGuest) {
          return false;
        }
      }

      if (filter?.status) {
        if (filter?.status?.attending) {
          if (seatingSummary?.guestList) {
            let existed = false;
            seatingSummary?.guestList?.forEach((guest: Guest) => {
              if (guest?.status?.attending === filter?.status?.attending) {
                existed = true;
                return true;
              }
            });
            if (!existed) {
              return false;
            }
          } else {
            return false;
          }
        }
      }

      if (filter?.createBy) {
        if (!seating?.createBy || !seating?.createBy?.uid || seating.createBy.uid !== filter?.createBy) {
          return false;
        }
      }

      if (filter?.updateBy) {
        if (!seating.updateBy || !seating.updateBy.uid || seating.updateBy.uid !== filter.updateBy) {
          return false;
        }
      }

      if (this.moduleService.currentModule === 'gift') {
        if (filter?.giftType || filter?.amount || filter?.currency || filter?.giftBy) {
          if (seatingSummary.guestList.length) {
            let guestFalseCount = 0;
            seatingSummary?.guestList?.forEach((guest: Guest) => {
              let guestFlag = true;
              if (filter.giftBy && guest?.giftBy?.uid !== filter.giftBy) {
                guestFlag = false;
                guestFalseCount++;
              } else if (guest.giftList?.length) {
                const giftList = this.giftService.getGiftListById(guest.giftList);
                if (giftList?.length) {
                  let falseCount = 0;
                  giftList?.forEach((gift: Gift) => {
                    let giftFlag = true;
                    if (giftFlag && filter?.giftType && gift?.giftType !== filter?.giftType) {
                      giftFlag = false;
                      falseCount++;
                    }
                    if (giftFlag && filter?.currency && gift?.currency !== filter?.currency) {
                      giftFlag = false;
                      falseCount++;
                    }
                    if (giftFlag && filter.amount) {
                      if (giftFlag && gift.giftType !== 'cash') {
                        giftFlag = false;
                        falseCount++;
                      }
                      if (giftFlag && filter.amount.lower && gift.amount < filter.amount.lower) {
                        giftFlag = false;
                        falseCount++;
                      }
                      if (giftFlag && filter.amount.upper && gift.amount > filter.amount.upper) {
                        giftFlag = false;
                        falseCount++;
                      }
                    }
                  });
                  if (falseCount === giftList.length) {
                    guestFlag = false;
                    guestFalseCount++;
                  } else {
                    return true;
                  }
                } else {
                  guestFlag = false;
                  guestFalseCount++;
                }
              } else {
                guestFlag = false;
                guestFalseCount++;
              }
            });
            if (guestFalseCount !== seatingSummary.guestList.length) {
              return true;
            }
          }
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Filter empty seating
   * @param seating Seating
   */
  filterSeatingEmpty(seating: Seating) {
    const seatingSummary = this.seatingSummaryService.getSeatingSummary(seating);
    if (seatingSummary?.guestList?.length) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Sort seating list
   * @param seatingList Seating list
   * @param desc sort by descending
   * @returns sorted seating list
   */
  sortList(seatingList: Seating[], field?: SeatingSortType, desc?: boolean, newGuestCount?: number,): Seating[] {
    if (seatingList) {
      seatingList.sort((a: Seating, b: Seating) => {
        if (field) {
          if (field === 'name' || field === 'count' || field === 'maxGuest') {
            if (a[field] !== b[field]) {
              if (field === 'name') {
                return this.functionService.compare(a.name?.toString().toLowerCase(), b.name?.toString().toLowerCase(), desc);
              } else {
                return this.functionService.compare(a[field], b[field], desc);
              }
            }
          } else if (field === 'createdTime') {
            const timeA: number = a?.createBy?.time?.seconds ? a.createBy.time.seconds : 0;
            const timeB: number = b?.createBy?.time?.seconds ? b.createBy.time.seconds : 0;
            if (timeA !== timeB) {
              return this.functionService.compare(timeA, timeB, desc);
            }
          } else if (field === 'updatedTime') {
            const timeA: number = a?.updateBy?.time?.seconds ? a.updateBy.time.seconds : 0;
            const timeB: number = b?.updateBy?.time?.seconds ? b.updateBy.time.seconds : 0;
            if (timeA !== timeB) {
              return this.functionService.compare(timeA, timeB, desc);
            }
          } else if (field === 'guestCount') {
            const guestCountA = this.guestService.getSeatingGuest(a?.name).length;
            const guestCountB = this.guestService.getSeatingGuest(b?.name).length;
            if (guestCountA !== guestCountB) {
              return this.functionService.compare(guestCountA, guestCountB, desc);
            }
          } else if (field === 'availableSeating') {
            const guestCountA = this.guestService.getSeatingGuest(a?.name).length;
            const guestCountB = this.guestService.getSeatingGuest(b?.name).length;
            const availableA = a.maxGuest - guestCountA;
            const availableB = b.maxGuest - guestCountB;

            if (newGuestCount) {
              const countA = availableA - newGuestCount;
              const countB = availableB - newGuestCount;
              if (countA !== countB) {
                if (countA === 0 && countB === 0) {
                  return 0;
                } else if (countA === 0) {
                  return -1;
                } else if (countB === 0) {
                  return 1;
                } else if (countA < 0 && countB < 0) {
                  return 0;
                } else if (countA < 0) {
                  return 1;
                } else if (countB < 0) {
                  return -1;
                } else if (countA > countB) {
                  return 1;
                } else {
                  return -1;
                }
              }
            } else {
              if (availableA <= 0 && availableB <= 0) {
                return 0;
              } else if (availableA <= 0) {
                return 1;
              } else if (availableB <= 0) {
                return -1;
              } else  {
                return this.functionService.compare(availableA, availableB, desc);
              }
            }
          }
        } else {
          if (a.favorite && b.favorite) {
            return this.functionService.compare(a.name?.toString().toLowerCase(), b.name?.toString().toLowerCase(), desc);
          }
          if (a.favorite) { return -1; }
          if (b.favorite) { return 1; }

          return this.functionService.compare(a.name?.toString().toLowerCase(), b.name?.toString().toLowerCase(), desc);
        }

        return this.functionService.compare(a.name?.toString().toLowerCase(), b.name?.toString().toLowerCase(), false);
      });
    }
    return seatingList;
  }

}
