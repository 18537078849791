import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { PopupService } from 'src/app/services/general/popup.service';
/**
 * Group log service
 */
@Injectable({
  providedIn: 'root'
})
export class GroupLogService implements OnInit, OnDestroy {

  /**
   * Account ID
   */
  private accountId: string;

  /**
   * Constructor
   * @param afs angualr firestore
   */
  constructor(
    private afs: AngularFirestore,
    private popupService: PopupService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Setup Account ID
   * @param accountId Account ID
   */
  async setupAccountId(accountId?: string) {
    this.accountId = accountId;
  }

  /**
   * Get guest log
   * @param guestId guest id
   * @param type log type
   * @returns Observable guest log
   */
  getGroupLog(groupId: string, type?: string): Observable<any[]> {
    if (this.accountId && groupId) {
      let condition = [];
      // if (type === 'info') {
      //   condition = ['new', 'update'];
      // } else if (type === 'checkin') {
      //   condition = ['checkin', 'uncheck'];
      // } else if (type === 'gift') {
      //   condition = ['gift'];
      // } else if (type === 'delete') {
      //   condition = ['delete'];
      // } else if (type === 'invite') {
      //   condition = ['invite'];
      // }
      if (condition.length) {
        return this.afs.collection(`accounts/${ this.accountId }/groups/${ groupId }/log`,
        ref => ref.where('type', 'in', condition)).valueChanges();
      } else {
        return this.afs.collection(`accounts/${ this.accountId }/groups/${ groupId }/log`).valueChanges();
      }
    } else {
      this.popupService.presentActionError();
    }
  }
}
