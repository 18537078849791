import { UrlService } from 'src/app/services/general/url.service';
import { LinkService } from 'src/app/services/general/link.service';
import { ConfigService } from 'src/app/services/general/config.service';
import { LanguageService } from 'src/app/services/general/language.service';
import { DeviceLanguageService } from 'src/app/services/device/device-language.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription, map } from 'rxjs';

import { CreditService } from 'src/app/services/subscription/credit.service';
import { TransactionService } from 'src/app/services/payment/transaction.service';
import { DeviceManageService } from 'src/app/services/device/device-manage.service';
import { DeviceTokenService } from 'src/app/services/device/device-token.service';
import { AccountsListService } from 'src/app/services/accounts/accounts-list.service';
import { UpdateByService } from 'src/app/services/user/update-by.service';
import { ProviderService } from 'src/app/services/user/provider.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { UserStripeCheckoutSession, User } from 'src/app/interfaces/user';
import { StripeStatusService } from '../payment/stripe/stripe-status.service';

import { RefundService } from '../payment/refund.service';
import { FunctionService } from '../general/function.service';


/**
 * User service.
 * Watch / update user database.
 */
@Injectable({
  providedIn: 'root'
})
export class UserService implements OnInit, OnDestroy {

  /**
   * Observable user
   */
  observableUser: any;
  /**
   * UID
   */
  uid: string;
  /**
   * User data
   */
  user: User;
  /**
   * User subscription
   */
  private userSubscription: Subscription;

  /**
   * Constructor
   * @param router router
   * @param translate translate service
   * @param afs angular firestore
   * @param providerService provider service
   * @param accountsListService accounts list service
   * @param deviceService device service
   * @param popupService popup service
   * @param errorService error service
   * @param functionService function service
   */
  constructor(
    private platform: Platform,
    private router: Router,
    private translate: TranslateService,
    private afs: AngularFirestore,
    private providerService: ProviderService,
    private accountsListService: AccountsListService,
    private deviceTokenService: DeviceTokenService,
    private deviceManageService: DeviceManageService,
    private deviceLanguageService: DeviceLanguageService,
    private languageService: LanguageService,
    private updateByService: UpdateByService,
    private transactionService: TransactionService,
    private refundService: RefundService,
    private stripeStatusService: StripeStatusService,
    // private inboxSetupService: InboxSetupService,
    private creditService: CreditService,
    private linkService: LinkService,
    private urlService: UrlService,
    private configService: ConfigService,
    private popupService: PopupService,
    private errorService: ErrorService,
  ) {
    this.observableUser = new BehaviorSubject<User>(this.user);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unwatchUser();
  }

  /**
   * Initial user data.
   * @param uid UID
   */
  async initialUser(uid: string) {
    try {
      await this.platform.ready();
      this.uid = uid;
      this.errorService.uid = this.uid;
      this.configService.initialize();
      this.updateByService.setUid(this.uid);
      this.deviceManageService.setUid(this.uid);
   
      if (this.uid) {
        await this.readUser();
      } else {
        this.user = null;
        this.observableUser.next(this.user);
        this.deviceTokenService.unwatchToken();
        await this.unwatchUser();
      }
    } catch(err: any) {
      console.error(err);
    }
  }

  /**
   * Setup User
   */
  setupUser() {
    this.accountsListService.setUid(this.uid);
    this.transactionService.setUid(this.uid);
    this.creditService.setUid(this.uid);
    this.refundService.setUid(this.uid);
  }

  /**
   * Read user data
   */
  async readUser() {
    if (this.uid) {
      const doc: any = await this.afs.doc(`users/${ this.uid }/`).ref.get()
      .catch( async (err: any) => {
        console.error(err);
        // if (err && err?.toString()?.indexOf('Failed to get document because the client is offline.') !== -1) {
        //   await this.functionService.delay(500);
        //   await this.readUser();
        // }
      });
      if (doc?.exists && doc?.data()) {
        this.user = doc.data() as User;
        this.observableUser.next(this.user);
        await this.watchUser();
        await this.deviceTokenService.tokenPermission();
      }
    }
  }

  /**
   * Watch user data
   */
  async watchUser() {
    if (this.uid && !this.userSubscription) {
      this.setupUser();
      this.userSubscription = this.afs.doc(`users/${ this.uid }`)
      .snapshotChanges().pipe(map(changes => {
        const data: User = changes.payload.data() as User;
        return data;
      })).subscribe({
        next: (user: User) => {
          this.user = user;
          this.observableUser.next(this.user);
          this.checkUser();
          // this.userCheckService.checkUser(user);
        }, error: (err: any) => {
          if (this.errorService.extractError(err)?.toString() === 'FirebaseError: [code=permission-denied]: Missing or insufficient permissions.') {
            this.invalidUserPrompt();
          } else {
            this.errorService.logError(err);
          }
        }
      });
    }
  }

  /**
   * Unwatch user data
   */
  async unwatchUser() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
      this.userSubscription = null;
      this.setupUser();
    }
  }

  async checkUser() {
    if (this.user) {
      this.providerService.setupProviderList(this.user.provider);
      if (this.user?.name && !this.user?.enable) {
        this.invalidUserPrompt();
      }
      if (this.user.language && this.user.language !== this.deviceLanguageService.language) {
        this.deviceLanguageService.setLanguage(this.user.language, true);
      }

      if (this.user?.language) {
        this.languageService.userLanguage = this.user.language;
        this.languageService.observableUserLanguage.next(this.user.language);
      }
      this.checkStripeSession();
    }
  }

  async checkStripeSession() {
    if (this.user?.stripeSession) {
      const userCheckoutSession: UserStripeCheckoutSession = this.user.stripeSession;
      if (!this.linkService.universalLink && !this.urlService.checkUrl(['/payment/stripe-status']) &&
      userCheckoutSession?.sessionId && userCheckoutSession?.currency &&
      userCheckoutSession?.sessionStatus === 'complete' && userCheckoutSession?.paymentStatus &&
      userCheckoutSession?.checkoutParam?.transactionType && userCheckoutSession?.checkoutParam?.platform && userCheckoutSession?.uid) {
        this.stripeStatusService.goStripeStatusPage(
          userCheckoutSession.sessionId,
          userCheckoutSession.currency,
          userCheckoutSession.checkoutParam.transactionType,
          userCheckoutSession.checkoutParam.platform,
          userCheckoutSession.uid,
          userCheckoutSession?.productAccountPageParam,
          userCheckoutSession?.sessionStatus,
          userCheckoutSession?.paymentStatus,
          userCheckoutSession?.creditIdList,
          // userCheckoutSession?.smsUsageId,
          // userCheckoutSession?.smsUnpaidIdList,
          userCheckoutSession?.time,
        );
      }
    }
  }

  /**
   * Invalid user prompt if user not at home page / user logout page.
   * Logout user after prompt.
   */
  async invalidUserPrompt() {
    if (this.router.url && this.router.url !== '/main' && this.router.url !== '/user/logout' && this.router.url !== '/user/delete') {
      const modal = await this.popupService.presentAlert(this.translate.instant('USER.msg.invalid_user'));
      modal.onWillDismiss().then(() => {
        this.router.navigate(['/user/logout']);
      });
    }
  }

}
