import { Menu } from '../interfaces/menu';

/**
 * Root menu
 */
export const RootMenuList: Menu[] = [{
    module: 'user',
    title: 'inbox',
    url: '/tabs-accounts/inbox',
    path: '/inbox/',
    icon: {
        src: './assets/wedding/icon/general/chatbubble-ellipses-outline.svg'
    }
}, {
    module: 'accounts',
    title: 'home',
    url: '/tabs-accounts/list',
    icon: {
        src: './assets/wedding/icon/menu/home.svg'
    }
}, 
{
    module: 'accounts',
    title: 'accounts_add',
    url: '/accounts/add',
    icon: {
        src: './assets/wedding/icon/general/add.svg'
    }
}, 
{
    module: 'user',
    title: 'help',
    url: '/about/help',
    path: '/about/',
    icon: {
        src: './assets/wedding/icon/general/help-buoy-outline.svg'
    }
}, {
    module: 'business',
    title: 'business',
    url: '/business/register',
    icon: {
        src: './assets/wedding/icon/general/storefront-outline.svg'
    }
}, {
    module: 'user',
    title: 'logout',
    url: '/user/logout',
    icon: {
        src: './assets/wedding/icon/menu/power.svg'
    }
}];

/**
 * Account menu list after logged in to accounts account
 */
export const AccountMenuList: Menu[] = [{
    module: 'user',
    title: 'inbox',
    url: '/inbox/home',
    path: '/inbox/',
    icon: {
        src: './assets/wedding/icon/general/chatbubble-ellipses-outline.svg'
    }
}, {
    module: 'accounts',
    title: 'home',
    url: '/account/home',
    icon: {
        src: './assets/wedding/icon/menu/home.svg'
    }
}, {
    module: 'guest',
    title: 'guest_list',
    url: '/tabs-guest/home',
    path: '/tabs-guest/',
    icon: {
        src: './assets/wedding/icon/menu/guestlist.svg'
     }
}, {
    module: 'blast',
    title: 'blast',
    url: '/blast/home',
    path: '/blast/',
    icon: {
        name: 'chatbox-ellipses-outline'
     }
}, {
    module: 'checkin',
    title: 'checkin',
    url: '/tabs-checkin/home',
    path: '/tabs-checkin/',
    icon: {
        src: './assets/wedding/icon/menu/checkin.svg'
    }
}, {
    module: 'gift',
    title: 'gift',
    url: '/tabs-gift/home',
    path: '/tabs-gift/',
    icon: {
        src: './assets/wedding/icon/menu/gift.svg'
    }
}, {
    module: 'account',
    title: 'setting',
    url: '/account/setting',
    icon: {
        src: './assets/wedding/icon/menu/setting.svg'
    }
}, {
    module: 'account',
    title: 'accountTask',
    url: '/account/accountTask',
    icon: {
        src: './assets/wedding/icon/menu/accountTask.svg'
    }
}, {
    module: 'trash',
    title: 'trash_bin',
    url: '/tabs-trash/guest',
    path: '/tabs-trash/',
    icon: {
        src: './assets/wedding/icon/menu/trash.svg'
    }
}, {
    module: 'user',
    title: 'help',
    url: '/about/help',
    path: '/about/',
    icon: {
        src: './assets/wedding/icon/general/help-buoy-outline.svg'
    }
}, {
    module: 'accounts',
    title: 'switch',
    url: '/accounts/logout',
    icon: {
        src: './assets/wedding/icon/menu/power.svg'
    }
}];
