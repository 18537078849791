<ion-header class="ion-no-border header-light">
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      <ng-container *ngIf="giftType">
        <ng-container *ngIf="giftType === 'cash'">
          {{ 'LIST.gift.cash' | translate }}
          <ng-container *ngIf="currency">&nbsp;({{ currency }})</ng-container>
        </ng-container>
        <ng-container *ngIf="giftType === 'other'">{{ 'LIST.gift.other' | translate }}</ng-container>
      </ng-container>
      <ng-container *ngIf="statisticType">
        <ng-container *ngIf="statisticType === 'recorded'">{{ 'GIFT.lbl.recorded_gift' | translate }}</ng-container>
        <ng-container *ngIf="statisticType === 'received'">{{ 'GIFT.lbl.received_gift_checkin' | translate }}</ng-container>
      </ng-container>
    </ion-title>
  </ion-toolbar>
  <ion-toolbar class="ion-no-padding background-transparent" *ngIf="invitedByList?.length > 1">
    <ion-segment class="ion-padding-horizontal" [(ngModel)]="invitedBy" scrollable>
      <ion-segment-button class="seg-btn-invitedBy ion-no-padding" [value]="''">
        <ion-text class="ion-text-wrap">
          <div>{{'LBL.all' | translate }}</div>
        </ion-text>
      </ion-segment-button>
      <ng-container *ngIf="invitedByList">
        <ion-segment-button class="seg-btn-invitedBy ion-no-padding" [value]="invitedBy" *ngFor="let invitedBy of invitedByList">
          <ion-text class="ion-text-wrap">
            <ng-container *ngIf="invitedBy.custom">{{ invitedBy.value }}</ng-container>
            <ng-container *ngIf="!invitedBy.custom">
              <div>{{ 'LIST.invited_by.' + invitedBy.value | translate }}</div>
            </ng-container>
          </ion-text>
        </ion-segment-button>
      </ng-container>
    </ion-segment>
  </ion-toolbar>
  <ion-toolbar class="ion-no-padding" color="light">
    <app-gift-statistic-item [statisticType]="statisticType" [setGiftType]="giftType" [currency]="currency" [setGiftType]="giftType" [currency]="currency" [setInvitedBy]="invitedBy" [name]="'LBL.total' | translate" [all]="true" [showNumber]="true"></app-gift-statistic-item>
  </ion-toolbar>
</ion-header>
<ion-content color="light" #content>
  <swiper-container class="swiper ion-margin-horizontal" (swiperactiveindexchange)="onActiveIndexChange($event)" navigation="true">
    <swiper-slide>
      <div class="full-width">
        <ion-item color="light" class="ion-text-center ion-item-no-padding ion-padding-horizontal" lines="none">
          <!-- <ion-button class="ion-no-margin" slot="start" fill="clear" (click)="prevSlide()" disabled="true">
            <ion-icon color="dark" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
          </ion-button> -->
          <ion-text class="center">
            <ion-icon color="dark" class="icon-small vertical-middle" src="./assets/wedding/icon/guest/category.svg"></ion-icon>
            {{ 'LBL.category' | translate }}
          </ion-text>
          <!-- <ion-button class="ion-no-margin" slot="end" fill="clear" (click)="nextSlide()">
            <ion-icon color="dark" src="./assets/wedding/icon/general/chevron-forward.svg"></ion-icon>
          </ion-button> -->
        </ion-item>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="full-width">
        <ion-item color="light" class="ion-text-center ion-item-no-padding ion-padding-horizontal" lines="none">
          <!-- <ion-button class="ion-no-margin" slot="start" fill="clear" (click)="prevSlide()">
            <ion-icon color="dark" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
          </ion-button> -->
          <ion-label class="center">
            <ion-icon color="dark" class="icon-small vertical-middle" src="./assets/wedding/icon/guest/dietary-req.svg"></ion-icon>
            {{ 'GUEST.lbl.dietary_req' | translate }}
          </ion-label>
          <!-- <ion-button class="ion-no-margin" slot="end" fill="clear" (click)="nextSlide()">
            <ion-icon color="dark" src="./assets/wedding/icon/general/chevron-forward.svg"></ion-icon>
          </ion-button> -->
        </ion-item>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="full-width">
        <ion-item color="light" class="ion-text-center ion-item-no-padding ion-padding-horizontal" lines="none">
          <!-- <ion-button class="ion-no-margin" slot="start" fill="clear" (click)="prevSlide()">
            <ion-icon color="dark" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
          </ion-button> -->
          <ion-text class="center">
            <ion-icon color="dark" class="icon-small vertical-middle" src="./assets/wedding/icon/guest/special-req.svg"></ion-icon>
            {{ 'GUEST.lbl.special_req' | translate }}
          </ion-text>
          <!-- <ion-button class="ion-no-margin" slot="end" fill="clear" (click)="nextSlide()" disabled="true">
            <ion-icon color="dark" src="./assets/wedding/icon/general/chevron-forward.svg"></ion-icon>
          </ion-button> -->
        </ion-item>
      </div>
    </swiper-slide>
  </swiper-container>
  <ng-container *ngIf="listType === 'category'">
    <div class="full-width viewport-div" *ngIf="categoryList?.length">
      <cdk-virtual-scroll-viewport [itemSize]="84">
        <ion-list class="full-width-height ion-padding-bottom">
          <div *cdkVirtualFor="let category of categoryList; trackBy: trackByFn;">
            <app-gift-statistic-item [category]="category" [statisticType]="statisticType" [setGiftType]="giftType" [currency]="currency" [setInvitedBy]="invitedBy" [name]="category.custom ? category.value : 'LIST.category.' + category.value | translate" [showNumber]="true"></app-gift-statistic-item>
          </div>
        </ion-list>
      </cdk-virtual-scroll-viewport>
    </div>
  </ng-container>
  <ng-container *ngIf="listType === 'dietaryReq'">
    <div class="full-width viewport-div" *ngIf="dietaryReqList?.length">
      <cdk-virtual-scroll-viewport [itemSize]="84">
        <ion-list class="full-width-height ion-padding-bottom">
          <div *cdkVirtualFor="let dietaryReq of dietaryReqList; trackBy: trackByFn;">
            <app-gift-statistic-item [dietaryReq]="dietaryReq" [statisticType]="statisticType" [setGiftType]="giftType" [currency]="currency" [setInvitedBy]="invitedBy" [name]="dietaryReq.custom ? dietaryReq.value : 'LIST.dietary_req.' + dietaryReq.value | translate" [showNumber]="true"></app-gift-statistic-item>
          </div>
        </ion-list>
      </cdk-virtual-scroll-viewport>
    </div>
  </ng-container>
  <ng-container *ngIf="listType === 'specialReq'">
    <div class="full-width viewport-div" *ngIf="specialReqList?.length">
      <cdk-virtual-scroll-viewport [itemSize]="84">
        <ion-list class="full-width-height ion-padding-bottom">
          <div *cdkVirtualFor="let specialReq of specialReqList; trackBy: trackByFn;">
            <app-gift-statistic-item [specialReq]="specialReq" [statisticType]="statisticType" [setGiftType]="giftType" [currency]="currency" [setInvitedBy]="invitedBy" [name]="specialReq.custom ? specialReq.value : 'LIST.special_req.' + specialReq.value | translate" [showNumber]="true"></app-gift-statistic-item>
          </div>
        </ion-list>
      </cdk-virtual-scroll-viewport>
    </div>
  </ng-container>
</ion-content>
