import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SupportTicket } from 'src/app/interfaces/chat';
import { Inbox } from 'src/app/interfaces/inbox';
import { InboxCountService } from 'src/app/services/inbox/inbox-count.service';
import { InboxService } from 'src/app/services/inbox/inbox.service';
import { SupportAdminService } from 'src/app/services/support/support-admin.service';
import { SupportTicketService } from 'src/app/services/support/support-ticket.service';
import { UserService } from 'src/app/services/user/user.service';
import { InboxType } from 'src/app/types/inbox';

@Component({
  selector: 'app-inbox-count',
  templateUrl: './inbox-count.component.html',
  styleUrls: ['./inbox-count.component.scss'],
})
export class InboxCountComponent  implements OnInit, OnDestroy {

  @Input() showNumber: boolean;
  @Input() inboxType: InboxType;
  @Input() showBadge: boolean;
  @Input() badgeColor: string;
  @Input() outputString: boolean;

  @Input() dotTop: string;

  unreadCount: number | string;

  private supportSubscription: Subscription;

  private inboxSubscription: Subscription;

  private releaseNoteStatusSubscription: Subscription;

  
  constructor(
    private userService: UserService,
    private inboxService: InboxService,
    private inboxCountService: InboxCountService,
    private supportAdminService: SupportAdminService,
    private supportTicketService :SupportTicketService,
  ) { }

  ngOnInit() {
    this.setup();
  }

  ngOnDestroy(): void {
    this.unwatch();
  }

  unwatch() {
    if (this.supportSubscription) {
      this.supportSubscription.unsubscribe();
      this.supportSubscription = null;
    }

    if (this.inboxSubscription) {
      this.inboxSubscription.unsubscribe();
      this.inboxSubscription = null;
    }

    if (this.releaseNoteStatusSubscription) {
      this.releaseNoteStatusSubscription.unsubscribe();
      this.releaseNoteStatusSubscription = null;
    }
  }

  setup() {
    if (this.userService?.uid) {
      if (!this.inboxType) {
        this.watchSupport();
        this.watchInbox();
        this.watchReleaseNoteStatus();
      } else if (this.inboxType === 'support') {
        this.watchSupport();
      } else {
        this.watchInbox();
        this.watchReleaseNoteStatus();
      }
    } else {
      setTimeout(() => {
        this.setup();
      }, 500);
    }
  }

  watchSupport() {
    if (this.supportAdminService.checkAdmin()) {
      if (!this.supportSubscription) {
        this.supportSubscription = this.supportAdminService.observableSupportTicketList.subscribe((supportTicketList: SupportTicket[]) => {
          this.getUnreadCount();
        });
      }
    } else {
      if (!this.supportSubscription) {
        this.supportSubscription = this.supportTicketService.observableSupportTicketList.subscribe((supportTicketList: SupportTicket[]) => {
          this.getUnreadCount();
        });
      }
    }
  }

  watchInbox() {
    if (!this.inboxSubscription) {
      this.inboxSubscription = this.inboxService.observableList.subscribe((inboxList: Inbox[]) => {
        this.getUnreadCount();
      });
    }
  }

  watchReleaseNoteStatus() {
    if (!this.releaseNoteStatusSubscription) {
      this.releaseNoteStatusSubscription = this.inboxService.observableReleaseNoteStatusList.subscribe(() => {
        this.getUnreadCount();
      });
    }
  }

  getUnreadCount(): number | string {
    this.unreadCount = this.inboxCountService.getUnreadCount(this.inboxType);

    if (this.outputString) {
      this.unreadCount = this.unreadCount.toString();
    }
    return this.unreadCount;
  }

}
