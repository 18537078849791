import { Locality } from 'src/app/interfaces/general';
import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { FunctionService } from 'src/app/services/general/function.service';
import { Coordinate } from 'src/app/interfaces/general';

/**
 * Google map JS sdk variable
 */
declare var google: any;

/**
 * Google map service to generate google map.
 */
@Injectable({
  providedIn: 'root'
})
export class GoogleMapService implements OnInit, OnDestroy {

  /**
   * Google map
   */
  map: any;
  /**
   * Map element
   */
  private mapElement: any;
  /**
   * coordinate for marker
   */
  private coordinate: Coordinate;
  /**
   * Google map API key
   */
  private apiKey = 'AIzaSyDeeOVRHeYboeZrVg9Y0JHQeCmVY4_D7wg';

  /**
   * Constructor
   * @param functionService function service
   */
  constructor(
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Initial google map
   * @param mapElement map element to display google map
   * @param coordinate coordinate for center of map & marker
   */
  async init(mapElement: any, coordinate?: Coordinate): Promise<any> {
    this.mapElement = mapElement;

    if (coordinate && !this.functionService.isEmpty(coordinate)) {
      this.coordinate = coordinate;
    } else {
      this.coordinate = {
        lat: 0,
        lng: 0
      };
    }

    return await this.loadGoogleMaps();
  }

  /**
   * Load google map and append google map JS api link to body.
   */
  loadGoogleMaps(): Promise<any> {
    return new Promise((resolve) => {
      if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
        console.log('Google maps JavaScript needs to be loaded.');
        window['mapInit'] = () => {
          this.initMap().then(() => {
            resolve(true);
          });
        };

        const script = document.createElement('script');
        script.id = 'googleMaps';
        script.async = true;

        if (this.apiKey) {
          script.src = 'http://maps.google.com/maps/api/js?key=' + this.apiKey + '&callback=mapInit&libraries=places';
        } else {
          script.src = 'http://maps.google.com/maps/api/js?callback=mapInit';
        }
        document.body.appendChild(script);
      } else {
        this.initMap();
        resolve(true);
      }
      // this.addConnectivityListeners();
    });
  }

  /**
   * Initial map with display setting.
   */
  initMap(): Promise<any> {
    return new Promise((resolve) => {
      const mapOptions = {
        mapTypeControl: true,
        center: new google.maps.LatLng(this.coordinate.lat, this.coordinate.lng),
        zoom: 8,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      this.map = new google.maps.Map(this.mapElement, mapOptions);

      resolve(true);
    });
  }

  generateLocality(addressComponents: google.maps.GeocoderAddressComponent[]): Locality {
    const locality: Locality = {
      country: ''
    };
    if (addressComponents?.length) {
      addressComponents?.forEach((address: google.maps.GeocoderAddressComponent) => {
        if (address?.types) {
          if (address?.types?.indexOf('country') !== -1) {
            if (address?.short_name && address?.short_name !== locality.country) {
              locality.country = address.short_name;
            }
          } else if (address?.types?.indexOf('administrative_area_level_1') !== -1) {
            if (address.long_name && address.long_name !== locality.state) {
              locality.state = address.long_name;
            }
          } else if (address?.types?.indexOf('administrative_area_level_2') !== -1) {
            if (address.long_name && address.long_name !== locality.district) {
              locality.district = address.long_name;
            }
          } else if (address?.types?.indexOf('administrative_area_level_2') === -1 &&
          address?.types?.indexOf('administrative_area_level_3') !== -1) {
            if (address.long_name && address.long_name !== locality.district) {
              locality.district = address.long_name;
            }
          } else if (address?.types?.indexOf('locality') !== -1) {
            if (address.long_name && address.long_name !== locality.city) {
              locality.city = address.long_name;
            }
          } else if (address?.types?.indexOf('sublocality_level_1') !== -1) {
            if (address.long_name && address.long_name !== locality.town) {
              locality.town = address.long_name;
            }
          } else if (address?.types?.indexOf('postal_code') !== -1) {
            if (address.long_name && address.long_name !== locality.postalCode) {
              locality.postalCode = address.long_name;
            }
          }
        }
      });
    }
    return locality;
  }

  generateLocationName(addressComponents: google.maps.GeocoderAddressComponent[]): string {
    let name = '';
    if (addressComponents?.length) {
      addressComponents?.forEach((address: google.maps.GeocoderAddressComponent) => {
        if (address?.types) {
          if (address?.types?.indexOf('point_of_interest') !== -1) {
            if (address.long_name && address.long_name !== name) {
              name = address.long_name;
            }
          }
        }
      });
    }
    return name;
  }

}
