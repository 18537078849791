<ng-container *ngIf="!newMode">
  <ion-header>
    <ion-toolbar color="light">
      <ion-buttons slot="start">
        <ion-button (click)="discard()">
          <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>
        <app-offline-indicator></app-offline-indicator>
        {{ 'SETTING.seating.title' | translate }}
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ng-container *ngIf="true then list"></ng-container>
  </ion-content>
  <ion-footer>
    <ion-toolbar>
      <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" (click)="submit()">
        {{ 'BTN.save' | translate }}
      </ion-button>
    </ion-toolbar>
  </ion-footer>
</ng-container>
<ng-container *ngIf="newMode">
  <ng-container *ngIf="true then list"></ng-container>
</ng-container>

<ng-template #list>
  <ion-list class="background-transparent">
    <ion-card class="ion-no-margin" color="light" button>
      <ion-item class="background-transparent" lines="none">
        <ion-icon color="medium" src="./assets/wedding/icon/guest/seating-outline.svg" slot="start"></ion-icon>
        <ion-label class="font-14 ion-text-wrap" color="dark" [innerHtml]="'SETTING.seating.seating_type.title' | translate"></ion-label>
        <ion-select [(ngModel)]="seatingType" [compareWith]="compareWith" (ionChange)="seatingTypeChanged()" placeholder="" justify="end">
          <ng-container *ngFor="let type of seatingTypeList">+
            <ion-select-option [value]="type" *ngIf="type?.value">
              <ion-text class="font-14" color="dark">
                <ng-container *ngIf="type?.custom">
                  {{ type.value }}
                </ng-container>
                <ng-container *ngIf="!type?.custom">
                  {{ 'LIST.seating_type.' + type.value | translate }}
                </ng-container>
              </ion-text>
            </ion-select-option>
          </ng-container>
        </ion-select>
      </ion-item>

      <ng-container *ngIf="seatingType?.value === 'others' || seatingType?.custom">
        <ion-item class="background-transparent" lines="none">
          <ion-label position="stacked" color="dark"></ion-label>
          <ion-input class="prompt-input" type="text" inputmode="text" autocapitalize="words" [(ngModel)]="seatingTypeOther" (ionChange)="seatingTypeOtherChanged()"></ion-input>
          <div class="error-div">
            <ng-container *ngIf="!seatingTypeOther">
              <div class="error-message">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ 'VALIDATION.required' | translate: { field: 'SETTING.seating.seating_type.title' | translate } }}
              </div>
            </ng-container>
          </div>
        </ion-item>
      </ng-container>
    </ion-card>

    <ion-card class="ion-no-margin" (click)="presentGuestPerSeatingPrompt()" color="light" button>
      <ion-item class="background-transparent" lines="none" button detail>
        <ion-icon src="./assets/wedding/icon/tabs/guest-outline.svg" slot="start"></ion-icon>
        <ion-label class="ion-no-margin">
          <ion-text class="font-14 ion-text-wrap" [innerHtml]="'SEATING.lbl.max_guest' | translate: { seating: seatingTypeName }" color="dark"></ion-text>
          <ion-label class="font-10 ion-text-wrap" [innerHtml]="'SETTING.seating.guest_per_seating.msg' | translate: { seating: seatingTypeName }" color="medium"></ion-label>
        </ion-label>
        <ion-note class="" color="dark" slot="end">{{ guestPerSeating }}</ion-note>
      </ion-item>
    </ion-card>
  </ion-list>
</ng-template>
