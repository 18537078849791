import { GAPurchase, GAItem } from 'src/app/interfaces/analytics';
import { AnalyticsService } from 'src/app/services/general/analytics.service';
import { DebugModeService } from 'src/app/services/general/debug-mode.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { Subscription, BehaviorSubject, map, firstValueFrom } from 'rxjs';

import { FunctionService } from 'src/app/services/general/function.service';
import { Item, PaymentRef, StripeData, Transaction } from 'src/app/interfaces/transaction';
import { UpdateByService } from 'src/app/services/user/update-by.service';
import { ErrorService } from 'src/app/services/general/error.service';

import { Price } from 'src/app/interfaces/product';
import { UpdateBy } from 'src/app/interfaces/user';
import { Locality } from 'src/app/interfaces/general';
import { Timezone } from 'src/app/interfaces/database';
import { NavigationExtras, Router } from '@angular/router';
import { TransactionType } from 'src/app/types/transaction';
import { Voucher } from 'src/app/interfaces/voucher';
import { PlatformType } from 'src/app/types/device';
import { TranslateService } from '@ngx-translate/core';
import { RefundService } from './refund.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionService implements OnInit, OnDestroy {

  /**
   * transaction list
   */
  transactionList: Transaction[];
  /**
   * observable transaction list
   */
  observableTransactionList: any;
  /**
   * user id
   */
  private uid: string;
  /**
   * user transaction subscription
   */
  private userTransactionSubscription: Subscription;

  /**
   * Constructor
   * @param afs angularfire store
   * @param functionService function service
   */
  constructor(
    private router: Router,
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private translate: TranslateService,
    private refundService: RefundService,
    private debugModeService: DebugModeService,
    private functionService: FunctionService,
    private updateByService: UpdateByService,
    // private onlineService: OnlineService,
    private popupService: PopupService,
    private errorService: ErrorService,
    private analyticService: AnalyticsService
  ) {
    this.transactionList = [];
    this.observableTransactionList = new BehaviorSubject<Transaction[]>(this.transactionList);
  }
  
  ngOnInit(): void {
    
  }

  ngOnDestroy() {
    this.unwatchUserTransaction();
  }

  /**
   * Set UID
   * @param uid uid
   */
  setUid(uid?: string) {
    this.uid = uid;
    this.transactionList = [];
    this.observableTransactionList.next(this.transactionList);
    if (this.uid) {
      // this.readTransaction();
      this.watchUserTransaction();
    } else {
      this.unwatchUserTransaction();
    }
  }

  /**
   * Watch user transaction
   */
  async watchUserTransaction() {
    if (this.uid) {
      if (!this.userTransactionSubscription) {
        this.userTransactionSubscription = this.afs.collection(`transactions`,
        ref => ref.where('createBy.uid', '==', this.uid))
        .snapshotChanges().pipe(map(changes => changes.map( a => {
          const data: Transaction = a.payload.doc.data() as Transaction;
          if (!data.transactionId) { data.transactionId = a.payload.doc.id; }
          if (data?.debug) {
            // console.log(data);
          }
          // if (data.transactionId) 
          return data;
        }).sort((a: Transaction, b: Transaction) => {
          return this.functionService.compare(a?.createBy?.time?.seconds, b?.createBy?.time?.seconds, true);
        }))).subscribe({
          next: (transactionList: Transaction[]) => {
            this.transactionList = transactionList;
            this.observableTransactionList.next(this.transactionList);
          }, error: (err: any) => {
            this.errorService.logError(err);
          }
        });
      }
    }
  }

  /**
   * unwatch user transaction
   */
  async unwatchUserTransaction() {
    if (this.userTransactionSubscription) {
      this.userTransactionSubscription.unsubscribe();
      this.userTransactionSubscription = null;
    }
  }

  async readTransaction() {
    if (this.uid) {
      try {
        const querySnapshot = await firstValueFrom(this.afs.collection('transactions', ref => ref.where('createBy.uid', '==', this.uid)).get());
        const transactionList: Transaction[] = querySnapshot.docs.map(doc => {
          const data = doc.data() as Transaction;
          if (!data.transactionId) {
            data.transactionId = doc.id;
          }
          return data;
        }).sort((a: Transaction, b: Transaction) => {
          return this.functionService.compare(
            a?.createBy?.time?.seconds,
            b?.createBy?.time?.seconds,
            true
          );
        });
  
        this.transactionList = transactionList;
        this.observableTransactionList.next(this.transactionList);
      } catch (error) {
        this.errorService.logError(error);
      }
    }
  }

  getTransactionList(transactionType: TransactionType) {
    return [ ...this.transactionList ].filter((transaction: Transaction) => {
      if (transaction?.transactionId && transaction?.type) {
        if (transactionType === 'account') {
          if (transaction.type === 'account') {
            return true;
          }
        } else if(transactionType === 'blast') {
          if (transaction.type === 'blast' || transaction.type === 'sms') {
            return true;
          }
        }
      }
      return false;
    });
  }

  getTransactionbyId(transactionId: string) {
    return [ ...this.transactionList ].filter((transaction: Transaction) => {
      return transaction?.transactionId && transaction.transactionId && transactionId;
    });
  }

  /**
   * Search transaction
   * @returns Transaction list
   */
  searchTransaction(): Transaction[] {
    return this.transactionList;
  }

  getTransactionById(transactionId: string): Transaction {
    if (transactionId) {
      const index = this.transactionList?.findIndex((x: Transaction) => x?.transactionId === transactionId );
      if (index !== -1) {
        return this.transactionList[index];
      }
    }
    return null;
  }

  getPaymentMethod(paymentRef: PaymentRef) {
    let method = '';
    if (paymentRef?.type === 'android-playstore' || paymentRef?.method === 'android-playstore') {
      method = this.translate.instant('PAYMENT.type.android-playstore');
    } else if (paymentRef?.type === 'ios-appstore' || paymentRef?.method === 'ios-appstore') {
      method = this.translate.instant('PAYMENT.type.ios-appstore');
    } else if (paymentRef?.type === 'free') {
      if (paymentRef?.method === 'redeem') {
        method = this.translate.instant('PAYMENT.type.redemption');
      } else {
        method = this.translate.instant('PAYMENT.type.free');
      }
    } else if (paymentRef?.type === 'offline' || paymentRef?.type === 'stripe') {
      if (paymentRef.method) {
        if (paymentRef.method === 'offline') {
          method = this.translate.instant('PAYMENT.type.offline');
        } else {
          if (paymentRef.method === 'other') {
            method = this.translate.instant('PAYMENT.type.other');
          } else {
            if (method !== 'card') {
              method = method + paymentRef.method.toUpperCase();
            }
            if (paymentRef?.detail) {
              const detail: StripeData = paymentRef.detail as StripeData;
              if (detail.brand && detail.card) {
                method = detail.brand.toUpperCase() + ' (' + detail.card.toUpperCase() + ')';
              } else if (detail.fpx) {
                method = method + ' (' + this.functionService.replaceAll(detail.fpx, '_', ' ').toUpperCase() + ')';
              }
            }
          }
        }
      }
    }
    
    return method;
  }

  getTransactionAmount(transaction: Transaction): number {
    if (transaction?.transactionId && transaction?.payment?.amount && transaction?.payment?.currency) {
      const refund = this.refundService.getRefundAmountByTransactionId(transaction.transactionId, transaction.payment.currency);
      if (refund >= transaction.payment.amount) {
        return -transaction.payment.amount;
      }
      
      return transaction.payment.amount - refund;
    }
    return 0;
  }

  /**
   * Create transaction fns call
   * @param payment payment
   * @param itemList item list
   * @param gift gift flag
   * @param paymentRef payment reference
   */
  async createAccountTransactionCall(payment: Price, itemList: Item[], voucher: Voucher, gift: boolean, upgrade: boolean, trial: boolean,
                              paymentRef: PaymentRef, language: string, locality: Locality, platform: PlatformType,
                              timezone: Timezone, accountId?: string, createBy?: UpdateBy) {
    // if (this.onlineService.isOnline()) {
      if (!createBy?.uid) {
        createBy = this.updateByService.getUpdateBy();
      }
      
      const param = {
        type: 'account',
        payment,
        paymentRef,
        itemList,
        voucher,
        gift,
        upgrade,
        trial,
        createBy,
        language,
        locality,
        platform,
        timezone,
        accountId,
        debugMode: this.debugModeService.debugMode,
      };

      await this.fns.httpsCallable('createIapTransactionCall')(param).toPromise().then(async (response) => {
        if (response?.creditIdList?.length) {
          const items: GAItem[] = [];
          itemList?.forEach((item: Item) => {
            if (item?.productId) {
              items.push({
                item_id: item.productId,
                item_category: 'account',
                item_name: item?.productName,
                quantity: item?.qty,
                price: item?.payment?.amount,
                currency: item?.payment?.currency,
              });
            }
          });
          const gaPurchase: GAPurchase = {
            currency: payment?.currency,
            value: payment?.amount,
            transaction_id: response.creditIdList[0],
            coupon: '',
            tax: 0,
            items,
          };
          await this.analyticService.iap(gaPurchase);
          await this.goPaymentResultPage(response.creditIdList[0], upgrade, trial, accountId, true);
        } else if (response?.error !== 'duplicate') {
          await this.popupService.presentActionError();
        }
        await this.functionService.delay(2000);
      }).catch((err: any) => {
        this.errorService.logError(err);
        this.popupService.presentActionError();
        this.popupService.dismissLoading();
      });
    // }
  }
  
  // async () {
  //   await this.popupService.presentLoading();
  //   const createBy: UpdateBy = {
  //     uid: '',
  //     uuid: '',
  //     time: this.functionService.firebaseServerTime
  //   };
  //   const payment: Price = {
  //     currency: 'MYR',
  //     amount: 4475,
  //   };
  //   const paymentRef: PaymentRef = {
  //     method: 'fpx',
  //     type: 'offline',
  //   };
  //   const item: Item = {
  //     productId: 'wedding_account_full',
  //     productType: 'account',
  //     productName: 'Wedding Account Full Access',
  //     oriPrice: {
  //       currency: 'MYR',
  //       amount: 299,
  //     },
  //     payment: {
  //       currency: 'MYR',
  //       amount: 44.75,
  //     },
  //     price: {
  //       currency: 'MYR',
  //       amount: 199,
  //     },
  //     qty: 100,

  //   };
  //   const voucher: Voucher = {"qtyPerUser":0,"qty":0,"voucherId":"","unlimited":true,"productId":"wedding_account_full","enable":true,"percent":0.55,"updateBy":{"uid":"3SAfCuWtlWXAvSnk4at4zezSct72","time":{"_seconds":1663817443,"_nanoseconds":173000000},"uuid":"695C441D-BA09-404D-96CC-70677E629B16"},"partnerId":"Gzs3vNuyVtGGs11kZNCU","minQty":10,"productType":"account","maxQty":0,"createBy":{"uid":"3SAfCuWtlWXAvSnk4at4zezSct72","uuid":"695C441D-BA09-404D-96CC-70677E629B16","time":{"_seconds":1663817443,"_nanoseconds":173000000}}};

  //   const itemList: Item[] = [];
  //   itemList.push(item);
  //   const locality: Locality = {
  //     country: 'MY'
  //   };
  //   const param = {
  //     type: 'account',
  //     payment,
  //     paymentRef,
  //     voucher,
  //     itemList,
  //     totalQty: 100,
  //     gift: false,
  //     upgrade: false,
  //     trial: false,
  //     bulk: true,
  //     createBy,
  //     language: 'en',
  //     locality,
  //     platform: 'web',
  //     timezone: this.timezoneService.getUserTimezone(),
  //     accountId: '',
  //     debugMode: false,
  //   };
  //     await this.fns.httpsCallable('')(param).toPromise().then(async (response) => {
        
  //     }).catch((err: any) => {
  //       this.errorService.logError(err);
  //       this.popupService.presentActionError();
  //     });
  //     this.popupService.dismissLoading();
  // }

  /**
   * Go payment result page
   * @param creditIdList credit id list
   * @param upgradeMode upgrade mode
   * @param accountId Account ID
   */
  async goPaymentResultPage(creditId?: string, upgradeMode?: boolean, upgradeTrial?: boolean, accountId?: string, iap?: boolean) {
    const navigationExtras: NavigationExtras = {
      state: {
        result: 'success',
        creditId,
        upgradeMode,
        upgradeTrial,
        accountId,
        iap,
      }
    };
    await this.router.navigate(['/subscription/payment-result'], navigationExtras);
  }

}
