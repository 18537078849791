<ng-container *ngIf="width">
  <ng-container *ngIf="seatingView === 'grid'">
    <ion-card class="ion-no-margin ion-text-center vertical-center" 
    [class]="((!seating?.count && !seating.name) || seating?.count > 1) ? 'free-table-card' : 'table-card'"
    [class.table-card-selected]="(editMode || assignMode || swapMode) && selectedSeatingList?.indexOf(seating.seatingId) !== -1"
    [style.--grid-size]="gridSize + 'px'" [style.--grid-name-height]="gridNameHeight + 'px'"
    [color]="color" (click)="click(true)" [button]="disableClick ? false : true">
      <ion-item class="ion-item-no-padding seating-card background-transparent" lines="none">
        <ion-label class="full-width ion-text-center ion-no-margin">
          <div>
            <ion-label class="font-8 font-italic" color="dark">
              <ng-container *ngIf="seating.favorite then favoriteBlock"></ng-container>
            </ion-label>
          </div>
          <div *ngIf="seating.count > 1">
            <ng-container *ngIf="true then freeSeatingBlock"></ng-container>
          </div>
          <div class="ion-text-wrap" *ngIf="seating.name">
            <ng-container *ngIf="true then seatingNameBlock"></ng-container>
          </div>
          <div class="ion-text-wrap" *ngIf="!seating.name">
            <ng-container *ngIf="true then unassignedNameBlock"></ng-container>
          </div>
          <div class="text-ellipsis">
            <ion-label class="invitedBy-lbl font-8 text-ellipsis" style="margin: 2px 0;" color="dark">
              <ng-container *ngIf="true then invitedByBlock"></ng-container>
            </ion-label>
            <ion-label class="category-lbl font-8 text-ellipsis" style="margin: 2px 0;" color="dark">
              <ng-container *ngIf="true then categoryBlock"></ng-container>
            </ion-label>
          </div>
          <div *ngIf="seating.maxGuest">
            <ion-label class="font-14" [class]="module !== 'gift' ? 'font-bold' : ''" color="dark">
              <ng-container *ngIf="true then guestCountBlock"></ng-container>
            </ion-label>
          </div>
          <div *ngIf="!seating.maxGuest && !seating.name">
            <ion-label class="font-14" [class]="module !== 'gift' ? 'font-bold' : ''" color="dark">
              <ng-container *ngIf="true then totalCountBlock"></ng-container>
            </ion-label>
          </div>
        </ion-label>
      </ion-item>
    </ion-card>
  </ng-container>

  <ng-container *ngIf="seatingView === 'list'">
    <ion-card class="ion-text-left" [style.width]="width ? width > 800 ? (800 -32) + 'px' : (width - 32) + 'px' : '100%'" [class.table-card-selected]="(editMode || assignMode || swapMode) && selectedSeatingList?.indexOf(seating.seatingId) !== -1" [button]="disableClick ? false : true">
      <ion-card-header [color]="color" (click)="click(true)">
        <ion-item class="ion-item-no-padding seating-card background-transparent" lines="none">
          <ion-label class="full-width ion-text-left ion-no-margin">
            <div *ngIf="seating.count > 1">
              <ng-container *ngIf="true then freeSeatingBlock"></ng-container>
            </div>
            <div *ngIf="seating.name">
              <ng-container *ngIf="seating.favorite then favoriteBlock"></ng-container>
              <ng-container *ngIf="true then seatingNameBlock"></ng-container>
            </div>
            <div *ngIf="!seating.name">
              <ng-container *ngIf="true then unassignedNameBlock"></ng-container>
            </div>
            <div>
              <ion-label class="font-10 font-italic line-120" color="dark">
                <ng-container *ngIf="true then invitedByBlock"></ng-container>
                <ng-container *ngIf="(seatingSummary?.invitedBy?.length || seating?.invitedBy?.length)">&nbsp;|&nbsp;</ng-container>
                <ng-container *ngIf="true then categoryBlock"></ng-container>
              </ion-label>
            </div>
          </ion-label>
          <ion-card slot="end" class="ion-text-center vertical-center ion-no-margin guest-count-card" color="light">
            <ion-label class="vertical-center">
              <div class="center ion-text-center" *ngIf="seating.maxGuest">
                <ion-label class="center ion-text-center font-14" [class]="module !== 'gift' ? 'font-bold' : ''" color="dark">
                  <ng-container *ngIf="true then guestCountBlock"></ng-container>
                </ion-label>
              </div>
              <div *ngIf="!seating.maxGuest && !seating.name">
                <ion-label class="font-14" [class]="module !== 'gift' ? 'font-bold' : ''" color="dark">
                  <ng-container *ngIf="true then totalCountBlock"></ng-container>
                </ion-label>
              </div>
              <div class="center ion-text-center" *ngIf="module === 'guest' || module === 'checkin'">
                <ion-icon class="icon-small margin-5" color="dark" style="margin-bottom: 0;" src="./assets/wedding/icon/guest/dietary-req.svg" *ngIf="seatingSummary?.dietaryReq?.length"></ion-icon>
                <ion-icon class="icon-small margin-5" color="dark" style="margin-bottom: 0;" src="./assets/wedding/icon/guest/special-req.svg" *ngIf="seatingSummary?.specialReq?.length"></ion-icon>
              </div>
            </ion-label>
          </ion-card>
        </ion-item>
      </ion-card-header>
      <ion-card-content class="card-content ion-no-padding" color="grey" style="overflow: hidden;" [style.height.px]="cardHeight" *ngIf="expand && guestList?.length">
        <div class="full-width viewport-div" [style.height.px]="cardHeight" *ngIf="guestList?.length">
          <cdk-virtual-scroll-viewport [itemSize]="estimateItemSize" [minBufferPx]="200" [maxBufferPx]="200" class="ion-content-scroll-host">
            <ion-list class="background-transparent" [style.width]="width ? (width - 32) + 'px' : '100%'" #list>
              <app-guest-card *cdkVirtualFor="let guest of guestList; trackBy: trackByFn; let index = index;" [style.height.px]="itemHeightFn(guest)" [mode]="module === 'checkin' ? module : null" [setGuest]="guest" [editMode]="editMode" [index]="index" [disableClick]="disableClick" [selectedGuestList]="selectedGuestList" [selectedGroupList]="selectedGroupList" (click)="click()" ></app-guest-card>
            </ion-list>
          </cdk-virtual-scroll-viewport>
        </div>
      </ion-card-content>
    </ion-card>
  </ng-container>

  <ng-template #seatingNameBlock>
    <!-- <app-text-fit [text]="seating.name" [minFontSize]="10" [maxFontSize]="30" [width]="gridSize - 10" [height]="gridNameHeight"></app-text-fit> -->
    <ng-container *ngIf="seatingView === 'grid'">
      <div class="" style="overflow:hidden;" [style.width.px]="gridSize - 10" [style.height.px]="gridNameHeight">
        <div class="font-bold" [style.width.px]="gridSize - 10" [fittext]="true"
        [modelToWatch]="seating.name"
        [maxFontSize]="38"
        [minFontSize]="8"
        [activateOnResize]="true">{{seating.name}}</div>
      </div>

      <!-- <div class="font-bold" color="dark" appTextFit [minFontSize]="10" [maxFontSize]="30" [width]="gridSize - 5" [height]="gridNameHeight" [style.max-height.px]="gridNameHeight">
        {{ seating.name }}
      </div> -->
      <!-- <div class="grid-name-block" [style.width.px]="gridSize - 10" [style.height.px]="gridNameHeight">
        <fit-text [minFontSize]="8" [maxFontSize]="38" [width]="(gridSize - 10) + 'px'" [height]="gridNameHeight + 'px'" [calcOnResize]="true" style="justify-content: center; align-items: center;">
          <div class="font-bold" color="dark" [style.width.px]="gridSize - 10" [style.max-height.px]="gridNameHeight">{{ seating.name }}</div>
        </fit-text>
      </div> -->
      <!-- <div class="grid-name-block" [style.max-height.px]="gridNameHeight">
        <fit-line [minFontSize]="10" [maxFontSize]="38" [calcOnResize]="true">
          <div class="font-bold" color="dark" [style.max-height.px]="gridNameHeight">{{ seating.name }}</div>
        </fit-line>
      </div> -->
    </ng-container>
    <ng-container *ngIf="seatingView === 'list'">
      <!-- <div class="grid-name-block" [style.width.px]="width - 32 - 40 - 10 - 50 - 10 - 16" [style.max-height.px]="50">
        <fit-text [minFontSize]="10" [maxFontSize]="38" [width]="(width - 32 - 40 - 10 - 50 - 10 - 16) + 'px'" [height]="50 + 'px'" [calcOnResize]="true" style="justify-content: center; align-items: center;">
          <div class="font-bold" color="dark" [style.width.px]="width - 32 - 40 - 10 - 50 - 10 - 16" [style.max-height.px]="50">{{ seating.name }}</div>
        </fit-text>
      </div> -->
      <fit-line [minFontSize]="10" [maxFontSize]="30" [calcOnResize]="true">
        <div class="font-bold" color="dark" [style.max-height.px]="50" style="padding-right: 10px;">{{ seating.name }}</div>
      </fit-line>
      <!-- <div class="vertical-center" style="overflow:hidden;" [style.width.px]="width - 32 - 40 - 10 - 50 - 10 - 16" [style.height.px]="50">
        <div class="font-bold" [style.width.px]="width - 32 - 40 - 10 - 50 - 10 - 16" [fittext]="true"
        [modelToWatch]="seating.name"
        [maxFontSize]="38"
        [minFontSize]="10"
        [activateOnResize]="true">{{seating.name}}</div>
      </div> -->
    </ng-container>
    
    
    <!-- <div class="grid-name-block" [style.max-height.px]="gridNameHeight">
      <fit-line [minFontSize]="10" [maxFontSize]="38" [calcOnResize]="true">
        <div class="font-bold" color="dark" [style.max-height.px]="gridNameHeight">{{ seating.name }}</div>
      </fit-line>
    </div> -->

    <!-- <div class="grid-name-block" [style.width.px]="gridSize - 10" [style.max-height.px]="gridNameHeight">
      <fit-text [minFontSize]="10" [maxFontSize]="38" [calcOnResize]="true" [width]="gridSize - 10" [height]="gridNameHeight">
        <div class="font-bold" color="dark" [style.width.px]="gridSize - 10" [style.max-height.px]="gridNameHeight">{{ seating.name }}</div>
      </fit-text>
    </div> -->
    
    
  </ng-template>

  <ng-template #unassignedNameBlock>
    <div>
      <ion-label class="font-bold" color="dark">{{ 'SEATING.lbl.not_yet_assigend_guest' | translate }}</ion-label>
    </div>
    <!-- <fit-line [minFontSize]="10" [maxFontSize]="seatingView === 'grid' ? 20 : 16" [calcOnResize]="true">
      
    </fit-line> -->
  </ng-template>

  <ng-template #guestCountBlock>
    <ng-container *ngIf="module === 'checkin'">
      {{ attendedCount ? attendedCount : 0 }} / {{ seatingSummary?.guestList?.length ? seatingSummary.guestList.length : 0 }}
    </ng-container>
    <ng-container *ngIf="module === 'guest'">
      {{ seatingSummary?.guestList?.length ? seatingSummary.guestList.length : 0 }} / {{ seating.maxGuest }}
    </ng-container>
    <ng-container *ngIf="module === 'gift'">
      <ion-icon class="font-14 margin-5 vertical-middle" color="dark" src="./assets/wedding/icon/gift/gift.svg"></ion-icon><ion-text class="font-12 font-bold" *ngIf="seatingSummary?.giftSummary?.other">{{ seatingSummary.giftSummary.other }}</ion-text>
      <br *ngIf="seatingView === 'list'" />
      <ng-container *ngIf="seatingView === 'grid'">&nbsp;&nbsp;</ng-container>
      <ion-icon class="font-14 margin-5 vertical-middle" color="dark" src="./assets/wedding/icon/gift/cash.svg"></ion-icon><ion-text class="font-12 font-bold" *ngIf="seatingSummary?.giftSummary?.cash">{{ seatingSummary.giftSummary.cash }}</ion-text>
    </ng-container>
  </ng-template>

  <ng-template #totalCountBlock>
    {{ seatingSummary?.guestList?.length ? seatingSummary.guestList.length : 0 }}
  </ng-template>
  
  <ng-template #invitedByBlock>
    <ng-container *ngIf="seatingSummary?.invitedBy?.length else seatingInvitedByBlock">
      {{ getSettingField('invited_by', seatingSummary.invitedBy) }}
    </ng-container>
    <ng-template #seatingInvitedByBlock>
      <ng-container *ngIf="seating?.invitedBy?.length">
        {{ getSettingField('invited_by', seating.invitedBy) }}
      </ng-container>
    </ng-template>
    <!-- <ng-container *ngIf="seatingSummary?.invitedBy?.length else seatingInvitedByBlock">
      <ng-container *ngFor="let invitedBy of seatingSummary.invitedBy; let i = index;">
        <ng-container *ngIf="invitedBy.value && i === 0">
          <ng-container *ngIf="!invitedBy.custom">
            <ion-icon class="icon-small" [style]="seatingView === 'grid' ? 'vertical-align: bottom' : ''" src="./assets/wedding/img/avatar/groom.svg" *ngIf="invitedBy.value === 'groom' || invitedBy.value === 'mutual' || seatingSummary.invitedBy.length > 1"></ion-icon>
            <ion-icon class="icon-small" [style]="seatingView === 'grid' ? 'vertical-align: bottom' : ''" src="./assets/wedding/img/avatar/bride.svg" *ngIf="invitedBy.value === 'bride' || invitedBy.value === 'mutual' || seatingSummary.invitedBy.length > 1"></ion-icon>
          </ng-container>
          <ng-container *ngIf="invitedBy.custom">
            {{ invitedBy.value }}
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #seatingInvitedByBlock>
      <ng-container *ngFor="let invitedBy of seating.invitedBy; let i = index;">
        <ng-container *ngIf="invitedBy.value">
          <ng-container *ngIf="!invitedBy.custom">
            <ion-icon class="icon-small" src="./assets/wedding/img/avatar/groom.svg" *ngIf="invitedBy.value === 'groom' || invitedBy.value === 'mutual'"></ion-icon>
            <ion-icon class="icon-small" src="./assets/wedding/img/avatar/bride.svg" *ngIf="invitedBy.value === 'bride' || invitedBy.value === 'mutual'"></ion-icon>
          </ng-container>
          <ng-container *ngIf="invitedBy.custom">
            {{ invitedBy.value }}
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-template> -->
  </ng-template>
  
  <ng-template #categoryBlock>
    <ng-container *ngIf="seatingSummary?.category?.length else seatingCategoryBlock">
      {{ getSettingField('category', seatingSummary.category) }}
    </ng-container>
    <ng-template #seatingCategoryBlock>
      <ng-container *ngIf="seating?.category?.length else emptyCategoryBlock">
        {{ getSettingField('category', seating.category) }}
      </ng-container>
      <ng-template #emptyCategoryBlock>
        <ng-container class="ion-text-uppercase font-8">
          <ng-container *ngIf="seating.reserved">{{ 'SEATING.lbl.reserved' | translate }}</ng-container>
          <ng-container *ngIf="!seating.reserved">{{ 'LIST.occupancy.empty' | translate }}</ng-container>
        </ng-container>
      </ng-template>
    </ng-template>
  </ng-template>

  <ng-template #favoriteBlock>
    <ion-icon class="font-16" style="margin-right: 5px; vertical-align: bottom;" src="./assets/wedding/icon/general/star.svg"></ion-icon>
  </ng-template>
  <ng-template #freeSeatingBlock>
    <span class="ion-text-uppercase font-10 free-seating">
      {{ 'SEATING.lbl.free_seating' | translate }}
    </span>
  </ng-template>
</ng-container>
