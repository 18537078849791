import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { CheckinSettingService } from 'src/app/services/checkin/checkin-setting.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { CheckinSetting } from 'src/app/interfaces/setting';

/**
 * Setting checkin component
 */
@Component({
  selector: 'app-setting-checkin',
  templateUrl: './setting-checkin.component.html',
  styleUrls: ['./setting-checkin.component.scss'],
})
export class SettingCheckinComponent implements OnInit, OnDestroy {

  /**
   * Setting form
   */
  settingForm: FormGroup;
  /**
   * Check-in setting
   */
  checkinSetting: CheckinSetting;

  forceUpdate: boolean;
  /**
   * Check-in setting subscription
   */
  private checkinSettingSubscription: Subscription;

  /**
   * Constructor
   * @param formBuilder form builder
   * @param modalController modal controller
   * @param translate translate service
   * @param checkinSettingService check-in setting service
   * @param popupService popup service
   */
  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private checkinSettingService: CheckinSettingService,
    private popupService: PopupService,
  ) { }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.unwatchCheckinSetting();
  }

  /**
   * Before view enter
   */
  ionViewWillEnter() {
    this.setupForm();
    this.watchCheckinSetting();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatchCheckinSetting();
  }

  /**
   * Watch check-in setting
   */
  async watchCheckinSetting() {
    if (!this.checkinSettingSubscription) {
      this.checkinSettingSubscription = this.checkinSettingService.observableCheckinSetting.subscribe((checkinSetting: CheckinSetting) => {
        this.checkinSetting = checkinSetting;
        this.setupValue();
      });
    }
    
  }

  /**
   * Unwatch check-in setting
   */
  async unwatchCheckinSetting() {
    if (this.checkinSettingSubscription) {
      this.checkinSettingSubscription.unsubscribe();
      this.checkinSettingSubscription = null;
    }
  }

  /**
   * Setup profile form
   */
  setupForm() {
    this.settingForm = this.formBuilder.group({
      giftRecord: new FormControl(false),
      giftDetail: new FormControl(false),
      promptCheckin: new FormControl(false),
      promptGift: new FormControl(false),
      partialCheckin: new FormControl(false),
      scanSound: new FormControl(false),
      pendingGuest: new FormControl(false),
      notAttendingGuest: new FormControl(false),
    });
  }

  /**
   * Setup value
   */
  setupValue() {
    if (!this.checkinSetting) {
      this.checkinSetting = this.checkinSettingService.getDefaultCheckinSetting();
    }
    if (this.checkinSetting && this.settingForm?.value) {
      Object.keys(this.checkinSetting)?.forEach((key: string) => {
        if (this.settingForm.controls[key] && this.settingForm.value[key] !== this.checkinSetting[key]) {
          this.settingForm.controls[key].setValue(this.checkinSetting[key]);
        }
      });
    }
  }

  /**
   * Dismiss checkin setting modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { modal.dismiss(); }
    }
  }

  /**
   * Submit
   */
  async submit() {
    if (this.settingForm.valid) {
      await this.popupService.presentLoading();
      await this.checkinSettingService.saveCheckInSetting(this.settingForm.value);
      await this.dismissModal();
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
  }
}
