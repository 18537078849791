<ion-card class="ion-padding ion-text-left ion-no-margin">
  <ng-container *ngIf="giftSummary">
    <div class="margin-5 font-bold">{{ giftSummary.cash + giftSummary.other }} {{ 'GIFT.lbl.recorded_gift' | translate }}</div>
    <ion-grid class="full-width ion-no-margin ion-no-padding" fixed>
      <ion-row class="ion-no-padding">
        <ion-col><ion-icon class="icon-small margin-5 vertical-middle" src="./assets/wedding/icon/gift/cash.svg"></ion-icon> {{ 'LIST.gift.cash' | translate }} &nbsp;<ion-text class="icon-small font-bold">{{ giftSummary.cash }}</ion-text>&nbsp;<ion-text class="font-10">{{ 'LBL.pcs' | translate | lowercase }}</ion-text></ion-col>
        <ion-col ><ion-icon class="icon-small margin-5 vertical-middle" src="./assets/wedding/icon/gift/gift.svg"></ion-icon> {{ 'LIST.gift.other' | translate }} &nbsp;<ion-text class="icon-small font-bold">{{ giftSummary.other }}</ion-text>&nbsp;<ion-text class="font-10">{{ 'LBL.pcs' | translate | lowercase }}</ion-text> &nbsp; </ion-col>
      </ion-row>
    </ion-grid>
    <ng-container *ngIf="giftSummary?.amount?.length">
      <div class="margin-5" *ngFor="let amount of giftSummary.amount">
        {{ amount.amount | currency: amount.currency : 'symbol'}}
      </div>
    </ng-container>
  </ng-container>
</ion-card>
