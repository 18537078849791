export const WebCheckinHourList: any[] = [
    {
      value: 0,
      text: '0',
    },
    {
      value: 1,
      text: '1',
    },
    {
      value: 2,
      text: '2',
    },
    {
      value: 3,
      text: '3',
    },
    {
      value: 4,
      text: '4',
    },
    {
      value: 5,
      text: '5',
    },
    {
      value: 6,
      text: '6',
    },
    {
      value: 7,
      text: '7',
    },
    {
      value: 8,
      text: '8',
    },
    {
      value: 9,
      text: '9',
    },
    {
      value: 10,
      text: '10',
    },
    {
      value: 11,
      text: '11',
    },
    {
      value: 12,
      text: '12',
    },
    {
      value: 24,
      text: '24',
    },
];

export const WebCheckinMinuteList: any[] = [
    {
      value: 0,
      text: '00',
    },
    {
      value: 15,
      text: '15',
    },
    {
      value: 30,
      text: '30',
    },
    {
      value: 45,
      text: '45',
    },
];