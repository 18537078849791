import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { VisitorAttachment } from 'src/app/interfaces/visitor';
import { AttachmentType } from 'src/app/types/general';
import { AccountInfoService } from '../account/account-info.service';

import { ErrorService } from './error.service';

import { LinkService } from './link.service';
import { PopupService } from './popup.service';
import { VisitorSettingService } from '../visitor/visitor-setting.service';
import { VisitorManageService } from '../visitor/visitor-manage.service';
import { WebsiteDetailService } from '../website/website-detail.service';

import { WebsiteAttachmentService } from '../website/website-attachment.service';
import { WebsiteVenueService } from '../website/website-venue.service';

@Injectable({
  providedIn: 'root'
})
export class FileService implements OnInit, OnDestroy {

  constructor(
    private afStorage: AngularFireStorage,
    private translate: TranslateService,
    private actionSheetController: ActionSheetController,
    private visitorSettingService: VisitorSettingService,
    private visitorManageService: VisitorManageService,
    private websiteVenueService: WebsiteVenueService,
    private accountInfoService: AccountInfoService,
    private websiteAttachmentService: WebsiteAttachmentService,
    private popupService: PopupService,
    private linkService: LinkService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  async selectFile(type: AttachmentType, index?: number, save?: boolean) {
    // if (!save) {
    //   this.setupAttachment(type);
    // }
    
    if (type) {
      let header = '';
      let deleteBtn = this.visitorSettingService.getAttachmentByType(type) ? true : false;

      if (type === 'floorplan') {
        header = this.translate.instant('VISITOR.setting.attachment.floorplan.title');
        if (this.websiteVenueService.websiteVenue?.attachment?.floorplan?.attachmentUrl || this.websiteAttachmentService.attachment?.floorplan?.base64 || this.visitorSettingService.pageSetting?.floorplan?.url) {
          deleteBtn = true;
        }
      } else if (type === 'menu') {
        header = this.translate.instant('VISITOR.setting.attachment.menu.title');
        if (this.websiteVenueService.websiteVenue?.attachment?.menu?.attachmentUrl || this.websiteAttachmentService.attachment?.menu?.base64 || this.visitorSettingService.pageSetting?.menu?.url) {
          deleteBtn = true;
        }
      } else if (type === 'attachment') {
        header = this.translate.instant('VISITOR.setting.attachment.title');
        if (this.visitorSettingService.pageSetting?.attachment?.length) {
          deleteBtn = true;
        }
      } else if (type === 'dressCode') {
        header = this.translate.instant('ATTACHMENT.list.dressCode');
        // if (this.websiteVenueService.websiteVenue?.attachment?.dressCode?.attachmentUrl || this.websiteAttachmentService.attachment?.dressCode?.base64) {
        //   deleteBtn = true;
        // }
      } else if (type === 'parking') {
        header = this.translate.instant('ATTACHMENT.list.parking');
        if (this.websiteVenueService.websiteVenue?.attachment?.parking?.attachmentUrl || this.websiteAttachmentService.attachment?.parking?.base64) {
          deleteBtn = true;
        }
      }
      //  else if (type === 'itinerary') {
      //   header = this.translate.instant('ATTACHMENT.list.itinerary');
      //   if (this.websiteDetailService.websiteVenue?.attachment?.itinerary?.attachmentUrl || this.websiteAttachmentService.attachment?.itinerary?.base64) {
      //     deleteBtn = true;
      //   }
      // }
      await this.presentActionSheet(type, header, deleteBtn, index, save);
    }
  }

  async presentActionSheet(type: AttachmentType, header?: string, deleteBtn?: boolean, index?: number, save?: boolean) {
    const buttons: any[] = [];
    buttons.push({
      text: this.translate.instant('FILES.picker'),
      handler: () => {
        this.filePicker(type, index, save);
      }
    });
    
    if (deleteBtn) {
      let previewBtn = this.websiteAttachmentService.attachment?.[type]?.base64 ? false : true;
      if (previewBtn) {
        buttons.push({
          text: this.translate.instant('BTN.preview'),
          handler: async () => {
            this.previewFile(type);
          }
        });
      }

      buttons.push({
        text: this.translate.instant('CRUD.delete'),
        handler: async () => {
          await this.popupService.presentLoading();
          if (this.websiteAttachmentService.attachment?.[type]) {
            this.websiteAttachmentService.attachment[type].base64 = '';
            this.websiteAttachmentService.attachment[type].attachmentUrl = { url: '', type: '' };
            this.websiteAttachmentService.attachment[type].mimeType = '';
            this.websiteAttachmentService.attachment[type].fileName = '';
          }
          if (save) {
            await this.saveDb(type, '', '', index);
            // if (type === 'floorplan' || type === 'menu') {
            //   await this.saveDb(type, '', '', index);
            // } else {
            //   await this.saveDb(type, '', '', index);
            // }
          }
          this.popupService.dismissLoading();
        }
      });
    }
    buttons.push({
      text: this.translate.instant('BTN.cancel'),
      role: 'cancel',
      handler: () => {
      }
    });
    const actionSheet = await this.actionSheetController.create({
      header,
      buttons
    });
    actionSheet.present();
  }

  async previewFile(type: AttachmentType) {
    await this.popupService.presentLoading();
    if (type === 'floorplan') {
      if (this.visitorSettingService.pageSetting?.floorplan?.url) {
        this.openFile(this.visitorSettingService.pageSetting.floorplan.url, this.visitorSettingService.pageSetting.floorplan.mimeType);
      } else if (this.websiteVenueService.websiteVenue?.attachment?.floorplan?.attachmentUrl?.url) {
        this.openFile(this.websiteVenueService.websiteVenue.attachment.floorplan.attachmentUrl.url, this.websiteVenueService.websiteVenue.attachment.floorplan.mimeType);
      }
    } else if (type === 'menu') {
      if (this.visitorSettingService.pageSetting?.menu?.url) {
        this.openFile(this.visitorSettingService.pageSetting.menu.url, this.visitorSettingService.pageSetting.menu.mimeType);
      } else if (this.websiteVenueService.websiteVenue?.attachment?.menu?.attachmentUrl?.url) {
        this.openFile(this.websiteVenueService.websiteVenue.attachment.menu.attachmentUrl.url, this.websiteVenueService.websiteVenue.attachment.menu.mimeType);
      }
    } else if (type === 'attachment') {
      if (this.visitorSettingService.pageSetting?.attachment?.length && this.visitorSettingService.pageSetting?.attachment?.[0]?.url) {
        this.openFile(this.visitorSettingService.pageSetting.attachment[0].url, this.visitorSettingService.pageSetting.attachment[0].mimeType);
      }
    } else if (type === 'dressCode') {
      // if (this.websiteVenueService.websiteVenue?.attachment?.dressCode?.attachmentUrl?.url) {
      //   this.openFile(this.websiteVenueService.websiteVenue.attachment.dressCode.attachmentUrl.url, this.websiteVenueService.websiteVenue.attachment.dressCode.mimeType);
      // }
    } else if (type === 'parking') {
      if (this.websiteVenueService.websiteVenue?.attachment?.parking?.attachmentUrl?.url) {
        this.openFile(this.websiteVenueService.websiteVenue.attachment.parking.attachmentUrl.url, this.websiteVenueService.websiteVenue.attachment.parking.mimeType);
      }
    }
    //  else if (type === 'itinerary') {
    //   if (this.websiteVenueService.websiteVenue?.attachment?.itinerary?.attachmentUrl?.url) {
    //     this.openFile(this.websiteVenueService.websiteVenue.attachment.itinerary.attachmentUrl.url, this.websiteVenueService.websiteVenue.attachment.itinerary.mimeType);
    //   }
    // }
    this.popupService.dismissLoading();
  }

  async filePicker(type: AttachmentType, index?: number, save?: boolean, types: string[] = ['image/*', 'application/pdf']) {
    const result = await FilePicker.pickFiles({
      types,
      limit: 1,
      readData: true,
    });

    if (result?.files?.length && result?.files?.[0]?.data && result.files[0].mimeType) {
      const file = result.files[0];
      if (file?.size > 11000000) {
        this.popupService.presentAlert(this.translate.instant('FILES.error.file_size', { size: 10 }));
      } else if (!this.validateFileType(file.mimeType, types)) {
        this.popupService.presentAlert(this.translate.instant('FILES.error.file_type'));
      } else {
        const base64 = 'data:' + file.mimeType + ';base64,' + file.data;
        if (!this.websiteAttachmentService.attachment) {
          this.websiteAttachmentService.attachment = {};
        }
        if (!this.websiteAttachmentService.attachment?.[type]) {
          this.websiteAttachmentService.attachment[type] = {
            title: '',
            attachmentType: type,
            enable: true,
            base64,
            mimeType: file.mimeType,
            fileName: file?.name ? file.name : '',
          };
        } else {
          this.websiteAttachmentService.attachment[type].base64 = base64;
          this.websiteAttachmentService.attachment[type].mimeType = file.mimeType;
          this.websiteAttachmentService.attachment[type].fileName = file?.name ? file.name : '';
        }

        if (save) {
          await this.uploadFile(type, base64, file.mimeType, save, index);
        }
      }
    } else {
      this.popupService.presentAlert(this.translate.instant('FILES.error.error'));
    }
  }

  async validateFileType(fileType: string, supportedType: string[]) {
    if (fileType) {
      supportedType?.forEach((type: string) => {
        type = type.replace('/*', '/');
        if (fileType.indexOf(type) === 0) {
          return true;
        }
      });
    }
    return false;
  }

  async uploadFile(type: AttachmentType, base64: string, mimeType: string, save: boolean, index?: number) {
    const storageRef = this.afStorage.storage.ref();
    const imageRef = storageRef.child('attachment').child(this.accountInfoService.accountId).child(type).child(type);
    return await imageRef.putString(base64, 'data_url').then(async (snapshot) => {
      return await snapshot.ref.getDownloadURL().then(async (url) => {
        if (url) {
          if (save) {
            await this.saveDb(type, url, mimeType, index);
          }
          return url;
        }
        // if (!hideSuccess) {
        //   this.successMsg();
        // }
        return '';
      });
    }, (err: any) => {
      this.errorService.logError(err);
      this.successMsg(true);
      return '';
    });
  }

  async deleteFile(type: AttachmentType, save?: boolean, index?: number) {
    // this.setupAttachment(type);
    if (this.websiteAttachmentService.attachment?.[type]) {
      if (this.websiteAttachmentService.attachment?.[type]?.attachmentUrl && save) {
        const storageRef = this.afStorage.storage.ref();
        const imageRef = storageRef.child('attachment').child(this.accountInfoService.accountId).child(type).child(type);
        await imageRef.delete();
      }
      this.websiteAttachmentService.attachment[type].base64 = '';
      this.websiteAttachmentService.attachment[type].attachmentUrl = {
        url: '',
        type: '',
      };
      this.websiteAttachmentService.attachment[type].mimeType = '';
      this.websiteAttachmentService.attachment[type].fileName = '';
    }
  }

  async saveDb(type: AttachmentType, url: string, mimeType: string, index?: number) {
    let flag = true;
    const setting = { ...this.visitorSettingService.getPageSetting() };
    const attachment: VisitorAttachment = {
      id: type,
      title: {
        custom: false,
        value: type,
      },
      enable: url ? true : false,
      mimeType,
      type,
      url,
    };
    if (type === 'floorplan') {
      setting.floorplan = attachment;
    } else if (type === 'menu') {
      setting.menu = attachment;
    } else if (type === 'attachment') {
      setting.attachment = [];
      if (url) {
        setting.attachment.push(attachment);
      }
    } else {
      flag = false;
    }

    if (flag) {
      await this.visitorManageService.savePageSetting({ setting });
      this.successMsg();
    }
  }

  async successMsg(fail?: boolean) {
    this.popupService.dismissLoading();
    if (fail) {
      this.popupService.saveFailToast();
    } else {
      this.popupService.saveSuccessToast();
    }
  }

  async openFile(url: string, mimeType?: string) {
    if (url && mimeType) {
      mimeType = mimeType.toLowerCase()
      if (mimeType === 'application/pdf') {
        this.linkService.openUrl(url);
      } else if (mimeType.indexOf('image/') === 0) {
        this.linkService.openUrl(url);
      } else {
        this.linkService.openUrl(url);
      }
      
    }
  }


}
