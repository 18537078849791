import { FunctionService } from './function.service';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { AccountInfoService } from 'src/app/services/account/account-info.service';

import { Language } from 'src/app/interfaces/database';
import { LanguageList } from 'src/app/commons/language';
import { DeviceLanguageService } from 'src/app/services/device/device-language.service';
import { BehaviorSubject } from 'rxjs';

/**
 * Language service
 */
@Injectable({
  providedIn: 'root'
})
export class LanguageService implements OnInit, OnDestroy {

  userLanguage: string;
  /**
   * Device lanaguage
   */
  // deviceLanguage: string;
  /**
   * Language list
   */
  languageList: Language[] = LanguageList;
  /**
   * default langauge
   */
  defaultLanguage: Language = {
    code: 'en',
    name: 'English'
  };

  translation: any;

  observableUserLanguage: any;

  /**
   * Constructor
   * @param accountInfoService account info service
   * @param userService user service
   */
  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private accountInfoService: AccountInfoService,
    // private userService: UserService,
    private deviceLanguageService: DeviceLanguageService,
    private functionService: FunctionService,
  ) {
    this.setupTranslation();
    this.observableUserLanguage = new BehaviorSubject<string>(this.userLanguage);
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
  }

  /**
   * Get language code
   * @param language language
   * @returns langauge code
   */
  getLanguageCode(language: Language): string {
    return language?.code ? language.code : '';
  }

  /**
   * Get language by code
   * @param langCode language code
   * @returns language obj
   */
  getLanguageByCode(langCode: string): Language {
    if (langCode) {
      const index = this.languageList?.findIndex((x: Language) => x?.code === langCode);
      if (index !== -1) {
        return this.languageList[index];
      }
    }
    return null;
  }

  async readDeviceLanguage(): Promise<Language> {
    const langCode: string = this.deviceLanguageService?.getDeviceLanguage();
    if (langCode && this.getLanguageByCode(langCode)) {
      return this.getLanguageByCode(langCode);
    }
    return null;
  }
  /**
   * Get device language by IP address.
   * @returns language obj
   */
  getDeviceLanguage(): Language {
    const langCode: string = this.deviceLanguageService?.getDeviceLanguage();
    if (langCode && this.getLanguageByCode(langCode)) {
      return this.getLanguageByCode(langCode);
    }
    return this.defaultLanguage;
  }

  /**
   * Get user account language, alternate to device language if not present.
   * @returns language obj
   */
  getUserLanguage(): Language {
    if (this.userLanguage && this.getLanguageByCode(this.userLanguage)) {
      return this.getLanguageByCode(this.userLanguage);
    }
    return this.getDeviceLanguage();
  }

  /**
   * Get wedding account language, alternate to user language if not present.
   * @returns language obj
   */
  getAccountLanguage(): Language {
    const langCode: string = this.accountInfoService?.accountInfo?.language;
    if (langCode && this.getLanguageByCode(langCode)) {
      return this.getLanguageByCode(langCode);
    }
    return this.getUserLanguage();
  }

  /**
   * Check if current language code is valid
   * @param languageCode Language code
   * @returns true if valid language code
   */
  checkValidLanguage(languageCode: string): boolean {
    const index = this.languageList?.findIndex((language: Language) => language?.code === languageCode);
    if (index !== -1) {
      return true;
    }
    return false;
  }

  async setupTranslation() {
    await this.platform.ready();
    this.languageList?.forEach((lang: Language) => {
      if (lang?.code) {
        const translate = this.functionService.cloneDeep(this.translate);
        const subscription = translate.getTranslation(lang.code).subscribe((res: any) => {
          if (!this.translation) {
            this.translation = {};
          }
          if (!this.translation?.[lang.code]) {
            this.translation[lang.code] = {};
          }
          this.translation[lang.code] = res;
          subscription.unsubscribe();
        });
      }
    });
  }

  getTranslation(langCode: string) {
    if (!langCode) {
      langCode = this.getUserLanguage()?.code;
    }
    if (this.translation?.[langCode]) {
      return this.translation[langCode];
    }
    return null;
  }
}
