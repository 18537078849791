import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { CheckinSettingService } from 'src/app/services/checkin/checkin-setting.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { GroupService } from 'src/app/services/group/group.service';
import { GroupManageService } from 'src/app/services/group/group-manage.service';
import { GroupListService } from 'src/app/services/group/group-list.service';
import { GroupCheckService } from 'src/app/services/group/group-check.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { GiftPromptComponent } from 'src/app/components/gift/gift-prompt/gift-prompt.component';
import { CheckinBulkComponent } from 'src/app/components/checkin/checkin-bulk/checkin-bulk.component';
import { CheckinBulkGiftComponent } from 'src/app/components/checkin/checkin-bulk-gift/checkin-bulk-gift.component';

import { CheckinSetting } from 'src/app/interfaces/setting';
import { UpdateByService } from 'src/app/services/user/update-by.service';
import { Guest } from 'src/app/interfaces/guest';
import { Group } from 'src/app/interfaces/group';
import { VisitorRealtimeService } from '../visitor/visitor-realtime.service';
import { CheckinMethodType } from 'src/app/types/guest';

/**
 * Checkin guest service
 */
@Injectable({
  providedIn: 'root'
})
export class CheckinService implements OnInit, OnDestroy {

  /**
   * Checkin setting
   */
  private checkinSetting: CheckinSetting;
  /**
   * Checkin setting subscription
   */
  private checkinSettingSubscription: Subscription;

  /**
   * Constructor
   * @param translate translate service
   * @param modalController modal controller
   * @param guestService guest service
   * @param guestManageService guest manage service
   * @param groupListService group list service
   * @param groupCheckService group check service
   * @param popupService popup service
   * @param functionService function service
   */
  constructor(
    private translate: TranslateService,
    private modalController: ModalController,
    private moduleService: ModuleService,
    private checkinSettingService: CheckinSettingService,
    private guestService: GuestService,
    private guestManageService: GuestManageService,
    private groupService: GroupService,
    private groupManageService: GroupManageService,
    private groupListService: GroupListService,
    private groupCheckService: GroupCheckService,
    private visitorRealtimeService: VisitorRealtimeService,
    private updateByService: UpdateByService,
    private popupService: PopupService,
  ) {
    this.watchCheckinSetting();
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
      this.unwatchCheckinSetting();
  }

  /**
   * Watch check-in setting
   */
  async watchCheckinSetting() {
    if (!this.checkinSettingSubscription) {
      this.checkinSettingSubscription = this.checkinSettingService.observableCheckinSetting.subscribe((checkinSetting: CheckinSetting) => {
        this.checkinSetting = checkinSetting;
      });
    }
    
  }

  /**
   * Unwatch check-in setting
   */
  async unwatchCheckinSetting() {
    if (this.checkinSettingSubscription) {
      this.checkinSettingSubscription.unsubscribe();
      this.checkinSettingSubscription = null;
    }
  }

  /**
   * Get guest checkin status
   * @param guestId guest id
   * @returns true - attended, flase - not_attend
   */
  getGuestCheckinStatus(guestId: string): boolean {
    if (guestId) {
      const guest = this.guestService.getGuest(guestId);
      if (guest?.status?.checkin) { return true; }
    }
    return false;
  }

  /**
   * Get group checkin member count
   * @param memberList member list
   * @returns group checkin count
   */
  getGroupCheckinCount(memberList: string[]): number {
    return memberList?.filter((guestId: string) => {
      const guest = this.guestService.getGuest(guestId);
      if (guest?.status?.checkin) {
        return true;
      } else {
        return false;
      }
    }).length;
  }

  /**
   * Check-in function
   * @param guestIdList Guest id list
   * @param checkin check flag, true attended, false not_attend
   * @param guestList Guest list
   */
  async checkin(
    checkinMethod: CheckinMethodType,
    guestIdList: string[],
    guestList?: Guest[],
    groupIdList?: string[],
    groupList?: Group[],
    skipConfirm?: boolean,
    skipGroup?: boolean,
    skipGroupCheckin?: boolean,
    dismissModal?: boolean,
    gift?: boolean,
  ) {
    if (this.moduleService.currentModule === 'checkin') {
      if (guestIdList && !guestList?.length) {
        guestList = this.guestService.getGuestListById(guestIdList);
      }
      if (groupIdList?.length && !groupList?.length && !skipGroup) {
        groupList = this.groupService.getGroupListById(groupIdList);
      }
      const validGuestList = guestList?.filter((guest: Guest) => {
        if (guest?.status?.checkin) {
          return false;
        }
        return true;
      });
      const validGroupList = groupList?.filter((group: Group) => {
        if (group?.status?.checkin) {
          return false;
        }
        return true;
      });

      if (!validGuestList?.length && !validGroupList?.length) {
        if (gift) {
          this.updateGiftStatus(gift, [], guestIdList, groupIdList, guestList, groupList, dismissModal, true, true, checkinMethod);
        } else {
          await this.actionDone(true, dismissModal, true);
        }
      } else if (!skipConfirm) {
        await this.confirmCheckinModal(checkinMethod, guestIdList, guestList, groupIdList, groupList, skipGroup, skipGroupCheckin, dismissModal, gift);
      } else if (!skipGroupCheckin) {
        await this.checkGroupCheckin(checkinMethod, guestIdList, guestList, groupIdList, groupList, skipGroup, skipGroupCheckin, dismissModal, gift);
      } else {
        await this.saveCheckin(checkinMethod, true, guestIdList, guestList, groupIdList, groupList, dismissModal, gift, false, skipGroupCheckin);
      }
    }
  }

  /**
   * Confirm checkin modal
   * @param guestIdList guest id list
   * @param guestList guest list
   * @param groupList group list
   * @param skipGroup skip group variable flag
   * @param skipGroupCheckin skip group checkin flag
   * @param previewResult preview result flag
   * @param dismissModal dismiss modal flag
   */
  async confirmCheckinModal(
    checkinMethod: CheckinMethodType,
    guestIdList: string[],
    guestList?: Guest[],
    groupIdList?: string[],
    groupList?: Group[],
    skipGroup?: boolean,
    skipGroupCheckin?: boolean,
    dismissModal?: boolean,
    gift?: boolean
  ) {
    let msg = this.translate.instant('CHECKIN.confirm.checkin');
    if ((guestIdList?.length === 1 || guestList?.length === 1) && !groupList?.length) {
      msg = this.translate.instant('CHECKIN.confirm.checkin_guest', { name: guestList[0]?.name });
    } else if (groupList?.length === 1 && groupList?.[0]?.memberList?.length === guestIdList.length) {
      msg = this.translate.instant('CHECKIN.confirm.checkin_group', { name: groupList[0]?.groupName });
    } else if (guestIdList?.length > 1) {
      msg = this.translate.instant('CHECKIN.confirm.checkin_bulk', { count: guestIdList.length });
    }
    if (msg) {
      if (!guestList?.length) {
        guestList = this.guestService.getGuestListById(guestIdList);
      }
      const modal = await this.popupService.presentConfirm(msg, null, null, null, null, null, null, null, guestList);
      modal.onDidDismiss().then((result: any) => {
        if (result?.data?.confirm) {
          this.checkGroupCheckin(checkinMethod, guestIdList, guestList, groupIdList, groupList, skipGroup, skipGroupCheckin, dismissModal, gift);
        } else if (gift) {
          this.updateGiftStatus(gift, [], guestIdList, groupIdList, guestList, groupList, false, false, true, checkinMethod);
        }
      });
    }
  }

  /**
   * Check group check-in
   * @param guestIdList guest id list
   * @param guestList guest list
   * @param groupList group list
   * @param skipGroup skip group variable flag
   * @param skipGroupCheckin skip group checkin flag
   * @param dismissModal dismiss modal flag
   */
  async checkGroupCheckin(
    checkinMethod: CheckinMethodType, 
    guestIdList: string[],
    guestList?: Guest[],
    groupIdList?: string[],
    groupList?: Group[],
    skipGroup?: boolean,
    skipGroupCheckin?: boolean,
    dismissModal?: boolean,
    gift?: boolean
  ) {
    if (guestIdList?.length) {
      if (!groupList?.length && !skipGroup) {
        groupList = this.groupListService.generateGroupList(guestIdList);
      }
      if (this.checkinSetting?.partialCheckin === false) {
        groupList?.forEach((group: Group) => {
          guestIdList = [...new Set([...guestIdList, ...group?.memberList])];
        });
        await this.saveCheckin(checkinMethod, true, guestIdList, guestList, groupIdList, groupList, dismissModal, gift, false, skipGroupCheckin);
      } else if (!skipGroupCheckin && groupList?.length) {
        if (this.groupCheckService.checkGroupCheckin(guestIdList, [...groupList], true)) {
          await this.presentBulkCheckinConfirmModal(checkinMethod, guestIdList, guestList, groupIdList, groupList, dismissModal, gift);
        } else {
          if (!guestList?.length) {
            guestList = this.guestService.getGuestListById(guestIdList);
          }
          if (!groupIdList?.length) {
            groupIdList = [];
          }
          guestList?.forEach((guest: Guest) => {
            if (guest?.groupId && groupIdList.indexOf(guest.groupId) === -1) {
              groupIdList.push(guest.groupId);
            }
          });
          await this.saveCheckin(checkinMethod, true, guestIdList, guestList, groupIdList, groupList, dismissModal, gift, false, skipGroupCheckin);
        }
      } else {
        await this.saveCheckin(checkinMethod, true, guestIdList, guestList, groupIdList, groupList, dismissModal, gift, false, skipGroupCheckin);
      }
    }
  }

  /**
   * Present confirm group checkin modal
   * @param guestIdList guest id list
   * @param groupList group list
   * @param dismissModal dismiss modal flag
   */
  async presentBulkCheckinConfirmModal(
    checkinMethod: CheckinMethodType, 
    guestIdList: string[],
    guestList: Guest[],
    groupIdList: string[],
    groupList: Group[],
    dismissModal?: boolean,
    gift?: boolean,
  ) {
    if (guestIdList?.length) {
      const modal = await this.popupService.presentConfirm(this.translate.instant('GROUP.change_prompt.checkin'));
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          this.presentCheckinBulkModal(checkinMethod, guestIdList, groupList, dismissModal);
        } else {
          await this.saveCheckin(checkinMethod, true, guestIdList, guestList, groupIdList, groupList, dismissModal, gift, false, true);
        }
      });
    }
  }

  /**
   * Present group change modal for apply change to group member
   * @param selectedGuestList selected guest list
   * @param groupList group list
   * @param dismissModal Dimiss modal flag
   */
  async presentCheckinBulkModal(checkinMethod: CheckinMethodType, selectedGuestList: string[], groupList: Group[], dismissModal?: boolean) {
    const modal = await this.modalController.create({
      component: CheckinBulkComponent,
      componentProps: {
        groupList,
        selectedGuestList,
        checkinMethod,
      }
    });
    modal.present();
    modal.onDidDismiss().then(async (result: any) => {
      if (result?.data?.dismiss && dismissModal) {
        await this.popupService.dismissModal();
      }
    });
  }

  async presentCheckinBulkGiftModal(previewGuestList: Guest[], dismissModal?: boolean, checkin?: boolean, checkinMethod?: CheckinMethodType) {
    const modal = await this.modalController.create({
      component: CheckinBulkGiftComponent,
      componentProps: {
        previewGuestList,
        checkin,
        checkinMethod,
      }
    });
    modal.present();
    modal.onDidDismiss().then(async (result: any) => {
      if (result?.data?.skip && checkin) {
        const guestIdList = previewGuestList.map((guest: Guest) => {
          return guest.guestId;
        });
        await this.saveCheckin(checkinMethod, checkin, guestIdList, previewGuestList, [], [], dismissModal, false, true, true);
      } else if (result?.data?.dismiss && dismissModal) {
        this.popupService.dismissModal();
      }
    });
  }

  /**
   * Un-check function
   * @param guestIdList guest id list
   * @param guestList guest list
   * @param groupList group list
   * @param dismissModal dismiss modal flag
   */
  async uncheck(
    guestIdList: string[],
    guestList?: Guest[],
    groupIdList?: string[],
    groupList?: Group[],
    dismissModal?: boolean
  ) {
    this.confirmUncheckModal(guestIdList, guestList, groupIdList, groupList, dismissModal);
  }

  /**
   * Confirm un-check modal
   * @param guestIdList guest id list
   * @param guestList guest list
   * @param groupList group list
   * @param dismissModal dismiss modal
   */
  async confirmUncheckModal(
    guestIdList: string[],
    guestList?: Guest[],
    groupIdList?: string[],
    groupList?: Group[],
    dismissModal?: boolean
  ) {
    let msg = this.translate.instant('CHECKIN.confirm.uncheck');
    if (this.checkinSetting?.partialCheckin) {
      if (guestIdList.length === 1 && guestList?.length === 1 && !groupList?.length) {
        msg = this.translate.instant('CHECKIN.confirm.uncheck_guest', { name: guestList[0]?.name });
      } else if (groupList?.length === 1 && groupList?.[0]?.memberList?.length === guestIdList.length) {
        msg = this.translate.instant('CHECKIN.confirm.uncheck_group', { name: groupList[0]?.groupName });
      } else if (guestIdList.length > 1) {
        msg = this.translate.instant('CHECKIN.confirm.uncheck_bulk', { count: guestIdList.length });
      }
    }

    if (msg) {
      if (!guestList?.length) {
        guestList = this.guestService.getGuestListById(guestIdList);
      }
      const modal = await this.popupService.presentConfirm(msg, null,  null, null, null, null, null, null, guestList);
      modal.onDidDismiss().then((result: any) => {
        if (result?.data?.confirm) {
          if (!groupList?.length) {
            groupList = this.groupListService.generateGroupList(guestIdList);
          }

          if (this.checkinSetting?.partialCheckin === false) {
            if (!groupList) {
              groupList = this.groupListService.generateGroupList(guestIdList);
            }
            groupList?.forEach((group: Group) => {
              guestIdList = [...new Set([...guestIdList, ...group?.memberList])];
            });
            this.saveCheckin('', false, guestIdList, guestList, groupIdList, groupList, dismissModal);
          } else if (groupList?.length) {
            if (this.groupCheckService.checkGroupCheckin(guestIdList, [...groupList], false)) {
              this.saveCheckin('', false, guestIdList, guestList, groupIdList, groupList, dismissModal);
            } else {
              if (!guestList?.length) {
                guestList = this.guestService.getGuestListById(guestIdList);
              }
              if (!groupIdList?.length) {
                groupIdList = [];
              }

              guestList?.forEach((guest: Guest) => {
                if (guest?.groupId && groupIdList.indexOf(guest.groupId) === -1) {
                  groupIdList.push(guest.groupId);
                }
              });
              this.saveCheckin('', false, guestIdList, guestList, groupIdList, groupList, dismissModal);
            }
          } else {
            this.saveCheckin('', false, guestIdList, guestList, groupIdList, groupList, dismissModal);
          }
        }
      });
    }
  }

  /**
   * Save checkin
   * @param checkin checkin status
   * @param guestIdList guest id list
   * @param dismissModal dismiss modal
   */
  async saveCheckin(
    checkinMethod: CheckinMethodType,
    checkin: boolean,
    guestIdList: string[],
    guestList?: Guest[],
    groupIdList?: string[],
    groupList?: Group[],
    dismissModal?: boolean,
    gift?: boolean,
    skipGift?: boolean,
    skipGroupCheckin?: boolean,
    skipSuccess?: boolean,
  ) {
    let promptGift = false;
    if (checkin && !skipGift && !gift && this.checkinSetting?.giftRecord && this.checkinSetting?.promptGift) {
      if (guestIdList?.length === 1 && !groupIdList?.length) {
        promptGift = true;
      } else if (groupIdList?.length === 1) {
        const otherGuestList = guestList.filter((guest: Guest) => guest.groupId !== groupIdList[0]);
        if (!otherGuestList?.length) {
          promptGift = true;
        }
      }
    }
    if (promptGift) {
      this.receiveGift(checkinMethod, true, guestIdList, groupIdList, guestList, groupList, dismissModal, checkin, skipGroupCheckin, false);
    } else {
      this.saveCheckinDb(checkinMethod, checkin, guestIdList, guestList, groupIdList, groupList, dismissModal, gift, skipSuccess);
    }
  }

  /**
   * Save checkin  db
   * @param checkin Checkin status
   * @param guestIdList guest id list
   * @param guestList guest list
   * @param groupIdList group id list
   * @param groupList group list
   * @param dismissModal dismiss modal
   * @param gift gift status
   */
  async saveCheckinDb(
    checkinMethod: CheckinMethodType,
    checkin: boolean,
    guestIdList: string[],
    guestList?: Guest[],
    groupIdList?: string[],
    groupList?: Group[],
    dismissModal?: boolean,
    gift?: boolean,
    skipSuccess?: boolean,
  ) {
    await this.popupService.presentLoading();
    const data: any = {
      status: { checkin },
    };
    if (checkin && checkinMethod) {
      data.checkinMethod = checkinMethod;
    }
    if (gift) {
      data.status.gift = true;
    }
    if (guestIdList) {
      await this.guestManageService.saveGuest(data, guestIdList, checkin ? 'checkin' : 'uncheck', guestList, true, groupList);
      this.visitorRealtimeService.batchUpdateRtdb(checkin, checkinMethod, this.guestService.getAccountId(), guestIdList);
    }

    if (groupIdList) {
      // save group checkin / gift
      await this.groupManageService.saveGroupList(groupIdList, data, groupList);
      this.visitorRealtimeService.batchUpdateRtdb(checkin, checkinMethod, this.guestService.getAccountId(), [], groupIdList);
    }
    if (!skipSuccess) {
      await this.actionDone(true, dismissModal, checkin);
    }
  }

  //#region Gift on Check-In

  /**
   * receive gift
   * @param gift Gift status
   * @param guestIdList guest id list
   * @param groupIdList group id list
   * @param guestList guest list
   * @param groupList group list
   * @param dismissModal dismiss modal
   * @param checkin checkin status
   * @param skipGroupCheckin skip group checkin
   */
  async receiveGift(
    checkinMethod: CheckinMethodType,
    gift: boolean,
    guestIdList?: string[],
    groupIdList?: string[],
    guestList?: Guest[],
    groupList?: Group[],
    dismissModal?: boolean,
    checkin?: boolean,
    skipGroupCheckin?: boolean,
    skipConfirm?: boolean,
  ) {
    if (this.moduleService.currentModule === 'checkin' && this.checkinSetting?.giftRecord) {
      if (guestIdList?.length && !guestList?.length) {
        guestList = this.guestService.getGuestListById(guestIdList);
      }
      if (groupIdList?.length && !groupList?.length) {
        groupList = this.groupService.getGroupListById(groupIdList);
      }

      const validGuestList = guestList?.filter((guest: Guest) => {
        if (guest?.status?.gift === gift) {
          return false;
        }
        return true;
      });

      const validGroupList = groupList?.filter((group: Group) => {
        if (group?.status?.gift === gift) {
          return false;
        }
        return true;
      });

      if (gift) {
        if (!validGuestList?.length && !validGroupList?.length) {
          if (checkin) {
            await this.saveCheckinDb(checkinMethod, checkin, guestIdList, guestList, groupIdList, groupList, dismissModal, gift, false);
          } else {
            await this.actionDone(true, dismissModal, checkin);
          }
        } else if (guestIdList?.length === 1 && (!groupIdList?.length || skipGroupCheckin)) {
          if (this.checkinSetting?.giftDetail && gift) {
            if (checkin) {
              await this.saveCheckinDb(checkinMethod, checkin, guestIdList, guestList, groupIdList, groupList, dismissModal, gift, true);
            }
            if (guestList?.length) {
              this.presentGiftDetailModal(guestList[0], null, dismissModal, checkin, checkinMethod);
            }
          } else {
            if (skipConfirm) {
              this.updateGiftStatus(gift, [], guestIdList, groupIdList, guestList, groupList, dismissModal, checkin, false, checkinMethod);
            } else {
              this.confirmGiftModal(gift, guestIdList, groupIdList, guestList, groupList, dismissModal, checkin, checkinMethod);
            }
          }
        } else if (groupIdList?.length === 1 && (!guestIdList?.length || skipGroupCheckin)) {
          if (this.checkinSetting?.giftDetail && gift) {
            if (checkin) {
              await this.saveCheckinDb(checkinMethod, checkin, guestIdList, guestList, groupIdList, groupList, dismissModal, gift, true);
            }
            if (groupIdList?.length) {
              this.presentGiftDetailModal(null, groupList[0], dismissModal, checkin, checkinMethod);
            }
          } else {
            if (skipConfirm) {
              this.updateGiftStatus(gift, [], guestIdList, groupIdList, guestList, groupList, dismissModal, checkin, false, checkinMethod);
            } else {
              this.confirmGiftModal(gift, guestIdList, groupIdList, guestList, groupList, dismissModal, checkin, checkinMethod);
            }
          }
        } else {
          if (checkin) {
            await this.saveCheckinDb(checkinMethod, checkin, guestIdList, guestList, groupIdList, groupList, dismissModal, false, false);
          } else {
            await this.actionDone(true, dismissModal, checkin);
          }
        }
        // else if (!this.groupCheckService.checkGroupSelected(guestIdList, groupList)) {
        //   if (this.checkinSetting?.giftDetail && gift) {
        //     if (guestList?.length) {
        //       this.presentCheckinBulkGiftModal(guestList, dismissModal, checkin);
        //     }
        //   } else {
        //     if (skipConfirm) {
        //       this.updateGiftStatus(gift, [], guestIdList, groupIdList, guestList, groupList, dismissModal, checkin);
        //     } else {
        //       this.confirmGiftModal(gift, guestIdList, groupIdList, guestList, groupList, dismissModal, checkin);
        //     }
        //   }
        // } else {
        //   this.confirmBulkGift(gift, guestIdList, groupIdList, guestList, groupList, dismissModal, checkin);
        // }
      } else {
        if (guestIdList?.length === 1 && (!groupIdList?.length || skipGroupCheckin)) {
          if (skipConfirm) {
            this.updateGiftStatus(gift, [], guestIdList, groupIdList, guestList, groupList, dismissModal, checkin, false, checkinMethod);
          } else {
            this.confirmGiftModal(gift, guestIdList, groupIdList, guestList, groupList, dismissModal, checkin, checkinMethod);
          }
        } else {
          this.confirmBulkGift(gift, guestIdList, groupIdList, guestList, groupList, dismissModal, checkin, checkinMethod);
        }
      }
    }
  }

  /**
   * Confirm bulk gift status
   * @param gift gift status
   * @param guestIdList guest id list
   * @param groupIdList group id list
   * @param guestList guest list
   * @param groupList group list
   * @param dismissModal dismiss modal
   * @param checkin checkin status
   */
  async confirmBulkGift(
    gift: boolean,
    guestIdList?: string[],
    groupIdList?: string[],
    guestList?: Guest[],
    groupList?: Group[],
    dismissModal?: boolean,
    checkin?: boolean,
    checkinMethod?: CheckinMethodType,
  ) {
    const modal = await this.popupService.presentConfirm(
      gift ? this.translate.instant('GIFT.confirm.gift_receive') : this.translate.instant('GIFT.confirm.gift_delete')
    );
    modal.onDidDismiss().then((result: any) => {
      if (result?.data?.confirm) {
        if (gift) {
          if (guestList?.length) {
            this.presentCheckinBulkGiftModal(guestList, dismissModal, checkin, checkinMethod);
          }
        } else {
          this.updateGiftStatus(gift, [], guestIdList, groupIdList, guestList, groupList, dismissModal, checkin, false, checkinMethod);
        }
      } else if (checkin) {
        this.saveCheckin(checkinMethod, checkin, guestIdList, guestList, groupIdList, groupList, dismissModal, false, true);
      }
    });
  }

  /**
   * Confirm gift modal
   * @param gift Gift status
   * @param guestIdList guest id list
   * @param groupIdList group id list
   * @param guestList guest list
   * @param groupList group list
   * @param dismissModal dismiss modal
   * @param checkin checkin status
   */
  async confirmGiftModal(
    gift: boolean,
    guestIdList?: string[],
    groupIdList?: string[],
    guestList?: Guest[],
    groupList?: Group[],
    dismissModal?: boolean,
    checkin?: boolean,
    checkinMethod?: CheckinMethodType,
  ) {
    const modal = await this.popupService.presentConfirm(
      gift ? this.translate.instant('GIFT.confirm.gift_receive') : this.translate.instant('GIFT.confirm.gift_delete')
    );
    modal.onDidDismiss().then((result: any) => {
      if (result?.data?.confirm) {
        this.updateGiftStatus(gift, [], guestIdList, groupIdList, guestList, groupList, dismissModal, checkin, false, checkinMethod);
      } else if (checkin) {
        this.saveCheckin(checkinMethod, checkin, guestIdList, guestList, groupIdList, groupList, dismissModal, false, true, true);
      }
    });
  }

  /**
   * Present gift detail modal on check-in
   * @param guest Guest
   * @param group Barcode
   */
  async presentGiftDetailModal(
    guest?: Guest,
    group?: Group,
    dismissModal?: boolean,
    checkin?: boolean,
    checkinMethod?: CheckinMethodType,
  ) {
    const modal = await this.modalController.create({
      component: GiftPromptComponent,
      cssClass: 'modal-transparent',
      componentProps: {
        guest,
        group
      }
    });
    this.popupService.dismissLoading();
    modal.present();
    modal.onDidDismiss().then((result: any) => {
      if (result?.data?.gift || checkin) {
        this.actionDone(true, dismissModal, checkin);
      }
    });
    // modal.onDidDismiss().then((result: any) => {
    //   // if (result?.data?.gift) {
    //   //   if (guest?.guestId) {
    //   //     this.saveGuestGiftStatus(guest, false, result.data.gift.giftId, dismissModal, checkin);
    //   //   } else if (group?.groupId) {
    //   //     this.saveGroupGiftStatus(group, false, result.data.gift.giftId, dismissModal, checkin);
    //   //   }
    //   // } else
    //   // if (checkin) {
    //   //   if (guest?.guestId) {
    //   //     this.saveCheckin(true, [ guest.guestId ], [ guest ], [], [], dismissModal, false, true);
    //   //   } else if (group?.groupId) {
    //   //     this.saveCheckin(true, [], [], [ group.groupId ], [ group ], dismissModal, false, true);
    //   //   }
    //   // }
    // });
  }

  /**
   * Save group gift status
   * @param group Group
   * @param gift Gift
   * @param giftId Gift ID
   */
  // async saveGroupGiftStatus(
  //   group: Group,
  //   gift: boolean,
  //   giftId?: string,
  //   dismissModal?: boolean,
  //   checkin?: boolean
  // ) {
  //   if (!checkin && gift && this.checkinSetting?.promptCheckin) {
  //     this.checkin(group?.memberList ? group.memberList : [], [], [], [ group ], false, false, false, true, gift);
  //   } else if (checkin) {
  //     this.checkin(group?.memberList, [], [ group?.groupId ], [ group ], true, true, true, dismissModal, gift)
  //   }
  //   // else {
  //   //   if (group?.groupId) {
  //   //     const data: any = {
  //   //       status: { gift },
  //   //       giftBy: this.updateByService.updateBy
  //   //     };
  //   //     if (gift && giftId) {
  //   //       if (group?.giftList?.indexOf(giftId) === -1) {
  //   //         data.gift = group.giftList;
  //   //         data.gift.push(giftId);
  //   //       }
  //   //     }
  //   //     await this.groupManageService.saveGroupList([ group.groupId ], data);
  //   //   }
  //   //   await this.actionDone(true, dismissModal);
  //   // }
  // }

  /**
   * 
   * @param gift gift status
   * @param giftIdList gift id list
   * @param guestIdList guest id list
   * @param groupIdList group id list
   * @param guestList guest list
   * @param groupList group list
   * @param dismissModal dismiss modal
   * @param checkin checkin status
   * @param skipCheckinPrompt skip checkin prompt
   */
  async updateGiftStatus(
    gift: boolean,
    giftIdList: string[],
    guestIdList: string [],
    groupIdList: string[],
    guestList?: Guest[],
    groupList?: Group[],
    dismissModal?: boolean,
    checkin?: boolean,
    skipCheckinPrompt?: boolean,
    checkinMethod?: CheckinMethodType,
  ) {
    if (gift && !skipCheckinPrompt && !checkin && this.checkinSetting?.promptCheckin) {
      await this.checkin(checkinMethod, guestIdList, guestList, groupIdList, groupList, false, false, false, dismissModal, gift);
    } else {
      await this.popupService.presentLoading();

      const data: any = {
        status: { gift },
        giftBy: this.updateByService.updateBy
      };
      if (checkin) {
        data.status.checkin = true;
        data.checkinMethod = checkinMethod;
      }

      const giftBy = this.updateByService.updateBy;

      // if (groupIdList?.length && !groupList?.length) {
      //   groupList = this.groupService.getGroupListById(groupIdList);
      // }
      if (guestIdList?.length && !guestList?.length) {
        guestList = this.guestService.getGuestListById(guestIdList);
      }

      guestList = guestList?.map((guest: Guest) => {
        // if (!guest?.groupId || (guest?.groupId && (!groupIdList?.length || groupIdList?.indexOf(guest.groupId) === -1))) {
        //   guest.status.gift = gift;
        //   guest.giftBy = giftBy;
        // }
        guest.status.gift = gift;
        guest.giftBy = giftBy;
        if (checkin) {
          guest.status.checkin = true;
          guest.checkinMethod = checkinMethod;
        }
        return guest;
      });
      // groupList = groupList?.map((group: Group) => {
      //   let flag = true;
      //   if (checkin) {
      //     group.status.checkin = true;
      //   }
      //   group.status.gift = gift;
      //   group.giftBy = giftBy;

      //   group?.memberList?.forEach((guestId: string) => {
      //     if (flag && guestIdList?.length && guestIdList?.indexOf(guestId) !== -1) {
      //       flag = false;
      //     }
      //   });
      //   if (!flag) {
      //     guestList = guestList?.map((guest: Guest) => {
      //       if (guest.groupId === group.groupId) {
      //         if (group?.memberList?.indexOf(guest?.guestId) === -1) {
      //           guest.status.gift = gift;
      //           guest.giftBy = giftBy;
      //         } 
      //       }
      //       if (checkin) {
      //         guest.status.checkin = true;
      //       }
      //       return guest;
      //     });
      //   }
      //   return group;
      // });
      
      
      // console.log(groupList);
      // groupList = groupList.filter((group: Group) => {
      //   let flag = true;
      //   group?.memberList?.forEach((guestId: string) => {
      //     if (flag && guestIdList?.length && guestIdList?.indexOf(guestId) !== -1) {
      //       flag = false;
      //     }
      //   });
      //   return flag;
      // })
      // groupIdList = groupIdList.filter((groupId: string) => {
      //   if (groupList?.findIndex((group: Group) => { group.groupId === groupId }) !== -1) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // });
      if (guestIdList?.length) {
        await this.guestManageService.saveGuest(null, guestIdList, 'update', guestList, true);
      }
      if (groupIdList?.length) {
        await this.groupManageService.saveGroupList(groupIdList, data);
      }

      await this.actionDone(true, dismissModal, checkin);
    }
  }

  /**
   * Save guest gift status
   * @param guest Guest
   * @param gift Gift
   * @param giftId Gift ID
   */
  // async saveGuestGiftStatus(
  //   guest: Guest,
  //   gift: boolean,
  //   giftId?: string,
  //   dismissModal?: boolean,
  //   checkin?: boolean
  // ) {
  //   if (!checkin && gift && this.checkinSetting?.promptCheckin) {
  //     this.checkin([ guest.guestId ], [ guest ], [], [], false, false, false, dismissModal, gift);
  //   } else if (checkin) {
  //     this.saveCheckin(checkin, [ guest?.guestId ], [ guest ], [], [], dismissModal, gift, true, true);
  //     // this.saveGiftStatusDb(guest, gift, giftId, checkin, dismissModal);
  //   }
  // }

  /**
   * Save gift status db
   * @param guest guest
   * @param gift gift status
   * @param giftId gift id
   * @param checkin checkin status
   * @param dismissModal dismiss modal
   */
  // async saveGiftStatusDb(
  //   guest: Guest,
  //   gift: boolean,
  //   giftId?: string,
  //   checkin?: boolean,
  //   dismissModal?: boolean,
  // ) {
  //   await this.popupService.presentLoading();
  //   if (guest?.guestId) {
  //     const data: any = {
  //       status: { gift },
  //       giftBy: this.updateByService.updateBy
  //     };
  //     if (checkin) {
  //       data.status.checkin = true;
  //     }
  //     if (gift && giftId) {
  //       if (!guest.giftList) { guest.giftList = []; }
  //       if (guest?.giftList?.indexOf(giftId) === -1) {
  //         data.gift = guest.giftList;
  //         data.gift.push(giftId);
  //       }
  //     }
  //     await this.guestManageService.saveGuestDb(data, guest.guestId);
  //   }

  //   await this.actionDone(true, dismissModal);
  // }

  //#endregion

  /**
   * Action done
   * @param success Succuess flag
   * @param dismissModal dismiss modal flag
   */
  async actionDone(success: boolean, dismissModal?: boolean, checkin?: boolean) {
    await this.popupService.dismissLoading();
    if (success)  {
      if (dismissModal) {
        await this.popupService.dismissModal();
      }
      await this.popupService.presentToast(this.translate.instant('CHECKIN.msg.' + (checkin ? 'checkin' : 'uncheck') + '_success'), 'success');
    }
  }

}
