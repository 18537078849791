import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { InboxTarget } from 'src/app/interfaces/inbox';
import { LocalityService } from '../location/locality.service';
import { AppService } from '../general/app.service';
import { AccountsListService } from '../accounts/accounts-list.service';
import { FunctionService } from '../general/function.service';
import { LanguageService } from '../general/language.service';

import { UserService } from '../user/user.service';
import { UserAccounts } from 'src/app/interfaces/account';

@Injectable({
  providedIn: 'root'
})
export class InboxTargetService implements OnInit, OnDestroy {

  constructor(
    private appService: AppService,
    private localityService: LocalityService,
    private languageService: LanguageService,
    private accountsListService: AccountsListService,
    private userService: UserService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  checkInboxTargetList(inboxTargetList: InboxTarget[]) {
    if (inboxTargetList?.length) {
      let valid = false;
      inboxTargetList?.forEach((inboxTarget: InboxTarget) => {
        if (!valid) {
          let flag = true;
          const appVersion = this.appService.appVersion;
          const accountList = this.accountsListService.userAccountsList;

          if (flag && inboxTarget?.users?.length) {
            const index = inboxTarget.users.indexOf(this.userService.uid);
            if (index === -1) {
              flag = false;
            }
          }
          if (flag && inboxTarget?.versions?.length) {
            const index = inboxTarget.versions.indexOf(appVersion);
            if (index === -1) {
              flag = false;
            }
          }
          if (flag && inboxTarget?.minVersion) {
            if (this.functionService.compareVersion(appVersion, inboxTarget.minVersion, '<')) {
              flag = false;
            }
          }
          if (flag && inboxTarget?.maxVersion) {
            if (this.functionService.compareVersion(appVersion, inboxTarget.maxVersion, '>')) {
              flag = false;
            }
          }
          
          if (flag && inboxTarget?.userLanguage?.length) {
            const languageCode = this.languageService.getUserLanguage()?.code;
            if (languageCode) {
              const index = inboxTarget.userLanguage.indexOf(languageCode);
              if (index === -1) {
                flag = false;
              }
            }
          }

          if (flag && inboxTarget?.countries?.length) {
            const userCountry = this.localityService.getUserCountry()?.code;
            const userCountryIndex = inboxTarget.countries.indexOf(userCountry);

            let accountFlag = false;
            if (accountList?.length) {
              accountList?.forEach((account: UserAccounts) => {
                if (!accountFlag && account?.accountId && account?.enable && account?.location?.locality?.country) {
                  const index = inboxTarget.countries.indexOf(account.location.locality.country);
                  if (index !== -1) {
                    accountFlag = true;
                  }
                }
              });
            }

            if (userCountryIndex === -1 && !accountFlag) {
              flag = false;
            }
          }

          if (flag && inboxTarget?.accounts?.length) {
            let accountFlag = false;
            accountList?.forEach((account: UserAccounts) => {
              if (!accountFlag && account?.accountId && account?.enable) {
                const index = inboxTarget.accounts.indexOf(account.accountId);
                if (index !== -1) {
                  accountFlag = true;
                }
              }
            });
            if (!accountFlag) {
              flag = false;
            }
          }
          if (flag && inboxTarget?.userRole?.length) {
            let roleFlag = false;
            accountList?.forEach((account: UserAccounts) => {
              if (!roleFlag && account?.accountId && account?.enable && account?.role?.type && !account.role.custom) {
                const index = inboxTarget.userRole.indexOf(account.role.type);
                if (index !== -1) {
                  roleFlag = true;
                }
              }
            });
            if (!roleFlag) {
              flag = false;
            }
          }
          if (flag && inboxTarget?.accountStatus?.length) {
            let accountStatusFlag = false;
            accountList?.forEach((account: UserAccounts) => {
              if (!accountStatusFlag && account?.accountId && account?.enable && account?.accountStatus) {
                inboxTarget.accountStatus?.forEach((x: string) => {
                  if (account.accountStatus?.[x]) {
                    accountStatusFlag = true;
                  }
                });
              }
            });
            if (!accountStatusFlag) {
              flag = false;
            }
          }
          if (flag && inboxTarget?.accountEventType?.length) {
            let accountEventType = true;
            accountList?.forEach((account: UserAccounts) => {
              let eventFlag = true;
              if (!accountEventType && account?.accountId && account?.enable) {
                inboxTarget.accountEventType?.forEach((x: string) => {
                  if (x === 'wedding') {
                    if (account?.eventMode) {
                      eventFlag = false;
                    }
                  } else if (x === 'event') {
                    if (!account?.eventMode) {
                      eventFlag = false;
                    }
                  }
                });
                if (!eventFlag) {
                  accountEventType = false;
                }
              }
            });
            if (!accountEventType) {
              flag = false;
            }
          }
          if (!valid && flag) {
            valid = true;
          }
        }
      });
      return valid;
    }
    return true;
  }

  getInboxTargetAccountIdList(inboxTargetList: InboxTarget[]): string[] {
    let accountIdList: string[] = [];
    inboxTargetList?.forEach((inboxTarget: InboxTarget) => {
      if (inboxTarget?.accounts?.length) {
        accountIdList = [ ...new Set([ ...accountIdList, ...inboxTarget.accounts ]) ];
      }
    });
    return accountIdList;
  }

  checkInboxTargetAccountIdList(inboxTargetList: InboxTarget[], userAccountList: UserAccounts[]): string[] {
    if (inboxTargetList?.length && userAccountList?.length) {
      const accountIdList = this.getInboxTargetAccountIdList(inboxTargetList)?.filter((accountId: string) => {
        const index = userAccountList.findIndex((userAccount: UserAccounts) => {
          return userAccount?.enable && userAccount?.accountId && userAccount.accountId === accountId;
        });
        return index !== -1 ? true : false;
      });
      return accountIdList;
    }
    return [];
  }
}
