import { LocalityService } from 'src/app/services/location/locality.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { PhoneNumberUtil, PhoneNumberFormat, PhoneNumberType } from 'google-libphonenumber';
import { Mobile } from 'src/app/interfaces/general';
import { Country } from 'src/app/interfaces/database';

const phoneUtil = PhoneNumberUtil.getInstance();

@Injectable({
  providedIn: 'root'
})
export class MobileService implements OnInit, OnDestroy {

  constructor(
    private localityService: LocalityService,
  ) { 
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  generate(mobile: string): Mobile {
    let result: Mobile = {
      code: 0,
      country: '',
      no: '',
    };
    if (mobile) {
      try {
        result = this.validate(mobile);
        if (!result?.no) {
          if (mobile?.charAt(0) !== '+') {
            const country: Country = this.localityService.getDeviceCountry();
            if (country?.phone) {
              mobile = country.phone + mobile;
            }
          }
          result = this.validate(mobile);
        }
      } catch (err) {
      }
    }
    if (result?.no && result?.code && !result.country) {
      result.country = this.getCountryByPhoneCode(result.code);
    }
    return result;
  }

  validate(mobile: string): Mobile {
    let result: Mobile = {
      code: 0,
      country: '',
      no: ''
    };
    try {
      if (mobile) {
        if (mobile.toString()?.charAt(0) === '\'') {
          mobile = mobile.slice(1);
        }
        if (mobile.toString()?.charAt(0) !== '+') {
          mobile = '+' + mobile;
        }
        mobile = mobile?.replace('+6001', '+601');
        const rawFormat = this.generateRawFormat(mobile);
        const numberType = this.generateNumberType(rawFormat);
        const no = this.generateE164Format(mobile, rawFormat);
        const code = rawFormat.getCountryCode();
        const country = this.getCountryByPhoneCode(code);

        if (no) {
          if (numberType === PhoneNumberType.MOBILE || numberType === PhoneNumberType.FIXED_LINE_OR_MOBILE) {
            result = {
              code,
              country,
              no
            };
          } else if (no.indexOf('+6010') === 0 && no.slice(3).length === 10) {
            result = {
              code: 60,
              country: 'MY',
              no
            };
          }
        } else {
          
        }
      }
    } catch (err) {
      // console.log(mobile);
      // console.error(err);
    }
    return result;
  }

  generateRawFormat(no: string): libphonenumber.PhoneNumber {
    if (no) {
      return phoneUtil.parseAndKeepRawInput(no);
    }
    return null;
  }

  generateNumberType(rawFormat: libphonenumber.PhoneNumber) {
    if (rawFormat) {
      return phoneUtil.getNumberType(rawFormat);
    }
    return null;
  }

  generateE164Format(no: string, rawFormat?: libphonenumber.PhoneNumber) {
    if (no) {
      if (!rawFormat) {
        rawFormat = this.generateRawFormat(no);
      }
      return phoneUtil.format(rawFormat, PhoneNumberFormat.E164);
    }
  }

  getCountryByPhoneCode(phoneCode: number): string {
    let duplicate = false;
    let countryCode = '';
    if (phoneCode) {
      const countryList: Country[] = this.localityService.countryList;
      countryList?.forEach((country: Country) => {
        if (country?.code && country?.phone === phoneCode) {
          if (!countryCode) {
            countryCode = country.code;
          } else {
            duplicate = true;
          }        
        }
      });
    }
    
    return !duplicate && countryCode ? countryCode : '';
  }

  isMobileExisted(text: string): boolean {
    const phoneRegex = /(\+\d{1,3}\s?)?(\(\d{1,4}\)|\d{1,4})[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g;

    // Array to store matched phone numbers
    let phoneNumbers: string[] = [];

    // Find matches in the input string
    const matches = text.match(phoneRegex);

    if (matches) {
      // Remove duplicates by converting the array to a Set and back to an array
      const uniqueMatches = Array.from(new Set(matches));

      // Push the unique phone numbers to the result array
      phoneNumbers.push(...uniqueMatches);
    }

    phoneNumbers = phoneNumbers?.filter((x: string) => {
      const number = x?.split(' ')?.join('');
      return number && number?.length >= 7;
    });

    return phoneNumbers?.length ? true : false;
  }

}
