/**
 * Receipt footer
 *
 * THANK_YOU - thank you purchase msg
 * SIGNATURE - signature msg
 */
export const ReceiptFooter = {
    footer: [
            {
                canvas: [
                    {
                        type: 'line',
                        x1: 40,
                        y1: 1,
                        x2: 555.28,
                        y2: 1,
                        lineWidth: 0.5
                    }
                ],
                margin: [0, 15]
            },
            { text: '{{THANK_YOU}}', style: ['center', 'margin'], fontSize: 14 },
            { text: '{{SIGNATURE}}', style: ['center', 'margin'], fontSize: 10 },
            { text: '{{COMPANY}}', fontSize: 10, style: ['center', 'margin'] },
            { text: '{{COMPANY_NO}}', fontSize: 10, style: ['center', 'margin'] },
            // { text: 'support@thebigdays.com', fontSize: 10, style: ['center', 'margin'], link: 'mailto:support@thebigdays.com' },
            // { text: currentPage.toString() + ' / ' + pageCount, style: [ 'right', 'margin' ], fontSize: 10 }
    ]
};
