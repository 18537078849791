<ion-header #header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'LIST.group.group' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <app-guest-card *ngIf="group" [mode]="'visitor'" [guestList]="guestList" [setGuest]="{ group: group }" [showGuestSeating]="showGuestSeating" [seatingTypeName]="seatingTypeName" [disableClick]="true" [disableSlide]="true"></app-guest-card>
</ion-content>