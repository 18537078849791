import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Messages, SupportTicket } from 'src/app/interfaces/chat';
import { Inbox } from 'src/app/interfaces/inbox';
import { SupportDbService } from './support-db.service';
import { FunctionService } from '../general/function.service';
import { SupportStatus } from 'src/app/types/chat';

@Injectable({
  providedIn: 'root'
})
export class SupportTicketService implements OnInit, OnDestroy {

  uid: string;

  supportTicketList: SupportTicket[] = [];

  private supportTicketSubscription: Subscription;

  observableSupportTicketList: any;

  constructor(
    private db: AngularFireDatabase,
    private supportDbService: SupportDbService,
    private functionService: FunctionService,
  ) {
    this.observableSupportTicketList = new BehaviorSubject<SupportTicket[]>(this.supportTicketList);
  }
  
  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unwatchSupportTicket();
  }

  setup(uid: string) {
    this.uid = uid;
    if (this.uid) {
      this.watchSupportTicket();
    } else {
      this.unwatchSupportTicket();
    }
  }

  async watchSupportTicket() {
    if (this.uid && !this.supportTicketSubscription) {
      this.supportTicketSubscription = this.db.object(this.supportDbService.getRef(this.uid)).valueChanges().subscribe((doc: any) => {
        const supportTicketList: SupportTicket[] = [];
        if (doc) {
          Object.keys(doc)?.forEach((key: string) => {
            const supportTicket: SupportTicket = doc[key];
            if (supportTicket?.ticketId) {
              supportTicketList.push(supportTicket);
            }
          });
        }
        this.setupSupportTicketList(supportTicketList);
      });
    }
  }

  async unwatchSupportTicket() {
    if (this.supportTicketSubscription) {
      this.supportTicketSubscription.unsubscribe();
      this.supportTicketSubscription = null;
    }
  }

  getSupportTicketById(ticketId: string) {
    const index = this.supportTicketList.findIndex((x: SupportTicket) => {
      return x?.ticketId === ticketId;
    });
    if (index !== -1 && this.supportTicketList?.[index]) {
      return this.supportTicketList[index];
    }
    return null;
  }

  getSupportMessageById(ticketId: string, messageId: string): Messages {
    if (ticketId && messageId) {
      const ticket = this.getSupportTicketById(ticketId);
      if (ticket?.messages?.[messageId]) {
        return ticket.messages[messageId];
      }
    }
    return null;
  }

  getSupportMessageByTicketId(ticketId: string): Messages[] {
    const msgList: Messages[] = [];
    const ticket = this.getSupportTicketById(ticketId);
    if (ticket?.messages) {
      Object.keys(ticket.messages)?.forEach((messageId: string) => {
        const index = msgList.findIndex((x: Messages) => {
          return x.messageId === messageId;
        });
        if (index === -1 && ticket.messages?.[messageId]) {
          msgList.push(ticket.messages[messageId]);
        }
      });
    }
    return msgList.sort((a: Messages, b: Messages) => {
      return this.functionService.compare(a.createBy.time, b.createBy.time);
    });
  }

  getLastSupportMessageByTicketId(ticketId: string): Messages {
    let msgList: Messages[] = this.getSupportMessageByTicketId(ticketId);
    if (msgList?.length) {
     return msgList[msgList.length - 1]; 
    }
    return null;
  }

  setupSupportTicketList(supportTicketList: SupportTicket[]) {
    this.supportTicketList = supportTicketList;
    this.observableSupportTicketList.next(this.supportTicketList);
  }

  async updateTicket(supportTicket: SupportTicket) {
    if (supportTicket?.ticketId) {
      const ref = this.supportDbService.getRef(this.uid).child(supportTicket.ticketId);
      await ref.update(supportTicket);
    }
  }

  async updateTicketStatus(uid: string, ticketId: string, ticketStatus: SupportStatus) {
    if (uid && ticketId && ticketStatus) {
      const ref = this.supportDbService.getRef(uid).child(ticketId);
      await ref.update({ ticketStatus });
    }
  }

  async updateTicketRating(uid: string, ticketId: string, rating: number) {
    if (uid && ticketId && rating) {
      const ref = this.supportDbService.getRef(uid).child(ticketId);
      await ref.update({ rating });
    }
  }

  async updateMsg(ticketId: string, message: Messages) {
    if (ticketId && message) {
      const ref = this.supportDbService.getRef(this.uid).child(ticketId);
      if (!message?.messageId) {
        message.messageId = this.supportDbService.generateMessagesId(this.uid, ticketId);
      }
      const messageRef = ref.child(`messages`).child(message.messageId);
      await messageRef.update(message);
    }
  }

  async updateMsgRead(ticketId: string) {
    if (ticketId) {
      const supportTicket = this.getSupportTicketById(ticketId);
      if (supportTicket?.uid) {
        if (supportTicket?.messages) {
          const ref = this.supportDbService.getRef(supportTicket.uid).child(ticketId);
          const status = {
            uid: supportTicket.uid,
            read: true,
            readTimestamp: this.functionService.firebaseServerTime,
          };
          Object.keys(supportTicket.messages)?.forEach((key: string) => {
            const msg = supportTicket.messages[key];
            let read = false;
            if (msg?.createBy?.uid === 'admin') {
              if (msg?.status) {
                Object.keys(msg.status)?.forEach((uid: string) => {
                  if (uid === supportTicket.uid && (msg.status?.[supportTicket.uid]?.read || msg.status?.[supportTicket.uid]?.deleted)) {
                    read = true;
                  }
                });
              }
              if (!read) {
                const statusRef = ref.child(`messages`).child(msg.messageId).child(`status`).child(supportTicket.uid);
                statusRef.update(status);
              }
            }
          });
        }
      }
    }
  }

  getUnreadCount(ticketId?: string) {
    let count = 0;
    this.supportTicketList?.forEach((ticket: SupportTicket) => {
      if (ticket?.messages && ticket?.uid && ticket.ticketId && (!ticketId || ticket?.ticketId === ticketId)) {
        Object.keys(ticket.messages)?.forEach((key: string) => {
          if (ticket.uid && ticket.messages?.[key]?.createBy?.uid !== ticket.uid) {
            const msg = ticket.messages?.[key];
            let read = false;
            if (msg.status) {
              Object.keys(msg.status)?.forEach((uid: string) => {
                if (ticket?.uid === uid) {
                  if (msg.status[uid]?.read || msg.status[uid]?.deleted) {
                    read = true;
                  }
                }
              });
            }
            if (!read) {
              count += 1;
            }
          }
        })
      }
    });
    return count;
  }
}
