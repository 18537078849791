import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { PopupService } from 'src/app/services/general/popup.service';
import { StorageService } from '../general/storage.service';
import { AccountService } from './account.service';
import { CreditService } from '../subscription/credit.service';
import { Credit } from 'src/app/interfaces/credit';

@Injectable({
  providedIn: 'root'
})
export class AccountSubscribeService implements OnInit, OnDestroy {

  private modal: HTMLIonModalElement;

  private promptExpiredFlag: boolean;

  /**
   * Constructor
   * @param router router
   * @param translate translate
   * @param popupService popup service
   */
  constructor(
    private router: Router,
    private translate: TranslateService,
    private accountService: AccountService,
    private creditService: CreditService,
    private storageService: StorageService,
    private popupService: PopupService,
  ) { }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  /**
   * Prompt subscribe
   * @param accountId Account ID
   * @param msg msg
   */
  async promptSubscribe(accountId: string, msg: string) {
    if (accountId && msg && !this.modal) {
      this.modal = await this.popupService.presentConfirm(
        msg,
        '',
        '',
        '',
        this.translate.instant('BTN.ok'),
        this.translate.instant('BTN.skip')
        );
      this.modal.onWillDismiss().then((result: any) => {
        if (result?.data?.confirm) {
          this.goUpgradeSubscriptionPage(accountId);
        }
        this.modal = null;
      });
    }
  }

  /**
   * Prompt expired
   * @param accountId Account ID
   */
  async promptExpired(accountId: string) {
    if (accountId && !this.modal && !this.promptExpiredFlag) {
      this.modal = await this.popupService.presentConfirm(
        this.translate.instant('ACCOUNT.msg.trial_expired'),
        '',
        '',
        '',
        this.translate.instant('BTN.ok'),
        this.translate.instant('BTN.skip')
      );
      this.modal.onWillDismiss().then((result: any) => {
        if (result?.data?.confirm) {
          this.goUpgradeSubscriptionPage(accountId);
        } else {
          this.router.navigate(['/accounts/logout']);
        }
        this.promptExpiredFlag = true;
        this.modal = null;
      });
    }
  }

  /**
   * Go upgrade subscription page
   * @param accountId Account ID
   */
  async goUpgradeSubscriptionPage(accountId: string) {
    if (accountId) {
      const navigationExtras: NavigationExtras = {
        state: {
          accountId,
          upgradeTrial: true,
          backPremiumPage: true,
        }
      };
      await this.router.navigate(['/subscription/premium'], navigationExtras);
      this.promptExpiredFlag = false;
    }
  }

  async checkAvailableCredit() {
    const creditList = this.creditService.getUserAvailableCreditList();
    return creditList;
  }

  async upgradeTrialByCredit(accountId: string, creditList?: Credit[]) {
    if (accountId && !await this.storageService.get('prormpt_upgrade_trial_by_credit')) {
      await this.storageService.set('prormpt_upgrade_trial_by_credit', true);
      const confirm = await this.popupService.presentConfirm(this.translate.instant('ACCOUNT.msg.upgrade_trial_credit'));
      confirm.onDidDismiss().then((result: any) => {
        if (result?.data?.confirm) {
          let goCreditPage = false;
          if (creditList?.length > 1) {
            let productId = '';
            creditList?.forEach((credit: Credit) => {
              if (!productId || productId !== credit.productId) {
                if (productId) {
                  goCreditPage = true;
                }
                productId = credit.productId;
              }
            });
          }
          if (goCreditPage) {
            this.goUserCreditPage();
          } else {
            if (accountId) {
              const credit: Credit = this.creditService.getCreditById(creditList[0].creditId);
              this.accountService.upgradeAccountCall(credit, accountId, true);
            }
          }
        }
      });
    }
  }

  goUserCreditPage() {
    this.router.navigate(['user/credit']);
  }
}
