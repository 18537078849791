/**
 * Uber not support country list
 */
export const UberNotSupportCountry: string[] = [
    'BG',
    'CN',
    'DK',
    'HU',
    'ID',
    'KH',
    'MM',
    'MY',
    'SG',
    'TH',
    'VN'
];
