import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, distinctUntilChanged, map } from 'rxjs';
import { BlastMsg } from 'src/app/interfaces/blast';
import { ErrorService } from '../general/error.service';
import { SettingField } from 'src/app/interfaces/database';
import { BlastDeliveredType, BlastType } from 'src/app/types/blast';
import { BlastStatusService } from './blast-status.service';
import { BlastDeliveredStatus, BlastReadStatus, BlastSentStatus, BlastFailedStatus } from 'src/app/commons/blast';
import { OnlineService } from '../general/online.service';

@Injectable({
  providedIn: 'root'
})
export class BlastMsgService implements OnInit, OnDestroy {

  blastMsgList: BlastMsg[];
  observableBlastMsgList: any;

  private accountId: string;
  private blastMsgListSubscription: Subscription;

  constructor(
    private afs: AngularFirestore,
    private onlineService: OnlineService,
    private errorService: ErrorService,
  ) {
    this.blastMsgList = [];
    this.observableBlastMsgList = new BehaviorSubject<BlastMsg[]>(this.blastMsgList);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy() {
    this.unwatchBlastMsgList();
    this.blastMsgList = [];
  }
  
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
   
    if (this.accountId) {
      await this.watchBlastMsgList();
    } else {
      await this.unwatchBlastMsgList();
      this.blastMsgList = [];
    }
  }

  async watchBlastMsgList() {
    if (this.accountId) {
      if (!this.blastMsgListSubscription) {
        this.blastMsgListSubscription = this.afs.collection(`accounts/${ this.accountId }/blast/msg/blastMsg/`,
        ref => ref.where('enable', '==', true))
        .snapshotChanges().pipe(distinctUntilChanged(), map(actions => actions.map( a => {
          const data = a.payload.doc.data();
          return data;
        }))).subscribe({
          next: (blastMsgList: BlastMsg[]) => {
            this.blastMsgList = blastMsgList;
            this.upgradeDb(blastMsgList);
            this.observableBlastMsgList.next(this.blastMsgList);
          }, error: (err: any) => {
            this.errorService.logError(err);
          }
        });
      }
    } else {
      setTimeout(() => {
        this.watchBlastMsgList();
      }, 500);
    }
  }

  async unwatchBlastMsgList() {
    if (this.blastMsgListSubscription) {
      this.blastMsgListSubscription.unsubscribe();
      this.blastMsgListSubscription = null;
      // this.blastMsgList = [];
      // this.observableBlastMsgList.next(this.blastMsgList);
    }
  }

  upgradeDb(blastMsgList: BlastMsg[]) {
    const list: BlastMsg[] = [];
    blastMsgList?.forEach((blastMsg: BlastMsg) => {
      if (!blastMsg?.status && blastMsg?.provider) {
        blastMsg.status = {};
        // if (blastMsg?.sent?.status) {
        //   blastMsg.status.sent = blastMsg.sent;
        // }
        if (blastMsg?.delivered?.status) {
          const provider = blastMsg.provider;
          const deliveredStatus = blastMsg.delivered.status;
          
          if (BlastReadStatus?.[provider]?.length && BlastReadStatus[provider].indexOf(deliveredStatus) !== -1) {
            blastMsg.status.read = blastMsg.delivered;
          } else if (BlastDeliveredStatus?.[provider]?.length && BlastDeliveredStatus[provider].indexOf(deliveredStatus) !== -1) {
            blastMsg.status.delivered = blastMsg.delivered;
          } else if (BlastSentStatus?.[provider]?.length && BlastSentStatus[provider].indexOf(deliveredStatus) !== -1) {
            blastMsg.status.sent = blastMsg.delivered;
          } else if (BlastFailedStatus?.[provider]?.length && BlastFailedStatus[provider].indexOf(deliveredStatus) !== -1) {
            blastMsg.status.failed = blastMsg.delivered;
          } else {
            blastMsg.status.other = blastMsg.delivered;
          }
          list.push(blastMsg);
        }
      }
    });
    if (list?.length) {
      if (this.onlineService.online) {
        const batch = this.afs.firestore.batch();
        list?.forEach((blastMsg: BlastMsg) => {
          const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/blast/msg/blastMsg/${ blastMsg.msgId }`);
          batch.set(ref, blastMsg, { merge: true });
        });
        batch.commit();
      } else {
        list?.forEach((blastMsg: BlastMsg) => {
          const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/blast/msg/blastMsg/${ blastMsg.msgId }`);
          ref.set(blastMsg, { merge: true });
        });
      }
    }
  }

  setupBlastMsg(blastMsg: BlastMsg) {
    if (!blastMsg?.status && blastMsg?.provider) {
      blastMsg.status = {};
      // if (blastMsg?.sent?.status) {
      //   blastMsg.status.sent = blastMsg.sent;
      // }
      if (blastMsg?.delivered?.status) {
        const provider = blastMsg.provider;
        const deliveredStatus = blastMsg.delivered.status;

        if (BlastReadStatus?.[provider]?.length && BlastReadStatus[provider].indexOf(deliveredStatus) !== -1) {
          blastMsg.status.read = blastMsg.delivered;
        } else if (BlastDeliveredStatus?.[provider]?.length && BlastDeliveredStatus[provider].indexOf(deliveredStatus) !== -1) {
          blastMsg.status.delivered = blastMsg.delivered;
        } else if (BlastSentStatus?.[provider]?.length && BlastSentStatus[provider].indexOf(deliveredStatus) !== -1) {
          blastMsg.status.sent = blastMsg.delivered;
        } else if (BlastFailedStatus?.[provider]?.length && BlastFailedStatus[provider].indexOf(deliveredStatus) !== -1) {
          blastMsg.status.failed = blastMsg.delivered;
        } else {
          blastMsg.status.other = blastMsg.delivered;
        }
      }
    }
    return blastMsg;
  }

  getBlastMsgListByGuestId(guestId: string, usageId?: string) {
    const blastMsgList: BlastMsg[] = this.blastMsgList.filter((blastMsg: BlastMsg) => {
      let valid = blastMsg?.guestId && blastMsg?.guestId === guestId;
      if (usageId && valid) {
        valid = blastMsg?.usageId && blastMsg.usageId === usageId;
      }
      return valid;
    });
    return blastMsgList;
  }

  getBlastMsgListByGroupId(groupId: string, usageId?: string) {
    const blastMsgList: BlastMsg[] = this.blastMsgList.filter((blastMsg: BlastMsg) => {
      let valid = blastMsg?.groupId && blastMsg?.groupId === groupId;
      if (usageId && valid) {
        valid = blastMsg?.usageId && blastMsg.usageId === usageId;
      }
      return valid;
    });
    return blastMsgList;
  }

  getBlastMsgListByGuestIdList(guestIdList: string[]) {
    const blastMsgList: BlastMsg[] = this.blastMsgList.filter((blastMsg: BlastMsg) => {
      return blastMsg?.guestId && guestIdList?.length && guestIdList?.indexOf(blastMsg?.guestId) !== -1;
    });
    return blastMsgList;
  }

  getBlastMsgListByGroupIdList(groupIdList: string) {
    const blastMsgList: BlastMsg[] = this.blastMsgList.filter((blastMsg: BlastMsg) => {
      return blastMsg?.groupId && groupIdList?.length && groupIdList?.indexOf(blastMsg?.groupId) !== -1;
    });
    return blastMsgList;
  }

  getBlastMsgListByTemplateId(templateId: string) {
    const blastMsgList: BlastMsg[] = this.blastMsgList.filter((blastMsg: BlastMsg) => {
      return templateId && blastMsg?.templateId === templateId;
    });
    return blastMsgList;
  }

  getBlastMsgListByTemplateType(templateType: SettingField) {
    const blastMsgList: BlastMsg[] = this.blastMsgList.filter((blastMsg: BlastMsg) => {
      return templateType?.value && blastMsg?.templateType?.value === templateType.value && blastMsg?.templateType?.custom === templateType?.custom;
    });
    return blastMsgList;
  }

  getBlastMsgListByBlastType(blastType: BlastType) {
    const blastMsgList: BlastMsg[] = this.blastMsgList.filter((blastMsg: BlastMsg) => {
      return blastType && blastType === blastMsg.blastType;
    });
    return blastMsgList;
  }

  getGuestBlastMsgListByTemplateId(templateId: string) {
    const blastMsgList: BlastMsg[] = this.blastMsgList.filter((blastMsg: BlastMsg) => {
      return templateId && blastMsg?.templateId === templateId;
    });
    return blastMsgList;
  }

  getBlastMsgListByUsageId(usageId: string) {
    const blastMsgList: BlastMsg[] = this.blastMsgList.filter((blastMsg: BlastMsg) => {
      return usageId && blastMsg.usageId === usageId;
    });
    return blastMsgList;
  }

  getGuestGroupList(blastMsgList: BlastMsg[]) {
    const guestIdList: string[] = [];
    const groupIdList: string[] = [];

    blastMsgList?.forEach((blastMsg: BlastMsg) => {
      if (blastMsg?.guestId && guestIdList.indexOf(blastMsg.guestId) === -1) {
        guestIdList.push(blastMsg.guestId);
      }
      if (blastMsg?.groupId && groupIdList.indexOf(blastMsg.groupId) === -1) {
        groupIdList.push(blastMsg.groupId);
      }
    });
    return { guestIdList, groupIdList };
  }

  getBlastMsgDeliveredStatus(blastMsg: BlastMsg): BlastDeliveredType {
    if (blastMsg?.status) {
      if (blastMsg?.status?.read) {
        return 'read';
      } else if (blastMsg?.status?.delivered) {
        return 'delivered';
      } else if (blastMsg?.status?.failed) {
        return 'failed';
      } else if (blastMsg?.status?.sent) {
        return  'sent';
      }
    }
    return 'processing';
  }

  async updateBlastMsgList(blastMsgList: BlastMsg[]) {
    const list: BlastMsg[] = [];
    blastMsgList.forEach((blastMsg: BlastMsg) => {
      if (blastMsg?.sent?.status === 'failed') {
        blastMsg.sent.status = 'pending';
        if (blastMsg?.status?.failed?.status === 'failed') {
          blastMsg.status.failed.status = '';
        }
        list.push(blastMsg);
      } else if (blastMsg?.status?.failed?.status) {
        blastMsg.status.failed.status = '';
        list.push(blastMsg);
      }

      // if (blastMsg?.sent?.status === 'failed') {
      //   blastMsg.sent.status = 'pending';
      //   if (blastMsg?.delivered?.status === 'failed') {
      //     blastMsg.delivered.status = '';
      //   }
      // }
    });

    if (list?.length) {
      // const batch = this.afs.firestore.batch();
      // list?.forEach((blastMsg: BlastMsg) => {
      //   const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/blast/msg/blastMsg/${ blastMsg.msgId }`);
      //   batch.set(ref, blastMsg, { merge: true });
      // });
      // await batch.commit();
    }
  }
}
