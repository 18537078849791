import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BlastType } from 'src/app/types/blast';

@Component({
  selector: 'app-blast-tips',
  templateUrl: './blast-tips.component.html',
  styleUrls: ['./blast-tips.component.scss'],
})
export class BlastTipsComponent  implements OnInit, OnDestroy {

  @Input() pageMode: boolean;
  
  blastType: BlastType;

  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  ionViewWillEnter() {
    if (!this.blastType) {
      this.blastType = 'sms';
    }
  }

  /**
   * Dismiss modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { modal.dismiss(); }
    }
  }

}
