import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

import { ActionService } from 'src/app/services/account/privilege/action.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { AccountModule } from 'src/app/interfaces/privilege';
import { ModuleType } from 'src/app/types/general';

import { StdModuleList } from 'src/app/commons/module';

/**
 * AccountModule service
 */
@Injectable({
  providedIn: 'root'
})
export class ModuleService implements OnInit, OnDestroy {
  /**
   * Standard module list database
   */
  stdModuleList: ModuleType[] = StdModuleList;
  /**
   * Current module
   */
  private module: ModuleType;
  /**
   * Available module list based on account package
   */
  moduleList: ModuleType[];
  /**
   * Observable AccountModule List
   */
  observableModuleList: any;
  /**
   * Observable current module
   */
  observableCurrentModule: any;

  /**
   * Constructor
   * @param actionService Action Service
   */
  constructor(
    private actionService: ActionService,
    private functionService: FunctionService,
  ) {
    this.observableModuleList = new BehaviorSubject<string[]>(this.moduleList);
    this.observableCurrentModule = new BehaviorSubject<ModuleType>(this.module);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  /**
   * Setup module list based on account package.
   * Setup action list based on available module list.
   * @param moduleList AccountModule List
   */
  async setupModuleList(moduleList?: AccountModule) {
    if ((!this.moduleList || !this.moduleList.length) || !this.functionService.isEqual(moduleList, this.moduleList)) {
      this.moduleList = this.stdModuleList;
      this.actionService.setupActionList(this.moduleList);
      this.observableModuleList.next(this.moduleList);

    }
  }

  /**
   * Set current module
   * @param module current module
   */
  setCurrentModule(module: ModuleType) {
    if (this.module !== module) {
      this.module = module;
      this.observableCurrentModule.next(this.module);
    }
  }

  /**
   * Get current module
   * @returns moduel type
   */
  get currentModule(): ModuleType {
    return this.module ? this.module : 'account';
  }

}
