import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { SupportAdminService } from '../support/support-admin.service';
import { SupportTicketService } from '../support/support-ticket.service';
import { OnlineDbService } from '../user/online-db.service';
import { InboxService } from '../inbox/inbox.service';

@Injectable({
  providedIn: 'root'
})
export class VisibilityService implements OnInit, OnDestroy {

  constructor(
    private onlineDbService: OnlineDbService,
    private supportAdminService: SupportAdminService,
    private supportTicketService: SupportTicketService,
    private inboxService: InboxService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    document.removeEventListener('visibilitychange', ()=> {});
  }

  onvisibilitychange() {
    document.onvisibilitychange = async (e) => {
      if (document.visibilityState === 'hidden') {
        this.onlineDbService.updateOnAway();
        this.supportAdminService.unwatchSupportTicket();
        this.supportTicketService.unwatchSupportTicket();
        this.inboxService.unwatch();
      } else {
        this.onlineDbService.updateOnResume();
        this.supportAdminService.setup();
        this.supportTicketService.watchSupportTicket();
        this.inboxService.watch();
      }
    };
  }
}
