import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { WebsiteTheme } from 'src/app/interfaces/website';
import { FunctionService } from '../general/function.service';
import { WebsiteTimeService } from './website-time.service';
import { WebsiteFontService } from './website-font.service';
import { WebsiteCoverSample } from 'src/app/commons/website';

@Injectable({
  providedIn: 'root'
})
export class WebsiteThemeService implements OnInit, OnDestroy {

  accountId: string;

  websiteTheme: WebsiteTheme;

  observable: any;

  constructor(
    private afs: AngularFirestore,
    private websiteTimeService: WebsiteTimeService,
    private websiteFontService: WebsiteFontService,
    private functionService: FunctionService,
  ) {
    this.observable = new BehaviorSubject<WebsiteTheme>(this.websiteTheme);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  setup(websiteTheme: WebsiteTheme) {
    if (!this.functionService.isEqual(this.websiteTheme, websiteTheme)) {
      this.websiteTheme = websiteTheme;
      this.observable.next(this.websiteTheme);
    }
  }

  getWebsiteTheme(): WebsiteTheme {
    if (this.websiteTheme) {
      return { ...this.websiteTheme };
    } else {
      return {
        cover: {
          media: WebsiteCoverSample[0],
          text: {
            opacity: 30,
            position: 'bottom',
          },
        },
        backgroundSize: 'fixed',
        opacity: 0,
        themeId: '1',
        colorId: '1',
        font: this.websiteFontService.getDefaultFont(this.websiteTimeService.getWebsiteTime().locale),
        // font: this.websiteFontService.getDefaultFont(this.websiteTimeService.getWebsiteTime().locale),
      };
    }
  }

  async save(theme: WebsiteTheme) {
    if (this.accountId && !this.functionService.isEqual(theme, this.websiteTheme)) {
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ theme }, { merge: true });
    }
  }

  calculatePreviewWidth(containerWidth: number): number {
    // Define constants
    const minItemSize = 150;
    const maxItemSize = 400;
    const padding = 10;
    const outerPadding = 5;
    const minColumns = 2;

    // Calculate the effective width of the container after removing outer padding
    const effectiveWidth = containerWidth - (2 * outerPadding);
    let bestSize = minItemSize;

    // Iterate over possible numbers of columns, starting from minColumns
    for (let columns = minColumns; columns <= Math.floor(effectiveWidth / minItemSize); columns++) {
      // Calculate the potential item size for the current number of columns
      let potentialSize = (effectiveWidth / columns) - (2 * padding);

      // Check if the potential size is within the allowed range
      if (potentialSize >= minItemSize && potentialSize <= maxItemSize) {
        bestSize = Math.max(bestSize, Math.floor(potentialSize));
      } else if (potentialSize < minItemSize) {
        // If the potential size falls below the minimum item size, stop the loop
        break;
      }
    }

    return bestSize;
  }
}
