<ion-card class="gift-card ion-no-margin" [color]="((editMode || selectMode) && selectedGiftList && selectedGiftList?.indexOf(gift.giftId) !== -1) || selected ? 'tertiary' : 'white'" [button]="disableClick ? false : true" *ngIf="gift?.giftId">
  <ion-item-sliding #slidingItem>
    <ion-item class="background-transparent" [lines]="'none'" [detail]="false" (click)="giftClick(gift)">
      <ng-container *ngIf="gift.giftType">
        <ion-icon slot="start" src="./assets/wedding/icon/gift/cash.svg" *ngIf="gift.giftType === 'cash'"></ion-icon>
        <ion-icon slot="start" src="./assets/wedding/icon/gift/gift.svg" *ngIf="gift.giftType === 'other'"></ion-icon>
      </ng-container>
      <ion-label>
        <div class="margin-3">
          <ng-container *ngIf="gift.giftType === 'cash'">
            <span class="font-14 font-bold">{{ gift.amount | currency: gift.currency: 'symbol' }}</span>
            <span *ngIf="gift?.name"class="font-12"> - {{ gift.name }}</span>
          </ng-container>
          <ng-container *ngIf="gift.giftType === 'other' && gift?.name">
            {{ gift.name }}<span *ngIf="gift?.qty"> x {{ gift.qty }}</span>
          </ng-container>
        </div>
        <div class="font-10 font-italic margin-3" *ngIf="name">
          {{ name }}
        </div>
        <div class="ion-text-wrap">
          <ion-text class="font-10 line-110" color="medium">
            <ng-container *ngIf="invitedBy">
              {{ invitedBy }}
            </ng-container>
            <ng-container *ngIf="invitedBy && category">&nbsp;|&nbsp;</ng-container>
            <ng-container *ngIf="category">
              {{ category }}
            </ng-container>
          </ion-text>
        </div>
      </ion-label>
    </ion-item>
    <ion-item-options class="ion-no-border" side="end">
      <ion-item-option color="success" (click)="presentGiftManageModal(gift, slidingItem)" *ngIf="!disableEdit"><ion-icon src="./assets/wedding/icon/general/edit.svg"></ion-icon></ion-item-option>
      <ion-item-option color="success" (click)="promptRestore(gift, slidingItem)" *ngIf="!disableRestore && module === 'trash'"><ion-icon name="arrow-undo-outline"></ion-icon></ion-item-option>
      <ion-item-option color="danger" (click)="deleteFunc(gift, slidingItem)" *ngIf="!disableDelete"><ion-icon src="./assets/wedding/icon/general/trash.svg"></ion-icon></ion-item-option>
    </ion-item-options>
  </ion-item-sliding>
</ion-card>

