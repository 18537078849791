import { PushNotificationService } from 'src/app/services/general/push-notification.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';

import {
  PushNotifications,
  PermissionStatus,
  Token
} from '@capacitor/push-notifications';

import { PermissionState } from '@capacitor/core';

import { ErrorService } from 'src/app/services/general/error.service';
import { DeviceManageService } from 'src/app/services/device/device-manage.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject, Subscription, take } from 'rxjs';
import { PopupService } from '../general/popup.service';
import { TranslateService } from '@ngx-translate/core';



@Injectable({
  providedIn: 'root'
})
export class DeviceTokenService implements OnInit, OnDestroy {

  /**
   * Device push notification token
   */
  token: string;

  observableToken: any;

  private subscription: Subscription;

  /**
   * Constructor
   * @param platform platform
   * @param afMessaging angularfire messaging
   * @param deviceManageService device manage service
   */
  constructor(
    private platform: Platform,
    private translate: TranslateService,
    // private messaging: Messaging,
    private afMessaging: AngularFireMessaging,
    private deviceManageService: DeviceManageService,
    private pushNotificationService: PushNotificationService,
    private popupService: PopupService,
    private errorService: ErrorService,
  ) {
    this.observableToken = new BehaviorSubject<string>(this.token);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unwatchToken();
  }

  /**
   * Request token permission
   * Register token with firebase
   * Hybrid use capacitor plugin
   * Web use web plugin
   */
   async tokenPermission() {
    await this.platform.ready();
    this.token = '';
    
    if (await this.checkPermission()) {
      this.initialize();
    } else {
      if (await this.requestPermission()) {
        this.initialize();
      }
    }
    // this.getPermissionStatus();
    // if (this.platform.is('hybrid')) {
    //   this.checkPermission();
    // } else {
    //   await Notification.requestPermission().then((value: NotificationPermission) => {
    //     if (value === 'granted') {
    //       this.watchToken();
    //     } else {
    //       this.setToken();
    //     }
    //   });
    // }
  }

  

  async getPermissionStatus(): Promise<PermissionState | NotificationPermission> {
    if (this.platform.is('hybrid')) {
      const result: PermissionStatus = await PushNotifications.checkPermissions();
      return result.receive;
    } else {
      const result: NotificationPermission = Notification?.permission;
      return result;
    }
  }

  async checkPermission(): Promise<boolean> {
    const result: PermissionState | NotificationPermission = await this.getPermissionStatus();
    if (result) {
      if (this.platform.is('hybrid')) {
        if (result === 'granted') {
          return true;
        }
      } else {
        if (result === 'granted') {
          return true;
        }
      }
    }
    return false;
  }

  async requestPermission(): Promise<boolean> {
    if (this.platform.is('hybrid')) {
      const result: PermissionStatus = await PushNotifications.requestPermissions();
      if (result.receive === 'granted') {
        return true;
      }
    } else {
      const result: NotificationPermission = await this.afMessaging.requestPermission.pipe(take(1)).toPromise();
      if (result === 'granted') {
        return true;
      }
    }
    return false
  }

  async manualRequestPermission() {
    const result = await this.getPermissionStatus();
    if (result === 'denied') {
        await this.popupService.presentAlert(this.translate.instant('INBOX.permission.denied'));
    } else if (result === 'granted') {
      await this.popupService.presentAlert(this.translate.instant('INBOX.permission.success'));
    } else {
      if (await this.requestPermission()) {
        await this.popupService.presentAlert(this.translate.instant('INBOX.permission.success'));
      } else {
        await this.popupService.presentAlert(this.translate.instant('INBOX.permission.denied'));
      }
    }
  }
  

  async initialize() {
    if (this.platform.is('hybrid')) {
      await PushNotifications.register();
    }
    await this.watchToken();
  }

  /**
   * Watch Token
   */
  async watchToken() {
    if (this.platform.is('hybrid')) {
      try {
        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration', (token: Token) => {
          this.setToken(token?.value);
        });

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError', (err: any) => {
          this.setToken('');
          this.errorService.logError(err);
        });
      } catch (err: any) {
        console.error(err);
        this.setToken('');
      }
    } else {
      this.subscription = this.afMessaging.requestToken.subscribe({
        next: (token) => {
          this.setToken(token);
        }, error: (err: any) => {
          console.error(err);
          this.setToken('');
        }
      });
    }
  }

  unwatchToken() {
    if (this.platform.is('hybrid')) {
      try {
        PushNotifications.removeAllListeners();
      } catch (err: any) {
      }
    } else {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = null;
      }
    }
    
  }

  setToken(token: string) {
    if (token !== this.token) {
      this.token = token;
      this.observableToken.next(this.token);
      this.deviceManageService.setToken(this.token);
      if (this.token) {
        this.pushNotificationService.watchPushNotification();
      }
    } else {
      this.deviceManageService.checkStatus();
    }
  }

}
