import { BlastTemplate } from "../interfaces/blast";

export const StdWhatsAppTemplateNoReply = {
    'en': '[Please do not reply to this message.]',
    'ms': '[Sila jangan balas mesej ini.]',
    'zh': '[请不要回覆此消息。]',
    'zh-TW': '[請不要回覆此消息。]'
}
export const StdWhatsAppTemplateList: BlastTemplate[] = [
    {   
        templateId: 'wedding_qrcode_en_1',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '1',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\nDear [GUEST_NAME],\n\nWe are thrilled to invite you to our wedding celebration on [WEDDING_DATE] at [WEDDING_START_TIME] at [WEDDING_VENUE_NAME]. Your presence would mean the world to us as we embark on this special journey together.\n\nTo streamline the check-in process and ensure a seamless experience for everyone, we have attached your unique guest QR code link for easy check-in on the wedding day.\n[GUEST_URL]\n\nWe look forward to celebrating this joyous occasion with you and creating cherished memories together.\n\nWarmest regards,\n[COUPLE1] & [COUPLE2]'
    },
    {   
        templateId: 'wedding_qrcode_en_2',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '2',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\nDear [GUEST_NAME],\n\nYou are cordially invited to join us on an enchanting journey as we celebrate our wedding day! Save the date for a day filled with love, joy, and beautiful memories.\n\nDate: [WEDDING_DATE]\nTime: [WEDDING_START_TIME]\nVenue: [WEDDING_VENUE_NAME]\n\nTo make the check-in process smooth and seamless, we have prepared a unique QR code specifically for you. Simply present the QR code attached to this message to check-in on our special day.\n[GUEST_URL]\n\nWith love and excitement,\n[COUPLE1] & [COUPLE2]'
    },
    {   
        templateId: 'wedding_qrcode_en_3',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '3',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\nDear [GUEST_NAME],\n\nLove is in the air, and we couldn\'t imagine celebrating our special day without you! We are thrilled to invite you to our wedding extravaganza on [WEDDING_DATE] at [WEDDING_START_TIME] at the enchanting [WEDDING_VENUE_NAME]. Get ready for an unforgettable celebration filled with love, laughter, and cherished memories.\n\nTo make your check-in process a breeze, we have attached your unique guest QR code. Simply click on the following link to access your personalized QR code: [GUEST_URL] . This code will be your passport to our wedding wonderland, ensuring smooth entry and seamless check-in on the big day.\n\nPlease keep in mind that this is an invitation-only event, and we kindly ask that you refrain from sharing the QR code with others. If you have any questions or need further information, please reach out to us directly.\n\nWe are beyond excited to have you join us on our special day. Your presence will make it truly magical.\n\nWarmest regards,\n[COUPLE1] & [COUPLE2]'
    },
    {   
        templateId: 'wedding_qrcode_en_4',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '4',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\nDear [GUEST_NAME],\n\nLove is in the air, and we are excited to invite you to join us as we tie the knot! Save the date for our wedding on [WEDDING_DATE] at [WEDDING_START_TIME] at the enchanting [WEDDING_VENUE_NAME].\n\nTo make your check-in process smooth and seamless, we have attached your unique Guest QR Code. Simply present it upon arrival to effortlessly register your attendance and be part of our special day.\n[GUEST_URL]\n\nWe are eagerly counting down the days until we celebrate our love surrounded by cherished friends and family. Your presence would mean the world to us and make our wedding day even more memorable.\n\nWith heartfelt anticipation,\n[COUPLE1] & [COUPLE2]'
    },
    {   
        templateId: 'wedding_reminder_en_1',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '1',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\nDear [GUEST_NAME],\n\nJust a gentle reminder that our big day is fast approaching! We can hardly contain our excitement and would love to have you join us as we exchange vows on [WEDDING_DATE] at [WEDDING_START_TIME] at the beautiful [WEDDING_VENUE_NAME].\n\nAs our special guest, we have assigned you a personalized seating number and reserved [NUMBER_OF_GUEST] seats for you and your party. You\'ll find your seating information enclosed with this message.\n\nTo streamline the check-in process and make it hassle-free, we have also attached your unique Guest QR Code. Simply present it upon arrival to quickly and effortlessly register your attendance.\n[GUEST_URL]\n\nThank you for being part of our special day. Your presence will make our wedding celebration truly memorable.\n\nWith much anticipation,\n[COUPLE1] & [COUPLE2]'
    },
    {   
        templateId: 'wedding_reminder_en_2',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '2',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\nDear [GUEST_NAME],\n\nThe countdown is on, and we can\'t wait to celebrate our special day with you! Just a friendly reminder about our upcoming wedding on [WEDDING_DATE] at [WEDDING_START_TIME] at the stunning [WEDDING_VENUE_NAME].\n\nPlease note that you have been assigned [SEATING_TYPE] [GUEST_SEATING] and we have reserved [NUMBER_OF_GUEST] seats for you and your party. We have attached your personalized Guest QR Code for a hassle-free check-in experience. Simply present it upon arrival, and our team will guide you to your designated seats.\n[GUEST_URL]\n\nWe are looking forward to creating beautiful memories with you and sharing this joyous occasion. Thank you for being an important part of our journey.\n\nWarmest regards,\n[COUPLE1] & [COUPLE2]'
    },
    {   
        templateId: 'wedding_reminder_en_3',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '3',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\nDear [GUEST_NAME],\n\nThe day we\'ve been eagerly anticipating is just around the corner, and we couldn\'t be more excited to celebrate our wedding with you! As a friendly reminder, here are the details you need:\n\nDate: [WEDDING_DATE]\nTime: [WEDDING_START_TIME]\nVenue: [WEDDING_VENUE_NAME]\n[SEATING_TYPE]: [GUEST_SEATING]\nNumber of Guests: [NUMBER_OF_GUEST]\n\nTo ensure a seamless check-in experience, we have attached your personalized Guest QR Code. Simply have it ready on your device and present it upon arrival. It will guide you to your designated seating area, where you can join in the joyous festivities.\n[GUEST_URL]\n\nWe cannot wait to see you and share this momentous occasion together. Your presence means the world to us, and we are truly grateful to have you as part of our wedding celebration.\n\nWarmest regards,\n[COUPLE1] & [COUPLE2]'
    },
    {   
        templateId: 'wedding_reminder_en_4',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '4',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\nDear [GUEST_NAME],\n\nJust a gentle reminder that our big day is fast approaching! We can hardly contain our excitement and would love to have you join us as we exchange vows on [WEDDING_DATE] at [WEDDING_START_TIME] at the beautiful [WEDDING_VENUE_NAME].\n\nTo streamline the check-in process and make it hassle-free, we have also attached your unique Guest QR Code. Simply present it upon arrival to quickly and effortlessly register your attendance.\n[GUEST_URL]\n\nThank you for being part of our special day. Your presence will make our wedding celebration truly memorable.\n\nWith much anticipation,\n[COUPLE1] & [COUPLE2]'
    },
    {   
        templateId: 'wedding_reminder_en_5',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '5',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\nDear [GUEST_NAME],\n\nJust a gentle reminder that our big day is fast approaching! We can hardly contain our excitement and would love to have you join our wedding ceremony on [WEDDING_DATE] at [WEDDING_START_TIME] at the beautiful [WEDDING_VENUE_NAME].\n\nThank you for being part of our special day. Your presence will make our wedding celebration truly memorable.\n\nWith much anticipation,\n[COUPLE1] & [COUPLE2]'
    },
    {   
        templateId: 'wedding_thank_you_en_1',
        blastType: 'whatsapp',
        enable: true,
        eventMode: false,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '1',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\nDear [GUEST_NAME],\n\nWords cannot express how grateful we are for your presence at our wedding. It meant the world to us to have you by our side as we celebrated our love and exchanged vows. Your warmth, love, and support made our day even more special.\n\nWe want to take a moment to thank you from the bottom of our hearts for being there, sharing in our joy, and creating beautiful memories together. Your presence and well wishes filled our hearts with immense happiness.\n\nOnce again, thank you for being a part of our special day. Your love and support mean everything to us.\n\nWith heartfelt gratitude,\n[COUPLE1] & [COUPLE2]'
    },
    {   
        templateId: 'wedding_thank_you_en_2',
        blastType: 'whatsapp',
        enable: true,
        eventMode: false,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '2',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\nDear [GUEST_NAME],\n\nWe are still basking in the love and joy of our wedding day, and we want to take a moment to express our heartfelt gratitude for your presence. It meant the world to us to have you there, sharing in our celebration of love and making our day even more special.\n\nYour warmth, smiles, and well wishes added an extra sparkle to our wedding. We are immensely grateful for the love and support you have shown us, and we will cherish the memories we created together.\n\nOnce again, thank you from the bottom of our hearts for being a part of our wedding and for the love you have showered upon us. It was an honor to have you by our side as we embarked on this beautiful journey of marriage.\n\nWith heartfelt appreciation,\n[COUPLE1] & [COUPLE2]'
    },
    {   
        templateId: 'wedding_thank_you_en_3',
        blastType: 'whatsapp',
        enable: true,
        eventMode: false,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '3',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\nDear [GUEST_NAME],\n\nWe are filled with immense joy and gratitude as we reflect on our wedding day. It was truly a magical celebration, and your presence made it even more special.\n\nThank you from the bottom of our hearts for joining us and being a part of our journey. Your love, support, and warm wishes meant the world to us. We hope you had a wonderful time, created beautiful memories, and enjoyed every moment of the festivities.\n\nPlease know that your presence was the greatest gift we could have asked for. We are truly grateful for your love and friendship.\n\nOnce again, thank you for being a part of our special day and making it unforgettable.\n\nWith heartfelt appreciation,\n[COUPLE1] & [COUPLE2]'
    },
    {   
        templateId: 'wedding_qrcode_ms_1',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '1',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n[GUEST_NAME] yang dihormati,\n\nKami sangat teruja untuk menjemput anda ke majlis perkahwinan kami pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME]. Kehadiran anda bermakna dunia kepada kami semasa kami memulakan perjalanan istimewa ini bersama-sama.\n\nUntuk menyelaraskan proses daftar masuk dan memastikan pengalaman yang lancar untuk semua orang, kami telah melampirkan pautan kod QR tetamu unik anda untuk daftar masuk yang mudah pada hari perkahwinan.\n[GUEST_URL]\n\nKami tidak sabar-sabar untuk meraikan majlis yang menggembirakan ini bersama anda dan mencipta kenangan yang berharga bersama-sama.\n\nsalam hormat,\n[COUPLE1] dan [COUPLE2]'
    },
    {   
        templateId: 'wedding_qrcode_ms_2',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '2',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n[GUEST_NAME] yang dihormati,\n\nAnda dengan hormatnya dijemput untuk menyertai kami dalam perjalanan yang mempesonakan semasa kami meraikan hari perkahwinan kami! Simpan tarikh untuk hari yang penuh dengan cinta, kegembiraan dan kenangan indah.\n\nTarikh: [WEDDING_DATE]\nMasa: [WEDDING_START_TIME]\nTempat: [WEDDING_VENUE_NAME]\n\nUntuk menjadikan proses daftar masuk lancar dan lancar, kami telah menyediakan kod QR unik khusus untuk anda. Hanya tunjukkan kod QR yang dilampirkan pada mesej ini untuk mendaftar masuk pada hari istimewa kami.\n[GUEST_URL]\n\nDengan cinta dan kegembiraan,\n[COUPLE1] dan [COUPLE2]'
    },
    {   
        templateId: 'wedding_qrcode_ms_3',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '3',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n[GUEST_NAME] yang dihormati,\n\nCinta sedang membara, dan kami tidak dapat membayangkan meraikan hari istimewa kami tanpa anda! Kami sangat teruja untuk menjemput anda ke pesta perkahwinan kami pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME] yang mempesonakan. Bersedia untuk perayaan yang tidak dapat dilupakan yang dipenuhi dengan cinta, gelak tawa, dan kenangan yang berharga.\n\nUntuk memudahkan proses daftar masuk anda, kami telah melampirkan kod QR tetamu unik anda. Hanya klik pada pautan berikut untuk mengakses kod QR peribadi anda: [GUEST_URL] . Kod ini akan menjadi pasport anda ke alam ajaib perkahwinan kami, memastikan kemasukan lancar dan daftar masuk lancar pada hari besar.\n\nKami sangat teruja untuk menerima anda menyertai kami pada hari istimewa kami. Kehadiran anda akan menjadikannya benar-benar ajaib.\n\nsalam hormat,\n[COUPLE1] dan [COUPLE2]'
    },
    {   
        templateId: 'wedding_qrcode_ms_4',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '4',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n[GUEST_NAME] yang dihormati,\n\nCinta sedang membara, dan kami teruja untuk menjemput anda menyertai kami semasa kami mengikat tali pertunangan! Simpan tarikh perkahwinan kami pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME] yang mempesonakan.\n\nUntuk menjadikan proses daftar masuk anda lancar dan lancar, kami telah melampirkan Kod QR Tetamu unik anda. Hanya tunjukkan semasa ketibaan untuk mendaftarkan kehadiran anda dengan mudah dan menjadi sebahagian daripada hari istimewa kami.\n[GUEST_URL]\n\nKami tidak sabar-sabar menghitung hari sehingga kami meraikan cinta kami dikelilingi oleh rakan dan keluarga yang dihargai. Kehadiran anda bermakna dunia bagi kami dan menjadikan hari perkahwinan kami lebih diingati.\n\nDengan penuh harapan,\n[COUPLE1] dan [COUPLE2]'
    },
    {   
        templateId: 'wedding_reminder_ms_1',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '1',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n[GUEST_NAME] yang dihormati,\n\nSekadar peringatan yang lembut bahawa hari besar kita semakin hampir! Kami tidak dapat menahan keterujaan kami dan ingin anda menyertai kami semasa kami bertukar ikrar pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME] yang indah.\n\nSebagai tetamu istimewa kami, kami telah memberikan anda nombor tempat duduk yang diperibadikan dan menempah tempat duduk [NUMBER_OF_GUEST] untuk anda dan parti anda. Anda akan mendapati maklumat tempat duduk anda disertakan dengan mesej ini.\n\nUntuk menyelaraskan proses daftar masuk dan menjadikannya bebas kerumitan, kami juga telah melampirkan Kod QR Tetamu unik anda. Hanya tunjukkan semasa ketibaan untuk mendaftarkan kehadiran anda dengan cepat dan mudah.\n[GUEST_URL]\n\nTerima kasih kerana menjadi sebahagian daripada hari istimewa kami. Kehadiran anda akan menjadikan perayaan perkahwinan kami benar-benar tidak dapat dilupakan.\n\nDengan penuh harapan,\n[COUPLE1] dan [COUPLE2]'
    },
    {   
        templateId: 'wedding_reminder_ms_2',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '2',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n[GUEST_NAME] yang dihormati,\n\nKira detik dihidupkan, dan kami tidak sabar untuk meraikan hari istimewa kami bersama anda! Sekadar peringatan mesra tentang perkahwinan kami yang akan datang pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME] yang menakjubkan.\n\nAnda diberikan nombor [SEATING_TYPE] [GUEST_SEATING] dan kami telah menempah [NUMBER_OF_GUEST] tempat duduk untuk anda dan parti anda. Kami telah melampirkan Kod QR Tetamu anda yang diperibadikan untuk pengalaman daftar masuk tanpa kerumitan. Hanya tunjukkannya semasa ketibaan, dan pasukan kami akan membimbing anda ke tempat duduk yang ditetapkan.\n[GUEST_URL]\n\nKami tidak sabar-sabar untuk mencipta kenangan indah bersama anda dan berkongsi majlis yang menggembirakan ini. Terima kasih kerana menjadi bahagian penting dalam perjalanan kami.\n\nsalam hormat,\n[COUPLE1] dan [COUPLE2]'
    },
    {   
        templateId: 'wedding_reminder_ms_3',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '3',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n[GUEST_NAME] yang dihormati,\n\nHari yang kami nantikan sudah hampir tiba, dan kami tidak boleh lebih teruja untuk meraikan perkahwinan kami dengan anda! Sebagai peringatan mesra, berikut ialah butiran yang anda perlukan:\n\nTarikh: [WEDDING_DATE]\nMasa: [WEDDING_START_TIME]\nTempat: [WEDDING_VENUE_NAME]\n[SEATING_TYPE]: [GUEST_SEATING]\nBilangan Tetamu: [NUMBER_OF_GUEST]\n\nUntuk memastikan pengalaman daftar masuk yang lancar, kami telah melampirkan Kod QR Tetamu peribadi anda. Cukup sediakan pada peranti anda dan tunjukkan semasa ketibaan. Ia akan membimbing anda ke ruang tempat duduk anda yang ditetapkan, di mana anda boleh menyertai perayaan yang meriah.\n[GUEST_URL]\n\nKami tidak sabar untuk melihat anda dan berkongsi peristiwa penting ini bersama-sama. Kehadiran anda bermakna dunia bagi kami, dan kami benar-benar berterima kasih kerana mempunyai anda sebagai sebahagian daripada perayaan perkahwinan kami.'
    },
    {   
        templateId: 'wedding_thank_you_ms_1',
        blastType: 'whatsapp',
        enable: true,
        eventMode: false,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '1',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n[GUEST_NAME] yang dihormati,\n\nTidak dapat diungkapkan dengan kata-kata betapa kami bersyukur atas kehadiran anda di majlis perkahwinan kami. Ia bermakna dunia kepada kami untuk mempunyai anda di sisi kami semasa kami meraikan cinta kami dan bertukar ikrar. Kemesraan, kasih sayang dan sokongan anda menjadikan hari kami lebih istimewa.\n\nKami ingin meluangkan sedikit masa untuk mengucapkan terima kasih dari lubuk hati kami kerana berada di sana, berkongsi kegembiraan kami, dan mencipta kenangan indah bersama. Kehadiran dan ucapan selamat anda memenuhi hati kami dengan kebahagiaan yang amat besar.\n\nSekali lagi, terima kasih kerana menjadi sebahagian daripada hari istimewa kami. Kasih sayang dan sokongan anda amat bermakna bagi kami.\n\nDengan rasa syukur yang tulus,\n[COUPLE1] dan [COUPLE2]'
    },
    {   
        templateId: 'wedding_thank_you_ms_2',
        blastType: 'whatsapp',
        enable: true,
        eventMode: false,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '2',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n[GUEST_NAME] yang dihormati,\n\nKami masih menikmati cinta dan kegembiraan hari perkahwinan kami, dan kami ingin meluangkan sedikit masa untuk menyatakan rasa terima kasih kami yang tulus atas kehadiran anda. Ia bermakna dunia kepada kami untuk mempunyai anda di sana, berkongsi dalam perayaan cinta kami dan menjadikan hari kami lebih istimewa.\n\nKemesraan, senyuman dan ucapan selamat anda menambahkan kilauan tambahan pada perkahwinan kami. Kami amat berterima kasih atas kasih sayang dan sokongan yang anda tunjukkan kepada kami, dan kami akan menghargai kenangan yang kami cipta bersama.\n\nSekali lagi, terima kasih dari lubuk hati kami kerana menjadi sebahagian daripada perkahwinan kami dan atas kasih sayang yang telah anda limpahkan kepada kami. Ia adalah satu penghormatan untuk mempunyai anda di sisi kami semasa kami memulakan perjalanan perkahwinan yang indah ini.\n\nDengan setinggi-tinggi penghargaan,\n[COUPLE1] dan [COUPLE2]'
    },
    {   
        templateId: 'wedding_thank_you_ms_3',
        blastType: 'whatsapp',
        enable: true,
        eventMode: false,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '3',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n[GUEST_NAME] yang dihormati,\n\nKami dipenuhi dengan kegembiraan dan kesyukuran yang luar biasa semasa kami merenung hari perkahwinan kami. Ia benar-benar sambutan yang ajaib, dan kehadiran anda menjadikannya lebih istimewa.\n\nTerima kasih dari lubuk hati kami kerana menyertai kami dan menjadi sebahagian daripada perjalanan kami. Kasih sayang, sokongan dan ucapan hangat anda bermakna dunia bagi kami. Kami berharap anda mempunyai masa yang indah, mencipta kenangan indah dan menikmati setiap saat perayaan.\n\nHarap maklum bahawa kehadiran anda adalah hadiah terhebat yang kami boleh minta. Kami benar-benar berterima kasih atas kasih sayang dan persahabatan anda.\n\nSekali lagi, terima kasih kerana menjadi sebahagian daripada hari istimewa kami dan menjadikannya tidak dapat dilupakan.\n\nDengan setinggi-tinggi penghargaan,\n[COUPLE1] dan [COUPLE2]'
    },
    {   
        templateId: 'wedding_qrcode_zh_1',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '1',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n亲爱的 [GUEST_NAME] ，\n\n非常荣幸地邀请您参加我们的婚礼。婚礼谨订于 [WEDDING_DATE] [WEDDING_START_TIME] 在 [WEDDING_VENUE_NAME] 举行，我们真心希望您能出席这个特别的时刻，和我们一起展开这段旅程。\n\n为了让当天的报到流程更加顺畅，我们附上了您的宾客QR code 连结，让您可以在婚礼当天快速入场。\n[GUEST_URL]\n\n期待和您一起庆祝这个喜悦的时刻，共同创造珍贵的回忆。\n\n最亲切的问候，\n[COUPLE1] 与 [COUPLE2]'
    },
    {   
        templateId: 'wedding_qrcode_zh_2',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '2',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n亲爱的 [GUEST_NAME] ，\n\n诚挚地邀请您参加我们的婚礼，希望您能与我们共同分享喜悦和祝福。\n\n以下是婚礼的详细资讯：\n日期： [WEDDING_DATE]\n时间： [WEDDING_START_TIME]\n地点： [WEDDING_VENUE_NAME]\n\n为了确保您的入场顺利且方便，我们特别提供了宾客QR码连结，您可以使用此连结快速进行入场登记。请点击以下连结以获取您的个人宾客QR码： [GUEST_URL]\n\n期待您的到来，让我们一同共襄盛举，共同创造美好回忆。\n\n最诚挚的问候，\n[COUPLE1] 与 [COUPLE2]'
    },
    {   
        templateId: 'wedding_qrcode_zh_3',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '3',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n嗨！亲爱的 [GUEST_NAME] ，\n\n我们的婚礼即将来临，充满爱与喜悦的时刻！我们诚挚地邀请您参加我们的婚礼，一同见证我们的爱情故事在 [WEDDING_DATE] 于 [WEDDING_VENUE_NAME] 展开华丽章节。\n\n婚礼当天会有许多惊喜和美好时刻等待着您！为了让您的入场更加方便快捷，我们特别为您准备了一个独一无二的宾客QR码。只需轻轻点击下方连结，您将获得一张专属入场通行证： [GUEST_URL]\n\n这个QR码将成为您在婚礼当天的入场宝贵通行证，让您轻松顺畅地进入我们的爱的盛宴！\n\n如有任何疑问或需要协助，请随时直接与我们联系。我们非常期待与您共度这个特别的日子！\n\n爱与祝福，\n[COUPLE1] 与 [COUPLE2]'
    },
    {   
        templateId: 'wedding_qrcode_zh_4',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '4',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n亲爱的 [GUEST_NAME] ，\n\n我们非常开心地邀请你参加我们的婚礼，日期是 [WEDDING_DATE] ，时间是 [WEDDING_START_TIME] ，地点在一个超美超浪漫的地方 [WEDDING_VENUE_NAME] ！我们保证，这将是一个充满爱、欢笑和美好回忆的难忘派对。\n\n为了方便你当天入场，我们附上你独特的宾客QR code，只需点击以下连结，即可访问你的个人QR code： [GUEST_URL] 。这个代码将确保你能顺利入场并轻松完成入场手续。\n\n如果您有任何问题或需要进一步协助，请随时直接联系我们。\n\n我们非常期待在我们的特殊日子与你见面，你的到来将使它变得更加完美！\n[COUPLE1] 与 [COUPLE2]'
    },
    {   
        templateId: 'wedding_reminder_zh_1',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '1',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n亲爱的 [GUEST_NAME] ，\n\n温馨提醒您，我们的婚礼即将到来！我们迫不及待地想和您一起分享这个特别的时刻。我们将在 [WEDDING_DATE] 的 [WEDDING_START_TIME] 在美丽的 [WEDDING_VENUE_NAME] 交换誓言，期待着您的到来。\n\n作为我们特别的宾客，我们已为您预留了 [NUMBER_OF_GUEST] 个座位，并为您指定了专属的[SEATING_TYPE]号码 [GUEST_SEATING]。您可以在此信息中找到您的座位信息。\n\n为了简化报到过程，使其无忧无虑，我们还附上了您的独特宾客 QR code 连结，只需在到达时出示，即可快速轻松地登记出席。\n[GUEST_URL]\n\n感谢您参加我们的特别日子，您的到来将使我们的婚礼庆祝活动更加难忘。\n\n期待您的到来，\n[COUPLE1] 与 [COUPLE2]'
    },
    {   
        templateId: 'wedding_reminder_zh_2',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '2',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n亲爱的 [GUEST_NAME] ，\n\n我们的婚礼即将举行，我们非常期待您的光临，共同见证我们于 [WEDDING_DATE] 在 [WEDDING_VENUE_NAME] 交换誓言的美好时刻。\n\n作为我们的尊贵宾客，我们已为您预留了 [NUMBER_OF_GUEST] 个座位。请在下方查看您的座位号码：\n[SEATING_TYPE]： [GUEST_SEATING]\n\n为了方便您的入场手续，我们特别提供了个人化的宾客QR code。请点击下方连结，以便在婚礼当天快速签到出席： [GUEST_URL]\n\n如有任何疑问或需要进一步资讯，请随时与我们联系。我们非常乐意协助您，确保您在婚礼中享受愉快的时刻。\n\n再次感谢您的出席，您的到来将为我们的婚礼增添无比的喜悦。\n\n期待与您共度这特别的日子！\n[COUPLE1] 与 [COUPLE2]'
    },
    {   
        templateId: 'wedding_reminder_zh_3',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '3',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n亲爱的 [GUEST_NAME] ，\n\n我们翘首以盼的大日子即将到来，我们非常兴奋能够与您一起庆祝我们的婚礼！在这里，我们友善地提醒您以下详情：\n\n日期： [WEDDING_DATE]\n时间： [WEDDING_START_TIME]\n地点： [WEDDING_VENUE_NAME]\n[SEATING_TYPE]： [GUEST_SEATING]\n宾客人数： [NUMBER_OF_GUEST]\n\n为了确保您顺利入场，我们已附上您个人化的宾客QR码。只需将其准备在您的设备上，到达时出示即可。它将引导您到指定的座位区域，让您加入欢乐的盛会中。\n[GUEST_URL]\n\n如果您有任何问题、需求或特殊要求，请随时与我们联系。\n\n我们迫不及待地想见到您，与您共同分享这个重要的时刻。您的到来对我们而言意义重大，我们由衷地感激您成为我们婚礼庆典的一部分。\n[COUPLE1] 与 [COUPLE2]'
    },
    {   
        templateId: 'wedding_thank_you_zh_1',
        blastType: 'whatsapp',
        enable: true,
        eventMode: false,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '1',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n亲爱的 [GUEST_NAME] ，\n\n言语无法表达我们对您在百忙中抽空出席的感激之情。有您的见证，一同庆祝我们的爱情并交换誓言，对我们来说意义非凡。\n\n我们衷心感谢您的到来，与我们分享喜悦，一起创造美好的回忆。您的出席和祝福充满了我们的心，带给了我们巨大的幸福感。\n\n我们要特别感谢你对我们的祝福和祝福。你的诚挚的祝福将伴随着我们踏上新婚生活的旅程，并成为我们珍贵的回忆。\n\n衷心感激，\n[COUPLE1] 与 [COUPLE2]'
    },
    {   
        templateId: 'wedding_thank_you_zh_2',
        blastType: 'whatsapp',
        enable: true,
        eventMode: false,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '2',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n亲爱的 [GUEST_NAME] ，\n\n我们仍然沉浸在婚礼当天的爱与喜悦中，现在想要花一些时间向您表达我们由衷的感谢。您的到场对我们来说意义非凡，与您分享我们对爱的庆祝，让我们的日子更加特别。\n\n您的热情、微笑和祝福为我们的婚礼增添了一抹光彩。我们非常感谢您给予我们的爱和支持，我们将珍藏我们一起创造的美好回忆。\n\n再次，我们由衷地感谢您成为我们婚礼的一部分，并对您对我们的爱心表示感谢。能够在我们开始这段美好婚姻旅程时有您在身边，我们感到荣幸。\n\n衷心感谢，\n[COUPLE1] 与 [COUPLE2]'
    },
    {   
        templateId: 'wedding_thank_you_zh_3',
        blastType: 'whatsapp',
        enable: true,
        eventMode: false,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '3',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n亲爱的 [GUEST_NAME] ，\n\n我们衷心感谢您在我们的婚礼上的出席和祝福。您的存在和关心使我们的婚礼更加温馨和难忘。\n\n您的祝福给予了我们巨大的力量和爱的能量。在这特别的日子里，我们感受到了您的支持和祝福，这对我们来说意义非凡。\n\n我们真心感激您对我们的喜悦和幸福的分享。您的参与让我们的婚礼更加完美，我们将永远珍藏这份珍贵的回忆。\n\n再次感谢您的慷慨和关心。我们深深感激您在我们一生中的特殊时刻中陪伴着我们。\n\n祝福您和您的家人幸福和健康。\n\n衷心感谢，\n[COUPLE1] 与 [COUPLE2]'
    },
    {   
        templateId: 'wedding_qrcode_zh_tw_1',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '1',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n親愛的 [GUEST_NAME] ，\n\n非常榮幸地邀請您參加我們的婚禮。婚禮謹訂於 [WEDDING_DATE] [WEDDING_START_TIME] 在 [WEDDING_VENUE_NAME] 舉行，我們真心希望您能出席這個特別的時刻，和我們一起展開這段旅程。\n\n為了讓當天的報到流程更加順暢，我們附上了您的賓客QR code 連結，讓您可以在婚禮當天快速入場。\n[GUEST_URL]\n\n期待和您一起慶祝這個喜悅的時刻，共同創造珍貴的回憶。\n\n最親切的問候，\n[COUPLE1] 與 [COUPLE2]'
    },
    {   
        templateId: 'wedding_qrcode_zh_tw_2',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '2',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n親愛的 [GUEST_NAME] ，\n\n誠摯地邀請您參加我們的婚禮，希望您能與我們共同分享喜悅和祝福。\n\n以下是婚禮的詳細資訊：\n日期： [WEDDING_DATE]\n時間： [WEDDING_START_TIME]\n地點： [WEDDING_VENUE_NAME]\n\n為了確保您的入場順利且方便，我們特別提供了賓客QR碼連結，您可以使用此連結快速進行入場登記。請點擊以下連結以獲取您的個人賓客QR碼： [GUEST_URL]\n\n期待您的到來，讓我們一同共襄盛舉，共同創造美好回憶。\n\n最誠摯的問候，\n[COUPLE1] 與 [COUPLE2]'
    },
    {   
        templateId: 'wedding_qrcode_zh_tw_3',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '3',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n嗨！親愛的 [GUEST_NAME] ，\n\n我們的婚禮即將來臨，充滿愛與喜悅的時刻！我們誠摯地邀請您參加我們的婚禮，一同見證我們的愛情故事在 [WEDDING_DATE] 於 [WEDDING_VENUE_NAME] 展開華麗章節。\n\n婚禮當天會有許多驚喜和美好時刻等待著您！為了讓您的入場更加方便快捷，我們特別為您準備了一個獨一無二的賓客 QR 碼。只需輕輕點擊下方連結，您將獲得一張專屬入場通行證： [GUEST_URL]\n\n這個QR碼將成為您在婚禮當天的入場寶貴通行證，讓您輕鬆順暢地進入我們的愛的盛宴！\n\n我們非常期待與您共度這個特別的日子！\n\n愛與祝福，\n[COUPLE1] 與 [COUPLE2]'
    },
    {   
        templateId: 'wedding_qrcode_zh_tw_4',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '4',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n親愛的 [GUEST_NAME] ，\n\n我們非常開心地邀請你參加我們的婚禮，日期是 [WEDDING_DATE] ，時間是 [WEDDING_START_TIME] ，地點在一個超美超浪漫的地方 [WEDDING_VENUE_NAME] ！我們保證，這將是一個充滿愛、歡笑和美好回憶的難忘派對。\n\n為了方便你當天入場，我們附上你獨特的賓客QR code，只需點擊以下連結，即可訪問你的個人QR code： [GUEST_URL] 。這個代碼將確保你能順利入場並輕鬆完成入場手續。\n\n我們非常期待在我們的特殊日子與你見面，你的到來將使它變得更加完美！\n[COUPLE1] 與 [COUPLE2]'
    },
    {   
        templateId: 'wedding_reminder_zh_tw_1',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '1',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n親愛的 [GUEST_NAME] ，\n\n溫馨提醒您，我們的婚禮即將到來！我們迫不及待地想和您一起分享這個特別的時刻。我們將在 [WEDDING_DATE] 的 [WEDDING_START_TIME] 在美麗的 [WEDDING_VENUE_NAME] 交換誓言，期待著您的到來。\n\n作為我們特別的賓客，我們已為您預留了 [NUMBER_OF_GUEST] 個座位，並為您指定了專屬的[SEATING_TYPE]號碼 [GUEST_SEATING] 。您可以在此信息中找到您的座位信息。\n\n為了簡化報到過程，使其無憂無慮，我們還附上了您的獨特賓客 QR code 連結，只需在到達時出示，即可快速輕鬆地登記出席。\n[GUEST_URL]\n\n感謝您參加我們的特別日子，您的到來將使我們的婚禮慶祝活動更加難忘。\n\n期待您的到來，\n[COUPLE1] 與 [COUPLE2]'
    },
    {   
        templateId: 'wedding_reminder_zh_tw_2',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '2',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n親愛的 [GUEST_NAME] ，\n\n我們的婚禮即將舉行，我們非常期待您的光臨，共同見證我們於 [WEDDING_DATE] 在 [WEDDING_VENUE_NAME] 交換誓言的美好時刻。\n\n作為我們的尊貴賓客，我們已為您預留了 [GUEST_SEATING] 個座位。請在下方查看您的座位號碼：\n[SEATING_TYPE]： [GUEST_SEATING]\n\n為了方便您的入場手續，我們特別提供了個人化的賓客 QR code。請點擊下方連結，以便在婚禮當天快速簽到出席： [GUEST_URL]\n\n如有任何疑問或需要進一步資訊，請隨時與我們聯繫。我們非常樂意協助您，確保您在婚禮中享受愉快的時刻。\n\n再次感謝您的出席，您的到來將為我們的婚禮增添無比的喜悅。\n\n期待與您共度這特別的日子！\n[COUPLE1] 與 [COUPLE2]'
    },
    {   
        templateId: 'wedding_reminder_zh_tw_3',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '3',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n親愛的 [GUEST_NAME] ，\n\n我們翹首以盼的大日子即將到來，我們非常興奮能夠與您一起慶祝我們的婚禮！在這裡，我們友善地提醒您以下詳情：\n\n日期： [WEDDING_DATE]\n時間： [WEDDING_START_TIME]\n地點： [WEDDING_VENUE_NAME]\n[SEATING_TYPE]： [GUEST_SEATING]\n賓客人數： [NUMBER_OF_GUEST]\n\n為了確保您順利入場，我們已附上您個人化的賓客 QR 碼。只需將其準備在您的設備上，到達時出示即可。它將引導您到指定的座位區域，讓您加入歡樂的盛會中。\n[GUEST_URL]\n\n如果您有任何問題、需求或特殊要求，請隨時與我們聯繫。\n\n我們迫不及待地想見到您，與您共同分享這個重要的時刻。您的到來對我們而言意義重大，我們由衷地感激您成為我們婚禮慶典的一部分。\n[COUPLE1] 與 [COUPLE2]'
    },
    {   
        templateId: 'wedding_thank_you_zh_tw_1',
        blastType: 'whatsapp',
        enable: true,
        eventMode: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '1',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n親愛的 [GUEST_NAME] ，\n\n言語無法表達我們對您在百忙中抽空出席的感激之情。有您的見證，一同慶祝我們的愛情並交換誓言，對我們來說意義非凡。\n\n我們衷心感謝您的到來，與我們分享喜悅，一起創造美好的回憶。您的出席和祝福充滿了我們的心，帶給了我們巨大的幸福感。\n\n我們要特別感謝你對我們的祝福和祝福。你的誠摯的祝福將伴隨著我們踏上新婚生活的旅程，並成為我們珍貴的回憶。\n\n衷心感激，\n[COUPLE1] 與 [COUPLE2]'
    },
    {   
        templateId: 'wedding_thank_you_zh_tw_2',
        blastType: 'whatsapp',
        enable: true,
        eventMode: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '2',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n親愛的 [GUEST_NAME] ，\n\n我們仍然沉浸在婚禮當天的愛與喜悅中，現在想要花一些時間向您表達我們由衷的感謝。您的到場對我們來說意義非凡，與您分享我們對愛的慶祝，讓我們的日子更加特別。\n\n您的熱情、微笑和祝福為我們的婚禮增添了一抹光彩。我們非常感謝您給予我們的愛和支持，我們將珍藏我們一起創造的美好回憶。\n\n再次，我們由衷地感謝您成為我們婚禮的一部分，並對您對我們的愛心表示感謝。能夠在我們開始這段美好婚姻旅程時有您在身邊，我們感到榮幸。\n\n衷心感謝，\n[COUPLE1] 與 [COUPLE2]'
    },
    {   
        templateId: 'wedding_thank_you_zh_tw_3',
        blastType: 'whatsapp',
        enable: true,
        eventMode: false,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '3',
        custom: false,
        dynamicVariable: true,
        msg: '[WEDDING_TITLE]\n\n親愛的 [GUEST_NAME] ，\n\n我們衷心感謝您在我們的婚禮上的出席和祝福。您的存在和關心使我們的婚禮更加溫馨和難忘。\n\n您的祝福給予了我們巨大的力量和愛的能量。在這特別的日子裡，我們感受到了您的支持和祝福，這對我們來說意義非凡。\n\n我們真心感激您對我們的喜悅和幸福的分享。您的參與讓我們的婚禮更加完美，我們將永遠珍藏這份珍貴的回憶。\n\n再次感謝您的慷慨和關心。我們深深感激您在我們一生中的特殊時刻中陪伴著我們。\n\n祝福您和您的家人幸福和健康。\n\n衷心感謝，\n[COUPLE1] 與 [COUPLE2]'
    }
];

export const StdEventWhatsAppTemplateList: BlastTemplate[] = [
    {   
        templateId: 'event_qrcode_en_1',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '1',
        custom: false,
        msg: '[WEDDING_TITLE]\n\nDear [GUEST_NAME],\n\nYou are cordially invited to attend our upcoming event, on [WEDDING_DATE] at [WEDDING_START_TIME] located at [WEDDING_VENUE_NAME]. Your [SEATING_TYPE] is [GUEST_SEATING] with a total of [NUMBER_OF_GUEST] guests.\n\nPlease find attached a unique QR code link for hassle-free check-in during the event. Kindly note that this code is unique and non-transferable.\n[GUEST_URL]\n\nBest Regards,\n[ORGANIZER]'
    },
    {   
        templateId: 'event_qrcode_en_2',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '2',
        custom: false,
        msg: '[WEDDING_TITLE]\n\nDear valued guest,\n\nWe cordially invite you to attend our upcoming event, which will take place on [WEDDING_DATE] at [WEDDING_START_TIME] in [WEDDING_VENUE_NAME]. To ensure a seamless check-in process, we have attached a unique QR code link for your convenience.\n[GUEST_URL]\n\nWe look forward to seeing you there.\n\nWarm regards,\n[ORGANIZER]'
    },
    {   
        templateId: 'event_qrcode_en_3',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '3',
        custom: false,
        msg: '[WEDDING_TITLE]\n\nDear [GUEST_NAME],\n\nWe\'re thrilled to invite you to our event, happening on [WEDDING_DATE] at [WEDDING_START_TIME] in [WEDDING_VENUE_NAME]. You\'ll be seated at [SEATING_TYPE] [GUEST_SEATING] with [NUMBER_OF_GUEST] guest(s).\n\nTo make the check-in process seamless, we\'ve created a unique QR code link just for you. Simply click on the link below to access your digital ticket.\n[GUEST_URL]\n\nIf you have any questions about the event, please contact the organizer [ORGANIZER]. No RSVP is needed.\n\nWe can\'t wait to see you there!\n\nWarmest regards,\n[ORGANIZER]'
    },
    {   
        templateId: 'event_qrcode_en_4',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '4',
        custom: false,
        msg: 'Dear [GUEST_NAME],\n\n📢 You\'re cordially invited to the prestigious [WEDDING_TITLE]!\nDate: [WEDDING_DATE]\nTime: [WEDDING_START_TIME]\nVenue: [WEDDING_VENUE_NAME]\n\n🔖 We are pleased to provide you with a unique Guest QR Code for a seamless check-in experience. Simply present the QR Code upon arrival, and our team will be ready to assist you.\n[GUEST_URL]\n\nWe look forward to welcoming you to this exceptional event!'
    },
    {   
        templateId: 'event_qrcode_en_5',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '5',
        custom: false,
        msg: '🌟 You\'re cordially invited to our special event!\n\nEvent: [WEDDING_TITLE]\nDate: [WEDDING_DATE]\nTime: [WEDDING_START_TIME]\nVenue: [WEDDING_VENUE_NAME]\n\n📲 To ensure a seamless check-in experience, we have attached your unique Guest QR Code. Simply present it upon arrival for hassle-free entry.\n[GUEST_URL]\n\nWe look forward to having you join us for this memorable occasion!'
    },
    {   
        templateId: 'event_qrcode_en_6',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '6',
        custom: false,
        msg: '📅 Save the Date!\n\nYou are cordially invited to attend the [WEDDING_TITLE].\nDate: [WEDDING_DATE]\nTime: [WEDDING_START_TIME]\nVenue: [WEDDING_VENUE_NAME]\n\n✨ To ensure a smooth check-in process, we have attached your unique Guest QR Code. Simply present it upon arrival for easy entry.\n[GUEST_URL]\n\nWe look forward to your presence at this special event!'
    },
    {   
        templateId: 'event_qrcode_en_7',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '7',
        custom: false,
        msg: 'Dear [GUEST_NAME],\n\nWe are delighted to confirm your registration for [WEDDING_TITLE], which will take place on [WEDDING_DATE] at [WEDDING_START_TIME] at [WEDDING_VENUE_NAME]. To ensure a seamless check-in process, we have attached a unique QR code link for your convenience: [GUEST_URL].\n\nWe look forward to seeing you there.'
    },
    {   
        templateId: 'event_reminder_en_1',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '1',
        custom: false,
        msg: 'Dear [GUEST_NAME],\n\nWe would like to kindly remind you about the upcoming [WEDDING_TITLE] scheduled on [WEDDING_DATE] at [WEDDING_START_TIME] in [WEDDING_VENUE_NAME].\n\nWe are excited to have you join us for this special occasion. As part of our preparations, we have carefully arranged the guest seating to ensure everyone\'s comfort and enjoyment throughout the event. Your assigned [SEATING_TYPE] will be communicated to you upon arrival.\n\nPlease note that the total number of guests attending the event is [NUMBER_OF_GUEST]. We have taken great care in organizing this event, and we are confident that it will be a memorable experience for all.\n\nYour QR Code: [GUEST_URL]\n\nIf you have any questions or require further information, we kindly request that you reach out to the event organizer directly.\n\nThank you for your cooperation, and we look forward to welcoming you at [WEDDING_TITLE]!\n\nBest regards,\n[ORGANIZER]'
    },
    {   
        templateId: 'event_reminder_en_2',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '2',
        custom: false,
        msg: 'Dear [GUEST_NAME],\n\nThis is a friendly reminder of our upcoming event, [WEDDING_TITLE], which will be held on [WEDDING_DATE] at [WEDDING_START_TIME] in [WEDDING_VENUE_NAME]. We hope you are looking forward to it as much as we are.\n\nYour QR Code: [GUEST_URL]\n\nIf you have any questions or concerns, please do not hesitate to contact the event organizer directly.\n\nWe look forward to seeing you at the event.\n\nBest regards,\n[ORGANIZER]'
    },
    {   
        templateId: 'event_reminder_en_3',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '3',
        custom: false,
        msg: 'Dear [GUEST_NAME],\n\nWe hope this message finds you well. This is a friendly reminder for the upcoming [WEDDING_TITLE] scheduled on [WEDDING_DATE] at [WEDDING_START_TIME]. The event will be held at [WEDDING_VENUE_NAME].\n\nAs a valued guest, we wanted to ensure that you have all the necessary details for a seamless and enjoyable experience. Please mark your calendar and make the necessary arrangements to join us on this special occasion.\n\nYour QR Code: [GUEST_URL]\n\nWe look forward to celebrating with you at the [WEDDING_TITLE]. Thank you for being a part of this memorable event.'
    },
    {   
        templateId: 'event_reminder_en_4',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '4',
        custom: false,
        msg: 'Dear [GUEST_NAME],\n\nWe hope this message finds you well. We would like to take this opportunity to send you a reminder regarding the upcoming [WEDDING_TITLE] scheduled for [WEDDING_DATE] at [WEDDING_START_TIME] at [WEDDING_VENUE_NAME].\n\nAs one of our esteemed guests, we are excited to have you join us for this special occasion. We would like to inform you that seating arrangements have been carefully organized to ensure your comfort and enjoyment throughout the event. Please note that you have been assigned [SEATING_TYPE] [GUEST_SEATING], and we kindly request that you occupy your designated [SEATING_TYPE] during the event.\n\nIn total, we are expecting [NUMBER_OF_GUEST] attendees to join us, creating a vibrant and memorable atmosphere. We have put considerable effort into ensuring a delightful experience for all guests, and we look forward to celebrating this occasion together.\n\nQR Code: [GUEST_URL]\n\nIf you have any questions or require further information regarding the event, we kindly request that you reach out to the event organizer directly.\n\nWarm regards,\n[ORGANIZER]'
    },
    {   
        templateId: 'event_thank_you_en_1',
        blastType: 'whatsapp',
        enable: true,
        eventMode: true,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '1',
        custom: false,
        msg: '[WEDDING_TITLE]\n\nDear [GUEST_NAME],\n\nWe are incredibly grateful for your support and the positive energy you brought to the event. Your presence added to the joyful atmosphere and created an environment of celebration and camaraderie.\n\nWe hope that you enjoyed the [WEDDING_TITLE] and had a fantastic time connecting with fellow attendees. Your enthusiasm and engagement were truly appreciated, and we couldn\'t have asked for a better group of guests.\n\nWe would also like to extend a special thank you for your kind words, encouragement, and valuable feedback. Your feedback helps us improve our future events and ensures that we continue to provide exceptional experiences for our guests.\n\nOnce again, thank you for being a part of [WEDDING_TITLE]. Your presence made a significant difference, and we hope to have the pleasure of hosting you again in the future.\n\nBest regards,\n[ORGANIZER]'
    },
    {   
        templateId: 'event_thank_you_en_2',
        blastType: 'whatsapp',
        enable: true,
        eventMode: true,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '2',
        custom: false,
        msg: '[WEDDING_TITLE]\n\nDear [GUEST_NAME],\n\nWe wanted to take a moment to express our sincerest gratitude for your presence at [WEDDING_TITLE]. It was an absolute pleasure to have you there and we hope that you enjoyed the event as much as we did.\n\nYour presence added to the joy and memories of the occasion. We appreciate you taking the time to celebrate with us and your presence made the day even more special.\n\nThank you once again for your support and love. We hope to see you again soon.\n\nWarm regards,\n[ORGANIZER]'
    },
    {   
        templateId: 'event_thank_you_en_3',
        blastType: 'whatsapp',
        enable: true,
        eventMode: true,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '3',
        custom: false,
        msg: '[WEDDING_TITLE]\n\nDear [GUEST_NAME],\n\nWe are filled with immense joy and gratitude as we reflect on our wedding day. It was truly a magical celebration, and your presence made it even more special.\n\nThank you from the bottom of our hearts for joining us and being a part of our journey. Your love, support, and warm wishes meant the world to us. We hope you had a wonderful time, created beautiful memories, and enjoyed every moment of the festivities.\n\nPlease know that your presence was the greatest gift we could have asked for. We are truly grateful for your love and friendship.\n\nOnce again, thank you for being a part of our special day and making it unforgettable.\n\nWith heartfelt appreciation,\n[ORGANIZER]'
    },
    {   
        templateId: 'event_thank_you_en_4',
        blastType: 'whatsapp',
        enable: true,
        eventMode: true,
        language: {
            value: 'en',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '4',
        custom: false,
        msg: 'Dear [GUEST_NAME],\n\nWe would like to extend our heartfelt gratitude for gracing us with your presence at [WEDDING_TITLE]. It was truly a pleasure having you as our guest, and your attendance added immense joy and warmth to the event.\n\nYour presence contributed to the success of the occasion and created cherished memories that will be treasured for years to come. We are sincerely grateful for your support and for taking the time to join us on this special day.\n\nWe hope you enjoyed the event and that it exceeded your expectations. Your participation and engagement made a significant impact, and we are truly thankful for your involvement in making this event a resounding success.\n\nWe look forward to future opportunities to gather and create more memorable moments together. Should you have any feedback or suggestions about the event, please do not hesitate to share them with us.\n\nThank you once again for your presence, and we hope to see you again soon.\n\nWarmest regards,\n[ORGANIZER]'
    },
    {   
        templateId: 'event_qrcode_ms_1',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '1',
        custom: false,
        msg: '[GUEST_NAME] yang dihormati,\n\nAnda dijemput untuk menghadiri acara kami yang akan datang, [WEDDING_TITLE], pada [WEDDING_DATE] pada [WEDDING_START_TIME] yang terletak di [WEDDING_VENUE_NAME]. Susunan [SEATING_TYPE] anda adalah pada [GUEST_SEATING] dengan jumlah [NUMBER_OF_GUEST] tetamu.\n\nSila dapatkan pautan kod QR unik yang dilampirkan untuk daftar masuk tanpa kerumitan semasa acara. Sila ambil perhatian bahawa kod ini adalah unik dan tidak boleh dipindah milik.\n[GUEST_URL]\n\nUntuk sebarang pertanyaan, sila hubungi pihak penganjur. Kami mengalu-alukan kedatangan anda ke majlis kami.'
    },
    {   
        templateId: 'event_qrcode_ms_2',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '2',
        custom: false,
        msg: '[GUEST_NAME] yang dihormati,\n\nKami dengan hormatnya menjemput anda untuk menghadiri acara kami yang akan datang, [WEDDING_TITLE], yang akan berlangsung pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME]. Untuk memastikan proses daftar masuk yang lancar, kami telah melampirkan pautan kod QR yang unik untuk kemudahan anda.\n[GUEST_URL]\n\nKami tidak sabar-sabar untuk melihat anda di sana.\n\nSelamat sejahtera,\n[ORGANIZER]'
    },
    {   
        templateId: 'event_qrcode_ms_3',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '3',
        custom: false,
        msg: '[GUEST_NAME] yang dihormati,\n\nKami sangat teruja untuk menjemput anda ke [WEDDING_TITLE], yang berlangsung pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME]. Anda akan duduk di [SEATING_TYPE] [GUEST_SEATING] dengan [NUMBER_OF_GUEST] tetamu.\n\nUntuk menjadikan proses daftar masuk berjalan lancar, kami telah mencipta pautan kod QR unik hanya untuk anda. Hanya klik pada pautan di bawah untuk mengakses tiket digital anda.\n[GUEST_URL]\n\nKami tidak sabar untuk melihat anda di sana!'
    },
    {   
        templateId: 'event_qrcode_ms_4',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '4',
        custom: false,
        msg: '📢 Anda dijemput dengan hormatnya ke [WEDDING_TITLE] yang berprestij!\nTarikh: [WEDDING_DATE]\nMasa: [WEDDING_START_TIME]\nTempat: [WEDDING_VENUE_NAME]\n\n🔖 Kami berbesar hati untuk memberikan anda Kod QR Tetamu yang unik untuk pengalaman daftar masuk yang lancar. Hanya tunjukkan Kod QR semasa ketibaan, dan pasukan kami akan bersedia untuk membantu anda.\n[GUEST_URL]\n\n📧 Untuk sebarang pertanyaan atau maklumat tambahan, sila hubungi terus penganjur acara.\n\nKami tidak sabar-sabar untuk mengalu-alukan anda ke acara yang luar biasa ini!'
    },
    {   
        templateId: 'event_reminder_ms_1',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '1',
        custom: false,
        msg: '[GUEST_NAME] yang dihormati,\n\nKami ingin mengingatkan anda tentang [WEDDING_TITLE] akan datang yang dijadualkan pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME].\n\nKami teruja untuk anda menyertai kami untuk majlis istimewa ini. Sebagai sebahagian daripada persiapan kami, kami telah mengatur tempat duduk tetamu dengan teliti untuk memastikan keselesaan dan keseronokan semua orang sepanjang acara. [SEATING_TYPE] yang diberikan kepada anda akan dimaklumkan kepada anda semasa ketibaan.\n\nSila ambil perhatian bahawa jumlah tetamu yang menghadiri acara itu ialah [NUMBER_OF_GUEST]. Kami telah mengambil berat dalam menganjurkan acara ini, dan kami yakin ia akan menjadi pengalaman yang tidak dapat dilupakan untuk semua.\n\nKod QR anda [GUEST_URL]\n\nJika anda mempunyai sebarang pertanyaan atau memerlukan maklumat lanjut, kami mohon agar anda menghubungi terus penganjur acara.\n\nTerima kasih atas kerjasama anda, dan kami mengalu-alukan kedatangan anda di [WEDDING_TITLE]!\n\nSelamat sejahtera,\n[ORGANIZER]'
    },
    {   
        templateId: 'event_reminder_ms_2',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '2',
        custom: false,
        msg: '[GUEST_NAME] yang dihormati,\n\nIni adalah peringatan mesra acara kami yang akan datang, [WEDDING_TITLE], yang akan diadakan pada [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME]. Kami berharap anda menantikannya seperti kami.\n\nKod QR anda [GUEST_URL]\n\nKami tidak sabar-sabar untuk melihat anda di acara tersebut.\n\nSelamat sejahtera,\n[ORGANIZER]'
    },
    {   
        templateId: 'event_reminder_ms_3',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '3',
        custom: false,
        msg: '[GUEST_NAME] yang dihormati,\n\nIni adalah peringatan mesra untuk [WEDDING_TITLE] akan datang yang dijadualkan pada [WEDDING_DATE] pada [WEDDING_START_TIME]. Acara akan diadakan di [WEDDING_VENUE_NAME].\n\nKod QR anda [GUEST_URL]\n\nSebagai tetamu yang dihargai, kami ingin memastikan anda mempunyai semua butiran yang diperlukan untuk pengalaman yang lancar dan menyeronokkan.\n\nKami tidak sabar-sabar untuk meraikan bersama anda di [WEDDING_TITLE]. Terima kasih kerana menjadi sebahagian daripada acara yang tidak dapat dilupakan ini.'
    },
    {   
        templateId: 'event_reminder_ms_4',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '4',
        custom: false,
        msg: '[GUEST_NAME] yang dihormati,\n\nKami ingin mengambil kesempatan ini untuk menghantar peringatan kepada anda mengenai [WEDDING_TITLE] akan datang yang dijadualkan untuk [WEDDING_DATE] pada [WEDDING_START_TIME] di [WEDDING_VENUE_NAME].\n\nSebagai salah seorang tetamu yang dihormati, kami teruja untuk menyertai anda untuk majlis istimewa ini. Kami ingin memaklumkan kepada anda bahawa susunan tempat duduk telah diatur dengan teliti untuk memastikan keselesaan dan keseronokan anda sepanjang majlis berlangsung. Sila ambil perhatian bahawa anda telah diberikan [SEATING_TYPE] di [GUEST_SEATING], dan kami mohon agar anda menduduki tempat duduk yang ditetapkan semasa acara tersebut.\n\nSecara keseluruhan, kami menjangkakan [NUMBER_OF_GUEST] hadirin untuk menyertai kami, mewujudkan suasana yang meriah dan tidak dapat dilupakan. Kami telah melakukan banyak usaha untuk memastikan pengalaman yang menggembirakan untuk semua tetamu, dan kami tidak sabar-sabar untuk meraikan majlis ini bersama-sama.\n\nKod QR anda [GUEST_URL]\n\nSalam Mesra\n[ORGANIZER]'
    },
    {   
        templateId: 'event_thank_you_ms_1',
        blastType: 'whatsapp',
        enable: true,
        eventMode: true,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '1',
        custom: false,
        msg: '[GUEST_NAME] yang dihormati,\n\nKami amat berterima kasih atas sokongan anda dan tenaga positif yang anda bawa ke acara tersebut. Kehadiran anda menambahkan lagi suasana ceria dan mewujudkan suasana perayaan dan kemesraan.\n\nKami berharap anda menikmati [WEDDING_TITLE] dan mempunyai masa yang hebat berhubung dengan rakan-rakan hadirin. Keghairahan dan penglibatan anda benar-benar dihargai, dan kami tidak mungkin meminta kumpulan tetamu yang lebih baik.\n\nKami juga ingin mengucapkan terima kasih khas atas kata-kata baik anda, galakan, dan maklum balas yang berharga. Maklum balas anda membantu kami menambah baik acara masa hadapan kami dan memastikan kami terus memberikan pengalaman yang luar biasa untuk tetamu kami.\n\nSekali lagi, terima kasih kerana menjadi sebahagian daripada [WEDDING_TITLE]. Kehadiran anda membuat perbezaan yang ketara, dan kami berharap dapat berbesar hati untuk menjadi tuan rumah anda lagi pada masa hadapan.\n\nSalam hormat,\n[ORGANIZER]'
    },
    {   
        templateId: 'event_thank_you_ms_2',
        blastType: 'whatsapp',
        enable: true,
        eventMode: true,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '2',
        custom: false,
        msg: '[WEDDING_TITLE]\n\n[GUEST_NAME] yang dihormati,\n\nKami ingin meluangkan sedikit masa untuk merakamkan ucapan terima kasih yang tulus atas kehadiran anda di [WEDDING_TITLE]. Kami sangat berbesar hati untuk berada di sana dan kami berharap anda menikmati acara tersebut seperti yang kami lakukan.\n\nKehadiran anda menambahkan lagi keceriaan dan kenangan pada majlis tersebut. Kami menghargai anda meluangkan masa untuk meraikan bersama kami dan kehadiran anda menjadikan hari itu lebih istimewa.\n\nKami berharap dapat berjumpa lagi tidak lama lagi.\n\nSalam mesra,\n[ORGANIZER]'
    },
    {   
        templateId: 'event_thank_you_ms_3',
        blastType: 'whatsapp',
        enable: true,
        eventMode: true,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '3',
        custom: false,
        msg: '[WEDDING_TITLE]\n\n[GUEST_NAME] yang dihormati,\n\nKami dipenuhi dengan kegembiraan dan kesyukuran yang luar biasa semasa kami merenung hari perkahwinan kami. Ia benar-benar sambutan yang ajaib, dan kehadiran anda menjadikannya lebih istimewa.\n\nTerima kasih dari lubuk hati kami kerana menyertai kami dan menjadi sebahagian daripada perjalanan kami. Kasih sayang, sokongan dan ucapan hangat anda amat bermakna bagi kami. Kami berharap anda mempunyai masa yang indah, mencipta kenangan indah dan menikmati setiap saat perayaan.\n\nHarap maklum bahawa kehadiran anda adalah hadiah terhebat yang kami boleh minta. Kami benar-benar berterima kasih atas kasih sayang dan persahabatan anda.\n\nSekali lagi, terima kasih kerana menjadi sebahagian daripada hari istimewa kami dan menjadikannya tidak dapat dilupakan.\n\nDengan setinggi-tinggi penghargaan,\n[ORGANIZER]'
    },
    {   
        templateId: 'event_thank_you_ms_4',
        blastType: 'whatsapp',
        enable: true,
        eventMode: true,
        language: {
            value: 'ms',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '4',
        custom: false,
        msg: '[GUEST_NAME] yang dihormati,\n\nKami ingin merakamkan setinggi-tinggi rasa terima kasih kerana menghargai kehadiran anda di [WEDDING_TITLE]. Sungguh menggembirakan anda sebagai tetamu kami, dan kehadiran anda menambahkan kegembiraan dan kemesraan yang luar biasa kepada acara tersebut.\n\nKehadiran anda menyumbang kepada kejayaan majlis itu dan mencipta kenangan terindah yang akan dihargai untuk tahun-tahun mendatang. Kami amat berterima kasih atas sokongan anda dan kerana meluangkan masa untuk menyertai kami pada hari istimewa ini.\n\nKami harap anda menikmati acara ini dan ia melebihi jangkaan anda. Penyertaan dan penglibatan anda memberi impak yang besar, dan kami amat berterima kasih atas penglibatan anda dalam menjayakan acara ini.\n\nTerima kasih sekali lagi atas kehadiran anda, dan kami berharap dapat berjumpa lagi tidak lama lagi.\n\nSelamat sejahtera,\n[ORGANIZER]'
    },
    {   
        templateId: 'event_qrcode_zh_1',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '1',
        custom: false,
        msg: '尊敬的 [GUEST_NAME] ，\n\n诚挚地邀请您参加我们即将于 [WEDDING_DATE] [WEDDING_START_TIME] 在 [WEDDING_VENUE_NAME] 举行的活动 [WEDDING_TITLE]。您的[SEATING_TYPE]安排在 [GUEST_SEATING] ，共有 [NUMBER_OF_GUEST] 位宾客。\n\n请在附件中找到一个独特的QR Code链接，以便在活动期间轻松签到。\n[GUEST_URL]\n\n如有任何疑问，请随时联系活动组织者。我们期待着欢迎您参加我们的活动。'
    },
    {   
        templateId: 'event_qrcode_zh_2',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '2',
        custom: false,
        msg: '尊敬的 [GUEST_NAME] ，\n\n非常荣幸邀请您参加即将举行的活动 [WEDDING_TITLE] ，时间为 [WEDDING_DATE] ，地点位于 [WEDDING_VENUE_NAME] 。您的座位已安排在[SEATING_TYPE] [GUEST_SEATING] 。\n\n为了方便您的参与，请点击以下连结获取您的宾客 QR Code，您将可以在活动期间轻松进行签到。\n[GUEST_URL]\n\n如果您有任何疑问或需要进一步的资讯，请随时联系活动组织者。我们迫不及待地期待着您的到来，一同共度这个特别的活动。\n\n感谢您的支持与参与。\n\n诚挚问候，\n[ORGANIZER]'
    },
    {   
        templateId: 'event_qrcode_zh_3',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '3',
        custom: false,
        msg: '亲爱的 [GUEST_NAME] ，\n\n我们诚挚邀请您出席即将于 [WEDDING_DATE] [WEDDING_START_TIME] 在 [WEDDING_VENUE_NAME] 举行的活动 [WEDDING_TITLE] 。您已被安排在 [SEATING_TYPE] [GUEST_SEATING] 。\n\n为了方便您在活动期间顺利签到，请使用以下宾客QR Code连结。只需扫描QR Code，即可完成签到手续。\n[GUEST_URL]\n\n如果您对活动有任何疑问或需要进一步的协助，请随时联系活动组织者。我们非常期待您的参与，共同创造一个难忘的活动体验。\n\n再次感谢您的支持与参与，期待与您共度这个特别的时刻！\n\n真挚的问候，\n[ORGANIZER]'
    },
    {   
        templateId: 'event_qrcode_zh_4',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '4',
        custom: false,
        msg: '📢 诚挚邀请您参加 [WEDDING_TITLE]\n日期： [WEDDING_DATE]\n时间： [WEDDING_START_TIME]\n地点： [WEDDING_VENUE_NAME]\n\n🔖 我们很高兴为您提供一个独特的宾客QR Code，以方便您在抵达会场时快速签到。只需在抵达时出示QR Code，我们的团队将随时为您提供帮助。\n[GUEST_URL]\n\n我们期待着欢迎您参加这一非凡的活动！'
    },
    {   
        templateId: 'event_reminder_zh_1',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '1',
        custom: false,
        msg: '亲爱的 [GUEST_NAME]，\n\n感谢您报名参加 [WEDDING_TITLE] ！活动即将到来，我们迫不及待地期待与您共度这个特别的时刻。\n\n活动日期： [WEDDING_DATE]\n活动时间： [WEDDING_START_TIME]\n活动地点： [WEDDING_VENUE_NAME]\nQR Code： [GUEST_URL]\n\n再次感谢您的参与！我们期待着与您在活动中相见！\n\n诚挚感谢，\n[ORGANIZER]'
    },
    {   
        templateId: 'event_reminder_zh_2',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '2',
        custom: false,
        msg: '亲爱的 [GUEST_NAME]，\n\n温馨提醒您出席 [WEDDING_TITLE]，活动将于 [WEDDING_DATE] ， [WEDDING_START_TIME] 开始，在 [WEDDING_VENUE_NAME] 举办。\n\n作为我们的尊贵宾客，我们已为您预留了 [NUMBER_OF_GUEST] 个座位。请在下方查看您的座位号码：\n\n[SEATING_TYPE]： [GUEST_SEATING]\n\n为了方便您的入场手续，我们特别提供了个人化的宾客QR code。请点击下方连结，以便在活动当天快速签到出席： [GUEST_URL]\n\n我们期待与您共度一个有意义且难忘的活动。如果您有任何变更或无法出席的情况，请提前通知我们，以便我们进行相应的安排。\n\n再次感谢您的参与，我们期待在活动中与您见面！\n\n[ORGANIZER]'
    },
    {   
        templateId: 'event_reminder_zh_3',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '3',
        custom: false,
        msg: '亲爱的 [GUEST_NAME] ，\n\n感谢您参与 [WEDDING_TITLE] ！我们将在 [WEDDING_DATE] 的 [WEDDING_START_TIME] 在 [WEDDING_VENUE_NAME] 举办这场盛会。\n\n我们为您预留了 [NUMBER_OF_GUEST] 个座位，请查看以下座位资讯：\n\n[SEATING_TYPE]： [GUEST_SEATING]\n\n为了让您更顺利入场，我们准备了个人化的宾客QR code。请点击下方连结以获取您的QR code，届时您只需扫描该码即可快速签到： [GUEST_URL]\n\n如果您有任何变更或无法出席的情况，请提前通知我们，以便我们做出相应的安排。\n\n非常感谢您的参与，我们期待在活动中与您相见！\n\n[ORGANIZER]'
    },
    {   
        templateId: 'event_reminder_zh_4',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '4',
        custom: false,
        msg: '亲爱的 [GUEST_NAME] ，\n\n感谢您参加我们即将在 [WEDDING_DATE] [WEDDING_START_TIME] 在 [WEDDING_VENUE_NAME] 举办的 [WEDDING_TITLE] 。我们诚挚地邀请您参与这个特别的场合，并希望能与您共同度过一个难忘的时刻。\n\n作为我们的尊贵客人之一，您的出席对我们来说至关重要。我们已经为您准备了一个专属的座位，以确保您在活动期间享受到舒适和愉悦的体验。请注意，您已被安排在 [SEATING_TYPE] - [GUEST_SEATING] ，请您在活动期间就坐于该座位。\n\n再次感谢您的参与，期待与您共同分享这个美好的时刻。如有任何变动或更新，我们将及时通知您。\n\n最诚挚的问候，\n[ORGANIZER]'
    },
    {   
        templateId: 'event_thank_you_zh_1',
        blastType: 'whatsapp',
        enable: true,
        eventMode: true,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '1',
        custom: false,
        msg: '亲爱的 [GUEST_NAME] ，\n\n感谢您莅临 [WEDDING_TITLE] ！在这特殊的时刻，您的参与让我们的活动更加精彩和有意义。我们想向每一位与会者表达衷心的感谢和诚挚的祝福。\n\n在这次活动中，我们见证了大家的热情和互动。您的积极参与和分享，让活动充满了欢笑和温暖的回忆。我们深深感谢您在活动中的参与，您的存在和参与为我们带来了无价的价值。\n\n再次感谢您的参与和支持 [WEDDING_TITLE] ，您是我们活动的重要一环。祝愿您和您的家人平安幸福，未来充满更多的喜悦和成功。期待与您在下次活动中再次相见！\n\n诚挚感谢，\n[ORGANIZER]'
    },
    {   
        templateId: 'event_thank_you_zh_2',
        blastType: 'whatsapp',
        enable: true,
        eventMode: true,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '2',
        custom: false,
        msg: '亲爱的 [GUEST_NAME] ，\n\n感谢您参加 [WEDDING_TITLE] ！活动已经圆满结束，我们希望这次的活动能给您留下美好的回忆和宝贵的经验。\n\n我们希望您在活动中度过了一段美好的时光，并且与我们和其他与会者建立了有意义的联系。感谢您为活动带来的能量和热情，我们感到无比荣幸能与您共度这个特别的时刻。\n\n再次感谢您的参与和支持。我们期待着再次与您相聚，一同创造更多难忘的回忆。如有任何问题或意见，请随时与我们联系。\n\n[ORGANIZER]'
    },
    {   
        templateId: 'event_thank_you_zh_3',
        blastType: 'whatsapp',
        enable: true,
        eventMode: true,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '3',
        custom: false,
        msg: '亲爱的 [GUEST_NAME] ，\n\n感谢您们莅临 [WEDDING_TITLE] ！活动结束后，我们想向每一位与会者表达我们最真挚的感谢和欣慰之情。\n\n您的参与使这次活动变得更加特别和成功。您的热情参与和支持让我们充满了能量和动力。无论是您的热情参与、对活动的贡献、分享的知识和经验，或是与我们一同度过的美好时光，都让这次活动成为一个难忘的回忆。\n\n我们非常感激您对我们活动的支持和参与，您的存在和参与让这个活动变得更加丰富和有意义。您的每一个贡献和互动都对我们来说都是无价的。\n\n谢谢您对我们的信任和支持，我们希望这次活动能够为您带来美好的回忆和宝贵的经验。期待未来能够再次见到您，继续分享和创造更多难忘的时刻。\n\n再次感谢您的参与和支持！\n\n衷心的祝福，\n[ORGANIZER]'
    },
    {   
        templateId: 'event_thank_you_zh_4',
        blastType: 'whatsapp',
        enable: true,
        eventMode: true,
        language: {
            value: 'zh',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '4',
        custom: false,
        msg: '亲爱的 [GUEST_NAME] ，\n\n非常感谢您参加 [WEDDING_TITLE] ！在这特别的时刻，您的参与对我们来说意义非凡。我们希望您度过了一个愉快且难忘的时光。\n\n您的参与和支持是我们活动成功的关键所在。无论是您的热情参与、丰富的交流还是对活动的积极反馈，都使得活动更加精彩而有意义。我们深感荣幸能够与您共度这美好的时刻。\n\n活动结束后，我们也将竭尽全力处理您提出的问题和意见。您的反馈对于我们的成长和改进至关重要。我们会将您的意见纳入考虑，并努力提供更好的活动体验。\n\n再次感谢您的参与和支持！我们衷心希望未来能够再次与您相聚，为您带来更多精彩和难忘的时刻。\n\n祝您一切顺利，幸福安康！\n[ORGANIZER]'
    },
    {   
        templateId: 'event_qrcode_zh_tw_1',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '1',
        custom: false,
        msg: '尊敬的 [GUEST_NAME] ，\n\n誠摯地邀請您參加我們即將於 [WEDDING_DATE] [WEDDING_START_TIME] 在 [WEDDING_VENUE_NAME] 舉行的活動 [WEDDING_TITLE] 。您的[SEATING_TYPE]安排在 [GUEST_SEATING] ，共有 [NUMBER_OF_GUEST] 位賓客。\n\n請通過以下 QR Code 鏈接在活動期間輕鬆簽到。\n[GUEST_URL]\n\n如有任何疑問，請隨時聯繫活動組織者。我們期待著歡迎您參加我們的活動。\n\n誠摯問候，\n[ORGANIZER]'
    },
    {   
        templateId: 'event_qrcode_zh_tw_2',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '2',
        custom: false,
        msg: '尊敬的 [GUEST_NAME] ，\n\n非常榮幸邀請您參加即將舉行的活動 [WEDDING_TITLE] ，時間為 [WEDDING_DATE] ，地點位於 [WEDDING_VENUE_NAME] 。您的[SEATING_TYPE]已安排在 [GUEST_SEATING] 。\n\n為了方便您的參與，請點擊以下連結獲取您的賓客 QR Code，您將可以在活動期間輕鬆進行簽到。\n[GUEST_URL]\n\n如果您有任何疑問或需要進一步的資訊，請隨時聯繫活動組織者。我們迫不及待地期待著您的到來，一同共度這個特別的活動。\n\n感謝您的支持與參與。\n\n[ORGANIZER]'
    },
    {   
        templateId: 'event_qrcode_zh_tw_3',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '3',
        custom: false,
        msg: '親愛的 [GUEST_NAME] ，\n\n我們誠摯邀請您出席即將於 [WEDDING_DATE] [WEDDING_START_TIME] 在 [WEDDING_VENUE_NAME] 舉行的 [WEDDING_TITLE] 。您已被安排在 [SEATING_TYPE] [GUEST_SEATING] 。\n\n為了方便您在活動期間順利簽到，請使用以下的 QR Code 連結。只需掃描 QR Code，即可完成簽到手續。\n[GUEST_URL]\n\n如果您對活動有任何疑問或需要進一步的協助，請隨時聯繫活動組織者。我們非常期待您的參與，共同創造一個難忘的活動體驗。\n\n再次感謝您的支持與參與，期待與您共度這個特別的時刻！\n\n[ORGANIZER]'
    },
    {   
        templateId: 'event_qrcode_zh_tw_4',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'qrcode',
            custom: false
        },
        templateNo: '4',
        custom: false,
        msg: '📢 誠摯邀請您參加 [WEDDING_TITLE]\n日期： [WEDDING_DATE]\n時間： [WEDDING_START_TIME]\n地點： [WEDDING_VENUE_NAME]\n\n🔖 我們很高興為您提供一個獨特的賓客 QR Code，以方便您在抵達會場時快速簽到。只需在抵達時出示 QR Code，我們的團隊將隨時為您提供幫助。\n[GUEST_URL]\n\n我們期待著歡迎您參加這一非凡的活動！\n\n最誠摯的問候，\n[ORGANIZER]'
    },
    {   
        templateId: 'event_reminder_zh_tw_1',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '1',
        custom: false,
        msg: '親愛的 [GUEST_NAME] ，\n\n感謝您報名參加 [WEDDING_TITLE] ！活動即將到來，我們迫不及待地期待與您共度這個特別的時刻。\n\n活動日期： [WEDDING_DATE]\n活動時間： [WEDDING_START_TIME]\n活動地點： [WEDDING_VENUE_NAME]\nQR Code： [GUEST_URL]\n\n如果您有任何問題或需要進一步的資訊，請隨時與我們聯繫。我們將竭誠為您提供幫助。\n\n再次感謝您的參與！我們期待著與您在活動中相見！\n\n誠摯感謝，\n[ORGANIZER]'
    },
    {   
        templateId: 'event_reminder_zh_tw_2',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '2',
        custom: false,
        msg: '親愛的 [GUEST_NAME] ，\n\n溫馨提醒您出席 [WEDDING_TITLE] ，活動將於 [WEDDING_DATE] ， [WEDDING_START_TIME] 開始，在 [WEDDING_VENUE_NAME] 舉辦。\n\n作為我們的尊貴賓客，我們已為您預留了 [NUMBER_OF_GUEST] 個座位。請在下方查看您的座位號碼：\n\n[SEATING_TYPE]： [GUEST_SEATING]\n\n為了方便您的入場手續，我們特別提供了個人化的賓客 QR code。請點擊下方連結，以便在活動當天快速簽到出席：\n[GUEST_URL]\n\n我們期待與您共度一個有意義且難忘的活動。如果您有任何變更或無法出席的情況，請提前通知我們，以便我們進行相應的安排。\n\n再次感謝您的參與，我們期待在活動中與您見面！\n\n[ORGANIZER]'
    },
    {   
        templateId: 'event_reminder_zh_tw_3',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '3',
        custom: false,
        msg: '親愛的 [GUEST_NAME] ，\n\n感謝您參與 [WEDDING_TITLE] ！我們在 [WEDDING_DATE] 的 [WEDDING_START_TIME] 在 [WEDDING_VENUE_NAME] 舉辦這場盛會。\n\n我們為您預留了 [NUMBER_OF_GUEST] 個座位，請查看以下座位資訊：\n\n[SEATING_TYPE]： [GUEST_SEATING]\n\n為了讓您更順利入場，我們準備了個人化的賓客 QR code。請點擊下方連結以獲取您的QR code，屆時您只需掃描該碼即可快速簽到： [GUEST_URL]\n\n如果您有任何變更或無法出席的情況，請提前通知我們，以便我們做出相應的安排。\n\n非常感謝您的參與，我們期待在活動中與您相見！\n\n[ORGANIZER]'
    },
    {   
        templateId: 'event_reminder_zh_tw_4',
        blastType: 'whatsapp',
        enable: true,
        url: true,
        eventMode: true,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'reminder',
            custom: false
        },
        templateNo: '4',
        custom: false,
        msg: '親愛的 [GUEST_NAME] ，\n\n感謝您參加我們即將在 [WEDDING_DATE] [WEDDING_START_TIME] 在 [WEDDING_VENUE_NAME] 舉辦的 [WEDDING_TITLE]。我們誠摯地邀請您參與這個特別的場合，並希望能與您共同度過一個難忘的時刻。\n\n作為我們的尊貴客人之一，您的出席對我們來說至關重要。我們已經為您準備了一個專屬的座位，以確保您在活動期間享受到舒適和愉悅的體驗。請注意，您已被安排在[SEATING_TYPE] [GUEST_SEATING] ，請您在活動期間就坐於該座位。\n\n再次感謝您的參與，期待與您共同分享這個美好的時刻。如有任何變動或更新，我們將及時通知您。\n\n最誠摯的問候，\n[ORGANIZER]'
    },
    {   
        templateId: 'event_thank_you_zh_tw_1',
        blastType: 'whatsapp',
        enable: true,
        eventMode: true,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '1',
        custom: false,
        msg: '親愛的 [GUEST_NAME] ，\n\n感謝您蒞臨 [WEDDING_TITLE] ！在這特殊的時刻，您的參與讓我們的活動更加精彩和有意義。我們想向每一位與會者表達衷心的感謝和誠摯的祝福。\n\n在這次活動中，我們見證了大家的熱情和互動。您的積極參與和分享，讓活動充滿了歡笑和溫暖的回憶。我們深深感謝您在活動中的參與，您的存在和參與為我們帶來了無價的價值。\n\n再次感謝您的參與和支持 [WEDDING_TITLE] ，您是我們活動的重要一環。祝願您和您的家人平安幸福，未來充滿更多的喜悅和成功。期待與您在下次活動中再次相見！\n\n誠摯感謝，\n[ORGANIZER]'
    },
    {   
        templateId: 'event_thank_you_zh_tw_2',
        blastType: 'whatsapp',
        enable: true,
        eventMode: true,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '2',
        custom: false,
        msg: '親愛的 [GUEST_NAME] ，\n\n感謝您參加 [WEDDING_TITLE] ！活動已經圓滿結束，我們希望這次的活動能給您留下美好的回憶和寶貴的經驗。\n\n我們希望您在活動中度過了一段美好的時光，並且與我們和其他與會者建立了有意義的聯繫。感謝您為活動帶來的能量和熱情，我們感到無比榮幸能與您共度這個特別的時刻。\n\n再次感謝您的參與和支持。我們期待著再次與您相聚，一同創造更多難忘的回憶。如有任何問題或意見，請隨時與我們聯繫。\n\n[ORGANIZER]'
    },
    {   
        templateId: 'event_thank_you_zh_tw_3',
        blastType: 'whatsapp',
        enable: true,
        eventMode: true,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '3',
        custom: false,
        msg: '[WEDDING_TITLE]\n\n親愛的 [GUEST_NAME] ，\n\n感謝您們蒞臨 [WEDDING_TITLE] ！活動結束後，我們想向每一位與會者表達我們最真摯的感謝和欣慰之情。\n\n您的參與使這次活動變得更加特別和成功。您的熱情參與和支持讓我們充滿了能量和動力。無論是您的熱情參與、對活動的貢獻、分享的知識和經驗，或是與我們一同度過的美好時光，都讓這次活動成為一個難忘的回憶。\n\n我們非常感激您對我們活動的支持和參與，您的存在和參與讓這個活動變得更加豐富和有意義。您的每一個貢獻和互動都對我們來說都是無價的。\n\n謝謝您對我們的信任和支持，我們希望這次活動能夠為您帶來美好的回憶和寶貴的經驗。期待未來能夠再次見到您，繼續分享和創造更多難忘的時刻。\n\n再次感謝您的參與和支持！\n\n衷心的祝福，\n[ORGANIZER]'
    },
    {   
        templateId: 'event_thank_you_zh_tw_4',
        blastType: 'whatsapp',
        enable: true,
        eventMode: true,
        language: {
            value: 'zh-TW',
            custom: false
        },
        templateType: {
            value: 'thank_you',
            custom: false
        },
        templateNo: '4',
        custom: false,
        msg: '親愛的 [GUEST_NAME]，\n\n非常感謝您參加 [WEDDING_TITLE] ！在這特別的時刻，您的參與對我們來說意義非凡。我們希望您度過了一個愉快且難忘的時光。\n\n您的參與和支持是我們活動成功的關鍵所在。無論是您的熱情參與、豐富的交流還是對活動的積極反饋，都使得活動更加精彩而有意義。我們深感榮幸能夠與您共度這美好的時刻。\n\n活動結束後，我們也將竭盡全力處理您提出的問題和意見。您的反饋對於我們的成長和改進至關重要。我們會將您的意見納入考慮，並努力提供更好的活動體驗。\n\n再次感謝您的參與和支持！我們衷心希望未來能夠再次與您相聚，為您帶來更多精彩和難忘的時刻。\n\n祝您一切順利，幸福安康！\n[ORGANIZER]'
    }
];