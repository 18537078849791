import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, map } from 'rxjs';

import { UpdateByService } from 'src/app/services/user/update-by.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { ErrorService } from 'src/app/services/general/error.service';

import { SettingField } from 'src/app/interfaces/database';
import { DefaultEventSpecialReqList, DefaultSpecialReqList, StdEventSpecialReqList, StdSpecialReqList } from 'src/app/commons/specialReq';
import { SettingFieldSortingType } from 'src/app/types/general';
import { AccountEventModeService } from '../account/account-event-mode.service';

/**
 * Special request service
 */
@Injectable({
  providedIn: 'root'
})
export class SpecialReqService implements OnInit, OnDestroy {

  reservedField: SettingField = {
    custom: false,
    value: 'none',
  };
  /**
   * Standard sepcial request database
   */
  // stdSpecialReqList: string[] = StdSpecialReqList;
  // defaultSpecialReqList: string[] = DefaultSpecialReqList;
  /**
   * Sepcial request list
   */
  specialReqList: SettingField[];
  /**
   * Observable special request list
   */
  observableSpecialReqList: any;
  /**
   * Special request subcription
   */
  private specialReqSubscription: Subscription;
  /**
   * Account ID
   */
  private accountId: string;

  /**
   * Constructor
   * @param afs angular firestore
   * @param translate translate service
   * @param userService user service
   * @param functionService function service
   */
  constructor(
    private afs: AngularFirestore,
    private accountEventModeService: AccountEventModeService,
    private updateByService: UpdateByService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
    this.specialReqList = [];
    this.observableSpecialReqList = new BehaviorSubject<SettingField[]>(this.specialReqList);
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.unwatchSpecialReq();
    this.specialReqList = [];
  }

  /**
   * Setup Account ID and watch / unwatch special request
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
    
    if (this.accountId) {
      await this.watchSpecialReq();
    } else {
      await this.unwatchSpecialReq();
      this.specialReqList = [];
    }
  }

  /**
   * Watch special request from firestore
   */
  async watchSpecialReq() {
    if (this.accountId && !this.specialReqSubscription) {
      this.specialReqSubscription = this.afs.doc(`accounts/${ this.accountId }/accountSetting/specialReq`)
      .snapshotChanges().pipe(map(changes => {
        const data: any = changes.payload.data();
        return data?.list;
      }), map((specialReqList: SettingField[]) => {
        if (specialReqList?.length) {
          return specialReqList.map((settingField: SettingField) => {
            if (!settingField?.id) {
              settingField.id = this.functionService.randomId() + '_' + settingField.value;
            }
            // if (settingField?.createBy) {
            //   delete settingField.createBy;
            // }
            // if (settingField?.updateBy) {
            //   delete settingField.updateBy;
            // }
            return settingField;
          });
        } else {
          return [];
          // return this.stdSpecialReqList.map((value: string) => {
          //   return { id: value, custom: false, value };
          // });
        }
      })).subscribe({
        next: (specialReqList: SettingField[]) => {
          if (!this.checkReservedField(specialReqList)) {
            specialReqList.push(this.reservedField);
          }
          this.specialReqList = specialReqList;
          this.observableSpecialReqList.next(this.specialReqList);
        }});
      }
  }

  checkReservedField(settingFieldList: SettingField[]) {
    const index = settingFieldList?.findIndex((x: SettingField) => {
      return x.custom === this.reservedField.custom && x.value === this.reservedField.value;
    });
    return index !== -1;
  }

  /**
   * Unwatch special request
   */
  async unwatchSpecialReq() {
    if (this.specialReqSubscription) {
      this.specialReqSubscription.unsubscribe();
      this.specialReqSubscription = null;
    }
  }

  /**
   * Search special request list
   * @param keyword Keyword
   * @param type Type
   * @param filter Filter
   * @returns List of fulfiled special request
   */
  searchSpecialReqList(eventMode?: boolean, keyword?: string, filter?: any, sorting?: SettingFieldSortingType, desc?: boolean): SettingField[] {
    let specialReqList: SettingField[] = this.getSpecialReqList();
    if (specialReqList?.length) {
      if (keyword) {
        specialReqList = specialReqList.filter((specialReq: SettingField) => {
          return this.searchSpecialReqBykeyword(specialReq, keyword);
        });
      }

      if (filter?.enable) {
        specialReqList = specialReqList.filter((specialReq: SettingField) => {
          return this.filterSpecialReqByCriteria(specialReq, filter);
        });
      }
    }
    return specialReqList;
  }

  /**
   * Search special request by keyword
   * @param specialReq specialReq
   * @param keyword Keyword
   * @returns sorted list
   */
  searchSpecialReqBykeyword(specialReq: SettingField, keyword: string): boolean {
    if (keyword && specialReq?.value) {
      const value = specialReq.custom ? specialReq.value :
        this.functionService.getTranslate('LIST.special_req.' + specialReq.value).toLowerCase();
      if (this.functionService.search(value, keyword)) {
        return true;
      } else if (this.functionService.chineseMath(value, keyword)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Filter special request by criteria
   * @param specialReq sepcial request
   * @param filter filter criteria
   */
  filterSpecialReqByCriteria(specialReq: SettingField, filter: any): boolean {
    if (filter?.enable) {
      if (filter.type) {
        if (filter.type === 'system' && specialReq.custom) {
          return false;
        } else if (filter.type === 'user' && !specialReq.custom) {
          return false;
        }
      }

      // if (filter.createBy) {
      //   if (filter.createBy === 'system') {
      //     if (specialReq.custom) {
      //       return false;
      //     }
      //   } else {
      //     if (!specialReq.createBy || !specialReq.createBy.uid || specialReq.createBy.uid !== filter.createBy) {
      //       return false;
      //     }
      //   }
      // }

      // if (filter.updateBy) {
      //   if (!specialReq.updateBy || !specialReq.updateBy.uid || specialReq.updateBy.uid !== filter.updateBy) {
      //     return false;
      //   }
      // }
    }
    return true;
  }

  /**
   * Sort special request list
   * @param specialReqList list to be sorted
   * @param field Sorting type
   * @param desc Descending flag
   */
  sortList(specialReqList: SettingField[], field?: SettingFieldSortingType, desc?: boolean): SettingField[] {
    if (specialReqList?.length) {
      specialReqList.sort((a: SettingField, b: SettingField) => {
        const nameA = a.custom ? a.value.toString() : this.functionService.getTranslate('LIST.special_req.' + a.value).toString();
        const nameB = b.custom ? b.value.toString() : this.functionService.getTranslate('LIST.special_req.' + b.value).toString();
        if (field) {
          // if (field === 'createdTime') {
          //   const timeA: number = a?.createBy?.time?.seconds ? a.createBy.time.seconds : 0;
          //   const timeB: number = b?.createBy?.time?.seconds ? b.createBy.time.seconds : 0;
          //   if (timeA !== timeB) {
          //     return this.functionService.compare(timeA, timeB, desc);
          //   }
          // } else if (field === 'updatedTime') {
          //   const timeA: number = a?.updateBy?.time?.seconds ? a.updateBy.time.seconds : 0;
          //   const timeB: number = b?.updateBy?.time?.seconds ? b.updateBy.time.seconds : 0;
          //   if (timeA !== timeB) {
          //     return this.functionService.compare(timeA, timeB, desc);
          //   }
          // }
          return this.functionService.compare(nameA.toLowerCase(), nameB.toLowerCase(), desc);
        } else {
          return this.functionService.compare(nameA.toLowerCase(), nameB.toLowerCase(), desc);
        }
        return this.functionService.compare(nameA.toLowerCase(), nameB.toLowerCase(), false);
      });
    }
    return specialReqList;
  }

  /**
   * Save special request into firestore
   * @param specialReqList Sepcial request list
   */
  async saveSpecialReq(list: SettingField[]) {
    if (this.accountId && list?.length) {
      const data: any = {
        list,
        updateBy: this.updateByService.updateBy
      };

      const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/specialReq/`);
      accountsRef.set(data, { merge: true }).then(result => {
      }).catch((err: any) => {
        this.errorService.logError(err);
      });
    }
  }

  getSpecialReqList(eventMode?: boolean) {
    if (!eventMode) {
      eventMode = this.accountEventModeService.eventMode;
    }
    return this.specialReqList?.length ? this.specialReqList : this.getStdSpecialReqList(eventMode);
  }

  getStdSpecialReqList(eventMode?: boolean): SettingField[] {
    const stdSpecialReqList: SettingField[] = [];
    if (eventMode) {
      StdEventSpecialReqList?.forEach((value) => {
        const settingField: SettingField = {
          id: value,
          value,
          custom: false,
        }
        stdSpecialReqList.push(settingField);
      });
    } else {
      StdSpecialReqList?.forEach((value) => {
        const settingField: SettingField = {
          id: value,
          value,
          custom: false,
        }
        stdSpecialReqList.push(settingField);
      });
    }
    return stdSpecialReqList;
  }

  getDefaultSpecialReqList(eventMode?: boolean): SettingField[] {
    const defaultSpecialReqList: SettingField[] = [];
    if (eventMode) {
      DefaultEventSpecialReqList?.forEach((value) => {
        const settingField: SettingField = {
          id: value,
          value,
          custom: false,
        }
        defaultSpecialReqList.push(settingField);
      });
    } else {
      DefaultSpecialReqList?.forEach((value) => {
        const settingField: SettingField = {
          id: value,
          value,
          custom: false,
        }
        defaultSpecialReqList.push(settingField);
      });
    }
    return defaultSpecialReqList;
  }
  
}
