import { BlastPrice } from "../interfaces/blast";

export const SmsPriceList: BlastPrice[] = [
	{
		country: 'SG',
		phone: 65,
		blastCredit: 0
	}, {
		country: 'MY',
		phone: 60,
		blastCredit: 5
	}, {
		country: 'TW',
		phone: 886,
		blastCredit: 9
	}, {
		country: 'HK',
		phone: 852,
		blastCredit: 9
	}, 
	{
		country: 'US',
		blastCredit: 0
	}, 
	{
		country: 'AF',
		phone: 93,
		blastCredit: 21
	}, {
		country: 'AL',
		phone: 355,
		blastCredit: 12
	}, {
		country: 'DZ',
		phone: 213,
		blastCredit: 22
	}, {
		country: 'AS',
		phone: 1684,
		blastCredit: 16
	}, {
		country: 'AD',
		phone: 376,
		blastCredit: 13
	}, {
		country: 'AO',
		phone: 244,
		blastCredit: 8
	}, {
		country: 'AI',
		phone: 1264,
		blastCredit: 11
	}, {
		country: 'AG',
		phone: 1268,
		blastCredit: 8
	}, {
		country: 'AR',
		phone: 54,
		blastCredit: 10
	}, {
		country: 'AM',
		phone: 374,
		blastCredit: 15
	}, {
		country: 'AW',
		phone: 297,
		blastCredit: 12
	}, {
		country: 'AU',
		phone: 61,
		blastCredit: 8
	}, {
		country: 'AT',
		phone: 43,
		blastCredit: 12
	}, {
		country: 'AZ',
		phone: 994,
		blastCredit: 30
	}, {
		country: 'BS',
		phone: 1242,
		blastCredit: 8
	}, {
		country: 'BH',
		phone: 973,
		blastCredit: 6
	}, {
		country: 'BD',
		phone: 880,
		blastCredit: 26
	}, {
		country: 'BB',
		phone: 1246,
		blastCredit: 12
	}, {
		country: 'BY',
		phone: 375,
		blastCredit: 28
	}, {
		country: 'BE',
		phone: 32,
		blastCredit: 13
	}, {
		country: 'BZ',
		phone: 501,
		blastCredit: 15
	}, {
		country: 'BJ',
		phone: 229,
		blastCredit: 15
	}, {
		country: 'BM',
		phone: 1441,
		blastCredit: 9
	}, {
		country: 'BT',
		phone: 975,
		blastCredit: 20
	}, {
		country: 'BO',
		phone: 591,
		blastCredit: 13
	}, {
		country: 'BA',
		phone: 387,
		blastCredit: 11
	}, {
		country: 'BW',
		phone: 267,
		blastCredit: 13
	}, {
		country: 'BV',
		phone: 47,
		blastCredit: 9
	}, {
		country: 'BR',
		phone: 55,
		blastCredit: 9
	}, {
		country: 'IO',
		phone: 246,
		blastCredit: 25
	}, {
		country: 'VG',
		phone: 1284,
		blastCredit: 10
	}, {
		country: 'BN',
		phone: 673,
		blastCredit: 5
	}, {
		country: 'BG',
		phone: 359,
		blastCredit: 16
	}, {
		country: 'BF',
		phone: 226,
		blastCredit: 9
	}, {
		country: 'BI',
		phone: 257,
		blastCredit: 19
	}, {
		country: 'KH',
		phone: 855,
		blastCredit: 17
	}, {
		country: 'CM',
		phone: 237,
		blastCredit: 18
	},
	{
		country: 'CA',
		// phone: 1,
		blastCredit: 2
	},
	{
		country: 'CV',
		phone: 238,
		blastCredit: 15
	}, {
		country: 'KY',
		phone: 1345,
		blastCredit: 12
	}, {
		country: 'CF',
		phone: 236,
		blastCredit: 6
	}, {
		country: 'TD',
		phone: 235,
		blastCredit: 17
	}, {
		country: 'CL',
		phone: 56,
		blastCredit: 9
	}, {
		country: 'CN',
		phone: 86,
		blastCredit: 7
	}, {
		country: 'CX',
		phone: 61,
		blastCredit: 8
	}, {
		country: 'CC',
		phone: 61,
		blastCredit: 8
	}, {
		country: 'CO',
		phone: 57,
		blastCredit: 8
	}, {
		country: 'KM',
		phone: 269,
		blastCredit: 10
	}, {
		country: 'CK',
		phone: 682,
		blastCredit: 7
	}, {
		country: 'CR',
		phone: 506,
		blastCredit: 7
	}, {
		country: 'HR',
		phone: 385,
		blastCredit: 9
	}, {
		country: 'CU',
		phone: 53,
		blastCredit: 11
	}, {
		country: 'CY',
		phone: 357,
		blastCredit: 11
	}, {
		country: 'CZ',
		phone: 420,
		blastCredit: 8
	}, {
		country: 'DK',
		phone: 45,
		blastCredit: 8
	}, {
		country: 'DJ',
		phone: 253,
		blastCredit: 15
	}, {
		country: 'DM',
		phone: 1767,
		blastCredit: 11
	}, {
		country: 'CD',
		phone: 243,
		blastCredit: 16
	}, {
		country: 'EC',
		phone: 593,
		blastCredit: 19
	}, {
		country: 'EG',
		phone: 20,
		blastCredit: 16
	}, {
		country: 'SV',
		phone: 503,
		blastCredit: 8
	}, {
		country: 'GQ',
		phone: 240,
		blastCredit: 14
	}, {
		country: 'ER',
		phone: 291,
		blastCredit: 16
	}, {
		country: 'EE',
		phone: 372,
		blastCredit: 11
	}, {
		country: 'ET',
		phone: 251,
		blastCredit: 21
	}, {
		country: 'FK',
		phone: 500,
		blastCredit: 18
	}, {
		country: 'FO',
		phone: 298,
		blastCredit: 6
	}, {
		country: 'FJ',
		phone: 679,
		blastCredit: 13
	}, {
		country: 'FI',
		phone: 358,
		blastCredit: 11
	}, {
		country: 'FR',
		phone: 33,
		blastCredit: 10
	}, {
		country: 'GF',
		phone: 594,
		blastCredit: 20
	}, {
		country: 'PF',
		phone: 689,
		blastCredit: 23
	}, {
		country: 'TF',
		phone: 262,
		blastCredit: 18
	}, {
		country: 'GA',
		phone: 241,
		blastCredit: 18
	}, {
		country: 'GM',
		phone: 220,
		blastCredit: 11
	}, {
		country: 'GE',
		phone: 995,
		blastCredit: 10
	}, {
		country: 'DE',
		phone: 49,
		blastCredit: 12
	}, {
		country: 'GH',
		phone: 233,
		blastCredit: 15
	}, {
		country: 'GI',
		phone: 350,
		blastCredit: 6
	}, {
		country: 'GR',
		phone: 30,
		blastCredit: 9
	}, {
		country: 'GL',
		phone: 299,
		blastCredit: 7
	}, {
		country: 'GD',
		phone: 1473,
		blastCredit: 9
	}, {
		country: 'GP',
		phone: 590,
		blastCredit: 18
	}, {
		country: 'GU',
		phone: 1671,
		blastCredit: 4
	}, {
		country: 'GT',
		phone: 502,
		blastCredit: 13
	}, {
		country: 'GG',
		phone: 44,
		blastCredit: 7
	}, {
		country: 'GN',
		phone: 224,
		blastCredit: 8
	}, {
		country: 'GW',
		phone: 245,
		blastCredit: 20
	}, {
		country: 'GY',
		phone: 592,
		blastCredit: 15
	}, {
		country: 'HT',
		phone: 509,
		blastCredit: 15
	}, {
		country: 'HN',
		phone: 504,
		blastCredit: 8
	}, {
		country: 'HU',
		phone: 36,
		blastCredit: 10
	}, {
		country: 'IS',
		phone: 354,
		blastCredit: 10
	}, {
		country: 'IN',
		phone: 91,
		blastCredit: 5
	}, {
		country: 'ID',
		phone: 62,
		blastCredit: 40
	}, {
		country: 'IR',
		phone: 98,
		blastCredit: 13
	}, {
		country: 'IQ',
		phone: 964,
		blastCredit: 10
	}, {
		country: 'IE',
		phone: 353,
		blastCredit: 10
	}, {
		country: 'IM',
		phone: 44,
		blastCredit: 7
	}, {
		country: 'IL',
		phone: 972,
		blastCredit: 13
	}, {
		country: 'IT',
		phone: 39,
		blastCredit: 10
	}, {
		country: 'CI',
		phone: 225,
		blastCredit: 10
	}, {
		country: 'JM',
		phone: 1876,
		blastCredit: 12
	}, {
		country: 'JP',
		phone: 81,
		blastCredit: 11
	}, {
		country: 'JE',
		phone: 44,
		blastCredit: 7
	}, {
		country: 'JO',
		phone: 962,
		blastCredit: 19
	}, {
		country: 'KE',
		phone: 254,
		blastCredit: 9
	}, {
		country: 'KI',
		phone: 686,
		blastCredit: 16
	}, {
		country: 'KW',
		phone: 965,
		blastCredit: 14
	}, {
		country: 'KG',
		phone: 996,
		blastCredit: 21
	}, {
		country: 'LA',
		phone: 856,
		blastCredit: 10
	}, {
		country: 'LV',
		phone: 371,
		blastCredit: 9
	}, {
		country: 'LB',
		phone: 961,
		blastCredit: 17
	}, {
		country: 'LS',
		phone: 266,
		blastCredit: 13
	}, {
		country: 'LR',
		phone: 231,
		blastCredit: 10
	}, {
		country: 'LY',
		phone: 218,
		blastCredit: 24
	}, {
		country: 'LI',
		phone: 423,
		blastCredit: 6
	}, {
		country: 'LT',
		phone: 370,
		blastCredit: 4
	}, {
		country: 'LU',
		phone: 352,
		blastCredit: 10
	}, {
		country: 'MO',
		phone: 853,
		blastCredit: 6
	}, {
		country: 'MK',
		phone: 389,
		blastCredit: 9
	}, {
		country: 'MG',
		phone: 261,
		blastCredit: 18
	}, {
		country: 'MW',
		phone: 265,
		blastCredit: 16
	}, {
		country: 'MV',
		phone: 960,
		blastCredit: 7
	}, {
		country: 'ML',
		phone: 223,
		blastCredit: 13
	}, {
		country: 'MT',
		phone: 356,
		blastCredit: 9
	}, {
		country: 'MH',
		phone: 692,
		blastCredit: 19
	}, {
		country: 'MQ',
		phone: 596,
		blastCredit: 10
	}, {
		country: 'MR',
		phone: 222,
		blastCredit: 15
	}, {
		country: 'MU',
		phone: 230,
		blastCredit: 15
	}, {
		country: 'YT',
		phone: 262,
		blastCredit: 18
	}, {
		country: 'MX',
		phone: 52,
		blastCredit: 8
	}, {
		country: 'FM',
		phone: 691,
		blastCredit: 18
	}, {
		country: 'MD',
		phone: 373,
		blastCredit: 9
	}, {
		country: 'MC',
		phone: 377,
		blastCredit: 20
	}, {
		country: 'MN',
		phone: 976,
		blastCredit: 17
	}, {
		country: 'MS',
		phone: 1664,
		blastCredit: 9
	}, {
		country: 'MA',
		phone: 212,
		blastCredit: 12
	}, {
		country: 'MZ',
		phone: 258,
		blastCredit: 7
	}, {
		country: 'MM',
		phone: 95,
		blastCredit: 17
	}, {
		country: 'NA',
		phone: 264,
		blastCredit: 7
	}, {
		country: 'NR',
		phone: 674,
		blastCredit: 3
	}, {
		country: 'NP',
		phone: 977,
		blastCredit: 14
	}, {
		country: 'NL',
		phone: 31,
		blastCredit: 12
	}, {
		country: 'NC',
		phone: 687,
		blastCredit: 19
	}, {
		country: 'NZ',
		phone: 64,
		blastCredit: 13
	}, {
		country: 'NI',
		phone: 505,
		blastCredit: 9
	}, {
		country: 'NE',
		phone: 227,
		blastCredit: 19
	}, {
		country: 'NG',
		phone: 234,
		blastCredit: 13
	}, {
		country: 'NU',
		phone: 683,
		blastCredit: 15
	}, {
		country: 'NF',
		phone: 672,
		blastCredit: 6
	}, {
		country: 'KP',
		phone: 850,
		blastCredit: 0
	}, {
		country: 'MP',
		phone: 1670,
		blastCredit: 5
	}, {
		country: 'NO',
		phone: 47,
		blastCredit: 9
	}, {
		country: 'OM',
		phone: 968,
		blastCredit: 14
	}, {
		country: 'PK',
		phone: 92,
		blastCredit: 23
	}, {
		country: 'PW',
		phone: 680,
		blastCredit: 6
	}, {
		country: 'PS',
		phone: 970,
		blastCredit: 25
	}, {
		country: 'PA',
		phone: 507,
		blastCredit: 9
	}, {
		country: 'PG',
		phone: 675,
		blastCredit: 11
	}, {
		country: 'PY',
		phone: 595,
		blastCredit: 5
	}, {
		country: 'PE',
		phone: 51,
		blastCredit: 8
	}, {
		country: 'PH',
		phone: 63,
		blastCredit: 20
	}, {
		country: 'PN',
		phone: 64,
		blastCredit: 13
	}, {
		country: 'PL',
		phone: 48,
		blastCredit: 7
	}, {
		country: 'PT',
		phone: 351,
		blastCredit: 7
	}, {
		country: 'QA',
		phone: 974,
		blastCredit: 9
	}, {
		country: 'CG',
		phone: 242,
		blastCredit: 9
	}, {
		country: 'RE',
		phone: 262,
		blastCredit: 18
	}, {
		country: 'RO',
		phone: 40,
		blastCredit: 10
	}, {
		country: 'RU',
		phone: 7,
		blastCredit: 19
	}, {
		country: 'RW',
		phone: 250,
		blastCredit: 9
	}, {
		country: 'KN',
		phone: 1869,
		blastCredit: 9
	}, {
		country: 'LC',
		phone: 1758,
		blastCredit: 8
	}, {
		country: 'PM',
		phone: 508,
		blastCredit: 17
	}, {
		country: 'VC',
		phone: 1784,
		blastCredit: 8
	}, {
		country: 'WS',
		phone: 685,
		blastCredit: 8
	}, {
		country: 'SM',
		phone: 378,
		blastCredit: 15
	}, {
		country: 'ST',
		phone: 239,
		blastCredit: 6
	}, {
		country: 'SA',
		phone: 966,
		blastCredit: 10
	}, {
		country: 'SN',
		phone: 221,
		blastCredit: 15
	}, {
		country: 'RS',
		phone: 381,
		blastCredit: 7
	}, {
		country: 'SC',
		phone: 248,
		blastCredit: 6
	}, {
		country: 'SL',
		phone: 232,
		blastCredit: 12
	}, {
		country: 'SK',
		phone: 421,
		blastCredit: 9
	}, {
		country: 'SI',
		phone: 386,
		blastCredit: 8
	}, {
		country: 'SB',
		phone: 677,
		blastCredit: 11
	}, {
		country: 'SO',
		phone: 252,
		blastCredit: 13
	}, {
		country: 'ZA',
		phone: 27,
		blastCredit: 6
	}, {
		country: 'GS',
		phone: 500,
		blastCredit: 18
	}, {
		country: 'KR',
		phone: 82,
		blastCredit: 8
	}, {
		country: 'SS',
		phone: 211,
		blastCredit: 16
	}, {
		country: 'ES',
		phone: 34,
		blastCredit: 11
	}, {
		country: 'LK',
		phone: 94,
		blastCredit: 21
	}, {
		country: 'SD',
		phone: 249,
		blastCredit: 16
	}, {
		country: 'SR',
		phone: 597,
		blastCredit: 12
	}, {
		country: 'SJ',
		phone: 4779,
		blastCredit: 12
	}, {
		country: 'SZ',
		phone: 268,
		blastCredit: 10
	}, {
		country: 'SE',
		phone: 46,
		blastCredit: 8
	}, {
		country: 'CH',
		phone: 41,
		blastCredit: 10
	}, {
		country: 'SY',
		phone: 963,
		blastCredit: 33
	}, {
		country: 'TJ',
		phone: 992,
		blastCredit: 31
	}, {
		country: 'TZ',
		phone: 255,
		blastCredit: 13
	}, {
		country: 'TH',
		phone: 66,
		blastCredit: 6
	}, {
		country: 'TL',
		phone: 670,
		blastCredit: 13
	}, {
		country: 'TG',
		phone: 228,
		blastCredit: 8
	}, {
		country: 'TK',
		phone: 690,
		blastCredit: 17
	}, {
		country: 'TO',
		phone: 676,
		blastCredit: 9
	}, {
		country: 'TT',
		phone: 1868,
		blastCredit: 13
	}, {
		country: 'TN',
		phone: 216,
		blastCredit: 19
	}, {
		country: 'TR',
		phone: 90,
		blastCredit: 6
	}, {
		country: 'TM',
		phone: 993,
		blastCredit: 16
	}, {
		country: 'TC',
		phone: 1649,
		blastCredit: 7
	}, {
		country: 'TV',
		phone: 688,
		blastCredit: 9
	}, {
		country: 'UG',
		phone: 256,
		blastCredit: 14
	}, {
		country: 'UA',
		phone: 380,
		blastCredit: 15
	}, {
		country: 'AE',
		phone: 971,
		blastCredit: 12
	}, {
		country: 'GB',
		phone: 44,
		blastCredit: 7
	},
	// {
	// 	country: 'UM',
	// 	phone: 1,
	// 	blastCredit: 2
	// },
	 {
		country: 'VI',
		phone: 1340,
		blastCredit: 5
	}, {
		country: 'UY',
		phone: 598,
		blastCredit: 12
	}, {
		country: 'UZ',
		phone: 998,
		blastCredit: 34
	}, {
		country: 'VU',
		phone: 678,
		blastCredit: 12
	}, {
		country: 'VA',
		phone: 379,
		blastCredit: 0
	}, {
		country: 'VE',
		phone: 58,
		blastCredit: 10
	}, {
		country: 'VN',
		phone: 84,
		blastCredit: 18
	}, {
		country: 'WF',
		phone: 681,
		blastCredit: 27
	}, {
		country: 'EH',
		phone: 212,
		blastCredit: 12
	}, {
		country: 'YE',
		phone: 967,
		blastCredit: 21
	}, {
		country: 'ZM',
		phone: 260,
		blastCredit: 8
	}, {
		country: 'ZW',
		phone: 263,
		blastCredit: 16
	}
];