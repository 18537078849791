import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GoogleMapNotSupportCountry } from 'src/app/commons/googleMap';
import { UberNotSupportCountry } from 'src/app/commons/uber';
import { PageSetting, VisitorAttachment } from 'src/app/interfaces/visitor';
import { AttachmentType } from 'src/app/types/general';

import { FunctionService } from '../general/function.service';
import { LocalityService } from '../location/locality.service';

import { VisitorDefaultService } from './visitor-default.service';
import { VisitorManageService } from './visitor-manage.service';
import { GrabSupportCountry } from 'src/app/commons/grab';

@Injectable({
  providedIn: 'root'
})
export class VisitorSettingService implements OnInit, OnDestroy {

  /**
   * Observable page setting
   */
  observablePageSetting: any;
  /**
   * Page setting
   */
  pageSetting: PageSetting;

  constructor(
    private visitorDefaultService: VisitorDefaultService,
    private visitorManageService: VisitorManageService,
    private localityService: LocalityService,
    private functionService: FunctionService,
  ) {
    this.observablePageSetting = new BehaviorSubject<PageSetting>(this.pageSetting);
  }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  setupPageSetting(pageSetting?: any) {
    const defaultPageSetting = this.getDefaultSetting();
    if (!pageSetting) {
      pageSetting = defaultPageSetting;
    }
    pageSetting = this.checkGpsCountry(pageSetting);
    if (this.functionService.isUndefined(pageSetting?.webCheckin)) {
      pageSetting.webCheckin = defaultPageSetting.webCheckin;
    }
    if (this.functionService.isUndefined(pageSetting?.webCheckinStartHour)) {
      pageSetting.webCheckinStartHour = defaultPageSetting.webCheckinStartHour;
    }
    if (this.functionService.isUndefined(pageSetting?.webCheckinStartMinute)) {
      pageSetting.webCheckinStartMinute = defaultPageSetting.webCheckinStartMinute;
    }
    if (this.functionService.isUndefined(pageSetting?.webCheckinEndHour)) {
      pageSetting.webCheckinEndHour = defaultPageSetting.webCheckinEndHour;
    }
    if (this.functionService.isUndefined(pageSetting?.webCheckinEndMinute)) {
      pageSetting.webCheckinEndMinute = defaultPageSetting.webCheckinEndMinute;
    }
    // if (pageSetting?.selfCheckin) {
    //   delete pageSetting.selfCheckin;
    // }
    // if (pageSetting?.selfCheckinStartHour) {
    //   delete pageSetting.selfCheckinStartHour;
    // }
    // if (!this.functionService.isUndefined(pageSetting?.selfCheckinStartMinute)) {
    //   delete pageSetting.selfCheckinStartMinute;
    // }
    // if (pageSetting?.selfCheckinEndHour) {
    //   delete pageSetting.selfCheckinEndHour;
    // }
    // if (!this.functionService.isUndefined(pageSetting?.selfCheckinEndMinute)) {
    //   delete pageSetting.selfCheckinEndMinute;
    // }
    this.pageSetting = pageSetting;
    this.visitorManageService.pageSetting = pageSetting;
    this.observablePageSetting.next(this.pageSetting);
    return pageSetting;
  }
  
  getPageSetting() {
    return this.pageSetting ? this.pageSetting : this.getDefaultSetting();
  }

  /**
   * Get default page setting.
   * Set page setting based on country.
   * Disable uber for Singapore, Indonesia, Cambodia, Malaysia, Myanmar, the Philippines, Thailand and Vietnam.
   * Disable google map for China
   * @returns default page setting
   */
  getDefaultSetting(): PageSetting {
    let pageSetting: PageSetting = this.visitorDefaultService.getDefaultSetting();
    pageSetting = this.checkGpsCountry(pageSetting);
    return pageSetting;
  }

  getAttachmentByType(type: AttachmentType, selectedIndex?: number) {
    if (this.pageSetting?.attachment?.length) {
      const index = this.pageSetting.attachment?.findIndex((x: VisitorAttachment, i: number) => {
        if (type === 'attachment') {
          if (x.type === type) {
            if (selectedIndex) {
              if (i === selectedIndex) {
                return true;
              }
            } else {
              return true;  
            }
          }
        } else {
          return x?.type && x.type === type;
        }
        return false;
      });
      if (index !== -1 && this.pageSetting?.attachment?.[index]) {
        return this.pageSetting.attachment[index];
      }
    }
    return '';
  }

  checkGpsCountry(pageSetting: PageSetting) {
    if (pageSetting) {
      if (!this.checkUberCountry()) {
        pageSetting.uber = false;
      }
      if (!this.checkGoogleMapCountry()) {
        pageSetting.googleMap = false;
      }
      if (!this.checkWazeCountry()) {
        pageSetting.waze = false;
      }
      if (!this.checkGrabCountry()) {
        pageSetting.grab = false;
      }
    }
    return pageSetting;
  }

  /**
   * Check if uber is support in country
   * @param country country to check, get current account country if not provided
   * @returns true if uber is support in the country
   */
  checkUberCountry(country?: string): boolean {
    if (!country) {
      country = this.localityService.getAccountCountry()?.code;
    }
    if (UberNotSupportCountry?.indexOf(country) !== -1) {
      return false;
    }
    return true;
  }

  checkGrabCountry(country?: string): boolean {
    if (!country) {
      country = this.localityService.getAccountCountry()?.code;
    }
    if (GrabSupportCountry?.indexOf(country) !== -1) {
      return true;
    }
    return false;
  }

  /**
   * Check if Google map is support in country
   * @param country country to check, get current account country if not provided
   * @returns true if Google map is support in the country
   */
  checkGoogleMapCountry(country?: string): boolean {
    if (!country) {
      country = this.localityService.getAccountCountry()?.code;
    }
    if (GoogleMapNotSupportCountry?.indexOf(country) !== -1) {
      return false;
    }
    return true;
  }

  /**
   * Check if Waze is support in country
   * @param country country to check, get current account country if not provided
   * @returns true if Waze is support in the country
   */
  checkWazeCountry(country?: string): boolean {
    if (!country) {
      country = this.localityService.getAccountCountry()?.code;
    }
    return true;
  }
}
