import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { GiftDeleteService } from 'src/app/services/gift/gift-delete.service';
import { GroupService } from 'src/app/services/group/group.service';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { GiftProfileComponent } from 'src/app/components/gift/gift-profile/gift-profile.component';
import { GiftManageComponent } from 'src/app/components/gift/gift-manage/gift-manage.component';

import { SettingField } from 'src/app/interfaces/database';
import { Guest } from 'src/app/interfaces/guest';
import { Group } from 'src/app/interfaces/group';
import { Gift } from 'src/app/interfaces/gift';
import { ModuleType, SettingFieldType } from 'src/app/types/general';
import { Subscription } from 'rxjs';

/**
 * Gift Item Component
 */
@Component({
  selector: 'app-gift-item',
  templateUrl: './gift-item.component.html',
  styleUrls: ['./gift-item.component.scss'],
})
export class GiftItemComponent implements OnInit, OnDestroy {

  /**
   * Gift item index
   */
  @Input() index: number;
  /**
   * Edit mode
   */
  @Input() editMode: boolean;
  /**
   * Select mode
   */
  @Input() selectMode: boolean;
  /**
   * Selected
   */
  @Input() selected: boolean;
  /**
   * Selected gift list
   */
  @Input() selectedGiftList: string[];
  /**
   * Disable click
   */
  @Input() disableClick: boolean;
  /**
   * Disable edit
   */
  @Input() disableEdit: boolean;
  /**
   * Disable delete
   */
  @Input() disableDelete: boolean;
  /**
   * Disable restore
   */
  @Input() disableRestore: boolean;

  /**
   * Module
   */
  module: ModuleType;

  /**
   * Gift modal
   */
  @Input() private giftModal: boolean;
  /**
   * Set Gift ID
   */
  @Input() set setGiftId(giftId: string) {
    this.gift = this.giftService.getGiftById(giftId);
    this.setupData();
  }
  /**
   * Set Gift
   */
  @Input() set setGift(gift: Gift) {
    this.gift = gift;
    this.setupData();
  }

  /**
   * Output selected gift
   */
  @Output() setSelectedGift = new EventEmitter<string>();
  
  /**
   * Gift
   */
  gift: Gift;
  /**
   * Guest / group name.
   */
  name: string;
  /**
   * Gift invited by
   */
  giftInvitedBy: SettingField[];
  /**
   * Gift category
   */
  giftCategory: SettingField[];

  invitedBy: string;
  category: string;

  /**
   * Constructor
   * @param translate translate service
   * @param modalController modal controller
   * @param guestService guest service
   * @param groupService group service
   * @param giftService gift service
   * @param giftDeleteService gift delete service
   * @param settingFieldService Setting field service
   * @param popupService popup service
   */
  constructor(
    private translate: TranslateService,
    private modalController: ModalController,
    private settingFieldService: SettingFieldService,
    private guestService: GuestService,
    private groupService: GroupService,
    private giftService: GiftService,
    private giftDeleteService: GiftDeleteService,
    private popupService: PopupService,
  ) {
  }

  /**
   * Ng init
   */
  ngOnInit() {
  }

  ngOnDestroy() {

  }

  /**
   * Setup module
   */
  setupModule() {
    if (this.module === 'trash') {
      this.disableEdit = true;
    } else {
      this.disableRestore = true;
    }
  }

  setupSettingField() {
    this.invitedBy = this.getSettingField('invited_by', this.giftInvitedBy);
    this.category = this.getSettingField('category', this.giftCategory);
  }

  /**
   * Setup gift data
   */
  setupData() {
    this.giftInvitedBy = [];
    this.giftCategory = [];
    this.name = '';
    if (this.gift?.groupIdList?.length) {
      let guestIdList: string[] = [];
      const groupList: Group[] = this.groupService.getGroupListById(this.gift.groupIdList);
      groupList?.forEach((group: Group) => {
        if (group?.groupName && group?.memberList?.length) {
          if (this.name) {
            this.name = this.name + ', ';
          }
          this.name += group.groupName;
          guestIdList = [...new Set([...guestIdList, ...group.memberList])];
        }
      });
      if (guestIdList.length) {
        const guestList = this.guestService.getGuestListById(guestIdList);
        guestList?.forEach((guest: Guest) => {
          if (guest?.guestId && guest?.name && !guest?.status?.deleted) {
            guest?.invitedBy?.forEach((invitedBy: SettingField) => {
              if (this.giftInvitedBy?.findIndex((x: SettingField) =>
              x?.value === invitedBy?.value && x?.custom === invitedBy?.custom) === -1) {
                this.giftInvitedBy.push(invitedBy);
              }
            });
            guest?.category?.forEach((category: SettingField) => {
              if (this.giftCategory?.findIndex((x: SettingField) =>
              x?.value === category?.value && x?.custom === category?.custom) === -1) {
                this.giftCategory.push(category);
              }
            });
          }
        });
      }
    }
    if (this.gift?.guestIdList?.length) {
      const guestList = this.guestService.getGuestListById(this.gift.guestIdList);
      guestList?.forEach((guest: Guest) => {
        if (guest?.guestId && guest?.name && !guest?.status?.deleted) {
          if (this.name) { this.name = this.name + ', '; }
          this.name = this.name + guest.name;
          guest?.invitedBy?.forEach((invitedBy: SettingField) => {
            if (this.giftInvitedBy?.findIndex((x: SettingField) => x?.value === invitedBy?.value && x?.custom === invitedBy?.custom) === -1) {
              this.giftInvitedBy.push(invitedBy);
            }
          });
          guest?.category?.forEach((category: SettingField) => {
            if (this.giftCategory?.findIndex((x: SettingField) => x?.value === category?.value && x?.custom === category?.custom) === -1) {
              this.giftCategory.push(category);
            }
          }); 
        }
      });
    }
    this.setupSettingField();
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[]): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList);
  }

  /**
   * Gift click
   * @param gift Gift
   */
  giftClick(gift: Gift) {
    if (!this.disableClick) {
      if (this.editMode && gift?.giftId) {
        this.setSelectedGift.next(gift.giftId);
      } else {
        if (!this.giftModal) {
          this.presentGiftProfileModal(gift);
        } else {
          this.dismissModal(gift);
        }
      }
    }
  }

  /**
   * Dismiss modal
   * @param gift Gift
   */
  async dismissModal(gift?: Gift) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ gift }); }
    }
  }

  /**
   * Present gift profile modal
   * @param gift Gift
   */
  async presentGiftProfileModal(gift: Gift) {
    if (gift) {
      const modal = await this.modalController.create({
        component: GiftProfileComponent,
        componentProps: {
          gift
        }
      });
      modal.present();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Present gift manage Modal
   * @param gift Gift
   * @param slidingItem Sliding item
   */
  async presentGiftManageModal(gift: Gift, slidingItem: any) {
    const modal = await this.modalController.create({
      component: GiftManageComponent,
      componentProps: {
        gift
      }
    });
    modal.present();
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * delete gift
   * @param gift Gift
   * @param slidingItem sliding item
   */
  async deleteFunc(gift: Gift, slidingItem?: any) {
    if (gift) {
      if (this.module === 'trash') {
        await this.promptDelete(gift);
      } else {
        await this.promptRemove(gift);
      }
    } else {
      this.popupService.presentActionError();
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Prompt delete
   * @param gift Gift
   */
  async promptDelete(gift: Gift) {
    if (gift?.giftId) {
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_db_field', {
          field: this.translate.instant('GIFT.lbl.gift') })
      );
      modal.onDidDismiss().then((result: any) => {
        if (result?.data?.confirm) {
          this.deleteGift([ gift.giftId ]);
        }
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Prompt remove
   * @param gift Gift
   * @param slidingItem Sliding item
   */
  async promptRemove(gift: Gift) {
    if (gift?.giftId) {
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_field', {
          field: this.translate.instant('GIFT.lbl.gift')
        })
      );
      modal.onDidDismiss().then((result: any) => {
        if (result?.data?.confirm) {
          this.removeGift([ gift.giftId ], [ gift ]);
        }
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Prompt restore
   * @param gift gift
   * @param slidingItem sliding item
   */
  async promptRestore(gift?: Gift, slidingItem?: any) {
    if (gift?.giftId) {
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_restore_field', {
          field: this.translate.instant('GIFT.lbl.gift')
        })
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.restoreGift([ gift.giftId ]);
        }
      });
    } else {
      this.popupService.presentActionError();
    }
    if (slidingItem) { slidingItem.close(); }
  }

  /**
   * Save remove gift
   */
   async removeGift(giftIdList: string[], giftList?: Gift[]) {
    await this.popupService.presentLoading();
    if (giftIdList?.length) {
      await this.giftDeleteService.removeGiftList(giftIdList, giftList);
    }
    this.popupService.dismissLoading();
    this.popupService.saveSuccessToast();
  }

  /**
   * Remove gift from db
   */
  async deleteGift(giftIdList: string[]) {
    await this.popupService.presentLoading();
    if (giftIdList?.length) {
      await this.giftDeleteService.deleteGiftList(giftIdList);
    }
    this.popupService.dismissLoading();
    this.popupService.saveSuccessToast();
  }

  /**
   * Save restore guest
   */
  async restoreGift(giftIdList: string[]) {
    await this.popupService.presentLoading();
    if (giftIdList?.length) {
      await this.giftDeleteService.restoreGiftList(giftIdList);
    }
    this.popupService.dismissLoading();
    this.popupService.saveSuccessToast();
  }

}
