import { NewsCardTncComponent } from './news-card-tnc/news-card-tnc.component';
import { NewsCardItemComponent } from './news-card-item/news-card-item.component';
import { NewsCardSlideComponent } from './news-card-slide/news-card-slide.component';

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { OfflineComponentsModule } from 'src/app/components/general/offline/offline.components.module';
// import { SwiperModule } from 'swiper/angular';

@NgModule({
    declarations: [
        NewsCardSlideComponent,
        NewsCardItemComponent,
        NewsCardTncComponent,
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        // SwiperModule,
        OfflineComponentsModule,
        TranslateModule,
    ],
    exports: [
        NewsCardSlideComponent,
        NewsCardItemComponent,
        NewsCardTncComponent,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class NewsCardComponentsModule { }
