import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { DeviceService } from 'src/app/services/device/device.service';
import { DeviceLanguageService } from 'src/app/services/device/device-language.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { UserDevice } from 'src/app/interfaces/device';
import { AngularFirestore } from '@angular/fire/compat/firestore';


/**
 * Device manage service
 */
@Injectable({
  providedIn: 'root'
})
export class DeviceManageService implements OnInit, OnDestroy {

  /**
   * token
   */
  private token: string;
  /**
   * Device data from capacitor plugin
   */
  private deviceData: UserDevice;
  /**
   * User UID
   */
  private uid: string;

  /**
   * Constructor
   * @param afs angular firestore
   * @param deviceLocationService device location service
   * @param deviceLanguageService device language service
   * @param errorService error service
   * @param functionService function service
   */
  constructor(
    private afs: AngularFirestore,
    // private firestore: Firestore,
    private deviceService: DeviceService,
    private deviceLanguageService: DeviceLanguageService,
    private errorService: ErrorService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Set user UID
   * @param uid UID
   */
  setUid(uid: string) {
    this.uid = uid;
  }

  /**
   * Set token
   * @param token token
   */
  async setToken(token: string) {
    this.token = token;
    await this.readDevice(token);

    if (this.deviceData) { 
      if (this.deviceData?.token !== token || this.deviceData?.uid !== this.uid) {
        await this.updateToken(this.token);
      }
    }
  }

  async checkStatus() {
    if (!this.deviceData) {
      await this.readDevice(this.token);
      if (!this.deviceData?.active) {
        await this.updateStatus(true);
      }
    }
  }

  /**
   * Compare if two variable is equal
   * @param a Variable A
   * @param b Variable B
   * @return true if two variable is equal
   */
  isEqual(a: any, b: any): boolean {
    return this.functionService.isEqual(a, b);
  }

  /**
   * Read device data from firestore
   */
  async readDevice(token: string) {
    if (this.deviceService.uuid && this.uid) {
      const docRef = this.afs.doc(`users/${ this.uid }/devices/${ this.deviceService.uuid }/`);
      const result = await docRef.ref.get();
      if (result?.exists && result?.data()) {
        const deviceData = result.data() as UserDevice;
        if (deviceData && token && deviceData?.token !== token) {
          this.deviceData = deviceData;
          this.deviceData.token = token;
          this.saveDevice();
        } else if (deviceData && !deviceData?.active) {
          this.deviceData = deviceData;
          this.deviceData.active = true;
          this.saveDevice();
        } else {
          this.checkDeviceData();
        }
      } else {
        this.saveDevice();
      }
    } else {
      await this.functionService.delay(1000);
      this.readDevice(token);
    }
  }

  /**
   * Get current device data
   * @returns device data obj
   */
  getDeviceData(): UserDevice {
    const language = this.deviceLanguageService.checkDeviceLanguage();
    return {
      uid: this.uid,
      uuid: this.deviceService.uuid,
      enable: true,
      active: true,
      info: this.deviceService.deviceInfo,
      token: this.token ? this.token : '',
      language,
      userLanguage: language,
    };
  }

  /**
   * Create device data to firestore under user account
   */
  async saveDevice() {
    if (!this.deviceData) {
      this.deviceData = this.getDeviceData();
    }
    if (this.deviceData) {
      if (!this.deviceData?.createdTime?.seconds) {
        this.deviceData.createdTime = this.functionService.firestoreServerTime;
      }
      this.deviceData.updatedTime = this.functionService.firestoreServerTime;

      if (this.functionService.isUndefined(this.deviceData?.info?.diskFree)) {
        delete this.deviceData.info.diskFree;
      }
      if (this.functionService.isUndefined(this.deviceData?.info?.diskTotal)) {
        delete this.deviceData.info.diskTotal;
      }
      if (this.functionService.isUndefined(this.deviceData?.info?.memUsed)) {
        delete this.deviceData.info.memUsed;
      }
      if (this.functionService.isUndefined(this.deviceData?.info?.name)) {
        delete this.deviceData.info.name;
      }

      if (this.functionService.isUndefined(this.deviceData?.info?.isVirtual)) {
        this.deviceData.info.isVirtual = false;
      }
      if (this.functionService.isUndefined(this.deviceData?.info?.manufacturer)) {
        this.deviceData.info.manufacturer = '';
      }
      if (this.functionService.isUndefined(this.deviceData?.info?.model)) {
        this.deviceData.info.model = '';
      }
      if (this.functionService.isUndefined(this.deviceData?.info?.operatingSystem)) {
        this.deviceData.info.operatingSystem = 'unknown';
      }
      if (this.functionService.isUndefined(this.deviceData?.info?.osVersion)) {
        this.deviceData.info.osVersion = '';
      }
      if (this.functionService.isUndefined(this.deviceData?.info?.platform)) {
        this.deviceData.info.platform = 'unknown';
      }
      if (this.functionService.isUndefined(this.deviceData?.info?.webViewVersion)) {
        this.deviceData.info.webViewVersion = '';
      }

      const docRef = this.afs.doc(`users/${ this.uid }/devices/${ this.deviceService.uuid }/`);
      docRef.set(this.deviceData, { merge: true }).then(() => {
      }).catch((err: any) => {
        this.errorService.logError(err);
      });
    }
  }

  /**
   * Update user device token for push notification
   * @param token device token
   */
  async updateToken(token: string) {
    if (this.uid && this.deviceService.uuid && token) {
      const updatedTime = this.functionService.firestoreServerTime;
      const docRef = this.afs.doc(`users/${ this.uid }/devices/${ this.deviceService.uuid }/`);
      docRef.set({ token, active: true, updatedTime }, { merge: true }).then(() => {
      }).catch((err: any) => {
        this.errorService.logError(err);
      });
    }
  }

  /**
   * Update device active status, set active to false once user logout from the device.
   * @param active active status
   * Notes: The device will not receive push notification if active set to false.
   */
  async updateStatus(active: boolean) {
    if (this.uid) {
      const updatedTime = this.functionService.firestoreServerTime;
      const docRef = this.afs.doc(`users/${ this.uid }/devices/${ this.deviceService.uuid }/`);
      docRef.set({ active, updatedTime }, { merge: true }).then(() => {
      }).catch((err: any) => {
        this.errorService.logError(err);
      });
    }
  }

  async checkDeviceData() {
    if (!this.deviceData?.uid || !this.deviceData?.userLanguage || !this.deviceData?.active) {
      this.deviceData = this.getDeviceData();
      await this.saveDevice();
    }
  }

  async updateDeviceLanguage(userLanguage: string) {
    if (this.uid && userLanguage) {
      const docRef = this.afs.doc(`users/${ this.uid }/devices/${ this.deviceService.uuid }/`);
      docRef.set({ userLanguage }, { merge: true }).then(() => {
      }).catch((err: any) => {
        this.errorService.logError(err);
      });
    }
  }
}
